import React, { useEffect, useState } from "react";
import elSidebarMenus from "../../../data/json/elSidebarMenus.json";
import { DebounceInput } from "react-debounce-input";
import courseColumns from "../../../data/json/courseColumns.json";
import { courseApi } from "../../../constants/apis/EL/Course";
import { convertParamsToString } from "../../../utils/Request";
import { getHrsMinsSecs } from "../../../utils/Time";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  setCourseAddModuleSpecific,
  setCourseAddOpenToggle,
  setCourseAdded,
  setCourseEdit,
  setCourseEditOpenToggle,
  setCourseEdited,
  setCourseId,
  setCourseModuleId,
  setCourseModuleName,
} from "../../../features/EL/courseFormSlice";
import {
  setCourseDetailsOpen,
  setCourseIdForDetails,
} from "../../../features/EL/courseDetailsSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { useNavigate, useParams } from "react-router-dom";
import { userRoles } from "../../../enums/Auth";
import {
  setCourseDetailsEdit,
  setCourseDetailsEditId,
  setCourseDetailsEditOpenToggle,
} from "../../../features/EL/courseDetailsForm";
import {
  setCourseQuestionsEdit,
  setCourseQuestionsEditId,
  setCourseQuestionsEditOpenToggle,
} from "../../../features/EL/courseQuestionsFormSlice";
import AdminCourseActions from "../../../components/ElCustomActions/AdminCourseActions/AdminCourseActions";
import CourseQuestionsForm from "../../../components/ElForms/CourseQuestionsForm/CourseQuestionsForm";
import CourseDetailsForm from "../../../components/ElForms/CourseDetailsForm/CourseDetailsForm";
import CourseForm from "../../../components/ElForms/CourseForm/CourseForm";
import CourseDetails from "../../../components/EntityDetails/CourseDetails/CourseDetails";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
  IconButton,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const ElModuleSpecific = () => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const profileData = useSelector((state) => state.userProfile.profileData);

  const moduleState = useSelector((state) => state.modules);

  const appSidebar = JSON.parse(JSON.stringify(elSidebarMenus));
  appSidebar.menuList[0].currentPage = true;
  const moduleSpecificColumns = JSON.parse(JSON.stringify(courseColumns));
  moduleSpecificColumns.splice(1, 1);
  let moduleSpecificColumnsLocal;

  if (parseInt(profileData?.role_id) === userRoles.SuperAdmin) {
    moduleSpecificColumnsLocal = moduleSpecificColumns;
  } else {
    moduleSpecificColumnsLocal = moduleSpecificColumns.filter(
      (singleColumn) => singleColumn.id !== "action"
    );
    appSidebar.menuList[1].addEntityButton = false;
  }

  const { moduleId } = useParams() || undefined;
  let courseColumnsLocal = JSON.parse(JSON.stringify(courseColumns));
  courseColumnsLocal.splice(1, 1);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [searched, setSearched] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [moduleName, setModuleName] = useState("");
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [rowsLoading, setRowsLoading] = useState(true);
  const [isCourseDetailsOpen, setIsCourseDetailsOpen] = useState(false);
  const [isCourseFormOpen, setIsCourseFormOpen] = useState(false);
  const [isSAdminSignedIn, setIsSAdminSignedIn] = useState(false);
  const [isCourseDetailsFormOpen, setIsCourseDetailsFormOpen] = useState(false);
  const [isCourseQuestionsFormOpen, setIsCourseQuestionsFormOpen] =
    useState(false);
  const courseAdded = useSelector((state) => state.courseForm.courseAdded);
  const courseEdited = useSelector((state) => state.courseForm.courseEdited);

  const requestData = async () => {
    setRowsLoading(true);
    const requestParams = {};

    //handle search
    if (searched !== "") {
      requestParams["search"] = searched;
    }

    //handle sorting here
    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }

    //apply pagination here
    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;

    const axiosInstance = createInstance(true);
    await axiosInstance
      .get(
        courseApi + `module/${moduleId}` + convertParamsToString(requestParams)
      )
      .then((res) => {
        setModuleName(res.data?.data?.module_title);
        const courseRows = res.data.data.rows;

        //adds required fields
        courseRows.forEach((currentRow) => {
          currentRow.id = currentRow.course_id;
          let durationArray = getHrsMinsSecs(currentRow.duration);
          let durationString = "";
          for (let i = 0; i < durationArray.length; i++) {
            let currentNumber = durationArray[i];
            if (currentNumber === 0) durationString += "00";
            else if (currentNumber < 10) durationString += "0" + currentNumber;
            else durationString += currentNumber;
            if (i !== durationArray.length - 1) durationString += ":";
          }
          currentRow.duration = durationString;
        });
        setRows(courseRows);
        setTotalCount(res.data.data.meta.total);
      })
      .catch((err) => {
        setRows([]);
        setTotalCount(0);
        toast.error("Failed to fetch courses", {
          autoClose: autoCloseToastError,
        });
      });
    setRowsLoading(false);
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const getTableHeadCell = (columnInformation) => {
    return (
      <TableCell key={columnInformation.id}>
        <TableSortLabel
          active={orderBy === columnInformation.id}
          direction={orderBy === columnInformation.id ? order : "asc"}
          onClick={() => handleSortRequest(columnInformation.id)}
        >
          {columnInformation.name}
        </TableSortLabel>
      </TableCell>
    );
  };

  const deleteRow = async (id) => {
    try {
      const axiosInstance = createInstance(true);
      const deleteResponse = await axiosInstance.delete(courseApi.concat(id));
      if (deleteResponse.data.success) {
        toast.success("Course deleted successfully");
        if (currentPage === 0 || rows.length > 1) {
          requestData();
        } else {
          setCurrentPage(currentPage - 1);
        }
      } else {
        toast.error("Failed to delete course", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Failed to delete course");
    }
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const setAddCourseForm = () => {
    setIsCourseFormOpen(true);
    dispatch(setCourseAddModuleSpecific(true));
    dispatch(setCourseModuleId(moduleId));
    dispatch(setCourseModuleName(moduleName));
    dispatch(setCourseEdit(false));
    dispatch(setCourseAddOpenToggle());
  };

  const handleCourseClose = () => {
    setIsCourseFormOpen(false);
  };

  const fillDataInForm = (id) => {
    dispatch(setCourseEdit(true));
    dispatch(setCourseId(id));
    dispatch(setCourseEditOpenToggle());
  };

  const navigateToCourseAssginemt = (id) => {
    navigate(`/el/course-assignment/${id}`);
  };

  const fillDataInCourseDetails = (id) => {
    dispatch(setCourseDetailsEdit(true));
    dispatch(setCourseDetailsEditId(id));
    dispatch(setCourseDetailsEditOpenToggle());
    setIsCourseDetailsFormOpen(true);
  };

  const fillDataInCourseQuestions = (id) => {
    dispatch(setCourseQuestionsEdit(true));
    dispatch(setCourseQuestionsEditId(id));
    dispatch(setCourseQuestionsEditOpenToggle());
    setIsCourseQuestionsFormOpen(true);
  };

  const customActions = {
    navigateToCourseAssginemt,
    fillDataInCourseDetails,
    fillDataInCourseQuestions,
  };

  const openDetailsForm = (id) => {
    dispatch(setCourseIdForDetails(id));
    dispatch(setCourseDetailsOpen(true));
    setIsCourseDetailsOpen(true);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  const handleCourseDetailsClose = () => {
    setIsCourseDetailsOpen(false);
  };

  useEffect(() => {
    if (courseAdded) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        requestData();
      }
      dispatch(setCourseAdded(false));
    }
  }, [courseAdded]);

  useEffect(() => {
    if (courseEdited) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        requestData();
      }
      dispatch(setCourseEdited(false));
    }
  }, [courseEdited]);

  useEffect(() => {
    requestData();
  }, [currentPage, rowsPerPage, searched, order, orderBy]);

  useEffect(() => {
    const sessionRole = profileData?.role_id;

    if (parseInt(sessionRole) === userRoles.SuperAdmin) {
      setIsSAdminSignedIn(true);
    }
  }, []);

  return (
    <>
      {isCourseDetailsOpen && (
        <CourseDetails
          isOpenProp={isCourseDetailsOpen}
          handleClose={handleCourseDetailsClose}
        />
      )}
      {isCourseFormOpen && (
        <CourseForm
          isOpenProp={isCourseFormOpen}
          handleClose={handleCourseClose}
        />
      )}
      {isCourseQuestionsFormOpen && (
        <CourseQuestionsForm
          isOpenProp={isCourseQuestionsFormOpen}
          handleClose={() => setIsCourseQuestionsFormOpen(false)}
        />
      )}
      {isCourseDetailsFormOpen && (
        <CourseDetailsForm
          isOpenProp={isCourseDetailsFormOpen}
          handleClose={() => setIsCourseDetailsFormOpen(false)}
        />
      )}
      <Container maxWidth={"xxl"}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container alignItems={"center"}>
            <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'center' }}>
              <span
                onClick={() => {
                  navigate("/el/dashboard");
                }}
                style={{
                  cursor: "pointer",
                  marginRight: "5px",
                  fontWeight: "bold",
                }}
              >
                <IconButton className="primary-text">
                  <ArrowBackIcon />
                </IconButton>
              </span>
              <Typography variant="h6">
                {`Module - ${moduleState?.selectedModule?.title}`}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnGap={!isSmallerScreen ? 2 : 0}
              justifyContent={!isSmallerScreen ? "end" : "space-between"}
              alignItems={"center"}
            >
              <Grid my={1} item xs={12} md={"auto"}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              {isSAdminSignedIn ? (
                <Grid item xs={12} md={"auto"}>
                  <Button
                    variant="outlined"
                    onClick={() => setAddCourseForm()}
                    startIcon={<AddIcon />}
                  >
                    Add Course
                  </Button>
                </Grid>
              ) : null}
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows.length === 0 ? (
            "No courses found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow>
                      {moduleSpecificColumnsLocal.map((assetCol) =>
                        getTableHeadCell(assetCol)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.map((courseRow) => {
                        return (
                          <TableRow key={courseRow.id}>
                            <Tooltip title={courseRow.title}>
                              <TableCell
                                sx={{ maxWidth: "350px" }}
                                className="text-truncate primary-text link-hover"
                                onClick={() => openDetailsForm(courseRow.id)}
                              >
                                {courseRow.title}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {courseRow.duration}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {courseRow.language}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {courseRow.status}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {courseRow.learners_count}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {courseRow.active_learners}
                            </TableCell>
                            {profileData?.role_id === userRoles.SuperAdmin && <TableCell align="right">
                              <AdminCourseActions
                                customActions={customActions}
                                id={courseRow.id}
                                deleteModalData={["Course", courseRow.title]}
                                handleDeleteRow={deleteRow}
                              />
                            </TableCell>}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ElModuleSpecific;
