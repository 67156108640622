const newAccount = {
    accountName: "Account Name",
    addNewAccount: "Add New Account",
    accountType: "Account Type",
    individual: "Individual",
    organization: "Organization",
    businessName: "Business Name",
    accountCategory: "Account Category",
    industry: "Industry",
    status: "Status",
    mobileNumber: "Mobile Number",
    email: "Email",
    gstin: "GSTIN",
    panNumber: "PAN Number",
    occupation: "Occupation",
    dob: "DOB",
    aadharNumber: "Aadhar Number",
    website: "Website",
    description: "Description",
    annualRevenue: "Annual Revenue",
    numberOfEmployees: "No. Of Employees",
    country: "Country",
    state: "State",
    city: "City",
    pincode: "PIN Code",
    streetAddress: "Street Address",
    assignTo: "Assign to",
    firstName: "First Name",
    lastName: "Last Name",
    save: "Save",
    cancel: "Cancel",
};

export { newAccount };
