const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    assetIdForDetails : null, 
    assetDetailsOpen : false
}

export const formSlice = createSlice({
    name: "assetDetails",
    initialState,
    reducers: {
        setAssetIdForDetails: (state, action) => {
            state.assetIdForDetails = action.payload;
        },

        setAssetDetailsOpen: (state, action) => {
            state.assetDetailsOpen = action.payload;
        }

    }
})

export const { setAssetIdForDetails, setAssetDetailsOpen } = formSlice.actions;

export default formSlice.reducer;