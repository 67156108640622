import "./ConsumableAssignmentDetails.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  Drawer,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const ConsumableAssignmentDetails = ({ isConsumableAssignmentsDetailsOpen, handleDrawerClose, isSmallerScreen }) => {
  const consumableAssignmentData = useSelector((state) => state.assignments.selectedAssignmentDetails);;

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: { sm: "90vw", lg: "70vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      open={isConsumableAssignmentsDetailsOpen}
      onClose={() => handleDrawerClose()}
    >
      <Box
        my={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">Consumable Assignment Details</Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleDrawerClose()}
          color="primary"
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" orientation="horizontal" />

      <Box my={3}>

        <Grid
          container
          columnSpacing={{
            sm: 10,
            md: 10,
            lg: 0,
          }}
        >
          <Grid item container xs={12} sm={5.8}>
            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Category
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableAssignmentData?.category}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Make
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableAssignmentData?.make}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Assigned On
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableAssignmentData?.assigned_on}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Location
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableAssignmentData?.location}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={5.8}>
            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Consumable Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableAssignmentData?.asset_name}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Assigned Quantity
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableAssignmentData?.assigned_quantity}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Assigned to
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableAssignmentData?.assigned_to_name}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} my={3}>
            <Divider className="horizontal-line" sx={{ mb: 3 }} />
            {consumableAssignmentData?.description && (
              <Box>
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  color={"textSecondary"}
                  mb={!isSmallerScreen && 3}
                >
                  Description
                </Typography>
                <Typography variant="body2" sx={{ my: 1 }}>
                  {consumableAssignmentData?.description}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      {consumableAssignmentData?.description && (
        <Divider className="horizontal-line" sx={{ mb: { xs: 3, md: 0 } }} />
      )}
    </Drawer>
  );
};

export default ConsumableAssignmentDetails;
