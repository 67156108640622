import React, { useEffect, useState } from "react";
import BdCounts from "../../../components/BdCounts/BdCounts";
import dealColumns from "../../../data/json/dealColumns.json";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import { useDispatch, useSelector } from "react-redux";
import { stages } from "../../../enums/Deal";
import { getDeals, dealApi, deleteDealById } from "../../../redux/actions/bd-actions/deals.action";
import { dealActions } from "../../../redux/reducers/bd-reducers/deals.reducer";
import { DebounceInput } from "react-debounce-input";
import { checkAllSelection } from "../../../utils/Common";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  TablePagination,
  TableCell,
  Tooltip,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Table,
  TableSortLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import DealForm from "../../../components/BdForms/DealForm/DealForm";
import TableMenu from "../../../components/TableMenu/TableMenu";
import getTableCell from "../../../utils/Common/DataTable";

const Deals = () => {
  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));

  const dealColumnsLocal = JSON.parse(JSON.stringify(dealColumns));
  appSidebar.menuList[3].currentPage = true;

  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const initialOrganizationType = {
    dealIndividual: false,
    dealOrganization: false,
  };

  const initialProbability = {
    probability30: false,
    probability60: false,
    probability90: false,
  };

  const [filterButtonAnchorEl, setFilterButtonAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searched, setSearched] = useState("");
  const [organizationType, setOrganizationType] = useState(
    initialOrganizationType
  );
  const [probability, setProbability] = useState(initialProbability);
  const [probabilityFilterMax, setProbabilityFilterMax] = useState(1);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [isDealFormOpen, setIsDealFormOpen] = useState(false);
  const [dealEditProp, setDealEditProp] = useState(false);

  const dealsState = useSelector((state) => state.deals);
  const rowsLoading = dealsState?.dealsLoading;

  const rows = dealsState.deals?.rows;

  const totalCount = dealsState.deals?.meta?.total;

  const initialDealCountCards = [
    {
      type: "main",
      iconImagePath: "/assets/images/deals_big.svg",
      altText: "deals-icon",
      count: 0,
      textLabel: "Total Deals",
    },
    {
      type: "normal",
      count: 0,
      textLabel: stages.qualification,
    },
    {
      type: "normal",
      count: 0,
      textLabel: stages.needsAnalysis,
    },
    {
      type: "normal",
      count: 0,
      textLabel: stages.identifyDecisionMakers,
    },
    {
      type: "normal",
      count: 0,
      textLabel: stages.proposal,
    },
    {
      type: "normal",
      count: 0,
      textLabel: stages.negotiation,
    },
    {
      type: "normal",
      count: 0,
      textLabel: "Over Due",
      color: "red",
    },
  ];

  const [countCards, setCountCards] = useState(initialDealCountCards);


  const getRequestParams = () => {
    let requestParams = {};
    //handle search
    if (searched !== "") {
      requestParams["search"] = searched;
    }

    //apply filter here
    if (!checkAllSelection(organizationType)) {
      if (organizationType.dealIndividual) {
        requestParams["individual"] = true;
      } else if (organizationType.dealOrganization) {
        requestParams["organization"] = true;
      }
    }

    if (probabilityFilterMax !== 1) {
      requestParams["max_probability"] = probabilityFilterMax;
    }

    //handle sorting here
    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }

    //apply pagination here
    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;

    return requestParams
  }

  const requestData = async () => {
    const requestParams = getRequestParams();
    dispatch(getDeals(requestParams));
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const deleteRow = async (id) => {
    dispatch(deleteDealById(id));
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const setAddDealForm = () => {
    setIsDealFormOpen(true);
    setDealEditProp(false);
  };

  const fillDataInForm = (id) => {
    const selectedDealData = rows?.filter(
      (deal) => deal.deal_id === id
    )?.[0];

    dispatch(dealActions.setSelectedDealData(selectedDealData));
    setIsDealFormOpen(true);
    setDealEditProp(true);
  };

  const handleOrganizationFilter = (e) => {
    setOrganizationType({
      ...organizationType,
      [e.target.name]: e.target.checked,
    });
    setCurrentPage(0);
  };

  const handleProbabilityFilter = (e) => {
    setProbability({ ...probability, [e.target.name]: e.target.checked });
    let highestAllowedProbability = 1;
    const localProbability = {
      ...JSON.parse(JSON.stringify(probability)),
      [e.target.name]: e.target.checked,
    };
    if (!checkAllSelection(localProbability)) {
      if (localProbability.probability90) highestAllowedProbability = 0.9;
      else if (localProbability.probability60) highestAllowedProbability = 0.6;
      else highestAllowedProbability = 0.3;
    }

    if (localProbability.probability90) highestAllowedProbability = 0.9;

    if (probabilityFilterMax !== highestAllowedProbability) {
      setProbabilityFilterMax(highestAllowedProbability);
      setCurrentPage(0);
    }
  };

  const clearFilter = () => {
    setOrganizationType(initialOrganizationType);
    setProbability(initialProbability);
    setProbabilityFilterMax(1);
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    setCurrentPage(0);
    setSearched(e.target.value);
  };

  const handleFilterButtonClick = (event) => {
    setFilterButtonAnchorEl(event.currentTarget);
  };

  const handleFilterButtonClose = () => {
    setFilterButtonAnchorEl(null);
  };

  const handleDrawerClose = () => {
    setIsDealFormOpen(false);
    setDealEditProp(false);
    dispatch(dealActions.setSelectedDealData(null))
  };


  useEffect(() => {
    requestData();
  }, [
    currentPage,
    rowsPerPage,
    searched,
    organizationType,
    probabilityFilterMax,
    order,
    orderBy,
  ]);

  useEffect(() => {
    if (Boolean(rows)) {
      const dealRows = rows;

      //sets the cards
      const responseCardData = dealsState?.deals?.cards;
      const currentCountCards = [...initialDealCountCards];
      currentCountCards[0].count = totalCount;
      for (let i = 1; i < currentCountCards.length; i++) {
        for (let j = 0; j < responseCardData.length; j++) {
          if (
            currentCountCards[i].textLabel === responseCardData[j].deal_stage
          ) {
            currentCountCards[i].count = responseCardData[j].count;
            break;
          }
        }
      }
      let overDueDeals = 0;
      const today = new Date();
      for (let i = 0; i < dealRows.length; i++) {
        if (
          dealRows[i].deal_stage !== stages.closedLost &&
          dealRows[i].deal_stage !== stages.closedWon
        ) {
          if (
            dealRows[i].due_date &&
            new Date(dealRows[i].due_date).setHours(23, 59, 59, 999) < today
          ) {
            overDueDeals++;
          }
        }
      }
      const index = initialDealCountCards.findIndex(
        (card) => card.textLabel === "Over Due"
      );
      if (index > -1) {
        currentCountCards[index].count = overDueDeals;
      }

      setCountCards(currentCountCards);
    }
  }, [rows]);

  useEffect(() => {
    setCountCards(initialDealCountCards);
  }, []);



  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isDealFormOpen && <DealForm
          isDealFormOpen={isDealFormOpen}
          editDealProp={dealEditProp}
          handleDrawerClose={handleDrawerClose}
          isSmallerScreen={isSmallerScreen}
        />}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Deals</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnSpacing={2}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Grid my={1} item xs={12} md={"auto"}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} md={"auto"}>
                <Button
                  variant="outlined"
                  onClick={() => setAddDealForm()}
                  startIcon={<AddIcon />}
                >
                  New Deal
                </Button>
              </Grid>
              <Grid item xs={4} md={"auto"} textAlign={"end"}>
                <IconButton onClick={handleFilterButtonClick}>
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-add-button"
                  disableScrollLock
                  anchorEl={filterButtonAnchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(filterButtonAnchorEl)}
                  onClose={handleFilterButtonClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        borderRadius: "10px",
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                >
                  <Box sx={{ p: 1, textAlign: "start" }}>
                    <Typography color={"textSecondary"}>Show Only</Typography>
                  </Box>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="dealIndividual"
                      id="deal-individual"
                      onChange={(e) => handleOrganizationFilter(e)}
                      checked={organizationType.dealIndividual}
                    />
                    <Typography textAlign="center">Individual</Typography>
                  </MenuItem>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="dealOrganization"
                      id="deal-organization"
                      onChange={(e) => handleOrganizationFilter(e)}
                      checked={organizationType.dealOrganization}
                    />
                    <Typography textAlign="center">Organization</Typography>
                  </MenuItem>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  <Box sx={{ p: 1, textAlign: "start" }}>
                    <Typography color={"textSecondary"}>Probability</Typography>
                  </Box>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="probability30"
                      onChange={(e) => handleProbabilityFilter(e)}
                      checked={probability.probability30}
                    />
                    <Typography textAlign="center">30%</Typography>
                  </MenuItem>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="probability60"
                      onChange={(e) => handleProbabilityFilter(e)}
                      checked={probability.probability60}
                    />
                    <Typography textAlign="center">60%</Typography>
                  </MenuItem>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="probability90"
                      onChange={(e) => handleProbabilityFilter(e)}
                      checked={probability.probability90}
                    />
                    <Typography textAlign="center">90%</Typography>
                  </MenuItem>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  <MenuItem
                    onClick={() => clearFilter()}
                    sx={{ pr: 4, py: 1, textAlign: "start" }}
                  >
                    <Typography className="primary-text" fontWeight={"bold"}>
                      Clear Filter
                    </Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          <Box mb={3}>
            <BdCounts cards={countCards} />
          </Box>
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows?.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow>
                      {dealColumnsLocal.map((col) =>
                        getTableCell(col, orderBy, order, handleSortRequest)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Boolean(rows) &&
                      rows?.map((dealRow) => {
                        return (
                          <TableRow key={dealRow.id}>
                            <Tooltip title={dealRow.deal_name}>
                              <TableCell
                                sx={{ maxWidth: "250px" }}
                                className="text-truncate primary-text link-hover"
                                onClick={() => fillDataInForm(dealRow.id)}
                              >
                                {dealRow.deal_name}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {dealRow.deal_stage}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {dealRow.account_name}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {dealRow.probability}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {dealRow.amount}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {dealRow.assigned_to_name}
                            </TableCell>

                            <TableCell align="right">
                              <TableMenu
                                fillDataInForm={fillDataInForm}
                                rowId={dealRow.id}
                                isDeleteOptionEnabled={true}
                                deleteModalData={["Deal", dealRow.deal_name]}
                                handleDeleteRow={() => deleteRow(dealRow.id)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={Boolean(totalCount) ? totalCount : 0}
                rowsPerPage={(rowsPerPage)}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Deals;
