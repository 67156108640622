import React, { useEffect, useState } from "react";
import elSidebarMenus from "../../../data/json/elSidebarMenus.json";
import { DebounceInput } from "react-debounce-input";
import { courseApi } from "../../../constants/apis/EL/Course";
import { convertParamsToString } from "../../../utils/Request";
import { getHrsMinsSecs } from "../../../utils/Time";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import Coursecard from "../../../components/Coursecard/Coursecard";
import { userRoles } from "../../../enums/Auth";
import { userCourseStatuses } from "../../../enums/EL/Course";
import {
  Container,
  Divider,
  Grid,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

const MyCertificates = () => {
  const appSidebar = JSON.parse(JSON.stringify(elSidebarMenus));
  appSidebar.menuList[3].currentPage = true;

  const profileData = useSelector((state) => state.userProfile.profileData);

  if (parseInt(profileData?.role_id) !== userRoles.SuperAdmin) {
    appSidebar.menuList[1].addEntityButton = false;
  }

  const [searched, setSearched] = useState("");
  const [courses, setCourses] = useState([]);
  const [coursesLoading, setCoursesLoading] = useState(true);

  const requestData = async () => {
    setCoursesLoading(true);
    const requestParams = {};

    requestParams.status = userCourseStatuses.completed;

    //handle search
    if (searched !== "") {
      requestParams["search"] = searched;
    }

    const axiosInstance = createInstance(true);
    await axiosInstance
      .get(courseApi + "assignedcourses" + convertParamsToString(requestParams))
      .then((res) => {
        if (res.data.success) {
          const courseCourses = res.data.data.rows;

          //adds required fields
          courseCourses.forEach((currentRow) => {
            currentRow.id = currentRow.course_id;
            let durationArray = getHrsMinsSecs(currentRow.duration);
            currentRow.durationHrs = durationArray[0];
            currentRow.durationMins = durationArray[1];
            delete currentRow.duration;
          });
          setCourses(courseCourses);
        } else {
          toast.error("Failed to fetch courses", {
            autoClose: autoCloseToastError,
          });
        }
      })
      .catch((err) => {
        setCourses([]);
     
        errorHandler(err, "Failed to fetch courses");
      });
    setCoursesLoading(false);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
  };

  useEffect(() => {
    requestData();
  }, [searched]);

  return (
    <>
      <Container maxWidth={"xxl"}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6">Completed Courses</Typography>
            </Grid>
            <Grid item xs={12} sm={5} textAlign={{xs: "start", sm: "end"}}>
              <Box className="search-container">
                <DebounceInput
                  type="text"
                  placeholder="Search"
                  className="search-input"
                  value={searched}
                  onChange={(e) => handleSearch(e)}
                  debounceTimeout={500}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          <Grid container>
            {coursesLoading ? (
              <LoadingSpinner />
            ) : courses.length === 0 ? (
              "No data found..."
            ) : (
              courses.map((course, index) => (
                <Grid key={course.id} item xs={12} sm={6} md={4} lg={3} p={1}>
                  <Coursecard
                    key={`course_${index}`}
                    course={course}
                    viewCertificate={true}
                  />
                </Grid>
              ))
            )}
          </Grid>
        </Paper>
      </Container>
    </>
  );
};

export default MyCertificates;
