const courseImageMaxSize = 400 * 1024;
const courseFileMaxSize = 100 * 1024 * 1024;
const courseImageMaxSizeText = "400KB";
const courseFileMaxSizeText = "100MB";
const allowedCourseFileTypes = {
    'application/pdf': ['.pdf']
};
const maxFilesPerCourse = 10;

const allowedCourseImageTypes = {
    'image/jpeg': [],
    'image/jpg': [],
    'image/png': [],
}

module.exports = { courseImageMaxSize, courseFileMaxSize, courseImageMaxSizeText, courseFileMaxSizeText, allowedCourseFileTypes, allowedCourseImageTypes, maxFilesPerCourse };