import React, { useState, useEffect } from "react";
import reportColumns from "../../../data/json/reportColumns.json";
import reports from "../../../data/json/reports.json";
import { reportsContent as content } from "../../../constants/helperText/Reports";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import { navAppNames } from "../../../constants/AppNames";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { DebounceInput } from "react-debounce-input";
import { errorHandler } from "../../../utils/Request/ReqUtils";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import getTableCell from "../../../utils/Common/DataTable";

export default function Reports() {
  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[6].currentPage = true;

  const navigate = useNavigate();

  const reportColumnsLocal = JSON.parse(JSON.stringify(reportColumns));

  const [searched, setSearched] = useState("");
  const [rows, setRows] = useState([]);
  const [rowsLoading, setRowsLoading] = useState(true);
  const [filteredRows, setFilteredRows] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const requestData = () => {
    setRowsLoading(true);
    try {
      //handle search
      let filteredData = [];
      filteredData = rows.filter((row) => {
        return row.name.toLowerCase().includes(searched.toLowerCase());
      });

      //handle sort
      if (orderBy && order) {
        filteredData = filteredData.sort(getComparator(order, orderBy));
      }

      setFilteredRows(filteredData);
    } catch (err) {
      errorHandler(err, "Failed to get reports");
      setFilteredRows([]);
    }
    setRowsLoading(false);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    } else if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
  };

  useEffect(() => {
    requestData();
  }, [searched, order, orderBy]);

  useEffect(() => {
    setRows(reports);
    setFilteredRows(reports);
    setRowsLoading(false);
  }, []);

  const toAssetManagementReportDetails = (rowID) =>
    navigate(`/bd/report-details/${rowID}`);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Reports</Typography>
            </Grid>
            <Grid item xs={12} md={5} container justifyContent={{ md: "end" }}>
              <Box className="search-container">
                <DebounceInput
                  type="text"
                  placeholder="Search"
                  className="search-input"
                  value={searched}
                  onChange={(e) => handleSearch(e)}
                  debounceTimeout={500}
                />
              </Box>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {rowsLoading ? (
            <LoadingSpinner />
          ) : filteredRows.length === 0 ? (
            "No data found..."
          ) : (
            <>
            <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow>
                      {reportColumnsLocal.map((bdCol) =>
                        getTableCell(bdCol,orderBy,order,handleSortRequest)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredRows &&
                      filteredRows.map((bdReportRow) => {
                        return (
                          <TableRow key={bdReportRow.id}>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "100px" }}
                            >
                              {bdReportRow.id}
                            </TableCell>
                            <Tooltip title={bdReportRow.name}>
                              <TableCell
                                sx={{ maxWidth: "200px" }}
                                className="text-truncate primary-text link-hover"
                                onClick={() =>
                                  toAssetManagementReportDetails(bdReportRow.id)
                                }
                              >
                                {bdReportRow.name}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              sx={{ maxWidth: "500px" }}
                              className="text-truncate"
                            >
                              {bdReportRow.description}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
}
