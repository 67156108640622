import { messages } from "../../constants/validationMessages/AMForms/AddAsset";
import { category, maintenance_frequency, criticality, sensitivity } from "../../enums/AM/Asset";

const Joi = require("joi");

const assetSchema = Joi.object().keys({


  asset_name: Joi.string()
  .min(1)
  .max(255)
  .required()
  .messages({
      "string.max": messages.assetNameDefault,
      "string.empty": messages.assetNameDefault,
  }),

  category: Joi.string()
  .valid(...Object.values(category))
  .required()
  .messages({
      "string.empty": messages.category,
      "any.only": messages.categoryValid
  }),


  vendor_id: Joi.number()
  .integer()
  .min(1)
  .required()
  .messages({
    "number.min": messages.vendorId,
    "number.base": messages.vendorId,
    "any.only": messages.vendorId,
  }),

  service_provider_id: Joi.number()
  .integer()
  .min(1)
  .required()
  .messages({
    "number.min": messages.serviceProviderId,
    "number.base": messages.serviceProviderId,
    "any.only": messages.serviceProviderId,
  }),

  date_of_purchase:
    Joi.date()
        .max('now')
        .empty()
        .required()
        .messages({
            "date.empty": messages.dop,
            "date.base": messages.dopDefault,
            "date.max": messages.dopDefault
        }).required(),


warranty_start_date: Joi.alternatives([
  Joi.date()
      .empty()
      .required()
      .messages({
          "date.base": messages.wsdDefault,
          "date.max": messages.wsdDefault
      }),
]).required(),

warranty_end_date: Joi.alternatives([
  Joi.date()
      .empty()
      .required()
      .messages({
          "date.base": messages.wedDefault,
          "date.max": messages.wedDefault
      }),
]).required(),


purchased_quantity: Joi.number()
.integer()
.min(1)
.required()
.messages({
    "number.min": messages.purchasedQuantityDefault,
    "number.base": messages.purchasedQuantityDefault
}),

available_quantity: Joi.number()
.integer()
.min(1)
.required()
.messages({
    "number.min": messages.availableQuantityDefault,
    "number.base": messages.availableQuantityDefault
}),


make: Joi.string()
.min(1)
.max(255)
.required()
.messages({
    "string.max": messages.makeDefault,
    "string.empty": messages.makeDefault,
}),

model: Joi.string()
.min(1)
.max(255)
.required()
.messages({
    "string.max": messages.modelDefault,
    "string.empty": messages.modelDefault,
}),

description: Joi.string().allow(null).min(0),

maintenance_frequency: Joi.string().allow(null || "").min(0),  

criticality: Joi.string()
.valid(...Object.values(criticality))
.required()
.messages({
    "string.empty": messages.criticality,
    "any.only": messages.criticality
}),     

sensitivity: Joi.string()
.valid(...Object.values(sensitivity))
.required()
.messages({
    "string.empty": messages.sensitivity,
    "any.only": messages.sensitivity
}),      


        
});

export {assetSchema};