const messages ={
    assetNameDefault : "Asset name cannot be empty",
    assetNameMax: "Maximum 255 characters are allowed",

    category: "Category cannot be empty",
    categoryValid: "Select proper category",
    
    vendorId: "Please select vendor",
    vendorValid : "Select the proper vendor Name from the dropdown",

    serviceProviderId: "Please select service provider",

    dopDefault: "Invalid date of purchase",

    dop: "Please enter date of purchase",

    wsdDefault: "Invalid warranty start date",

    wedDefault: "Invalid warranty end date", 

    purchasedQuantityDefault: "Please enter the purchased quantity of asset", 

    availableQuantityDefault: "Please enter the available quantity of asset", 

    makeDefault: "Please enter make of the  asset",

    modelDefault: "Please enter model of the asset", 

    descriptionDefault: "Please enter the description",

    maintenanceFreqDefault: "Please select the maintenance frequency", 

    criticality: "Please select the criticality",

    sensitivity: "Please select the sensitivity"

}

export { messages };
