import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  locations: {},
  locationsLoading: false,
  editLocationLoading: false,
  addLocationLoading: false,
  deleteLocationLoading: false,
  selectedLocation: null,
  message: null,
  error: null,
};

const locationsSlice = createSlice({
  name: "locations",
  initialState,
  reducers: {
    getLocationsRequest(state) {
      state.locationsLoading = true;
    },
    getLocationsSuccess(state, action) {
      state.locationsLoading = false;
      state.locations = action.payload;
      state.locations.rows.forEach((row) => {
        row.id = row.location_id;
      });
    },
    getLocationsFail(state) {
      state.locationsLoading = false;
    },

    editLocationRequest(state) {
      state.editLocationLoading = true;
    },
    editLocationSuccess(state, action) {
      const editedLocation = action.payload;
      editedLocation.id = editedLocation.location_id;
      state.locations.rows = state.locations.rows.map((row) => {
        if (row.id === editedLocation.id) {
          return editedLocation;
        }
        return row;
      })
      state.editLocationLoading = false;
    },
    editLocationFail(state) {
      state.editLocationLoading = false;
    },
    addLocationRequest(state) {
      state.addLocationLoading = true;
    },
    addLocationSuccess(state, action) {
      const addedLocation = action.payload;
      addedLocation.id = addedLocation.location_id;
      state.locations.rows = [addedLocation, ...state.locations?.rows]
      state.locations.meta.total++;
      state.addLocationLoading = false;
    },
    addLocationFail(state) {
      state.addLocationLoading = false;
    },
    setSelectedLocation(state, action) {
        const id = action.payload;
        state.selectedLocation = state?.locations?.rows?.find((row) => row.id === id) || {};
    },
    deleteLocationRequest(state) {
      state.deleteLocationLoading = true;
    },
    deleteLocationSuccess(state, action) {
      const deletedLocationId = action.payload;
      state.locations.rows = state.locations.rows.filter((row) => {
        return row.id !== deletedLocationId;
      })
      state.locations.meta.total--;
      state.deleteLocationLoading = false;
    },
    deleteLocationFail(state) {
      state.deleteLocationLoading = false;
    },
    clearError(state) {
      state.error = null;
    },
    clearMessage(state) {
      state.message = null;
    },
  },
});

export const locationReducer = locationsSlice.reducer;

export const locationActions = locationsSlice.actions;
