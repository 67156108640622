const priorities = Object.freeze({
    High: "High",
    Medium: "Medium",
    Low: "Low",
});

const types = Object.freeze({
    Marketing: "Marketing",
    Support: "Support",
    Sales: "Sales"
});

const statuses = Object.freeze({
    NotStarted: "Not Started",
    InProgress: "In Progress",
    Completed: "Completed",
});

module.exports = { priorities, types, statuses };
