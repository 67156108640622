import { createSlice } from "@reduxjs/toolkit";
import { getContactCardsBasedOnRows } from "../../../utils/BD/contactUtils";

const initialState = {
  contacts: {},
  contactsLoading: false,
  keyContacts: {},
  addContactLoading: false,
  keyContactsLoading: false,
  accountSpecificContacts: {},
  accountSpecificContactsLoading: false,
  editContactLoading: false,
  deleteContactLoading: false,
  selectedContactData: null,
  message: null,
  error: null,
};

const contactSlice = createSlice({
  name: "contacts",

  initialState,
  reducers: {
    getKeyContactsRequest(state) {
      state.keyContactsLoading = true;
    },

    getKeyContactsSuccess(state, action) {
      state.keyContactsLoading = false;
      state.keyContacts = action.payload;
      state.keyContacts.rows.forEach((row) => {
        row.id = row.contact_id;
      });
    },

    getKeyContactsFail(state) {
      state.keyContactsLoading = false;
    },

    getAccountSpecificContactsRequest(state) {
      state.accountSpecificContactsLoading = true;
    },
    getAccountSpecificContactsSuccess(state, action) {
      state.accountSpecificContacts = action.payload;
      state.accountSpecificContacts.rows.forEach((row) => {
        row.id = row.contact_id;
      });
      state.accountSpecificContactsLoading = false;
    },
    getAccountSpecificContactsFail(state) {
      state.accountSpecificContactsLoading = false;
    },

    editKeyContactsSuccess(state, action) {
      if (Boolean(state.keyContacts?.rows)) {
        const editedContact = action.payload;
        editedContact.id = editedContact.contact_id;
        state.keyContacts.rows = state.keyContacts.rows.map((row) => {
          if (row.id === editedContact.id) {
            return { ...editedContact, account_name: editedContact?.account_name };
          }
          return row;
        });
      }
    },

    getContactsRequest(state) {
      state.contactsLoading = true;
    },

    getContactsSuccess(state, action) {
      state.contactsLoading = false;
      state.contacts = action.payload;
      state.contacts.rows.forEach((row) => {
        row.id = row.contact_id;
      });
    },

    getContactsFail(state) {
      state.contactsLoading = false;
    },

    setSelectedContactData(state, action) {
      state.selectedContactData = action.payload;
    },

    addContactRequest(state) {
      state.addContactLoading = true;
    },

    addContactSuccess(state, action) {
      const addedContact = action.payload.addedContact;
      const accountSpecific = action.payload.accountSpecific;

      if (accountSpecific) {
        if (state.accountSpecificContacts?.rows) {
          state.accountSpecificContacts.rows = [addedContact, ...state.accountSpecificContacts.rows,];
        }
        if (state.accountSpecificContacts?.meta?.total) {
          state.accountSpecificContacts.meta.total++;
        }
      } else {
        state.contacts.rows = [addedContact, ...state.contacts.rows,];
        state.contacts.meta.total++;
        state.contacts.cards = getContactCardsBasedOnRows(state.contacts.rows);
      }

      state.addContactLoading = false;
    },

    addContactFail(state) {
      state.addContactLoading = false;
    },

    editContactRequest(state) {
      state.editContactLoading = true;
    },

    editContactSuccess(state, action) {
      const editedContact = action.payload.editedContact;
      editedContact.id = editedContact.contact_id;
      const accountSpecific = action.payload.accountSpecific;

      if (accountSpecific) {
        state.accountSpecificContacts.rows = state.accountSpecificContacts.rows.map((contact) => {
          if (contact.id === editedContact.id) {
            return editedContact;
          }
          return contact;
        })
      } else {
        if (state.contacts.rows) {
          state.contacts.rows = state.contacts.rows.map((contact) => {
            if (contact.id === editedContact.id) {
              return editedContact;
            }
            return contact;
          });
          state.contacts.cards = getContactCardsBasedOnRows(state.contacts.rows);
        }
      }

      state.editContactLoading = false;
    },

    editContactFail(state) {
      state.editContactLoading = false;
    },

    deleteContactRequest(state) {
      state.deleteContactLoading = true;
    },

    deleteContactSuccess(state, action) {
      state.deleteContactLoading = false;
      const deletedContactId = action.payload.id;
      const accountSpecific = action.payload.accountSpecific;
      if (accountSpecific) {
        state.accountSpecificContacts.rows = state.accountSpecificContacts.rows.filter(contact => contact.id !== deletedContactId);
        state.accountSpecificContacts.meta.total -= 1;
      } else {
        state.contacts.rows = state.contacts.rows.filter(contact => contact.id !== deletedContactId);
        state.contacts.meta.total -= 1;
        state.contacts.cards = getContactCardsBasedOnRows(state.contacts.rows);
      }
    },

    deleteContactFail(state) {
      state.deleteContactLoading = false;
    },

    clearError(state) {
      state.error = null;
    },

    clearMessage(state) {
      state.message = null;
    },
  },
});

export const contactReducer = contactSlice.reducer;

export const contactActions = contactSlice.actions;