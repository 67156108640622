import { useDispatch, useSelector } from "react-redux";
import assetSideBarMenus from "../../../data/json/assetSideBarMenus.json";
import { createSearchParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { dashboardAPI } from "../../../constants/apis/AM/Dashboard";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import BdCounts from "../../../components/BdCounts/BdCounts";
import assetMaintenanceColumns from "../../../data/json/assetMaintenanceColumn.json";
import AssetDetails from "../../../components/EntityDetails/AssetDetails/AssetDetails";
import ConsumableDetails from "../../../components/EntityDetails/ConsumableDetails/ConsumableDetails";
import assetDashboardColumns from "../../../data/json/assetDashboardColumns.json";
import consumableDashboardColumns from "../../../data/json/consumableDashboardColumns.json";
import lowQuantityConsumableDashboardColumns from "../../../data/json/lowQuantityConsumableDashboardColumns.json";

import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  Tooltip,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  TableCell,
  TableRow,
  TableBody,
  TableSortLabel,
  TableHead,
  TableContainer,
  Table,
} from "@mui/material";
import MuiTooltip from "@mui/material/Tooltip"
import { getAssets } from "../../../redux/actions/am-actions/assets.actions";
import { getAssetsOnMaintenance } from "../../../redux/actions/am-actions/assets.actions";
import { getConsumables, getLowQuantityConsumables } from "../../../redux/actions/am-actions/consumables.actions";
import { assetActions } from "../../../redux/reducers/am-reducers/assets.reducer";
import { consumableActions } from "../../../redux/reducers/am-reducers/consumables.reducer";
Chart.register(
  LinearScale,
  Tooltip,
  ArcElement,
  CategoryScale,
  BarElement,
  ChartDataLabels,
  Legend
);

const AmDashboard = () => {
  const appSidebar = JSON.parse(JSON.stringify(assetSideBarMenus));
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
  appSidebar.menuList[0].currentPage = true;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialAssetCountCards = [
    {
      type: "main",
      iconImagePath: "/assets/images/task-asset-view.svg",
      altText: "eldashboard-icon",
      count: 0,
      textLabel: "Total Assets",
    },
    {
      type: "normal",
      count: 0,
      textLabel: "Assigned Assets",
    },
    {
      type: "normal",
      count: 0,
      textLabel: "Total Consumables",
    },
    {
      type: "normal",
      count: 0,
      textLabel: "Assigned Consumables",
    },
  ];

  const initialTableRowsCount = {
    assetRowsCount: 0,
    assetOnMaintenanceRowsCount: 0,
    consumableRowsCount: 0,
    lowQuantityConsumableRowsCount: 0,
  };

  const [countCards, setCountCards] = useState(initialAssetCountCards);
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [tableRowsCount, setTableRowsCount] = useState(initialTableRowsCount);
  const [assetDetailsOpen, setAssetDetailsOpen] = useState(false)
  const [consumableDetailsOpen, setConsumableDetailsOpen] = useState(false)


  const assetsState = useSelector(state => state.assets);
  const assetRows = assetsState.assets?.rows;
  const assetsLoading = assetsState.assetsLoading;
  const assetMaintenanceRows = assetsState.assetsOnMaintenance?.rows;
  const assetsOnMaintenanceLoading = assetsState.assetsOnMaintenanceLoading;

  const consumableStates = useSelector(state => state.consumables);
  const consumableRows = consumableStates.consumables?.rows;
  const consumablesLoading = consumableStates.consumablesLoading;
  const lowQuantityConsumableRows = consumableStates.lowQuantityConsumables?.rows;
  const lowQuantityConsumableRowsLoading = consumableStates.lowQuantityConsumablesLoading;

  let params = createSearchParams({
    fromDashboard: true,
  });

  const fetchCountCards = async () => {
    const axiosInstance = createInstance(true);
    const res = await axiosInstance.get(dashboardAPI);
    try {
      const data = res.data.data;

      setCountCards([
        { ...initialAssetCountCards[0], count: data.totalAssetsCount || 0 },
        { ...initialAssetCountCards[1], count: data.assignedAssetsCount || 0 },
        {
          ...initialAssetCountCards[2],
          count: data.totalConsumablesCount || 0,
        },
        {
          ...initialAssetCountCards[3],
          count: data.assignedConsumablesCount || 0,
        },
      ]);
    } catch (err) {
      errorHandler(err, "Failed to fetch card counts");
    }
  };

  const fetchAssets = async () => {
    dispatch(getAssets(params));
  };

  const fetchAssetsOnMaintenance = async () => {
    dispatch(getAssetsOnMaintenance());
  };

  const fetchConsumables = async () => {
    dispatch(getConsumables(params))
  };

  const fetchLowQuantityConsumables = async () => {
    dispatch(getLowQuantityConsumables(params))
  };

  const requestData = async () => {
    setDashboardLoading(true);
    fetchCountCards();
    fetchAssets();
    fetchAssetsOnMaintenance();
    fetchConsumables();
    fetchLowQuantityConsumables();
  };

  const openAssetDetailsForm = (id) => {
    const selectedAssetData = assetRows?.filter(
      (asset) => asset.asset_id === id
    )?.[0];
    dispatch(assetActions.setSelectedAssetData(selectedAssetData));
    setAssetDetailsOpen(true);
  };

  const openAssetOnMaintenanceDetailsForm = (id) => {
    const selectedAssetData = assetMaintenanceRows?.filter(
      (asset) => asset.asset_id === id
    )?.[0];
    dispatch(assetActions.setSelectedAssetData(selectedAssetData));
    setAssetDetailsOpen(true);
  };

  const closeAssetDetails = () => {
    dispatch(assetActions.setSelectedAssetData(null));
    setAssetDetailsOpen(false);
  }

  const openConsumableDetailsForm = (id) => {
    const selectedConsumableData = consumableRows?.filter(
      (consumable) => consumable.consumable_id === id
    )?.[0];
    dispatch(consumableActions.setSelectedConsumableData(selectedConsumableData));
    setConsumableDetailsOpen(true);
  };

  const openLowQuantityConsumableDetailsForm = (id) => {
    const selectedConsumableData = lowQuantityConsumableRows?.filter(
      (consumable) => consumable.consumable_id === id
    )?.[0];
    dispatch(consumableActions.setSelectedConsumableData(selectedConsumableData));
    setConsumableDetailsOpen(true);
  };

  const closeConsumableDetails = () => {
    dispatch(consumableActions.setSelectedConsumableData(null));
    setConsumableDetailsOpen(false);
  }

  useEffect(() => {
    if (!(assetsLoading || assetsOnMaintenanceLoading || consumablesLoading || lowQuantityConsumableRowsLoading)) {
      setTableRowsCount((prev) => ({
        ...prev,
        assetRowsCount: assetsState.assets?.meta?.total || 0,
        assetOnMaintenanceRowsCount: assetsState.assetsOnMaintenance?.meta?.total || 0,
        consumableRowsCount: consumableStates?.consumable?.meta?.total || 0,
        lowQuantityConsumableRowsCount: consumableStates?.lowQuantityConsumables?.meta?.total || 0,
      }));
      setDashboardLoading(false);
    }


  }, [assetsLoading, assetsOnMaintenanceLoading, consumablesLoading, lowQuantityConsumableRowsLoading])


  useEffect(() => {
    requestData();
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {assetDetailsOpen && <AssetDetails assetDetailsOpen={assetDetailsOpen} handleDrawerClose={closeAssetDetails} />}
        {consumableDetailsOpen && <ConsumableDetails isConsumableDetailsOpen={consumableDetailsOpen} handleDetailsClose={closeConsumableDetails} isSmallerScreen={isSmallerScreen} />}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Asset System</Typography>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {dashboardLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Box mb={3}>
                <BdCounts cards={countCards} />
              </Box>
              <Grid container columnSpacing={8} rowSpacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Assets
                  </Typography>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  {assetRows?.length === 0 ? (
                    "No data found..."
                  ) : (
                    <>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead className="stat-card-bg">
                            <TableRow key={`asset_head`}>
                              {assetDashboardColumns &&
                                assetDashboardColumns.map((assetCol) => (
                                  <TableCell key={assetCol.id}>
                                    {assetCol.name}
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {assetRows &&
                              assetRows.map((assetRow, i) => (
                                <TableRow key={`asset_${i}`}>
                                  <MuiTooltip title={assetRow.asset_name}>
                                    <TableCell
                                      sx={{ maxWidth: "250px" }}
                                      className="text-truncate primary-text link-hover"

                                      onClick={() => openAssetDetailsForm(assetRow.id)}
                                    >
                                      {assetRow.asset_name}
                                    </TableCell>
                                  </MuiTooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {assetRow.available_quantity}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {assetRow.date_of_purchase}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {tableRowsCount.assetRowsCount > 10 ? (
                    <Button
                      variant="text"
                      onClick={() => navigate("/am/assets")}
                    >
                      view more...
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Assets on maintenance
                  </Typography>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  {assetMaintenanceRows?.length === 0 ? (
                    "No data found..."
                  ) : (
                    <>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead className="stat-card-bg">
                            <TableRow key={'asset_on_maintenance_head'}>
                              {assetMaintenanceColumns &&
                                assetMaintenanceColumns.map((assetMaintenanceCol) => (
                                  <TableCell key={assetMaintenanceCol.id}>
                                    {assetMaintenanceCol.name}
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {assetMaintenanceRows &&
                              assetMaintenanceRows.map((assetMaintenanceRow, i) => (
                                <TableRow key={`asset_on_maintenance${i}`}>
                                  <MuiTooltip title={assetMaintenanceRow.asset_name}>
                                    <TableCell
                                      sx={{ maxWidth: "250px" }}
                                      className="text-truncate primary-text link-hover"
                                      onClick={() => openAssetOnMaintenanceDetailsForm(assetMaintenanceRow.id)}
                                    >
                                      {assetMaintenanceRow.asset_name}
                                    </TableCell>
                                  </MuiTooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {assetMaintenanceRow.maintenance_date}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {assetMaintenanceRow.assigned_to_name}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {tableRowsCount.assetOnMaintenanceRowsCount > 10 ? (
                    <Button
                      variant="text"
                      onClick={() => navigate("/am/assets")}
                    >
                      view more...
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Consumables
                  </Typography>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  {consumableRows?.length === 0 ? (
                    "No data found..."
                  ) : (
                    <>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead className="stat-card-bg">
                            <TableRow key={`consumable_head`}>
                              {consumableDashboardColumns &&
                                consumableDashboardColumns.map((consumableCol) => (
                                  <TableCell key={consumableCol.id}>
                                    {consumableCol.name}
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {consumableRows &&
                              consumableRows.map((consumableRow, i) => (
                                <TableRow key={`consumable_${i}`}>
                                  <MuiTooltip title={consumableRow.consumable_name}>
                                    <TableCell
                                      sx={{ maxWidth: "250px" }}
                                      className="text-truncate primary-text link-hover"
                                      onClick={() => openConsumableDetailsForm(consumableRow.id)}
                                    >
                                      {consumableRow.consumable_name}
                                    </TableCell>
                                  </MuiTooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {consumableRow.in_stock}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {consumableRow.date_of_purchase}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {tableRowsCount.consumableRowsCount > 10 ? (
                    <Button
                      variant="text"
                      onClick={() => navigate("/am/consumables")}
                    >
                      view more...
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Low quantity consumables
                  </Typography>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  {lowQuantityConsumableRows?.length === 0 ? (
                    "No data found..."
                  ) : (
                    <>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead className="stat-card-bg">
                            <TableRow key={'low_quantity_consumables'}>
                              {lowQuantityConsumableDashboardColumns &&
                                lowQuantityConsumableDashboardColumns.map((lowQuantityConsumableCol) => (
                                  <TableCell key={lowQuantityConsumableCol.id}>
                                    {lowQuantityConsumableCol.name}
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {lowQuantityConsumableRows &&
                              lowQuantityConsumableRows.map((lowQuantityConsumableRow, i) => (
                                <TableRow key={`low_quantity_consumables_${i}`}>
                                  <MuiTooltip title={lowQuantityConsumableRow.consumable_name}>
                                    <TableCell
                                      sx={{ maxWidth: "250px" }}
                                      className="text-truncate primary-text link-hover"
                                      onClick={() => openLowQuantityConsumableDetailsForm(lowQuantityConsumableRow.id)}
                                    >
                                      {lowQuantityConsumableRow.consumable_name}
                                    </TableCell>
                                  </MuiTooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {lowQuantityConsumableRow.in_stock}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {lowQuantityConsumableRow.date_of_purchase}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {tableRowsCount.lowQuantityConsumableRowsCount > 10 ? (
                    <Button
                      variant="text"
                      onClick={() => navigate("/am/consumables")}
                    >
                      view more...
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default AmDashboard;
