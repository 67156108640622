import React, { useState, useEffect, memo } from "react";
import "./CourseForm.scss";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import "../../../styles/BD/entityForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { courseApi } from "../../../constants/apis/EL/Course";
import { elDashboardApi } from "../../../constants/apis/EL/ElDashboard";
import { languages } from "../../../enums/EL/Course";
import { setCourseAdded } from "../../../features/EL/courseFormSlice";
import { searchOptionDebounceTime } from "../../../constants/Common";
import {
  asyncSelectCustomStyles,
  autoCloseToastError,
  minCharsToLoadOptions,
} from "../../../constants/Common";
import { toast } from "react-toastify";
import {
  setModuleOptions,
  setModuleOptionsLoaded,
} from "../../../features/optionSlice";
import { useDropzone } from "react-dropzone";
import {
  courseFirstStepSchema,
  singleQuestionSchema,
} from "../../../validations/ElForms/Course";
import { elPagesPaths } from "../../../constants/elPagesPaths";
import { useLocation } from "react-router-dom";
import { getHrsMinsSecs } from "../../../utils/Time";
import { getOriginalFileName } from "../../../utils/File";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import {
  allowedCourseFileTypes,
  allowedCourseImageTypes,
  courseFileMaxSize,
  courseFileMaxSizeText,
  courseImageMaxSize,
  courseImageMaxSizeText,
  maxFilesPerCourse
} from "../../../constants/CourseForm";
import RequestInProgressModal from "../../RequestInProgressModal/RequestInProgressModal";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Input,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import { useFormik } from "formik";
import {
  courseDetailsSchema,
  questionSchema,
} from "../../../validations/schemas/e-learning/course.schema.validator";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { DropzoneArea } from "mui-file-dropzone";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Asterisk from "../../Misc/Asterisk";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";

const CourseForm = ({ isOpenProp, handleClose }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPagePath = location.pathname;

  const courseEdit = useSelector((state) => state.courseForm.courseEdit);
  const courseId = useSelector((state) => state.courseForm.courseId);
  const courseAddModuleSpecific = useSelector(
    (state) => state.courseForm.courseAddModuleSpecific
  );
  const courseModuleId = useSelector(
    (state) => state.courseForm.courseModuleId
  );
  const courseModuleName = useSelector(
    (state) => state.courseForm.courseModuleName
  );

  const courseEditOpenToggle = useSelector(
    (state) => state.courseForm.courseEditOpenToggle
  );
  const courseAddOpenToggle = useSelector(
    (state) => state.courseForm.courseAddOpenToggle
  );
  const moduleOptions = useSelector(
    (state) => state.optionsForAsyncSelect.moduleOptions
  );
  const moduleOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.moduleOptionsLoaded
  );

  const initialCourseData = {
    title: "",
    description: "",
    module_id: "",
    language: "",
    duration: 0,
    passing_percentage: 1,
    course_file_names: [],
    course_image_name: "",
  };

  const singleQuestionData = {
    question_text: "",
    marks: "",
    options: [],
  };

  const initialOptionsData = [
    {
      option_text: "",
      is_correct: false,
    },
    {
      option_text: "",
      is_correct: false,
    },
    {
      option_text: "",
      is_correct: false,
    },
    {
      option_text: "",
      is_correct: false,
    },
  ];

  const optionNames = [
    "optionText1",
    "optionText2",
    "optionText3",
    "optionText4",
  ];

  const initialDurationString = "00:00";

  const [courseData, setCourseData] = useState(initialCourseData);
  const [courseFormErrors, setCourseFormErrors] = useState({});

  const [questions, setQuestions] = useState([]);
  const [singleQuestion, setSingleQuestion] = useState(singleQuestionData);
  const [options, setOptions] = useState(initialOptionsData);

  const [questionFormErrors, setQuestionFormErrors] = useState({});
  const [module, setModule] = useState(null);
  const [durationString, setDurationString] = useState(initialDurationString);
  const [previewDuration, setPreviewDuration] = useState([0, 0]);
  const [uploadImageProgress, setUploadImageProgress] = useState(0);
  const [uploadCourseFileProgress, setUploadCourseFileProgress] = useState(0);
  const [uploadingImageFile, setUploadingImageFile] = useState(false);
  const [uploadingCourseFile, setUploadingCourseFile] = useState(false);
  const [totalMarks, setTotalMarks] = useState(0);
  const [firstStepLocked, setFirstStepLocked] = useState(false);
  const [secondStepLocked, setSecondStepLocked] = useState(false);

  const [displayProcceedModal, setDisplayProcceedModal] = useState(false);
  const [proceedMessage, setProceedMessage] = useState("");

  const [courseAddInProgress, setCourseAddInProgress] = useState(false);

  const [allFormsData, setAllFormsData] = useState({});
  const [courseFileNames, setCourseFileNames] = useState([]);
  const [courseImageName, setCourseImageName] = useState("");
  const [duration, setDuration] = useState({
    hours: 0,
    minutes: 0,
  });
  const firstForm = useFormik({
    initialValues: {
      title: "",
      description: "",
      module_id: null,
      language: languages.english,
      hours: 0,
      minutes: 0,
      passing_percentage: 100,
      course_image_name: "",
      course_file_names: [],
    },
    validationSchema: courseDetailsSchema,
    onSubmit: (data) => {
      const firstFormData = { ...data };
      const durationInSeconds =
        3600 * firstFormData.hours + 60 * firstFormData.minutes;
      if (durationInSeconds < 60) {
        return;
      }
      delete firstFormData.hours;
      delete firstFormData.minutes;
      firstFormData.duration = durationInSeconds;
      setAllFormsData((prevData) => ({
        ...prevData,
        firstFormData,
      }));

      setActiveStep(activeStep + 1);
    },
  });

  const secondForm = useFormik({
    initialValues: {
      question_text: "",
      marks: 1,
      options: initialOptionsData,
      option_1: "",
      option_2: "",
      option_3: "",
      option_4: "",
    },
    validationSchema: questionSchema,
    onSubmit: (secondFormData) => {
      const isTicked = secondFormData.options.filter((option) => {
        return option.is_correct;
      });
      if (isTicked.length === 0) {
        toast.warn("Select correct option");
      } else {
        setQuestions((prev) => [...prev, secondFormData]);
        toast.success("Question added");
        secondForm.resetForm();
      }
    },
  });

  const handleQuestionsStepSubmit = () => {
    if (questions.length === 0) {
      toast.error("At least one question is required");
    } else {
      setAllFormsData((prev) => ({
        ...prev,
        secondFormData: { questions },
      }));
      setActiveStep(activeStep + 1);
    }
  };

  const handleOptionChange = (index, value) => {
    const updatedOptions = secondForm.values.options.map((option, i) =>
      i === index ? { ...option, option_text: value } : option
    );
    secondForm.setFieldValue("options", updatedOptions);
  };

  const handleCorrectOptionChange = (index) => {
    const updatedOptions = secondForm.values.options.map((option, i) =>
      i === index
        ? { ...option, is_correct: true }
        : { ...option, is_correct: false }
    );
    secondForm.setFieldValue("options", updatedOptions);
  };

  const handleQuestionDelete = (questionIndexToBeDeleted) => {
    const filteredQuestions = questions.filter(
      (question, index) => questionIndexToBeDeleted !== index
    );
    setQuestions(filteredQuestions);
    toast.success("Question deleted");
  };

  const handleAddCourse = async () => {
    setCourseAddInProgress((_) => true);
    try {
      const payloadCourseData = {
        ...allFormsData.firstFormData,
        ...allFormsData.secondFormData,
      };
      const axiosInstance = createInstance(true);
      let courseAddResponse = await axiosInstance.post(
        courseApi,
        payloadCourseData
      );
      if (courseAddResponse.data.success) {
        toast.success("Course added successfully");

        //Clears the form
        setCourseData(initialCourseData);
        setQuestions([]);
        clearQuestionForm();

        //Notifies that refetch new data after adding new course
        if (
          currentPagePath === elPagesPaths.coursePagePath ||
          courseAddModuleSpecific ||
          currentPagePath === elPagesPaths.elMainPagePath ||
          currentPagePath === elPagesPaths.elDashboardPagePath
        ) {
          dispatch(setCourseAdded(true));
        }
        setCourseAddInProgress((_) => false);
        handleDrawerClose();
      } else {
        throw new Error("Failed to add course");
      }
    } catch (error) {
      setCourseAddInProgress((_) => false);
      errorHandler(error, "Failed to add course");
    }
    setCourseAddInProgress((_) => false);
  };

  const handleDuration = (e) => {
    setDuration((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
    duration = 3600 * duration[0] + 60 * duration[1];
    firstForm.setFieldValue("duration", duration);
  };

  useEffect(() => { }, [allFormsData]);

  const fetchModuleOptions = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      const response = await axiosInstance.get(
        elDashboardApi
          .concat("/moduleoptions")
          .concat(inputValue ? "?search=".concat(inputValue) : "")
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => ({
          value: item.module_id,
          label: item.title,
        }));
        return formattedOptions;
      } else {
        toast.error("Error while fetching module options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "error while fetching module options");
    }
    return [];
  };

  const loadModuleOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchModuleOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        callback([]);
      });
  };

  const closeCourseForm = () => {
    //closes form after course add is successfull
    const closeCourseFormButton = document.getElementById("closecourseform");
    closeCourseFormButton.click();
  };

  const hideProceedModal = () => {
    setDisplayProcceedModal((_) => false);
  };

  const lockSecondStep = () => {
    setSecondStepLocked((prev) => true);
  };

  const handleCourseForm = (e) => {
    e.preventDefault();
    setCourseFormErrors({ ...courseFormErrors, [e.target.name]: "" });
    if (e.target.name === "duration") {
      setDurationString(e.target.value);
      let duration = e.target.value.split(":");
      duration = 3600 * duration[0] + 60 * duration[1];
      setCourseData({ ...courseData, duration: duration });
      return;
    }
    setCourseData({ ...courseData, [e.target.name]: e.target.value });
  };
  const handleCourseNext = async (e) => {
    e.preventDefault();

    if (!firstStepLocked) {
      const validation = courseFirstStepSchema.validate(courseData, {
        abortEarly: false,
      });
      if (validation.error) {
        setCourseFormErrors({});
        const errorDetails = validation.error.details;
        let currentErrors = {};
        errorDetails.forEach((error) => {
          currentErrors[error.path[0]] = error.message;
        });
        setCourseFormErrors(currentErrors);
      } else {
        setFirstStepLocked(true);
      }
    } else if (!secondStepLocked) {
      if (
        JSON.stringify(singleQuestion) === JSON.stringify(singleQuestionData) &&
        JSON.stringify(options) === JSON.stringify(initialOptionsData)
      ) {
        setQuestionFormErrors({});
        if (questions.length === 0) {
          toast.error("Add at least 1 question", {
            autoClose: autoCloseToastError,
          });
        } else {
          setCourseData({ ...courseData, questions });
          const totalQuestions = questions.length;
          setProceedMessage(
            (_) =>
              `You have added ${totalQuestions} questions. Proceed to preview ?`
          );
          setDisplayProcceedModal((_) => true);
        }
      } else {
        const { validation, nextSingleQuestion } = validateSingleQuestion();
        if (!validation.error) {
          const allQuestions = [...questions];
          allQuestions.push(nextSingleQuestion);
          setCourseData({ ...courseData, questions: [...allQuestions] });
          setQuestions([...questions, nextSingleQuestion]);
          const totalQuestions = allQuestions.length;
          setProceedMessage(
            (_) =>
              `You have added ${totalQuestions} questions. Proceed to preview ?`
          );
          setDisplayProcceedModal((_) => true);
        }
      }
    } else {
      setCourseAddInProgress((_) => true);
      try {
        const payloadCourseData = { ...courseData };
        const axiosInstance = createInstance(true);
        let courseAddResponse = await axiosInstance.post(
          courseApi,
          payloadCourseData
        );
        if (courseAddResponse.data.success) {
          toast.success("Course added successfully");

          //Clears the form
          setCourseData(initialCourseData);
          setQuestions([]);
          clearQuestionForm();

          //Notifies that refetch new data after adding new course
          if (
            currentPagePath === elPagesPaths.coursePagePath ||
            courseAddModuleSpecific ||
            currentPagePath === elPagesPaths.elMainPagePath ||
            currentPagePath === elPagesPaths.elDashboardPagePath
          ) {
            dispatch(setCourseAdded(true));
          }
          setCourseAddInProgress((_) => false);
          closeCourseForm();
        } else {
          throw new Error("Failed to add course");
        }
      } catch (error) {
        setCourseAddInProgress((_) => false);
        errorHandler(error, "Failed to add course");
      }
      setCourseAddInProgress((_) => false);
    }
  };

  const clearQuestionForm = () => {
    setQuestionFormErrors({});
    setSingleQuestion({ ...singleQuestionData });
    setOptions([...initialOptionsData]);
  };

  const validateSingleQuestion = () => {
    const nextSingleQuestion = {
      ...singleQuestion,
    };
    nextSingleQuestion.options = [...options];
    const validation = singleQuestionSchema.validate(nextSingleQuestion, {
      abortEarly: false,
    });

    if (validation.error) {
      setQuestionFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        if (error.path[0] === "options" && error.path.length > 1) {
          const errorOptionIndex = error.path[1];
          currentErrors[optionNames[errorOptionIndex]] = error.message;
        } else {
          currentErrors[error.path[0]] = error.message;
        }
      });
      setQuestionFormErrors(currentErrors);
    } else {
      setQuestionFormErrors({});
      setSingleQuestion({ ...singleQuestionData });
      setOptions([...initialOptionsData]);
    }

    return { validation, nextSingleQuestion };
  };

  const calCulateTotalMarks = () => {
    let currentTotal = 0;
    for (let i = 0; i < questions.length; i++) {
      currentTotal += parseInt(questions[i].marks);
    }
    setTotalMarks(currentTotal);
  };

  const handleModuleFocus = async () => {
    if (!moduleOptionsLoaded) {
      const fetchedOptions = await fetchModuleOptions("");
      dispatch(setModuleOptionsLoaded(true));
      dispatch(setModuleOptions(fetchedOptions));
    }
  };

  useEffect(() => {
    setCourseFormErrors({});

    setCourseData({ ...initialCourseData });
    setSingleQuestion({ ...singleQuestionData });
    setOptions([...initialOptionsData]);
    setQuestions((_) => []);
    setDisplayProcceedModal((_) => false);
    setProceedMessage((_) => "");
    setFirstStepLocked((_) => false);
    setSecondStepLocked((_) => false);
    setModule((_) => null);
    acceptedCourseFiles.length = 0;
    acceptedImageFiles.length = 0;
    setDurationString(initialDurationString);
    setPreviewDuration([0, 0]);

    if (courseAddModuleSpecific) {
      setModule({
        value: courseModuleId,
        label: courseModuleName,
      });
      firstForm.setValues({
        ...firstForm.values,
        module_id: courseModuleId,
      });
      setCourseData({ ...initialCourseData, module_id: courseModuleId });
    }
  }, [
    courseEdit,
    courseId,
    courseEditOpenToggle,
    courseAddOpenToggle,
    courseAddModuleSpecific,
  ]);

  useEffect(() => {
    if (firstStepLocked && secondStepLocked) {
      let hrsAndMins = getHrsMinsSecs(courseData.duration);
      setPreviewDuration([hrsAndMins[0], hrsAndMins[1]]);
      calCulateTotalMarks(courseData.questions);
    }
  }, [firstStepLocked, secondStepLocked, courseData]);

  const onCourseFilesDrop = async (acceptedCourseFiles) => {
    if (acceptedCourseFiles.length === 0) {
      toast.error("Invalid file selected", { autoClose: autoCloseToastError });
      return;
    }

    try {
      const formData = new FormData();
      acceptedCourseFiles.forEach(acceptedFile => {
        formData.append("course_files", acceptedFile);
        setCourseFileNames(currentFiles => [...currentFiles, acceptedFile.name]);
      });

      setUploadingCourseFile(true);

      const axiosInstance = createInstance(true);
      const fileUploadResponse = await axiosInstance.post(
        courseApi.concat("uploadcoursefile"),
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.round((loaded / total) * 100);
            setUploadCourseFileProgress(percentage);
          },
        }
      );
      setUploadingCourseFile(false);

      if (fileUploadResponse.data.success) {
        toast.success("File uploaded to server successfully");
        const firstFormValues = firstForm.values;
        firstFormValues.course_file_names.push(...fileUploadResponse.data.data.fileNames)
        firstForm.setValues(firstFormValues);
      } else {
        acceptedCourseFiles.length = 0;
        toast.error("Failed to upload the file");
      }
    } catch (error) {
      acceptedCourseFiles.length = 0;
      firstForm.setValues({ ...firstForm.values, course_file_names: [] });
      setCourseFileNames([]);
      setUploadingCourseFile(false);
      errorHandler(error, "Error in file upload");
    }
    setUploadCourseFileProgress((prev) => 0);
  };

  const handleCourseFileDelete = (fileName) => {
    const index = courseFileNames.findIndex(name => fileName === name);
    const firstFormValues = firstForm.values;
    firstFormValues.course_file_names.splice(index, 1)
    firstForm.setValues(firstFormValues);
    setCourseFileNames(prev => prev.toSpliced(index, 1));
    toast.info(`${fileName} is successfully removed.`);
  };

  const {
    acceptedFiles: acceptedCourseFiles,
    getRootProps: getRootPropsCourseFiles,
    getInputProps: getInputPropsCourseFiles,
    isDragActive: isDragActiveCourseFiles,
    isDragAccept: isDragAcceptCourseFiles,
    isDragReject: isDragRejectCourseFiles,
  } = useDropzone({
    onDrop: onCourseFilesDrop,
    accept: allowedCourseFileTypes,
    maxFiles: maxFilesPerCourse,
    useFsAccessApi: false,
    maxSize: courseFileMaxSize,
  });

  const onImageFileDrop = async (acceptedImageFiles) => {
    firstForm.setValues({ ...firstForm.values, course_image_name: "" });
    if (acceptedImageFiles.length === 0) {
      toast.error("Invalid file selected", { autoClose: autoCloseToastError });
      return;
    }

    try {
      const imageFile = acceptedImageFiles[0];
      const formData = new FormData();
      formData.append("course_image_file", imageFile);
      setCourseImageName(imageFile.name);

      setUploadingImageFile(true);

      const axiosInstance = createInstance(true);
      const fileUploadResponse = await axiosInstance.post(
        courseApi.concat("uploadcourseimagefile"),
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.round((loaded / total) * 100);
            setUploadImageProgress(percentage);
          },
        }
      );
      setUploadingImageFile(false);

      if (fileUploadResponse.data.success) {
        toast.success("File uploaded to server successfully");
        const uploadedFileName = fileUploadResponse.data.data?.filename;
        firstForm.setValues({
          ...firstForm.values,
          course_image_name: uploadedFileName,
        });
      } else {
        acceptedImageFiles.length = 0;
        firstForm.setValues({ ...firstForm.values, course_image_name: "" });
        toast.error("Failed to upload the file");
      }
    } catch (error) {
      acceptedImageFiles.length = 0;
      firstForm.setValues({ ...firstForm.values, course_image_name: "" });
      setUploadingImageFile(false);
      errorHandler(error, "Error in file upload");
    }
    setUploadImageProgress((prev) => 0);
  };

  const handleCourseImageDelete = () => {
    toast.info(`"${courseImageName}" is successfully removed`);
    firstForm.setValues({ ...firstForm.values, course_image_name: "" });
    setCourseImageName("");
  };

  const {
    acceptedFiles: acceptedImageFiles,
    getRootProps: getRootPropsImageFiles,
    getInputProps: getInputPropsImageFiles,
    isDragActive: isDragActiveImageFiles,
    isDragAccept: isDragAcceptImageFiles,
    isDragReject: isDragRejectImageFiles,
  } = useDropzone({
    onDrop: onImageFileDrop,
    accept: allowedCourseImageTypes,
    maxFiles: 1,
    useFsAccessApi: false,
    maxSize: courseImageMaxSize,
  });

  const [isOpen, setIsOpen] = useState(isOpenProp);

  const handleDrawerClose = (_) => {
    handleClose();
    setIsOpen(false);
  };

  const [activeStep, setActiveStep] = useState(0);
  const steps = ["Course Details", "Create Questionaire", "Preview"];

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Drawer
      sx={
        {
          // TODO: Fix zIndex issue
          // zIndex: 2001,
        }
      }
      PaperProps={{
        sx: {
          width: { xs: "90vw", md: "90vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      anchor={"right"}
      open={isOpen}
      onClose={handleDrawerClose}
      id="offcanvasModuleForm"
      aria-labelledby="offcanvasModuleFormLabel"
    >
      <Box
        my={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">New Course</Typography>
        <IconButton
          aria-label="close"
          onClick={handleDrawerClose}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" />

      <Box mt={{ md: 2 }} className="elevated-bg">
        <Stepper
          activeStep={activeStep}
          sx={{ mx: "auto", my: 3, width: { xs: "100%", md: "70%" } }}
          orientation={isSmallerScreen ? "vertical" : "horizontal"}
        >
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel
                  StepIconProps={{
                    className: "primary-text",
                    sx: { fontSize: "30px" },
                  }}
                >
                  <Typography variant="body1">{label}</Typography>
                </StepLabel>
                {isSmallerScreen && (
                  <StepContent sx={{ pl: { xs: "8px", sm: "20px" } }}>
                    {/* TODO: Code repetition here */}
                    {activeStep === 0 && (
                      <Grid container>
                        <Grid item xs={12} md={5.8} container height={"100%"}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            mb={{ xs: 1, md: 4 }}
                            mt={isSmallerScreen && 1}
                          >
                            <Typography variant="h6" className="fw-bold">
                              Course Details
                            </Typography>
                            <Divider
                              className={"horizontal-line"}
                              sx={{ my: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} lg={3} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="module_name"
                            >
                              {"Course Title "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} md={12} lg={9}>
                            <TextField
                              name="title"
                              size="small"
                              value={firstForm.values.title}
                              placeholder="Course Title"
                              onChange={firstForm.handleChange}
                              onBlur={firstForm.handleBlur}
                              error={
                                firstForm.touched.title &&
                                Boolean(firstForm.errors.title)
                              }
                              helperText={
                                firstForm.touched.title &&
                                firstForm.errors.title
                              }
                              sx={{ mb: 2, width: "100%" }}
                            />
                          </Grid>

                          <Grid item xs={12} md={12} lg={3} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="description"
                            >
                              {"Description "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} md={12} lg={9}>
                            <TextField
                              id="course-description"
                              name="description"
                              placeholder="Description"
                              value={firstForm.values.description}
                              onChange={firstForm.handleChange}
                              onBlur={firstForm.handleBlur}
                              error={
                                firstForm.touched.description &&
                                Boolean(firstForm.errors.description)
                              }
                              helperText={
                                firstForm.touched.description &&
                                firstForm.errors.description
                              }
                              sx={{ mb: 2, width: "100%" }}
                              size="small"
                              multiline
                            />
                          </Grid>

                          {/* TODO: Need to come back | module data using formik */}
                          <Grid item xs={12} md={12} lg={3} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="description"
                            >
                              {"Module "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} md={12} lg={9} mb={2}>
                            {/* TODO: Need to migrate to new async select */}
                            <AsyncSelect
                              className={`course-input-select ${firstForm.touched.module_id &&
                                Boolean(firstForm.errors.module_id)
                                ? "error-border"
                                : ""
                                }`}
                              name="module_id"
                              styles={asyncSelectCustomStyles}
                              onChange={(selectedOption) => {
                                setModule(selectedOption);
                                firstForm.setValues({
                                  ...firstForm.values,
                                  module_id: selectedOption?.value,
                                });
                              }}
                              defaultOptions={moduleOptions}
                              value={module}
                              placeholder="Type to search..."
                              loadOptions={debounce(
                                loadModuleOptions,
                                searchOptionDebounceTime
                              )}
                              noOptionsMessage={() => {
                                if (!moduleOptionsLoaded) {
                                  return "Fethcing modules...";
                                } else {
                                  return "No modules found. Type again...";
                                }
                              }}
                              isClearable
                              onFocus={handleModuleFocus}
                              menuPosition="fixed"
                            />
                            {firstForm.touched.module_id &&
                              Boolean(firstForm.errors.module_id) && (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  p={2}
                                >
                                  {firstForm.errors.module_id}
                                </Typography>
                              )}
                          </Grid>

                          <Grid item xs={12} lg={2.5} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="description"
                              sx={{ textAlign: { xs: "start", lg: "end" } }}
                            >
                              {"Language "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} md={12} lg={3}>
                            <Select
                              id="course-language"
                              value={firstForm.values.language}
                              name="language"
                              onChange={firstForm.handleChange}
                              onBlur={firstForm.handleBlur}
                              error={
                                firstForm.touched.language &&
                                Boolean(firstForm.errors.language)
                              }
                              size="small"
                              sx={{ width: "100%", mb: 2 }}
                            >
                              {Object.keys(languages).map((key) => (
                                <MenuItem key={key} value={languages[key]}>
                                  {languages[key]}
                                </MenuItem>
                              ))}
                            </Select>
                          </Grid>

                          <Grid item xs={12} md={12} lg={3} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="description"
                            >
                              {"Duration "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          {/* TODO:  add a time picker*/}
                          {/* Previous duration input */}
                          <Grid
                            item
                            container
                            xs={12}
                            md={12}
                            lg={9}
                            justifyContent={"start"}
                          >
                            {/* TODO:  add a time picker*/}
                            <Box mr={2} mb={{ xs: 2, sm: 0 }}>
                              <Box
                                display={"flex"}
                                maxWidth={"150px"}
                                alignItems={"end"}
                              >
                                <TextField
                                  type="number"
                                  name="hours"
                                  placeholder="Hours"
                                  InputProps={{ inputProps: { min: 0 } }}
                                  value={firstForm.values.hours}
                                  onChange={firstForm.handleChange}
                                  onBlur={firstForm.handleBlur}
                                  error={
                                    firstForm.touched.hours &&
                                    Boolean(firstForm.errors.hours)
                                  }
                                  size="small"
                                />
                                <Typography variant="body1" ml={1}>
                                  HH
                                </Typography>
                              </Box>
                            </Box>
                            <Box>
                              <Box
                                display={"flex"}
                                maxWidth={"150px"}
                                alignItems={"end"}
                              >
                                <TextField
                                  type="number"
                                  name="minutes"
                                  placeholder="Minutes"
                                  value={firstForm.values.minutes}
                                  InputProps={{ inputProps: { min: 0 } }}
                                  onChange={firstForm.handleChange}
                                  onBlur={firstForm.handleBlur}
                                  error={
                                    firstForm.touched.minutes &&
                                    Boolean(firstForm.errors.minutes)
                                  }
                                  size="small"
                                />
                                <Typography variant="body1" ml={1}>
                                  MM
                                </Typography>
                              </Box>
                            </Box>
                          </Grid>

                          <Grid item md={12}>
                            {firstForm.touched.hours &&
                              firstForm.touched.minutes &&
                              firstForm.values.hours * 3600 +
                              firstForm.values.minutes * 60 <
                              60 && (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  align="center"
                                >
                                  {`Course duration must be at least 1 minute`}
                                </Typography>
                              )}
                          </Grid>

                          <Grid item xs={12} md={12} lg={3} pr={2} sx={{ mt: 2 }}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="passing-percentage"
                            >
                              {"Passing Percentage "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} md={12} lg={9}>
                            <TextField
                              id="passing-percentage"
                              name="passing_percentage"
                              type="number"
                              InputProps={{ inputProps: { min: 1 } }}
                              placeholder="35"
                              value={firstForm.values?.passing_percentage}
                              onChange={firstForm.handleChange}
                              onBlur={firstForm.handleBlur}
                              error={
                                firstForm.touched.passing_percentage &&
                                Boolean(firstForm.errors.passing_percentage)
                              }
                              helperText={
                                firstForm.touched.passing_percentage &&
                                firstForm.errors.passing_percentage
                              }
                              sx={{ mb: 2, width: "100%" }}
                              size="small"
                            />
                          </Grid>
                        </Grid>
                        {!isSmallerScreen && (
                          <Grid
                            item
                            md={0.4}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Divider
                              className="horizontal-line"
                              orientation="vertical"
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={5.8} container height={"100%"}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            mb={{ xs: 1, md: 4 }}
                            mt={{ xs: 2, sm: 4, md: 0 }}
                          >
                            <Typography variant="h6" className="fw-bold">
                              Media Center
                            </Typography>
                            <Divider
                              className={"horizontal-line"}
                              sx={{ my: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={12} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="description"
                            >
                              {"Upload Course Image "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>

                          <Grid item xs={12} md={12} mb={2}>
                            <Box className={`primary-drop-zone`} pl={2}>
                              <DropzoneArea
                                classes={{
                                  root: "drop-container",
                                  text: "drop-text",
                                }}
                                filesLimit={1}
                                acceptedFiles={[".jpg, .jpeg, .png"]}
                                maxFileSize={courseImageMaxSize}
                                onDrop={onImageFileDrop}
                                Icon={CloudUploadOutlinedIcon}
                                showPreviewsInDropzone={false}
                                showAlerts={false}
                                previewGridProps={{
                                  container: { spacing: 1, direction: "row" },
                                }}
                              />
                              {courseImageName && (
                                <Box width={"100%"} mt={1}>
                                  <Chip
                                    label={courseImageName}
                                    variant="outlined"
                                    onDelete={handleCourseImageDelete}
                                  />
                                </Box>
                              )}
                              <Typography variant="caption" mt={1}>
                                jpeg, jpg, png file upto 400KB is allowed
                              </Typography>
                            </Box>
                            {firstForm.touched.course_image_name &&
                              Boolean(firstForm.errors.course_image_name) && (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  p={2}
                                >
                                  {firstForm.errors.course_image_name}
                                </Typography>
                              )}
                          </Grid>
                          <Grid item xs={12} md={12} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="description"
                              sx={{ textAlign: "" }}
                            >
                              {"Upload Course Files "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <Box className={`primary-drop-zone`} pl={2}>
                              <DropzoneArea
                                classes={{
                                  root: "drop-container",
                                  text: "drop-text",
                                }}
                                filesLimit={maxFilesPerCourse}
                                acceptedFiles={[".mp4, .pdf, .txt"]}
                                maxFileSize={courseFileMaxSize}
                                onDrop={onCourseFilesDrop}
                                Icon={CloudUploadOutlinedIcon}
                                showPreviewsInDropzone={false}
                                showAlerts={false}
                                previewGridProps={{
                                  container: { spacing: 1, direction: "row" },
                                }}
                                previewText="Selected files"
                              />
                              <Box width={"100%"} mt={1}>
                                {courseFileNames.length !== 0 && (courseFileNames.map(filename => {
                                  return <Chip
                                    label={filename}
                                    variant="outlined"
                                    onDelete={() => handleCourseFileDelete(filename)}
                                  />
                                })
                                )}
                              </Box>
                              <Typography variant="caption" mt={1}>
                                PDF, MP4, TXT file upto 100MB is allowed
                              </Typography>
                            </Box>
                            {firstForm.touched.course_file_names &&
                              Boolean(firstForm.errors.course_file_names) && (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  pl={2}
                                >
                                  {firstForm.errors.course_file_names}
                                </Typography>
                              )
                            }
                          </Grid>
                        </Grid>
                        <Typography variant="body2" my={2}>
                          <Asterisk />
                          {" Mandatory Fields"}
                        </Typography>
                      </Grid>
                    )}
                    {activeStep === 1 && (
                      <Grid container>
                        <Grid item container xs={12} md={5.8} height={"100%"}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            mb={{ xs: 1, md: 4 }}
                            mt={isSmallerScreen && 1}
                          >
                            <Typography variant="h6" className="fw-bold">
                              Questions
                            </Typography>
                            <Divider
                              className={"horizontal-line"}
                              sx={{ my: 1 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={2} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="question_text"
                            >
                              {"Question "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} md={10}>
                            <TextField
                              id="question_text"
                              name="question_text"
                              placeholder="Enter Question Text"
                              value={secondForm.values.question_text}
                              onChange={secondForm.handleChange}
                              onBlur={secondForm.handleBlur}
                              error={
                                secondForm.touched.question_text &&
                                Boolean(secondForm.errors.question_text)
                              }
                              helperText={
                                secondForm.touched.question_text &&
                                secondForm.errors.question_text
                              }
                              size="small"
                              multiline
                              fullWidth
                              sx={{ mb: 2 }}
                            />
                          </Grid>

                          <Grid item xs={12} md={2} pr={2}>
                            <InputLabel
                              className="fw-bold"
                              htmlFor="module_name"
                            >
                              {"Marks "}
                              <Asterisk />
                            </InputLabel>
                          </Grid>
                          <Grid item xs={12} md={2}>
                            <TextField
                              id="marks"
                              name="marks"
                              type="number"
                              placeholder="Add Marks"
                              value={secondForm.values.marks}
                              onChange={secondForm.handleChange}
                              onBlur={secondForm.handleBlur}
                              InputProps={{ inputProps: { min: 1 } }}
                              error={
                                secondForm.touched.marks &&
                                Boolean(secondForm.errors.marks)
                              }
                              helperText={
                                secondForm.touched.marks &&
                                secondForm.errors.marks
                              }
                              size="small"
                              sx={{ mb: 4, width: "100%" }}
                            />
                          </Grid>
                          <Grid item md={7}></Grid>

                          <Grid
                            item
                            xs={12}
                            md={12}
                            mt={!isSmallerScreen && 1}
                            mb={2}
                          >
                            <Typography variant="body1">
                              Options to select from (Tick the correct answer)
                            </Typography>
                          </Grid>

                          <Grid item md={2}></Grid>
                          <Grid item xs={12} md={4.5}>
                            <Asterisk />
                            <Radio
                              value={secondForm.values.options[0].is_correct}
                              name="correct_option"
                              checked={secondForm.values.options[0].is_correct}
                              onChange={() => handleCorrectOptionChange(0)}
                            />
                            <TextField
                              name="option_1"
                              size="small"
                              placeholder="Option 1"
                              onChange={(e) => {
                                secondForm.handleChange(e);
                                handleOptionChange(0, e.target.value);
                              }}
                              value={secondForm.values.option_1}
                              onBlur={secondForm.handleBlur}
                              error={
                                secondForm.touched.option_1 &&
                                Boolean(secondForm.errors.option_1)
                              }
                              helperText={
                                secondForm.touched.option_1 &&
                                secondForm.errors.option_1
                              }
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item md={1}></Grid>
                          <Grid item xs={12} md={4.5}>
                            <Asterisk />
                            <Radio
                              value={secondForm.values.options[1].is_correct}
                              name="correct_option"
                              checked={secondForm.values.options[1].is_correct}
                              onChange={() => handleCorrectOptionChange(1)}
                            />
                            <TextField
                              name="option_2"
                              size="small"
                              placeholder="Option 2"
                              onChange={(e) => {
                                secondForm.handleChange(e);
                                handleOptionChange(1, e.target.value);
                              }}
                              value={secondForm.values.option_2}
                              onBlur={secondForm.handleBlur}
                              error={
                                secondForm.touched.option_2 &&
                                Boolean(secondForm.errors.option_2)
                              }
                              helperText={
                                secondForm.touched.option_2 &&
                                secondForm.errors.option_2
                              }
                              sx={{ mb: 2 }}
                            />
                          </Grid>

                          <Grid item md={2}></Grid>
                          <Grid item xs={12} md={4.5}>
                            <Asterisk />
                            <Radio
                              value={secondForm.values.options[2].is_correct}
                              name="correct_option"
                              checked={secondForm.values.options[2].is_correct}
                              onChange={() => handleCorrectOptionChange(2)}
                            />
                            <TextField
                              name="option_3"
                              size="small"
                              placeholder="Option 3"
                              onChange={(e) => {
                                secondForm.handleChange(e);
                                handleOptionChange(2, e.target.value);
                              }}
                              value={secondForm.values.option_3}
                              onBlur={secondForm.handleBlur}
                              error={
                                secondForm.touched.option_3 &&
                                Boolean(secondForm.errors.option_3)
                              }
                              helperText={
                                secondForm.touched.option_3 &&
                                secondForm.errors.option_3
                              }
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item md={1}></Grid>
                          <Grid item xs={12} md={4.5}>
                            <Asterisk />
                            <Radio
                              value={secondForm.values.options[3].is_correct}
                              name="correct_option"
                              checked={secondForm.values.options[3].is_correct}
                              onChange={() => handleCorrectOptionChange(3)}
                            />
                            <TextField
                              name="option_4"
                              size="small"
                              placeholder="Option 4"
                              onChange={(e) => {
                                secondForm.handleChange(e);
                                handleOptionChange(3, e.target.value);
                              }}
                              value={secondForm.values.option_4}
                              onBlur={secondForm.handleBlur}
                              error={
                                secondForm.touched.option_4 &&
                                Boolean(secondForm.errors.option_4)
                              }
                              helperText={
                                secondForm.touched.option_4 &&
                                secondForm.errors.option_4
                              }
                              sx={{ mb: 2 }}
                            />
                          </Grid>
                          <Grid item xs={12} md={4}>
                            <Typography variant="body2" my={2}>
                              <Asterisk />
                              {" Mandatory Fields"}
                            </Typography>
                          </Grid>
                          <Grid
                            item
                            container
                            xs={12}
                            md={8}
                            mb={isSmallerScreen && 2}
                            justifyContent={"flex-end"}
                          >
                            <Button
                              variant="outlined"
                              className="primary-text primary-btn"
                              onClick={secondForm.handleSubmit}
                              startIcon={<AddIcon />}
                              sx={{ m: isSmallerScreen ? 0 : 2 }}
                              fullWidth={isSmallerScreen}
                            >
                              Add question
                            </Button>
                          </Grid>
                        </Grid>
                        {!isSmallerScreen && (
                          <Grid
                            item
                            md={0.4}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Divider
                              className="horizontal-line"
                              orientation="vertical"
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={5.8}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            mb={{ xs: 1, md: 4 }}
                            mt={isSmallerScreen && 2}
                          >
                            <Typography variant={"h6"}>
                              Questions Preview
                            </Typography>
                            <Divider
                              className={"horizontal-line"}
                              sx={{ my: 1 }}
                            />
                          </Grid>
                          <Box
                            height={
                              questions.length === 0
                                ? "50"
                                : isSmallerScreen
                                  ? "120px"
                                  : "450px"
                            }
                            sx={{ overflowY: "scroll" }}
                          >
                            {questions.length === 0 ? (
                              <Typography variant={"body1"}>
                                Add questions to preview...
                              </Typography>
                            ) : (
                              questions.map((question, index) => (
                                <Grid item key={index} md={12} mb={2}>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"space-between"}
                                    alignItems={"center"}
                                  >
                                    <Typography
                                      variant="body2"
                                      fontWeight={"bold"}
                                    >{`${index + 1}. ${question.question_text
                                      }`}</Typography>
                                    <Tooltip title={"Remove Question"} arrow>
                                      <IconButton
                                        onClick={() =>
                                          handleQuestionDelete(index)
                                        }
                                        sx={{ mr: 2 }}
                                        color="warning"
                                      >
                                        <DeleteForeverIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                  {question.options.map((option, index) => (
                                    <Box key={index}>
                                      <Typography
                                        name="option_4"
                                        variant="body2"
                                        size="small"
                                        color={
                                          option.is_correct
                                            ? "green"
                                            : "inherit"
                                        }
                                      >
                                        {`${String.fromCharCode(index + 65)}. ${option.option_text
                                          }`}
                                      </Typography>
                                    </Box>
                                  ))}
                                </Grid>
                              ))
                            )}
                          </Box>
                        </Grid>
                      </Grid>
                    )}
                    {activeStep === 2 && (
                      <Grid container>
                        <Grid item container xs={12} md={5.8}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            mb={{ xs: 1, sm: 3 }}
                            mt={isSmallerScreen && 1}
                          >
                            <Typography variant={"h6"}>
                              Course Details
                            </Typography>
                            <Divider
                              className={"horizontal-line"}
                              sx={{ my: 1 }}
                            />
                          </Grid>

                          <Grid item xs={12} md={3} pr={2}>
                            <Typography
                              variant="body1"
                              className="fw-bold"
                              mb={!isSmallerScreen && 3}
                            >
                              Course Title
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Typography
                              variant="body1"
                              mb={isSmallerScreen ? 2 : 3}
                            >
                              {allFormsData?.firstFormData?.title}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={3} pr={2}>
                            <Typography
                              variant="body1"
                              className="fw-bold"
                              mb={!isSmallerScreen && 3}
                            >
                              Module
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Typography
                              variant="body1"
                              mb={isSmallerScreen ? 2 : 3}
                            >
                              {module?.label}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={3} pr={2}>
                            <Typography
                              variant="body1"
                              className="fw-bold"
                              mb={!isSmallerScreen && 3}
                            >
                              Language
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Typography
                              variant="body1"
                              mb={isSmallerScreen ? 2 : 3}
                            >
                              {allFormsData?.firstFormData?.language}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={3} pr={2}>
                            <Typography
                              variant="body1"
                              className="fw-bold"
                              mb={!isSmallerScreen && 3}
                            >
                              Duration
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            <Typography
                              variant="body1"
                              mb={isSmallerScreen ? 2 : 3}
                            >
                              {allFormsData?.firstFormData?.duration}
                            </Typography>
                          </Grid>

                          <Grid item xs={12} md={3} pr={2}>
                            <Typography
                              variant="body1"
                              className="fw-bold"
                              mb={!isSmallerScreen && 3}
                            >
                              Uploaded Course Files
                            </Typography>
                          </Grid>
                          <Grid item xs={12} md={9}>
                            {courseFileNames.length !== 0 && courseFileNames.map(filename => {
                              return <Typography
                                variant="body1"
                                mb={isSmallerScreen ? 2 : 3}
                              >
                                {filename}
                              </Typography>
                            })}
                          </Grid>
                        </Grid>
                        {!isSmallerScreen && (
                          <Grid
                            item
                            md={0.4}
                            display={"flex"}
                            justifyContent={"center"}
                          >
                            <Divider
                              className="horizontal-line"
                              orientation="vertical"
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} md={5.8}>
                          <Grid
                            item
                            xs={12}
                            md={12}
                            mt={isSmallerScreen && 1}
                            mb={!isSmallerScreen && 3}
                          >
                            <Box
                              display={"flex"}
                              justifyContent={"space-between"}
                              alignItems={"center"}
                            >
                              <Typography
                                variant="h6"
                                className="fw-bold"
                                flexGrow={1}
                              >
                                Questions
                              </Typography>
                              <StarsRoundedIcon />
                              <Typography variant="body2" fontWeight={"bold"}>
                                {` Total Marks: ${allFormsData?.secondFormData?.questions?.reduce(
                                  (acc, question) => acc + question.marks,
                                  0
                                )}`}
                              </Typography>
                            </Box>
                            <Divider
                              className={"horizontal-line"}
                              sx={{ my: 1 }}
                            />
                          </Grid>
                          {allFormsData?.secondFormData?.questions?.length ===
                            0 ? (
                            <Typography variant={"body1"}>
                              No questions to preview...
                            </Typography>
                          ) : (
                            allFormsData?.secondFormData?.questions?.map(
                              (question, index) => {
                                return (
                                  <Grid item md={12} mt={2} key={index}>
                                    <Box
                                      display={"flex"}
                                      justifyContent={"space-between"}
                                      alignItems={"center"}
                                    >
                                      <Typography
                                        variant="body1"
                                        fontWeight={"bold"}
                                      >{`Q${index + 1}. ${question.question_text
                                        }`}</Typography>{" "}
                                      <Typography
                                        variant="body2"
                                        fontWeight={"bold"}
                                        align="right"
                                        color={"textSecondary"}
                                      >{`${question.marks} Marks`}</Typography>
                                    </Box>
                                    {question.options.map((option, index) => (
                                      <Box key={index}>
                                        <Typography
                                          name="option_4"
                                          variant="body2"
                                          size="small"
                                          color={
                                            option.is_correct
                                              ? "green"
                                              : "inherit"
                                          }
                                        >
                                          {`${String.fromCharCode(
                                            index + 65
                                          )}. ${option.option_text}`}
                                        </Typography>
                                      </Box>
                                    ))}
                                  </Grid>
                                );
                              }
                            )
                          )}
                        </Grid>
                        <Grid item sm={12} md={8}></Grid>
                      </Grid>
                    )}
                  </StepContent>
                )}
              </Step>
            );
          })}
        </Stepper>
      </Box>

      <Box my={3}>
        {/* TODO: Code repetition here */}
        {activeStep === 0 && !isSmallerScreen && (
          <Grid container>
            <Grid item xs={12} md={5.8} container height={"100%"}>
              <Grid
                item
                xs={12}
                md={12}
                mb={{ xs: 1, md: 4 }}
                mt={isSmallerScreen && 1}
              >
                <Typography variant="h6" className="fw-bold">
                  Course Details
                </Typography>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={12} md={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="module_name">
                  {"Course Title "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={9}>
                <TextField
                  name="title"
                  size="small"
                  value={firstForm.values.title}
                  placeholder="Course Title"
                  onChange={firstForm.handleChange}
                  onBlur={firstForm.handleBlur}
                  error={
                    firstForm.touched.title && Boolean(firstForm.errors.title)
                  }
                  helperText={firstForm.touched.title && firstForm.errors.title}
                  sx={{ mb: 2, width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Description "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={9}>
                <TextField
                  id="course-description"
                  name="description"
                  placeholder="Description"
                  value={firstForm.values.description}
                  onChange={firstForm.handleChange}
                  onBlur={firstForm.handleBlur}
                  error={
                    firstForm.touched.description &&
                    Boolean(firstForm.errors.description)
                  }
                  helperText={
                    firstForm.touched.description &&
                    firstForm.errors.description
                  }
                  sx={{ mb: 2, width: "100%" }}
                  size="small"
                  multiline
                />
              </Grid>

              {/* TODO: Need to come back | module data using formik */}
              <Grid item xs={12} md={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Module "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={9} mb={2}>
                {/* TODO: Need to migrate to new async select */}
                <AsyncSelect
                  className={`course-input-select ${firstForm.touched.module_id &&
                    Boolean(firstForm.errors.module_id)
                    ? "error-border"
                    : ""
                    }`}
                  name="module_id"
                  styles={asyncSelectCustomStyles}
                  onChange={(selectedOption) => {
                    setModule(selectedOption);
                    firstForm.setValues({
                      ...firstForm.values,
                      module_id: selectedOption?.value,
                    });
                  }}
                  defaultOptions={moduleOptions}
                  value={module}
                  placeholder="Type to search..."
                  loadOptions={debounce(
                    loadModuleOptions,
                    searchOptionDebounceTime
                  )}
                  noOptionsMessage={() => {
                    if (!moduleOptionsLoaded) {
                      return "Fethcing modules...";
                    } else {
                      return "No modules found. Type again...";
                    }
                  }}
                  isClearable
                  onFocus={handleModuleFocus}
                  menuPosition="fixed"
                />
                {firstForm.touched.module_id &&
                  Boolean(firstForm.errors.module_id) && (
                    <Typography variant="caption" color="error" p={2}>
                      {firstForm.errors.module_id}
                    </Typography>
                  )}
              </Grid>

              <Grid item xs={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Language "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={5}>
                <Select
                  id="course-language"
                  value={firstForm.values.language}
                  name="language"
                  onChange={firstForm.handleChange}
                  onBlur={firstForm.handleBlur}
                  error={
                    firstForm.touched.language &&
                    Boolean(firstForm.errors.language)
                  }
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                >
                  {Object.keys(languages).map((key) => (
                    <MenuItem key={key} value={languages[key]}>
                      {languages[key]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item lg={4}></Grid>

              <Grid item xs={12} md={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Duration "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              {/* TODO:  add a time picker*/}
              {/* Previous duration input */}
              <Grid
                item
                container
                xs={12}
                md={12}
                lg={9}
                justifyContent={"start"}
              >
                {/* TODO:  add a time picker*/}
                <Box mr={2} mb={{ xs: 2, sm: 0 }}>
                  <Box display={"flex"} maxWidth={"150px"} alignItems={"end"}>
                    <TextField
                      type="number"
                      name="hours"
                      placeholder="Hours"
                      value={firstForm.values.hours}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={firstForm.handleChange}
                      onBlur={firstForm.handleBlur}
                      error={
                        firstForm.touched.hours &&
                        Boolean(firstForm.errors.hours)
                      }
                      size="small"
                    />
                    <Typography variant="body1" ml={1}>
                      HH
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box display={"flex"} maxWidth={"150px"} alignItems={"end"}>
                    <TextField
                      type="number"
                      name="minutes"
                      placeholder="Minutes"
                      value={firstForm.values.minutes}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={firstForm.handleChange}
                      onBlur={firstForm.handleBlur}
                      error={
                        firstForm.touched.minutes &&
                        Boolean(firstForm.errors.minutes)
                      }
                      size="small"
                    />
                    <Typography variant="body1" ml={1}>
                      MM
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3}></Grid>
              <Grid item md={12} lg={9}>
                {firstForm.touched.hours &&
                  firstForm.touched.minutes &&
                  firstForm.values.hours * 3600 +
                  firstForm.values.minutes * 60 <
                  60 && (
                    <Typography variant="caption" color="error" align="center">
                      {`Course duration must be at least 1 minute`}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} md={12} lg={3} pr={2} sx={{ mt: 2 }}>
                <InputLabel
                  className="fw-bold"
                  htmlFor="passing-percentage"
                >
                  {"Passing Percentage "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={9} sx={{ mt: 2 }}>
                <TextField
                  id="passing-percentage"
                  name="passing_percentage"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  placeholder="35"
                  value={firstForm.values?.passing_percentage}
                  onChange={firstForm.handleChange}
                  onBlur={firstForm.handleBlur}
                  error={
                    firstForm.touched.passing_percentage &&
                    Boolean(firstForm.errors.passing_percentage)
                  }
                  helperText={
                    firstForm.touched.passing_percentage &&
                    firstForm.errors.passing_percentage
                  }
                  sx={{ mb: 2, width: "100%" }}
                  size="small"
                />
              </Grid>
            </Grid>
            {!isSmallerScreen && (
              <Grid item md={0.4} display={"flex"} justifyContent={"center"}>
                <Divider className="horizontal-line" orientation="vertical" />
              </Grid>
            )}
            <Grid item xs={12} md={5.8} container height={"100%"}>
              <Grid
                item
                xs={12}
                md={12}
                mb={{ xs: 1, md: 4 }}
                mt={{ xs: 2, sm: 4, md: 0 }}
              >
                <Typography variant="h6" className="fw-bold">
                  Media Center
                </Typography>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={12} md={12} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Upload Course Image "}
                  <Asterisk />
                </InputLabel>
              </Grid>

              <Grid item xs={12} md={12} mb={2}>
                <Box className={`primary-drop-zone`} pl={2}>
                  <DropzoneArea
                    classes={{ root: "drop-container", text: "drop-text" }}
                    filesLimit={1}
                    acceptedFiles={[".jpg, .jpeg, .png"]}
                    maxFileSize={courseImageMaxSize}
                    onDrop={onImageFileDrop}
                    Icon={CloudUploadOutlinedIcon}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    previewGridProps={{
                      container: { spacing: 1, direction: "row" },
                    }}
                  />
                  {courseImageName && (
                    <Box width={"100%"} mt={1}>
                      <Chip
                        label={courseImageName}
                        variant="outlined"
                        onDelete={handleCourseImageDelete}
                      />
                    </Box>
                  )}
                  <Typography variant="caption" mt={1}>
                    jpeg, jpg, png file upto 400KB is allowed
                  </Typography>
                </Box>
                {firstForm.touched.course_image_name &&
                  Boolean(firstForm.errors.course_image_name) && (
                    <Typography variant="caption" color="error" p={2}>
                      {firstForm.errors.course_image_name}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} md={12} pr={2}>
                <InputLabel
                  className="fw-bold"
                  htmlFor="description"
                  sx={{ textAlign: "" }}
                >
                  {"Upload Course Files "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box className={`primary-drop-zone`} pl={2}>
                  <DropzoneArea
                    classes={{ root: "drop-container", text: "drop-text" }}
                    filesLimit={maxFilesPerCourse}
                    acceptedFiles={[".mp4, .pdf, .txt"]}
                    maxFileSize={courseFileMaxSize}
                    onDrop={onCourseFilesDrop}
                    Icon={CloudUploadOutlinedIcon}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    previewGridProps={{
                      container: { spacing: 1, direction: "row" },
                    }}
                    previewText="Selected files"
                  />
                  <Box width={"100%"} mt={1}>
                    {courseFileNames.length !== 0 && (courseFileNames.map(filename => {
                      return <Chip
                        label={filename}
                        variant="outlined"
                        onDelete={() => handleCourseFileDelete(filename)}
                      />
                    })
                    )}
                  </Box>
                  <Typography variant="caption" mt={1}>
                    PDF, MP4, TXT file upto 100MB is allowed
                  </Typography>
                </Box>
                {firstForm.touched.course_file_names &&
                  Boolean(firstForm.errors.course_file_names) && (
                    <Typography variant="caption" color="error" pl={2}>
                      {firstForm.errors.course_file_names}
                    </Typography>
                  )}
              </Grid>
            </Grid>
            <Typography variant="body2" my={2}>
              <Asterisk />
              {" Mandatory Fields"}
            </Typography>
          </Grid>
        )}

        {activeStep === 1 && !isSmallerScreen && (
          <Grid container>
            <Grid item container xs={12} md={5.8} height={"100%"}>
              <Grid
                item
                xs={12}
                md={12}
                mb={{ xs: 1, md: 4 }}
                mt={isSmallerScreen && 1}
              >
                <Typography variant="h6" className="fw-bold">
                  Questions
                </Typography>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={12} lg={2.5} pr={2}>
                <InputLabel className="fw-bold" htmlFor="question_text">
                  {"Question "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} lg={9.5}>
                <TextField
                  id="question_text"
                  name="question_text"
                  placeholder="Enter Question Text"
                  value={secondForm.values.question_text}
                  onChange={secondForm.handleChange}
                  onBlur={secondForm.handleBlur}
                  error={
                    secondForm.touched.question_text &&
                    Boolean(secondForm.errors.question_text)
                  }
                  helperText={
                    secondForm.touched.question_text &&
                    secondForm.errors.question_text
                  }
                  size="small"
                  multiline
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={2.5} pr={2}>
                <InputLabel className="fw-bold" htmlFor="module_name">
                  {"Marks "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={2.5}>
                <TextField
                  id="marks"
                  name="marks"
                  type="number"
                  placeholder="Add Marks"
                  value={secondForm.values.marks}
                  onChange={secondForm.handleChange}
                  onBlur={secondForm.handleBlur}
                  InputProps={{ inputProps: { min: 1 } }}
                  error={
                    secondForm.touched.marks && Boolean(secondForm.errors.marks)
                  }
                  helperText={
                    secondForm.touched.marks && secondForm.errors.marks
                  }
                  size="small"
                  sx={{ mb: 4, width: "100%" }}
                />
              </Grid>
              <Grid item md={7}></Grid>

              <Grid item xs={12} md={12} mt={!isSmallerScreen && 1} mb={2}>
                <Typography variant="body1">
                  Options to select from (Tick the correct answer)
                </Typography>
              </Grid>

              <Grid item md={2}></Grid>
              <Grid item xs={12} md={4.5}>
                <Asterisk />
                <Radio
                  value={secondForm.values.options[0].is_correct}
                  name="correct_option"
                  checked={secondForm.values.options[0].is_correct}
                  onChange={() => handleCorrectOptionChange(0)}
                />
                <TextField
                  name="option_1"
                  size="small"
                  placeholder="Option 1"
                  onChange={(e) => {
                    secondForm.handleChange(e);
                    handleOptionChange(0, e.target.value);
                  }}
                  value={secondForm.values.option_1}
                  onBlur={secondForm.handleBlur}
                  error={
                    secondForm.touched.option_1 &&
                    Boolean(secondForm.errors.option_1)
                  }
                  helperText={
                    secondForm.touched.option_1 && secondForm.errors.option_1
                  }
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item xs={12} md={4.5}>
                <Asterisk />
                <Radio
                  value={secondForm.values.options[1].is_correct}
                  name="correct_option"
                  checked={secondForm.values.options[1].is_correct}
                  onChange={() => handleCorrectOptionChange(1)}
                />
                <TextField
                  name="option_2"
                  size="small"
                  placeholder="Option 2"
                  onChange={(e) => {
                    secondForm.handleChange(e);
                    handleOptionChange(1, e.target.value);
                  }}
                  value={secondForm.values.option_2}
                  onBlur={secondForm.handleBlur}
                  error={
                    secondForm.touched.option_2 &&
                    Boolean(secondForm.errors.option_2)
                  }
                  helperText={
                    secondForm.touched.option_2 && secondForm.errors.option_2
                  }
                  sx={{ mb: 2 }}
                />
              </Grid>

              <Grid item md={2}></Grid>
              <Grid item xs={12} md={4.5}>
                <Asterisk />
                <Radio
                  value={secondForm.values.options[2].is_correct}
                  name="correct_option"
                  checked={secondForm.values.options[2].is_correct}
                  onChange={() => handleCorrectOptionChange(2)}
                />
                <TextField
                  name="option_3"
                  size="small"
                  placeholder="Option 3"
                  onChange={(e) => {
                    secondForm.handleChange(e);
                    handleOptionChange(2, e.target.value);
                  }}
                  value={secondForm.values.option_3}
                  onBlur={secondForm.handleBlur}
                  error={
                    secondForm.touched.option_3 &&
                    Boolean(secondForm.errors.option_3)
                  }
                  helperText={
                    secondForm.touched.option_3 && secondForm.errors.option_3
                  }
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item md={1}></Grid>
              <Grid item xs={12} md={4.5}>
                <Asterisk />
                <Radio
                  value={secondForm.values.options[3].is_correct}
                  name="correct_option"
                  checked={secondForm.values.options[3].is_correct}
                  onChange={() => handleCorrectOptionChange(3)}
                />
                <TextField
                  name="option_4"
                  size="small"
                  placeholder="Option 4"
                  onChange={(e) => {
                    secondForm.handleChange(e);
                    handleOptionChange(3, e.target.value);
                  }}
                  value={secondForm.values.option_4}
                  onBlur={secondForm.handleBlur}
                  error={
                    secondForm.touched.option_4 &&
                    Boolean(secondForm.errors.option_4)
                  }
                  helperText={
                    secondForm.touched.option_4 && secondForm.errors.option_4
                  }
                  sx={{ mb: 2 }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Typography variant="body2" my={2}>
                  <Asterisk />
                  {" Mandatory Fields"}
                </Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={8}
                mb={isSmallerScreen && 2}
                justifyContent={"flex-end"}
              >
                <Button
                  variant="outlined"
                  className="primary-text primary-btn"
                  onClick={secondForm.handleSubmit}
                  startIcon={<AddIcon />}
                  sx={{ m: isSmallerScreen ? 0 : 2 }}
                  fullWidth={isSmallerScreen}
                >
                  Add question
                </Button>
              </Grid>
            </Grid>
            {!isSmallerScreen && (
              <Grid item md={0.4} display={"flex"} justifyContent={"center"}>
                <Divider className="horizontal-line" orientation="vertical" />
              </Grid>
            )}
            <Grid item xs={12} md={5.8}>
              <Grid
                item
                xs={12}
                md={12}
                mb={{ xs: 1, md: 4 }}
                mt={isSmallerScreen && 2}
              >
                <Typography variant={"h6"}>Questions Preview</Typography>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>
              <Box
                height={
                  questions.length === 0
                    ? "50"
                    : isSmallerScreen
                      ? "120px"
                      : "450px"
                }
                sx={{ overflowY: "scroll" }}
              >
                {questions.length === 0 ? (
                  <Typography variant={"body1"}>
                    Add questions to preview...
                  </Typography>
                ) : (
                  questions.map((question, index) => (
                    <Grid item key={index} md={12} mb={2}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography variant="body2" fontWeight={"bold"}>{`${index + 1
                          }. ${question.question_text}`}</Typography>
                        <Tooltip title={"Remove Question"} arrow>
                          <IconButton
                            onClick={() => handleQuestionDelete(index)}
                            sx={{ mr: 2 }}
                            color="warning"
                          >
                            <DeleteForeverIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                      {question.options.map((option, index) => (
                        <Box key={index}>
                          <Typography
                            name="option_4"
                            variant="body2"
                            size="small"
                            color={option.is_correct ? "green" : "inherit"}
                          >
                            {`${String.fromCharCode(index + 65)}. ${option.option_text
                              }`}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  ))
                )}
              </Box>
            </Grid>
          </Grid>
        )}

        {activeStep === 2 && !isSmallerScreen && (
          <Grid container>
            <Grid item container xs={12} md={5.8}>
              <Grid
                item
                xs={12}
                md={12}
                mb={{ xs: 1, md: 3 }}
                mt={isSmallerScreen && 1}
              >
                <Typography variant={"h6"}>Course Details</Typography>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Course Title
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Typography
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {allFormsData?.firstFormData?.title}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Module
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Typography
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {module?.label}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Language
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Typography
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {allFormsData?.firstFormData?.language}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Duration
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Typography
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {allFormsData?.firstFormData?.duration}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Uploaded Course Files
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                {courseFileNames.length !== 0 && courseFileNames.map(filename => {
                  return <Typography
                    variant="body1"
                    mb={isSmallerScreen ? 2 : 3}
                  >
                    {filename}
                  </Typography>
                })}
              </Grid>
            </Grid>
            {!isSmallerScreen && (
              <Grid item md={0.4} display={"flex"} justifyContent={"center"}>
                <Divider className="horizontal-line" orientation="vertical" />
              </Grid>
            )}
            <Grid item xs={12} md={5.8}>
              <Grid
                item
                xs={12}
                md={12}
                mt={isSmallerScreen && 1}
                mb={!isSmallerScreen && 3}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h6" flexGrow={1}>
                    Questions
                  </Typography>
                  <StarsRoundedIcon />
                  <Typography variant="body2" fontWeight={"bold"}>
                    {`Total Marks: ${allFormsData?.secondFormData?.questions?.reduce(
                      (acc, question) => acc + question.marks,
                      0
                    )}`}
                  </Typography>
                </Box>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>
              {allFormsData?.secondFormData?.questions?.length === 0 ? (
                <Typography variant={"body1"}>
                  No questions to preview...
                </Typography>
              ) : (
                allFormsData?.secondFormData?.questions?.map(
                  (question, index) => {
                    return (
                      <Grid item md={12} mt={2} key={index}>
                        <Box
                          display={"flex"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          <Typography variant="body1" fontWeight={"bold"}>{`Q${index + 1
                            }. ${question.question_text}`}</Typography>{" "}
                          <Typography
                            variant="body2"
                            fontWeight={"bold"}
                            align="right"
                            color={"textSecondary"}
                          >{`${question.marks} Marks`}</Typography>
                        </Box>
                        {question.options.map((option, index) => (
                          <Box key={index}>
                            <Typography
                              name="option_4"
                              variant="body2"
                              size="small"
                              color={option.is_correct ? "green" : "inherit"}
                            >
                              {`${String.fromCharCode(index + 65)}. ${option.option_text
                                }`}
                            </Typography>
                          </Box>
                        ))}
                      </Grid>
                    );
                  }
                )
              )}
            </Grid>
            <Grid item sm={12} md={8}></Grid>
          </Grid>
        )}
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"end"}
        bottom={0}
        height={"100%"}
      >
        <Divider className="horizontal-line" sx={{ my: 1 }} />

        <Box
          my={3}
          display={"flex"}
          columnGap={5}
          justifyContent={"space-between"}
        >
          <RequestInProgressModal
            show={courseAddInProgress}
            headerText={"Course Creation"}
            contentText={"Course creation in progress.."}
          />
          <Button
            variant="contained"
            type="button"
            className={`primary-btn  ${activeStep === 0 ? "disabled-btn" : ""
              } ${isSmallerScreen ? "primary-small-btn" : ""}`}
            onClick={() => setActiveStep(activeStep - 1)}
            disabled={activeStep === 0}
          >
            Previous
          </Button>

          <Button
            variant="contained"
            type="submit"
            className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""
              }`}
            onClick={(e) => {
              if (activeStep === 0) {
                firstForm.handleSubmit();
              } else if (activeStep === 1) {
                handleQuestionsStepSubmit();
              } else {
                handleAddCourse();
              }
            }}
          >
            {activeStep === 2 ? "Add Course" : "Next"}
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CourseForm;
