import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import "../../../styles/BD/entityForm.scss";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import {
  searchOptionDebounceTime,
  asyncSelectNewCustomStyles,
  autoCloseToastError,
} from "../../../constants/Common";
import {
  setConsumableNameOptions,
  setVendorOptions,
  setVendorOptionsLoaded,
} from "../../../features/optionSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { category } from "../../../enums/AM/Consumable";
import { consumableSchema } from "../../../validations/AmForms/Consumable.schema.validator";
import { convertParamsToString } from "../../../utils/Request";
import { inventoryAPI } from "../../../constants/apis/AM/Assets";
import { accountTypes } from "../../../enums/Account";
import { accountApi } from "../../../constants/apis/BD/Account";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import Asterisk from "../../Misc/Asterisk";
import { addConsumable, editConsumableById } from "../../../redux/actions/am-actions/consumables.actions";


const ConsumableForm = ({ isConsumableFormOpen,
  consumableEditProp = false, isSmallerScreen, handleDrawerClose, }) => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.userProfile.profileData);

  const vendorOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.vendorOptionsLoaded
  );

  const vendorOptions = useSelector(
    (state) => state.optionsForAsyncSelect.vendorOptions
  );

  const consumableNameOptions = useSelector(
    (state) => state.optionsForAsyncSelect.consumableNameOptions
  );

  const consumablesState = useSelector(state => state.consumables);
  const selectedConsumableData = consumablesState.selectedConsumableData;
  const editConsumableLoading = consumablesState.editConsumableLoading;
  const addConsumableLoading = consumablesState.addConsumableLoading;


  const consumableForm = useFormik({
    initialValues: {
      consumable_name: "",
      category: category.c1,
      vendor_id: "",
      date_of_purchase: "",
      purchased_quantity: "",
      in_stock: null,
      make: "",
      description: "",
      model: "",
    },
    validationSchema: consumableSchema,
  });

  const [consumableNameSelected, setConsumableNameSelected] = useState(null);
  const [vendorNameSelected, setVendorNameSelected] = useState(null);

  const fetchVendorOptions = async (inputvalue) => {
    const axiosInstance = createInstance(true);
    try {
      const response = await axiosInstance.get(
        accountApi + "/vendor-options?search=" + inputvalue
      );

      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = {};

          // Add additional data based on a condition
          if (item.account_type === accountTypes.Organization) {
            additionalData = {
              account_type: item.account_type,
              gstin: item.gstin,
            };
          } else if (item.account_type === accountTypes.Individual) {
            additionalData = {
              account_type: item.account_type,
              mobile: item.mobile,
              city: item.city,
            };
          }

          return {
            value: item.account_id,
            label: item.account_name,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching vendor options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching vendor options");
    }
    return [];
  };

  const handleConsumableSubmit = async (e) => {

    const userId = profileData?.user_id;
    if (!consumableEditProp) {
      consumableForm.setValues({
        ...consumableForm.values,
        in_stock: consumableForm.values.purchased_quantity,
      });
    }
    const payload = {
      ...consumableForm.values,
      in_stock: consumableForm.values.purchased_quantity,
      created_by: userId,
    };

    let payloadConsumableData = JSON.parse(JSON.stringify(payload));
    dispatch(addConsumable(payloadConsumableData));
  };

  const handleConsumableEdit = async (e) => {
    const userId = profileData?.user_id;
    const payload = { ...consumableForm.values, updated_by: userId };
    let payloadConsumableData = JSON.parse(JSON.stringify(payload));
    dispatch(editConsumableById(selectedConsumableData?.id, payloadConsumableData))
  };

  const handleConsumableNameFocus = async () => {
    const fetchedOptions = await fetchConsumableNames("");
    dispatch(setConsumableNameOptions(fetchedOptions));
  };

  const fetchConsumableNames = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      if (consumableForm.category === "") {
        consumableForm.category = " ";
      }
      const params = {
        category: consumableForm.values.category,
        type: "Consumable",
        search: inputValue,
      };
      const response = await axiosInstance.get(
        inventoryAPI + convertParamsToString(params)
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        return formattedOptions;
      } else {
        toast.error("Error while fetching consumable name options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error wile fetching consumable name options");
      toast.error("Error while fetching consumable name options", {
        autoClose: autoCloseToastError,
      });
    }
    return [];
  };

  const loadConsumableNames = (inputValue, callback) => {
    fetchConsumableNames(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((err) => {
        errorHandler(err, "error while fetching consumable name options");
        callback([]);
      });
  };


  const loadVendorOptions = async () => {
    if (!vendorOptionsLoaded) {
      const fetchedOptions = await fetchVendorOptions("");
      dispatch(setVendorOptionsLoaded(true));
      dispatch(setVendorOptions(fetchedOptions));
    }
  }

  const handleVendorFocus = async () => {
    loadVendorOptions();
  };

  useEffect(() => {
    //fetching single consumable data
    const setEditConsumableData = () => {
      let editConsumableData = { ...selectedConsumableData };
      for (let key in { ...consumableForm.initialValues }) {
        editConsumableData[key] = editConsumableData[key] || "";
        if (key === "date_of_purchase") {
          const fetchedDate = editConsumableData[key];
          editConsumableData[key] = fetchedDate
            ? moment((new Date(fetchedDate)).toISOString()).format("YYYY-MM-DD")
            : "";
        }
      }

      consumableForm.setValues({
        consumable_name: editConsumableData?.consumable_name,
        category: editConsumableData.category,
        vendor_id: editConsumableData.vendor_id,
        date_of_purchase: editConsumableData.date_of_purchase,
        purchased_quantity: editConsumableData.purchased_quantity,
        in_stock: editConsumableData.in_stock,
        make: editConsumableData.make,
        description: editConsumableData.description,
        model: editConsumableData.model,
      });

      setConsumableNameSelected({
        label: selectedConsumableData?.consumable_name,
        value: selectedConsumableData?.consumable_id,
      });

      setVendorNameSelected({
        label: selectedConsumableData?.vendor_name,
        value: selectedConsumableData?.vendor_id,
      })

    };

    loadVendorOptions();

    if (consumableEditProp) {
      //fetch Consumable Data
      setEditConsumableData();
    } else {
      consumableForm.resetForm();
      setConsumableNameSelected("");
      setVendorNameSelected(null);
    }
  }, [consumableEditProp]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: { sm: "80vw", md: "70vw", lg: "60vw" },
            px: { xs: 2, md: 4 },
          },
        }}
        anchor={"right"}
        open={isConsumableFormOpen}
        onClose={handleDrawerClose}
      >
        <Box my={2} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h5">
            {" "}
            {consumableEditProp ? "Edit " : "New "}
            Consumable
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleDrawerClose}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider className="horizontal-line" />

        <Box my={3}>
          <Grid
            container
            rowSpacing={{ xs: 1, md: 4 }}
            columnSpacing={{ xs: 0, md: 10, lg: 15 }}
          >
            {/* Category */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Category "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select

                  name="category"
                  value={consumableForm.values.category}
                  onChange={(e) => {
                    consumableForm.handleChange(e);
                  }}
                  size="small"
                  onBlur={consumableForm.handleBlur}
                  error={
                    consumableForm.touched.category &&
                    Boolean(consumableForm.errors.category)
                  }
                  sx={{ width: "100%" }}
                >
                  {Object.keys(category).map((key) => {
                    return (
                      <MenuItem key={key} value={category[key]}>
                        {category[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
                {consumableForm.touched.category &&
                  Boolean(consumableForm.errors.category) && (
                    <Typography variant="caption" color="error" p={2}>
                      {consumableForm.errors.category}
                    </Typography>
                  )}
              </Grid>
            </Grid>

            {/* Consumable Name */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Consumable Name "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <AsyncSelect
                  className={`course-input-select ${consumableForm.touched.consumable_name &&
                    Boolean(consumableForm.errors.consumable_name)
                    ? "error-border"
                    : ""
                    }`}
                  styles={asyncSelectNewCustomStyles}
                  value={consumableNameSelected}
                  defaultOptions={consumableNameOptions}
                  onChange={(selectedOption) => {
                    setConsumableNameSelected(selectedOption);
                    consumableForm.setValues({
                      ...consumableForm.values,
                      consumable_name: selectedOption?.value,
                    });
                  }}
                  loadOptions={debounce(
                    loadConsumableNames,
                    searchOptionDebounceTime
                  )}
                  placeholder="Type to search..."
                  name="consumable_name"
                  noOptionsMessage={() =>
                    "No Consumable Names Found. Type again..."
                  }
                  isClearable
                  onFocus={handleConsumableNameFocus}
                />
                {consumableForm.touched.consumable_name &&
                  Boolean(consumableForm.errors.consumable_name) && (
                    <Typography variant="caption" color="error" p={2}>
                      {consumableForm.errors.consumable_name}
                    </Typography>
                  )}
              </Grid>
            </Grid>

            {/* Make */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Make "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  type="text"
                  id="consumable-make"
                  name="make"
                  value={consumableForm.values.make}
                  onBlur={consumableForm.handleBlur}
                  onChange={consumableForm.handleChange}
                  error={
                    consumableForm.touched.make &&
                    Boolean(consumableForm.errors.make)
                  }
                  helperText={
                    consumableForm.touched.make && consumableForm.errors.make
                  }
                  placeholder="Make"
                  sx={{ width: "100%" }}
                  size="small"
                />
              </Grid>
            </Grid>

            {/* Model */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>{"Model "}</InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  type="text"
                  id="consumable-model"
                  name="model"
                  value={consumableForm.values.model}
                  onBlur={consumableForm.handleBlur}
                  onChange={consumableForm.handleChange}
                  error={
                    consumableForm.touched.model &&
                    Boolean(consumableForm.errors.model)
                  }
                  helperText={
                    consumableForm.touched.model &&
                    consumableForm.errors.model
                  }
                  placeholder="Model"
                  sx={{ width: "100%" }}
                  size="small"
                />
              </Grid>
            </Grid>

            {/* Date of Purchase */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Date of Purchase "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  type="date"
                  id="consumable-date-of-purchase"
                  name="date_of_purchase"
                  placeholder="Date of Purchase"
                  value={consumableForm.values.date_of_purchase}
                  onBlur={consumableForm.handleBlur}
                  onChange={consumableForm.handleChange}
                  error={
                    consumableForm.touched.date_of_purchase &&
                    Boolean(consumableForm.errors.date_of_purchase)
                  }
                  helperText={
                    consumableForm.touched.date_of_purchase &&
                    consumableForm.errors.date_of_purchase
                  }
                  sx={{ width: "100%" }}
                  size="small"
                />
              </Grid>
            </Grid>

            {/* Purchased Quantity */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Purchased Quantity "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  type="text"
                  id="consumable-purchased-quantity"
                  name="purchased_quantity"
                  value={consumableForm.values.purchased_quantity}
                  onBlur={consumableForm.handleBlur}
                  onChange={consumableForm.handleChange}
                  error={
                    consumableForm.touched.purchased_quantity &&
                    Boolean(consumableForm.errors.purchased_quantity)
                  }
                  helperText={
                    consumableForm.touched.purchased_quantity &&
                    consumableForm.errors.purchased_quantity
                  }
                  sx={{ width: "100%" }}
                  size="small"
                />
              </Grid>
            </Grid>

            {/* Vendor */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Vendor "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  name="vendor_id"
                  onFocus={handleVendorFocus}
                  label={vendorNameSelected?.label}
                  onChange={(e) => {
                    consumableForm.handleChange(e);
                    setVendorNameSelected((_) => {
                      return {
                        value: e.target.value,
                        label: vendorOptions[e.target.value]
                      };
                    })
                  }}
                  onBlur={consumableForm.handleBlur}
                  value={Boolean(vendorNameSelected?.value) && vendorOptionsLoaded ? vendorNameSelected?.value : ""}
                  error={
                    consumableForm.touched.vendor_id &&
                    Boolean(consumableForm.errors.vendor_id)
                  }
                  sx={{ width: "100%" }}
                  size="small"
                >

                  <MenuItem key="empty_vendor" value="">Select</MenuItem>
                  {vendorOptions &&
                    vendorOptions.map((vendor) => {
                      return (
                        <MenuItem key={vendor.value} value={vendor.value}>
                          {vendor.label}
                        </MenuItem>
                      );
                    })}
                </Select>

                {consumableForm.touched.vendor_id &&
                  Boolean(consumableForm.errors.vendor_id) && (
                    <Typography variant="caption" color="error" p={2}>
                      {consumableForm.errors.vendor_id}
                    </Typography>
                  )}
              </Grid>
            </Grid>

            {/* Description */}
            <Grid item container xs={12}>
              <Grid item xs={12} lg={2.2}>
                <InputLabel className={"fw-bold"}>
                  {"Description "}
                </InputLabel>
              </Grid>
              <Grid item xs={12} lg={9.8}>
                <TextField
                  id="course-description"
                  name="description"
                  placeholder="Description"
                  value={consumableForm.values.description}
                  onChange={consumableForm.handleChange}
                  onBlur={consumableForm.handleBlur}
                  error={
                    consumableForm.touched.description &&
                    Boolean(consumableForm.errors.description)
                  }
                  helperText={
                    consumableForm.touched.description &&
                    consumableForm.errors.description
                  }
                  sx={{ mb: 2, width: "100%" }}
                  className={"fw-bold"}
                  size="large"
                  multiline
                />
              </Grid>
              <Typography variant="body2" my={2}>
                <Asterisk />
                {" Mandatory Fields"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"end"}
          bottom={0}
        >
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          <Box my={3} display={"flex"} columnGap={5}>

            <Button
              variant="contained"
              type="submit"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={(e) => {
                if (consumableEditProp) handleConsumableEdit(e);
                else handleConsumableSubmit(e);
              }}
              disabled={addConsumableLoading || editConsumableLoading}
              endIcon={
                (addConsumableLoading || editConsumableLoading) && (
                  <CircularProgress size={25} />
                )
              }
            >
              Save
            </Button>

            <Button
              variant="contained"
              type="button"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={handleDrawerClose}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ConsumableForm;
