const messages = {
    userIdDefault: "Please select proper user",
    frequencyDefault: "Please select proper frequency",
    descriptionDefault: "Enter description",

    dealsAmountPrecision: "Must be at max 2 digits after the decimal point",
    dealsAmountBase: "Enter proper deals amount",
    dealsAmountMin: "Deals amount must be greater than 0",
    dealsAmountRequired: "Deals amount is required",


    dealsCountInteger: "Deals count must be an integer",
    dealsCountBase: "Enter proper deals count",
    dealsCountRequired: "Deals count is required",
    dealsCountMin: "Deals count must be greater than 0",
};

export { messages };
