import { messages } from "../../constants/validationMessages/BdForms/NewAccount";
import { categories, statuses, occupations, industries, noOfEmployees } from "../../enums/Account";

const Joi = require("joi");

const precisionRule = (value, helpers) => {
    if (value !== undefined) {
        const decimalPart = (value.toString().split(".")[1] || "").length;
        if (decimalPart > 2) {
            return helpers.error("number.precision");
        }
    }
    return value;
};

const organizationAccountSchema = Joi.object().keys({
    account_name: Joi.string().min(3).max(255).required().messages({
        "string.min": messages.accountNameMin,
        "string.max": messages.accountNameMax,
        "string.empty": messages.accountNameEmpty,
    }),

    industry: Joi.valid(...Object.values(industries))
        .required()
        .messages({
            "any.only": messages.industryDefault,
        }),

    status: Joi.valid(...Object.values(statuses))
        .required()
        .messages({
            "any.only": messages.statusDefault,
        }),

    account_category: Joi.valid(...Object.values(categories))
        .required()
        .messages({
            "any.only": messages.categoryDefault,
        }),

    mobile: Joi.string()
        .allow("")
        .regex(/^(\+91[-\s]?)?[0-9]\d{1,5}[-\s]?\d{5,8}$/)
        .min(8)
        .max(15)
        .messages({
            "string.min": messages.phoneDefault,
            "string.max": messages.phoneDefault,
            "string.pattern.base": messages.phoneDefault,
        }),

    email: Joi.string()
        .allow("")
        .min(3)
        .max(255)
        .email({ tlds: { allow: false } })
        .messages({
            "string.min": messages.emailMin,
            "string.max": messages.emailMax,
            "string.empty": messages.emailEmpty,
            "email.base": messages.emailInvalid,
            "string.email": messages.emailInvalid,
        }),

    gstin: Joi.string().allow("").max(15).min(15).messages({
        "string.max": messages.gstinlength,
        "string.min": messages.gstinlength,
        "string.empty": messages.gstinEmpty,
    }),

    pan: Joi.string().allow("").max(10).min(10).messages({
        "string.max": messages.panLength,
        "string.min": messages.panLength,
        "string.empty": messages.panEmpty,
    }),

    annual_revenue: Joi.number().allow("").min(0).custom(precisionRule, "custom precision").max(999999999999999999).messages({
        "number.max": messages.annualRevenueMax,
        "number.min": messages.annualRevenueMin,
        "number.precision": messages.annualRevenuePrecision,
        "number.base": messages.annualRevenueBase,
    }),

    no_of_employees: Joi.valid(...Object.values(noOfEmployees))
        .allow("")
        .messages({
            "any.only": messages.noOfEmployeesDefault,
        }),

    country: Joi.string().required().max(100).messages({
        "string.max": messages.countryMax,
        "string.empty": messages.countryEmpty,
    }),
    state: Joi.string().required().max(100).messages({
        "string.max": messages.stateMax,
        "string.empty": messages.stateEmpty,
    }),
    city: Joi.string().required().max(100).messages({
        "string.max": messages.cityMax,
        "string.empty": messages.cityEmpty,
    }),
    pincode: Joi.string().min(3).max(20).required().messages({
        "string.min": messages.pincodeMin,
        "string.max": messages.pincodeMax,
        "string.empty": messages.pincodeEmpty,
    }),

    street_address: Joi.string().allow("").max(1024).messages({
        "string.max": messages.addressMax,
    }),

    website: Joi.string()
        .allow("")
        .min(3)
        .max(255)
        .regex(/^(https?:\/\/|www\.)/i)
        .messages({
            "string.min": messages.websiteMin,
            "string.max": messages.websiteMax,
            "string.empty": messages.websiteEmpty,
            "string.pattern.base": messages.websiteRegex,
        }),

    description: Joi.string().allow("").messages({
        "string.empty": messages.descriptionEmpty,
    }),
});

const individualAccountSchema = Joi.object().keys({
    account_name: Joi.string().min(3).max(255).required().messages({
        "string.min": messages.accountNameMin,
        "string.max": messages.accountNameMax,
        "string.empty": messages.accountNameEmpty,
    }),

    status: Joi.valid(...Object.values(statuses))
        .required()
        .messages({
            "any.only": messages.statusDefault,
        }),

    account_category: Joi.valid(...Object.values(categories))
        .required()
        .messages({
            "any.only": messages.categoryDefault,
        }),

    mobile: Joi.string()
        .required()
        .regex(/^\d{10}$/)
        .min(10)
        .max(10)
        .messages({
            "string.empty": messages.mobileEmpty,
            "string.min": messages.mobileDefault,
            "string.max": messages.mobileDefault,
            "string.pattern.base": messages.mobileRegex,
        }),

    email: Joi.string()
        .allow("")
        .min(3)
        .max(255)
        .email({ tlds: { allow: false } })
        .messages({
            "string.min": messages.emailMin,
            "string.max": messages.emailMax,
            "string.empty": messages.emailEmpty,
            "email.base": messages.emailInvalid,
            "string.email": messages.emailInvalid,
        }),

    occupation: Joi.valid(...Object.values(occupations))
        .allow("")
        .messages({
            "any.only": messages.occupationDefault,
        }),

    dob: Joi.date().allow("").max(new Date()).messages({
        "date.base": messages.dobDefault,
        "date.max": messages.dobDefault,
    }),

    aadhar: Joi.string().allow("").max(12).min(12).messages({
        "string.max": messages.aadharLength,
        "string.min": messages.aadharLength,
        "string.empty": messages.aadharEmpty,
    }),

    pan: Joi.string().allow("").max(10).min(10).messages({
        "string.max": messages.panLength,
        "string.min": messages.panLength,
        "string.empty": messages.panEmpty,
    }),

    country: Joi.string().required().max(100).messages({
        "string.max": messages.countryMax,
        "string.empty": messages.countryEmpty,
    }),
    state: Joi.string().required().max(100).messages({
        "string.max": messages.stateMax,
        "string.empty": messages.stateEmpty,
    }),
    city: Joi.string().required().max(100).messages({
        "string.max": messages.cityMax,
        "string.empty": messages.cityEmpty,
    }),
    pincode: Joi.string().min(3).max(20).required().messages({
        "string.min": messages.pincodeMin,
        "string.max": messages.pincodeMax,
        "string.empty": messages.pincodeEmpty,
    }),

    street_address: Joi.string().allow("").max(1024).messages({
        "string.max": messages.addressMax,
    }),

    website: Joi.string()
        .allow("")
        .min(3)
        .max(255)
        .regex(/^(https?:\/\/|www\.)/i)
        .messages({
            "string.min": messages.websiteMin,
            "string.max": messages.websiteMax,
            "string.empty": messages.websiteEmpty,
            "string.pattern.base": messages.websiteRegex,
        }),

    description: Joi.string().allow("").messages({
        "string.empty": messages.descriptionEmpty,
    }),
});

export { individualAccountSchema, organizationAccountSchema };
