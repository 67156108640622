import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  tickets: {},
  ticketsLoading: false,
  editTicketLoading: false,
  addTicketLoading: false,
  deleteTicketLoading: false,
  selectedTicketId: null,
  message: null,
  error: null,
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    getTicketsRequest(state) {
      state.ticketsLoading = true;
    },
    getTicketsSuccess(state, action) {
      state.ticketsLoading = false;
      state.tickets = action.payload;
      state.tickets.rows.forEach((row) => {
        row.id = row.ticket_id;
      });
    },
    getTicketsFail(state) {
      state.ticketsLoading = false;
    },

    editTicketRequest(state) {
      state.editTicketLoading = true;
    },
    editTicketSuccess(state, action) {
      const editedTicket = action.payload;
      editedTicket.id = editedTicket.ticket_id;
      state.tickets.rows = state.tickets.rows.map((row) => {
        if (row.id === editedTicket.id) {
          return editedTicket;
        }
        return row;
      })
      state.editTicketLoading = false;
    },
    editTicketFail(state) {
      state.editTicketLoading = false;
    },
    addTicketRequest(state) {
      state.addTicketLoading = true;
    },
    addTicketSuccess(state, action) {
      const addedTicket = action.payload;
      addedTicket.id = addedTicket.ticket_id;
      state.tickets.rows = [addedTicket, ...state.tickets?.rows]
      state.tickets.meta.total++;
      state.addTicketLoading = false;
    },
    addTicketFail(state) {
      state.addTicketLoading = false;
    },
    setSelectedTicketId(state, action) {
      state.selectedTicketId = action.payload;
    },
    deleteTicketRequest(state) {
      state.deleteTicketLoading = true;
    },
    deleteTicketSuccess(state, action) {
      const deletedTicketId = action.payload;
      state.tickets.rows = state.tickets.rows.filter((row) => {
        return row.id !== deletedTicketId;
      })
      state.tickets.meta.total--;
      state.deleteTicketLoading = false;
    },
    deleteTicketFail(state) {
      state.deleteTicketLoading = false;
    },
    clearError(state) {
      state.error = null;
    },
    clearMessage(state) {
      state.message = null;
    },
  },
});

export const ticketReducer1 = ticketsSlice.reducer;

export const ticketActions = ticketsSlice.actions;
