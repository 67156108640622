const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    accountAdded: false,
    accountEdited: false,
    accountEdit: false,
    accountsId: "",
    accountEditOpenToggle: false,
    accountAddOpenToggle: false,
};

export const accountSlice = createSlice({
    name: "account",
    initialState,
    reducers: {
        setAccountAdded: (state, action) => {
            state.accountAdded = action.payload;
        },
        setAccountEdited: (state, action) => {
            state.accountEdited = action.payload;
        },
        setAccountEdit: (state, action) => {
            state.accountEdit = action.payload;
        },
        setAccountsId: (state, action) => {
            state.accountsId = action.payload;
        },
        setAccountEditOpenToggle: (state, action) => {
            state.accountEditOpenToggle = !state.accountEditOpenToggle;
        },

        setAccountAddOpenToggle: (state, action) => {
            state.accountAddOpenToggle = !state.accountAddOpenToggle;
        },
    },
});

export const { setAccountAdded, setAccountEdited, setAccountEdit, setAccountsId, setAccountAddOpenToggle, setAccountEditOpenToggle } =
    accountSlice.actions;

export default accountSlice.reducer;
