const messages = {
    assetNameDefault : "Asset name cannot be empty",
    assetNameMax: "Maximum 255 characters are allowed",

    make : "Please select make",
    makeValid : "Please select make",

    dateOfPurchase : "Please select date of purchase",

    model : "Please select model",
    modelValid : "Please select model",

    assignedOn : "Please select valid date to assign asset",
    assignedTill : "Please select valid date for assigned till",

    assignedTo : "Please select user to assign asset",
    
    assetTag: "Please enter asset tag", 
    assetTagDefault: "Asset tag should not be empty"
}

export {messages};