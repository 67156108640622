import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import { setTaskAdded, setTaskAddedForAccountId, setTaskEdit, setTaskEdited, setTaskId } from "../../../features/BD/taskFormSlice";
import { accountApi } from "../../../constants/apis/BD/Account";
import { taskApi } from "../../../constants/apis/BD/Task";
import { userOptionsApi } from "../../../constants/apis/User";
import { searchOptionDebounceTime, asyncSelectNewCustomStyles, minCharsToLoadOptions, autoCloseToastError } from "../../../constants/Common";
import { types, priorities, statuses } from "../../../enums/Task";
import { taskSchema } from "../../../validations/BdForms/Task";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { setUserOptionsLoaded, setUserOptions, setAccountOptionsLoaded, setAccountOptions } from "../../../features/optionSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { userRoles } from "../../../enums/Auth";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { accountTypes } from "../../../enums/Account";
import { accountOption, userOption } from "../../AsyncSelectOption/CustomizedOptions";
import { Box, Button, CircularProgress, Divider, Drawer, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Asterisk from "../../Misc/Asterisk";
import { addTask, editTaskById } from "../../../redux/actions/bd-actions/tasks.action";

export default function TaskForm({ isOpenProp, handleClose, taskEditProp, accountSpecific=false, accountData, forDashboard=false }) {
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

    const axiosInstance = createInstance(true);
    const dispatch = useDispatch();
    const initialTaskData = {
        task_title: "",
        task_type: "",
        assigned_to: "",
        priority: "",
        status: statuses.NotStarted,
        description: "",
        account_id: "",
    };

    const [taskData, setTaskData] = useState(initialTaskData);
    const [taskFormErrors, setTaskFormErrors] = useState({});
    const [taskAccount, setTaskAccount] = useState(null);
    const [taskAssignedTo, setTaskAssignedTo] = useState(null);
    const [fetchingTaskData, setFetchingTaskData] = useState(false);
    const [edit, setEdit] = useState(taskEditProp || false);

    const taskEdit = useSelector((state) => state.taskForm.taskEdit);
    const taskId = useSelector((state) => state.taskForm.taskId);
    const taskAccountId = useSelector((state) => state.taskForm.taskAccountId);
    const taskAccountName = useSelector((state) => state.taskForm.taskAccountName);

    const taskEditOpenToggle = useSelector((state) => state.taskForm.taskEditOpenToggle);
    const taskAddOpenToggle = useSelector((state) => state.taskForm.taskAddOpenToggle);
    const accountOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.accountOptionsLoaded);
    const userOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.userOptionsLoaded);
    const accountOptions = useSelector((state) => state.optionsForAsyncSelect.accountOptions);
    const userOptions = useSelector((state) => state.optionsForAsyncSelect.userOptions);

    const profileData = useSelector((state) => state.userProfile.profileData);

    const taskState = useSelector((state) => state.tasks);

    const handleTaskForm = (e) => {
        setTaskFormErrors({ ...taskFormErrors, [e.target.name]: "" });
        setTaskData({ ...taskData, [e.target.name]: e.target.value });
    };

    const role = Number(profileData?.role_id);

    const fetchUserOptions = async (inputValue) => {
        try {
            const response = await axiosInstance.get(userOptionsApi + "?search=" + inputValue);
            if (response.data && response.data.success) {
                const formattedOptions = response.data.data.map((item) => {
                    let additionalData = { location: item.location, mobile: item.mobile };

                    return {
                        value: item.user_id,
                        label: item.assign_to,
                        additionalData: additionalData, // Include additional data in the result
                    };
                });

                return formattedOptions;
            } else {
                toast.error("Error while fetching assigned to options", { autoClose: autoCloseToastError });
            }
        } catch (err) {
            errorHandler(err, "Error while fetching assigned to options");
        }
        return [];
    };

    const fetchAccountOptions = async (inputvalue) => {
        try {
            const response = await axiosInstance.get(accountApi + "/accountoptions?search=" + inputvalue);

            if (response.data && response.data.success) {
                const formattedOptions = response.data.data.map((item) => {
                    let additionalData = {};

                    // Add additional data based on a condition
                    if (item.account_type === accountTypes.Organization) {
                        additionalData = { account_type: item.account_type, gstin: item.gstin };
                    } else if (item.account_type === accountTypes.Individual) {
                        additionalData = { account_type: item.account_type, mobile: item.mobile, city: item.city };
                    }

                    return {
                        value: item.account_id,
                        label: item.account_name,
                        additionalData: additionalData, // Include additional data in the result
                    };
                });

                return formattedOptions;
            } else {
                toast.error("Error while fetching account options", { autoClose: autoCloseToastError });
            }
        } catch (err) {
            errorHandler(err, "Error while fetching account options");
        }
        return [];
    };

    const loadUserOptions = (inputValue, callback) => {
        if (inputValue.length < minCharsToLoadOptions) {
            return callback([]);
        }
        fetchUserOptions(inputValue)
            .then((options) => callback(options))
            .catch((error) => {
                console.error(error);
                callback([]);
            });
    };

    const loadAccountOptions = (inputValue, callback) => {
        if (inputValue.length < minCharsToLoadOptions) {
            return callback([]);
        }
        fetchAccountOptions(inputValue)
            .then((options) => callback(options))
            .catch((error) => {
                console.error(error);
                callback([]);
            });
    };

    const closeTaskForm = () => {
        handleDrawerClose();
    };

    const handleTaskEdit = async (e) => {
        e.preventDefault();
        const userId = profileData?.user_id;
        const payload = { ...taskData, updated_by: userId };
        const validation = taskSchema.validate(taskData, { abortEarly: false });
        if (validation.error) {
            setTaskFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setTaskFormErrors(currentErrors);
        } else {
            try {
                dispatch(editTaskById(taskState?.selectedTask?.id, payload, accountSpecific, forDashboard));
            } catch (err) {
                errorHandler(err, "Failed to edit task");
            }
            dispatch(setTaskId(null));
        }
    };

    const handleTaskSubmit = async (e) => {
        e.preventDefault();
        const userId = profileData?.user_id;
        const payload = { ...taskData, created_by: userId };
        const validation = taskSchema.validate(taskData, { abortEarly: false });
        if (validation.error) {
            setTaskFormErrors({});
            const validationErrors = validation.error.details.reduce((acc, error) => {
                const fieldName = error.path[0];
                acc[fieldName] = error.message;
                return acc;
            }, {});

            setTaskFormErrors(validationErrors);
        } else {
            setTaskFormErrors({});
            try {
                dispatch(addTask(payload, accountSpecific));
            } catch (err) {
                errorHandler(err, "Task addition failed");
            }
        }
    };

    useEffect(() => {

        setTaskFormErrors({});
        //fetch single task data
        const fetchTaskData = async (taskId) => {
            setFetchingTaskData(true);
            try {
                const fetchedTaskData = taskState?.selectedTask;
                const editTaskData = {};
                for (let key in initialTaskData) {
                    editTaskData[key] = fetchedTaskData[key] || "";
                }

                setTaskAccount({
                    value: fetchedTaskData.account_id,
                    label: fetchedTaskData.account_name,
                });

                if (fetchedTaskData.assigned_to) {
                    setTaskAssignedTo({
                        value: fetchedTaskData.assigned_to,
                        label: fetchedTaskData.assigned_to_name,
                    });
                } else {
                    setTaskAssignedTo(null);
                }
                setTaskData(editTaskData);
            } catch (err) {
                errorHandler(err, "Failed to fetch task data");
            }
            setFetchingTaskData(false);
        };

        const assignedTo = Number(profileData?.user_id);
        const assignedToName = profileData?.first_name.concat(" ").concat(profileData?.last_name);

        if (edit) {
            fetchTaskData(taskId);
        } else {
            setTaskAccount(null);
            setTaskAssignedTo({
                value: assignedTo,
                label: assignedToName,
            });
            setTaskData({ ...initialTaskData, assigned_to: assignedTo });
        }
        if (accountSpecific && !edit) {
            setTaskAccount({ value: accountData?.account_id, label: accountData?.account_name });
            setTaskAssignedTo({
                value: assignedTo,
                label: assignedToName,
            });
            setTaskData({ ...initialTaskData, account_id: accountData?.account_id, assigned_to: assignedTo });
        }
    }, []);

    const handleAccountFocus = async () => {
        if (!accountOptionsLoaded) {
            const fetchedOptions = await fetchAccountOptions("");
            dispatch(setAccountOptionsLoaded(true));
            dispatch(setAccountOptions(fetchedOptions));
        }
    };

    const handleUserFocus = async () => {
        if (!userOptionsLoaded) {
            const fetchedOptions = await fetchUserOptions("");
            dispatch(setUserOptionsLoaded(true));
            dispatch(setUserOptions(fetchedOptions));
        }
    };

    const [isOpen, setIsOpen] = useState(isOpenProp);
    const handleDrawerClose = _ => {
        handleClose();
        setIsOpen(false);
    }

    return (
        <Drawer
            PaperProps={{
                sx: {
                width: {  sm: "70vw", md: "85vw", lg: "75vw", xl: "65vw" },
                px: { xs: 2, md: 4 },
                },
            }}
            anchor={"right"}
            open={isOpen}
            onClose={handleDrawerClose}
            >
            <Box
                my={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="h5">{edit ? "Edit " : "New "} Task</Typography>
                <IconButton aria-label="close" onClick={handleDrawerClose} color="primary" >
                <CloseIcon />
                </IconButton>
            </Box>

            <Divider className="horizontal-line" />
            {
            fetchingTaskData ?
                <LoadingSpinner /> :
                <Box my={!isSmallerScreen ? 3 : 2}>
                    {/* Content */}
                    <Grid container>
                        {/* Account Name */}
                        <Grid item xs={12} md={2} pr={2} >
                            <InputLabel className="fw-bold">
                                {"Account Name "}
                                <Asterisk />
                            </InputLabel>
                        </Grid>
                        { (edit || accountSpecific) &&
                            <Grid item xs={12} md={10} mb={2}>
                                <TextField
                                    value={taskAccount?.label}
                                    size="small"
                                    disabled
                                    fullWidth
                                />
                            </Grid>
                        }
                        { (!edit && !accountSpecific) && 
                            <Grid item xs={12} md={10} mb={2}>
                                <AsyncSelect
                                    className={`async-select-input ${Boolean(taskFormErrors?.account_id) ? " error-border" : ""}`}
                                    styles={asyncSelectNewCustomStyles}
                                    onChange={(selectedOption) => {
                                        setTaskFormErrors({ ...taskFormErrors, account_id: "" });
                                        setTaskAccount(selectedOption);
                                        if (selectedOption) {
                                            setTaskData({ ...taskData, account_id: selectedOption.value });
                                        } else {
                                            setTaskData({ ...taskData, account_id: null });
                                        }
                                    }}
                                    value={taskAccount}
                                    defaultOptions={accountOptions}
                                    loadOptions={debounce(loadAccountOptions, searchOptionDebounceTime)}
                                    placeholder="Type to search..."
                                    name="account_id"
                                    noOptionsMessage={() => "No accounts found. Type again..."}
                                    isClearable
                                    onFocus={handleAccountFocus}
                                    components={{ Option: accountOption }}
                                />
                                {   taskFormErrors?.account_id &&
                                    <Typography variant="caption" color="error" p={2} >
                                        {taskFormErrors?.account_id}
                                    </Typography>
                                }
                            </Grid>
                        }

                        {/* Task Title */}
                        <Grid item xs={12} md={2} pr={2}>
                            <InputLabel className="fw-bold">
                                {"Task Title "}
                                <Asterisk />
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12} md={10} mb={2}>
                            <TextField
                                value={Boolean(taskData?.task_title) ? taskData?.task_title : ""}
                                name="task_title"
                                onChange={(e) => handleTaskForm(e)}
                                placeholder="Task Title"
                                size="small"
                                error={Boolean(taskFormErrors?.task_title)}
                                helperText={taskFormErrors?.task_title || ""}
                                fullWidth
                            />
                        </Grid>

                        {/* Double Column */}
                        <Grid container item md={12}>
                            {/* Status */}
                            <Grid item xs={12} md={2} pr={2}>
                                <InputLabel className="fw-bold">
                                    {"Status "}
                                    <Asterisk />
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={3.5} mb={2}>
                                <Select
                                    name="status"
                                    value={Boolean(taskData?.status) ? taskData?.status : ''} 
                                    onChange={(e) => handleTaskForm(e)}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    error={Boolean(taskFormErrors?.status)}
                                    displayEmpty
                                >
                                    <MenuItem key={'Select'} value="">
                                    {'Select Status'}
                                    </MenuItem>
                                    {Object.keys(statuses).map((key) => (
                                        <MenuItem key={key} value={statuses[key]}>
                                        {statuses[key]}
                                        </MenuItem>
                                    ))}
                                </Select>
                                { taskFormErrors?.status &&
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {taskFormErrors?.status}
                                    </Typography>
                                }
                            </Grid>

                            {/* Priority */}
                            <Grid item xs={12} md={3} pr={2}>
                                <InputLabel className="fw-bold" sx={{textAlign: !isSmallerScreen ? 'right' : 'inherit'}}>
                                    {"Priority "}
                                    <Asterisk />
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={3.5} mb={2}>
                                <Select
                                    name="priority"
                                    value={Boolean(taskData?.priority) ? taskData?.priority : ""}
                                    onChange={(e) => handleTaskForm(e)}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    error={Boolean(taskFormErrors?.priority)}
                                    displayEmpty
                                >
                                    <MenuItem key={'Select'} value="">
                                    {'Select Priority'}
                                    </MenuItem>
                                    {Object.keys(priorities).map((key) => (
                                        <MenuItem key={key} value={priorities[key]}>
                                        {priorities[key]}
                                        </MenuItem>
                                    ))}
                                </Select>
                                { taskFormErrors?.priority &&
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {taskFormErrors?.priority}
                                    </Typography>
                                }
                            </Grid>

                            {/* Type */}
                            <Grid item xs={12} md={2} pr={2}>
                                <InputLabel className="fw-bold">
                                    {"Type "}
                                    <Asterisk />
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={3.5} mb={2}>
                                <Select
                                    name="task_type"
                                    value={(taskData?.task_type) ? taskData?.task_type : ""}
                                    onChange={(e) => handleTaskForm(e)}
                                    size="small"
                                    sx={{ width: "100%" }}
                                    error={Boolean(taskFormErrors?.task_type)}
                                    displayEmpty
                                >
                                    <MenuItem key={'Select'} value="">
                                    {'Select Type'}
                                    </MenuItem>
                                    {Object.keys(types).map((key) => (
                                        <MenuItem key={key} value={types[key]}>
                                        {types[key]}
                                        </MenuItem>
                                    ))}
                                </Select>
                                { taskFormErrors?.task_type &&
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {taskFormErrors?.task_type || ""}
                                    </Typography>
                                }
                            </Grid>

                            { role !== userRoles.User ?
                            <>
                                {/* Assigned To */}
                                <Grid item xs={12} md={3} pr={2}>
                                    <InputLabel className="fw-bold" sx={{textAlign: !isSmallerScreen ? 'right' : 'inherit'}}>
                                        {"Assigned To "}
                                        <Asterisk />
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={3.5} mb={2}>
                                    <AsyncSelect
                                        className={`async-select-input ${Boolean(taskFormErrors?.assigned_to) ? " error-border" : ""}`}
                                        styles={asyncSelectNewCustomStyles}
                                        onChange={(selectedOption) => {
                                            setTaskFormErrors({ ...taskFormErrors, assigned_to: "" });
                                            setTaskAssignedTo(selectedOption);
                                            if (selectedOption) {
                                                setTaskData({ ...taskData, assigned_to: selectedOption.value });
                                            } else {
                                                setTaskData({ ...taskData, assigned_to: null });
                                            }
                                        }}
                                        value={taskAssignedTo}
                                        defaultOptions={userOptions || []}
                                        loadOptions={debounce(loadUserOptions, searchOptionDebounceTime)}
                                        placeholder="Type to search..."
                                        name="assigned_to"
                                        noOptionsMessage={() => "No user found. Type again..."}
                                        isClearable
                                        onFocus={handleUserFocus}
                                        components={{Option: userOption}}
                                    />
                                    {   taskFormErrors?.assigned_to &&
                                        <Typography variant="caption" color="error" p={2} >
                                            {taskFormErrors?.assigned_to}
                                        </Typography>
                                    }
                                </Grid>
                            </> :
                            <Grid md={6.5}></Grid>
                            }

                            {/* Description */}
                            <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold">
                                        {"Description"}
                                    </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={10} mb={2}>
                                <TextField
                                    name="description"
                                    onChange={(e) => handleTaskForm(e)}
                                    placeholder="Description"
                                    value={Boolean(taskData?.description) ?  taskData?.description : ""}
                                    error={Boolean(taskFormErrors?.description)}
                                    helperText={taskFormErrors?.description || ""}
                                    size="small"
                                    fullWidth
                                    multiline
                                    rows={3}
                                />
                            </Grid>

                        </Grid>
                    </Grid>
                </Box>
            }
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
                height="100%"
            >
                <Divider className="horizontal-line" sx={{ my: 1 }} />

                <Box
                my={3}
                display={"flex"}
                justifyContent={{xs: "space-between", sm: "flex-start"}}
                columnGap={5}
                >
                <Button
                    variant="contained"
                    type="button"
                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                    onClick={handleDrawerClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                    onClick={(e) => {
                        if (edit) handleTaskEdit(e);
                        else handleTaskSubmit(e);
                    }}
                    disabled={taskState?.editTaskLoading || taskState?.addTaskLoading}
                    endIcon={(taskState?.editTaskLoading || taskState?.addTaskLoading) && <CircularProgress size={25}/>}
                >
                    Save
                </Button>
                </Box>
            </Box>
        </Drawer>
    );
}
