import { messages } from "../../constants/validationMessages/BdForms/ImportUsers";
const Joi = require("joi");

const ImportLocationsSchema = Joi.object().keys({
    account_id: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            "number.min": messages.accountId,
            "number.base": messages.accountId,
            "any.only": messages.accountId
        }),

    file_extension: Joi.string().required().valid(".xlsx", ".xls", ".ods").messages({
        "string.empty": messages.fileExtensionEmpty,
        "any.only": messages.fileExtensionImproper
    })
});

export { ImportLocationsSchema };