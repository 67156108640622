import { React, useState, useEffect } from "react";
import { appNames } from "../../../constants/AppNames";
import tsSidebarMenus from "../../../data/json/tsSidebarMenus.json";
import { userRoles } from "../../../enums/Auth";
import {
  ticket_stages,
  priority,
  status,
  type,
  months,
} from "../../../enums/TS/Ticket";
import BdCounts from "../../../components/BdCounts/BdCounts";
import { ticketApi } from "../../../constants/apis/TS/Tickets";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { convertParamsToString } from "../../../utils/Request";
import { Bar, Pie } from "react-chartjs-2";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";

const TsDashboard = () => {
  const navigate = useNavigate();
  const profileData = useSelector((state) => state.userProfile.profileData);
  const canCreateTicket = () => {
    return [userRoles.Admin, userRoles.User].includes(profileData?.role_id);
  };

  const isUser = () => {
    return [userRoles.User].includes(profileData?.role_id);
  };

  const appSidebar = JSON.parse(JSON.stringify(tsSidebarMenus));
  appSidebar.menuList[0].currentPage = true;
  if (!canCreateTicket()) {
    appSidebar.menuList[1].addEntityButton = false;
  }
  if (isUser()) {
    appSidebar.menuList.splice(0, 1);
    appSidebar.menuList.splice(1, 1);
  }
  const initialDealCountCards = [
    {
      type: "main",
      iconImagePath: "/assets/images/ticket.svg",
      altText: "deals-icon",
      count: 0,
      textLabel: "Total Tickets",
    },
    {
      type: "normal",
      count: 0,
      textLabel: ticket_stages.openTickets,
    },
    {
      type: "normal",
      count: 0,
      textLabel: ticket_stages.overdueTickets,
    },
    {
      type: "normal",
      count: 0,
      textLabel: ticket_stages.resolvedTickets,
    },
  ];

  const initialTicketStatusChartData = {
    labels: [
      status.open,
      status.closed,
      status.in_progres,
      status.waiting_for_input,
    ],
    datasets: [
      {
        label: "Tickets By Status",
        data: [300, 200, 100, 50],
        backgroundColor: ["#D45B43", "#5383EC", "#8C98A0", "#E8EBEF"],
        borderWidth: 1,
        datalabels: {
          color: "#000000",
          font: {
            weight: "bold",
          },
        },
      },
    ],
  };
  const initialTicketPriorityChartData = {
    labels: [priority.low, priority.medium, priority.high],
    datasets: [
      {
        label: "Tickets By Priority",
        data: [300, 100, 50],
        backgroundColor: ["#D45B43", "#5383EC", "#8C98A0"],
        borderWidth: 1,
        datalabels: {
          color: "#000000",
          font: {
            weight: "bold",
          },
        },
      },
    ],
  };
  const initialTicketTypeChartData = {
    labels: [type.change, type.problem],
    datasets: [
      {
        label: "Tickets By Type",
        data: [300, 100],
        backgroundColor: ["#D45B43", "#5383EC"],
        borderWidth: 1,
        datalabels: {
          color: "#000000",
          font: {
            weight: "bold",
          },
        },
      },
    ],
  };

  const [countCards, setCountCards] = useState(initialDealCountCards);
  const [dataLoading, setDataLoading] = useState(true);
  const [ticketStatusChartData, setTicketStatusChartData] = useState(
    initialTicketStatusChartData
  );
  const [ticketPriorityChartData, setTicketPriorityChartData] = useState(
    initialTicketPriorityChartData
  );
  const [ticketTypeChartData, setTicketTypeChartData] = useState(
    initialTicketTypeChartData
  );

  const deatChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: "right",
      },
    },
    height: 300,
    width: 300,
  };

  const initialTaskGraphData = {
    labels: Object.values(months),
    datasets: [
      {
        label: "New",
        data: [1, 2, 3],
        backgroundColor: "#5383EC",
      },
      {
        label: "Closed",
        data: [0, 0, 1],
        backgroundColor: "#E8EBEF",
      },
    ],
  };
  const [tasksGraphData, setTaskGraphData] = useState(initialTaskGraphData);
  const taskGraphOptions = {
    barPercentage: 1,
    barThickness: 23,
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      x: {
        stacked: true,
        grid: {
          display: false,
        },
      },
      y: {
        stacked: true,
        type: "linear",
        grid: {
          display: false,
        },
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      datalabels: {
        anchor: "end",
        align: "center",
        formatter: (value) => `${value}\n`,
      },
      legend: {
        position: "right",
      },
    },
    layout: {
      padding: {
        top: 20,
        left: 20,
        right: 20,
      },
    },
  };
  const getNumber = (str) => {
    if (!str || str.length === 0) {
      return 0;
    }
    return Number(str);
  };

  const requestData = async () => {
    setDataLoading(true);
    const requestParams = {};
    const axiosInstance = createInstance(true);
    await axiosInstance
      .get(ticketApi + "metadata" + convertParamsToString(requestParams))
      .then((res) => {
        // cards
        const currentCountCards = [...initialDealCountCards];
        currentCountCards[0].count = res.data.data.totalTickets;
        currentCountCards[1].count =
          res.data.data.totalTickets - res.data.data.closedTickets;
        currentCountCards[3].count = res.data.data.closedTickets;
        setCountCards(currentCountCards);

        // priority pie chart
        const currentPriorityData = { ...initialTicketPriorityChartData };
        const priorityData = res.data.data.priorityData;
        let newData = [
          getNumber(priorityData.Low),
          getNumber(priorityData.Medium),
          getNumber(priorityData.High),
        ];
        let filteredData = newData.filter((value) => value !== 0);
        currentPriorityData.datasets[0].data = filteredData;
        currentPriorityData.labels = currentPriorityData.labels.filter(
          (_, index) => newData[index] !== 0
        );
        currentPriorityData.datasets[0].backgroundColor =
          currentPriorityData.datasets[0].backgroundColor.filter(
            (_, index) => newData[index] !== 0
          );
        setTicketPriorityChartData(currentPriorityData);

        // status pie chart
        const currentStatusData = { ...initialTicketStatusChartData };
        const statusData = res.data.data.statusData;
        newData = [
          getNumber(statusData.Open),
          getNumber(statusData.Closed),
          getNumber(statusData["In progress"]),
          getNumber(statusData["Waiting for input"]),
        ];
        filteredData = newData.filter((value) => value !== 0);
        currentStatusData.datasets[0].data = filteredData;
        currentStatusData.labels = currentStatusData.labels.filter(
          (_, index) => newData[index] !== 0
        );
        currentStatusData.datasets[0].backgroundColor =
          currentStatusData.datasets[0].backgroundColor.filter(
            (_, index) => newData[index] !== 0
          );
        setTicketStatusChartData(currentStatusData);

        // type pie chart
        const currentTypeData = { ...initialTicketTypeChartData };
        const typeData = res.data.data.typeData;
        newData = [getNumber(typeData.Change), getNumber(typeData.Problem)];
        filteredData = newData.filter((value) => value !== 0);
        currentTypeData.datasets[0].data = filteredData;
        currentTypeData.labels = currentTypeData.labels.filter(
          (_, index) => newData[index] !== 0
        );
        currentTypeData.datasets[0].backgroundColor =
          currentTypeData.datasets[0].backgroundColor.filter(
            (_, index) => newData[index] !== 0
          );
        setTicketTypeChartData(currentTypeData);

        // bar chart
        const currentBarData = { ...initialTaskGraphData };

        const barDataTotalTickets = res.data.data.totalTicketsMonthly;
        currentBarData.datasets[0].data =
          Object.values(barDataTotalTickets).map(Number);

        const barDataClosedTickets = res.data.data.closedTicketsMonthly;
        currentBarData.datasets[1].data =
          Object.values(barDataClosedTickets).map(Number);

        setTaskGraphData(currentBarData);
      })
      .catch((err) => {
        errorHandler(err, "Failed to fetch data");
      });

    setDataLoading(false);
  };

  useEffect(() => {
    if (isUser()) {
      navigate("/ts/tickets", { replace: true });
    } else {
      requestData();
    }
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Dashboard</Typography>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {dataLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Box sx={{ mb: 3 }}>
                <BdCounts cards={countCards} />
              </Box>
              <Grid container columnSpacing={2} rowSpacing={2}>
                <Grid item xs={12} md={4} p={2}>
                  <Typography variant="body1" fontWeight={"bold"} sx={{mb: 2}}>
                    Tickets By Priority
                  </Typography>
                  <Box>
                    <Pie
                      data={ticketPriorityChartData}
                      options={deatChartOptions}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                  <Typography variant="body1" fontWeight={"bold"} sx={{mb: 2}}>
                    Tickets By Status
                  </Typography>
                  <Box>
                    <Pie
                      data={ticketStatusChartData}
                      options={deatChartOptions}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} md={4} p={2}>
                  <Typography variant="body1" fontWeight={"bold"} sx={{mb: 2}}>
                    Tickets By Type
                  </Typography>
                  <Box>
                    <Pie
                      data={ticketTypeChartData}
                      options={deatChartOptions}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12} p={2}>
                  <Typography variant="body1" fontWeight={"bold"} sx={{mb: 2}}>
                    Total Tickets v/s Closed Tickets
                  </Typography>
                  <Box height={"28vh"}>
                    <Bar data={tasksGraphData} options={taskGraphOptions} />
                  </Box>
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default TsDashboard;
