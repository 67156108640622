import "./App.scss";
import { BrowserRouter, Routes, Route, Outlet } from "react-router-dom";
import BdDashboard from "./pages/BD/BdDashboard/BdDashboard";
import BdAccountSpecific from "./pages/BD/BdAccountSpecific/BdAccountSpecific";
import { ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Login from "./pages/Login/Login";
import Account from "./pages/BD/Accounts/Accounts";
import Reports from "./pages/BD/Reports/Reports";
import Deals from "./pages/BD/Deals/Deals";
import Contacts from "./pages/BD/Contacts/Contacts";
import Targets from "./pages/BD/Targets/Targets";
import Tasks from "./pages/BD/Tasks/Tasks";
import ElDashboard from "./pages/EL/ElDashboard/ElDashboard";
import TsDashboard from "./pages/TS/TsDashboard/TsDashboard";
import Tickets from "./pages/TS/Tickets/Tickets";
import Course from "./pages/EL/Course/Course";
import Assets from "./pages/AM/Assets";
import { toastAutocloseAfter } from "./constants/Common";
import ReportDetails from "./pages/BD/ReportDetails/ReportDetails";
import ImportUsers from "./pages/BD/ImportUsers/ImportUsers";
import CourseAssignment from "./pages/EL/CourseAssignment/CourseAssignment";
import ElModuleSpecific from "./pages/EL/ElModuleSpecific/ElModuleSpecific";
import MyCourses from "./pages/EL/MyCourses/MyCourses";
import MyCertificates from "./pages/EL/MyCertificates/MyCertificates";
import CourseLearning from "./pages/EL/CourseLearning/CourseLearning";
import Results from "./pages/EL/Result/Results";
import Assignments from "./pages/AM/AssignAsset/Assignments";
import Consumables from "./pages/AM/Consumable/Consumables";
import AmDashboard from "./pages/AM/AmDashboard/AmDashboard";
import TicketReports from "./pages/TS/TicketReports/TicketReports";
import AssetReports from "./pages/AM/AmReports/AmReports";
import AmReportDetails from "./pages/AM/AmReports/AmReportDetails";

import PageNotFound from "./pages/PageNotFound/PageNotFound";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ImportLocations from "./pages/BD/ImportLocations/ImportLocations";
import AssignCourses from "./pages/EL/AssignCourses/AssignCourses";
import Navbar from "./components/Navbar/Navbar";
import { Box, Toolbar, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import AllApps from "./pages/AllApps/AllApps";
import Protected from "./components/Protected/Protected";
import { userRoles } from "./enums/Auth";
import { useSelector } from "react-redux";

//routes are defined below
function App() {
	const theme = useTheme();
	const isSmallerScreen = useMediaQuery(theme.breakpoints.down('lg'));
	const profileData = useSelector((state) => state.userProfile.profileData);

	const [isSidenavOpen, setIsSidenavOpen] = useState(false);

	return (
		<div className='App'>
			<BrowserRouter>
				<ScrollToTop />
				<ToastContainer
					position='top-center'
					transition={Zoom}
					autoClose={toastAutocloseAfter}
					hideProgressBar={true}
					theme='colored'
					draggable={false}
				/>
				{profileData && <Navbar
					setIsSidenavOpen={setIsSidenavOpen}
					isSidenavOpen={isSidenavOpen}
				/>}
				<Box pl={isSidenavOpen && !isSmallerScreen ? "280px" : "0px"}>
					{profileData && <Toolbar />}
					<Routes>
						<Route path='' element={<Outlet />}>
							<Route exact path='' element={<Login setIsSidenavOpen={setIsSidenavOpen} />} />
							<Route exact path='login' element={<Login setIsSidenavOpen={setIsSidenavOpen} />} />
						</Route>
						<Route
							path='allapps'
							element={
								<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='allapps'>
									<AllApps setIsSidenavOpen={setIsSidenavOpen} />
								</Protected>
							}
						/>
						<Route path='bd'>
							<Route
								path=''
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path=''>
										<BdDashboard />
									</Protected>
								}
							/>
							<Route
								path='dashboard'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='dashboard'>
										<BdDashboard />
									</Protected>
								}
							/>
							<Route
								path='accounts'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='accounts'>
										<Account />
									</Protected>
								}
							/>
							<Route
								path='account/:accountId'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='account/:accountId'>
										<BdAccountSpecific />
									</Protected>
								}
							/>
							<Route
								path='reports'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='reports'>
										<Reports />
									</Protected>
								}
							/>
							<Route
								path='report-details/:reportId'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='report-details:reportId'>
										<ReportDetails />
									</Protected>
								}
							/>
							<Route
								path='deals'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='deals'>
										<Deals />
									</Protected>
								}
							/>
							<Route
								path='contacts'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='contacts'>
										<Contacts />
									</Protected>
								}
							/>
							<Route
								path='tasks'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='tasks'>
										<Tasks />
									</Protected>
								}
							/>
							<Route
								path='targets'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User]} path='targets'>
										<Targets />
									</Protected>
								}
							/>
							<Route
								path='import-users'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='import-users' localAdminsAllowed={true}>
										<ImportUsers />
									</Protected>
								}
							/>
							<Route
								path='import-locations'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin]} path='import-locations'>
										<ImportLocations />
									</Protected>
								}
							/>
						</Route>

						<Route path='el'>
							<Route
								path=''
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='' localAdminsAllowed={true}>
										<ElDashboard />
									</Protected>
								}
							/>
							<Route
								path='dashboard'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='' localAdminsAllowed={true}>
										<ElDashboard />
									</Protected>
								}
							/>
							<Route
								path='module/:moduleId'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='module/:moduleId'>
										<ElModuleSpecific />
									</Protected>
								}
							/>
							<Route
								path='course'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='course' localAdminsAllowed={true}>
										<Course />
									</Protected>
								}
							/>
							<Route
								path='courselearning/:courseId'
								element={
									<Protected allowedRoles={[userRoles.Admin, userRoles.User]} path='course'>
										<CourseLearning />
									</Protected>
								}
							/>
							<Route
								path='course-assignment/:courseId'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin]} path='course-assignment/:courseId'>
										<CourseAssignment />
									</Protected>
								}
							/>
							<Route
								path='my-courses'
								element={
									<Protected allowedRoles={[userRoles.Admin, userRoles.User]} path='my-courses'>
										<MyCourses />
									</Protected>
								}
							/>
							<Route
								path='my-certificates'
								element={
									<Protected allowedRoles={[userRoles.Admin, userRoles.User]} path='my-certificates'>
										<MyCertificates />
									</Protected>
								}
							/>
							<Route
								path='results'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='results' localAdminsAllowed={true}>
										<Results />
									</Protected>
								}
							/>
							<Route
								path='assign-courses'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin]} path='assign-courses'>
										<AssignCourses />
									</Protected>
								}
							/>
						</Route>

						<Route path='am'>
							<Route
								path=''
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='' localAdminsAllowed={true}>
										<AmDashboard />
									</Protected>
								}
							/>
							<Route
								path='dashboard'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='dashboard' localAdminsAllowed={true}>
										<AmDashboard />
									</Protected>
								}
							/>
							<Route
								path='assets'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='assets' localAdminsAllowed={true}>
										<Assets />
									</Protected>
								}
							/>
							<Route
								path='assignments'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='assignments' localAdminsAllowed={true}>
										<Assignments />
									</Protected>
								}
							/>
							<Route
								path='consumables'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='consumables' localAdminsAllowed={true}>
										<Consumables />
									</Protected>
								}
							/>
							<Route
								path='reports'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='reports' localAdminsAllowed={true}>
										<AssetReports />
									</Protected>
								}
							/>
							<Route
								path='report-details/:reportId'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin]} path='report-details:reportId' localAdminsAllowed={true}>
										<AmReportDetails />
									</Protected>
								}
							/>
							<Route
								path='my-assets'
								element={
									<Protected allowedRoles={[userRoles.User]} path='my-assets'>
										<Assignments />
									</Protected>
								}
							/>
						</Route>

						<Route path='ts'>
							<Route
								path=''
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.Vendor]} path='dashboard'>
										<TsDashboard />
									</Protected>
								}
							/>
							<Route
								path='dashboard'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.Vendor]} path='dashboard'>
										<TsDashboard />
									</Protected>
								}
							/>
							<Route
								path='tickets'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.User, userRoles.Vendor]} path='tickets'>
										<Tickets />
									</Protected>
								}
							/>
							<Route
								path='reports'
								element={
									<Protected allowedRoles={[userRoles.SuperAdmin, userRoles.Admin, userRoles.Vendor]} path='reports'>
										<TicketReports />
									</Protected>
								}
							/>
						</Route>
						<Route path='*' element={<PageNotFound />} />
					</Routes>
				</Box>
			</BrowserRouter>
		</div>
	);
}

export default App;
