import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { userRoles } from "../../enums/Auth";

const TSLinks = () => {
  const profileData = useSelector((state) => state.userProfile.profileData);
  return (
    <>
      <List sx={{ py: "0", px: 1 }}>

        { [userRoles.SuperAdmin, userRoles.Admin, userRoles.Vendor].includes(profileData?.role_id) &&
          <NavLink
          to={"/ts/dashboard"}
          className={({ isActive }) =>
            isActive || ["/ts", "/ts/"].includes(window.location.pathname)
              ? "active-link"
              : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/dashboard-icon.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
          </NavLink>
        }

        <NavLink
          to={"/ts/tickets"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/ticket-icon.svg" style={{filter: 'invert(1)'}} height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Tickets"} />
          </ListItem>
        </NavLink>

        { [userRoles.SuperAdmin, userRoles.Admin, userRoles.Vendor].includes(profileData?.role_id) &&
          <NavLink
          to={"/ts/reports"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/results-icon.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Reports"} />
          </ListItem>
          </NavLink>
        }
      </List>
    </>
  );
};

export default TSLinks;
