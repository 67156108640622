import {
  AppBar,
  Avatar,
  Badge,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,

} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import LockResetIcon from "@mui/icons-material/LockReset";
import PersonIcon from "@mui/icons-material/Person";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import delay from "lodash/delay";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  setProfileFirstTimeOpened,
  setProfileFormField,
  setProfileToggle,
} from "../../features/userSlice";
import { userLogout } from "../../constants/Common";
import { setPasswordChangeFormToggle } from "../../features/passwordChangeFormSlice";
import Sidebar from "../Sidebar/Sidebar";
import UserProfile from "../UserProfile/UserProfile";
import ChangePasswordForm from "../ChangePasswordForm/ChangePasswordForm";

const getAppName = (pathname) => {
  if (pathname.indexOf("/el") !== -1) {
    return "E-LEARNING";
  }

  if (pathname.indexOf("/bd") !== -1) {
    return "BUSINESS";
  }

  if (pathname.indexOf("/ts") !== -1) {
    return "TICKETING SYSTEM";
  }
  if (pathname.indexOf("/am") !== -1) {
    return "ASSET MANAGEMENT";
  }

  return "";
};

const Navbar = ({ setIsSidenavOpen, isSidenavOpen }) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const appName = getAppName(location.pathname);
  const [accountName, setAccountName] = useState("");

  // Redux States
  const profileData = useSelector((state) => state.userProfile.profileData);

  // States
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [sideNavOpen, setSideNavOpen] = useState(false);
  const [profileDrawerOpen, setProfileDrawerOpen] = useState(false);
  const [changePasswordDrawerOpen, setChangePasswordDrawerOpen] =
    useState(false);

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleProfile = () => {
    dispatch(setProfileFirstTimeOpened(true));
    setProfileDrawerOpen(true);
    handleCloseUserMenu();
  };

  const handleProfileDrawerClose = () => {
    setProfileDrawerOpen(false);
  };

  const handleChangePasswordDrawerClose = () => {
    setChangePasswordDrawerOpen(false);
  };

  const handleChangePassword = () => {
    setChangePasswordDrawerOpen(true);
    handleCloseUserMenu();
  };

  const clearLocalStorage = () => {
    localStorage.clear();
  }

  const logout = () => {
    if (location.pathname.includes('courselearning')) {
      // delay clearing of local storage in order to update course watch time.
      delay(clearLocalStorage, 500);
    } else {
      clearLocalStorage();
    }
    handleCloseUserMenu();
    dispatch({ type: userLogout });
    navigate("/");
  };

  const toggleSideNav = () => {
    setIsSidenavOpen(!isSidenavOpen);
    setSideNavOpen(!sideNavOpen);
  };

  useEffect(() => {
    if (!isSidenavOpen) {
      setSideNavOpen(false);
    }
  }, [isSidenavOpen])


  return (
    <>
      {profileDrawerOpen && (
        <UserProfile
          isProfileOpen={profileDrawerOpen}
          handleDrawerClose={handleProfileDrawerClose}
        />
      )}
      {changePasswordDrawerOpen && (
        <ChangePasswordForm
          isChangePasswordOpen={changePasswordDrawerOpen}
          handleDrawerClose={handleChangePasswordDrawerClose}
        />
      )}
      <Box sx={{ flexGrow: 1 }}>
        <AppBar
          position="fixed"
          sx={{ boxShadow: "none", zIndex: 1 }}
          className="secondary-bg"
        >
          <Toolbar disableGutters>
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              sx={{ color: "black", fontWeight: "bold", ml: 1, visibility: (["/allapps", "/allapps/"].includes(window.location.pathname)) ? "hidden" : "visible" }}
              onClick={() => {
                setIsSidenavOpen(!isSidenavOpen);
                setSideNavOpen(!sideNavOpen);
              }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ flexGrow: isMobileScreen || !appName ? 1 : 0 }}>
              {!isMobileScreen ? (
                <img
                  onClick={() => navigate("/allapps")}
                  src="/assets/images/VG_Logo.png"
                  alt="vishwaguru logo"
                  style={{ cursor: "pointer", height: "35px" }}
                />
              ) : (
                <img
                  onClick={() => navigate("/allapps")}
                  src="/assets/images/VG_Small_Logo.png"
                  alt="vishwaguru logo"
                  style={{ cursor: "pointer", height: "35px" }}
                />
              )}
            </Box>
            {Boolean(appName) && !isMobileScreen && (
              <>
                {!isMobileScreen && (
                  <Divider
                    sx={{ my: 2, mx: 1 }}
                    className="horizontal-line"
                    orientation="vertical"
                    flexItem
                  />
                )}
                <Box sx={{ flexGrow: 1, ml: isSidenavOpen ? "100px" : 0 }}>
                  <Typography variant="h6" className="app-name-text">
                    {appName}
                  </Typography>
                </Box>
              </>
            )}

            <Box sx={{ flexGrow: 0 }} display={"flex"} alignItems={"center"}>
              {!isSmallerScreen && (
                <>
                  <Box>
                    <Typography variant="h6" className="app-name-text">
                      {accountName}
                    </Typography>
                  </Box>
                  <Divider
                    sx={{ mx: "17.5px", my: 1 }}
                    className="horizontal-line"
                    orientation="vertical"
                    flexItem
                  />
                </>
              )}
              <Tooltip title={"Go to All Apps"} arrow>
                <IconButton
                  onClick={() => navigate("/allapps")}
                  size="large"
                  className="text-dark"
                >
                  <img
                    src="/assets/images/allApps.png"
                    alt="All Apps"
                    style={{ height: "22px" }}
                  />
                </IconButton>
              </Tooltip>

              {/* <Tooltip title={"Open Notifications"} arrow>
                <IconButton className="text-dark" sx={{ mr: 2 }} size="large">
                  <Badge badgeContent={1} color="error">
                    <img
                      src="/assets/images/bell.svg"
                      alt="notifications"
                      style={{ height: "20px" }}
                    />
                  </Badge>
                </IconButton>
              </Tooltip> */}

              <Tooltip title="Open settings" arrow>
                <IconButton onClick={handleOpenUserMenu} sx={{ p: 0, mr: 2 }}>
                  <Avatar className="primary-bg">
                    {profileData.first_name &&
                      profileData.last_name &&
                      profileData.first_name[0].toUpperCase() +
                      profileData.last_name[0].toUpperCase()}
                  </Avatar>
                </IconButton>
              </Tooltip>
              <Menu
                sx={{ mt: "45px" }}
                id="menu-appbar"
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                disableScrollLock
                slotProps={{
                  paper: {
                    elevation: 0,
                    sx: {
                      overflow: "visible",
                      filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                      mt: 1.5,
                      borderRadius: "10px",
                      "& .MuiAvatar-root": {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      "&::before": {
                        content: '""',
                        display: "block",
                        position: "absolute",
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: "background.paper",
                        transform: "translateY(-50%) rotate(45deg)",
                        zIndex: 0,
                      },
                    },
                  },
                }}
              >
                <MenuItem
                  sx={{ pr: 4, py: 1, textAlign: "start" }}
                  onClick={handleProfile}
                >
                  <PersonIcon sx={{ mr: 1 }} />
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem
                  sx={{ pr: 4, py: 1, textAlign: "start" }}
                  onClick={handleChangePassword}
                >
                  <LockResetIcon sx={{ mr: 1 }} />
                  <Typography textAlign="center">Change Password</Typography>
                </MenuItem>
                <MenuItem
                  sx={{ pr: 4, py: 1, textAlign: "start" }}
                  onClick={logout}
                >
                  <ExitToAppIcon sx={{ mr: 1 }} />
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>
          </Toolbar>
        </AppBar>
        <Sidebar open={sideNavOpen} toggleSideNav={toggleSideNav} />
      </Box>
    </>
  );
};

export default Navbar;
