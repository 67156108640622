import React, { useState, useEffect } from "react";
import "../../../styles/BD/entityForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import RequestInProgressModal from "../../RequestInProgressModal/RequestInProgressModal";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { questionSchema } from "../../../validations/schemas/e-learning/course.schema.validator";
import Asterisk from "../../Misc/Asterisk";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { courseApi } from "../../../constants/apis/EL/Course";
import { convertParamsToString } from "../../../utils/Request";
import { setCourseQuestionsEdit } from "../../../features/EL/courseQuestionsFormSlice";
import EditIcon from "@mui/icons-material/Edit";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const CourseQuestionsForm = ({ isOpenProp, handleClose }) => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();

  const courseQuestionsEdit = useSelector(
    (state) => state.courseQuestionsForm.courseQuestionsEdit
  );
  const courseId = useSelector(
    (state) => state.courseQuestionsForm.courseQuestionsEditId
  );
  const courseQuestionsEditOpenToggle = useSelector(
    (state) => state.courseQuestionsForm.courseQuestionsEditOpenToggle
  );

  const initialOptionsData = [
    {
      option_text: "",
      is_correct: false,
    },
    {
      option_text: "",
      is_correct: false,
    },
    {
      option_text: "",
      is_correct: false,
    },
    {
      option_text: "",
      is_correct: false,
    },
  ];

  const [fetchingCourseQuestions, setFetchingCourseQuestions] = useState(true);
  const [startEdit, setStartEdit] = useState(false);
  const [courseAssigned, setCourseAssigned] = useState(false);
  const [questions, setQuestions] = useState([]);

  const [isOpen, setIsOpen] = useState(isOpenProp);

  const secondForm = useFormik({
    initialValues: {
      question_text: "",
      marks: 1,
      options: initialOptionsData,
      option_1: "",
      option_2: "",
      option_3: "",
      option_4: "",
    },
    validationSchema: questionSchema,
    onSubmit: (secondFormData) => {
      delete secondFormData.option_1;
      delete secondFormData.option_2;
      delete secondFormData.option_3;
      delete secondFormData.option_4;
      const isTicked = secondFormData.options.filter((option) => {
        return option.is_correct;
      });
      if (isTicked.length === 0) {
        toast.warn("Select correct option");
      } else {
        setQuestions((prevQuestions) => {
          return prevQuestions.map((question) => {
            if (question.question_id === secondFormData.question_id) {
              return secondFormData;
            }
            return question;
          });
        });
        toast.success("Question saved");
        setStartEdit(false);
        secondForm.resetForm();
      }
    },
  });

  const handleOptionChange = (index, value) => {
    const updatedOptions = secondForm.values.options.map((option, i) =>
      i === index ? { ...option, option_text: value } : option
    );
    secondForm.setFieldValue("options", updatedOptions);
  };

  const handleCorrectOptionChange = (index) => {
    const updatedOptions = secondForm.values.options.map((option, i) =>
      i === index
        ? { ...option, is_correct: true }
        : { ...option, is_correct: false }
    );
    secondForm.setFieldValue("options", updatedOptions);
  };

  const handleDrawerClose = (_) => {
    handleClose();
    setIsOpen(false);
  };

  const handleQuestionsFormEdit = async () => {
    try {
      const axiosInstance = createInstance(true);
      let courseAddResponse = await axiosInstance.put(
        courseApi.concat(`update-course-questions/${courseId}`),
        {
          questions: questions,
        }
      );
      if (courseAddResponse.data.success) {
        toast.success("Course questions updated successfully");
        handleDrawerClose();
      } else {
        throw new Error("Failed to add course");
      }
    } catch (error) {
      errorHandler(error, "Failed to add course");
    }
  };

  const handleEditQuestion = (id) => {
    const questionToEdit = questions.filter(
      (question) => question.question_id === id
    )[0];
    setStartEdit(true);
    secondForm.setValues({
      question_text: questionToEdit.question_text,
      marks: questionToEdit.marks,
      options: questionToEdit.options,
      option_1: questionToEdit.options[0].option_text,
      option_2: questionToEdit.options[1].option_text,
      option_3: questionToEdit.options[2].option_text,
      option_4: questionToEdit.options[3].option_text,
      question_id: id,
    });
  };

  const fetchCourseQuestions = async (courseId) => {
    setFetchingCourseQuestions(true);
    try {
      const axiosInstance = createInstance(true);
      const result = await axiosInstance.get(
        courseApi.concat(courseId) +
          convertParamsToString({ dataForEdit: true })
      );
      if (result.data.success) {
        const fetchedCourseQuestions = result.data.data.questions;
        if (result.data.data?.courseAssigned) {
          setCourseAssigned((_) => true);
          toast.warn(
            "Course questions cannot be edited as course is already to assigned to some account",
            { autoClose: false }
          );
        }
        setQuestions([...fetchedCourseQuestions]);
      }
    } catch (err) {
      errorHandler(err, "Failed to fetch course questions.");
    }
    setFetchingCourseQuestions(false);
  };
  useEffect(() => {
    if (courseQuestionsEdit) {
      setCourseAssigned((_) => false);
      fetchCourseQuestions(courseId);
      dispatch(setCourseQuestionsEdit(false));
    }
  }, [courseQuestionsEditOpenToggle, courseQuestionsEdit, courseId]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: { md: "90vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      anchor={"right"}
      open={isOpen}
      onClose={handleDrawerClose}
    >
      <Box
        my={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">Edit Questions</Typography>
        <IconButton
          aria-label="close"
          onClick={handleDrawerClose}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" />

      <Box my={3}>
        <Grid container>
          <Grid item container xs={12} md={5.8} height={"100%"}>
            <Grid
              item
              xs={12}
              md={12}
              mb={{ xs: 1, md: 4 }}
              mt={isSmallerScreen && 1}
            >
              <Typography variant="h6" className="fw-bold">
                Edit Section
              </Typography>
              <Divider className={"horizontal-line"} sx={{ my: 1 }} />
            </Grid>
            <Grid item xs={12} lg={2.5} pr={2}>
              <InputLabel className="fw-bold" htmlFor="question_text">
                {"Question "}
                <Asterisk />
              </InputLabel>
            </Grid>
            <Grid item xs={12} lg={9.5}>
              <TextField
                id="question_text"
                name="question_text"
                placeholder="Enter Question Text"
                value={secondForm.values.question_text}
                onChange={secondForm.handleChange}
                onBlur={secondForm.handleBlur}
                error={
                  secondForm.touched.question_text &&
                  Boolean(secondForm.errors.question_text)
                }
                helperText={
                  secondForm.touched.question_text &&
                  secondForm.errors.question_text
                }
                size="small"
                multiline
                fullWidth
                sx={{ mb: 2 }}
                disabled={!startEdit}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={2.5} pr={2}>
              <InputLabel className="fw-bold" htmlFor="module_name">
                {"Marks "}
                <Asterisk />
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={12} lg={2.5}>
              <TextField
                id="marks"
                name="marks"
                type="number"
                placeholder="Add Marks"
                value={secondForm.values.marks}
                onChange={secondForm.handleChange}
                onBlur={secondForm.handleBlur}
                InputProps={{ inputProps: { min: 1 } }}
                error={
                  secondForm.touched.marks && Boolean(secondForm.errors.marks)
                }
                helperText={secondForm.touched.marks && secondForm.errors.marks}
                size="small"
                sx={{ mb: 4, width: "100%" }}
                disabled={!startEdit}
              />
            </Grid>
            <Grid item md={7}></Grid>

            <Grid item xs={12} md={12} mt={!isSmallerScreen && 1} mb={2}>
              <Typography variant="body1">
                Options to select from (Tick the correct answer)
              </Typography>
            </Grid>

            <Grid item md={2}></Grid>
            <Grid item xs={12} md={4.5}>
              <Asterisk />
              <Radio
                value={secondForm.values.options[0].is_correct}
                name="correct_option"
                checked={secondForm.values.options[0].is_correct}
                onChange={() => handleCorrectOptionChange(0)}
                disabled={!startEdit}
              />
              <TextField
                name="option_1"
                size="small"
                placeholder="Option 1"
                onChange={(e) => {
                  secondForm.handleChange(e);
                  handleOptionChange(0, e.target.value);
                }}
                value={secondForm.values.option_1}
                onBlur={secondForm.handleBlur}
                error={
                  secondForm.touched.option_1 &&
                  Boolean(secondForm.errors.option_1)
                }
                helperText={
                  secondForm.touched.option_1 && secondForm.errors.option_1
                }
                sx={{ mb: 2 }}
                disabled={!startEdit}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item xs={12} md={4.5}>
              <Asterisk />
              <Radio
                value={secondForm.values.options[1].is_correct}
                name="correct_option"
                checked={secondForm.values.options[1].is_correct}
                onChange={() => handleCorrectOptionChange(1)}
                disabled={!startEdit}
              />
              <TextField
                name="option_2"
                size="small"
                placeholder="Option 2"
                onChange={(e) => {
                  secondForm.handleChange(e);
                  handleOptionChange(1, e.target.value);
                }}
                value={secondForm.values.option_2}
                onBlur={secondForm.handleBlur}
                error={
                  secondForm.touched.option_2 &&
                  Boolean(secondForm.errors.option_2)
                }
                helperText={
                  secondForm.touched.option_2 && secondForm.errors.option_2
                }
                sx={{ mb: 2 }}
                disabled={!startEdit}
              />
            </Grid>

            <Grid item md={2}></Grid>
            <Grid item xs={12} md={4.5}>
              <Asterisk />
              <Radio
                value={secondForm.values.options[2].is_correct}
                name="correct_option"
                checked={secondForm.values.options[2].is_correct}
                onChange={() => handleCorrectOptionChange(2)}
                disabled={!startEdit}
              />
              <TextField
                name="option_3"
                size="small"
                placeholder="Option 3"
                onChange={(e) => {
                  secondForm.handleChange(e);
                  handleOptionChange(2, e.target.value);
                }}
                value={secondForm.values.option_3}
                onBlur={secondForm.handleBlur}
                error={
                  secondForm.touched.option_3 &&
                  Boolean(secondForm.errors.option_3)
                }
                helperText={
                  secondForm.touched.option_3 && secondForm.errors.option_3
                }
                sx={{ mb: 2 }}
                disabled={!startEdit}
              />
            </Grid>
            <Grid item md={1}></Grid>
            <Grid item xs={12} md={4.5}>
              <Asterisk />
              <Radio
                value={secondForm.values.options[3].is_correct}
                name="correct_option"
                checked={secondForm.values.options[3].is_correct}
                onChange={() => handleCorrectOptionChange(3)}
                disabled={!startEdit}
              />
              <TextField
                name="option_4"
                size="small"
                placeholder="Option 4"
                onChange={(e) => {
                  secondForm.handleChange(e);
                  handleOptionChange(3, e.target.value);
                }}
                value={secondForm.values.option_4}
                onBlur={secondForm.handleBlur}
                error={
                  secondForm.touched.option_4 &&
                  Boolean(secondForm.errors.option_4)
                }
                helperText={
                  secondForm.touched.option_4 && secondForm.errors.option_4
                }
                sx={{ mb: 2 }}
                disabled={!startEdit}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography variant="body2" my={2}>
                <Asterisk />
                {" Mandatory Fields"}
              </Typography>
            </Grid>
            <Grid
              item
              container
              xs={12}
              md={8}
              mb={isSmallerScreen && 2}
              justifyContent={"flex-end"}
            >
              <Button
                variant="outlined"
                className="primary-text primary-btn"
                onClick={secondForm.handleSubmit}
                sx={{ m: isSmallerScreen ? 0 : 2 }}
                fullWidth={isSmallerScreen}
                disabled={!startEdit}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
          {!isSmallerScreen && (
            <Grid item md={0.4} display={"flex"} justifyContent={"center"}>
              <Divider className="horizontal-line" orientation="vertical" />
            </Grid>
          )}
          <Grid item xs={12} md={5.8}>
            <Grid
              item
              xs={12}
              md={12}
              mb={{ xs: 1, md: 4 }}
              mt={isSmallerScreen && 2}
            >
              <Typography variant={"h6"}>Course Questions</Typography>
              <Divider className={"horizontal-line"} sx={{ my: 1 }} />
            </Grid>
            {fetchingCourseQuestions ? (
              <LoadingSpinner />
            ) : (
              <Box
                height={
                  questions.length === 0
                    ? "50"
                    : isSmallerScreen
                    ? "120px"
                    : "450px"
                }
                sx={{ overflowY: "scroll" }}
              >
                {questions.length === 0 ? (
                  <Typography variant={"body1"}>
                    No questions to preview...
                  </Typography>
                ) : (
                  questions.map((question, index) => (
                    <Grid item key={index} md={12} mb={2}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography variant="body2" fontWeight={"bold"}>{`${
                          index + 1
                        }. ${question.question_text}`}</Typography>
                        <Typography
                          variant="body2"
                          fontWeight={"bold"}
                          color="textSecondary"
                          mr={2}
                        >{`Marks ${question.marks}`}</Typography>
                      </Box>
                      {question.options.map((option, index) => (
                        <Box key={index}>
                          <Typography
                            name="option_4"
                            variant="body2"
                            size="small"
                            color={option.is_correct ? "green" : "inherit"}
                          >
                            {`${String.fromCharCode(index + 65)}. ${
                              option.option_text
                            }`}
                          </Typography>
                        </Box>
                      ))}
                      <Button
                        variant="text"
                        endIcon={<EditIcon />}
                        onClick={() => handleEditQuestion(question.question_id)}
                        sx={{
                          my: 0,
                        }}
                        disabled={courseAssigned}
                      >
                        Edit
                      </Button>
                      <Divider className="horizontal-line" sx={{ my: 1 }} />
                    </Grid>
                  ))
                )}
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"end"}
        bottom={0}
        height={"100%"}
      >
        <Divider className="horizontal-line" sx={{ my: 1 }} />

        <Box
          my={3}
          display={"flex"}
          columnGap={5}
          justifyContent={"space-between"}
        >
          <Button
            variant="contained"
            type="button"
            className={`primary-btn ${
              isSmallerScreen ? "primary-small-btn" : ""
            }`}
            onClick={handleDrawerClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            className={`primary-btn ${
              isSmallerScreen ? "primary-small-btn" : ""
            }`}
            onClick={(e) => {
              handleQuestionsFormEdit();
            }}
            disabled={courseAssigned}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CourseQuestionsForm;
