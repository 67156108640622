import { messages } from "../../constants/validationMessages/BdForms/AddContact";
import { roles } from "../../enums/Contact";
const Joi = require("joi");

const contactSchema = Joi.object().keys({
    account_id: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            "number.min": messages.accountId,
            "number.base": messages.accountId
        }),

    first_name: Joi.string()
        .min(1)
        .max(255)
        .required()
        .messages({
            "string.max": messages.firstNameMax,
            "string.empty": messages.firstNameDefault,
        }),

    last_name: Joi.string()
        .min(1)
        .max(255)
        .required()
        .messages({
            "string.max": messages.lastNameMax,
            "string.empty": messages.lastNameDefault,
        }),

    dob: Joi.alternatives([
        Joi.string().valid(''),
        Joi.date()
            .max('now')
            .required()
            .messages({
                "date.base": messages.dobDefault,
                "date.max": messages.dobDefault
            }),
    ]).required(),

    mobile: Joi.string()
        .required()
        .regex(/^\d{10}$/)
        .min(10)
        .max(10)
        .messages({
            "string.empty": messages.mobileEmpty,
            "string.min": messages.mobileDefault,
            "string.max": messages.mobileDefault,
            "string.pattern.base": messages.mobileRegex,
        }),

    email: Joi.string()
        .allow('', null)
        .when(Joi.not('').required(), {
            then: Joi.string()
                .email({ tlds: { allow: false } })
                .messages({
                    "string.empty": messages.emailDefault,
                    "email.base": messages.emailInvalid,
                    "string.email": messages.emailInvalid,
                })
        })
    ,

    role: Joi.string()
        .valid(...Object.values(roles))
        .required()
        .messages({
            "string.empty": messages.roleValid,
            "any.only": messages.roleValid
        }),

    description: Joi.string().allow(null).min(0)
});

export { contactSchema };