import React from "react";
import "./DeleteConfirmation.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const DeleteConfirmation = ({
  showModal,
  hideModal,
  confirmModal,
  message,
}) => {
  return (
    <React.Fragment>
      <Dialog open={showModal}>
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: "bold", pr: 10 }}
          id="customized-dialog-title"
        >
          Delete Confirmation
        </DialogTitle>

        <IconButton
          aria-label="close"
          onClick={hideModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
          }}
          color="primary"
        >
          <CloseIcon />
        </IconButton>

        <DialogContent dividers>
          <Typography variant="body2" color={"textSecondary"} gutterBottom>
            {message}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            className="primary-btn "
            color="error"
            onClick={() => confirmModal()}
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default DeleteConfirmation;
