import { useTheme, useMediaQuery, Box } from '@mui/material';
import React from 'react'
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import './MultipleViewer.scss'
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function PdfViewer({ url, onDocumentLoadSuccess, currentPage, onLoadError }) {
  const theme = useTheme();
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const loader = <Box sx={{
    height: { xs: "350px", md: "75vh", display: "flex", alignItems: "center" },
  }}>
    <LoadingSpinner />
  </Box>;

 
  return (

    <Document loading={loader} onLoadSuccess={onDocumentLoadSuccess} onLoadError={(error) => onLoadError(error, 'Failed to load document!')} file={url}  >
      <Page loading={loader} onLoadError={(error) => onLoadError(error, 'Failed to load page!')} width={isMobileScreen ? 250 : isMediumScreen ? 500 : 800} pageNumber={currentPage} />
    </Document>

  )
}

export default PdfViewer;