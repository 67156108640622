import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import assetSideBarMenus from "../../../data/json/assetSideBarMenus.json";
import consumableColumns from "../../../data/json/consumableColumns.json";
import { DebounceInput } from "react-debounce-input";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ConsumableDetails from "../../../components/EntityDetails/ConsumableDetails/ConsumableDetails";
import ConsumableForm from "../../../components/AmForms/ConsumableForm/ConsumableForm";

import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TableMenu from "../../../components/TableMenu/TableMenu";
import { getConsumables } from "../../../redux/actions/am-actions/consumables.actions";
import { consumableActions } from "../../../redux/reducers/am-reducers/consumables.reducer";
import moment from "moment";
import getTableCell from "../../../utils/Common/DataTable";

const Consumables = () => {
  const appSidebar = JSON.parse(JSON.stringify(assetSideBarMenus));
  appSidebar.menuList[2].currentPage = true;
  const dispatch = useDispatch();

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searched, setSearched] = useState("");
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();

  const [isConsumableFormOpen, setIsConsumableFormOpen] = useState(false)
  const [consumableEditProp, setConsumableEditProp] = useState(false)
  const [consumableDetailsOpen, setConsumableDetailsOpen] = useState(false)


  const consumablesState = useSelector((state) => state.consumables);
  const rowsLoading = consumablesState.consumablesLoading;
  const rows = consumablesState?.consumables?.rows;
  const totalCount = consumablesState?.consumables?.meta?.total;

  const getRequestParams = () => {
    const requestParams = {};

    if (searched !== "") {
      requestParams["search"] = searched;
    }

    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }

    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;
    return requestParams;
  }

  const requestData = async () => {
    const requestParams = getRequestParams();
    dispatch(getConsumables(requestParams));
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const setConsumableForm = () => {
    setIsConsumableFormOpen(true);
    setConsumableEditProp(false);
    dispatch(consumableActions.setSelectedConsumableData(null));
  };

  const fillDataInForm = (id) => {
    const selectedConsumableData = rows?.filter(
      (consumable) => consumable.consumable_id === id
    )?.[0];
    dispatch(consumableActions.setSelectedConsumableData(selectedConsumableData));
    setConsumableEditProp(true);
    setIsConsumableFormOpen(true);
  };

  const openDetailsForm = (id) => {
    const selectedConsumableData = rows?.filter(
      (consumable) => consumable.consumable_id === id
    )?.[0];
    dispatch(consumableActions.setSelectedConsumableData(selectedConsumableData));
    setConsumableDetailsOpen(true);
  };

  const handleDetailsClose = () => {
    dispatch(consumableActions.setSelectedConsumableData(null));
    setConsumableDetailsOpen(false);
  }

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  const handleDrawerClose = () => {
    setIsConsumableFormOpen(false);
    setConsumableEditProp(false);
    dispatch(consumableActions.setSelectedConsumableData(null));
  };

  useEffect(() => {
    requestData();
  }, [currentPage, rowsPerPage, searched, order, orderBy]);



  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {consumableDetailsOpen && <ConsumableDetails
          isConsumableDetailsOpen={consumableDetailsOpen}
          handleDetailsClose={handleDetailsClose}
          isSmallerScreen={isSmallerScreen}
        />}

        {isConsumableFormOpen && <ConsumableForm
          isConsumableFormOpen={isConsumableFormOpen}
          consumableEditProp={consumableEditProp}
          isSmallerScreen={isSmallerScreen}
          handleDrawerClose={handleDrawerClose}
        />}

        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Consumables</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnGap={{ md: 2 }}
              justifyContent={{ xs: "space-between", md: "end" }}
              alignItems={"center"}
            >
              <Grid item xs={6} md={"auto"}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              <Grid item xs={5} md={"auto"} textAlign={{ md: "end" }}>
                <Button
                  variant="outlined"
                  onClick={() => setConsumableForm()}
                  startIcon={<AddIcon />}
                >
                  New Consumable
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows?.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow key={'consumable_head_row'}>
                      {consumableColumns.map((assetCol) =>
                        getTableCell(assetCol,orderBy,order,handleSortRequest)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.map((consumableRow, i) => {
                        return (
                          <TableRow key={`consumable_${i}`}>
                            <Tooltip title={consumableRow.consumable_name}>
                              <TableCell
                                sx={{ maxWidth: "250px" }}
                                className="text-truncate primary-text link-hover"
                                onClick={() =>
                                  openDetailsForm(consumableRow.id)
                                }
                              >
                                {consumableRow.consumable_name}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {moment(consumableRow.date_of_purchase).format("DD/MM/YYYY")}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {consumableRow.purchased_quantity}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {consumableRow.in_stock}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {consumableRow.category}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {consumableRow.make}
                            </TableCell>
                            <TableCell align="right">
                              <TableMenu
                                fillDataInForm={fillDataInForm}
                                rowId={consumableRow.id}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={Boolean(totalCount) ? totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Consumables;
