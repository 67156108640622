
import React, { useEffect, useRef, useState } from 'react'

function VideoViewer({ url, lastRecordedProgress, updateFileLastProgress }) {
  const videoRef = useRef(null);
  const [lastSeekTime, setLastSeekTime] = useState(lastRecordedProgress || 0); // Initial lastWatchTime

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = lastRecordedProgress;
    }
  }, [videoRef, lastRecordedProgress]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load(); // Load the new video source
      videoRef.current.currentTime = lastRecordedProgress;
    }
  }, [url]);

  const handleSeeked = () => {
    setLastSeekTime(videoRef.current.currentTime);
  };

  const handleProgress = () => {
    setLastSeekTime(videoRef.current.currentTime);
  }

  useEffect(() => {
    updateFileLastProgress(lastSeekTime);
  }, [lastSeekTime]);


  return (

    <video style={{ borderRadius: '8px' }}
      controls ref={videoRef}
      onSeeked={handleSeeked}
      onTimeUpdate={handleProgress}
      controlsList='nodownload'
      disablePictureInPicture
      preload='auto'
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <source src={url} />
    </video>

  )
}

export default VideoViewer