const extractFileFromUrl = (url) => {
    const parsedUrl = new URL(url);
    const pathname = parsedUrl.pathname;
    const fileName = pathname.substring(pathname.lastIndexOf('/') + 1);
    return fileName;
}


const getOriginalFileName = (serverFileName) => {
    const fileNameChunks = serverFileName.split("_");
    if (fileNameChunks.length >= 3) {
        const originalFileName = fileNameChunks.slice(2).join("_");
        return originalFileName;
    } else {
        return serverFileName;
    }
}

const getFileTypeFromUrl = (url) => {
    // Use regex to extract the file extension from the URL
    const match = url.match(/\.([a-zA-Z0-9]+)(\?|#|$)/);

    // If there's a match, return the file type, otherwise return null
    return match ? match[1] : null;
}

module.exports = { extractFileFromUrl, getOriginalFileName, getFileTypeFromUrl };