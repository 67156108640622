import React, { useEffect } from 'react'
import DocViewer from './DocViewer';
import VideoViewer from './VideoViewer';

function MultipleFileViewer({
    fileName,
    fileType,
    fileUrl,
    isPptModalOpen,
    setIsPptModalOpen,
    lastRecordedProgress,
    recordedTimeSpend,
    userId,
    onFileViewCloseCallBack,
    uniqueId,
}) {
    const fileTimeSpendKey = `${userId}_${uniqueId}_file_last_watch_time_${fileName}`;
    const fileLastProgressKey = `${userId}_${uniqueId}_file_last_progress_${fileName}`;

    const getFileLocalLastProgress = () => localStorage.getItem(fileLastProgressKey);

    const updateFileLastProgress = (updatedFileLastProgress) => {
        localStorage.setItem(fileLastProgressKey, updatedFileLastProgress)
    };

    const getFileLocalTimeSpend = () => localStorage.getItem(fileTimeSpendKey);

    const updateFileTimeSpend = (updatedFileTimeSpend) => {
        localStorage.setItem(fileTimeSpendKey, updatedFileTimeSpend);
    };

    let fileLastProgress = parseInt(getFileLocalLastProgress());
    // prioritize local stored file progress over progress from database.
    fileLastProgress = Boolean(fileLastProgress) ? fileLastProgress : lastRecordedProgress;

    useEffect(() => {
        // initialize file watch time
        let fileViewStartDateTime;
        let fileTimeSpend;
        let fileLocalTimeSpend;
        let fileLocalLastProgress;

        if (Boolean(fileName)) {
            fileViewStartDateTime = Date.now();

            // get file watch time from local 
            fileLocalTimeSpend = getFileLocalTimeSpend();
            fileLocalLastProgress = getFileLocalLastProgress();

            fileTimeSpend = Boolean(fileLocalTimeSpend) ? parseInt(fileLocalTimeSpend) : Boolean(recordedTimeSpend) ? recordedTimeSpend : 0;
            fileLastProgress = Boolean(fileLocalLastProgress) ? parseInt(fileLocalLastProgress) : Boolean(lastRecordedProgress) ? lastRecordedProgress : 0;

            // update file watch time in local storage 
            updateFileLastProgress(fileLastProgress);
            updateFileTimeSpend(fileTimeSpend);
        }


        return () => {
            if (Boolean(fileName)) {
                // calculate file time spent
                let recordedTimeSpendOnACourseFile = parseInt((Date.now() - fileViewStartDateTime) / 1000);

                // set  time spend on file to update it in file change callback
                fileTimeSpend += recordedTimeSpendOnACourseFile;
                fileLocalTimeSpend = parseInt(getFileLocalTimeSpend());
                // set the greater time spend on file
                fileTimeSpend = (fileLocalTimeSpend > fileTimeSpend) ? fileLocalTimeSpend : fileTimeSpend;

                fileLastProgress = parseInt(getFileLocalLastProgress());
                onFileViewCloseCallBack(fileName, fileTimeSpend, fileLastProgress);

                // update time spend in local storage 
                updateFileTimeSpend(fileTimeSpend)

                fileViewStartDateTime = null;
                fileLastProgress = 0;
            }
        }

    }, [fileName, fileType, fileUrl, lastRecordedProgress, recordedTimeSpend])

    const fileViewer = fileType !== 'mp4' ?
        <DocViewer fileType={fileType}
            fileUrl={fileUrl}
            isPptModalOpen={isPptModalOpen}
            setIsPptModalOpen={setIsPptModalOpen}
            lastRecordedProgress={fileLastProgress}
            updateFileLastProgress={updateFileLastProgress}
        /> :
        <VideoViewer
            url={fileUrl}
            lastRecordedProgress={fileLastProgress}
            updateFileLastProgress={updateFileLastProgress}
        />;
    return fileViewer;
}

export default MultipleFileViewer