import { createSlice } from "@reduxjs/toolkit";
import { taskActions } from "./tasks.reducer";
import { contactActions } from "./contacts.reducer";
import { dealActions } from "./deals.reducer";

const initialState = {
  dashboardAccounts: {},
  dashboardAccountsLoading: false,
  allAccounts: {},
  allAccountsLoading: false,
  selectedAccount: null,
  editAccountLoading: false,
  deleteAccountLoading: false,
  accountMetaDataLoading: false,
  accountMetaData: {},
  addAccountLoading: false,
  message: null,
  error: null,
};

const accountSlice = createSlice({
  name: "accounts",
  initialState,
  reducers: {
    getDashboardAccountsRequest(state) {
      state.dashboardAccountsLoading = true;
    },
    getDashboardAccountsSuccess(state, action) {
      state.dashboardAccountsLoading = false;
      state.dashboardAccounts = action.payload;
      state.dashboardAccounts.rows.forEach((row) => {
        row.id = row.account_id;
      });
    },
    getDashboardAccountsFail(state) {
      state.dashboardAccountsLoading = false;
    },
    setSelectedDashboardAccount(state, action) {
      const id = action.payload;
      state.selectedAccount = state?.dashboardAccounts?.rows?.find((row) => row.id === id) || {};
    },
    setSelectedDashboardAccountWithAccountData(state,action) {
      state.selectedAccount = action.payload;
    },
    getAllAccountsRequest(state) {
      state.allAccountsLoading = true;
    },
    getAllAccountsSuccess(state, action) {
      state.allAccountsLoading = false;
      state.allAccounts = action.payload;
      state.allAccounts.rows.forEach((row) => {
        row.id = row.account_id;
      });
    },
    getAllAccountsFail(state) {
      state.allAccountsLoading = false;
    },
    setSelectedAccount(state, action) {
      const id = action.payload;
      state.selectedAccount = state?.allAccounts?.rows?.find((row) => row.id === id) || {};
    },

    deleteAccountRequest(state) {
      state.deleteAccountLoading = true;
    },
    deleteAccountSuccess(state, action) {
      const deletedAccountId = action.payload;
      state.allAccounts.rows = state.allAccounts.rows.filter((row) => row.id !== deletedAccountId);
      state.allAccounts.meta.total--;
      state.deleteAccountLoading = false;
    },
    deleteAccountFail(state) {
      state.deleteAccountLoading = false;
    },
    editAccountRequest(state) {
      state.editAccountLoading = true;
    },
    editAccountSuccess(state, action) {
      const editedAccount = action.payload.editedAccount;
      editedAccount.id = editedAccount.account_id;
      const forDashboard = action.payload.forDashboard;

      if (forDashboard) {
        state.dashboardAccounts.rows = state.dashboardAccounts?.rows?.map((row) => {
          if (row.id === editedAccount.id) {
            return editedAccount;
          }
          return row;
        })
      } else {
        state.allAccounts.rows = state.allAccounts?.rows?.map((row) => {
          if (row.id === editedAccount.id) {
            return editedAccount;
          }
          return row;
        })
        state.selectedAccount = state?.allAccounts?.rows?.find((row) => row.id === editedAccount.id) || {};
      }

      state.editAccountLoading = false;
    },
    editAccountFail(state) {
      state.editAccountLoading = false;
    },

    getMetaDataRequest(state) {
      state.accountMetaDataLoading = true;
    },
    getMetaDataSuccess(state, action) {
      state.accountMetaDataLoading = false;
      state.accountMetaData = action.payload;
    },
    getMetaDataFail(state) {
      state.accountMetaDataLoading = false;
    },
    addAccountRequest(state) {
      state.addAccountLoading = true;
    },
    addAccountSuccess(state, action) {
      const addedAccount = action.payload;
      addedAccount.id = addedAccount.account_id;
      state.allAccounts.rows = [addedAccount, ...state.allAccounts.rows]
      state.allAccounts.meta.total++;
      state.addAccountLoading = false;
    },
    addAccountFail(state) {
      state.addAccountLoading = false;
    },
    clearError(state) {
      state.error = null;
    },
    clearMessage(state) {
      state.message = null;
    },

  },
  extraReducers: builder => {
    builder
      .addCase(contactActions.addContactSuccess, state => {
        state.accountMetaData = {
          ...state.accountMetaData,
          contacts: (Number((state.accountMetaData?.contacts ?? 0))) + 1,
        };
      })
      .addCase(contactActions.deleteContactSuccess, state => {
        if (state.accountMetaData?.contacts) {
          state.accountMetaData.contacts -= 1;
        }
      })
      .addCase(dealActions.addDealSuccess, state => {
        state.accountMetaData = {
          ...state.accountMetaData,
          deals: (Number((state.accountMetaData?.deals ?? 0))) + 1,
        };
      })
      .addCase(dealActions.deleteDealSuccess, state => {
        if (state.accountMetaData?.deals) {
          state.accountMetaData.deals -= 1;
        }
      })
      .addCase(taskActions.addTaskSuccess, state => {
        state.accountMetaData = {
          ...state.accountMetaData,
          tasks: (Number(state.accountMetaData?.tasks ?? 0)) + 1,
        };
      })
      .addCase(taskActions.deleteTaskSuccess, state => {
        if (state.accountMetaData?.tasks) {
          state.accountMetaData.tasks -= 1;
        }
      });
  }
});

export const accountsReducer = accountSlice.reducer;

export const accountActions = accountSlice.actions;
