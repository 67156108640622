import React, { useEffect, useState } from "react";
import { userRoles } from "../../enums/Auth";
import { setFirstTimeLoggedIn } from "../../features/userSlice";
import { useDispatch, useSelector } from "react-redux";
import initialApps from "../../constants/all-apps/card.constant";
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AppCard from "../../components/Appcard/AppCard";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { VISHWAGURU_WEBSITE } from "../../constants/app-constants.constant";

const AllApps = ({ setIsSidenavOpen }) => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const profileData = useSelector((state) => state.userProfile.profileData);

  const dispatch = useDispatch();

  const [appsLoading, setAppsLoading] = useState(true);
  const [apps, setApps] = useState(initialApps);

  const firstTimeLoggedIn = useSelector(
    (state) => state.userProfile.firstTimeLoggedIn,
  );

  useEffect(() => {
    setIsSidenavOpen(false);
    setAppsLoading(true);
    if ([userRoles.SuperAdmin, userRoles.Admin].includes(parseInt(profileData?.role_id))) {
      setApps((prevApps) =>
        prevApps.map((singleApp) =>
          singleApp.appName === "E Learning"
            ? { ...singleApp, appUrl: "/el/" }
            : singleApp,
        ),
      );
    }
    setAppsLoading(false);

    // TODO: Need to add password change modal
    // if (firstTimeLoggedIn) {
    //   //open change password form if first time logged in
    //   const closeAccountFormButton = document.getElementById(
    //     "change-password-menu",
    //   );
    //   closeAccountFormButton.click();
    //   dispatch(setFirstTimeLoggedIn(false));
    // }
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"}>
        <Grid
          container
          mt={{xs: 3, md: 6}}
          mb={2}
        >
          <Grid item xs={12} lg={7}>
            <Box px={!isSmallerScreen && 3} pr={!isMobileScreen && 10}>
              <Box mb={4}>
                <Typography
                  variant={isSmallerScreen ? "h5" : "h4"}
                  component="h4"
                  fontWeight={"450"}
                >
                  Enhance the way you work..
                </Typography>
                <Typography
                  variant={isSmallerScreen ? "h5" : "h4"}
                  component="h4"
                  mb={1}
                  fontWeight={"450"}
                >
                  Life is better with VishwaGuru solutions...
                </Typography>
              </Box>
              <Typography
                variant={"body1"}
                fontSize={isSmallerScreen ? "14px" : "18px"}
                mb={isSmallerScreen ? 2 : 4}
              >
                Redefine your approach to work with dynamic solutions.
                Streamline operations and elevate productivity across every
                aspect of your organization.
              </Typography>
              <Button
                variant="contained"
                type="button"
                className={`${isSmallerScreen ? "primary-small-btn" : "primary-btn"} primary-bg`}
                sx={isSmallerScreen ? { my: 1 } : { my: 2 }}
                onClick={() => window.open(VISHWAGURU_WEBSITE, "_blank")}
              >
                Contact Us
              </Button>
            </Box>
            <Box mt={2} mb={{xs: 2, lg: 0}}>
              <img
                src="/assets/images/all-apps-bg.png"
                alt="all-apps-bg"
                style={{
                  width: "100%",
                }}
              />
            </Box>
          </Grid>
          <Grid
            item
            container
            justifyContent={"flex-end"}
            xs={12}
            lg={5}
            pr={{lg: 3}}
            columnSpacing={2}
          >
            {appsLoading ? (
              <LoadingSpinner />
            ) : (
              apps.map((app) => {
                // Exclude "Asset Management" if role ID is not 1 or 2
                if (
                  app.appName === "Asset Management" &&
                  ![
                    userRoles.SuperAdmin,
                    userRoles.Admin,
                    userRoles.User,
                  ].includes(parseInt(profileData?.role_id))
                ) {
                  return null; // Exclude the app from rendering
                }
                return (
                  <Grid key={app.appName} item mb={1} xs={12} md={6} lg={10}>
                    <AppCard appDetails={app} />
                  </Grid>
                );
              })
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default AllApps;
