const ticketsContent = {
    tickets: "Tickets",
    newTicket: "New Ticket",
    filterBy: "Filter by",
    ticketType: "Ticket Type",
    role: "Role",
    action: "Action",
};

export { ticketsContent };
