import React, { useState, useEffect } from "react";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import "./ReportDetails.scss";
import { navAppNames } from "../../../constants/AppNames";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { DebounceInput } from "react-debounce-input";
import { toast } from "react-toastify";
import { accountApi } from "../../../constants/apis/BD/Account";
import { contactApi } from "../../../constants/apis/BD/Contact";
import { useParams, createSearchParams } from "react-router-dom";
import keyAccountsColumns from "../../../data/json/keyAccountsColumns.json";
import keyCustomersColumns from "../../../data/json/keyCustomersColumns.json";
import keyContactsColumns from "../../../data/json/keyContactsColumns.json";
import { dealApi } from "../../../redux/actions/bd-actions/deals.action";
import salesThisYearReportColumns from "../../../data/json/salesThisYearReportColumns.json";
import lostDealsReportColumns from "../../../data/json/lostDealsReportColumns.json";
import pipelineByStageReportColumns from "../../../data/json/pipelineByStageReportColumns.json";
import pipelineByUserReportColumns from "../../../data/json/pipelineByUserReportColumns.json";
import { stages } from "../../../enums/Deal";
import { useDispatch, useSelector } from "react-redux";
import { userOptionsApi } from "../../../constants/apis/User";
import {
  searchOptionDebounceTime,
  asyncSelectCustomStyles,
  minCharsToLoadOptions,
  autoCloseToastError,
} from "../../../constants/Common";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import {
  setUserOptionsLoaded,
  setUserOptions,
} from "../../../features/optionSlice";
import { taskApi } from "../../../constants/apis/BD/Task";
import { statuses } from "../../../enums/Task";
import taskByStatusReportColumns from "../../../data/json/taskByStatusReportColumns.json";
import taskByUserReportColumns from "../../../data/json/taskByUserReportColumns.json";
import targetAchievementReportColumns from "../../../data/json/targetAchievementReportColumns.json";
import delayedDealsReportColumns from "../../../data/json/delayedDealsReportColumns.json";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { userRoles } from "../../../enums/Auth";
import { targetApi } from "../../../constants/apis/BD/Target";
import { frequencies, months, quarters } from "../../../enums/Target";
import { userOption } from "../../../components/AsyncSelectOption/CustomizedOptions";
import moment from "moment";
import { Select, MenuItem, TablePagination, TableCell, TableRow, TableBody, TableHead, TableContainer, Table, TableSortLabel } from "@mui/material";
import getTableCell from "../../../utils/Common/DataTable";

export default function ReportDetails() {
  const axiosInstance = createInstance(true);
  const dispatch = useDispatch();
  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[6].currentPage = true;

  const { reportId } = useParams() || undefined;

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [rowsLoading, setRowsLoading] = useState(true);
  const [stage, setStage] = useState(stages["negotiation"]);
  const [status, setStatus] = useState(statuses["NotStarted"]);
  const [frequency, setFrequency] = useState(frequencies["Yearly"]);
  const [assignedTo, setAssignedTo] = useState(null);
  const [user, setUser] = useState(null);

  const userOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.userOptionsLoaded
  );
  const userOptions = useSelector(
    (state) => state.optionsForAsyncSelect.userOptions
  );

  const role = Number(localStorage.getItem("role"));

  const handleUserOptions = async () => {
    if (!userOptionsLoaded) {
      const fetchedOptions = await fetchUserOptions("");
      dispatch(setUserOptionsLoaded(true));
      dispatch(setUserOptions(fetchedOptions));
      setAssignedTo(fetchedOptions[0]);
      setUser(fetchedOptions[0].value);
    } else {
      setAssignedTo(userOptions[0]);
      setUser(userOptions[0].value);
    }
  };

  const fetchUserOptions = async (inputValue) => {
    try {
      const response = await axiosInstance.get(
        userOptionsApi + "?search=" + inputValue
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = { location: item.location, mobile: item.mobile };

          return {
            value: item.user_id,
            label: item.assign_to,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching assigned to options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching assigned to options");
    }
    return [];
  };

  const loadUserOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchUserOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const handleDealStage = (e) => {
    setStage(e.target.value);
  };

  const handleTaskStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleTargetFrequency = (e) => {
    setFrequency(e.target.value);
  };

  const createKeyParams = (type, extraParams = {}) => {
    return createSearchParams({
      [type]: true,
      search: searched,
      sort_order: order,
      sort_by: orderBy,
      page: currentPage + 1,
      rows_per_page: rowsPerPage,
      ...extraParams,
    });
  };

  const keyAccountsParams = createKeyParams("keyaccounts");
  const keyCustomersParams = createKeyParams("keycustomers");
  const keyContactsParams = createKeyParams("keycontacts");
  const salesThisYearParams = createKeyParams("salesthisyear");
  const lostDealsParams = createKeyParams("lostdeals");
  const pipelineByUserParams = createKeyParams("pipelinebyuser", {
    user: user,
  });
  const pipelineByStageParams = createKeyParams("pipelinebystage", {
    stage: stage,
  });
  const taskByUserParams = createKeyParams("taskbyuser", { user: user });
  const taskByStatusParams = createKeyParams("taskbystatus", {
    status: status,
  });
  const targetAchievementParams = createKeyParams("targetachievement", {
    user_id: user,
    frequency: frequency,
  });
  const delayedDealsParams = createKeyParams("delayeddeals");

  const idAndReportMappings = {
    101: [
      accountApi + `report-details?${keyAccountsParams}`,
      keyAccountsColumns,
      "Key Accounts",
    ],
    102: [
      accountApi + `report-details?${keyCustomersParams}`,
      keyCustomersColumns,
      "Key Customers",
    ],
    103: [
      contactApi + `report-details?${keyContactsParams}`,
      keyContactsColumns,
      "Key Contacts",
    ],
    104: [
      dealApi + `report-details?${salesThisYearParams}`,
      salesThisYearReportColumns,
      "Sales This Year",
    ],
    105: [
      dealApi + `report-details?${pipelineByStageParams}`,
      pipelineByStageReportColumns,
      "Pipeline By Stage",
    ],
    106: [
      dealApi + `report-details?${pipelineByUserParams}`,
      pipelineByUserReportColumns,
      "Pipeline By User",
    ],
    107: [
      dealApi + `report-details?${lostDealsParams}`,
      lostDealsReportColumns,
      "Lost Deals",
    ],
    108: [
      taskApi + `report-details?${taskByStatusParams}`,
      taskByStatusReportColumns,
      "Task By Status",
    ],
    109: [
      taskApi + `report-details?${taskByUserParams}`,
      taskByUserReportColumns,
      "Task By User",
    ],
    110: [
      targetApi + `report-details?${targetAchievementParams}`,
      targetAchievementReportColumns,
      "Targets vs Achievements",
    ],
    111: [
      dealApi + `report-details?${delayedDealsParams}`,
      delayedDealsReportColumns,
      "Delayed Deals",
    ],
  };

  const fetchData = async () => {
    if (
      (reportId === "106" || reportId === "109" || reportId === "110") &&
      !user
    ) {
      return;
    } else if (
      (reportId === "105" && !stage) ||
      (reportId === "108" && !status)
    ) {
      return;
    }
    setRowsLoading(true);
    try {
      const response = await axiosInstance.get(
        idAndReportMappings[reportId][0]
      );
      if (response.status === 200) {
        const reportRows = response.data.data.rows;
        reportRows.forEach((row) => {
          row.due_date = moment(row.due_date).format("YYYY-MM-DD");
        });
        if (reportId === "110") {
          reportRows.forEach((currentRow) => {
            if (currentRow.frequency === frequencies.Quarterly) {
              currentRow.time_period = quarters[currentRow.time_period];
            } else if (currentRow.frequency === frequencies.Yearly) {
              currentRow.time_period = `Apr ${
                currentRow.financial_year
              } - Mar ${currentRow.financial_year + 1}`;
            } else if (currentRow.frequency === frequencies.Monthly) {
              currentRow.time_period = months[currentRow.time_period];
            }

            if (Number(currentRow.deals_count_difference) > 0) {
              currentRow.deals_count_difference = `+${currentRow.deals_count_difference}`;
            }
            if (Number(currentRow.deals_amount_difference) > 0) {
              currentRow.deals_amount_difference = `+${currentRow.deals_amount_difference}`;
            }
            currentRow.financial_year = `${currentRow.financial_year}-${
              currentRow.financial_year + 1
            }`;
          });
        }
        setRows(reportRows);
        setTotalCount(response.data.data.meta.total);
      } else {
        toast.error("Failed to fetch report details", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Failed to fetch report details");
    }
    setRowsLoading(false);
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    rowsPerPage,
    searched,
    order,
    orderBy,
    stage,
    user,
    status,
    frequency,
  ]);

  useEffect(() => {
    if (reportId === "106" || reportId === "109" || reportId === "110") {
      if (role !== userRoles.User) handleUserOptions();
      else {
        const userId = localStorage.getItem("user_id");
        setUser(userId);
      }
    }
  }, []);

  return (
    <div className="report-details-base">
      <div className="report-details-parent">
        <div className="report-details-content">
          <div className="top-container">
            <div className="report-details-head-name">
              {idAndReportMappings[reportId][2]}
            </div>
            <div className="report-details-actions">
              {reportId === "105" && (
                <div className="select-div second-action">
                  <Select
                    size="small"
                    sx={{ width: "100%" }}
                    value={stage}
                    onChange={(e) => handleDealStage(e)}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {Object.keys(stages)
                      .reverse()
                      .map((key) => {
                        if (key !== "closedWon" && key !== "closedLost") {
                          return (
                            <MenuItem key={key} value={stages[key]}>
                              {stages[key]}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </div>
              )}
              {reportId === "108" && (
                <div className="select-div second-action">
                  <Select
                    size="small"
                    sx={{ width: "100%" }}
                    value={status}
                    onChange={(e) => handleTaskStatus(e)}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {Object.keys(statuses).map((key) => {
                      return (
                        <MenuItem key={key} value={statuses[key]}>
                          {statuses[key]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )}
              {(reportId === "106" ||
                reportId === "109" ||
                reportId === "110") &&
                role !== userRoles.User && (
                  <div className="async-select-container second-action">
                    <AsyncSelect
                      className="report-async-select-field async-select-input"
                      styles={asyncSelectCustomStyles}
                      onChange={(selectedOption) => {
                        setAssignedTo(selectedOption);
                        if (selectedOption) {
                          setUser(selectedOption.value);
                        } else {
                          setUser(null);
                        }
                      }}
                      value={assignedTo}
                      defaultOptions={userOptions || []}
                      loadOptions={debounce(
                        loadUserOptions,
                        searchOptionDebounceTime
                      )}
                      placeholder="Type to search..."
                      name="assigned_to"
                      noOptionsMessage={() => "No user found. Type again..."}
                      isClearable
                      components={{ Option: userOption }}
                    />
                  </div>
                )}
              {reportId === "110" && (
                <div className="select-div second-action">
                  <Select
                    size="small"
                    sx={{ width: "100%" }}
                    className="target-frequency-select"
                    value={frequency}
                    onChange={(e) => handleTargetFrequency(e)}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {Object.keys(frequencies).map((key) => {
                      return (
                        <MenuItem key={key} value={frequencies[key]}>
                          {frequencies[key]}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )}
              {reportId !== "110" && (
                <div className="search-container report-details-search">
                  <div className="search-icon-input-wrapper">
                    <DebounceInput
                      type="text"
                      placeholder="Search"
                      className="search-input"
                      value={searched}
                      onChange={(e) => handleSearch(e)}
                      debounceTimeout={500}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="horizontal-separator"></div>
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows.length === 0 ? (
            "No data found..."
          ) : (
            <>
            <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow>
                      {idAndReportMappings[reportId][1].map((bdCol) =>
                        getTableCell(bdCol, orderBy, order, handleSortRequest)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.map((amReportDetailsRow, i) => {
                        return (
                          <TableRow key={i}>
                            {idAndReportMappings[reportId][1].map(
                              (bdCol) => {
                                return (
                                  <TableCell
                                    key={bdCol.id}
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {amReportDetailsRow[bdCol.id]}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
}
