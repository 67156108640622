import React, { useState, useEffect, useRef } from "react";
import "../../../styles/BD/entityForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { courseApi } from "../../../constants/apis/EL/Course";
import { languages } from "../../../enums/EL/Course";
import { autoCloseToastError } from "../../../constants/Common";
import { toast } from "react-toastify";
import { getHrsMinsSecs } from "../../../utils/Time";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import {
  courseFileMaxSize,
  courseImageMaxSize,
  maxFilesPerCourse
} from "../../../constants/CourseForm";
import RequestInProgressModal from "../../RequestInProgressModal/RequestInProgressModal";
import {
  Box,
  Button,
  Chip,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import { courseDetailsSchema } from "../../../validations/schemas/e-learning/course.schema.validator";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import { DropzoneArea } from "mui-file-dropzone";
import Asterisk from "../../Misc/Asterisk";
import { convertParamsToString } from "../../../utils/Request";
import {
  setCourseDetailsEdit,
  setCourseDetailsEdited,
} from "../../../features/EL/courseDetailsForm";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { extractFileFromUrl } from "../../../utils/File";

const CourseDetailsForm = ({ isOpenProp, handleClose }) => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const courseDetailsEdit = useSelector(
    (state) => state.courseDetailsForm.courseDetailsEdit
  );
  const courseId = useSelector(
    (state) => state.courseDetailsForm.courseDetailsEditId
  );
  const courseDetailsEditOpenToggle = useSelector(
    (state) => state.courseDetailsForm.courseDetailsEditOpenToggle
  );

  const [isOpen, setIsOpen] = useState(isOpenProp);

  const [module, setModule] = useState(null);
  // TODO: Add a progress bar for course file upload
  const [uploadImageProgress, setUploadImageProgress] = useState(0);
  const [uploadCourseFileProgress, setUploadCourseFileProgress] = useState(0);
  const [uploadingCourseFile, setUploadingCourseFile] = useState(false);
  const [uploadingImageFile, setUploadingImageFile] = useState(false);
  const [courseUpdateInProgress, setCourseUpdateInProgress] = useState(false);
  const [courseFileNames, setCourseFileNames] = useState([]);
  const [courseImageName, setCourseImageName] = useState("");
  const [isCourseImageChanged, setIsCourseImageChanged] = useState(false);
  const [fetchingCourseDetails, setFetchingCourseDetails] = useState(true);
  const [courseAssigned, setCourseAssigned] = useState(false);
  const savedCourseFiles = useRef([]);
  const courseFiles = useRef([]);
  const savedCourseFileNames = useRef([]);
  const firstForm = useFormik({
    initialValues: {
      title: "",
      description: "",
      module_id: null,
      language: languages.english,
      hours: 0,
      minutes: 0,
      passing_percentage: 100,
      course_image_name: "",
      deleted_course_file_names: [],
      new_course_file_names: []
    },
    validationSchema: courseDetailsSchema,
    onSubmit: async (data) => {
      const firstFormData = { ...data };
      const durationInSeconds =
        3600 * firstFormData.hours + 60 * firstFormData.minutes;
      if (durationInSeconds < 60) {
        return;
      }

      const deletedCourseFileNames = savedCourseFiles.current.filter((savedFile) => {
        return !courseFiles.current.includes(savedFile);
      });
      const newCourseFileNames = courseFiles.current.filter((savedFile) => {
        return !savedCourseFiles.current.includes(savedFile);
      });

      delete firstFormData.hours;
      delete firstFormData.minutes;
      firstFormData.duration = durationInSeconds;
      firstFormData.passing_percentage = 20;
      firstFormData.courseImageChanged = isCourseImageChanged;
      firstFormData.deleted_course_file_names = deletedCourseFileNames;
      firstFormData.new_course_file_names = newCourseFileNames;

      setCourseUpdateInProgress((_) => true);
      try {
        const axiosInstance = createInstance(true);
        let courseAddResponse = await axiosInstance.put(
          courseApi.concat(`update-course-details/${courseId}`),
          firstFormData
        );
        if (courseAddResponse.data.success) {
          toast.success("Course updated successfully");
          dispatch(setCourseDetailsEdited(true));
          setCourseUpdateInProgress((_) => true);
          handleDrawerClose();
        } else {
          throw new Error("Failed to edit course details", {
            autoClose: autoCloseToastError,
          });
        }
      } catch (error) {
        setCourseUpdateInProgress((_) => false);
        errorHandler(error, "Failed to edit course details");
      }
      setCourseUpdateInProgress((_) => false);
    },
  });

  const onCourseFilesDrop = async (acceptedCourseFiles) => {
    if (acceptedCourseFiles.length === 0) {
      toast.error("Invalid file selected", { autoClose: autoCloseToastError });
      return;
    }

    try {
      const formData = new FormData();
      acceptedCourseFiles.forEach(acceptedFile => {
        formData.append("course_files", acceptedFile);
        setCourseFileNames(currentFiles => [...currentFiles, acceptedFile.name]);
      });

      setUploadingCourseFile(true);

      const axiosInstance = createInstance(true);
      const fileUploadResponse = await axiosInstance.post(
        courseApi.concat("uploadcoursefile"),
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.round((loaded / total) * 100);
            setUploadCourseFileProgress(percentage);
          },
        }
      );
      setUploadingCourseFile(false);

      if (fileUploadResponse.data.success) {
        toast.success("File uploaded to server successfully");
        const firstFormValues = firstForm.values;
        courseFiles.current.push(...fileUploadResponse.data.data.fileNames)
        firstForm.setValues(firstFormValues);
      } else {
        acceptedCourseFiles.length = 0;
        toast.error("Failed to upload the file");
      }
    } catch (error) {
      acceptedCourseFiles.length = 0;
      firstForm.setValues({ ...firstForm.values });
      courseFiles.current = savedCourseFiles.current;
      setCourseFileNames(savedCourseFileNames.current);
      setUploadingCourseFile(false);
      errorHandler(error, "Error in file upload");
    }
    setUploadCourseFileProgress((prev) => 0);
  };

  const handleCourseFileDelete = (fileName) => {
    const index = courseFileNames.findIndex(name => fileName === name);
    const firstFormValues = firstForm.values;
    courseFiles.current.splice(index, 1)
    firstForm.setValues(firstFormValues);
    setCourseFileNames(prev => prev.toSpliced(index, 1));
    toast.info(`${fileName} is successfully removed.`);
  };

  const onImageFileDrop = async (acceptedImageFiles) => {
    firstForm.setValues({ ...firstForm.values, course_image_name: "" });
    setIsCourseImageChanged(true);
    if (acceptedImageFiles.length === 0) {
      toast.error("Invalid file selected", { autoClose: autoCloseToastError });
      return;
    }

    try {
      const imageFile = acceptedImageFiles[0];
      const formData = new FormData();
      formData.append("course_image_file", imageFile);
      setCourseImageName(imageFile.name);

      setUploadingImageFile(true);

      const axiosInstance = createInstance(true);
      const fileUploadResponse = await axiosInstance.post(
        courseApi.concat("uploadcourseimagefile"),
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            const percentage = Math.round((loaded / total) * 100);
            setUploadImageProgress(percentage);
          },
        }
      );
      setUploadingImageFile(false);

      if (fileUploadResponse.data.success) {
        toast.success("File uploaded to server successfully");
        const uploadedFileName = fileUploadResponse.data.data?.filename;
        firstForm.setValues({
          ...firstForm.values,
          course_image_name: uploadedFileName,
        });
      } else {
        acceptedImageFiles.length = 0;
        firstForm.setValues({ ...firstForm.values, course_image_name: "" });
        toast.error("Failed to upload the file");
      }
    } catch (error) {
      acceptedImageFiles.length = 0;
      firstForm.setValues({ ...firstForm.values, course_image_name: "" });
      setUploadingImageFile(false);
      errorHandler(error, "Error in file upload");
    }
    setUploadImageProgress((prev) => 0);
  };

  const handleCourseImageDelete = () => {
    setIsCourseImageChanged(true);
    toast.info(`"${courseImageName}" is successfully removed`);
    firstForm.setValues({ ...firstForm.values, course_image_name: "" });
    setCourseImageName("");
  };

  const handleDrawerClose = (_) => {
    handleClose();
    setIsOpen(false);
  };

  useEffect(() => {
    const fetchCourseDetails = async (courseId) => {
      setFetchingCourseDetails(true);
      try {
        const axiosInstance = createInstance(true);
        const result = await axiosInstance.get(
          courseApi.concat(courseId) +
          convertParamsToString({ dataForEdit: true })
        );
        if (result.data.success) {
          const fetchedCourseData = result.data.data;
          delete fetchedCourseData.questions;

          //calculating hrs and minutes
          const hrsMinsSeconds = getHrsMinsSecs(fetchedCourseData.duration);
          courseFiles.current = fetchedCourseData.course_file_urls.map((url) => {
            return extractFileFromUrl(url);
          });
          savedCourseFiles.current = [...courseFiles.current];
          savedCourseFileNames.current = fetchedCourseData.course_file_names;
          fetchedCourseData.durationHours = hrsMinsSeconds[0];
          fetchedCourseData.durationMinutes = hrsMinsSeconds[1];
          setModule(fetchedCourseData.module_title);
          setCourseImageName(fetchedCourseData.course_image_name);
          setCourseFileNames(fetchedCourseData.course_file_names);
          firstForm.setValues({
            title: fetchedCourseData["course_title"],
            description: fetchedCourseData.description,
            module_id: fetchedCourseData.module_id,
            language: fetchedCourseData.language,
            hours: fetchedCourseData.durationHours,
            minutes: fetchedCourseData.durationMinutes,
            passing_percentage: fetchedCourseData.passing_percentage,
            course_image_name: fetchedCourseData.course_image_name,
            deleted_course_file_names: [],
            new_course_file_names: []
          });

          if (fetchedCourseData.courseAssigned) {
            setCourseAssigned((_) => true);
            toast.warn(
              "Course details cannot be edited as course is already to assigned to some account",
              { autoClose: autoCloseToastError }
            );
          }
        }
      } catch (err) {
        errorHandler(err, "Failed to fetch course details.");
      }
      setFetchingCourseDetails(false);
    };

    if (courseDetailsEdit) {
      setCourseAssigned((_) => false);
      setModule(null);
      fetchCourseDetails(courseId);
      dispatch(setCourseDetailsEdit(false));
    }
  }, [courseDetailsEditOpenToggle, courseDetailsEdit, courseId]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: { xs: "90vw", md: "90vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      anchor={"right"}
      open={isOpen}
      onClose={handleDrawerClose}
    >
      <Box
        my={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">Edit Course</Typography>
        <IconButton
          aria-label="close"
          onClick={handleDrawerClose}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" />

      {fetchingCourseDetails ? (
        <LoadingSpinner />
      ) : (
        <Box my={3}>
          <Grid container>
            <Grid item xs={12} md={5.8} container height={"100%"}>
              <Grid
                item
                xs={12}
                md={12}
                mb={{ xs: 1, md: 4 }}
                mt={isSmallerScreen && 1}
              >
                <Typography variant="h6" className="fw-bold">
                  Course Details
                </Typography>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={12} md={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="module_name">
                  {"Course Title "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={9}>
                <TextField
                  name="title"
                  size="small"
                  value={firstForm.values.title}
                  placeholder="Course Title"
                  onChange={firstForm.handleChange}
                  onBlur={firstForm.handleBlur}
                  error={
                    firstForm.touched.title && Boolean(firstForm.errors.title)
                  }
                  helperText={firstForm.touched.title && firstForm.errors.title}
                  sx={{ mb: 2, width: "100%" }}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Description "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={9}>
                <TextField
                  id="course-description"
                  name="description"
                  placeholder="Description"
                  value={firstForm.values.description}
                  onChange={firstForm.handleChange}
                  onBlur={firstForm.handleBlur}
                  error={
                    firstForm.touched.description &&
                    Boolean(firstForm.errors.description)
                  }
                  helperText={
                    firstForm.touched.description &&
                    firstForm.errors.description
                  }
                  sx={{ mb: 2, width: "100%" }}
                  className={"fw-bold"}
                  size="small"
                  multiline
                />
              </Grid>

              {/* TODO: Need to come back | module data using formik */}
              <Grid item xs={12} md={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Module "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={9} mb={2}>
                <Typography variant="body1">{module}</Typography>
              </Grid>

              <Grid item xs={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Language "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={5}>
                <Select
                  id="course-language"
                  value={firstForm.values.language}
                  name="language"
                  onChange={firstForm.handleChange}
                  onBlur={firstForm.handleBlur}
                  error={
                    firstForm.touched.language &&
                    Boolean(firstForm.errors.language)
                  }
                  size="small"
                  sx={{ width: "100%", mb: 2 }}
                >
                  {Object.keys(languages).map((key) => (
                    <MenuItem key={key} value={languages[key]}>
                      {languages[key]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item lg={4}></Grid>

              <Grid item xs={12} md={12} lg={3} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Duration "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              {/* TODO:  add a time picker*/}
              {/* Previous duration input */}
              <Grid
                item
                container
                xs={12}
                md={12}
                lg={9}
                justifyContent={"start"}
              >
                {/* TODO:  add a time picker*/}
                <Box mr={2} mb={{ xs: 2, sm: 0 }}>
                  <Box display={"flex"} maxWidth={"150px"} alignItems={"end"}>
                    <TextField
                      type="number"
                      name="hours"
                      placeholder="Hours"
                      value={firstForm.values.hours}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={firstForm.handleChange}
                      onBlur={firstForm.handleBlur}
                      error={
                        firstForm.touched.hours &&
                        Boolean(firstForm.errors.hours)
                      }
                      size="small"
                    />
                    <Typography variant="body1" ml={1}>
                      HH
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Box display={"flex"} maxWidth={"150px"} alignItems={"end"}>
                    <TextField
                      type="number"
                      name="minutes"
                      placeholder="Minutes"
                      value={firstForm.values.minutes}
                      InputProps={{ inputProps: { min: 0 } }}
                      onChange={firstForm.handleChange}
                      onBlur={firstForm.handleBlur}
                      error={
                        firstForm.touched.minutes &&
                        Boolean(firstForm.errors.minutes)
                      }
                      size="small"
                    />
                    <Typography variant="body1" ml={1}>
                      MM
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid item lg={3}></Grid>
              <Grid item md={12} lg={9}>
                {firstForm.touched.hours &&
                  firstForm.touched.minutes &&
                  firstForm.values.hours * 3600 +
                  firstForm.values.minutes * 60 <
                  60 && (
                    <Typography variant="caption" color="error" align="center">
                      {`Course duration must be at least 1 minute`}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} md={12} lg={3} pr={2} sx={{ mt: 2 }}>
                <InputLabel
                  className="fw-bold"
                  htmlFor="passing-percentage"
                >
                  {"Passing Percentage "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12} lg={9} sx={{ mt: 2 }}>
                <TextField
                  id="passing-percentage"
                  name="passing_percentage"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  placeholder="35"
                  value={firstForm.values?.passing_percentage}
                  onChange={firstForm.handleChange}
                  onBlur={firstForm.handleBlur}
                  error={
                    firstForm.touched.passing_percentage &&
                    Boolean(firstForm.errors.passing_percentage)
                  }
                  helperText={
                    firstForm.touched.passing_percentage &&
                    firstForm.errors.passing_percentage
                  }
                  sx={{ mb: 2, width: "100%" }}
                  size="small"
                />
              </Grid>
            </Grid>
            {!isSmallerScreen && (
              <Grid item md={0.4} display={"flex"} justifyContent={"center"}>
                <Divider className="horizontal-line" orientation="vertical" />
              </Grid>
            )}
            <Grid item xs={12} md={5.8} container height={"100%"}>
              <Grid
                item
                xs={12}
                md={12}
                mb={{ xs: 1, md: 4 }}
                mt={{ xs: 2, sm: 4, md: 0 }}
              >
                <Typography variant="h6" className="fw-bold">
                  Media Center
                </Typography>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>
              <Grid item xs={12} md={12} pr={2}>
                <InputLabel className="fw-bold" htmlFor="description">
                  {"Upload Course Image "}
                  <Asterisk />
                </InputLabel>
              </Grid>

              <Grid item xs={12} md={12} mb={2}>
                <Box className={`primary-drop-zone`} pl={2}>
                  <DropzoneArea
                    classes={{ root: "drop-container", text: "drop-text" }}
                    filesLimit={1}
                    acceptedFiles={[".jpg, .jpeg, .png"]}
                    maxFileSize={courseImageMaxSize}
                    onDrop={onImageFileDrop}
                    Icon={CloudUploadOutlinedIcon}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    previewGridProps={{
                      container: { spacing: 1, direction: "row" },
                    }}
                  />
                  {courseImageName && (
                    <Box width={"100%"} mt={1}>
                      <Chip
                        label={courseImageName}
                        variant="outlined"
                        onDelete={handleCourseImageDelete}
                      />
                    </Box>
                  )}
                  <Typography variant="caption" mt={1}>
                    jpeg, jpg, png file upto 400KB is allowed
                  </Typography>
                </Box>
                {firstForm.touched.course_image_name &&
                  Boolean(firstForm.errors.course_image_name) && (
                    <Typography variant="caption" color="error" p={2}>
                      {firstForm.errors.course_image_name}
                    </Typography>
                  )}
              </Grid>
              <Grid item xs={12} md={12} pr={2}>
                <InputLabel
                  className="fw-bold"
                  htmlFor="description"
                  sx={{ textAlign: "" }}
                >
                  {"Upload Course Files "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} md={12}>
                <Box className={`primary-drop-zone`} pl={2}>
                  <DropzoneArea
                    classes={{ root: "drop-container", text: "drop-text" }}
                    filesLimit={maxFilesPerCourse}
                    acceptedFiles={[".mp4,.pdf,.txt"]}
                    maxFileSize={courseFileMaxSize}
                    onDrop={onCourseFilesDrop}
                    Icon={CloudUploadOutlinedIcon}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    previewGridProps={{
                      container: { spacing: 1, direction: "row" },
                    }}
                    previewText="Selected files"
                  />
                  {courseFileNames && (
                    <Box width={"100%"} mt={1}>
                      {courseFileNames.length !== 0 && (courseFileNames.map(filename => {
                        return <Chip
                          label={filename}
                          variant="outlined"
                          onDelete={() => handleCourseFileDelete(filename)}
                        />
                      })
                      )}
                    </Box>
                  )}
                  <Typography variant="caption" mt={1}>
                    PDF, MP4, TXT file upto 100MB is allowed
                  </Typography>
                </Box>
                {firstForm.touched.course_file_name &&
                  Boolean(firstForm.errors.course_file_name) && (
                    <Typography variant="caption" color="error" pl={2}>
                      {firstForm.errors.course_file_name}
                    </Typography>
                  )}
              </Grid>
            </Grid>
            <Typography variant="body2" my={2}>
              <Asterisk />
              {" Mandatory Fields"}
            </Typography>
          </Grid>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"end"}
        bottom={0}
        height={"100%"}
      >
        <Divider className="horizontal-line" sx={{ my: 1 }} />

        <Box
          my={3}
          display={"flex"}
          columnGap={5}
          justifyContent={"space-between"}
        >
          <RequestInProgressModal
            show={courseUpdateInProgress}
            headerText={"Course Updation"}
            contentText={"Course update in progress.."}
          />
          <Button
            variant="contained"
            type="button"
            className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""
              }`}
            onClick={handleDrawerClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""
              }`}
            onClick={(e) => {
              firstForm.handleSubmit();
            }}
            disabled={courseAssigned}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CourseDetailsForm;
