import { React, useEffect, useState } from "react";
import { appNames } from "../../../constants/AppNames";
import tsSidebarMenus from "../../../data/json/tsSidebarMenus.json";
import "./TicketReports.scss";
import { userRoles } from "../../../enums/Auth";
import { DebounceInput } from "react-debounce-input";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import ticketColumns from "../../../data/json/ticketColumns.json";
import { type, status } from "../../../enums/TS/Ticket";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { ticketApi } from "../../../constants/apis/TS/Tickets";
import { createSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { userApi } from "../../../constants/apis/User";
import { accountApi } from "../../../constants/apis/BD/Account";
import { accountTypes } from "../../../enums/Account";
import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import TableMenu from "../../../components/TableMenu/TableMenu";
import getTableCell from "../../../utils/Common/DataTable";

const TicketReports = () => {
  const profileData = useSelector((state) => state.userProfile.profileData);
  const canCreateTicket = () => {
    return [userRoles.Admin, userRoles.User].includes(profileData?.role_id);
  };
  const isUser = () => {
    return [userRoles.User].includes(profileData?.role_id);
  };

  const appSidebar = JSON.parse(JSON.stringify(tsSidebarMenus));
  appSidebar.menuList[2].currentPage = true;
  if (!canCreateTicket()) {
    appSidebar.menuList[1].addEntityButton = false;
  }

  if (isUser()) {
    appSidebar.menuList.splice(0, 1);
    appSidebar.menuList.splice(1, 1);
  }

  const reportColumns = JSON.parse(JSON.stringify(ticketColumns));
  reportColumns[0].hasCursorPointer = false;
  reportColumns[0].hasColor = false;
  reportColumns[0].haveDetailsPage = false;
  reportColumns.splice(6, 1);

  const axiosInstance = createInstance(true);
  const [searched, setSearched] = useState("");
  const [rowsLoading, setRowsLoading] = useState(true);
  const [rows, setRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [vendors, setVendors] = useState([]);
  const [filterData, setFilterData] = useState({
    status: status.open,
    type: type.change,
    vendor: '',
  });

  const handleSearch = (e) => {
    setSearched(e.target.value);
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleFilter = (e) => {
    setFilterData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const params = createSearchParams({
    search: searched,
    sort_order: order,
    sort_by: orderBy,
    page: currentPage + 1,
    rows_per_page: rowsPerPage,
    status: filterData.status,
    type: filterData.type,
    vendor: filterData.vendor,
  });

  const fetchData = async () => {
    setRowsLoading(true);
    try {
      const response = await axiosInstance.get(ticketApi + `?${params}`);
      if (response.status === 200) {
        setRows(response.data.data.rows);
        setTotalCount(response.data.data.meta.total);
      } else {
        toast.error("Failed to fetch tickets", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      toast.error("Failed to fetch tickets", {
        autoClose: autoCloseToastError,
      });
    }
    setRowsLoading(false);
  };

  const fetchVendors = async (inputvalue) => {
    const axiosInstance = createInstance(true);
    try {
      const response = await axiosInstance.get(
        accountApi + "/vendor-options?search=" + inputvalue
      );

      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = {};

          // Add additional data based on a condition
          if (item.account_type === accountTypes.Organization) {
            additionalData = {
              account_type: item.account_type,
              gstin: item.gstin,
            };
          } else if (item.account_type === accountTypes.Individual) {
            additionalData = {
              account_type: item.account_type,
              mobile: item.mobile,
              city: item.city,
            };
          }

          return {
            value: item.account_id,
            label: item.account_name,
            additionalData: additionalData, // Include additional data in the result
          };
        });
        setVendors(formattedOptions);
        setFilterData((appliedFiltersData) => {
          return {
            ...appliedFiltersData,
            vendor: formattedOptions[0]?.value ?? '',
          };
        });
        return formattedOptions;
      } else {
        toast.error("Error while fetching vendor options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching vendor options");
    }
    return [];
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, rowsPerPage, searched, order, orderBy, filterData]);

  useEffect(() => {
    fetchVendors("");
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Tickets</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnSpacing={2}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Box className="search-container">
                <DebounceInput
                  type="text"
                  placeholder="Search"
                  className="search-input"
                  value={searched}
                  onChange={(e) => handleSearch(e)}
                  debounceTimeout={500}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid
            container
            mt={1}
            justifyContent={"end"}
            alignItems={"center"}
            columnSpacing={2}
          >
            <Grid item xs={12} md={"auto"}>
              <Typography variant="body2" component="span" sx={{ mr: 1 }}>
                Status
              </Typography>
              <FormControl
                sx={{ minWidth: { xs: "100%", md: "210px" } }}
                size="small"
              >
                <Select
                  id="status"
                  name="status"
                  value={filterData.status}
                  onChange={(e) => handleFilter(e)}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {Object.keys(status).map((key) => {
                    return (
                      <MenuItem key={key} value={status[key]}>
                        {status[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={"auto"}>
              <Typography variant="body2" component="span" sx={{ mr: 1 }}>
                Type
              </Typography>
              <FormControl
                sx={{ minWidth: { xs: "100%", md: "210px" } }}
                size="small"
              >
                <Select
                  id="type"
                  name="type"
                  value={filterData.type}
                  onChange={(e) => handleFilter(e)}
                >
                  <MenuItem value="">
                    <em>Select</em>
                  </MenuItem>
                  {Object.keys(type).map((key) => {
                    return (
                      <MenuItem key={key} value={type[key]}>
                        {type[key]}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {[userRoles.Admin].includes(profileData?.role_id) && (
              <Grid item xs={12} md={"auto"}>
                <Typography variant="body2" component="span" sx={{ mr: 1 }}>
                  Vendor
                </Typography>
                <FormControl
                  sx={{ minWidth: { xs: "100%", md: "210px" } }}
                  size="small"
                >
                  <Select
                    id="vendor"
                    name="vendor"
                    value={filterData.vendor}
                    onChange={(e) => handleFilter(e)}
                  >
                    <MenuItem value="">
                      <em>Select</em>
                    </MenuItem>
                    {vendors.map((vendor) => {
                      return (
                        <MenuItem key={vendor.value} value={vendor.value}>
                          {vendor.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
              </Grid>
            )}
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow>
                      {reportColumns.map((col) => getTableCell(col, orderBy, order, handleSortRequest))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.map((ticketRow) => {
                        return (
                          <TableRow key={ticketRow.ticket_id}>
                            <Tooltip title={ticketRow.title}>
                              <TableCell
                                sx={{ maxWidth: "250px" }}
                                className="text-truncate"
                              >
                                {ticketRow.title}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {ticketRow.asset_name}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {ticketRow.vendor_name}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {ticketRow.support_status}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {ticketRow.priority}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {ticketRow.type}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default TicketReports;
