const loginContent = {
    groupImageAlt: "Logingroup",
    logoImageAlt: "vishwaguru logo",
    login: "Login",
    existingAccount: "Use your existing account login details.",
    mobileNumber: "Mobile Number",
    sendOtp: "Send OTP",
    enterOtp: "Enter OTP",
    signUp: "Sign Up",
};

export { loginContent };
