const Joi = require("joi");

const courseTestPayloadSchema = Joi.object({
    course_id: Joi.number().integer().min(1).required(),
    testResponses: Joi.array().min(1).items(
        Joi.object({
            question_id: Joi.number().integer().min(1).required(),
            option_id: Joi.number().integer().min(1).required(),
        }).length(2).unknown(false)
    ).required(),
}).unknown(false);

export { courseTestPayloadSchema };