import { TableCell, TableSortLabel } from "@mui/material";

/**
 * Generates a table cell element for a sortable table.
 * @param {object} columnInformation - Information about the table column.
 * @param {string} orderBy - The currently active sorting column.
 * @param {string} order - The sorting order ('asc' or 'desc').
 * @param {Function} handleSortRequest - Function to handle sorting when a column header is clicked.
 * @returns {JSX.Element} - A TableCell component with sorting functionality.
 */
const getTableCell = (columnInformation, orderBy, order, handleSortRequest) => {
    return (
      <TableCell key={columnInformation.id}>
        { !columnInformation.isSortingDisabled ?
          <TableSortLabel
            active={orderBy === columnInformation.id}
            direction={orderBy === columnInformation.id ? order : "asc"}
            onClick={() => handleSortRequest(columnInformation.id)}
          >
            {columnInformation.name}
          </TableSortLabel> :
          <TableSortLabel
            active={false}
            hideSortIcon
          >
            {columnInformation.name}
          </TableSortLabel>
        }
      </TableCell>
    );
  };

export default getTableCell;