import React, { useState } from "react";
import { Link } from "react-router-dom";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import { courseApi } from "../../constants/apis/EL/Course";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../constants/Common";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import {
  Box,
  Button,
  Card,
  CardMedia,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import LanguageIcon from "@mui/icons-material/Language";
import StarsIcon from "@mui/icons-material/Stars";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const Coursecard = (props) => {
  const [certificateLoading, setCertificateLoading] = useState(true);
  const [certificateLink, setCertificateLink] = useState(null);

  const [certModalOpen, setCertModalOpen] = useState(false);

  const handleClickOpen = () => {
    setCertModalOpen(true);
  };
  const handleClose = () => {
    setCertModalOpen(false);
  };

  const fetchCertificate = async (courseId) => {
    setCertificateLoading((_) => true);
    try {
      const axiosInstance = createInstance(true);
      const certificateUrlResult = await axiosInstance.get(
        `${courseApi}/certificate/${courseId}`
      );
      if (certificateUrlResult.data.success) {
        setCertificateLink(
          (_) => certificateUrlResult.data.data.certificate_url
        );
        handleClickOpen();
      } else {
        toast.error("Failed to fetch course certificate", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Failed to fetch course certificate");
    }
    setCertificateLoading((_) => false);
  };

  return (
    <>
      <Card
        sx={{ p: 1, height: "100%" }}
        className="content-body-card"
        variant="outlined"
      >
        <Box position={"relative"}>
          <CardMedia
            component={"img"}
            height={"150"}
            image={props.course.image_url}
            sx={{
              backgroundSize: "cover",
              borderRadius: "10px 10px 0 0",
              mb: 1,
            }}
          />
          <Box
            position={"absolute"}
            style={{ bottom: 10, left: "8", transform: "translateX(10%)" }}
          >
            <Chip
              icon={<LanguageIcon />}
              label={props.course.language}
              sx={{ textTransform: "uppercase" }}
              size="small"
              color="primary"
            />
          </Box>
        </Box>
        <Typography variant="h6" className="primary-text">
          {props.course.title}
        </Typography>
        <Divider className="horizontal-line" sx={{ my: 1 }} />
        <Typography
          variant="subtitle2"
          fontWeight={"bold"}
          color={"textSecondary"}
        >
          Module Title
        </Typography>
        <Typography sx={{ mb: 1 }} variant="subtitle2" color={"textSecondary"}>
          {props.course.module_title}
        </Typography>
        <Typography
          variant="subtitle2"
          fontWeight={"bold"}
          color={"textSecondary"}
        >
          Course Description
        </Typography>
        <Typography
          sx={{ mb: 1 }}
          variant="caption"
          component={"h6"}
          color={"textSecondary"}
          className="text-truncate"
        >
          {props.course.description}
        </Typography>
        <Grid container columnGap={2}>
          <Grid item xs={12} md={"auto"} container alignItems={"center"}>
            <Box>
              <StarsIcon fontSize="small" />
            </Box>
            <Box>
              <Typography variant="caption">
                {props.course.total_marks} Marks
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={"auto"} container alignItems={"center"}>
            <Box>
              <StarsIcon fontSize="small" />
            </Box>
            <Box>
              <Typography variant="caption">
                {props.course.durationHrs} Hours {props.course.durationMins}{" "}
                Minutes
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Divider className="horizontal-line" sx={{ my: 1 }} />
        {props.viewCertificate ? (
          <>
            <Box textAlign={"end"}>
              <Button
                size="small"
                onClick={() => fetchCertificate(props.course.id)}
              >
                View Certificate
              </Button>
            </Box>
            <BootstrapDialog
              fullWidth
              onClose={handleClose}
              aria-labelledby="customized-dialog-title"
              open={certModalOpen}
            >
              <DialogTitle sx={{ m: 0, p: 2, fontWeight: "bold" }}>
                Course Certificate
              </DialogTitle>
              <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                }}
                color="primary"
              >
                <CloseIcon />
              </IconButton>
              <DialogContent dividers>
                {certificateLoading || !certificateLink ? (
                  <LoadingSpinner />
                ) : (
                  <Box width={"100%"}>
                    <iframe
                      style={{ width: "100%", height: "398px" }}
                      src={`${certificateLink}#toolbar=0`}
                      title={`${props.course?.id}_iframe`}
                    ></iframe>
                  </Box>
                )}
              </DialogContent>
              <DialogActions>
                <Link to={certificateLink} target={"_blank"} download>
                  <Button
                    variant="contained"
                    className="primary-btn"
                    disabled={certificateLoading}
                  >
                    <FileDownloadOutlinedIcon sx={{ mr: 1 }} />
                    Download
                  </Button>
                </Link>
              </DialogActions>
            </BootstrapDialog>
          </>
        ) : (
          <Box textAlign={"end"}>
            <Button size="small" variant="text" sx={{ p: 1 }}>
              <Link
                to={`/el/courselearning/${props.course.course_id}`}
                style={{ textDecoration: "none", color: "unset" }}
              >
                {props.linkText}
              </Link>
            </Button>
          </Box>
        )}
      </Card>
    </>
  );
};

export default Coursecard;
