const initialApps = [
  {
    appName: "Business Development",
    imagePath: "/assets/images/all-apps-logos/business-development.svg",
    altText: "business_development_icon",
    appUrl: "/bd",
    text: "Business Development: Your robust lead management solution for tracking deals, targets, accounts, and beyond.",
  },
  {
    appName: "E Learning",
    imagePath: "/assets/images/all-apps-logos/e-learning.svg",
    altText: "e_learning_icon",
    appUrl: "/el/my-courses/",
    text: "E-Learning module enables seamless skill development and professional growth with interactive lessons, exams, and certifications.",
  },
  {
    appName: "Asset Management",
    imagePath: "/assets/images/all-apps-logos/asset-management.svg",
    altText: "asset_management_icon",
    appUrl: "/am/",
    text: "Asset Management module efficiently administers organization-owned assets, facilitating allocation and comprehensive management tasks.",
  },
  {
    appName: "Ticketing System",
    imagePath: "/assets/images/all-apps-logos/ticketing-system.svg",
    altText: "ticketing_system_icon",
    appUrl: "/ts/",
    text: "Ticketing system module with ticketing lets users report issues directly tied to specific assets.",
  },
  {
    appName: "Project Management",
    imagePath: "/assets/images/all-apps-logos/project-management.svg",
    altText: "project_management_icon",
    appUrl: "",
    disabled: true,
    text: "Coming Soon...",
  },
  {
    appName: "Digital Documents",
    imagePath: "/assets/images/all-apps-logos/digital-documentation.svg",
    altText: "digital_documentation_icon",
    appUrl: "",
    disabled: true,
    text: "Coming Soon...",
  },
];
export default initialApps;
