import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    assetsOnMaintenance: {},
    assetsOnMaintenanceLoading: false,
    assets: {},
    assetsLoading: false,
    addAssetLoading: false,
    editAssetLoading: false,
    selectedAssetData: null,
    message: null,
    error: null,
};

const assetSlice = createSlice({
    name: "assets",
    initialState,
    reducers: {

        getAssetsOnMaintenanceRequest(state) {
            state.assetsOnMaintenanceLoading = true;
        },

        getAssetsOnMaintenanceSuccess(state, action) {
            state.assetsOnMaintenance = action.payload;
            state.assetsOnMaintenance.rows.forEach((asset) => {
                asset.id = asset.asset_id;
            });

            state.assetsOnMaintenanceLoading = false;
        },

        getAssetsOnMaintenanceFail(state) {
            state.assetsOnMaintenanceLoading = false;
        },

        getAssetsRequest(state) {
            state.assetsLoading = true;
        },

        getAssetsSuccess(state, action) {
            state.assetsLoading = false;
            state.assets = action.payload;
            state.assets.rows.forEach((asset) => {
                asset.id = asset.asset_id;
            });
        },

        getAssetsFail(state) {
            state.assetsLoading = false;
        },


        setSelectedAssetData(state, action) {
            state.selectedAssetData = action.payload;
        },

        addAssetRequest(state) {
            state.addAssetLoading = true;
        },

        addAssetSuccess(state, action) {
            state.addAssetLoading = false;
            let addedAsset = action.payload;
            addedAsset.provider_name = addedAsset.service_provider_name;
            addedAsset.id = addedAsset.asset_id;
            state.assets.rows = [addedAsset, ...state.assets.rows];
        },

        addAssetFail(state) {
            state.addAssetLoading = false;
        },

        editAssetRequest(state) {
            state.editAssetLoading = true;
        },

        editAssetSuccess(state, action) {
            const editedAsset = action.payload;
            editedAsset.id = editedAsset.asset_id;
            state.assets.rows = state.assets.rows.map((asset) => {
                if (editedAsset.id === asset.id) {
                    return { ...editedAsset, provider_name: editedAsset.service_provider_name };
                }
                return asset;
            });
            state.editAssetLoading = false;
        },

        editAssetFail(state) {
            state.editAssetLoading = false;
        },

        deleteAssetRequest(state) {
            state.deleteAssetLoading = true;
        },

        clearError(state) {
            state.error = null;
        },

        clearMessage(state) {
            state.message = null;
        },
    },
});

export const assetReducer = assetSlice.reducer;

export const assetActions = assetSlice.actions;
