import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { toast } from "react-toastify";
import { moduleActions } from "../../reducers/el-reducers/modules.reducer";
import { elDashboardApi } from "../../../constants/apis/EL/ElDashboard";

const axiosInstance = createInstance();

/**
 * getDeals - Get modules data
 * @param {string} param
 */
export const getModules = (params) => async (dispatch) => {
  try {
    dispatch(moduleActions.getModulesRequest());

    const { data } = await axiosInstance.get(elDashboardApi + params);

    dispatch(moduleActions.getModulesSuccess(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get modules");
    dispatch(moduleActions.getModulesFail(error.response.data.message));
  }
};

export const editModuleById = (id, payload) => async (dispatch) => {
  try {
    dispatch(moduleActions.editModuleRequest());

    const { data } = await axiosInstance.put(elDashboardApi + id, payload);

    if(data.success) {
      dispatch(moduleActions.editModuleSuccess(data.data));
      toast.success("Module updated successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to edit module");
    dispatch(moduleActions.editModuleFail(error.response.data.message));
  }
};

export const addModule = (payload) => async (dispatch) => {
  try {
    dispatch(moduleActions.addModuleRequest());

    const { data } = await axiosInstance.post(elDashboardApi, payload);

    if(data.success) {
      dispatch(moduleActions.addModuleSuccess(data.data));
      toast.success("Module added successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to add module");
    dispatch(moduleActions.addModuleFail(error.response.data.message));
  }
};

export const deleteModuleById = (id) => async (dispatch) => {
  try {
    dispatch(moduleActions.deleteModuleRequest());

    const { data } = await axiosInstance.delete(elDashboardApi.concat(id));

    if(data.success) {
      dispatch(moduleActions.deleteModuleSuccess(id));
      toast.success("Module deleted successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to delete module");
    dispatch(moduleActions.deleteModuleFail(error.response.data.message));
  }
};