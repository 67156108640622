const getHrsMinsSecs = (duration = 0) => {
    if(duration === null)
        duration = 0;
    const hrsMinsSecs = [0, 0, 0];
    hrsMinsSecs[0] = Math.floor(duration / 3600);
    duration -= (hrsMinsSecs[0] * 3600);
    hrsMinsSecs[1] = Math.floor(duration / 60);
    duration -= (hrsMinsSecs[1] * 60);
    hrsMinsSecs[2] = duration % 60;
    return hrsMinsSecs;
}

module.exports = { getHrsMinsSecs };