const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    vendorIdForDetails: null,
    vendorDetailsOpen: false
}

export const formSlice = createSlice({
    name: "vendorDetails",
    initialState,
    reducers: {
        setVendorIdForDetails: (state, action) => {
            state.vendorIdForDetails = action.payload;
        },

        setVendorDetailsOpen: (state, action) => {
            state.vendorDetailsOpen = action.payload;
        }
    }
});

export const { setVendorIdForDetails, setVendorDetailsOpen } = formSlice.actions;

export default formSlice.reducer;