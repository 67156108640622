import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { userRoles } from "../../enums/Auth";

const ELLinks = () => {
  const profileData = useSelector((state) => state.userProfile.profileData);
  return (
    <>
      <List sx={{ py: "0", px: 1 }}>

        {[userRoles.SuperAdmin, userRoles.Admin].includes(profileData?.role_id) && <>
          {/* Dashboard */}
          <NavLink
          to={"/el/dashboard"}
          className={({ isActive }) =>
            isActive || window.location.pathname === "/el/"
              ? "active-link"
              : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/dashboard-icon.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
          </NavLink>

          {/* Courses */}
          <NavLink
          to={"/el/course"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/my_courses-icon.png" style={{filter: 'invert(1)'}} height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Courses"} />
          </ListItem>
          </NavLink>

        </>}

        {[userRoles.Admin, userRoles.User].includes(profileData?.role_id) && <>
          {/* My Courses */}
          <NavLink
          to={"/el/my-courses"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/my_courses-icon.png" style={{filter: 'invert(1)'}} height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"My Courses"} />
          </ListItem>
          </NavLink>

          {/* My Certificates */}
          <NavLink
          to={"/el/my-certificates"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/certificate-icon.png" style={{filter: 'invert(1)'}} height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"My Certificates"} />
          </ListItem>
          </NavLink>

        </>}

        {/* Results */}
        {[userRoles.SuperAdmin, userRoles.Admin].includes(profileData?.role_id) &&
          <NavLink
          to={"/el/results"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/results-icon.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Results"} />
          </ListItem>
          </NavLink>
        }

        {/* Assign Course */}
        {[userRoles.SuperAdmin].includes(profileData?.role_id) &&
          <NavLink
          to={"/el/assign-courses"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/course_assignment.png" style={{filter: 'invert(1)'}} height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Assign Courses"} />
          </ListItem>
          </NavLink>
        }

      </List>
    </>
  );
};

export default ELLinks;
