import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { userApi } from "../../constants/apis/User";
import { userProfileSchema } from "../../validations/UserProfile";
import AsyncSelect from "react-select/async";
import {
  searchOptionDebounceTime,
  asyncSelectNewCustomStyles,
  minCharsToLoadOptions,
  autoCloseToastError,
} from "../../constants/Common";
import {
  setLocationOptionsLoaded,
  setLocationOptions,
} from "../../features/optionSlice";
import { accountLocationApi } from "../../constants/apis/BD/AccountLocation";
import debounce from "lodash/debounce";
import {
  setProfileFormField,
  setProfileDataFetched,
  setProfileFirstTimeOpened,
  setProfileUpdated,
} from "../../features/userSlice";
import { titleCase } from "../../utils/Common";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const UserProfile = ({ isProfileOpen, handleDrawerClose }) => {
  const axiosInstance = createInstance(true);
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.userProfile.profileData);

  const userId = profileData?.user_id;

  const profileToggle = useSelector((state) => state.userProfile.profileToggle);
  const profileFormFields = useSelector((state) => state.userProfile);
  const profileDataFetched = useSelector(
    (state) => state.userProfile.profileDataFetched
  );
  const profileFirstTimeOpened = useSelector(
    (state) => state.userProfile.profileFirstTimeOpened
  );

  const initialUserProfileData = {
    first_name: "",
    last_name: "",
    dob: "",
    mobile: "",
    email: "",
    retirement_date: "",
    location_id: "",
  };

  const [userProfileErrors, setUserProfileErrors] = useState({});
  const [userProfileData, setUserProfileData] = useState(
    initialUserProfileData
  );

  const [fetchingUserProflieData, setFetchingUserProfileData] = useState(false);

  const [edit, setEdit] = useState(false);
  const [userLocation, setUserLocation] = useState(null);

  const locationOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.locationOptionsLoaded
  );
  const locationOptions = useSelector(
    (state) => state.optionsForAsyncSelect.locationOptions
  );

  const fetchLocationOptions = async (inputvalue) => {
    try {
      const response = await axiosInstance.get(
        accountLocationApi + "/account-location-options?search=" + inputvalue
      );

      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          return {
            value: item.location_id,
            label: item.location_name.concat(` (${item.location_code})`),
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching location options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching location options");
    }
    return [];
  };

  const loadLocationOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchLocationOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const handleLocationFocus = async () => {
    if (!locationOptionsLoaded) {
      const fetchedOptions = await fetchLocationOptions("");
      dispatch(setLocationOptionsLoaded(true));
      dispatch(setLocationOptions(fetchedOptions));
    }
  };

  const handleUserProfileFields = (e) => {
    setUserProfileErrors({ ...userProfileErrors, [e.target.name]: "" });
    setUserProfileData({ ...userProfileData, [e.target.name]: e.target.value });
  };

  const fetchUserProfileData = async (userId) => {
    setFetchingUserProfileData(true);
    try {
      const result = await axiosInstance.get(
        userApi + `/user-profile/${userId}`
      );
      if (result.status === 200) {
        const fetchedUserProfileData = result.data.data;
        let value, fieldName;
        for (let key in initialUserProfileData) {
          value = fetchedUserProfileData[key] || "";
          fieldName = key;
          dispatch(setProfileFormField({ fieldName, value }));
          if (key === "dob" || key === "retirement_date") {
            const formattedDate = fetchedUserProfileData[key]
              ? moment(fetchedUserProfileData[key]).format("YYYY-MM-DD")
              : "";
            value = formattedDate;
            fieldName = key;
            dispatch(setProfileFormField({ fieldName, value }));
          } else dispatch(setProfileFormField({ fieldName, value }));
        }
        fieldName = "userLocation";
        value = {
          value: fetchedUserProfileData.location_id,
          label: fetchedUserProfileData.location_name.concat(
            ` (${fetchedUserProfileData.location_code})`
          ),
        };
        dispatch(setProfileFormField({ fieldName, value }));
        dispatch(setProfileDataFetched(true));
        dispatch(setProfileFirstTimeOpened(false));
      } else {
        toast.error("Failed to fetch profile details", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Failed to fetch profile details");
    }
    setFetchingUserProfileData(false);
  };

  const editUserProfileData = async (e) => {
    e.preventDefault();
    const {
      firstTimeLoggedIn,
      profileFirstTimeOpened,
      profileDataFetched,
      profileToggle,
      profileUpdated,
      ...updatedProfileData
    } = userProfileData;
    delete updatedProfileData.userLocation;
    delete updatedProfileData.profileData;
    updatedProfileData.first_name = await titleCase(
      updatedProfileData.first_name
    );
    updatedProfileData.last_name = await titleCase(
      updatedProfileData.last_name
    );
    const userId = profileData?.user_id;
    const validation = userProfileSchema.validate(updatedProfileData, {
      abortEarly: false,
    });
    if (validation.error) {
      setUserProfileErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setUserProfileErrors(currentErrors);
    } else {
      try {
        const axiosInstance = createInstance(true);
        let response = await axiosInstance.put(
          userApi + `/user-profile/${userId}`,
          updatedProfileData
        );
        if (response.data.success && response.data.data.length !== 0) {
          toast.success("Profile edited successfully");
          setUserProfileErrors({});
          localStorage.setItem("first_name", updatedProfileData.first_name);
          localStorage.setItem("last_name", updatedProfileData.last_name);
          for (let key in initialUserProfileData) {
            dispatch(
              setProfileFormField({
                fieldName: key,
                value: updatedProfileData[key],
              })
            );
          }
          dispatch(
            setProfileFormField({
              fieldName: "userLocation",
              value: userLocation,
            })
          );
          dispatch(setProfileUpdated(true));
        } else {
          toast.error("Failed to edit profile details", {
            autoClose: autoCloseToastError,
          });
        }
      } catch (err) {
        errorHandler(err, "Failed to edit profile details");
      }
      setEdit(false);
    }
  };

  useEffect(() => {
    if (!profileDataFetched && profileFirstTimeOpened)
      fetchUserProfileData(userId);
    setEdit(false);
    setUserProfileErrors({});
  }, [profileToggle]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: { sm: "90vw", md: "70vw" },
            p: 2,
          },
        }}
        anchor={"right"}
        open={isProfileOpen}
        onClose={handleDrawerClose}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5">
            {edit ? "Edit " : ""} Profile Details
          </Typography>
          <IconButton
            aria-label="close"
            onClick={handleDrawerClose}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider className="horizontal-line" sx={{ my: 1 }} />
        {fetchingUserProflieData ? (
          <LoadingSpinner />
        ) : (
          <>
            <Grid container rowSpacing={2} columnSpacing={2}>
              {edit ? (
                <>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Full Name{" "}
                        <span className="required-field-asterisk"> *</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          name="first_name"
                          value={userProfileData.first_name}
                          onChange={handleUserProfileFields}
                          placeholder="First Name"
                          className="input-value"
                          size="small"
                          error={Boolean(userProfileErrors?.first_name)}
                        />
                        <Typography color={"red"} variant="caption">
                          {userProfileErrors?.first_name || ""}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Last Name{" "}
                        <span className="required-field-asterisk"> *</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          name="last_name"
                          value={userProfileData.last_name}
                          onChange={handleUserProfileFields}
                          placeholder="Last Name"
                          className="input-value"
                          size="small"
                          error={Boolean(userProfileErrors?.last_name)}
                        />
                        <Typography color={"red"} variant="caption">
                          {userProfileErrors.last_name || ""}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Mobile Number{" "}
                        <span className="required-field-asterisk"> *</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          name="mobile"
                          inputProps={{ maxLength: 10 }}
                          value={userProfileData.mobile}
                          onChange={handleUserProfileFields}
                          placeholder="Mobile Number"
                          className="input-value"
                          size="small"
                          error={Boolean(userProfileErrors?.mobile)}
                        />
                        <Typography color={"red"} variant="caption">
                          {userProfileErrors?.mobile || ""}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Email{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth>
                        <TextField
                          type="text"
                          name="email"
                          value={userProfileData.email}
                          onChange={handleUserProfileFields}
                          placeholder="Email"
                          className="input-value"
                          size="small"
                          error={Boolean(userProfileErrors?.email)}
                        />
                        <Typography color={"red"} variant="caption">
                          {userProfileErrors?.email || ""}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        DOB{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth>
                        <TextField
                          type="date"
                          name="dob"
                          value={userProfileData.dob}
                          onChange={handleUserProfileFields}
                          error={Boolean(userProfileErrors?.dob)}
                          size="small"
                        />
                        <Typography color={"red"} variant="caption">
                          {userProfileErrors?.dob || ""}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Retirement Date{" "}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl fullWidth>
                        <TextField
                          type="date"
                          name="retirement_date"
                          value={userProfileData.retirement_date}
                          onChange={handleUserProfileFields}
                          error={Boolean(userProfileErrors?.retirement_date)}
                          className={`input-value ${userProfileData.retirement_date === ""
                            ? "not-selected"
                            : ""
                            }`}
                          size="small"
                        />
                        <Typography color={"red"} variant="caption">
                          {userProfileErrors?.retirement_date || ""}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Location{" "}
                        <span className="required-field-asterisk"> *</span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <FormControl className={`form-control-for-field`}>
                        <AsyncSelect
                          className={`async-select-input ${Boolean(userProfileErrors?.location_id)
                            ? " async-select-error"
                            : ""
                            }`}
                          styles={asyncSelectNewCustomStyles}
                          onChange={(selectedOption) => {
                            setUserProfileErrors({
                              ...userProfileErrors,
                              location_id: "",
                            });
                            setUserLocation(selectedOption);
                            if (selectedOption) {
                              setUserProfileData({
                                ...userProfileData,
                                location_id: selectedOption.value,
                              });
                            } else {
                              setUserProfileData({
                                ...userProfileData,
                                location_id: null,
                              });
                            }
                          }}
                          value={userLocation}
                          defaultOptions={locationOptions}
                          loadOptions={debounce(
                            loadLocationOptions,
                            searchOptionDebounceTime
                          )}
                          placeholder="Type to search..."
                          name="location_id"
                          noOptionsMessage={() =>
                            "No locations found. Type again..."
                          }
                          isClearable
                          onFocus={handleLocationFocus}
                        />
                        <Typography color={"red"} variant="caption">
                          {userProfileErrors?.location_id || ""}
                        </Typography>
                      </FormControl>
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item container md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Full Name
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body2">
                        {profileFormFields.first_name +
                          " " +
                          profileFormFields.last_name}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item md={6}></Grid>
                  <Grid item container md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Mobile Number
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body2">
                        {profileFormFields?.mobile}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Email
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body2">
                        {profileFormFields?.email}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        DOB
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body2">
                        {profileFormFields?.dob}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Retirement Date
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body2">
                        {profileFormFields?.retirement_date}
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid item container md={6}>
                    <Grid item xs={12} md={4}>
                      <Typography
                        variant="body1"
                        fontWeight={"bold"}
                        color={"textSecondary"}
                      >
                        Location
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <Typography variant="body2">
                        {profileFormFields?.userLocation?.label}
                      </Typography>
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
            <Divider className="horizontal-line" sx={{ my: 1 }} />
            <Box>
              <Button
                variant="contained"
                sx={{ mr: 2 }}
                className="primary-btn"
                onClick={(e) => {
                  if (edit) editUserProfileData(e);
                  else {
                    setEdit(true);
                    setUserProfileData(profileFormFields);
                    setUserLocation(profileFormFields?.userLocation);
                  }
                }}
              >
                {edit ? "Save" : "Edit"}
              </Button>

              <Button
                variant="contained"
                color="error"
                onClick={handleDrawerClose}
                className="primary-btn"
              >
                Cancel
              </Button>
            </Box>
          </>
        )}
      </Drawer>
    </>
  );
};

export default UserProfile;
