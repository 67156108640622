const frequencies = Object.freeze({
    Monthly: "Monthly",
    Quarterly: "Quarterly",
    Yearly: "Yearly",
});

const months = Object.freeze({
    1: "Jan",
    2: "Feb",
    3: "Mar",
    4: "Apr",
    5: "May",
    6: "Jun",
    7: "Jul",
    8: "Aug",
    9: "Sep",
    10: "Oct",
    11: "Nov",
    12: "Dec",
});

const quarters = Object.freeze({
    1: "Quarter 4",
    2: "Quarter 1",
    3: "Quarter 2",
    4: "Quarter 3",
});

module.exports = { frequencies, months, quarters };
