import { createSlice } from "@reduxjs/toolkit";
import { getDealsCardsBasedOnRows } from "../../../utils/BD/dealUtils";

const initialState = {
  openDeals: {},
  openDealsLoading: false,
  deals: {},
  dealsLoading: false,
  accountSpecificDeals: {},
  accountSpecificDealsLoading: false,
  addDealLoading: false,
  editDealLoading: false,
  deleteDealLoading: false,
  selectedDealData: null,
  message: null,
  error: null,
};

const dealSlice = createSlice({
  name: "deals",
  initialState,
  reducers: {
    getOpenDealsRequest(state) {
      state.openDealsLoading = true;
    },

    getOpenDealsSuccess(state, action) {
      state.openDealsLoading = false;
      state.openDeals = action.payload;
      state.openDeals.rows.forEach((deal) => {
        deal.id = deal.deal_id;
        deal.probability = deal.probability * 100;
      });
    },

    getOpenDealsFail(state) {
      state.openDealsLoading = false;
    },

    editOpenDealsSuccess(state, action) {
      if (Boolean(state.openDeals?.rows)) {
        const editedDeal = action.payload;
        editedDeal.id = editedDeal.deal_id;
        editedDeal.probability = editedDeal.probability * 100;
        state.openDeals.rows = state.openDeals.rows.map((deal) => {
          if (deal.id === editedDeal.id) {
            return { ...editedDeal, account_name: editedDeal?.account_name, assigned_to_name: editedDeal.assigned_to_username, };
          }
          return deal;
        });
      }
    },

    getDealsRequest(state) {
      state.dealsLoading = true;
    },

    getDealsSuccess(state, action) {
      state.deals = action.payload;
      state.deals.rows.forEach((deal) => {
        deal.id = deal.deal_id;
        deal.probability = parseInt(deal.probability * 100);
      });
      state.dealsLoading = false;
    },

    getDealsFail(state) {
      state.dealsLoading = false;
    },

    getAccountSpecificDealsRequest(state) {
      state.accountSpecificDealsLoading = true;
    },
    getAccountSpecificDealsSuccess(state, action) {
      state.accountSpecificDeals = action.payload;
      state.accountSpecificDeals.rows.forEach((row) => {
        row.id = row.deal_id;
        row.probability = parseInt(row.probability * 100);
      });
      state.accountSpecificDealsLoading = false;
    },
    getAccountSpecificDealsFail(state) {
      state.accountSpecificDealsLoading = false;
    },

    setSelectedDealData(state, action) {
      state.selectedDealData = action.payload;
    },

    addDealRequest(state) {
      state.addDealLoading = true;
    },

    addDealSuccess(state, action) {
      const addedDeal = action.payload.addedDeal;
      const accountSpecific = action.payload.accountSpecific;
      addedDeal.probability = addedDeal.probability * 100;
      if (accountSpecific) {
        if (state.accountSpecificDeals?.rows) {
          state.accountSpecificDeals.rows = [addedDeal, ...state.accountSpecificDeals.rows,];
        }
        if (state.accountSpecificDeals?.meta?.total) {
          state.accountSpecificDeals.meta.total++;
        }
      } else {
        state.deals.rows = [addedDeal, ...state.deals.rows,];
        state.deals.meta.total++;
        state.deals.cards = getDealsCardsBasedOnRows(state.deals.rows)
      }
      state.addDealLoading = false;
    },

    addDealFail(state) {
      state.addDealLoading = false;
    },

    editDealRequest(state) {
      state.editDealLoading = true;
    },

    editDealSuccess(state, action) {
      const editedDeal = action.payload.editedDeal;
      editedDeal.id = editedDeal.deal_id;
      editedDeal.probability = editedDeal.probability * 100;
      const accountSpecific = action.payload.accountSpecific;

      if (accountSpecific) {
        state.accountSpecificDeals.rows = state.accountSpecificDeals.rows.map((deal) => {
          if (deal.id === editedDeal.id) {
            return { ...editedDeal, account_name: editedDeal?.account_name, assigned_to_name: editedDeal.assigned_to_username, };
          }
          return deal;
        })
      } else {
        if (state.deals?.rows) {
          state.deals.rows = state.deals?.rows.map((deal) => {
            if (deal.id === editedDeal.id) {
              return { ...editedDeal, account_name: editedDeal?.account_name, assigned_to_name: editedDeal.assigned_to_username, };
            }
            return deal;
          });
          state.deals.cards = getDealsCardsBasedOnRows(state.deals.rows)
        }
      }
      state.editDealLoading = false;
    },

    editDealFail(state) {
      state.editDealLoading = false;
    },

    deleteDealRequest(state) {
      state.deleteDealLoading = true;

    },

    deleteDealSuccess(state, action) {
      state.deleteDealLoading = false;
      const deletedDealId = action.payload.deletedDealId;
      const accountSpecific = action.payload.accountSpecific;
      if (accountSpecific) {
        state.accountSpecificDeals.rows = state.accountSpecificDeals.rows.filter(deal => deal.id !== deletedDealId);
        state.accountSpecificDeals.meta.total -= 1;
      } else {
        state.deals.rows = state.deals.rows.filter(deal => deal.id !== deletedDealId);
        state.deals.meta.total -= 1;
        state.deals.cards = getDealsCardsBasedOnRows(state.deals.rows)
      }
    },

    deleteDealFail(state) {
      state.deleteDealLoading = false;
    },

    clearError(state) {
      state.error = null;
    },

    clearMessage(state) {
      state.message = null;
    },
  },
});

export const dealReducer = dealSlice.reducer;

export const dealActions = dealSlice.actions;
