import { messages } from "../../constants/validationMessages/TsForms/Ticket";
import { type, priority, status, assign_to } from "../../enums/TS/Ticket";
const Joi = require("joi");

const createTicketSchema = Joi.object().keys({
    title: Joi.string()
        .min(3)
        .max(255)
        .required()
        .messages({
            "string.max": messages.titleMax,
            "string.empty": messages.titleDefault,
        }),
    asset_tag: Joi.string()
        .optional()
        .min(3)
        .max(255)
        .allow(''),
    type: Joi.string()
        .valid(...Object.values(type))
        .messages({
            "string.empty": messages.typeDefault,
            "any.only": messages.typeDefault
        }),
    priority: Joi.string()
        .valid(...Object.values(priority))
        .messages({
            "string.empty": messages.priorityDefault,
            "any.only": messages.priorityDefault
        }),   
    description: Joi.string()
        .allow("")
        .optional(),
    user: Joi.number().integer().min(1).optional().messages({
        "number.min": messages.assignedTo,
        "number.base": messages.assignedTo,
        "any.only": messages.assignedTo,
    }),
    asset_name: Joi.string()
        .required()
        .messages({
            "string.empty": messages.assetNameDefault,
            "any.only": messages.assetNameDefault
        }),
    make: Joi.string()
        .required()
        .messages({
            "string.empty": messages.makeDefault,
            "any.only": messages.makeDefault
        }),
    model: Joi.string()
        .required()
        .messages({
            "string.empty": messages.modelDefault,
            "any.only": messages.modelDefault
        }),
    
});

const editTicketSchema = Joi.object().keys({
    status: Joi.string()
        .valid(...Object.values(status))
        .messages({
            "string.empty": messages.statusDefault,
            "any.only": messages.statusDefault
        }),
    assign_to: Joi.string()
        .valid(...Object.values(assign_to))
        .messages({
            "string.empty": messages.assignToDefault,
            "any.only": messages.assignToDefault
        }),
});

export { createTicketSchema, editTicketSchema };