import { messages } from "../../constants/validationMessages/AMForms/AddAssignment";

const Joi = require("joi");

const assignmentSchema = Joi.object().keys({
    
    asset_name: Joi.string()
    .required()
    .empty()
    .messages({
            "string.empty":messages.assetNameDefault,
            "any.only": messages.assetNameDefault
    }),

    make: Joi.string()
    .empty()
    .required()
    .messages({
        "string.empty":messages.make,
        "any.only": messages.makeValid
    }),

    model: Joi.string()
    .empty()
    .required()
    .messages({
        "string.empty":messages.model,
        "any.only": messages.modelValid
    }),

    date_of_purchase: 
        Joi.any()
        .custom((value,helpers)=>{
            if(!value){
           return helpers.message("please select date of purchase");}
           return value;
        }
    ).messages({
        "any.custom":messages.dateOfPurchase,
    }),
    

    assigned_on: 
        Joi.any()
        .custom((value,helpers)=>{
            if(value===null || value===""){
           return helpers.message("please select assigned on date");}
           return value;
        }
    ).messages({
        "any.custom":messages.assignedOn,
    }),

    user_id: Joi.number()
    .integer()
    .min(1)
    .required()
    .messages({
      "number.min": messages.assignedTo,
      "number.base": messages.assignedTo,
      "any.only": messages.assignedTo,
    }),
   
    asset_tag: Joi.string()
          .min(1)
          .max(255)
          .required()
          .messages({
    "string.min": messages.assetTagDefault,
    "string.max": messages.assetTag,
    "string.empty": messages.assetTag,
}),
})

export {assignmentSchema}