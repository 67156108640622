const { createSlice } = require("@reduxjs/toolkit");


const  initialState = {
    assignmentId: null,
    assignmentAddOpenToggle: false,
    assignmentAdded: false,
}

export const formSlice = createSlice({
    name:"assignment",
    initialState,
    reducers:{
        setAssignmentId : (state, action)=>{
            state.assignmentId=action.payload;
        },
        setAssignmentAddOpenToggle:(state,action)=>{
            state.assignmentAddOpenToggle=!state.assignmentAddOpenToggle;
        },
        setAssignmentAdded:(state,action)=>{
            state.assignmentAdded=action.payload;
        },
    }
});

export const {setAssignmentId, setAssignmentAddOpenToggle, setAssignmentAdded}=formSlice.actions;
export default formSlice.reducer;