import { messages } from "../constants/validationMessages/UserProfile";
const Joi = require("joi");

const userProfileSchema = Joi.object().keys({
    first_name: Joi.string().min(1).max(255).required().messages({
        "string.max": messages.firstNameMax,
        "string.empty": messages.firstNameDefault,
    }),

    last_name: Joi.string().min(1).max(255).required().messages({
        "string.max": messages.lastNameMax,
        "string.empty": messages.lastNameDefault,
    }),

    mobile: Joi.string()
        .required()
        .min(10)
        .max(10)
        .regex(/^\d*$/)
        .messages({
            "string.empty": messages.mobileEmpty,
            "string.min": messages.mobileDefault,
            "string.max": messages.mobileDefault,
            "string.pattern.base": messages.mobileRegex,
        }),

    email: Joi.string()
        .allow("")
        .min(3)
        .max(255)
        .email({ tlds: { allow: false } })
        .messages({
            "string.min": messages.emailMin,
            "string.max": messages.emailMax,
            "string.empty": messages.emailEmpty,
            "email.base": messages.emailInvalid,
            "string.email": messages.emailInvalid,
        }),

    dob: Joi.alternatives([
        Joi.string().valid(""),
        Joi.date().max("now").required().messages({
            "date.base": messages.dobDefault,
            "date.max": messages.dobDefault,
        }),
    ]).required(),

    retirement_date: Joi.alternatives([
        Joi.string().valid(""),
        Joi.date().min("now").required().messages({
            "date.base": messages.retirementDefault,
            "date.min": messages.retirementDefault,
        }),
    ]).required(),

    location_id: Joi.number().integer().min(1).required().messages({
        "number.min": messages.locationId,
        "number.base": messages.locationId,
        "any.only": messages.locationId,
    }),
});

export { userProfileSchema };
