const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    vendorEdit: false,
    vendorId: null,
    vendorEditOpenToggle: false,
    vendorAddOpenToggle: false,
    vendorAdded: false,
    vendorEdited: false,
}

export const formSlice = createSlice({
    name:"vendorForm",
    initialState,
    reducers:{
        setVendorEdit : (state, action)=>{
            state.vendorEdit = action.payload;
        },
        setVendorId : (state, action)=>{
            state.vendorId=action.payload;
        },
        setVendorEditOpenToggle:(state,action)=>{
            state.vendorEditOpenToggle=!state.vendorEditOpenToggle;
        },
        setVendorAddOpenToggle:(state,action)=>{
            state.vendorAddOpenToggle=!state.vendorAddOpenToggle;
        },
        setVendorAdded:(state,action)=>{
            state.vendorAdded=action.payload;
        },
        setVendorEdited:(state,action)=>{
            state.vendorEdited=action.payload;
        }
}
});
export const {setVendorEdit, setVendorId, setVendorAddOpenToggle, setVendorEditOpenToggle, setVendorAdded, setVendorEdited}=formSlice.actions;
export default formSlice.reducer;