import { Country, State, City } from "country-state-city";

const getAllCountriesEnx = () => {
    try {
        const countries = Country.getAllCountries();
        const formattedCountries = [
            //default country
            {
                name: "Select Country",
                value: "",
            }
        ];

        countries.forEach(country => {
            formattedCountries.push({
                name: country.name,
                value: country.isoCode,
            });
        });

        return formattedCountries;
    } catch (error) {
        console.error("Error fetching countries:", error);
    }
    return [];
}

const getStatesForCountry = (countryCode) => {
    try {
        const statesInCountry = State.getStatesOfCountry(countryCode);
        const formattedStates = [
            //default state
            {
                name: "Select State",
                value: ""
            }
        ];

        statesInCountry.forEach(state => {
            formattedStates.push({
                name: state.name,
                value: state.isoCode
            });
        });

        return formattedStates;
    } catch (error) {
        console.error("Error fetching states:", error);
    }
    return [];
}

const getCitiesForState = (countryCode,stateCode) => {
    try {
        const citiesInState = City.getCitiesOfState(countryCode, stateCode);
        const formattedCities = [
            //default city
            {
                name: "Select City",
                value: ""
            }
        ];

        citiesInState.forEach(state => {
            formattedCities.push({
                name: state.name,
                value: state.name
            });
        });

        return formattedCities;
    } catch (error) {
        console.error("Error fetching cities:", error);
    }
    return [];
}

export { getAllCountriesEnx, getStatesForCountry, getCitiesForState };