import React, { useEffect, useState } from "react";
import accountColumns from "../../../data/json/accountColumns.json";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import { useDispatch, useSelector } from "react-redux";
import { createSearchParams, useNavigate } from "react-router-dom";
import { DebounceInput } from "react-debounce-input";
import { categories } from "../../../enums/Account";
import { checkAllSelection } from "../../../utils/Common";
import { errorHandler } from "../../../utils/Request/ReqUtils";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Checkbox,
  Typography,
  TablePagination,
  TableCell,
  Tooltip,
  TableRow,
  TableBody,
  TableHead,
  TableContainer,
  Table,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import NewAccount from "../../../components/BdForms/NewAccount/NewAccount";
import TableMenu from "../../../components/TableMenu/TableMenu";
import getTableCell from "../../../utils/Common/DataTable";
import { deleteAccountById, getAllAccounts } from "../../../redux/actions/bd-actions/accounts.action";
import { accountActions } from "../../../redux/reducers/bd-reducers/accounts.reducer";

export default function Account() {
  const initialFilterAccountTypes = {
    individual: false,
    organization: false,
  };
  const initialFilterCategories = {
    customer: false,
    prospect: false,
    vendor: false,
  };
  const [searched, setSearched] = useState("");
  const [filterButtonAnchorEl, setFilterButtonAnchorEl] = useState(null);

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [filterAccountType, setFilterAccountType] = useState(
    initialFilterAccountTypes
  );
  const [filterCategories, setFilterCategories] = useState(
    initialFilterCategories
  );

  const accountState = useSelector((state) => state.accounts);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const params = createSearchParams({
    search: searched,
    sort_order: order,
    sort_by: orderBy,
    page: currentPage + 1,
    rows_per_page: rowsPerPage,
    ...(!checkAllSelection(filterAccountType) && {
      ...(filterAccountType.individual && { individual: true }),
      ...(filterAccountType.organization && { organization: true }),
    }),
    ...(!checkAllSelection(filterCategories) && {
      ...(filterCategories.customer && { customer: true }),
      ...(filterCategories.prospect && { prospect: true }),
      ...(filterCategories.vendor && { vendor: true }),
    }),
  });

  const fetchData = async () => {
    try {
      dispatch(getAllAccounts(params));
    } catch (err) {
      errorHandler(err, "Failed to fetch accounts");
    }
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const setAddAccountForm = () => {
    setIsAccountFormOpen(true);
  };

  const fillDataInForm = (id) => {
    dispatch(accountActions.setSelectedAccount(id));
    setAccountEditProp(true);
    setIsAccountFormOpen(true);
  };

  const handleOrganizationFilter = (e) => {
    setFilterAccountType({
      ...filterAccountType,
      [e.target.name]: e.target.checked,
    });
    setCurrentPage(0);
  };

  const handleCategoryFilter = (e) => {
    setFilterCategories({
      ...filterCategories,
      [e.target.name]: e.target.checked,
    });
    setCurrentPage(0);
  };

  const clearFilter = () => {
    setFilterAccountType(initialFilterAccountTypes);
    setFilterCategories(initialFilterCategories);
    setCurrentPage(0);
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    rowsPerPage,
    searched,
    order,
    orderBy,
    filterAccountType,
    filterCategories,
  ]);

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    setCurrentPage(0);
    setSearched(e.target.value);
  };

  const deleteRow = async (id) => {
    try {
      dispatch(deleteAccountById(id));
    } catch (err) {
      errorHandler(err, "Account deletion failed");
    }
  };

  const handleFilterButtonClick = (event) => {
    setFilterButtonAnchorEl(event.currentTarget);
  };

  const handleFilterButtonClose = () => {
    setFilterButtonAnchorEl(null);
  };

  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[2].currentPage = true;

  const [isAccountFormOpen, setIsAccountFormOpen] = useState(false);
  const [accountEditProp, setAccountEditProp] = useState(false);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isAccountFormOpen && (
          <NewAccount
            isOpenProp={isAccountFormOpen}
            handleClose={() => setIsAccountFormOpen(false)}
            accountEditProp={accountEditProp}
          />
        )}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Accounts</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnSpacing={2}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Grid item xs={12} my={1} md={"auto"}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              <Grid item xs={8} md={"auto"}>
                <Button
                  variant="outlined"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasTicketForm"
                  onClick={() => setAddAccountForm()}
                  startIcon={<AddIcon />}
                >
                  New Account
                </Button>
              </Grid>
              <Grid item xs={4} md={"auto"} textAlign={"end"}>
                <IconButton onClick={handleFilterButtonClick}>
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-add-button"
                  disableScrollLock
                  anchorEl={filterButtonAnchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(filterButtonAnchorEl)}
                  onClose={handleFilterButtonClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        borderRadius: "10px",
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                >
                  <Box sx={{ p: 1, textAlign: "start" }}>
                    <Typography color={"textSecondary"}>Show Only</Typography>
                  </Box>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="individual"
                      id="account-individual"
                      onChange={(e) => handleOrganizationFilter(e)}
                      checked={filterAccountType.individual}
                    />
                    <Typography textAlign="center">Individual</Typography>
                  </MenuItem>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="organization"
                      id="account-organization"
                      onChange={(e) => handleOrganizationFilter(e)}
                      checked={filterAccountType.organization}
                    />
                    <Typography textAlign="center">Organization</Typography>
                  </MenuItem>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  <Box sx={{ p: 1, textAlign: "start" }}>
                    <Typography color={"textSecondary"}>Category</Typography>
                  </Box>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="customer"
                      onChange={(e) => handleCategoryFilter(e)}
                      checked={filterCategories.customer}
                    />
                    <Typography textAlign="center">
                      {categories.Customer}
                    </Typography>
                  </MenuItem>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="prospect"
                      onChange={(e) => handleCategoryFilter(e)}
                      checked={filterCategories.prospect}
                    />
                    <Typography textAlign="center">
                      {categories.Prospect}
                    </Typography>
                  </MenuItem>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Checkbox
                      name="vendor"
                      onChange={(e) => handleCategoryFilter(e)}
                      checked={filterCategories.vendor}
                    />
                    <Typography textAlign="center">
                      {categories.Vendor}
                    </Typography>
                  </MenuItem>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  <MenuItem
                    onClick={() => clearFilter()}
                    sx={{ pr: 4, py: 1, textAlign: "start" }}
                  >
                    <Typography className="primary-text" fontWeight={"bold"}>
                      Clear Filter
                    </Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {accountState?.allAccountsLoading ? (
            <LoadingSpinner />
          ) : accountState?.allAccounts?.rows?.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                  <Table size="medium">
                    <TableHead className="stat-card-bg">
                      <TableRow>
                        { accountColumns.map((col) => getTableCell(col, orderBy, order, handleSortRequest)) }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {accountState?.allAccounts?.rows &&
                        accountState?.allAccounts?.rows?.map((accountRow) => {
                          return (
                            <TableRow key={accountRow.id}>
                              <Tooltip title={accountRow.account_name}>
                                <TableCell
                                  sx={{ maxWidth: "250px" }}
                                  className="text-truncate primary-text link-hover"
                                  onClick={() => {
                                    dispatch(dispatch(accountActions.setSelectedAccount(accountRow.id)))
                                    navigate(`/bd/account/${accountRow.id}`)
                                  }}
                                >
                                  {accountRow.account_name}
                                </TableCell>
                              </Tooltip>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {accountRow.account_type}
                              </TableCell>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {accountRow.account_category}
                              </TableCell>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {accountRow.city}
                              </TableCell>

                              <TableCell align="right">
                                <TableMenu
                                  fillDataInForm={fillDataInForm}
                                  rowId={accountRow.id}
                                  isDeleteOptionEnabled={true}
                                  deleteModalData={['Account', accountRow.account_name]}
                                  handleDeleteRow={() => deleteRow(accountRow.id)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={accountState?.allAccounts?.meta?.total || 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
}
