import * as yup from "yup";

const moduleValidationSchema = yup.object({
  title: yup
    .string()
    .trim()
    .required("Module name cannot be empty")
    .max(255, "Maximum 255 characters are allowed"),
  description: yup.string("Enter description"),
});

export default moduleValidationSchema;
