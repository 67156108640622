import { messages } from "../../constants/validationMessages/ElForms/Course";
import { languages } from "../../enums/EL/Course";
const Joi = require("joi");

const courseFirstStepSchema = Joi.object().keys({
    title: Joi.string()
        .min(1)
        .max(255)
        .required()
        .messages({
            "string.max": messages.titleMax,
            "string.empty": messages.titleDefault,
            "any.required": messages.titleDefault
        }),

    description: Joi.string()
        .min(1)
        .required()
        .messages({
            "string.empty": messages.descriptionMin,
            "any.required": messages.descriptionDefault
        }),

    module_id: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            "number.min": messages.moduleId,
            "number.base": messages.moduleId
        }),

    passing_percentage: Joi.number()
        .integer()
        .min(1)
        .max(100)
        .required()
        .messages({
            "number.base": messages.passingPercentageDefault,
            "number.min": messages.passingPercentageMin,
            "number.max": messages.passingPercentageMax
        }),

    duration: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            "number.min": messages.duration,
            "number.base": messages.duration
        }),

    language: Joi.string()
        .valid(...Object.values(languages))
        .required()
        .messages({
            "string.empty": messages.language,
            "any.only": messages.language
        }),

    course_file_name: Joi.string()
        .min(1)
        .required()
        .messages({
            "string.empty": messages.courseFile,
            "any.required": messages.courseFile
        }),

    course_image_name: Joi.string()
        .min(1)
        .required()
        .messages({
            "string.empty": messages.courseFile,
            "any.required": messages.courseFile
        }),
}).unknown(false);

const optionSchema = Joi.object({
    option_text: Joi.string()
        .min(1)
        .required()
        .messages({
            "string.empty": messages.optionText,
            "any.required": messages.optionText
        }),

    is_correct: Joi.boolean()
        .required()
        .messages({
            "any.required": messages.is_correct
        }),
}).unknown(false);

const singleQuestionSchema = Joi.object({
    question_text: Joi.string()
        .required()
        .messages({
            "string.empty": messages.questionText,
            "any.required": messages.questionText
        }),

    marks: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            "number.min": messages.marksMin,
            "number.base": messages.marksDefault
        }),

    options: Joi.array()
        .length(4)
        .items(optionSchema)

        .custom((value, helpers) => {
            // Check if at least one option has is_correct set to true
            const hasCorrectOption = value.some(option => option.is_correct === true);

            if (!hasCorrectOption) {
                return helpers.message(messages.correctOptionMissing);
            }
            return value;
        })
        .required(),
}).unknown(false);

const singleQuestionUpdateSchema = Joi.object({
    question_text: Joi.string()
        .required()
        .messages({
            "string.empty": messages.questionText,
            "any.required": messages.questionText
        }),

    marks: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            "number.min": messages.marksMin,
            "number.base": messages.marksDefault
        }),

    options: Joi.array()
        .length(4)
        .items(optionSchema.unknown(true))

        .custom((value, helpers) => {
            // Check if at least one option has is_correct set to true
            const hasCorrectOption = value.some(option => option.is_correct === true);

            if (!hasCorrectOption) {
                return helpers.message(messages.correctOptionMissing);
            }
            return value;
        })
        .required(),
}).unknown(true);

const courseUpdateSchema = Joi.object().keys({
    title: Joi.string()
        .min(1)
        .max(255)
        .required()
        .messages({
            "string.max": messages.titleMax,
            "string.empty": messages.titleDefault,
            "any.required": messages.titleDefault
        }),

    description: Joi.string()
        .min(1)
        .required()
        .messages({
            "string.empty": messages.descriptionMin,
            "any.required": messages.descriptionDefault
        }),

    passing_percentage: Joi.number()
        .integer()
        .min(1)
        .max(100)
        .required()
        .messages({
            "number.base": messages.passingPercentageDefault,
            "number.min": messages.passingPercentageMin,
            "number.max": messages.passingPercentageMax
        }),

    duration: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            "number.min": messages.duration,
            "number.base": messages.duration
        }),

    language: Joi.string()
        .valid(...Object.values(languages))
        .required()
        .messages({
            "string.empty": messages.language,
            "any.only": messages.language
        }),

    course_file_name: Joi.string()
        .min(1)
        .required()
        .messages({
            "string.empty": messages.courseFile,
            "any.required": messages.courseFile
        }),

    course_image_name: Joi.string()
        .min(1)
        .required()
        .messages({
            "string.empty": messages.courseFile,
            "any.required": messages.courseFile
        }),

    courseFileChanged: Joi.boolean()
        .required()
        .messages({
            "any.required": messages.courseImageChanged
        }),

    courseImageChanged: Joi.boolean()
        .required()
        .messages({
            "any.required": messages.courseFileChanged
        })
}).unknown(false);

export { courseFirstStepSchema, singleQuestionSchema,singleQuestionUpdateSchema,  optionSchema, courseUpdateSchema };