import React, { Fragment, useEffect, useState } from "react";
import dealColumns from "../../../data/json/dealColumns.json";
import contactColumns from "../../../data/json/contactColumns.json";
import taskColumns from "../../../data/json/taskColumns.json";
import accountLocationColumns from "../../../data/json/accountLocationColumns.json";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import {
  setDealAccountId,
  setDealAccountName,
  setDealAddAccountSpecific,
  setDealAddOpenToggle,
  setDealAdded,
  setDealEdit,
  setDealEditOpenToggle,
  setDealEdited,
  setDealId,
} from "../../../features/BD/dealFormSlice";
import EntityCountCardRow from "../../../components/EntityCountCard/EntityCountCardRow";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { dealApi, deleteDealById, getAccountSpecificDeals } from "../../../redux/actions/bd-actions/deals.action";
import { convertParamsToString } from "../../../utils/Request";
import { DebounceInput } from "react-debounce-input";
import {
  autoCloseToastError,
  searchOptionDebounceTime,
} from "../../../constants/Common";
import { contactApi } from "../../../constants/apis/BD/Contact";
import {
  setContactAddOpenToggle,
  setContactEditOpenToggle,
  setContactEdit,
  setContactId,
  setContactEdited,
  setContactAddAccountSpecific,
  setContactAccountId,
  setContactAccountName,
  setContactAdded,
} from "../../../features/BD/contactForm";
import { taskApi } from "../../../constants/apis/BD/Task";
import { toast } from "react-toastify";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { accountTypes } from "../../../enums/Account";
import moment from "moment";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import LocationForm from "../../../components/BdForms/LocationForm/LocationForm";
import TaskForm from "../../../components/BdForms/TaskForm/TaskForm";
import NewAccount from "../../../components/BdForms/NewAccount/NewAccount";
import DealForm from "../../../components/BdForms/DealForm/DealForm";
import ContactForm from "../../../components/BdForms/ContactForm/ContactForm";
import TableMenu from "../../../components/TableMenu/TableMenu";
import getTableCell from "../../../utils/Common/DataTable";
import { getAllAccounts, getIndividualAccount, getSelectedAccountMetaData } from "../../../redux/actions/bd-actions/accounts.action";
import { deleteTaskById, getAccountSpecificTasks } from "../../../redux/actions/bd-actions/tasks.action";
import { taskActions } from "../../../redux/reducers/bd-reducers/tasks.reducer";
import { getAllLocations } from "../../../redux/actions/bd-actions/location.action";
import { locationActions } from "../../../redux/reducers/bd-reducers/location.reducer";
import { dealActions } from "../../../redux/reducers/bd-reducers/deals.reducer";
import { deleteContactById, getAccountSpecificContacts } from "../../../redux/actions/bd-actions/contacts.action";
import { contactActions } from "../../../redux/reducers/bd-reducers/contacts.reducer";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const BdAccountSpecific = () => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { accountId } = useParams() || undefined;
  const dealState = useSelector((state) => state.deals);
  const accountState = useSelector((state) => state.accounts);
  const taskState = useSelector((state) => state.tasks);
  const contactState = useSelector((state) => state.contacts);
  const locationState = useSelector((state) => state.locations);

  let editAccountData = {...accountState?.selectedAccount};
  if (editAccountData?.dob) {
    const formattedDate = Boolean(editAccountData?.dob)
      ? moment(editAccountData.dob).format("DD-MM-YYYY")
      : "";
    editAccountData.dob = formattedDate;
  }

  const entityCardArray = [
    {
      entityName: "deals",
      entityText: "Deals",
      entityCount: accountState?.accountMetaData?.deals,
      imagePath: "/assets/images/deals_big.svg",
      imageAlt: "deals_big",
    },
    {
      entityName: "contacts",
      entityText: "Contacts",
      entityCount: accountState?.accountMetaData?.contacts,
      imagePath: "/assets/images/contacts_big.svg",
      imageAlt: "contacts_big",
    },
    {
      entityName: "tasks",
      entityText: "Tasks",
      entityCount: accountState?.accountMetaData?.tasks,
      imagePath: "/assets/images/tasks_big.svg",
      imageAlt: "tasks_big",
    },
  ];

  // =====================================
  // Deal starts here
  // =====================================
  let dealColumnsLocal = JSON.parse(JSON.stringify(dealColumns));
  dealColumnsLocal.splice(2, 1);

  const [dealCurrentPage, setDealCurrentPage] = useState(0);
  const [dealRowsPerPage, setDealRowsPerPage] = useState(25);
  const [dealSearched, setDealSearched] = useState("");
  const [dealOrder, setDealOrder] = useState();
  const [dealOrderBy, setDealOrderBy] = useState();

  const [isDealFormOpen, setIsDealFormOpen] = useState(false);
  const [dealEditProp, setDealEditProp] = useState(false);

  const requestDealData = async () => {
    //if currently the tab of deals is not active
    //then don't fetch the deal data
    if (previouslyClicked.name !== "dealsSelect") return;

    const requestParams = {};

    //handle search
    if (dealSearched !== "") {
      requestParams["search"] = dealSearched;
    }

    //handle sorting here
    if (dealOrderBy && dealOrder) {
      requestParams["sort_by"] = dealOrderBy;
      requestParams["sort_order"] = dealOrder;
    }

    //apply pagination here
    requestParams["page"] = dealCurrentPage + 1;
    requestParams["rows_per_page"] = dealRowsPerPage;

    dispatch(getAccountSpecificDeals(accountId, convertParamsToString(requestParams)));
  };

  const handleDealSortRequest = (cellId) => {
    const isAscending = dealOrderBy === cellId && dealOrder === "asc";
    setDealOrder(isAscending ? "desc" : "asc");
    setDealOrderBy(cellId);
    setDealCurrentPage(0);
  };

  const handleDealPage = (e, newPage) => {
    setDealCurrentPage(newPage);
  };

  const deleteDealRow = async (id) => {
    try {
      dispatch(deleteDealById(id, true));
    } catch (err) {
      errorHandler(err, "Failed to delete deal");
    }
  };

  const handleDealRowsPerPage = (e) => {
    setDealRowsPerPage(e.target.value);
    setDealCurrentPage(0);
  };

  const setAddDealForm = () => {
    setAddButtonAnchorEl(null);
    dispatch(dealActions.setSelectedDealData(null));
    setIsDealFormOpen(true);
    setDealEditProp(false);
  };

  const fillDataInFormDeal = (id) => {
    const selectedDealData = dealState?.accountSpecificDeals?.rows?.filter(
      (deal) => deal.deal_id === id
    )?.[0];

    dispatch(dealActions.setSelectedDealData(selectedDealData));
    setIsDealFormOpen(true);
    setDealEditProp(true);
  };

  const handleDealSearch = (e) => {
    e.preventDefault();
    setDealCurrentPage(0);
    setDealSearched(e.target.value);
  };

  useEffect(() => {
    requestDealData();
  }, [dealCurrentPage, dealRowsPerPage, dealSearched, dealOrder, dealOrderBy]);

  // =====================================
  // Deal ends here
  // =====================================

  // =====================================
  // Contact starts here
  // =====================================
  let contactColumnsLocal = JSON.parse(JSON.stringify(contactColumns));
  contactColumnsLocal.splice(2, 1);

  const [contactCurrentPage, setContactCurrentPage] = useState(0);
  const [contactRowsPerPage, setContactRowsPerPage] = useState(25);
  const [contactTotalCount, setContactTotalCount] = useState(0);
  const [contactRows, setContactRows] = useState([]);
  const [contactSearched, setContactSearched] = useState("");
  const [contactOrder, setContactOrder] = useState();
  const [contactOrderBy, setContactOrderBy] = useState();
  const [contactRowsLoading, setContactRowsLoading] = useState(false);
  const contactAdded = useSelector((state) => state.contactForm.contactAdded);
  const contactEdited = useSelector((state) => state.contactForm.contactEdited);
  const contactAddedForAccountId = useSelector(
    (state) => state.contactForm.contactAddedForAccountId
  );
  const contactEditOpenToggle = useSelector(
    (state) => state.contactForm.contactEditOpenToggle
  );

  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [contactEditProp, setContactEditProp] = useState(false);

  const requestContactData = async () => {
    //if currently the tab of deals is not active
    //then don't fetch the deal data
    if (previouslyClicked.name !== "contactsSelect") return;

    setContactRowsLoading(true);
    const requestParams = {};

    //handle search
    if (contactSearched !== "") {
      requestParams["search"] = contactSearched;
    }

    //handle sorting here
    if (contactOrderBy && contactOrder) {
      requestParams["sort_by"] = contactOrderBy;
      requestParams["sort_order"] = contactOrder;
    }

    //apply pagination here
    requestParams["page"] = contactCurrentPage + 1;
    requestParams["rows_per_page"] = contactRowsPerPage;

    dispatch(getAccountSpecificContacts(accountId, convertParamsToString(requestParams)));
  };

  const handleContactSortRequest = (cellId) => {
    const isAscending = contactOrderBy === cellId && contactOrder === "asc";
    setContactOrder(isAscending ? "desc" : "asc");
    setContactOrderBy(cellId);
    setContactCurrentPage(0);
  };

  const handleContactPage = (e, newPage) => {
    setContactCurrentPage(newPage);
  };

  const deleteContactRow = async (id) => {
    try {
      dispatch(deleteContactById(id, true));
    } catch (err) {
      errorHandler(err, "Failed to delete contact");
    }
  };

  const handleContactRowsPerPage = (e) => {
    setContactRowsPerPage(e.target.value);
    setContactCurrentPage(0);
  };

  const setAddContactForm = () => {
    setAddButtonAnchorEl(null);
    dispatch(contactActions.setSelectedContactData(null));
    setIsContactFormOpen(true);
    setContactEditProp(false);
  };

  const fillDataInFormContact = (id) => {
    const selectedContactData = contactState?.accountSpecificContacts?.rows?.filter(
      (contact) => contact.contact_id === id
    )?.[0];

    dispatch(contactActions.setSelectedContactData(selectedContactData));
    setIsContactFormOpen(true);
    setContactEditProp(true);
  };

  const handleContactSearch = (e) => {
    e.preventDefault();
    setContactCurrentPage(0);
    setContactSearched(e.target.value);
  };

  const handleContactDrawerClose = () => {
    if (contactEditOpenToggle) {
      dispatch(setContactEditOpenToggle());
      return;
    }
    dispatch(setContactAddOpenToggle());
  };

  useEffect(() => {
    requestContactData();
  }, [
    contactCurrentPage,
    contactRowsPerPage,
    contactSearched,
    contactOrder,
    contactOrderBy,
  ]);

  useEffect(() => {
    if (
      contactAdded &&
      contactAddedForAccountId === editAccountData?.account_id
    ) {
      if (contactCurrentPage !== 0) {
        setDealCurrentPage(0);
      } else {
        requestContactData();
      }
      fetchAndSetEntityCards();
    }
    dispatch(setContactAdded(false));
  }, [contactAdded]);

  useEffect(() => {
    if (contactEdited) {
      if (contactCurrentPage !== 0) {
        setContactCurrentPage(0);
      } else {
        requestContactData();
      }
      fetchAndSetEntityCards();
      dispatch(setContactEdited(false));
    }
  }, [contactEdited]);

  // =====================================
  // Contact ends here
  // =====================================

  // =====================================
  // Task starts here
  // =====================================
  let taskColumnsLocal = JSON.parse(JSON.stringify(taskColumns));
  taskColumnsLocal.splice(2, 1);

  const [taskCurrentPage, setTaskCurrentPage] = useState(0);
  const [taskRowsPerPage, setTaskRowsPerPage] = useState(25);
  const [taskTotalCount, setTaskTotalCount] = useState(0);
  const [taskRows, setTaskRows] = useState([]);
  const [taskSearched, setTaskSearched] = useState("");
  const [taskOrder, setTaskOrder] = useState();
  const [taskOrderBy, setTaskOrderBy] = useState();
  const [taskRowsLoading, setTaskRowsLoading] = useState(true);
  const taskAdded = useSelector((state) => state.taskForm.taskAdded);
  const taskEdited = useSelector((state) => state.taskForm.taskEdited);
  const taskAddedForAccountId = useSelector(
    (state) => state.taskForm.taskAddedForAccountId
  );

  const requestTaskData = async () => {
    //if currently the tab of deals is not active
    //then don't fetch the deal data
    if (previouslyClicked.name !== "tasksSelect") return;

    const requestParams = {};

    //handle search
    if (taskSearched !== "") {
      requestParams["search"] = taskSearched;
    }

    //handle sorting here
    if (taskOrderBy && taskOrder) {
      requestParams["sort_by"] = taskOrderBy;
      requestParams["sort_order"] = taskOrder;
    }

    //apply pagination here
    requestParams["page"] = taskCurrentPage + 1;
    requestParams["rows_per_page"] = taskRowsPerPage;

    dispatch(getAccountSpecificTasks(accountId, convertParamsToString(requestParams)));
  };

  const handleTaskSortRequest = (cellId) => {
    const isAscending = taskOrderBy === cellId && taskOrder === "asc";
    setTaskOrder(isAscending ? "desc" : "asc");
    setTaskOrderBy(cellId);
    setTaskCurrentPage(0);
  };

  const handleTaskPage = (e, newPage) => {
    setTaskCurrentPage(newPage);
  };

  const deleteTaskRow = async (id) => {
    try {
      dispatch(deleteTaskById(id, true));
    } catch (err) {
      errorHandler(err, "Failed to delete task");
    }
  };

  const handleTaskRowsPerPage = (e) => {
    setTaskRowsPerPage(e.target.value);
    setTaskCurrentPage(0);
  };

  const setAddTaskForm = () => {
    setAddButtonAnchorEl(null);
    setTaskEditProp(false);
    setIsTaskFormOpen(true);
  };

  const fillDataInFormTask = (id) => {
    dispatch(taskActions.setSelectedAccountSpecificTask(id));
    setTaskEditProp(true);
    setIsTaskFormOpen(true);
  };

  const handleTaskSearch = (e) => {
    e.preventDefault();
    setTaskCurrentPage(0);
    setTaskSearched(e.target.value);
  };

  useEffect(() => {
    requestTaskData();
  }, [taskCurrentPage, taskRowsPerPage, taskSearched, taskOrder, taskOrderBy]);

  // =====================================
  // Task ends here
  // =====================================

  // Location starts here
  // =====================================
  const [locationCurrentPage, setLocationCurrentPage] = useState(0);
  const [locationRowsPerPage, setLocationRowsPerPage] = useState(25);
  const [locationTotalCount, setLocationTotalCount] = useState(0);
  const [locationRows, setLocationRows] = useState([]);
  const [locationSearched, setLocationSearched] = useState("");
  const [locationOrder, setLocationOrder] = useState();
  const [locationOrderBy, setLocationOrderBy] = useState();
  const [accountLocationRowsLoading, setAccountLocationRowsLoading] =
    useState(true);
  const locationAdded = useSelector(
    (state) => state.locationForm.locationAdded
  );
  const locationEdited = useSelector(
    (state) => state.locationForm.locationEdited
  );
  const locationAddedForAccountId = useSelector(
    (state) => state.locationForm.locationAddedForAccountId
  );

  const handleLocationSortRequest = (cellId) => {
    const isAscending = locationOrderBy === cellId && locationOrder === "asc";
    setLocationOrder(isAscending ? "desc" : "asc");
    setLocationOrderBy(cellId);
    setLocationCurrentPage(0);
  };

  const handleLocationPage = (e, newPage) => {
    setLocationCurrentPage(newPage);
  };

  const handleLocationRowsPerPage = (e) => {
    setLocationRowsPerPage(e.target.value);
    setLocationCurrentPage(0);
  };

  const handleLocationSearch = (e) => {
    e.preventDefault();
    setLocationCurrentPage(0);
    setLocationSearched(e.target.value);
  };

  const setAddLocationForm = () => {
    setAddButtonAnchorEl(null);
    setEditLocationProp(false);
    setIsLocationFormOpen(true);
  };

  const fillDataInFormLocation = (id) => {
    dispatch(locationActions.setSelectedLocation(id));
    setEditLocationProp(true);
    setIsLocationFormOpen(true);
  };

  const requestLocationsData = async (accountsId) => {
    if (previouslyClicked.name !== "locationsSelect") return;
    setAccountLocationRowsLoading(true);
    try {
      const requestParams = { accountId: accountsId };

      //handle search
      if (locationSearched !== "") {
        requestParams["search"] = locationSearched;
      }

      //handle sorting here
      if (locationOrderBy && locationOrder) {
        requestParams["sort_by"] = locationOrderBy;
        requestParams["sort_order"] = locationOrder;
      }

      //apply pagination here
      requestParams["page"] = locationCurrentPage + 1;
      requestParams["rows_per_page"] = locationRowsPerPage;
      dispatch(getAllLocations(convertParamsToString(requestParams)));
    } catch (err) {
      setLocationRows([]);
      setLocationTotalCount(0);
      errorHandler(err, "Failed to fetch account locations");
    }
    setAccountLocationRowsLoading(false);
  };

  useEffect(() => {
    requestLocationsData(accountId);
  }, [
    locationCurrentPage,
    locationRowsPerPage,
    locationSearched,
    locationOrder,
    locationOrderBy,
  ]);

  // =====================================
  // Location ends here
  // =====================================

  const [addButtonAnchorEl, setAddButtonAnchorEl] = useState(null);
  const [previouslyClicked, setPreviouslyClicked] = useState({
    name: "",
    element: "",
  });
  const accountEdited = useSelector((state) => state.account.accountEdited);

  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[2].currentPage = true;

  const tabs = [
    {
      divId: "account-details",
      name: "accountDetails",
      anchorId: "account-details-tab",
      targetId: "account-details-container",
      text: "Account Details",
      initiallyActive: true,
    },
    {
      divId: "deals-select",
      name: "dealsSelect",
      anchorId: "deals-tab",
      targetId: "deals-container",
      text: "Deals",
    },
    {
      divId: "contacts-select",
      name: "contactsSelect",
      anchorId: "contacts-tab",
      targetId: "contacts-container",
      text: "Contacts",
    },
    {
      divId: "tasks-select",
      name: "tasksSelect",
      anchorId: "tasks-tab",
      targetId: "tasks-container",
      text: "Tasks",
    },
    {
      divId: "locations-select",
      name: "locationsSelect",
      anchorId: "locations-tab",
      targetId: "locations-container",
      text: "Locations",
    },
  ];

  const individualAccountDetailsFields = [
    {
      sideBySide: false,
      field: {
        label: "Full Name",
        value: editAccountData?.account_name,
      },
    },
    {
      sideBySide: false,
      field: {
        label: "Account Type",
        value: editAccountData?.account_type,
      },
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "Industry",
          value: editAccountData?.industry,
        },
        {
          label: "Account Category",
          value: editAccountData?.account_category,
        },
      ],
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "First Name",
          value: editAccountData?.first_name,
        },
        {
          label: "Last Name",
          value: editAccountData?.last_name,
        },
      ],
    },
    {
      sideBySide: false,
      field: {
        label: "Status",
        value: editAccountData?.status,
      },
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "Mobile Number",
          value: editAccountData?.mobile,
        },
        {
          label: "Email",
          value: editAccountData?.email,
        },
      ],
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "Occupation",
          value: editAccountData?.occupation,
        },
        {
          label: "DOB",
          value: editAccountData?.dob,
        },
      ],
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "Aadhar Number",
          value: editAccountData?.aadhar,
        },
        {
          label: "PAN Number",
          value: editAccountData?.pan,
        },
      ],
    },
    {
      sideBySide: false,
      field: {
        label: "Description",
        value: editAccountData?.description,
      },
    },
  ];

  const organizationAccountDetailsFields = [
    {
      sideBySide: false,
      field: {
        label: "Account Name",
        value: editAccountData?.account_name,
      },
    },
    {
      sideBySide: false,
      field: {
        label: "Account Type",
        value: editAccountData?.account_type,
      },
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "Industry",
          value: editAccountData?.industry,
        },
        {
          label: "Account Category",
          value: editAccountData?.account_category,
        },
      ],
    },
    {
      sideBySide: false,
      field: {
        label: "Status",
        value: editAccountData?.status,
      },
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "Mobile Number",
          value: editAccountData?.mobile,
        },
        {
          label: "Email",
          value: editAccountData?.email,
        },
      ],
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "GSTIN",
          value: editAccountData?.gstin,
        },
        {
          label: "PAN Number",
          value: editAccountData?.pan,
        },
      ],
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "Annual Revenue                    ",
          value: editAccountData?.annual_revenue,
        },
        {
          label: "No. Of Employees",
          value: editAccountData?.no_of_employees,
        },
      ],
    },
    {
      sideBySide: false,
      field: {
        label: "Description",
        value: editAccountData?.description,
      },
    },
  ];

  const accountAddressFields = [
    {
      sideBySide: false,
      field: {
        label: "Street Address",
        value: editAccountData?.street_address,
      },
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "City                   ",
          value: editAccountData?.city,
        },
        {
          label: "State",
          value: editAccountData?.state,
        },
      ],
    },
    {
      sideBySide: true,
      fields: [
        {
          label: "Country                   ",
          value: editAccountData?.country,
        },
        {
          label: "Pincode",
          value: editAccountData?.pincode,
        },
      ],
    },
    {
      sideBySide: false,
      field: {
        label: "Website",
        value: editAccountData?.website,
      },
    },
  ];

  //Used to change style of clicked tab option
  const handleClick = (tabId, tabName) => {
    const currentlyClickedElement = document.getElementById(tabId);
    if (currentlyClickedElement === previouslyClicked.element) return;

    if (previouslyClicked.element !== null) {
      previouslyClicked.element.classList.remove("selected");
    }
    currentlyClickedElement.classList.add("selected");
    setPreviouslyClicked({ name: tabName, element: currentlyClickedElement });
    //set Entity cards
    if (tabName != "accountDetails") fetchAndSetEntityCards();
  };

  const handleEdit = () => {
    setAccountEditProp(true);
    setIsAccountFormOpen(true);
  };

  const fetchAndSetEntityCards = async () => {
    try {
      dispatch(getSelectedAccountMetaData(accountId));
    } catch (err) {
      errorHandler(err, "Failed to get card counts");
    }
  };

  useEffect(() => {
    fetchAndSetEntityCards();

    let initiallySelectedElement = document.getElementById("account-details");
    setPreviouslyClicked({
      name: "accountDetails",
      element: initiallySelectedElement,
    });
  }, []);

  //when tab is changed, new data is fetched
  useEffect(() => {
    requestDealData();
    requestContactData();
    requestTaskData();
    requestLocationsData(accountId);
  }, [previouslyClicked]);

  useEffect(() => {
    // update selected account with accountId from parameter
    if (!Boolean(Object.keys(accountState?.allAccounts).length)) {
      dispatch(getIndividualAccount(accountId));
    }
  }, [accountId])


  const handleAddButtonClick = (event) => {
    setAddButtonAnchorEl(event.currentTarget);
  };

  const handleAddButtonClose = () => {
    setAddButtonAnchorEl(null);
  };

  const [tabIndex, setTabIndex] = useState(0);

  const handleTabChange = (event, newIndex) => {
    setTabIndex(newIndex);
  };

  const [isLocationFormOpen, setIsLocationFormOpen] = useState(false);
  const [editLocationProp, setEditLocationProp] = useState(false);
  const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);
  const [taskEditProp, setTaskEditProp] = useState(false);
  const [isAccountFormOpen, setIsAccountFormOpen] = useState(false);
  const [accountEditProp, setAccountEditProp] = useState(false);

  const accountData = {
    account_name: editAccountData?.account_name,
    account_id: editAccountData?.account_id,
  }

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isDealFormOpen && <DealForm
          handleDrawerClose={() => setIsDealFormOpen(false)}
          isSmallerScreen={isSmallerScreen}
          isDealFormOpen={isDealFormOpen}
          editDealProp={dealEditProp}
          accountSpecific={true}
          dealAddAccountSpecific={true}
          accountData={accountData}
        />}
        {isContactFormOpen && <ContactForm
          handleDrawerClose={() => setIsContactFormOpen(false)}
          isSmallerScreen={isSmallerScreen}
          isOpen={isContactFormOpen}
          contactEditProp={contactEditProp}
          accountSpecific={true}
          contactAddAccountSpecific={true}
          accountData={accountData}
        />}
        {isLocationFormOpen && (
          <LocationForm
            isOpenProp={isLocationFormOpen}
            handleClose={() => setIsLocationFormOpen(false)}
            editLocationProp={editLocationProp}
          />
        )}
        {isTaskFormOpen && (
          <TaskForm
            isOpenProp={isTaskFormOpen}
            handleClose={() => setIsTaskFormOpen(false)}
            taskEditProp={taskEditProp}
            accountSpecific={true}
            accountData={accountData}
          />
        )}
        {isAccountFormOpen && (
          <NewAccount
            isOpenProp={isAccountFormOpen}
            handleClose={() => setIsAccountFormOpen(false)}
            accountEditProp={accountEditProp}
          />
        )}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7} sx={{ display: 'flex', alignItems: 'center' }}>
              <span
                onClick={() => {
                  navigate("/bd/accounts");
                }}
                style={{
                  cursor: "pointer",
                  marginRight: "5px",
                  fontWeight: "bold",
                }}
              >
                <IconButton className="primary-text">
                  <ArrowBackIcon />
                </IconButton>
              </span>
              <Typography variant="h6" >

                {editAccountData?.account_name}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnGap={{ md: 2 }}
              justifyContent={{ xs: "space-between", md: "end" }}
              alignItems={"center"}
            >
              <Grid item xs={6} md={"auto"}>
                <Button variant="outlined" onClick={handleEdit}>
                  Edit Details
                </Button>
              </Grid>
              <Grid item xs={5} md={"auto"}>
                <>
                  <Button
                    variant="outlined"
                    onClick={handleAddButtonClick}
                    startIcon={<AddIcon />}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    New
                  </Button>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-add-button"
                    disableScrollLock
                    anchorEl={addButtonAnchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(addButtonAnchorEl)}
                    onClose={handleAddButtonClose}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          borderRadius: "10px",
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem
                      sx={{ pr: 4, py: 1, textAlign: "start" }}
                      onClick={() => setAddDealForm()}
                    >
                      <Typography textAlign="center">Deal</Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ pr: 4, py: 1, textAlign: "start" }}
                      onClick={() => setAddContactForm()}
                    >
                      <Typography textAlign="center">Contact</Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ pr: 4, py: 1, textAlign: "start" }}
                      onClick={() => setAddTaskForm()}
                    >
                      <Typography textAlign="center">Task</Typography>
                    </MenuItem>
                    <MenuItem
                      sx={{ pr: 4, py: 1, textAlign: "start" }}
                      onClick={() => setAddLocationForm()}
                    >
                      <Typography textAlign="center">Location</Typography>
                    </MenuItem>
                  </Menu>
                </>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          <EntityCountCardRow cardArray={entityCardArray} />
          <Tabs
            variant="scrollable"
            scrollButtons={isSmallerScreen}
            allowScrollButtonsMobile={isSmallerScreen}
            value={tabIndex}
            onChange={handleTabChange}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.divId}
                id={tab.divId}
                onClick={() => handleClick(tab.divId, tab.name)}
                label={tab.text}
                sx={{ textTransform: "capitalize", fontSize: "17px" }}
              />
            ))}
          </Tabs>
          {tabIndex === 0 && (
            <Paper variant="outlined" sx={{ p: 2, border: "none" }}>
              <Typography fontWeight={"bold"} sx={{ mb: 3 }}>
                Account Details
              </Typography>

              {editAccountData?.account_type === accountTypes.Organization && (
                <>
                  <Grid container rowSpacing={2}>
                    {organizationAccountDetailsFields.map((row, index) => {
                      return row.sideBySide ? (
                        <Fragment key={index}>
                          <Grid item container xs={12} md={6}>
                            <Grid item xs={12} md={6} lg={4}>
                              <Typography
                                variant="body1"
                                fontWeight={"bold"}
                                color={"textSecondary"}
                              >
                                {row.fields[0].label}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                              <Typography variant="body2">
                                {row.fields[0].value}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} md={6}>
                            <Grid item xs={12} md={6} lg={4}>
                              <Typography
                                variant="body1"
                                fontWeight={"bold"}
                                color={"textSecondary"}
                              >
                                {row.fields[1].label}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                              <Typography variant="body2">
                                {row.fields[1].value}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Fragment>
                      ) : (
                        <Fragment key={index}>
                          <Grid item container xs={12} md={6}>
                            <Grid item xs={12} md={6} lg={4}>
                              <Typography
                                variant="body1"
                                fontWeight={"bold"}
                                color={"textSecondary"}
                              >
                                {row.field.label}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                              <Typography variant="body2">
                                {row.field.value}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} md={6}></Grid>
                        </Fragment>
                      );
                    })}
                  </Grid>
                </>
              )}

              {editAccountData?.account_type === accountTypes.Individual && (
                <>
                  <Grid container rowSpacing={2}>
                    {individualAccountDetailsFields.map((row, i) => {
                      return row.sideBySide ? (
                        <Fragment key={i}>
                          <Grid item container xs={12} md={6}>
                            <Grid item xs={12} md={6} lg={4}>
                              <Typography
                                variant="body1"
                                fontWeight={"bold"}
                                color={"textSecondary"}
                              >
                                {row.fields[0].label}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                              <Typography variant="body2">
                                {row.fields[0].value}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} md={6}>
                            <Grid item xs={12} md={6} lg={4}>
                              <Typography
                                variant="body1"
                                fontWeight={"bold"}
                                color={"textSecondary"}
                              >
                                {row.fields[1].label}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                              <Typography variant="body2">
                                {row.fields[1].value}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Fragment>
                      ) : (
                        <Fragment key={i}>
                          <Grid item container xs={12} md={6}>
                            <Grid item xs={12} md={6} lg={4}>
                              <Typography
                                variant="body1"
                                fontWeight={"bold"}
                                color={"textSecondary"}
                              >
                                {row.field.label}
                              </Typography>
                            </Grid>
                            <Grid item xs={12} md={6} lg={8}>
                              <Typography variant="body2">
                                {row.field.value}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid item container xs={12} md={6}></Grid>
                        </Fragment>
                      );
                    })}
                  </Grid>
                </>
              )}

              <Divider className="horizontal-line" sx={{ my: 1 }} />

              <Typography fontWeight={"bold"} sx={{ mb: 3 }}>
                Address Details
              </Typography>

              <Grid container rowSpacing={2}>
                {accountAddressFields.map((row, index) => {
                  return row.sideBySide ? (
                    <Fragment key={index}>
                      <Grid item container xs={12} md={6}>
                        <Grid item xs={12} md={6} lg={4}>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            color={"textSecondary"}
                          >
                            {row.fields[0].label}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={8}>
                          <Typography variant="body2">
                            {row.fields[0].value}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} md={6}>
                        <Grid item xs={12} md={6} lg={4}>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            color={"textSecondary"}
                          >
                            {row.fields[1].label}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={8}>
                          <Typography variant="body2">
                            {row.fields[1].value}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Fragment>
                  ) : (
                    <Fragment key={index}>
                      <Grid item container xs={12} md={6}>
                        <Grid item xs={12} md={6} lg={4}>
                          <Typography
                            variant="body1"
                            fontWeight={"bold"}
                            color={"textSecondary"}
                          >
                            {row.field.label}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={6} lg={8}>
                          <Typography variant="body2">
                            {row.field.value}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid item container xs={12} md={6}></Grid>
                    </Fragment>
                  );
                })}
              </Grid>

              <Divider className="horizontal-line" sx={{ my: 1 }} />
            </Paper>
          )}
          {tabIndex === 1 && (
            <Paper variant="outlined" sx={{ p: 2, border: "none" }}>
              <Grid container mb={2}>
                <Grid item xs={12} md={6}>
                  <Typography fontWeight={"bold"}>Deals</Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign={"end"}>
                  <Box className="search-container">
                    <DebounceInput
                      type="text"
                      placeholder="Search"
                      className="search-input"
                      value={dealSearched}
                      onChange={(e) => handleDealSearch(e)}
                      debounceTimeout={searchOptionDebounceTime}
                    />
                  </Box>
                </Grid>
              </Grid>
              {dealState?.accountSpecificDealsLoading ? (
                <LoadingSpinner />
              ) : dealState?.accountSpecificDeals?.rows?.length === 0 ? (
                "No data found..."
              ) : (
                <>
                  <TableContainer>
                    <Table size="medium">
                      <TableHead className="stat-card-bg">
                        <TableRow>
                          {dealColumnsLocal.map((col) => getTableCell(col, dealOrderBy, dealOrder, handleDealSortRequest))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {dealState?.accountSpecificDeals?.rows &&
                          dealState?.accountSpecificDeals?.rows?.map((dealRow) => {
                            return (
                              <TableRow key={dealRow.deal_id}>
                                <Tooltip title={dealRow.deal_name}>
                                  <TableCell
                                    sx={{ maxWidth: "250px" }}
                                    className="text-truncate primary-text link-hover"
                                    onClick={() => fillDataInFormDeal(dealRow.deal_id)}
                                  >
                                    {dealRow.deal_name}
                                  </TableCell>
                                </Tooltip>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {dealRow.deal_stage}
                                </TableCell>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {dealRow.probability}
                                </TableCell>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {dealRow.amount}
                                </TableCell>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {dealRow.assigned_to_name}
                                </TableCell>

                                <TableCell align="right">
                                  <TableMenu
                                    fillDataInForm={fillDataInFormDeal}
                                    rowId={dealRow.deal_id}
                                    isDeleteOptionEnabled={true}
                                    deleteModalData={['Deal', dealRow.deal_name]}
                                    handleDeleteRow={() => deleteDealRow(dealRow.deal_id)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={dealState?.accountSpecificDeals?.meta?.total || 0}
                    rowsPerPage={dealRowsPerPage || 0}
                    page={dealCurrentPage}
                    onPageChange={handleDealPage}
                    onRowsPerPageChange={handleDealRowsPerPage}
                  />
                </>
              )}
            </Paper>
          )}
          {tabIndex === 2 && (
            <Paper variant="outlined" sx={{ p: 2, border: "none" }}>
              <Grid container mb={2}>
                <Grid item xs={12} md={6}>
                  <Typography fontWeight={"bold"}>Contacts</Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign={"end"}>
                  <Box className="search-container">
                    <DebounceInput
                      type="text"
                      placeholder="Search"
                      className="search-input"
                      value={contactSearched}
                      onChange={(e) => handleContactSearch(e)}
                      debounceTimeout={searchOptionDebounceTime}
                    />
                  </Box>
                </Grid>
              </Grid>
              {contactState?.accountSpecificContactsLoading ? (
                <LoadingSpinner />
              ) : contactState?.accountSpecificContacts?.rows?.length === 0 ? (
                "No data found..."
              ) : (
                <>
                  <TableContainer>
                    <Table size="medium">
                      <TableHead className="stat-card-bg">
                        <TableRow>
                          {contactColumnsLocal.map((col) => getTableCell(col, contactOrderBy, contactOrder, handleContactSortRequest))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {contactState?.accountSpecificContacts?.rows &&
                          contactState?.accountSpecificContacts?.rows?.map((contactRow) => {
                            return (
                              <TableRow key={contactRow.id}>
                                <Tooltip title={`${contactRow.first_name} ${contactRow.last_name}`}>
                                  <TableCell
                                    sx={{ maxWidth: "250px" }}
                                    className="text-truncate primary-text link-hover"
                                    onClick={() => fillDataInFormContact(contactRow.id)}
                                  >
                                    {contactRow.first_name} {contactRow.last_name}
                                  </TableCell>
                                </Tooltip>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {contactRow.mobile}
                                </TableCell>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {contactRow.role}
                                </TableCell>

                                <TableCell align="right">
                                  <TableMenu
                                    fillDataInForm={fillDataInFormContact}
                                    rowId={contactRow.id}
                                    isDeleteOptionEnabled={true}
                                    deleteModalData={['Contact', contactRow.contact_name]}
                                    handleDeleteRow={() => deleteContactRow(contactRow.id)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={contactState?.accountSpecificContacts?.meta?.total || 0}
                    rowsPerPage={contactRowsPerPage || 0}
                    page={contactCurrentPage}
                    onPageChange={handleContactPage}
                    onRowsPerPageChange={handleContactRowsPerPage}
                  />
                </>
              )}
            </Paper>
          )}
          {tabIndex === 3 && (
            <Paper variant="outlined" sx={{ p: 2, border: "none" }}>
              <Grid container mb={2}>
                <Grid item xs={12} md={6}>
                  <Typography fontWeight={"bold"}>Tasks</Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign={"end"}>
                  <Box className="search-container">
                    <DebounceInput
                      type="text"
                      placeholder="Search"
                      className="search-input"
                      value={taskSearched}
                      onChange={(e) => handleTaskSearch(e)}
                      debounceTimeout={searchOptionDebounceTime}
                    />
                  </Box>
                </Grid>
              </Grid>
              {taskState?.accountSpecificTasksLoading ? (
                <LoadingSpinner />
              ) : taskState?.accountSpecificTasks?.rows?.length === 0 ? (
                "No data found..."
              ) : (
                <>
                  <TableContainer>
                    <Table size="medium">
                      <TableHead className="stat-card-bg">
                        <TableRow>
                          {taskColumnsLocal.map((col) => getTableCell(col, taskOrderBy, taskOrder, handleTaskSortRequest))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {taskState?.accountSpecificTasks?.rows &&
                          taskState?.accountSpecificTasks?.rows?.map((taskRow) => {
                            return (
                              <TableRow key={taskRow.id}>
                                <Tooltip title={taskRow.task_title}>
                                  <TableCell
                                    sx={{ maxWidth: "250px" }}
                                    className="text-truncate primary-text link-hover"
                                    onClick={() => fillDataInFormTask(taskRow.id)}
                                  >
                                    {taskRow.task_title}
                                  </TableCell>
                                </Tooltip>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {taskRow.task_type}
                                </TableCell>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {taskRow.status}
                                </TableCell>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {taskRow.assigned_to_name}
                                </TableCell>

                                <TableCell align="right">
                                  <TableMenu
                                    fillDataInForm={fillDataInFormTask}
                                    rowId={taskRow.id}
                                    isDeleteOptionEnabled={true}
                                    deleteModalData={['Task', taskRow.task_title]}
                                    handleDeleteRow={() => deleteTaskRow(taskRow.id)}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={taskState?.accountSpecificTasks?.meta?.total || 0}
                    rowsPerPage={taskRowsPerPage || 0}
                    page={taskCurrentPage}
                    onPageChange={handleTaskPage}
                    onRowsPerPageChange={handleTaskRowsPerPage}
                  />
                </>
              )}
            </Paper>
          )}
          {tabIndex === 4 && (
            <Paper variant="outlined" sx={{ p: 2, border: "none" }}>
              <Grid container mb={2}>
                <Grid item xs={12} md={6}>
                  <Typography fontWeight={"bold"}>Locations</Typography>
                </Grid>
                <Grid item xs={12} md={6} textAlign={"end"}>
                  <Box className="search-container">
                    <DebounceInput
                      type="text"
                      placeholder="Search"
                      className="search-input"
                      value={locationSearched}
                      onChange={(e) => handleLocationSearch(e)}
                      debounceTimeout={searchOptionDebounceTime}
                    />
                  </Box>
                </Grid>
              </Grid>
              {locationState?.locationsLoading ? (
                <LoadingSpinner />
              ) : locationState?.locations?.rows?.length === 0 ? (
                "No data found..."
              ) : (
                <>
                  <TableContainer>
                    <Table size="medium">
                      <TableHead className="stat-card-bg">
                        <TableRow>
                          {accountLocationColumns.map((col) => getTableCell(col, locationOrderBy, locationOrder, handleLocationSortRequest))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {locationState?.locations?.rows &&
                          locationState?.locations?.rows?.map((locationRow) => {
                            return (
                              <TableRow key={locationRow.id}>
                                <Tooltip title={locationRow.task_title}>
                                  <TableCell
                                    sx={{ maxWidth: "250px" }}
                                    className="text-truncate primary-text link-hover"
                                    onClick={() => fillDataInFormLocation(locationRow.id)}
                                  >
                                    {locationRow.location_name}
                                  </TableCell>
                                </Tooltip>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {locationRow.location_code}
                                </TableCell>
                                <TableCell
                                  className="text-truncate"
                                  sx={{ maxWidth: "150px" }}
                                >
                                  {locationRow.address}
                                </TableCell>

                                <TableCell align="right">
                                  <TableMenu
                                    fillDataInForm={fillDataInFormLocation}
                                    rowId={locationRow.id}
                                  />
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={locationState?.locations?.meta?.total || 0}
                    rowsPerPage={locationRowsPerPage || 0}
                    page={locationCurrentPage}
                    onPageChange={handleLocationPage}
                    onRowsPerPageChange={handleLocationRowsPerPage}
                  />
                </>
              )}
            </Paper>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default BdAccountSpecific;
