import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    setLocationAdded,
    setLocationAddedForAccountId,
    setLocationEdit,
    setLocationEdited,
    setLocationId,
} from "../../../features/BD/locationFormSlice";
import { accountLocationApi } from "../../../constants/apis/BD/AccountLocation";
import { locationSchema } from "../../../validations/BdForms/AccountLocation";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { autoCloseToastError } from "../../../constants/Common";
import { titleCase } from "../../../utils/Common";
import { Box, Button, CircularProgress, Divider, Drawer, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Asterisk from "../../Misc/Asterisk";
import { addLocation, editLocationById } from "../../../redux/actions/bd-actions/location.action";

export default function LocationForm({ isOpenProp, handleClose, editLocationProp }) {
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

    const profileData = useSelector((state) => state.userProfile.profileData);
    const locationState = useSelector((state) => state.locations);

    const [fetchingLocationData, setFetchingLocationData] = useState(false);
    const axiosInstance = createInstance(true);
    const dispatch = useDispatch();

    const [locationFormErrors, setLocationFormErrors] = useState({});
    const [edit, setEdit] = useState(editLocationProp || false);
    const initialLocationData = {
        location_name: "",
        location_code: "",
        address: "",
    };

    const [locationData, setLocationData] = useState(initialLocationData);

    const locationAddAccountSpecific = useSelector((state) => state.locationForm.locationAddAccountSpecific);
    const locationAccountId = useSelector((state) => state?.accounts?.selectedAccount?.account_id || '');

    const handleLocationForm = (e) => {
        setLocationFormErrors({ ...locationFormErrors, [e.target.name]: "" });
        setLocationData({ ...locationData, [e.target.name]: e.target.value });
    };

    const handleLocationSubmit = async (e) => {
        e.preventDefault();
        const userId = profileData?.user_id;
        const payload = { ...locationData, created_by: userId, account_id: locationAccountId };
        payload.address = await titleCase(payload.address);
        payload.location_name = await titleCase(payload.location_name);
        const validation = locationSchema.validate(locationData, { abortEarly: false });
        if (validation.error) {
            setLocationFormErrors({});
            const validationErrors = validation.error.details.reduce((acc, error) => {
                const fieldName = error.path[0];
                acc[fieldName] = error.message;
                return acc;
            }, {});

            setLocationFormErrors(validationErrors);
        } else {
            setLocationFormErrors({});
            try {
                dispatch(addLocation(payload));
            } catch (err) {
                errorHandler(err, "Location addition failed");
            }
        }
    };

    const handleLocationEdit = async (e) => {
        e.preventDefault();
        const userId = profileData?.user_id;
        const payload = { ...locationData, updated_by: userId, account_id : locationAccountId };
        payload.address = await titleCase(payload.address);
        payload.location_name = await titleCase(payload.location_name);
        const validation = locationSchema.validate(locationData, { abortEarly: false });
        if (validation.error) {
            setLocationFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setLocationFormErrors(currentErrors);
        } else {
            try {
                dispatch(editLocationById(locationState?.selectedLocation?.id, payload));
            } catch (err) {
                errorHandler(err, "Failed to edit location");
            }
        }
    };

    useEffect(() => {
        setLocationFormErrors({});
        //fetch single location data
        const fetchLocationData = async () => {
            try {
                const fetchedLocationData = locationState?.selectedLocation;
                const editLocationData = {};
                for (let key in initialLocationData) {
                    editLocationData[key] = fetchedLocationData[key] || "";
                }
                setLocationData(editLocationData);
            } catch (err) {
                errorHandler(err, "Failed to fetch location data");
            }
        };

        if (editLocationProp) {
            //fetch location data
            setEdit(true);
            fetchLocationData();
            dispatch(setLocationEdit(false));
        } else {
            setLocationData({ ...initialLocationData });
        }

        if (locationAddAccountSpecific) {
            setLocationData({ ...initialLocationData, account_id: locationAccountId });
        }
    }, []);

    const [isOpen, setIsOpen] = useState(isOpenProp);
    const handleDrawerClose = _ => {
        handleClose();
        setIsOpen(false);
    }

    return (
        <Drawer
            PaperProps={{
                sx: {
                width: { sm: "70vw", md: '60vw', lg: "45vw"},
                px: { xs: 2, md: 4 },
                },
            }}
            anchor={"right"}
            open={isOpen}
            onClose={handleDrawerClose}
            >
            <Box
                my={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="h5">{edit ? "Edit " : "New "} Location</Typography>
                <IconButton aria-label="close" onClick={handleDrawerClose} color="primary" >
                <CloseIcon />
                </IconButton>
            </Box>

            <Divider className="horizontal-line" />
            {
            fetchingLocationData ?
                <LoadingSpinner /> :
                <Box my={!isSmallerScreen ? 3 : 2}>
                    {/* Content */}
                    <Grid container>

                        {/* Location Name */}
                        <Grid item xs={12} md={2} pr={2}>
                            <InputLabel className="fw-bold">
                                {"Name "}
                                <Asterisk />
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12} md={10} mb={2}>
                            <TextField
                                value={locationData.location_name}
                                name="location_name"
                                onChange={(e) => handleLocationForm(e)}
                                placeholder="Name"
                                size="small"
                                error={Boolean(locationFormErrors?.location_name)}
                                helperText={locationFormErrors?.location_name || ""}
                                fullWidth
                            />
                        </Grid>

                        {/* Double Column */}
                        <Grid container item md={12}>

                            {/* Code */}
                            <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold">
                                        {"Code"}
                                    </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={5} mb={2}>
                                <TextField
                                    type="text"
                                    name="location_code"
                                    placeholder="Code"
                                    value={locationData.location_code}
                                    onChange={handleLocationForm}
                                    error={Boolean(locationFormErrors?.location_code)}
                                    helperText={locationFormErrors?.location_code || ""}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>

                        </Grid>

                            {/* Code */}
                            <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold">
                                        {"Address"}
                                    </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={10} mb={2}>
                                <TextField
                                    type="text"
                                    name="address"
                                    placeholder="Address"
                                    value={locationData.address}
                                    onChange={handleLocationForm}
                                    error={Boolean(locationFormErrors?.address)}
                                    helperText={locationFormErrors?.address || ""}
                                    size="small"
                                    fullWidth
                                />
                            </Grid>
                    </Grid>
                </Box>
            }
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
                height="100%"
            >
                <Divider className="horizontal-line" sx={{ my: 1 }} />

                <Box
                my={3}
                display={"flex"}
                justifyContent={{xs: "space-between", sm: "flex-start"}}
                columnGap={5}
                >
                <Button
                    variant="contained"
                    type="button"
                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                    onClick={handleDrawerClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                    onClick={(e) => {
                        if (edit) handleLocationEdit(e);
                        else handleLocationSubmit(e);
                    }}
                    disabled={locationState?.editLocationLoading || locationState?.editLocationLoading}
                    endIcon={(locationState?.editLocationLoading || locationState?.editLocationLoading) && <CircularProgress size={25}/>}
                >
                    Save
                </Button>
                </Box>
            </Box>
        </Drawer>
    );
}