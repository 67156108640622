const messages ={
    consumableNameDefault : "Consumable name cannot be empty",
    consumableNameMax: "Maximum 255 characters are allowed",

    category: "Category cannot be empty",
    categoryValid: "Please select proper category",
    
    vendorId: "Please select vendor",
    vendorValid : "Please select the proper vendor name from the dropdown",

    dopDefault: "Invalid date of purchase",

    dop: "Please enter date of purchase",

    wsdDefault: "Invalid warranty start date",

    wedDefault: "Invalid warranty end date", 

    purchasedQuantityDefault: "Please enter the purchased quantity of consumable", 

    availableQuantityDefault: "Please enter the available quantity of consumable", 

    makeDefault: "Please enter make of consumable",

    modelDefault: "Please enter model of the consumable", 

    descriptionDefault: "Please enter the description",

}

export { messages };
