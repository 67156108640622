import { messages } from "../../constants/validationMessages/BdForms/AddTask";
import { priorities, statuses, types } from "../../enums/Task";
const Joi = require("joi");

const taskSchema = Joi.object().keys({
    account_id: Joi.number().integer().min(1).required().messages({
        "number.min": messages.accountId,
        "number.base": messages.accountId,
        "any.only": messages.accountId,
    }),

    assigned_to: Joi.number().integer().min(1).required().messages({
        "number.min": messages.assignedTo,
        "number.base": messages.assignedTo,
        "any.only": messages.assignedTo,
    }),

    task_title: Joi.string().min(3).max(255).required().messages({
        "string.max": messages.titleMax,
        "string.min": messages.titleMin,
        "string.empty": messages.titleDefault,
    }),

    task_type: Joi.string()
        .valid(...Object.values(types))
        .required()
        .messages({
            "any.only": messages.typeDefault,
            "string.empty": messages.typeDefault,
        }),

    priority: Joi.string()
        .valid(...Object.values(priorities))
        .required()
        .messages({
            "any.only": messages.priorityDefault,
            "string.empty": messages.priorityDefault,
        }),

    status: Joi.string()
        .valid(...Object.values(statuses))
        .required()
        .messages({
            "any.only": messages.statusDefault,
            "string.empty": messages.statusDefault,
        }),

    description: Joi.string().allow("").messages({
        "any.required": messages.descriptionDefault,
    }),
});

export { taskSchema };
