import { ticketActions } from "../../reducers/ts-reducers/ticket.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { ticketApi } from "../../../constants/apis/TS/Tickets";
import { toast } from "react-toastify";

const axiosInstance = createInstance();

/**
 * getAllTickets - Fetch all tickets.
 * 
 * Retrieves all tickets based on the specified parameters.
 * 
 * @param {object} params - Parameters to filter tickets.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const getAllTickets = (params) => async (dispatch) => {
  try {
    dispatch(ticketActions.getTicketsRequest());

    const { data } = await axiosInstance.get(`${ticketApi}?${params}`);

    if (data.success) {
      dispatch(ticketActions.getTicketsSuccess(data.data));
    }

  } catch (error) {
    errorHandler(error, "Failed to get tasks");
    dispatch(ticketActions.getTicketsFail(error.response.data.message));
  }
};

/**
 * editTicketById - Edit a ticket by ID.
 * 
 * Edits a ticket with the specified ID using the provided payload.
 * 
 * @param {string} id - The ID of the ticket to be edited.
 * @param {object} payload - The data to update the ticket with.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const editTicketById = (id, payload) => async (dispatch) => {
  try {
    dispatch(ticketActions.editTicketRequest());

    const { data } = await axiosInstance.put(`${ticketApi}/${id}`, payload);

    if (data.success) {
      dispatch(ticketActions.editTicketSuccess(data.data));
      toast.success("Ticket updated successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to edit ticket");
    dispatch(ticketActions.editTicketFail(error.response.data.message));
  }
};

/**
 * addTicket - Add a new ticket.
 * 
 * Adds a new ticket using the provided payload.
 * 
 * @param {object} payload - The data for the new ticket.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const addTicket = (payload) => async (dispatch) => {
  try {
    dispatch(ticketActions.addTicketRequest());

    const { data } = await axiosInstance.post(`${ticketApi}`, payload);

    if (data.success) {
      dispatch(ticketActions.addTicketSuccess(data.data));
      toast.success("Ticket added successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to add ticket");
    dispatch(ticketActions.editTicketFail(error.response.data.message));
  }
};

/**
 * deleteTicketById - Delete a ticket by ID.
 * 
 * Deletes a ticket with the specified ID.
 * 
 * @param {string} id - The ID of the ticket to be deleted.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const deleteTicketById = (id) => async (dispatch) => {
  try {
    dispatch(ticketActions.deleteTicketRequest());

    const { data } = await axiosInstance.delete(ticketApi.concat(id));

    if (data.success) {
      dispatch(ticketActions.deleteTicketSuccess(id));
      toast.success("Ticket deleted successfully");
      dispatch(getAllTickets());
    }

  } catch (error) {
    errorHandler(error, "Failed to add ticket");
    dispatch(ticketActions.deleteTicketFail(error.response.data.message));
  }
};
