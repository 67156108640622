import * as yup from "yup";
import { INDIAN_MOBILE_REGEX } from "../../constants/validation.constant";

const loginValidationSchema = yup.object({
    mobile_number: yup
      .string()
      .required("Mobile number is required")
      .matches(INDIAN_MOBILE_REGEX, "Mobile number is not valid"),
    password: yup.string("Enter password").required("Password is required"),
  });

export default loginValidationSchema;