import { messages } from "../../constants/validationMessages/AMForms/AddConsumableAssignment";

const Joi = require("joi");

const consumableAssignmentSchema = Joi.object().keys({
    
    consumable_name: Joi.string()
    .required()
    .empty()
    .messages({
            "string.empty":messages.consumableNameDefault,
            "any.only": messages.consumableNameDefault
    }),

    make: Joi.string()
    .empty()
    .required()
    .messages({
        "string.empty":messages.make,
        "any.only": messages.makeValid
    }),

    model: Joi.string()
    .empty()
    .required()
    .messages({
        "string.empty":messages.model,
        "any.only": messages.modelValid
    }),

    date_of_purchase: 
        Joi.any()
        .custom((value,helpers)=>{
            if(!value){
           return helpers.message("please select date of purchase");}
           return value;
        }
    ).messages({
        "any.custom":messages.dateOfPurchase,
    }),

assigned_quantity: Joi.number()
.integer()
.min(1)
.required()
.messages({
    "number.min": messages.assignTotal,
    "number.base": messages.assignTotal
}),

    

assigned_on: 
        Joi.any()
        .custom((value,helpers)=>{
            if(value===null || value===""){
           return helpers.message("please select assigned on date");}
           return value;
        }
    ).messages({
        "any.custom":messages.assignedOn,
    }),

    user_id: Joi.number()
    .integer()
    .min(1)
    .required()
    .messages({
      "number.min": messages.assignedTo,
      "number.base": messages.assignedTo,
      "any.only": messages.assignedTo,
    }),

})

export {consumableAssignmentSchema}