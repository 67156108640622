import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { autoCloseToastError } from "../../constants/Common";
import { toast } from "react-toastify";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import { userApi } from "../../constants/apis/User";
import { passwordChangeSchema } from "../../validations/PasswordChange";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const ChangePasswordForm = ({ isChangePasswordOpen, handleDrawerClose }) => {
  const passwordChangeFormToggle = useSelector(
    (state) => state.passwordChangeForm.passwordChangeFormToggle
  );

  const initialPasswordChangeData = {
    current_password: "",
    new_password: "",
    confirm_password: "",
  };

  const [passwordChangeData, setPasswordChangeData] = useState(
    initialPasswordChangeData
  );
  const [passwordChangeErrors, setPasswordChangeErrors] = useState({});

  const closeChangePasswordForm = () => {
    //closes form
    const closeChangePasswordFormButton = document.getElementById(
      "closeChangePasswordForm"
    );
    closeChangePasswordFormButton.click();
  };

  const logout = () => {
    //closes form
    const logoutButton = document.getElementById("logoutButton");
    logoutButton.click();
  };
  const handlePasswordChangeFields = (e) => {
    setPasswordChangeErrors({ ...passwordChangeErrors, [e.target.name]: "" });
    setPasswordChangeData({
      ...passwordChangeData,
      [e.target.name]: e.target.value,
    });
  };

  const changePassword = async (e) => {
    e.preventDefault();
    const userId = localStorage.getItem("user_id");
    const validation = passwordChangeSchema.validate(passwordChangeData, {
      abortEarly: false,
    });
    if (validation.error) {
      setPasswordChangeErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setPasswordChangeErrors(currentErrors);
    } else {
      try {
        if (
          passwordChangeData.new_password !==
          passwordChangeData.confirm_password
        ) {
          setPasswordChangeErrors({
            confirm_password: "Password does not match!",
          });
        } else {
          const axiosInstance = createInstance(true);
          let response = await axiosInstance.post(
            userApi + `/change-password/${userId}`,
            passwordChangeData
          );
          if (response.data.success) {
            toast.success("Password changed successfully! Please Login again.");
            logout();
            setPasswordChangeData(passwordChangeData);
            setPasswordChangeErrors({});
          } else {
            toast.error("Failed to change password", {
              autoClose: autoCloseToastError,
            });
          }
          closeChangePasswordForm();
        }
      } catch (err) {
        if (err.response?.status === 409) {
          setPasswordChangeErrors({
            current_password: "Current password is incorrect",
          });
          errorHandler(err, "Current password is incorrect");
        } else {
          errorHandler(err, "Failed to change password");
          closeChangePasswordForm();
        }
      }
    }
  };

  useEffect(() => {
    setPasswordChangeData(initialPasswordChangeData);
    setPasswordChangeErrors({});
  }, [passwordChangeFormToggle]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: { sm: "70vw", md: "50vw" },
            p: 2,
          },
        }}
        anchor={"right"}
        open={isChangePasswordOpen}
        onClose={handleDrawerClose}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Typography variant="h5">Change Password</Typography>
          <IconButton
            aria-label="close"
            onClick={handleDrawerClose}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider className="horizontal-line" sx={{ my: 1 }} />
        <Grid container rowSpacing={2} columnSpacing={2}>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={"textSecondary"}
            >
              Current Password{" "}
              <span className="required-field-asterisk"> *</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl className="form-control-for-field" fullWidth>
              <TextField
                inputProps={{ maxLength: 15 }}
                type="password"
                name="current_password"
                value={passwordChangeData.current_password}
                onChange={(e) => handlePasswordChangeFields(e)}
                placeholder="Current Password"
                className="input-value"
                size="small"
                error={Boolean(passwordChangeErrors?.current_password)}
              />
              <Typography color={"red"} variant="caption">
                {passwordChangeErrors?.current_password || ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={"textSecondary"}
            >
              New Password <span className="required-field-asterisk"> *</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl className="form-control-for-field" fullWidth>
              <TextField
                inputProps={{ maxLength: 15 }}
                type="password"
                name="new_password"
                value={passwordChangeData.new_password}
                onChange={(e) => handlePasswordChangeFields(e)}
                placeholder="New Password"
                className="input-value"
                size="small"
                error={Boolean(passwordChangeErrors?.new_password)}
              />
              <Typography color={"red"} variant="caption">
                {passwordChangeErrors?.new_password || ""}
              </Typography>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Typography
              variant="body1"
              fontWeight={"bold"}
              color={"textSecondary"}
            >
              Confirm Password{" "}
              <span className="required-field-asterisk"> *</span>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={8}>
            <FormControl className="form-control-for-field" fullWidth>
              <TextField
                inputProps={{ maxLength: 15 }}
                type="password"
                name="confirm_password"
                value={passwordChangeData.confirm_password}
                onChange={(e) => handlePasswordChangeFields(e)}
                placeholder="Confirm Password"
                className="input-value"
                size="small"
                error={Boolean(passwordChangeErrors?.confirm_password)}
              />
              <Typography color={"red"} variant="caption">
                {passwordChangeErrors?.confirm_password || ""}
              </Typography>
            </FormControl>
          </Grid>
        </Grid>
        <Divider className="horizontal-line" sx={{ my: 1 }} />
        <Box>
          <Button
            variant="contained"
            className="primary-btn"
            onClick={(e) => {
              changePassword(e);
            }}
            sx={{ mr: 2 }}
          >
            Save
          </Button>
          <Button
            variant="contained"
            className="primary-btn"
            color="error"
            onClick={handleDrawerClose}
          >
            Cancel
          </Button>
        </Box>
      </Drawer>
    </>
  );
};

export default ChangePasswordForm;
