const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    consumableIdForDetails : null, 
    consumableDetailsOpen : false
}

export const formSlice = createSlice({
    name: "consumableDetails",
    initialState,
    reducers: {
        setConsumableIdForDetails: (state, action) => {
            state.consumableIdForDetails = action.payload;
        },

        setConsumableDetailsOpen: (state, action) => {
            state.consumableDetailsOpen = action.payload;
        }

    }
})

export const { setConsumableIdForDetails, setConsumableDetailsOpen } = formSlice.actions;

export default formSlice.reducer;