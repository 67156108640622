const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    userOptionsLoaded: false,
    userOptions: null,
    accountOptionsLoaded: false,
    accountOptions: null,
    customerAccnOptionsLoaded: false,
    customerAccnOptions: null,
    moduleOptionsLoaded: false,
    moduleOptions: null,
    accountOptionsLoadedForImport: false,
    accountOptionsForImport: null,
    vendorOptions:null,
    vendorOptionsLoaded:false,
    assetNameOptions: null,
    assetNameOptionsLoaded: null,
    consumableNameOptions: null,
    consumableOptionsLoaded: null,
    userOptionsLoadedForVendor: false,
    userOptionsForVendor: null,
    locationOptionsLoaded: false,
    locationOptions: null,
};

export const formSlice = createSlice({
    name: "optionsForAsyncSelect",
    initialState,
    reducers: {
        setUserOptionsLoaded: (state, action) => {
            state.userOptionsLoaded = action.payload;
        },

        setUserOptions: (state, action) => {
            state.userOptions = action.payload;
        },

        setAccountOptionsLoaded: (state, action) => {
            state.accountOptionsLoaded = action.payload;
        },

        setCustomerAccnOptionsLoaded: (state, action) => {
            state.customerAccnOptionsLoaded = action.payload;
        },

        setCustomerAccnOptions: (state, action) => {
            state.customerAccnOptions = action.payload;
        },

        setAccountOptions: (state, action) => {
            state.accountOptions = action.payload;
        },

        setModuleOptionsLoaded: (state, action) => {
            state.moduleOptionsLoaded = action.payload;
        },

        setModuleOptions: (state, action) => {
            state.moduleOptions = action.payload;
        },
        
        setAccountOptionsLoadedForImport: (state, action) => {
            state.accountOptionsLoadedForImport = action.payload;
        },

        setAccountOptionsForImport: (state, action) => {
            state.accountOptionsForImport = action.payload;
        },

        setVendorOptions: (state, action) => {
            state.vendorOptions = action.payload;
        },

        setVendorOptionsLoaded:(state,action)=>{
            state.vendorOptionsLoaded=action.payload;
        },

        setAssetNameOptions : (state,action) => {
            state.assetNameOptions = action.payload;
        },

        setAssetNameOptionsLoaded: (state, action) => {
            state.assetNameOptionsLoaded = action.payload;
        },

        setConsumableNameOptions : (state,action) => {
            state.consumableNameOptions = action.payload;
        },

        setConsumableNameOptionsLoaded: (state, action) => {
            state.consumableOptionsLoaded = action.payload;
        },

        setUserOptionsLoadedForVendor: (state, action) => {
            state.userOptionsLoadedForVendor = action.payload;
        },

        setUserOptionsForVendor: (state, action) => {
            state.userOptionsForVendor = action.payload;
        },
        setLocationOptionsLoaded: (state, action) => {
            state.locationOptionsLoaded = action.payload;
        },

        setLocationOptions: (state, action) => {
            state.locationOptions = action.payload;
        },
    },
});

export const { setUserOptionsLoaded, setUserOptions, setAccountOptionsLoaded, setAccountOptions, setCustomerAccnOptionsLoaded, setCustomerAccnOptions, setModuleOptionsLoaded, setModuleOptions, setAccountOptionsLoadedForImport, setAccountOptionsForImport, setVendorOptions, setVendorOptionsLoaded, setAssetNameOptions, setAssetNameOptionsLoaded, setConsumableNameOptions, setConsumableNameOptionsLoaded, setUserOptionsLoadedForVendor, setUserOptionsForVendor, setLocationOptionsLoaded, setLocationOptions } = formSlice.actions;

export default formSlice.reducer;
