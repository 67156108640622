const messages = {
    accountId: "You must select account",

    salutationDefault: "Select salutation",
    salutationValid: "Select proper value",

    firstNameDefault: "First name cannot be empty",
    firstNameMax: "Maximum 255 characters allowed",

    lastNameDefault: "Last name cannot be empty",
    lastNameMax: "Maximum 255 characters allowed",

    dobDefault: "Invalid DOB",

    mobileEmpty: "Mobile number is required",
    mobileDefault: "Mobile number must be of 10 digits",
    mobileRegex: "Only digits are allowed",

    emailDefault: "Email cannot be empty",
    emailInvalid: "Invalid email entered",

    roleDefault: "Role cannot be empty",
    roleValid: "Select proper role",

    addressDefault: "Enter address",

    jobTitleDefault: "Enter job title",
    jobTitleMax: "Max 100 chars allowed",

    departmentDefault: "Enter department",
    departmentMax: "Max 100 chars allowed",

    descriptionDefault: "Enter description",
};

export { messages };
