import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { NavLink } from "react-router-dom";
import { userRoles } from "../../enums/Auth";
import { useSelector } from "react-redux";

const AssetLinks = () => {
  const profileData = useSelector((state) => state.userProfile.profileData);
  return (
    <>
      <List sx={{ py: "0", px: 1 }}>
        {[userRoles.SuperAdmin, userRoles.Admin].includes(profileData?.role_id) && <>
          {/* Dashboard */}
          <NavLink
            to={"/am/dashboard"}
            className={({ isActive }) =>
              isActive || window.location.pathname === "/am/"
                ? "active-link"
                : "sideNavLink"
            }
          >
            <ListItem>
              <ListItemIcon>
                <img src="/assets/images/dashboard-icon.svg" height={"20px"} />
              </ListItemIcon>
              <ListItemText primary={"Dashboard"} />
            </ListItem>
          </NavLink>

          {/* Assets */}
          <NavLink
            to={"/am/assets"}
            className={({ isActive }) =>
              isActive ? "active-link" : "sideNavLink"
            }
          >
            <ListItem>
              <ListItemIcon>
                <img
                  src="/assets/images/asset_management_icon.svg"
                  height={"20px"}
                />
              </ListItemIcon>
              <ListItemText primary={"Assets"} />
            </ListItem>
          </NavLink>

          {/* Consumables */}
          <NavLink
            to={"/am/consumables"}
            className={({ isActive }) =>
              isActive ? "active-link" : "sideNavLink"
            }
          >
            <ListItem>
              <ListItemIcon>
                <img
                  src="/assets/images/asset-management-consumables.svg"
                  style={{ filter: "invert(1)" }}
                  height={"20px"}
                />
              </ListItemIcon>
              <ListItemText primary={"Consumables"} />
            </ListItem>
          </NavLink>

          {/* Assignments */}
          <NavLink
            to={"/am/assignments"}
            className={({ isActive }) =>
              isActive ? "active-link" : "sideNavLink"
            }
          >
            <ListItem>
              <ListItemIcon>
                <img
                  src="/assets/images/assignments.svg"
                  style={{ filter: "invert(1)" }}
                  height={"23px"}
                />
              </ListItemIcon>
              <ListItemText primary={"Assignments"} />
            </ListItem>
          </NavLink>

          {/* Reports */}
          <NavLink
            to={"/am/reports"}
            className={({ isActive }) =>
              isActive ? "active-link" : "sideNavLink"
            }
          >
            <ListItem>
              <ListItemIcon>
                <img src="/assets/images/results-icon.svg" height={"20px"} />
              </ListItemIcon>
              <ListItemText primary={"Reports"} />
            </ListItem>
          </NavLink>

        </>}

        {/* My Assets */}
        {[userRoles.User].includes(profileData?.role_id) &&
          <NavLink
            to={"/am/my-assets"}
            className={({ isActive }) =>
              isActive ? "active-link" : "sideNavLink"
            }
          >
            <ListItem>
              <ListItemIcon>
                <img
                  src="/assets/images/asset_management_icon.svg"
                  height={"20px"}
                />
              </ListItemIcon>
              <ListItemText primary={"My Assets"} />
            </ListItem>
          </NavLink>
        }

      </List>
    </>
  );
};

export default AssetLinks;
