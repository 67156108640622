const messages = {
    titleDefault: "Title cannot be empty",
    titleMax: "Maximum 255 characters allowed",
    assetTagDefault: "Asset tag cannot be empty",
    typeDefault: "Select proper Type",
    priorityDefault: "Select proper Priority",
    userDefault: "User is mandatory",
    statusDefault: "Select proper Status",
    problemChangeDefault: "Problem Change cannot be empty",
    descriptionDefault: "Description cannot be empty",
    createdByDefault: "Created by is not found",
    
    assetNameDefault: "Select proper Asset name",
    makeDefault: "Select proper make",
    modelDefault: "Select proper model",
    assignedTo: "Select proper user",
    assignToDefault: "Select proper 'Assign to'",
};

export { messages };
