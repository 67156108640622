import React, { useState, useEffect } from 'react';
import "../../styles/BD/entityForm.scss";
import { useDispatch, useSelector } from 'react-redux';
import { autoCloseToastError, minCharsToLoadOptions, asyncSelectNewCustomStyles } from '../../constants/Common';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { toast } from 'react-toastify';
import { createInstance, errorHandler } from '../../utils/Request/ReqUtils';
import { type, priority, status, assign_to } from '../../enums/TS/Ticket';
import { createTicketSchema, editTicketSchema } from '../../validations/TsForms/Ticket';
import { ticketApi } from '../../constants/apis/TS/Tickets';
import { assignedAssetsApi, assetTagApi } from '../../constants/apis/AM/Assets';
import AsyncSelect from 'react-select/async';
import debounce from 'lodash/debounce';
import { userOptionsApi } from '../../constants/apis/User';
import moment from 'moment';
import { userRoles } from '../../enums/Auth';
import { Avatar, Box, Button, CircularProgress, Divider, Drawer, Grid, IconButton, InputLabel, MenuItem, Select, TextField, Typography, useMediaQuery, useTheme } from '@mui/material';
import { setUserOptionsLoaded, setUserOptions } from "../../features/optionSlice";
import { userOption } from '../AsyncSelectOption/CustomizedOptions';
import Asterisk from '../Misc/Asterisk';
import CloseIcon from '@mui/icons-material/Close';
import { addTicket, editTicketById } from '../../redux/actions/ts-actions/ticket.action';

const TicketForm = ({ isOpenProp, handleClose, ticketEditProp }) => {
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

    const dispatch = useDispatch();
    const profileData = useSelector((state) => state.userProfile.profileData);
    const ticketsState = useSelector(state => state.tickets1);

    const initialTicketData = {
        title: "",
        asset_tag: "",
        type: "",
        priority: "",
        user: null,
        description: "",
        asset_name: "",
        make: "",
        model:"",
    }

    const editTicketData = (ticketsState.tickets.rows.filter(ticket => ticket.ticket_id === ticketsState.selectedTicketId))?.[0];
    const curatedTicketData = {
        ticket_id: editTicketData?.ticket_id,
        title: editTicketData?.title,
        type: editTicketData?.type,
        priority: editTicketData?.priority,
        status: editTicketData?.support_status,
        description: editTicketData?.description,
        asset_tag: editTicketData?.asset_tag,
        user: editTicketData?.user_id,
        asset_name: editTicketData?.asset_name,
        make: editTicketData?.make,
        model: editTicketData?.model,
        assign_to: [userRoles.SuperAdmin, userRoles.Admin, userRoles.User].includes(editTicketData?.assigned_to_role) ? 'User' : 'Vendor',
    }

    const [ticketData, setTicketData] = useState(ticketEditProp ? curatedTicketData : initialTicketData);
    const [ticketFormErrors, setTicketFormErrors] = useState({});
    const [isOpen, setIsOpen] = useState(isOpenProp);
    const [displayAddCommentButton, setDisplayAddCommentButton] = useState(false);
    const [ticketUser, setTicketUser] = useState(ticketEditProp ? {value: editTicketData.user_id ,label: `${editTicketData.user_name} (${editTicketData.user_id})`} : null);
    const [assetFormData, setAssetFormData] = useState({
        asset_name: '',
        make: '',
        model: ''
    });
    const [assetNames, setAssetNames] = useState([]);
    const [assetMakes, setAssetMakes] = useState([]);
    const [assetModels, setAssetModels] = useState([]);
    const searchOptionDebounceTime = 300;

    const [fetchingTicketData, setFetchingTicketData] = useState(false);

    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const [canEdit, setCanEdit] = useState(true);
    const [edit, setEdit] = useState(ticketEditProp || false);

    const userOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.userOptionsLoaded);
    const userOptions = useSelector((state) => state.optionsForAsyncSelect.userOptions);
    
    const fetchUserOptions = async (inputValue) => {
        try {
            const axiosInstance = createInstance(true);
            const response = await axiosInstance.get(userOptionsApi + "?search=" + inputValue);
            if (response.data && response.data.success) {
                const formattedOptions = response.data.data.map((item) => {
                    let additionalData = { location: item.location, mobile: item.mobile };

                    return {
                        value: item.user_id,
                        label: item.assign_to,
                        additionalData: additionalData, // Include additional data in the result
                    };
                });

                return formattedOptions;
            } else {
                toast.error("Error while fetching user options", { autoClose: autoCloseToastError });
            }
        } catch (err) {
            errorHandler(err, "Error while fetching user options");
        }
        return [];
    };
    const loadUserOptions = (inputValue, callback) => {
        if (inputValue.length < minCharsToLoadOptions) {
            return callback([]);
        }
        fetchUserOptions(inputValue)
            .then((options) => callback(options))
            .catch((error) => {
                console.error(error);
                callback([]);
            });
    };

    const handleUserFocus = async () => {
        if (!userOptionsLoaded) {
            const fetchedOptions = await fetchUserOptions("");
            dispatch(setUserOptionsLoaded(true));
            dispatch(setUserOptions(fetchedOptions));
        }
    };

    const handleTicketForm = (e) => {
        const name = e.target.name;
        if(name === "asset_name") {
            setAssetFormData((prev) => ({
                [name]: e.target.value,
                make: '',
            }))
            setAssetMakes([]);
            setAssetModels([]);
            setTicketData({ ...ticketData, asset_name: e.target.value, make: '', model: '' });
        } else if(name === "make") {
            setAssetFormData((prev) => ({
                ...prev, 
                make: e.target.value,
            }))
            setAssetModels([]);
            setTicketData({ ...ticketData, make: e.target.value, model: '' });
        } else if(name === "model") {
            setAssetFormData((prev) => ({
                ...prev, 
                model: e.target.value,
            }))
            setTicketData({ ...ticketData, model: e.target.value});
        } else if(name === "asset_tag") {
            setAssetMakes([]);
            setAssetModels([]);
            setTicketData({ ...ticketData, asset_tag: e.target.value, asset_name: '', make: '', model: '' });
        } else {
            setTicketData({ ...ticketData, [e.target.name]: e.target.value });
        }
        setTicketFormErrors({ ...ticketFormErrors, [e.target.name]: "" });
    }

    const fetchAssetTag = async ({asset_name, make, model}) => {
        try {
            let userId = ticketUser ? (ticketUser.value || "") : "";
            if ((asset_name && asset_name.length!==0) && (make && make.length!==0) && (model && model.length!==0)) {
                const axiosInstance = createInstance(true);
                const result = await axiosInstance.get(assetTagApi + "/" + `?asset_name=${assetFormData.asset_name}&make=${assetFormData.make}&model=${assetFormData.model}&user=${userId}`);
                if (result.data.success) {
                    const fetchedData = result.data.data;
                    if(fetchedData.found) {
                        setTicketData((prev) => (
                            {
                                ...prev,
                                asset_tag: fetchedData.asset_tag
                            }
                        ));
                    } else {
                        toast.error("Failed to fetch asset tag", { autoClose: autoCloseToastError });    
                    }
                } else {
                    toast.error("Failed to fetch asset tag", { autoClose: autoCloseToastError });
                }
            }
        } catch (err) {
            errorHandler(err, "Failed to fetch asset_tag");
        }
    }
    const fetchAssetData = async (assetFormData) => {
        try {
            let userId = ticketUser ? (ticketUser.value || "") : "";
            if (!assetFormData) {
                const axiosInstance = createInstance(true);
                const result = await axiosInstance.get(assignedAssetsApi + "/" + `?user=${userId}`);
                if (result.data.success) {
                    const fetchedAssignedAssets = result.data.data;
                    setAssetNames([...new Set(fetchedAssignedAssets.map(obj => {
                        return obj.asset_name
                    }))]);
                }
            } else {
                const axiosInstance = createInstance(true);
                const result = await axiosInstance.get(assignedAssetsApi + "/" + `?asset_name=${assetFormData.asset_name.toLowerCase()}&make=${assetFormData.make.toLowerCase()}&user=${userId}`);
                if (result.data.success) {

                    const fetchedAssignedAssets = result.data.data;
                    setAssetMakes(fetchedAssignedAssets.map(obj => {
                        return obj.make
                    }));
                    setAssetModels(fetchedAssignedAssets.map(obj => {
                        return obj.model
                    }))
                } else {
                    toast.error("Failed to fetch ticket data for edit", { autoClose: autoCloseToastError });
                }
            }
        } catch (err) {
            errorHandler(err, "Failed to fetch ticket data for edit");
        }
            setFetchingTicketData(false);
    }   

    const handleTicketSubmit = async (e) => {
        e.preventDefault();
        const {status, ...payload} = ticketData;
        if (payload.user === null) {
            delete payload.user;
        }
        const validation = createTicketSchema.validate(payload, { abortEarly: false });
        if (validation.error) {
            setTicketFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setTicketFormErrors(currentErrors);
        } else {
            try {
                let payloadTicketData = JSON.parse(JSON.stringify(payload));
                dispatch(addTicket(payloadTicketData));

                setTicketData(initialTicketData);
                setTicketUser(null);

                setTicketFormErrors({});
                handleDrawerClose();

            } catch (err) {
                if (err.response?.status === 404) {
                    errorHandler(err, "Vendor agent not found");
                }
                else errorHandler(err, "Failed to create ticket");
            }
        }
    }

    const handleTicketEdit = async (e) => {
        e.preventDefault();
        const payload = { status: ticketData.status, assign_to:  ticketData.assign_to};
        const validation = editTicketSchema.validate(payload, { abortEarly: false });
        if (validation.error) {
            setTicketFormErrors({});
            const errorDetails = validation.error.details;
            let currentErrors = {};
            errorDetails.forEach((error) => {
                currentErrors[error.path[0]] = error.message;
            });
            setTicketFormErrors(currentErrors);
        } else {
            try {
                let payloadTicketData = JSON.parse(JSON.stringify(payload));
                dispatch(editTicketById(ticketsState.selectedTicketId, payloadTicketData));
            } catch (err) {
                errorHandler(err, "Failed to update ticket");
            }
        }
    }  

    const handleAddComment = async () => {
        let payload = {
            ticket_id: ticketData.ticket_id,
            data: comment
        }
        try {
            let payloadCommentData = JSON.parse(JSON.stringify(payload));
            const axiosInstance = createInstance(true);
            let commentAddResponse = await axiosInstance.post(ticketApi + '/comments', payloadCommentData);
            if (commentAddResponse.data.success && commentAddResponse.data.data.length !== 0) {
                toast.success("Comment added successfully");
                setComment("");

                fetchComments(ticketData.ticket_id);
            }

        } catch (err) {
            errorHandler(err, "Failed to create ticket");
        }
    }

    const fetchComments = async (ticketId) => {
        try {
            const axiosInstance = createInstance(true);
            const result = await axiosInstance.get(ticketApi + "comments" + `?ticketId=${ticketId}`);
            if (result.data.success) {

                const fetchedComments = result.data.data;
                setComments(fetchedComments)
            } else {
                toast.error("Failed to fetch comments", { autoClose: autoCloseToastError });
            }
        } catch (err) {
            errorHandler(err, "Failed to fetch comments");
        }
    }

    const handleDrawerClose = _ => {
        handleClose();
        setIsOpen(false);
    }

    const getNameInitial = (name) => {
        return name.substring(0, 1).toUpperCase();
    }

    const avatarColors = new Map();
    const getAvatarColor = (created_by, theme) => {
        if (!avatarColors.has(created_by)) {
            const color = avatarColors.size === 0 ? theme.palette.primary.main : theme.palette.warning.main;
            avatarColors.set(created_by, color);
        }
    
        return avatarColors.get(created_by);
    }

    useEffect(() => {
        if(ticketEditProp) {
            setCanEdit(true);
            setTicketFormErrors({});
            setEdit(true);
            fetchComments(ticketsState.selectedTicketId);
            setComment("");
        }

    }, []);

    useEffect(() => {
        if(!ticketEditProp) {
            if(assetFormData.asset_name === '') {
                setTicketData({ ...ticketData, asset_tag: '', asset_name: '', make:'', model:'' });
                return;
            }
            if(assetFormData.make === '') {
                setTicketData({ ...ticketData, asset_tag:'', make:'', model:'' });
            }
            fetchAssetData(assetFormData);
            fetchAssetTag(assetFormData);
        }
    }, [assetFormData])

    useEffect(() => {
        if (!ticketEditProp) {
            fetchAssetData();
        }
    }, [ticketUser])

    return (
        <Drawer
            PaperProps={{
                sx: {
                width: { sm: "70vw", md: "85vw", lg: "65vw", xl: "55vw" },
                px: { xs: 2, md: 4 },
                },
            }}
            anchor={"right"}
            open={isOpen}
            onClose={handleDrawerClose}
            >
            <Box
                my={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="h5">{edit ? "Update " : "New "} Ticket</Typography>
                <IconButton aria-label="close" onClick={handleDrawerClose} color="primary" >
                <CloseIcon />
                </IconButton>
            </Box>

            <Divider className="horizontal-line" />
            {fetchingTicketData ?
                <LoadingSpinner /> :
                <Box my={!isSmallerScreen ? 3 : 2}>
                    {/* Content */}
                    <Grid container>

                        { !edit && 
                        <>
                            {/* Title */}
                            <Grid item xs={12} md={2} pr={2}>
                                <InputLabel className="fw-bold">
                                {"Title "}
                                <Asterisk />
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <TextField
                                    name="title"
                                    size="small"
                                    value={ticketData.title}
                                    onChange={(e) => handleTicketForm(e)}
                                    placeholder="Title"
                                    error={Boolean(ticketFormErrors?.title)}
                                    sx={{ mb: 2 }}
                                    helperText={ticketFormErrors?.title || ""}
                                    fullWidth
                                />
                            </Grid>

                            {/* User */}
                            {userRoles.Admin === profileData?.role_id &&
                            <>
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold" htmlFor="description">
                                    {"User"}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={10} mb={2}>
                                    {/* TODO: Need to migrate to new async select */}
                                    <AsyncSelect
                                        className={`async-select-input ${Boolean(ticketFormErrors?.user) ? " async-select-error" : ""}`}
                                        styles={asyncSelectNewCustomStyles}
                                        onChange={(selectedOption) => {
                                            setTicketFormErrors({ ...ticketFormErrors, ["user"]: "" });
                                            setTicketUser(selectedOption);
                                            if (selectedOption) {
                                                setTicketData({ ...ticketData, user: selectedOption.value});
                                            } else {
                                                setTicketData({ ...ticketData, user: null });
                                            }
                                        }}
                                        value={ticketUser}
                                        defaultOptions={userOptions || []}
                                        loadOptions={debounce(loadUserOptions, searchOptionDebounceTime)}
                                        placeholder="Type to search..."
                                        name="user"
                                        noOptionsMessage={() => "No users found. Type again..."}
                                        isClearable
                                        onFocus={handleUserFocus}
                                        components={{Option: userOption}}
                                    />
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {ticketFormErrors?.user || ""}
                                    </Typography>
                                </Grid>
                            </>
                            }

                            {/* Double Column */}
                            <Grid container item md={12}>
                                
                                {/* Asset Name */}
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold">
                                    {"Asset Name "}
                                    <Asterisk />
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4} mb={2}>
                                    <Select
                                        value={ticketData.asset_name}
                                        name="asset_name"
                                        onChange={(e) => handleTicketForm(e)}
                                        size="small"
                                        sx={{ width: "100%" }}
                                        placeholder='Select'
                                    >
                                        <MenuItem key={'Select'} value="">
                                        {'Select'}
                                        </MenuItem>
                                        {Object.keys(assetNames).map((key) => (
                                            <MenuItem key={key} value={assetNames[key]}>
                                            {assetNames[key]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {ticketFormErrors?.asset_name || ""}
                                    </Typography>
                                </Grid>

                                {/* Make */}
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold" sx={{textAlign: !isSmallerScreen ? 'right' : 'inherit'}}>
                                    {"Make "}
                                    <Asterisk />
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4} mb={2}>
                                    <Select
                                        value={ticketData.make}
                                        name="make"
                                        onChange={(e) => handleTicketForm(e)}
                                        size="small"
                                        sx={{ width: "100%" }}
                                        disabled={ticketData.asset_name === "" || Boolean(ticketFormErrors?.asset_name)}
                                    >
                                    {Object.keys(assetMakes).map((key) => (
                                        <MenuItem key={key} value={assetMakes[key]}>
                                        {assetMakes[key]}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {ticketFormErrors?.make || ""}
                                    </Typography>
                                </Grid>

                                {/* Model */}
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold" >
                                    {"Model "}
                                    <Asterisk />
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4} mb={2}>
                                    <Select
                                        value={ticketData.model}
                                        name="model"
                                        onChange={(e) => handleTicketForm(e)}
                                        size="small"
                                        sx={{ width: "100%"}}
                                        disabled={ticketData.make === "" || Boolean(ticketFormErrors.make)}
                                    >
                                    {Object.keys(assetModels).map((key) => (
                                        <MenuItem key={key} value={assetModels[key]}>
                                        {assetModels[key]}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {ticketFormErrors?.model || ""}
                                    </Typography>
                                </Grid>

                                {/* Asset Tag */}
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold" sx={{textAlign: !isSmallerScreen ? 'right' : 'inherit'}}>
                                    {"Asset Tag"}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        name="asset_tag"
                                        size="small"
                                        value={ticketData.asset_tag}
                                        placeholder="Asset Tag"
                                        onChange={(e) => handleTicketForm(e)}
                                        helperText={ticketFormErrors?.asset_tag || ""}
                                        sx={{ mb: 2 }}
                                        fullWidth
                                        InputProps={{ readOnly: true }}
                                        disabled
                                    />
                                </Grid>

                                {/* Type */}
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold">
                                    {"Type "}
                                    <Asterisk />
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4} mb={2}>
                                    <Select
                                        value={ticketData?.type}
                                        name="type"
                                        onChange={(e) => handleTicketForm(e)}
                                        size="small"
                                        sx={{ width: "100%" }}
                                        >
                                        {Object.keys(type).map((key) => (
                                            <MenuItem key={key} value={type[key]}>
                                            {type[key]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {ticketFormErrors?.type || ""}
                                    </Typography>
                                </Grid>

                                {/* Priority */}
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold" sx={{textAlign: !isSmallerScreen ? 'right' : 'inherit'}}>
                                    {"Priority "}
                                    <Asterisk />
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4} mb={2}>
                                    <Select
                                        name="priority"
                                        value={ticketData.priority}
                                        onChange={(e) => handleTicketForm(e)}
                                        size="small"
                                        sx={{ width: "100%" }}
                                        >
                                        {Object.keys(priority).map((key) => (
                                            <MenuItem key={key} value={priority[key]}>
                                            {priority[key]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {ticketFormErrors?.priority || ""}
                                    </Typography>
                                </Grid>

                                {/* Need to confirm location's existence */}
                                {/* Location */}
                                {/* <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold">
                                    {"Location"}
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4}>
                                    <TextField
                                        name="title"
                                        size="small"
                                        value={addTicketForm.values.location}
                                        placeholder="Location"
                                        onChange={addTicketForm.handleChange}
                                        onBlur={addTicketForm.handleBlur}
                                        error={
                                        addTicketForm.touched.location && Boolean(addTicketForm.errors.location)
                                        }
                                        helperText={addTicketForm.touched.location && addTicketForm.errors.location}
                                        sx={{ mb: 2 }}
                                        fullWidth
                                    />
                                </Grid> */}

                            </Grid>
                            
                            {/* Description */}
                            <Grid item xs={12} md={2} pr={2}>
                                <InputLabel className="fw-bold">
                                {"Description "}
                                </InputLabel>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <TextField
                                    name="description"
                                    size="small"
                                    value={ticketData.description}
                                    onChange={(e) => handleTicketForm(e)}
                                    placeholder="Description"
                                    helperText={ticketFormErrors?.description || ""}
                                    sx={{ mb: 2 }}
                                    rows={3}
                                    multiline
                                    fullWidth
                                />
                            </Grid>
                        </>
                        }

                        { edit &&
                            <Grid container item md={12}>
                                
                                {/* Status */}
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold">
                                    {"Status "}
                                    <Asterisk />
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4} mb={2}>
                                    <Select
                                        name="status"
                                        value={ticketData.status}
                                        onChange={(e) => handleTicketForm(e)}
                                        size="small"
                                        sx={{ width: "100%" }}
                                        >
                                        {Object.keys(status).map((key) => (
                                            <MenuItem key={key} value={status[key]} disabled={(profileData?.role_id === userRoles.User && [status.in_progres, status.waiting_for_input].includes(status[key])) || (profileData?.role_id === userRoles.Vendor && [status.open].includes(status[key]))}>
                                            {status[key]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {ticketFormErrors?.status || ""}
                                    </Typography>
                                </Grid>

                                {/* Assign To */}
                                <Grid item xs={12} md={2} pr={2}>
                                    <InputLabel className="fw-bold" sx={{textAlign: !isSmallerScreen ? 'right' : 'inherit'}}>
                                    {"Assign To "}
                                    <Asterisk />
                                    </InputLabel>
                                </Grid>
                                <Grid item xs={12} md={4} mb={2}>
                                    <Select
                                        name="assign_to"
                                        value={ticketData.assign_to}
                                        onChange={(e) => handleTicketForm(e)}
                                        size="small"
                                        sx={{ width: "100%" }}
                                        >
                                        {Object.keys(assign_to).map((key) => (
                                            <MenuItem key={key} value={assign_to[key]}>
                                            {assign_to[key]}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <Typography variant="caption" color="error" pl={2} m={0}>
                                        {ticketFormErrors?.assign_to || ""}
                                    </Typography>
                                </Grid>

                            </Grid>
                        }
                    </Grid>
                </Box>
            }
            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
                height={!edit ? "100%" : "auto"}
            >
                <Divider className="horizontal-line" sx={{ my: 1 }} />

                <Box
                my={3}
                display={"flex"}
                columnGap={5}
                >
                <Button
                    variant="contained"
                    type="button"
                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                    onClick={handleDrawerClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                    onClick={(e) => {
                        if (edit) handleTicketEdit(e);
                        else handleTicketSubmit(e);
                    }}
                    disabled={!canEdit || ticketsState?.editTicketLoading || ticketsState?.addTicketLoading}
                    endIcon={(ticketsState?.editTicketLoading || ticketsState?.addTicketLoading) && <CircularProgress size={25}/>}
                >
                    {edit ? "Update" : "Create"}
                </Button>
                </Box>
            </Box>
            {
                edit &&
                <>
                    <Box mb={2}>
                        <Typography variant="h6">Comments</Typography>
                    </Box>
                    <Grid container mb={2}>
                        <Grid item xs={12} container justifyContent={'flex-end'}>
                            <TextField
                                name="comment"
                                size="small"
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                placeholder="Add a comment"
                                helperText={ticketFormErrors?.comment || ""}
                                sx={{ mb: 2 }}
                                fullWidth
                                rows={1}
                                multiline
                                onFocus={() => setDisplayAddCommentButton(true)}
                            />
                            {displayAddCommentButton &&
                                <Button
                                    variant="contained"
                                    type="submit"
                                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                                    onClick={handleAddComment}
                                    disabled={!canEdit || !comment}
                                >
                                    Add
                                </Button>
                            }
                        </Grid>

                        <Divider className="horizontal-line" sx={{ my: 1 }} />

                        {/* Comments */}
                        {
                            comments.map((_comment, i) => {
                                return (
                                    <>
                                        <Grid item xs={12} mb={1} container>
                                            {i!==0 && <Divider className="horizontal-line" sx={{ my: 1, width: '100%' }}/>}
                                            <Grid item xs={2} md={1} container alignItems={"center"}>
                                                <Avatar variant="circular" sx={{background: theme => getAvatarColor(_comment.created_by, theme)}} >
                                                    {getNameInitial(_comment.created_by)}
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={10} md={11}>
                                                <Grid item xs={12} container justifyContent={"space-between"}>
                                                    <Typography variant="h6">
                                                        {_comment.created_by}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center'}} align='right'>
                                                        { (moment(_comment.created_at).format("DD-MM-YYYY")) + ` at ${moment(_comment.created_at).format("HH:mm")}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">
                                                        {_comment.comment}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                    </Grid>
                </>
            }
            </Drawer>
    );
}

export default TicketForm;