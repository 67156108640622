import * as yup from "yup";
import { messages } from "../../constants/validationMessages/AMForms/AddConsumable";
import { category } from "../../enums/AM/Consumable";

const consumableSchema = yup.object().shape({
  consumable_name: yup
    .string()
    .min(1, messages.consumableNameDefault) // Use message key for min length
    .max(255, messages.consumableNameMax) // Use message key for max length
    .required(messages.consumableNameDefault), // Use message key for required

  category: yup
    .string()
    .oneOf(Object.values(category), messages.categoryValid) // Use oneOf with message key
    .required(messages.category), // Use message key for required

  vendor_id: yup
    .number()
    .integer() // Use custom message key for integer validation
    .min(1, messages.vendorId) // Use message key for min value
    .required(messages.vendorId), // Use message key for required

  date_of_purchase: yup
    .date()
    .when("enteredDate", (enteredDate, schema) => {
      if (enteredDate) {
        // This can be calculated in many ways. Just be sure that its type is `Date` object
        const presentDate = Date();

        return schema.max(presentDate);
      }

      return schema;
    }) // Use 'now' keyword for max date and message key
    .nullable(true) // Allow null value
    .required(messages.dop), // Use message key for required

  purchased_quantity: yup
    .number()
    .integer() // Use custom message key for integer validation
    .min(1, messages.purchasedQuantityDefault) // Use message key for min value
    .required(messages.purchasedQuantityDefault), // Use message key for required

  in_stock: yup
    .number()
    .integer() // Use custom message key for integer validation
    .min(1, messages.availableQuantityDefault) // Use message key for min value
    .required(messages.availableQuantityDefault), // Use message key for required

  make: yup
    .string()
    .min(1, messages.makeDefault) // Use message key for min length
    .max(255, messages.makeDefault) // Use message key for max length
    .required(messages.makeDefault), // Use message key for required

  model: yup.string().nullable(true), // Allow null value

  description: yup.string().nullable(true), // Allow null value
});

export { consumableSchema };
