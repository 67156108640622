const messages = {
    accountNameMin: "Minimum 3 characters are required",
    accountNameMax: "Maximum 255 characters are allowed",
    accountNameEmpty: "Account name is required",

    businessNameMax: "Maximum 255 characters are allowed",
    businessNameEmpty: "Business name is required",

    industryDefault: "Please select proper industry",

    categoryDefault: "Please select proper category",

    statusDefault: "Please select proper status",
    
    phoneDefault: "Please enter a valid phone number between 8 and 15 digits long",

    mobileEmpty: "Mobile number is required",
    mobileDefault: "Mobile number must be of 10 digits",
    mobileRegex: "Only digits are allowed",

    emailMin: "Minimum 3 characters are required",
    emailMax: "Maximum 255 characters are allowed",
    emailEmpty: "Email cannot be empty",
    emailInvalid: "Invalid email entered",

    gstinlength: "Must be exact 15 characters long",
    gstinEmpty: "GSTIN is required",

    panLength: "Must be exact 10 characters long",
    panEmpty: "PAN number is required",

    annualRevenueMax: "Must be at max 18 digits",
    annualRevenueMin: "Annual revenue should be greater than zero",
    annualRevenuePrecision: "Must be at max 2 digits after the decimal point",
    annualRevenueBase: "Annual revenue is required",

    noOfEmployeesDefault: "Please select proper number of employees",

    countryEmpty: "Please select country",
    countryMax: "Maximum 100 characters are allowed",

    stateEmpty: "Please select state",
    stateMax: "Maximum 100 characters are allowed",

    cityEmpty: "Please select city",
    cityMax: "Maximum 100 characters are allowed",

    pincodeMin: "Minimum 3 characters are required",
    pincodeMax: "Maximum 20 characters allowed",
    pincodeEmpty: "Pincode is required",

    addressMax: "Maximum 1024 characters are allowed",

    websiteMin: "Minimum 3 characters are required",
    websiteMax: "Maximum 255 characters are allowed",
    websiteEmpty: "Website is required",
    websiteRegex: "Website should start with www. or https:// or http://",

    descriptionEmpty: "Description is required",

    occupationDefault: "Please select proper occupation",

    dobDefault: "Invalid DOB",

    aadharLength: "Must be exact 12 characters long",
    aadharEmpty: "Aadhar is required",
};

export { messages };
