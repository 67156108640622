import React from "react";
import { useState } from "react";

import { Menu, MenuItem, IconButton, Typography } from "@mui/material";

import EditIcon from "@mui/icons-material/Edit";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteConfirmation from "../DeleteConfirmation/DeleteConfirmation";

function TableMenu({
  isEditOptionEnabled = true,
  isDeleteOptionEnabled = false,
  fillDataInForm,
  rowId,
  deleteModalData,
  handleDeleteRow,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = rowId;

  const handleCloseMenu = () => setAnchorEl(null);
  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const submitDelete = () => {
    handleDeleteRow(id);
    setDisplayConfirmationModal(false);
  };

  const msg = Boolean(deleteModalData)
    ? "Delete "
        .concat(deleteModalData[0])
        .concat(' "')
        .concat(deleteModalData[1])
        .concat('" ?')
    : "";

  return (
    <>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={() => setDisplayConfirmationModal(false)}
        message={msg}
      />
      <IconButton color="primary" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        {isEditOptionEnabled && (
          <MenuItem
            sx={{
              pr: 4,
              py: 1,
              textAlign: "start",
            }}
            onClick={() => {
              if (fillDataInForm) {
                fillDataInForm(id);
              }
              handleCloseMenu();
            }}
          >
            <EditIcon sx={{ mr: 1 }} />
            <Typography textAlign="center">Edit</Typography>
          </MenuItem>
        )}

        {isDeleteOptionEnabled && (
          <MenuItem
            onClick={() => {
              handleCloseMenu();
              showDeleteModal();
            }}
          >
            <DeleteIcon sx={{ mr: 1 }} />
            <Typography textAlign="center">Delete</Typography>
          </MenuItem>
        )}
      </Menu>
    </>
  );
}

export default TableMenu;
