import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import { setTargetAdded } from "../../../features/BD/targetFormSlice";
import { userOptionsApi } from "../../../constants/apis/User";
import { searchOptionDebounceTime, asyncSelectNewCustomStyles, minCharsToLoadOptions, autoCloseToastError } from "../../../constants/Common";
import { targetSchema, targetDetailsSchema } from "../../../validations/BdForms/Target";
import { toast } from "react-toastify";
import { setUserOptionsLoaded, setUserOptions } from "../../../features/optionSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { userRoles } from "../../../enums/Auth";
import { frequencies, months } from "../../../enums/Target";
import { targetApi } from "../../../constants/apis/BD/Target";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { userOption } from "../../AsyncSelectOption/CustomizedOptions";
import { Box, Button, Divider, Drawer, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import Asterisk from "../../Misc/Asterisk";
import { getAllTargets } from "../../../redux/actions/bd-actions/targets.actions";

export default function TargetForm({ isOpenProp, handleClose }) {
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
    
    const profileData = useSelector((state) => state.userProfile.profileData);

    const axiosInstance = createInstance(true);
    const dispatch = useDispatch();
    const initialTargetData = {
        user_id: null,
        frequency: "",
        description: "",
    };

    const [monthlyInputs, setMonthlyInputs] = useState(Array(12).fill({ deals_count: 0, deals_amount: 0 }));
    const [quarterlyInputs, setQuarterlyInputs] = useState(Array(4).fill({ deals_count: 0, deals_amount: 0 }));
    const [yearlyInputs, setYearlyInputs] = useState(Array(1).fill({ deals_count: 0, deals_amount: 0 }));

    const [targetData, setTargetData] = useState(initialTargetData);
    const [targetFormErrors, setTargetFormErrors] = useState({});
    const [targetDetailsErrors, setTargetDetailsErrors] = useState({});

    const [selectedUser, setSelectedUser] = useState(null);

    const targetAddOpenToggle = useSelector((state) => state.targetForm.targetAddOpenToggle);
    const userOptionsLoaded = useSelector((state) => state.optionsForAsyncSelect.userOptionsLoaded);
    const userOptions = useSelector((state) => state.optionsForAsyncSelect.userOptions);
    const handleTargetForm = (e) => {
        setTargetFormErrors({ ...targetFormErrors, [e.target.name]: "" });
        setTargetData({ ...targetData, [e.target.name]: e.target.value });
    };

    const handleInputChange = (index, field, value) => {
        // Update the corresponding input field based on the selected frequency
        if (targetData.frequency === frequencies.Monthly) {
            setMonthlyInputs((prevInputs) => prevInputs.map((input, i) => (i === index ? { ...input, [field]: value } : input)));
        } else if (targetData.frequency === frequencies.Quarterly) {
            setQuarterlyInputs((prevInputs) => prevInputs.map((input, i) => (i === index ? { ...input, [field]: value } : input)));
        } else if (targetData.frequency === frequencies.Yearly) {
            setYearlyInputs((prevInputs) => prevInputs.map((input, i) => (i === index ? { ...input, [field]: value } : input)));
        }

        if (field === "deals_count") {
            setTargetDetailsErrors((prevErrors) => ({
                ...prevErrors,
                [`${index}_deals_count`]: "",
            }));
        } else {
            setTargetDetailsErrors((prevErrors) => ({
                ...prevErrors,
                [`${index}_deals_amount`]: "",
            }));
        }
    };

    const fetchUserOptions = async (inputValue) => {
        try {
            const response = await axiosInstance.get(userOptionsApi + "?search=" + inputValue);
            if (response.data && response.data.success) {
                const formattedOptions = response.data.data.map((item) => {
                    let additionalData = { location: item.location, mobile: item.mobile };

                    return {
                        value: item.user_id,
                        label: item.assign_to,
                        additionalData: additionalData, // Include additional data in the result
                    };
                });

                return formattedOptions;
            } else {
                toast.error("Error while fetching assigned to options", { autoClose: autoCloseToastError });
            }
        } catch (err) {
            errorHandler(err, "Error while fetching assigned to options");
        }
        return [];
    };

    const loadUserOptions = (inputValue, callback) => {
        if (inputValue.length < minCharsToLoadOptions) {
            return callback([]);
        }
        fetchUserOptions(inputValue)
            .then((options) => callback(options))
            .catch((error) => {
                console.error(error);
                callback([]);
            });
    };

    const closeTargetForm = () => {
        handleDrawerClose();
    };

    const handleTargetSubmit = async (e) => {
        e.preventDefault();
        const targetSchemaValidation = targetSchema.validate(targetData, { abortEarly: false });
        let targetDetails;
        if (targetData.frequency === frequencies.Monthly) targetDetails = monthlyInputs;
        else if (targetData.frequency === frequencies.Quarterly) targetDetails = quarterlyInputs;
        else if (targetData.frequency === frequencies.Yearly) targetDetails = yearlyInputs;

        const targetDetailsSchemaValidation = targetDetailsSchema.validate(targetDetails, { abortEarly: false });
        if (targetSchemaValidation.error || targetDetailsSchemaValidation.error) {
            if (targetSchemaValidation.error) {
                setTargetFormErrors({});
                const targetvalidationErrors = targetSchemaValidation.error.details.reduce((acc, error) => {
                    const fieldName = error.path[0];
                    acc[fieldName] = error.message;
                    return acc;
                }, {});

                setTargetFormErrors(targetvalidationErrors);
            }

            if (targetDetailsSchemaValidation.error) {
                const updatedValidationErrors = targetDetailsSchemaValidation.error.details.reduce((acc, detail) => {
                    const key = `${detail.path[0]}_${detail.context.key}`;
                    return { ...acc, [key]: detail.message };
                }, {});

                setTargetDetailsErrors(updatedValidationErrors);
            }
        } else {
            setTargetFormErrors({});
            setTargetDetailsErrors({});
            let payload = { ...targetData };
            try {
                if (targetData.frequency === frequencies.Quarterly) {
                    const quarterlyInputsWithTime = JSON.parse(JSON.stringify(quarterlyInputs));
                    quarterlyInputsWithTime.forEach((currentRow, index) => {
                        currentRow.time_period = ((index + 1) % 4) + 1;
                    });
                    payload.details = quarterlyInputsWithTime;
                } else if (targetData.frequency === frequencies.Yearly) {
                    yearlyInputs[0].time_period = 1;
                    payload.details = yearlyInputs;
                } else if (targetData.frequency === frequencies.Monthly) {
                    const monthlyInputsWithTime = JSON.parse(JSON.stringify(monthlyInputs));
                    monthlyInputsWithTime.forEach((currentRow, index) => {
                        currentRow.time_period = ((index + 3) % 12) + 1;
                    });

                    payload.details = monthlyInputsWithTime;
                }
                const response = await axiosInstance.post(targetApi, payload);
                if (response.status === 200) {
                    toast.success("Target added");
                    //Clears the form
                    setTargetData(initialTargetData);

                    dispatch(getAllTargets(""));
                } else {
                    toast.error("Target addition failed", { autoClose: autoCloseToastError });
                }
            } catch (err) {
                errorHandler(err, "Target addition failed");
            }
        }
    };

    useEffect(() => {
        setMonthlyInputs(Array(12).fill({ deals_count: 0, deals_amount: 0 }));
        setQuarterlyInputs(Array(4).fill({ deals_count: 0, deals_amount: 0 }));
        setYearlyInputs(Array(1).fill({ deals_count: 0, deals_amount: 0 }));
        setTargetFormErrors({});
        setTargetDetailsErrors({});
    }, [targetData.frequency]);

    const handleUserFocus = async () => {
        if (!userOptionsLoaded) {
            const fetchedOptions = await fetchUserOptions("");
            dispatch(setUserOptionsLoaded(true));
            dispatch(setUserOptions(fetchedOptions));
        }
    };

    const [isOpen, setIsOpen] = useState(isOpenProp);
    const handleDrawerClose = _ => {
        handleClose();
        setIsOpen(false);
    }

    return (
        <Drawer
            PaperProps={{
                sx: {
                width: { md: "70vw", lg: "55vw", xl: "50vw" },
                px: { xs: 2, md: 4 },
                },
            }}
            anchor={"right"}
            open={isOpen}
            onClose={handleDrawerClose}
            >
            <Box
                my={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="h5">New Target</Typography>
                <IconButton aria-label="close" onClick={handleDrawerClose} color="primary" >
                <CloseIcon />
                </IconButton>
            </Box>

            <Divider className="horizontal-line" />
            <Box my={!isSmallerScreen ? 3 : 2}>
                {/* Content */}
                <Grid container>

                { profileData?.role_id !== userRoles.User &&
                <>
                    {/* Assigned To */}
                    <Grid item xs={12} md={3} pr={2}>
                        <InputLabel className="fw-bold">
                            {"Assigned To "}
                            <Asterisk />
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={9} mb={2}>
                        <AsyncSelect
                            className={`async-select-input ${Boolean(targetFormErrors?.user_id) ? " error-border" : ""}`}
                            styles={asyncSelectNewCustomStyles}
                            onChange={(selectedOption) => {
                                setTargetFormErrors({ ...targetFormErrors, user_id: "" });
                                setSelectedUser(selectedOption);
                                if (selectedOption) {
                                    setTargetData({ ...targetData, user_id: selectedOption.value });
                                } else {
                                    setTargetData({ ...targetData, user_id: null });
                                }
                            }}
                            value={selectedUser}
                            defaultOptions={userOptions || []}
                            loadOptions={debounce(loadUserOptions, searchOptionDebounceTime)}
                            placeholder="Type to search..."
                            name="assigned_to"
                            noOptionsMessage={() => "No user found. Type again..."}
                            isClearable
                            onFocus={handleUserFocus}
                            components={{Option: userOption}}
                        />
                        {   targetFormErrors?.user_id &&
                            <Typography variant="caption" color="error" p={2} >
                                {targetFormErrors?.user_id}
                            </Typography>
                        }
                    </Grid>
                </>
                }
                    {/* Double Column */}
                    <Grid container item md={12}>

                        {/* Frequency */}
                        <Grid item xs={12} md={3} pr={2}>
                            <InputLabel className="fw-bold">
                                {"Frequency "}
                                <Asterisk />
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12} md={4.5} mb={2}>
                            <Select
                                name="frequency"
                                value={targetData.frequency}
                                onChange={(e) => handleTargetForm(e)}
                                size="small"
                                sx={{ width: "100%" }}
                                error={Boolean(targetFormErrors?.frequency)}
                                displayEmpty
                            >
                                <MenuItem key={'Select'} value="">
                                {'Select Frequency'}
                                </MenuItem>
                                {Object.keys(frequencies).map((key) => (
                                    <MenuItem key={key} value={frequencies[key]}>
                                    {frequencies[key]}
                                    </MenuItem>
                                ))}
                            </Select>
                            { targetFormErrors?.frequency &&
                                <Typography variant="caption" color="error" pl={2} m={0}>
                                    {targetFormErrors?.frequency || ""}
                                </Typography>
                            }
                        </Grid>

                        <Grid item md={4.5}></Grid>
                        {
                            targetData.frequency === frequencies.Monthly && 
                            <Grid item xs={12} container mb={1}>
                                <Grid item xs={3} pr={2} mb={2}>
                                    <Typography variant="body1" align="right"> 
                                        Month
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1"> 
                                        Deals Count
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} pr={2}>
                                    <Typography variant="body1" align="right"> 
                                        Month
                                    </Typography>
                                </Grid>
                                <Grid item xs={3}>
                                    <Typography variant="body1"> 
                                        Deals Amount
                                    </Typography>
                                </Grid>

                                {
                                    monthlyInputs.map((input, index) => (
                                        <Fragment key={index}>
                                            <Grid item xs={3} pr={2}>
                                                <InputLabel className="fw-bold" sx={{textAlign: 'right'}}>
                                                    {`${months[((index + 3) % 12) + 1]} `}
                                                    <Asterisk />
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={3} mb={1}>
                                                <TextField
                                                    type="number"
                                                    value={monthlyInputs[index].deals_count}
                                                    onChange={(e) => handleInputChange(index, "deals_count", e.target.value)}
                                                    placeholder="Deals Count"
                                                    size="small"
                                                    error={Boolean(targetDetailsErrors[`${index}_deals_count`])}
                                                    helperText={targetDetailsErrors[`${index}_deals_count`]}
                                                />
                                            </Grid>
                                            <Grid item xs={3} pr={2}>
                                                <InputLabel className="fw-bold" sx={{textAlign: 'right'}}>
                                                    {`${months[((index + 3) % 12) + 1]} `}
                                                    <Asterisk />
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={3}>
                                                <TextField
                                                    type="number"
                                                    value={monthlyInputs[index].deals_amount}
                                                    onChange={(e) => handleInputChange(index, "deals_amount", e.target.value)}
                                                    placeholder="Deals Amount"
                                                    className="input-value"
                                                    size="small"
                                                    error={Boolean(targetDetailsErrors[`${index}_deals_amount`])}
                                                    helperText={targetDetailsErrors[`${index}_deals_amount`]}
                                                />
                                            </Grid>
                                        </Fragment>
                                    ))
                                }

                            </Grid>
                        }

                        {
                            targetData.frequency === frequencies.Quarterly &&
                            <Grid item xs={12} container mb={1} columnSpacing={1}>
                                <Grid item container xs={6} mb={{xs: 1, sm: 0}}>
                                    <Grid item xs={12} sm={6} pr={2} mb={{xs: 0, sm: 2}}>
                                        <Typography variant="body1" sx={{textAlign: {xs: 'left', sm: 'right'}}}> 
                                            Quarter
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1"> 
                                            Deals Count
                                        </Typography>
                                    </Grid>
                                </Grid>

                                <Grid item container xs={6} mb={{xs: 1, sm: 0}}>
                                    <Grid item xs={12} sm={6} pr={2}>
                                        <Typography variant="body1" sx={{textAlign: {xs: 'left', sm: 'right'}}}>
                                        Quarter
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant="body1"> 
                                            Deals Amount
                                        </Typography>
                                    </Grid>
                                </Grid>

                                {
                                    quarterlyInputs.map((input, index) => (
                                        <Fragment key={index}>
                                        <Grid item container xs={6}>
                                            <Grid item xs={12} sm={6} pr={2} mb={1}>
                                                <InputLabel className="fw-bold" sx={{textAlign: {xs: 'left', sm: 'right'}}}>
                                                {`Quarter ${index + 1} `}
                                                    <Asterisk />
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={6} mb={1}>
                                                <TextField
                                                    type="number"
                                                    value={quarterlyInputs[index].deals_count}
                                                    onChange={(e) => handleInputChange(index, "deals_count", e.target.value)}
                                                    placeholder="Deals Count"
                                                    className="input-value"
                                                    size="small"
                                                    error={Boolean(targetDetailsErrors[`${index}_deals_count`])}
                                                    helperText={targetDetailsErrors[`${index}_deals_count`]}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid item container xs={6}>
                                            <Grid item xs={12} sm={6} pr={2}>
                                                <InputLabel className="fw-bold" sx={{textAlign: {xs: 'left', sm: 'right'}}}>
                                                {`Quarter ${index + 1} `}
                                                    <Asterisk />
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    type="number"
                                                    value={quarterlyInputs[index].deals_amount}
                                                    onChange={(e) => handleInputChange(index, "deals_amount", e.target.value)}
                                                    placeholder="Deals Amount"
                                                    className="input-value"
                                                    size="small"
                                                    error={Boolean(targetDetailsErrors[`${index}_deals_amount`])}
                                                    helperText={targetDetailsErrors[`${index}_deals_amount`]}
                                                />
                                            </Grid>
                                        </Grid>
                                        </Fragment>
                                    ))
                                }

                            </Grid>
                        }

                        {
                            targetData.frequency === frequencies.Yearly &&
                            <Grid item xs={12} container mb={1}>
                                {
                                    yearlyInputs.map((input, index) => (
                                        <Fragment key={index}>
                                            <Grid item xs={12} md={3} pr={2}>
                                                <InputLabel className="fw-bold">
                                                    {`Deals Count `}
                                                    <Asterisk />
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={3} mb={1}>
                                                <TextField
                                                    type="number"
                                                    value={yearlyInputs[index].deals_count}
                                                    onChange={(e) => handleInputChange(index, "deals_count", e.target.value)}
                                                    placeholder="Deals Count"
                                                    className="input-value"
                                                    size="small"
                                                    error={Boolean(targetDetailsErrors[`${index}_deals_count`])}
                                                    helperText={targetDetailsErrors[`${index}_deals_count`]}
                                                    fullWidth
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={3} pr={2}>
                                                <InputLabel className="fw-bold" sx={{textAlign: {xs: 'left', md: 'right'}}}>
                                                {`Deals Amount `}
                                                    <Asterisk />
                                                </InputLabel>
                                            </Grid>
                                            <Grid item xs={12} md={3}>
                                                <TextField
                                                    type="number"
                                                    value={yearlyInputs[index].deals_amount}
                                                    onChange={(e) => handleInputChange(index, "deals_amount", e.target.value)}
                                                    placeholder="Deals Amount"
                                                    className="input-value"
                                                    size="small"
                                                    error={Boolean(targetDetailsErrors[`${index}_deals_amount`])}
                                                    helperText={targetDetailsErrors[`${index}_deals_amount`]}
                                                    fullWidth
                                                />
                                            </Grid>
                                        </Fragment>
                                    ))
                                }

                            </Grid>
                        }

                    </Grid>

                    {/* Description */}
                    <Grid item xs={12} md={3} pr={2}>
                        <InputLabel className="fw-bold">
                            {"Description"}
                        </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={9} mb={2}>
                        <TextField
                            name="description"
                            onChange={(e) => handleTargetForm(e)}
                            placeholder="Description"
                            value={targetData.description}
                            error={Boolean(targetFormErrors?.description)}
                            helperText={targetFormErrors?.description || ""}
                            size="small"
                            fullWidth
                            multiline
                            rows={3}
                        />
                    </Grid>
                </Grid>
            </Box>

            <Box
                display={"flex"}
                flexDirection={"column"}
                justifyContent={"end"}
                height="100%"
            >
                <Divider className="horizontal-line" sx={{ my: 1 }} />

                <Box
                my={3}
                display={"flex"}
                justifyContent={{xs: "space-between", sm: "flex-start"}}
                columnGap={5}
                >
                <Button
                    variant="contained"
                    type="button"
                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                    onClick={handleDrawerClose}
                >
                    Cancel
                </Button>

                <Button
                    variant="contained"
                    type="submit"
                    className={`primary-btn ${isSmallerScreen ? "primary-small-btn" : ""}`}
                    onClick={(e) => {
                        handleTargetSubmit(e);
                    }}
                >
                    Save
                </Button>
                </Box>
            </Box>
        </Drawer>
    );
}
