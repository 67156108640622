import { consumableActions } from "../../reducers/am-reducers/consumables.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { timeFrameParam } from "../../../constants/queries";
import { consumableAPI } from "../../../constants/apis/AM/Consumables";
import { toast } from "react-toastify";

const axiosInstance = createInstance();


/**
 * getLowQuantityConsumables Consumables - Get Low quantity consumables data
 *
 * Returns Low Quantity Consumables data using the provided parameters
 *
 * @param {object} params
 */
export const getLowQuantityConsumables = (params) => async (dispatch) => {
    try {
        dispatch(consumableActions.getLowQuantityConsumablesRequest());

        const { data } = await axiosInstance.get(consumableAPI, {
            params: { ...params, lowQuantityConsumables: true, },
        });

        dispatch(consumableActions.getLowQuantityConsumablesSuccess(data.data));
    } catch (error) {

        errorHandler(error, "Failed to get consumables");
        dispatch(consumableActions.getLowQuantityConsumablesFail());
    }
};

/**
 * getConsumables - Get consumables data
 *
 * Returns Consumables data using the provided parameters
 *
 * @param {object} params
 */
export const getConsumables = (params) => async (dispatch) => {
    try {
        dispatch(consumableActions.getConsumablesRequest());

        const { data } = await axiosInstance.get(consumableAPI, {
            params: params,
        });

        dispatch(consumableActions.getConsumablesSuccess(data.data));
    } catch (error) {

        errorHandler(error, "Failed to get consumables");
        dispatch(consumableActions.getConsumablesFail());
    }
};

/**
 * editConsumableById - Edit a consumable by ID.
 *
 * Edits a consumable with the specified ID using the provided payload.
 *
 * @param {string} id - The ID of the consumable to be edited.
 * @param {object} payload - The data to update the consumable with.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const editConsumableById = (id, payload) => async (dispatch) => {
    try {
        dispatch(consumableActions.editConsumableRequest());

        const { data } = await axiosInstance.put(consumableAPI.concat(id), payload);

        if (data.success) {
            dispatch(
                consumableActions.editConsumableSuccess({ ...data.data, consumable_id: id })
            );
            toast.success("Consumable updated successfully");
        }
    } catch (error) {
        errorHandler(error, "Failed to edit consumable");
        dispatch(consumableActions.editConsumableFail());
    }
};

/**
 * addConsumable - Add a new consumable.
 *
 * Adds a new consumable using the provided payload.
 *
 * @param {object} payload - The data for the new consumable.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const addConsumable = (payload) => async (dispatch) => {
    try {
        dispatch(consumableActions.addConsumableRequest());

        const { data } = await axiosInstance.post(`${consumableAPI}`, payload);

        if (data.success) {
            dispatch(
                consumableActions.addConsumableSuccess({
                    ...data.data,

                    id: data.data.consumable_id,
                })
            );
            toast.success("Consumable added successfully");
        }
    } catch (error) {
        errorHandler(error, "Failed to add consumable");
        dispatch(consumableActions.addConsumableFail());
    }
};




