import {
  Box,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import { useEffect, useState } from "react";
import ELLinks from "./ELLinks";
import BDLinks from "./BDLinks";
import TSLinks from "./TSLinks";
import AssetLinks from "./AssetLinks";
import "./Sidebar.scss";

const drawerWidth = 280;

const Sidebar = ({ open, toggleSideNav }) => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const navigate = useNavigate();
  const [sidenavType, setSidenavType] = useState("");

  useEffect(() => {
    setSidenavType(window.location.pathname.split("/")[1]);
  }, [window.location.pathname]);

  return (
    <>
      <Collapse orientation="horizontal" in={open}>
        <Drawer
          sx={{
            display: { xs: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundImage: "none",
              boxShadow: "inherit",
              zIndex: 1100,
              border: "none",
            },
          }}
          PaperProps={{ className: "sidenav-bg" }}
          variant={isSmallerScreen ? "temporary" : "persistent"}
          ModalProps={{ keepMounted: true }}
          open={open}
          onClose={() => toggleSideNav()}
        >
          <Box sx={{ overflow: "auto" }}>
            <List sx={{ py: 0, px: 1}}>
              <ListItem sx={{ px: 0, pb: 0, ml: 1 }}>
                <IconButton
                  size="large"
                  edge="start"
                  aria-label="menu"
                  sx={{ fontWeight: "bold"}}
                  onClick={() => toggleSideNav()}
                >
                  <MenuIcon className="text-light" />
                </IconButton>
                <img
                  onClick={() => navigate("/allapps")}
                  src="/assets/images/VG_Logo.png"
                  style={{
                    cursor: "pointer",
                    height: "35px",
                    filter: "brightness(1.7)",
                  }}
                />
              </ListItem>
            </List>
          </Box>
          <Divider className="horizontal-line-light" sx={{ my: 1 }} />
          <Box sx={{ overflow: "auto" }}>
            {sidenavType === "el" && <ELLinks toggleSideNav={toggleSideNav} />}
            {sidenavType === "bd" && <BDLinks toggleSideNav={toggleSideNav} />}
            {sidenavType === "ts" && <TSLinks toggleSideNav={toggleSideNav} />}
            {sidenavType === "am" && <AssetLinks toggleSideNav={toggleSideNav} />}
          </Box>
        </Drawer>
      </Collapse>
    </>
  );
};

export default Sidebar;
