import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./CourseLearning.scss";
import { navAppNames } from "../../../constants/AppNames";
import elSidebarMenus from "../../../data/json/elSidebarMenus.json";
import { courseApi } from "../../../constants/apis/EL/Course";
import { getHrsMinsSecs } from "../../../utils/Time";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { userRoles } from "../../../enums/Auth";
import { testResults, userCourseStatuses } from "../../../enums/EL/Course";
import CourseExam from "../../../components/CourseExam/CourseExam";
import { courseTestPayloadSchema } from "../../../validations/CourseTest";
import UserResponses from "../../../components/UserResponses/UserResponses";
import MultipleFileViewer from "../../../components/MultipleFileViewer/MultipleFileViewer";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LanguageIcon from "@mui/icons-material/Language";
import StarsIcon from "@mui/icons-material/Stars";
import AccessAlarmIcon from "@mui/icons-material/AccessAlarm";


import {
  Link,
  Chip,
  Box,
  Paper,
  Button,
  ImageList,
  ImageListItem,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
  Menu,
  MenuItem,
  IconButton,
  Tooltip,
} from "@mui/material";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from "react-redux";
import { getFileTypeFromUrl } from "../../../utils/File";
import { courseResourceWatchTimePayloadSchema } from "../../../validations/ElForms/courseRsourseWatchTimeUpdate";

const CourseLearning = () => {
  const userId = localStorage.getItem('user_id');
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumOrSmallerScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const profileData = useSelector((state) => state.userProfile.profileData);

  const appSidebar = JSON.parse(JSON.stringify(elSidebarMenus));

  if (parseInt(profileData?.role_id) !== userRoles.SuperAdmin) {
    appSidebar.menuList[1].addEntityButton = false;
  }

  let { courseId } = useParams() || undefined;

  const navigate = useNavigate();

  const [courseData, setCourseData] = useState({});
  const [courseDataLoading, setCourseDataLoading] = useState(true);
  const [userCourseStatus, setUserCourseStatus] = useState(null);

  const [responseSheet, setResponseSheet] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [testStarted, setTestStarted] = useState(false);

  const [submittingTest, setSubmittingTest] = useState(false);
  const [testSubmissionSuccess, setTestSubmissionSuccess] = useState(false);

  const [testResult, setTestResult] = useState("");
  const [obtainedTotal, setObtainedTotal] = useState(0);
  const [testTotal, setTestTotal] = useState(0);
  const [testResponses, setTestResponses] = useState([]);
  const [isPptModalOpen, setIsPptModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const isCourseMenuOpen = Boolean(anchorEl);



  const [courseResources, setCourseResources] = useState(null)

  const [selectedCourseResource, setSelectedCourseResource] = useState(Boolean(courseResources) ? courseResources[0] : null)

  const updateSelectedCourseResource = (courseResource) => {
    setSelectedCourseResource(Boolean(courseResource) ?
      { ...courseResource, course_file_type: getFileTypeFromUrl(courseResource.course_file_url) }
      : null);
  };

  const requestData = async () => {
    setCourseDataLoading(true);
    const axiosInstance = createInstance(true);
    await axiosInstance
      .get(courseApi + `usercourse/${courseId}`)
      .then((res) => {
        if (res.data.success) {
          let responseCourseData = res.data.data;
          let durationArray = getHrsMinsSecs(responseCourseData.duration);
          responseCourseData.durationHrs = durationArray[0];
          responseCourseData.durationMins = durationArray[1];

          setUserCourseStatus(responseCourseData.status);
          setCourseData(responseCourseData);
          let updatedCourseResources = responseCourseData?.course_resources;
          setCourseResources(updatedCourseResources);
          updateSelectedCourseResource(updatedCourseResources[0]);
        } else {

          toast.error("Failed to fetch course data!", {
            autoClose: autoCloseToastError,
          });
        }
      })
      .catch((err) => {
        setCourseData({});

        errorHandler(err, "Failed to fetch course data!");
      });
    setCourseDataLoading(false);
  };

  const requestTestData = async () => {
    setCourseDataLoading(true);
    const axiosInstance = createInstance(true);
    await axiosInstance
      .get(courseApi + `usertest/${courseId}`)
      .then((res) => {
        if (res.data.success) {
          let responseCourseTestData = res.data.data;
          let durationArray = getHrsMinsSecs(responseCourseTestData.duration);
          responseCourseTestData.durationHrs = durationArray[0];
          responseCourseTestData.durationMins = durationArray[1];

          setQuestions((_) => responseCourseTestData?.questions);
          const initialResponseSheet = [];
          responseCourseTestData?.questions.forEach((singleQuestion) => {
            initialResponseSheet.push({
              question_id: singleQuestion.question_id,
              option_id: null,
            });
          });
          setResponseSheet((_) => initialResponseSheet);
          setTestStarted(true);
        } else {
          toast.error("Failed to fetch test!", {
            autoClose: autoCloseToastError,
          });
        }
      })
      .catch((err) => {
        errorHandler(err, "Failed to fetch test!");
      });
    setCourseDataLoading(false);
  };

  const startCourse = async () => {
    try {
      const axiosInstance = createInstance(true);
      const startLearningResponse = await axiosInstance.put(
        courseApi + `start-course-learning/${courseId}`
      );
      if (!startLearningResponse.data.success) {
        toast.error("Failed to start course", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Failed to start course");
    }
  };

  const submitTest = async () => {
    const courseTestPayload = {
      course_id: courseId,
      testResponses: responseSheet,
    };

    const validation = courseTestPayloadSchema.validate(courseTestPayload);
    if (!validation.error) {
      setSubmittingTest((_) => true);
      try {
        const axiosInstance = createInstance(true);
        const testSubmitResponse = await axiosInstance.post(
          courseApi + `submit-test`,
          courseTestPayload
        );
        if (
          testSubmitResponse.data.success &&
          testSubmitResponse.data.data.testResult
        ) {
          setSubmittingTest((_) => false);
          toast.info(`Test is successfully submitted !`);
          if (testSubmitResponse.data.data.testResult === testResults.pass) {
            toast.success(
              `Congratulations you have passed the test! You have scored ${testSubmitResponse.data.data.marksObtained} marks!`
            );
          } else if (
            testSubmitResponse.data.data.testResult === testResults.fail
          ) {
            toast.warning(
              `You have failed the test! You have scored ${testSubmitResponse.data.data.marksObtained} marks!`
            );
          }
          const submissionResponse = testSubmitResponse.data.data;
          setTestResult((_) => submissionResponse.testResult);
          setObtainedTotal((_) => submissionResponse.marksObtained);
          const userTestResponses = submissionResponse.userTestResponses;
          let responseTotal = 0;
          userTestResponses.forEach((singleResponse) => {
            singleResponse.question = questions.find(
              (item) => item["question_id"] === singleResponse.question_id
            );
            responseTotal += singleResponse?.marks;
          });
          setTestTotal((_) => responseTotal);
          setTestResponses((_) => userTestResponses);
          setTestSubmissionSuccess((_) => true);
        } else {
          setSubmittingTest((_) => false);
          toast.error("Failed to submit test", {
            autoClose: autoCloseToastError,
          });
        }
      } catch (err) {
        setSubmittingTest((_) => false);
        errorHandler(err, "Failed to submit test.");
      }
      setSubmittingTest((_) => false);
    } else {
      toast.error("Please attempt all questions", {
        autoClose: autoCloseToastError,
      });
    }
  };

  const handleContinueLearning = () => {
    if (testResult === testResults.pass) {
      navigate("/el/my-courses");
    } else {
      setTestResult((_) => "");
      setObtainedTotal((_) => 0);
      setTestTotal((_) => 0);
      setTestResponses((_) => []);
      setTestSubmissionSuccess((_) => false);
      setTestStarted((_) => false);
      setResponseSheet((_) => []);
      requestData();
    }
  };

  const handleFileViewClose = async (fileName, fileTimeSpend, fileLastProgress) => {

    const courseResourceWatchTimePayload = {
      file_time_spend: fileTimeSpend,
      file_last_progress: fileLastProgress,
    };

    const validation = courseResourceWatchTimePayloadSchema.validate(courseResourceWatchTimePayload);

    if (!validation.error) {
      try {
        const axiosInstance = createInstance(true);
        const testSubmitResponse = await axiosInstance.put(
          courseApi + `${courseId}/resources/${fileName}/progress`,
          courseResourceWatchTimePayload
        );
        if (!(testSubmitResponse.data.success)) {
          toast.error("Failed to save course resource watch time.", { autoClose: true, });
        }
      } catch (err) {
        errorHandler(err, "Failed to save course resource watch time.");
      }
    }
  }

  const courseResourceComponent = (resource, i) => {
    return <Box key={`course_resource_${i}`} my={!isSmallerScreen ? 3 : 0.5} >
      <Link
        className=".primary-hover link-hover"
        variant="body2"
        color={"textSecondary"}
        display={'flex'}
        alignItems={'center'}
        onClick={() => {
          updateSelectedCourseResource(resource);
        }}
      >
        <CheckCircleIcon
          className="primary-text"
          fontSize="small"
          sx={{
            mr: 1,
          }}
        />
        <div className={selectedCourseResource.course_file_name === resource.course_file_name ? 'primary-text' : ''}>
          {resource.course_file_name}
        </div>
      </Link>
    </Box>
  };

  const getCourseResourcesForLargeScreen = () => (
    <Box
      key='course_resources_for_large_screen'
      sx={{
        maxHeight: '250px',
        overflowY: 'auto'
      }}>
      {
        courseResources?.map((resource, i) => courseResourceComponent(resource, i))
      }
    </Box >);

  const getCourseResourcesForSmallScreen = () => {
    return <>
      <Box sx={{ display: 'flex', alignItems: 'center', width: 'fit-content' }} onClick={(e) => setAnchorEl(e.currentTarget)} >

        <Tooltip title={selectedCourseResource.resource_name}>
          <Box sx={{
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            width: { xs: '230px', sm: '100%' },

          }}>
            {courseResourceComponent(selectedCourseResource ?? {})}
          </Box>
        </ Tooltip>
        <IconButton color="primary" >
          <ArrowDropDownIcon />
        </IconButton>
      </Box>
      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        open={isCourseMenuOpen}
        onClose={() => setAnchorEl(null)}
      >
        {courseResources.map((resource, i) => (
          <MenuItem
            selected={i === courseResources.indexOf(selectedCourseResource)}
            key={`course_resource_${i}`} value={i} onClick={() => {
              updateSelectedCourseResource(resource);
              setAnchorEl(null);
            }}>
            {courseResourceComponent(resource)}
          </MenuItem>
        ))}
      </Menu>
    </>
  }

  useEffect(() => {
    if (userCourseStatus === userCourseStatuses.notStarted) {
      startCourse();
    }
  }, [userCourseStatus]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [testStarted, testSubmissionSuccess]);


  useEffect(() => {
    courseId = parseInt(courseId);

    if (courseId) {
      requestData();
    } else {
      navigate("/el/my-courses");
    }
  }, [courseId]);

  return (
    <>
      {courseDataLoading ? (
        <LoadingSpinner />
      ) : Object.keys(courseData).length === 0 ? (
        "Course Not Found"
      ) : (
        <Grid container spacing={2} pl={2}>
          {/* course information   */}
          <Grid item xs={12}>
            <Paper
              variant="outlined"
              elevation={0}
              sx={{
                mr: 1.5,
                px: 2,
                pb: 2,
                minHeight: "90vh",
                borderRadius: 2,
              }}
            >
              {/* Course Title */}
              <Typography variant="h6" mt={2}>
                {courseData.title} {testStarted ? " - Quiz" : ""}
              </Typography>

              {/* Divider */}
              <Divider className="horizontal-line" sx={{ my: 1 }} />

              <Grid container>
                <Grid
                  item
                  lg={4}
                  xs={12}
                  sx={{
                    pr: {
                      xs: 0,
                      lg: 4,
                    },
                  }}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      {/* Course thumbnail */}
                      <ImageList
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexWrap: "wrap",
                          overflow: "hidden",
                          borderRadius: 2,
                        }}
                        cols={
                          isMobileScreen ? 1 : isMediumOrSmallerScreen ? 2 : 3
                        } // Adjust columns based on screen size
                        style={{ height: { xs: 150, sm: 250, md: 300 } }}
                      >
                        <ImageListItem key={`${courseData.title}_image`}>
                          <img
                            src={courseData.course_image_url}
                            alt={`${courseData.title}_image`}
                            style={{
                              width: "100%", // Ensures image fills container width
                              height: "auto", // Maintains aspect ratio
                              objectFit: 'cover'
                            }}
                            loading="lazy"
                          />
                        </ImageListItem>
                      </ImageList>
                    </Grid>
                    <Grid item xs={12} >
                      {/* Course Resources title */}
                      {!testStarted && !testSubmissionSuccess ? (
                        <>
                          <Typography
                            className="primary-text "
                            variant="body1"
                            sx={{
                              mt: 2,
                              mb: 1,
                            }}
                          >
                            Course Resources
                          </Typography>

                          {(!isSmallerScreen || courseResources?.length === 1) ? getCourseResourcesForLargeScreen() : getCourseResourcesForSmallScreen()}
                          <Divider sx={{ my: 1 }} className="horizontal-line" />
                        </>
                      ) : (
                        <></>
                      )}
                      <Typography
                        className="primary-text"
                        variant="body1"
                        sx={{
                          mt: 2,
                          mb: 1,
                        }}
                      >
                        Course Details
                      </Typography>
                      <Box
                        display={"flex"}
                        flexDirection={isMobileScreen ? "column" : "row"}
                        alignItems={isMobileScreen ? "start" : "center"}
                        justifyContent={"space-between"}
                        my={1}
                      >
                        <Box display={"flex"}>
                          {/* Course Info */}
                          {/*  module name */}
                          <Typography variant="body2" fontWeight={"bold"}>
                            Module Name :
                          </Typography>
                          <Typography variant="body2">&nbsp;</Typography>
                          <Typography
                            variant="body2"

                          >
                            {`${courseData.module_title}`}
                          </Typography>
                        </Box>
                        {/* Course Language */}
                        <Box
                          sx={{
                            borderRadius: 0.5,
                            mt: {
                              xs: 1,
                              md: 0,
                            },
                          }}
                        >
                          <Chip
                            icon={<LanguageIcon />}
                            label={courseData.language}
                            sx={{ textTransform: "uppercase" }}
                            size="small"
                            color="primary"
                          />
                        </Box>
                      </Box>

                      <Box
                        display={"flex"}
                        flexDirection={isMobileScreen ? "column" : "row"}
                        alignItems={isMobileScreen ? "start" : "end"}
                        justifyContent={"space-between"}
                      >
                        {/* Course   */}
                        {/* course marks */}
                        <Box display="flex" justifyContent={"start"} mr={3}>
                          <Box mr={0.5}>
                            <StarsIcon fontSize="small" />
                          </Box>

                          <Typography variant="body2">
                            {courseData.total_marks} Marks
                          </Typography>
                        </Box>
                        {/* course duration */}
                        <Box display="flex" justifyContent={"start"}>
                          <Box mr={0.5}>
                            <AccessAlarmIcon fontSize="small" />
                          </Box>

                          <Typography variant="body2">
                            {courseData.durationHrs} Hrs{" "}
                            {courseData.durationMins} Mins
                          </Typography>
                        </Box>
                      </Box>
                      {/* Course Description */}
                      <Typography
                        variant="body2"
                        color={"textSecondary"}
                        sx={{ my: 2 }}
                      >
                        {courseData.description}
                      </Typography>

                      {!testStarted && !testSubmissionSuccess && (
                        <>
                          <Divider className="horizontal-line" sx={{ my: 1 }} />
                          <Box sx={{ my: 1 }}>
                            <Button
                              variant="contained"
                              type="button"
                              className={`${isSmallerScreen
                                ? `primary-small-btn`
                                : `primary-btn`
                                } `}
                              sx={{ p: 0, ml: 0 }}
                              onClick={requestTestData}
                            >
                              Give Exam
                            </Button>
                          </Box>
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>

                {/* course details*/}
                <Grid
                  item
                  xs={12}
                  lg={8}
                  display="flex"
                  flexDirection="column"
                  my={isMobileScreen ? 2 : 0}
                >
                  {!testStarted && !testSubmissionSuccess ? (
                    <MultipleFileViewer
                      fileName={selectedCourseResource?.course_file_name}
                      fileType={selectedCourseResource?.course_file_type}
                      fileUrl={selectedCourseResource?.course_file_url}
                      isPptModalOpen={isPptModalOpen}
                      setIsPptModalOpen={setIsPptModalOpen}
                      lastRecordedProgress={selectedCourseResource?.last_progress}
                      recordedTimeSpend={selectedCourseResource?.time_spend}
                      userId={userId}
                      uniqueId={courseId}
                      onFileViewCloseCallBack={handleFileViewClose}
                    />
                  ) : testSubmissionSuccess ? (
                    <UserResponses
                      testResult={testResult}
                      obtainedTotal={obtainedTotal}
                      testTotal={testTotal}
                      responses={testResponses}
                      nextAction={true}
                      nextActionText={"Continue Learning"}
                      nextActionFunction={handleContinueLearning}
                      isSmallerScreen={isSmallerScreen}
                      isMobileScreen={isMobileScreen}
                    />
                  ) : (
                    <CourseExam
                      questions={questions}
                      responseSheet={responseSheet}
                      setResponseSheet={setResponseSheet}
                      submitTest={submitTest}
                      submittingTest={submittingTest}
                      isSmallerScreen={isSmallerScreen}
                      isMobileScreen={isMobileScreen}
                    />
                  )}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default CourseLearning;
