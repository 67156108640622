export const checkAllSelection = (inputObject) => {
  const allTrue = Object.values(inputObject).every((value) => value === true);
  const allFalse = Object.values(inputObject).every((value) => value === false);
  return allTrue || allFalse;
};

export const titleCase = async (str) => {
  const wordsWithSeparators = str.split(/([ ,.-]+)/);
  const words = [];
  let currentWord = "";

  for (const wordOrSeparator of wordsWithSeparators) {
    if (!/[ ,.-]+/.test(wordOrSeparator)) {
      currentWord =
        wordOrSeparator.charAt(0).toUpperCase() +
        wordOrSeparator.slice(1).toLowerCase();
    } else {
      if (currentWord) {
        words.push(currentWord);
      }
      currentWord = "";
      words.push(wordOrSeparator);
    }
  }
  if (currentWord) {
    words.push(currentWord);
  }
  return words.join("");
};

export const customTimeRoundUp = (seconds) => {
  seconds = parseFloat(seconds);
  let hours = seconds / parseFloat(3600);
  let decimalPart = hours - Math.floor(hours);

  // If the decimal part is greater than 0.5, round up, otherwise round down
  // Also if decimal part is exact half, add it
  if (decimalPart > 0.5) {
    return Math.ceil(hours);
  } else if (decimalPart < 0.5) {
    return Math.floor(hours);
  } else {
    return Math.floor(hours) + 0.5;
  }
};
