import React, { useEffect, useState } from "react";
import elSidebarMenus from "../../../data/json/elSidebarMenus.json";
import { DebounceInput } from "react-debounce-input";
import BdCounts from "../../../components/BdCounts/BdCounts";
import resultsColumns from "../../../data/json/resultsColumns.json";
import { resultApi } from "../../../constants/apis/EL/Result";
import { convertParamsToString } from "../../../utils/Request";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  asyncSelectCustomStyles,
  minCharsToLoadOptions,
  searchOptionDebounceTime,
} from "../../../constants/Common";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { userRoles } from "../../../enums/Auth";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import { accountApi } from "../../../constants/apis/BD/Account";
import { elDashboardApi } from "../../../constants/apis/EL/ElDashboard";
import { courseApi } from "../../../constants/apis/EL/Course";
import {
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import { useSelector } from "react-redux";

const Result = () => {
  const appSidebar = JSON.parse(JSON.stringify(elSidebarMenus));
  appSidebar.menuList[4].currentPage = true;

  const profileData = useSelector((state) => state.userProfile.profileData);

  const initialContactCountCards = [
    {
      type: "main",
      iconImagePath: "/assets/images/eldashboard.svg",
      altText: "results-icon",
      count: 0,
      textLabel: "Total Learners",
    },
    {
      type: "normal",
      count: 0,
      textLabel: "Not Started",
    },
    {
      type: "normal",
      count: 0,
      textLabel: "In Progress",
    },
    {
      type: "normal",
      count: 0,
      textLabel: "Pass",
    },
  ];

  const [searched, setSearched] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [countCards, setCountCards] = useState(initialContactCountCards);
  const [rowsLoading, setRowsLoading] = useState(false);
  const [isSAdminSignedIn, setIsSAdminSignedIn] = useState(false);
  const [isLocalAdminSignedIn, setIsLocalAdminSignedIn] = useState(false);

  const [organizationOptions, setOrganizationOptions] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [moduleOptions, setModuleOptions] = useState([]);
  const [module, setModule] = useState(null);
  const [courseOptions, setCourseOptions] = useState([]);
  const [course, setCourse] = useState(null);

  const requestData = async () => {
    if (!organization || !module || !course) {
      return;
    }
    setRowsLoading(true);
    const requestParams = {
      account_id: organization.value,
      module_id: module.value,
      course_id: course.value,
    };

    //handle search
    if (searched !== "") {
      requestParams["search"] = searched;
    }

    //handle sorting here
    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }

    //apply pagination here
    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;

    const axiosInstance = createInstance(true);
    await axiosInstance
      .get(resultApi + convertParamsToString(requestParams))
      .then((res) => {
        const moduleRows = res.data.data.rows;

        //adds required fields
        moduleRows.forEach((currentRow) => {
          currentRow.id = currentRow.user_course_id;
        });
        setRows(moduleRows);
        setTotalCount(res.data.data.meta.total);

        //sets the cards
        const responseCardData = res.data.data.cards;
        const currentCountCards = [...initialContactCountCards];

        for (let i = 0; i < currentCountCards.length; i++) {
          for (let j = 0; j < responseCardData.length; j++) {
            if (
              currentCountCards[i].textLabel === responseCardData[j].card_name
            ) {
              currentCountCards[i].count = responseCardData[j].count;
              break;
            }
          }
        }
        setCountCards(currentCountCards);
      })
      .catch((err) => {
        setRows([]);
        setTotalCount(0);
        errorHandler(err, "Failed to fetch modules");
      });
    setRowsLoading(false);
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const getTableHeadCell = (columnInformation) => {
    return (
      <TableCell key={columnInformation.id}>
        <TableSortLabel
          active={orderBy === columnInformation.id}
          direction={orderBy === columnInformation.id ? order : "asc"}
          onClick={() => handleSortRequest(columnInformation.id)}
        >
          {columnInformation.name}
        </TableSortLabel>
      </TableCell>
    );
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  const fetchCourseOptions = async (input) => {
    try {
      const axiosInstance = createInstance(true);
      const result = await axiosInstance.get(
        courseApi +
          `assignedcourseoptions/${organization.value}/${module.value}?search=${input}`
      );
      if (!result.data.success) {
        throw new Error("Failed to fetch course options");
      } else {
        const optionsRows = result.data.data;
        const formattedOptions = optionsRows.map((singleRow) => {
          return {
            value: singleRow.course_id,
            label: singleRow.title,
          };
        });
        return formattedOptions;
      }
    } catch (err) {
      errorHandler(err, "Failed to fetch course options");
      return [];
    }
  };

  const loadCourseOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchCourseOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const fetchModuleOptions = async (inputvalue) => {
    const axiosInstance = createInstance(true);
    try {
      const result = await axiosInstance.get(
        elDashboardApi +
          `assignedmoduleoptions/${organization.value}?search=${inputvalue}`
      );

      if (!result.data.success) {
        throw new Error("Failed to fetch module options");
      } else {
        const optionsRows = result.data.data;
        const formattedOptions = optionsRows.map((singleRow) => {
          return {
            value: singleRow.module_id,
            label: singleRow.title,
          };
        });
        return formattedOptions;
      }
    } catch (err) {
      errorHandler(err, "Failed to fetch module options");
      return [];
    }
  };

  const loadModuleOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchModuleOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const fetchOrganizationOptions = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      const result = await axiosInstance.get(
        accountApi + `enxcustomeraccountoptions?search=${inputValue}`
      );
      if (!result.data.success) {
        throw new Error("Failed to fetch customer account options");
      } else {
        const optionsRows = result.data.data;
        const formattedOptions = optionsRows.map((singleRow) => {
          return {
            value: singleRow.account_id,
            label: singleRow.account_name,
          };
        });
        return formattedOptions;
      }
    } catch (err) {
      errorHandler(err, "Failed to fetch customer account options");
      return [];
    }
  };

  const loadOrganizationOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchOrganizationOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  useEffect(() => {
    setCurrentPage(0);
    const setDefaultCourseOptions = async () => {
      const options = await fetchCourseOptions("");
      setCourseOptions((_) => options);
      if (options.length) {
        setCourse((_) => options[0]);
      }
    };

    if (module) {
      setCourse((_) => null);
      setDefaultCourseOptions();
    } else {
      setCourse((_) => null);
    }
  }, [module]);

  useEffect(() => {
    setCurrentPage(0);
    const setDefaultModuleOptions = async () => {
      const options = await fetchModuleOptions("");
      setModuleOptions((_) => options);
      if (options.length) {
        setModule((_) => options[0]);
      }
    };

    if (organization) {
      setModule((_) => null);
      setDefaultModuleOptions();
    } else {
      setModule((_) => null);
    }
  }, [organization]);

  useEffect(() => {
    const setDefaultOrganizationOptions = async () => {
      const options = await fetchOrganizationOptions("");
      setOrganizationOptions((_) => options);
      if (options.length) {
        setOrganization((_) => options[0]);
      }
    };
    if (isSAdminSignedIn) {
      setDefaultOrganizationOptions();
    } else if (isLocalAdminSignedIn) {
      setOrganization({
        label: profileData?.account_name,
        value: profileData?.account_id,
      });
    }
  }, [isSAdminSignedIn, isLocalAdminSignedIn]);

  useEffect(() => {
    setCurrentPage(0);
    if (course) {
      requestData();
    }
  }, [course]);

  useEffect(() => {
    if (isSAdminSignedIn || isLocalAdminSignedIn) {
      requestData();
    }
  }, [currentPage, rowsPerPage, searched, order, orderBy]);

  useEffect(() => {
    const sessionRole = profileData?.role_id;

    if (parseInt(sessionRole) === userRoles.SuperAdmin) {
      setIsSAdminSignedIn(true);
      setCountCards(initialContactCountCards);
    } else if (parseInt(sessionRole) === userRoles.Admin) {
      setIsLocalAdminSignedIn(true);
      setCountCards(initialContactCountCards);
    }
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} sm={7}>
              <Typography variant="h6">Results</Typography>
            </Grid>
            <Grid item xs={12} sm={5} textAlign={{ xs: "start", sm: "end" }}>
              {isLocalAdminSignedIn || isSAdminSignedIn ? (
                <Box className="search-container results-search">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input results-search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                    disabled={course === null}
                  />
                </Box>
              ) : (
                ""
              )}
            </Grid>
          </Grid>
          <Grid
            container
            mt={2}
            justifyContent={"end"}
            columnGap={3}
            rowGap={2}
          >
            <Grid
              item
              xs={12}
              sm={"auto"}
              display={"flex"}
              alignItems={{ xs: "start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Typography sx={{ mr: 1 }} variant="body1">
                Organization
              </Typography>
              {isLocalAdminSignedIn || isSAdminSignedIn ? (
                isSAdminSignedIn ? (
                  <AsyncSelect
                    styles={{
                      control: (provided) => ({
                        ...provided,
                        width: "210px",
                      }),
                    }}
                    onChange={(selectedOption) => {
                      setOrganization(selectedOption);
                    }}
                    value={organization}
                    defaultOptions={organizationOptions}
                    loadOptions={debounce(
                      loadOrganizationOptions,
                      searchOptionDebounceTime
                    )}
                    placeholder="Type to search..."
                    name="assigned_to"
                    noOptionsMessage={() =>
                      "No organization found. Type again..."
                    }
                    isClearable
                  />
                ) : (
                  <Typography
                    sx={{ ml: 2 }}
                    color={"textSecondary"}
                    variant="caption"
                  >
                    {organization?.label}
                  </Typography>
                )
              ) : (
                ""
              )}
            </Grid>
            <Grid
              item
              xs={12}
              sm={"auto"}
              display={"flex"}
              alignItems={{ xs: "start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Typography sx={{ mr: 1 }} variant="body1" component={"span"}>
                Module
              </Typography>
              <Box width={"100%"}>
                <AsyncSelect
                  styles={{
                    control: (provided) => ({
                      ...provided,
                      width: "210px",
                    }),
                  }}
                  onChange={(selectedOption) => {
                    setModule(selectedOption);
                  }}
                  value={module}
                  defaultOptions={moduleOptions}
                  isDisabled={organization === null}
                  loadOptions={debounce(
                    loadModuleOptions,
                    searchOptionDebounceTime
                  )}
                  placeholder="Type to search..."
                  name="assigned_to"
                  noOptionsMessage={() => "No module found. Type again..."}
                  isClearable
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={"auto"}
              display={"flex"}
              alignItems={{ xs: "start", sm: "center" }}
              flexDirection={{ xs: "column", sm: "row" }}
            >
              <Typography sx={{ mr: 1 }} variant="body1" component={"span"}>
                Course
              </Typography>
              <AsyncSelect
                styles={{
                  control: (provided) => ({
                    ...provided,
                    width: "210px",
                  }),
                }}
                onChange={(selectedOption) => {
                  setCourse(selectedOption);
                }}
                value={course}
                defaultOptions={courseOptions}
                isDisabled={module === null}
                loadOptions={debounce(
                  loadCourseOptions,
                  searchOptionDebounceTime
                )}
                placeholder="Type to search..."
                name="assigned_to"
                noOptionsMessage={() => "No course found. Type again..."}
                isClearable
              />
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {isLocalAdminSignedIn || isSAdminSignedIn ? (
            <>
              <Box mb={3}>
                <BdCounts cards={countCards} />
              </Box>
              <Box className="el-results-table-container">
                {rowsLoading ? (
                  <LoadingSpinner />
                ) : rows.length === 0 ? (
                  "No data found..."
                ) : (
                  <>
                    <TableContainer>
                      <Table size="medium">
                        <TableHead className="stat-card-bg">
                          <TableRow>
                            {resultsColumns.map((assetCol) =>
                              getTableHeadCell(assetCol)
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {rows &&
                            rows.map((resultRow) => {
                              return (
                                <TableRow key={resultRow.id}>
                                  <Tooltip title={resultRow.user_name}>
                                    <TableCell
                                      sx={{ maxWidth: "350px" }}
                                      className="text-truncate"
                                    >
                                      {resultRow.user_name}
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {resultRow.mobile}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "300px" }}
                                  >
                                    {resultRow.location_name}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "200px" }}
                                  >
                                    {resultRow.result}
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={totalCount}
                      rowsPerPage={rowsPerPage}
                      page={currentPage}
                      onPageChange={handlePage}
                      onRowsPerPageChange={handleRowsPerPage}
                    />
                  </>
                )}
              </Box>
            </>
          ) : (
            ""
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Result;
