import { Grid, Typography } from "@mui/material";

const EntityCountCardRow = (props) => {
  return (
    <Grid container>
      {props.cardArray.map((card, index) => (
        <Grid
          key={index}
          item
          xs={12}
          sm={5.9}
          md={2.9}
          container
          className="stat-card-bg"
          sx={{ borderRadius: "15px", p: 2, my: 1, mr: 2 }}
        >
          <Grid item xs={8}>
            <img src={card.imagePath} alt={card.imageAlt} />
          </Grid>
          <Grid item xs={4} textAlign={"end"}>
            <Typography variant="h3">{card.entityCount}</Typography>
          </Grid>
          <Grid item xs={12} textAlign={"end"}>
            <Typography variant="body1" color={"textSecondary"}>
              {card.entityText}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default EntityCountCardRow;
