import { taskActions } from "../../reducers/bd-reducers/tasks.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { timeFrameParam } from "../../../constants/queries";
import { toast } from "react-toastify";
import { taskApi } from "../../../constants/apis/BD/Task";
import { bdDashboardApi } from "../../../constants/apis/BD/BdDashboard";

const axiosInstance = createInstance();

/**
 * getDeals - Get tasks data
 * @param {string} timeFrame
 */
export const getDashboardTasks = (timeFrame) => async (dispatch) => {
  try {
    dispatch(taskActions.getDashboardTasksRequest());

    const { data } = await axiosInstance.get(bdDashboardApi + "dashboard-tasks".concat(`?timeframe=${timeFrame}`));
    dispatch(taskActions.getDashboardTasksSuccess(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get tasks");
    dispatch(taskActions.getDashboardTasksFail(error.response.data.message));
  }
};

export const getAllTasks = (params) => async (dispatch) => {
  try {
    dispatch(taskActions.getAllTasksRequest());

    const { data } = await axiosInstance.get(
      `/business-development/task/?${params}`
    );

    dispatch(taskActions.getAllTasksSuccess(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get tasks");
    dispatch(taskActions.getAllTasksFail(error.response.data.message));
  }
};

export const editTaskById = (id, payload, accountSpecific, forDashboard=false) => async (dispatch) => {
  try {
    dispatch(taskActions.editTaskRequest());

    const { data } = await axiosInstance.put(`${taskApi}/${id}`, payload);

    if(data.success) {
      dispatch(taskActions.editTaskSuccess({editedTask: data.data, accountSpecific, forDashboard}));
      toast.success("Task updated successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to edit task");
    dispatch(taskActions.editTaskFail(error.response.data.message));
  }
};

export const addTask = (payload, accountSpecific) => async (dispatch) => {
  try {
    dispatch(taskActions.addTaskRequest());

    const { data } = await axiosInstance.post(taskApi, payload);

    if(data.success) {
      dispatch(taskActions.addTaskSuccess({addedTask: data.data, accountSpecific}));
      toast.success("Task added successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to add task");
    dispatch(taskActions.addTaskFail(error.response.data.message));
  }
};

export const deleteTaskById = (id, accountSpecific) => async (dispatch) => {
  try {
    dispatch(taskActions.deleteTaskRequest());

    const { data } = await axiosInstance.delete(taskApi.concat(id));

    if(data.success) {
      dispatch(taskActions.deleteTaskSuccess({id, accountSpecific}));
      toast.success("Task deleted successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to add task");
    dispatch(taskActions.deleteTaskFail(error.response.data.message));
  }
};

export const getAccountSpecificTasks = (accountId, params) => async (dispatch) => {
  try {
    dispatch(taskActions.getAccountSpecificTasksRequest());

    const { data } = await axiosInstance.get(taskApi.concat("/account/").concat(accountId) + params);

    dispatch(taskActions.getAccountSpecificTasksSuccess(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get tasks");
    dispatch(taskActions.getAccountSpecificTasksFail(error.response.data.message));
  }
};