import React, { useState } from "react";
import "./CourseExam.scss";
import ConfirmationModal from "../ConfirmationModal/ConfirmationModal";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

import {
  Dialog,
  Grid,
  Box,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Paper,
  Button,
  Typography,
  styled,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(2),
  },
}));

const SubmissionInProcessModal = () => {
  return (
    <>
      <DialogTitle
        sx={{ m: 0, p: 2, fontWeight: "bold" }}
        id="customized-dialog-title"
      >
        Test Submission
      </DialogTitle>

      <DialogContent dividers>
        <LoadingSpinner />
      </DialogContent>
    </>
  );
};

const CourseExam = (props) => {
  const questions = props.questions;
  const responseSheet = props.responseSheet;
  const responseSheetSetter = props.setResponseSheet;
  const submitTest = props.submitTest;
  const submittingTest = props.submittingTest;
  const isSmallerScreen = props.isSmallerScreen;

  const [currentQuestionNumber, setCurrentQuestionNumber] = useState(0);
  const [questionAttemptionError, setQuestionAttemptionError] = useState("");
  const [displayTestSubmitModal, setDisplayTestSubmitModal] = useState(false);
  const [submitConfirmationMessage, setSubmitConfirmationMessage] =
    useState("");

  const questionNotAttemptedMessage = "Please attempt above question";

  const handlePrevClick = () => {
    setQuestionAttemptionError((_) => "");
    if (currentQuestionNumber !== 0) {
      setCurrentQuestionNumber((_) => _ - 1);
    }
  };

  const handleNextClick = () => {
    if (responseSheet[currentQuestionNumber].option_id === null) {
      setQuestionAttemptionError((_) => questionNotAttemptedMessage);
      return;
    } else {
      setQuestionAttemptionError((_) => "");
    }

    if (currentQuestionNumber !== questions.length - 1) {
      setCurrentQuestionNumber((_) => _ + 1);
    }
  };

  const handleOptionSelect = (questionId, optionId) => {
    setQuestionAttemptionError((_) => "");
    let resonponseCopy = [...responseSheet];
    for (let i = 0; i < resonponseCopy.length; i++) {
      if (resonponseCopy[i].question_id === questionId) {
        resonponseCopy[i].option_id = optionId;
        break;
      }
    }
    responseSheetSetter((_) => [...resonponseCopy]);
  };

  const optionChecked = (questionId, optionId) => {
    if (
      responseSheet.find(
        (item) => item.question_id === questionId && item.option_id === optionId
      )
    )
      return true;
    return false;
  };

  const submitTestAndCloseModal = () => {
    submitTest();
    setDisplayTestSubmitModal((_) => false);
  };

  // Hide test submit modal
  const hideTestSubmitModal = () => {
    setDisplayTestSubmitModal(false);
  };

  const openTestModal = (e) => {
    e.preventDefault();
    if (responseSheet[currentQuestionNumber].option_id === null) {
      setQuestionAttemptionError((_) => questionNotAttemptedMessage);
      return;
    } else {
      setQuestionAttemptionError((_) => "");
    }

    setSubmitConfirmationMessage("Do you want to submit test ?");
    setDisplayTestSubmitModal(true);
  };

  return (
    <Paper
      variant="outlined"
      sx={{ p: 1, my: isSmallerScreen ? 1 : 0, my: 1, borderRadius: 2 }}
    >
      <Grid container sx={{ pt: 3, px: 1 }}>
        <Grid item xs={12} md={9}>
          <Typography fontWeight={"bold"} variant="body1">
            Q{currentQuestionNumber + 1}.{" "}
            {questions[currentQuestionNumber].question_text}
          </Typography>
        </Grid>
        <Grid item xs={12} md={3}>
          <Typography
            fontWeight={"bold"}
            variant="body1"
            align={isSmallerScreen ? "left" : "right"}
          >
            Marks: {questions[currentQuestionNumber].marks}
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ px: 1, position: "relative" }}>
        <RadioGroup name="use-radio-group" defaultValue="first" sx={{ my: 2 }}>
          {questions[currentQuestionNumber].options.map(
            (singleOption, optionIndex) => {
              return (
                <FormControlLabel
                  key={`option${optionIndex + 1}`}
                  value={singleOption.option_id}
                  label={singleOption.option_text}
                  control={<Radio />}
                  onChange={() =>
                    handleOptionSelect(
                      questions[currentQuestionNumber].question_id,
                      singleOption.option_id
                    )
                  }
                  checked={optionChecked(
                    questions[currentQuestionNumber].question_id,
                    singleOption.option_id
                  )}
                  sx={{ my: 0.5 }}
                />
              );
            }
          )}
        </RadioGroup>
        <Box sx={{ position: "absolute", bottom: -20 }}>
          <Typography
            className={"error-text"}
            variant="body2"
            color={"textSecondary"}
          >
            {questionAttemptionError}
          </Typography>
        </Box>
      </Box>

      <Box display={"flex"} justifyContent={"center"} sx={{ mt: 5 }}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <IconButton
            onClick={() => handlePrevClick()}
            className={`${currentQuestionNumber && "primary-text"}`}
            disabled={!currentQuestionNumber}
          >
            <ArrowCircleLeftIcon fontSize="large" />
          </IconButton>

          <Typography sx={{ mx: 0.5 }} variant="body1">
            Question {currentQuestionNumber + 1} OF {questions.length}
          </Typography>

          <IconButton
            size="large"
            className={`${
              currentQuestionNumber !== questions.length - 1 && "primary-text"
            }`}
            onClick={() => handleNextClick()}
            disabled={currentQuestionNumber === questions.length - 1}
          >
            <ArrowCircleRightIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
      <Box
        sx={{
          position: isSmallerScreen ? "inherit" : "relative", // Needed for absolute positioning of child elements
        }}
        display={isSmallerScreen ? "flex" : "block"}
        justifyContent={isSmallerScreen ? "center" : "block"}
      >
        {currentQuestionNumber === questions.length - 1 && (
          <>
            <Button
              variant="contained"
              sx={{
                position: isSmallerScreen ? "inherit" : "absolute",
                bottom: 0,
                right: 5,
              }}
              onClick={openTestModal}
              className={
                isSmallerScreen ? `primary-small-btn ` : `primary-btn `
              }
            >
              Submit
            </Button>

            <BootstrapDialog
              fullWidth
              onClose={hideTestSubmitModal}
              aria-labelledby="testSubmissionConfirmation"
              open={displayTestSubmitModal}
            >
              <ConfirmationModal
                modalHeader={"Test submission confirmation"}
                confirmModal={submitTestAndCloseModal}
                hideModal={hideTestSubmitModal}
                message={submitConfirmationMessage}
              />
            </BootstrapDialog>

            <BootstrapDialog
              fullWidth
              open={submittingTest}
              onClose={() => {}}
              aria-labelledby="submissionInProgress"
            >
              <SubmissionInProcessModal />
            </BootstrapDialog>
          </>
        )}
      </Box>
    </Paper>
  );
};

export default CourseExam;
