import React, { useEffect, useState } from "react";
import targetColumns from "../../../data/json/targetColumns.json";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import { DebounceInput } from "react-debounce-input";
import { createSearchParams } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { errorHandler } from "../../../utils/Request/ReqUtils";
import { useDispatch, useSelector } from "react-redux";
import { userRoles } from "../../../enums/Auth";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TargetForm from "../../../components/BdForms/TargetForm/TargetForm";
import getTableCell from "../../../utils/Common/DataTable";
import { getAllTargets } from "../../../redux/actions/bd-actions/targets.actions";

const Targets = () => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.userProfile.profileData);

  const targetState = useSelector((state) => state.targets);

  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[1].currentPage = true;

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [searched, setSearched] = useState("");
  const [targetColumnsState, setTargetColumnsState] = useState([]);
  const [isTargetFormOpen, setIsTargetFormOpen] = useState(false);


  const params = createSearchParams({
    search: searched,
    sort_order: order,
    sort_by: orderBy,
    page: currentPage + 1,
    rows_per_page: rowsPerPage,
  });

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const fetchData = async () => {
    try {
      dispatch(getAllTargets(params));
    } catch (err) {
      errorHandler(err, "Failed to fetch targets");
    }
  };

  const setAddTargetForm = () => {
    setIsTargetFormOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, [currentPage, rowsPerPage, searched, order, orderBy]);

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    if (profileData?.role_id === userRoles.User) {
      setTargetColumnsState(JSON.parse(JSON.stringify(targetColumns)).splice(1))
    }
    else {
      setTargetColumnsState(targetColumns)

    }
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isTargetFormOpen && <TargetForm isOpenProp={isTargetFormOpen} handleClose={() => setIsTargetFormOpen(false)} />}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Targets</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnGap={!isSmallerScreen ? 2 : 0}
              justifyContent={!isSmallerScreen ? "end" : "space-between"}
              alignItems={"center"}
            >
              {profileData?.role_id !== userRoles.User && <>
                <Grid my={1} item xs={12} md={"auto"}>
                  <Box className="search-container">
                    <DebounceInput
                      type="text"
                      placeholder="Search User"
                      className="search-input"
                      value={searched}
                      onChange={(e) => handleSearch(e)}
                      debounceTimeout={500}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={"auto"}
                >
                  <Button
                    variant="outlined"
                    onClick={() => setAddTargetForm()}
                    startIcon={<AddIcon />}
                  >
                    New Target
                  </Button>
                </Grid>
              </>}

            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {targetState?.targetsLoading ? (
            <LoadingSpinner />
          ) : targetState?.targets?.rows?.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow>
                      {targetColumnsState.map((col) => getTableCell(col, orderBy, order, handleSortRequest))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {targetState?.targets?.rows &&
                      targetState?.targets?.rows?.map((targetRow) => {
                        return (
                          <TableRow key={targetRow.id}>
                            <Tooltip title={targetRow.title}>
                              <TableCell
                                sx={{ maxWidth: "250px" }}
                                className="text-truncate"
                              >
                                {targetRow.user_name}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {targetRow.frequency}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {targetRow.time_period}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {targetRow.financial_year}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {targetRow.deals_count}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {targetRow.deals_amount}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {targetRow.description}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={targetState?.targets?.meta?.total || 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Targets;
