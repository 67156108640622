import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  modules: {},
  modulesLoading: false,
	cardsData: {},
  selectedModule: null,
  editModuleLoading: false,
  addModuleLoading: false,
  deleteModuleLoading: false,
  message: null,
  error: null,
};

const moduleSlice = createSlice({
  name: "modules",
  initialState,
  reducers: {
    getModulesRequest(state) {
      state.modulesLoading = true;
    },
    getModulesSuccess(state, action) {
      state.modules = action.payload;
      state.modules.rows.forEach((row) => {
        row.id = row.module_id;
      });
			const cardsData = {};
			state.modules?.cards?.forEach(card => {
				const count = parseInt(card.count, 10);
				if (card.card_name === "Total Courses") {
					cardsData.total_courses = count;
				} else if (card.card_name === "Active Courses") {
					cardsData.active_courses = count;
				} else if (card.card_name === "Total Learners") {
					cardsData.total_learners = count;
				} else if (card.card_name === "Active Learners") {
					cardsData.active_learners = count;
				}
			});
			state.cardsData = cardsData;
      state.modulesLoading = false;
    },
    getModulesFail(state) {
      state.modulesLoading = false;
    },

    getAllModulesRequest(state) {
      state.modulesLoading = true;
    },
    setSelectedModule(state, action) {
      const id = action.payload;
      state.selectedModule = state?.modules?.rows?.find((row) => row.id === id) || {};
    },
    editModuleRequest(state) {
      state.editModuleLoading = true;
    },
    editModuleSuccess(state, action) {
      const editedModule = action.payload;
      editedModule.id = editedModule.module_id;

			state.modules.rows = state.modules.rows.map((row) => {
					if (row.id === editedModule.id) {
					return editedModule;
					}
					return row;
			})
			state.editModuleLoading = false;
    },
    editModuleFail(state) {
      state.editModuleLoading = false;
    },
    addModuleRequest(state) {
      state.addModuleLoading = true;
    },
    addModuleSuccess(state, action) {
      const addedModule = action.payload;
      addedModule.id = addedModule.module_id;
      
      state.modules.rows = [addedModule, ...state.modules?.rows]
      state.modules.meta.total++;
      state.addModuleLoading = false;
    },
    addModuleFail(state) {
      state.addModuleLoading = false;
    },
    deleteModuleRequest(state) {
      state.deleteModuleLoading = true;
    },
    deleteModuleSuccess(state,action) {
      const deletedModuleId = action.payload;
      state.modules.rows = state.modules.rows.filter((row) => row.id !== deletedModuleId);
      state.modules.meta.total--;
      state.deleteModuleLoading = false;
    },
    deleteModuleFail(state) {
      state.deleteModuleLoading = false;
    },
    resetModules(state) {
      state.selectedModule = null;
      state.editModuleLoading = false;
      state.addModuleLoading = false;
      state.deleteModuleLoading = false;
    },
  },
});

export const moduleReducer = moduleSlice.reducer;

export const moduleActions = moduleSlice.actions;
