import React from "react";
import { Box } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const LoadingSpinner = () => {
  return (
    <Box display={"flex"} justifyContent={"center"}>
      <CircularProgress color="primary" />
    </Box>
  );
};

export default LoadingSpinner;
