import { assignmentActions } from "../../reducers/am-reducers/assignments.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { assignmentsAPI } from "../../../constants/apis/AM/AssignAssets";


const axiosInstance = createInstance();

/**
 * getAssignments - Get assignments data
 *
 * Returns Assignments data using the provided parameters
 *
 * @param {object} params
 */
export const getAssignments = (params) => async (dispatch) => {
    try {
        dispatch(assignmentActions.getAssignmentsRequest());

        const { data } = await axiosInstance.get(assignmentsAPI, {
            params: params,
        });


        dispatch(assignmentActions.getAssignmentsSuccess(data.data));
    } catch (error) {

        errorHandler(error, "Failed to get assignments");
        dispatch(assignmentActions.getAssignmentsFail());
    }
};