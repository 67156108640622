import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import { useDispatch, useSelector } from "react-redux";
import { inventoryAPI } from "../../../constants/apis/AM/Assets";
import moment from "moment";
import { toast } from "react-toastify";
import {
  searchOptionDebounceTime,
  asyncSelectNewCustomStyles,
  autoCloseToastError,
} from "../../../constants/Common";
import {
  setVendorOptions,
  setVendorOptionsLoaded,
} from "../../../features/optionSlice";
import {
  category,
  maintenance_frequency,
  criticality,
  sensitivity,
} from "../../../enums/AM/Asset";
import { assetSchema } from "../../../validations/AmForms/Asset";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import {
  setAssetNameOptions,
} from "../../../features/optionSlice";

import { convertParamsToString } from "../../../utils/Request";
import { accountApi } from "../../../constants/apis/BD/Account";
import { accountTypes } from "../../../enums/Account";

import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Asterisk from "../../Misc/Asterisk";

import { addAsset, editAssetById } from "../../../redux/actions/am-actions/assets.actions";

const AssetForm = ({ assetFormOpen, assetEditProp = false, isSmallerScreen, handleDrawerClose }) => {
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.userProfile.profileData);

  const vendorOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.vendorOptionsLoaded
  );
  const vendorOptions = useSelector(
    (state) => state.optionsForAsyncSelect.vendorOptions
  );

  const initialAssetData = {
    asset_name: "",
    category: "",
    vendor_id: "",
    service_provider_id: "",
    date_of_purchase: "",
    purchased_quantity: "",
    available_quantity: null,
    make: "",
    model: "",
    warranty_start_date: "",
    warranty_end_date: "",
    description: "",
    maintenance_frequency: "",
    criticality: "",
    sensitivity: "",
  };

  const [assetData, setAssetData] = useState(initialAssetData);
  const [assetFormErrors, setAssetFormErrors] = useState({});
  const [vendorName, setVendorName] = useState({
    value: ""
  });

  const [assetNameSelected, setAssetNameSelected] = useState(null);

  const assetNameOptions = useSelector(
    (state) => state.optionsForAsyncSelect.assetNameOptions
  );

  const assetsState = useSelector(state => state.assets);
  const selectedAssetData = assetsState.selectedAssetData;
  const addAssetLoading = assetsState.addAssetLoading;
  const editAssetLoading = assetsState.editAssetLoading;



  const fetchVendorOptions = async (inputvalue) => {
    const axiosInstance = createInstance(true);
    try {
      const response = await axiosInstance.get(
        accountApi + "/vendor-options?search=" + inputvalue
      );

      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = {};

          // Add additional data based on a condition
          if (item.account_type === accountTypes.Organization) {
            additionalData = {
              account_type: item.account_type,
              gstin: item.gstin,
            };
          } else if (item.account_type === accountTypes.Individual) {
            additionalData = {
              account_type: item.account_type,
              mobile: item.mobile,
              city: item.city,
            };
          }

          return {
            value: item.account_id,
            label: item.account_name,
            additionalData: additionalData, // Include additional data in the result
          };
        });
        return formattedOptions;
      } else {
        toast.error("Error while fetching vendor options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching vendor options");
    }
    return [];
  };

  const closeAssetForm = () => {
    handleDrawerClose();
    setAssetFormErrors({})
    setAssetData(initialAssetData);
  };

  const handleAssetForm = (e) => {
    setAssetFormErrors({ ...assetFormErrors, [e.target.name]: "" });
    setAssetData({ ...assetData, [e.target.name]: e.target.value });
  };

  const handleAssetSubmit = async (e) => {
    e.preventDefault();
    const userId = profileData?.user_id;
    if (!assetEditProp) {
      assetData["available_quantity"] = assetData["purchased_quantity"];
    }
    const payload = { ...assetData, created_by: userId };
    const validation = assetSchema.validate(assetData, { abortEarly: false });
    if (validation.error) {
      setAssetFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setAssetFormErrors(currentErrors);
    } else {

      let payloadAssetData = JSON.parse(JSON.stringify(payload));
      dispatch(addAsset(payloadAssetData,),)
      setAssetFormErrors({});

    }
  };

  const handleAssetEdit = async (e) => {
    e.preventDefault();
    const userId = profileData?.user_id;
    const payload = { ...assetData, updated_by: userId };
    const validation = assetSchema.validate(assetData, { aboutEarly: false });
    if (validation.error) {
      setAssetFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setAssetFormErrors(currentErrors);
    } else {
      let payloadAssetData = JSON.parse(JSON.stringify(payload));
      dispatch(editAssetById(selectedAssetData.id, payloadAssetData));
      setAssetFormErrors({});
    }
  };

  const handleAssetNameFocus = async () => {
    const fetchedOptions = await fetchAssetNames("");
    dispatch(setAssetNameOptions(fetchedOptions));
  };

  const fetchAssetNames = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      if (assetData.category === "") {
        assetData.category = " ";
      }
      const params = {
        category: assetData.category,
        type: "Asset",
        search: inputValue,
      };
      const response = await axiosInstance.get(
        inventoryAPI + convertParamsToString(params)
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => ({
          value: item.name,
          label: item.name,
        }));
        return formattedOptions;
      } else {
        toast.error("Error while fetching asset name options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "error while fetching asset name options");
    }
    return [];
  };

  const loadAssetNames = (inputValue, callback) => {
    fetchAssetNames(inputValue)
      .then((options) => callback(options))
      .catch((err) => {
        errorHandler(err, "Error while fetching asset names");
      });
  };

  const loadVendorOptions = async () => {
    if (!vendorOptionsLoaded) {
      const fetchedOptions = await fetchVendorOptions("");
      dispatch(setVendorOptionsLoaded(true));
      dispatch(setVendorOptions(fetchedOptions));
    }
  }

  const handleVendorFocus = async () => {
    loadVendorOptions();
  };

  useEffect(() => {

    const setAssetEditData = () => {
      //when the component opens, set the scroll position to the start
      setAssetFormErrors({});

      //setSelectedAssetData
      const editAssetData = {};
      for (let key in assetData) {
        editAssetData[key] = selectedAssetData[key] || "";
        if (
          key === "date_of_purchase" ||
          key === "warranty_start_date" ||
          key === "warranty_end_date"
        ) {
          const fetchedDate = selectedAssetData[key];
          editAssetData[key] = fetchedDate
            ? moment((new Date(fetchedDate)).toISOString()).format("YYYY-MM-DD")
            : "";
        }
      }


      setAssetData(editAssetData);
      setAssetNameSelected({
        label: selectedAssetData.asset_name,
        value: selectedAssetData.asset_id,
      });

      setVendorName({
        label: selectedAssetData.vendor_name,
        value: selectedAssetData.vendor_id,
      });
    };

    loadVendorOptions();

    if (assetEditProp) {
      //fetch Asset Data
      setAssetEditData();
    } else {
      setAssetData(initialAssetData);
      setAssetNameSelected("");
      setVendorName("");
    }
  }, [assetEditProp]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: { sm: "80vw", md: "70vw", lg: "60vw" },
            px: { xs: 2, md: 4 },
          },
        }}
        anchor={"right"}
        open={assetFormOpen}
        onClose={closeAssetForm}
      >
        <Box my={2} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h5">
            {assetEditProp ? "Edit " : "New "}
            Asset
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeAssetForm}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider className="horizontal-line" />


        <Box my={3}>
          <Grid
            container
            rowSpacing={{ xs: 1, md: 2 }}
            columnSpacing={{ xs: 0, md: 10, lg: 15 }}
          >
            {/* Category */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Category "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  size="small"
                  sx={{ width: "100%" }}
                  id="asset-category"
                  name="category"
                  value={assetData?.category}
                  onChange={(e) => handleAssetForm(e)}
                  error={Boolean(assetFormErrors.category)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {Object.keys(category).map((key) => (
                    <MenuItem key={key} value={category[key]}>
                      {category[key]}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(assetFormErrors.category) && (
                  <Typography variant="caption" color="error" p={2}>
                    {assetFormErrors.category}
                  </Typography>
                )}
              </Grid>
            </Grid>
            {/* Asset Name */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Asset Name "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <AsyncSelect
                  className={
                    Boolean(assetFormErrors.asset_name) ? "error-border" : ""
                  }
                  styles={asyncSelectNewCustomStyles}
                  value={assetNameSelected}
                  defaultOptions={assetNameOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setAssetFormErrors({
                        ...assetFormErrors,
                        ["asset_name"]: "",
                      });
                      setAssetData({
                        ...assetData,
                        asset_name: selectedOption.value,
                      });
                    } else {
                      setAssetData({ ...assetData, asset_name: "" });
                    }
                    setAssetNameSelected(selectedOption);
                  }}
                  loadOptions={debounce(
                    loadAssetNames,
                    searchOptionDebounceTime
                  )}
                  placeholder="Type to search..."
                  name="asset_name"
                  noOptionsMessage={() =>
                    "No Asset Names Found. Type again..."
                  }
                  isClearable
                  onFocus={handleAssetNameFocus}
                />
                {Boolean(assetFormErrors.asset_name) && (
                  <Typography variant="caption" color="error" p={2}>
                    {assetFormErrors.asset_name}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* Make */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Make "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  type="text"
                  id="asset-make"
                  name="make"
                  value={assetData.make}
                  onChange={(e) => handleAssetForm(e)}
                  error={Boolean(assetFormErrors.make)}
                  helperText={
                    Boolean(assetFormErrors.make) && assetFormErrors.make
                  }
                  placeholder="Make"
                />
              </Grid>
            </Grid>

            {/* Model */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Model "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  type="text"
                  id="asset-model"
                  name="model"
                  value={assetData.model}
                  onChange={(e) => handleAssetForm(e)}
                  placeholder="Model"
                  error={Boolean(assetFormErrors.model)}
                  helperText={
                    Boolean(assetFormErrors.model) && assetFormErrors.model
                  }
                />
              </Grid>
            </Grid>

            {/*Criticality*/}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Criticality "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  size="small"
                  sx={{ width: "100%" }}
                  id="asset-category"
                  name="criticality"
                  value={assetData?.criticality}
                  onChange={(e) => handleAssetForm(e)}
                  error={Boolean(assetFormErrors.criticality)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {Object.keys(criticality).map((key) => (
                    <MenuItem key={key} value={criticality[key]}>
                      {criticality[key]}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="error" p={2}>
                  {assetFormErrors.criticality}
                </Typography>
              </Grid>
            </Grid>

            {/* Sensitivity */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Sensitivity "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  size="small"
                  sx={{ width: "100%" }}
                  id="asset-category"
                  name="sensitivity"
                  value={assetData?.sensitivity}
                  onChange={(e) => handleAssetForm(e)}
                  error={Boolean(assetFormErrors.sensitivity)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {Object.keys(sensitivity).map((key) => (
                    <MenuItem key={key} value={sensitivity[key]}>
                      {sensitivity[key]}
                    </MenuItem>
                  ))}
                </Select>
                <Typography variant="caption" color="error" p={2}>
                  {assetFormErrors.sensitivity}
                </Typography>
              </Grid>
            </Grid>

            {/* Date Of Purchase */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Date of Purchase "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  type="date"
                  id="asset-date-of-purchase"
                  name="date_of_purchase"
                  value={assetData.date_of_purchase}
                  onChange={(e) => handleAssetForm(e)}
                  placeholder="Date of Purchase"
                  error={Boolean(assetFormErrors.date_of_purchase)}
                  helperText={assetFormErrors.date_of_purchase}
                />
              </Grid>
            </Grid>

            {/* Purchase Quantity*/}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Purchased Quantity "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  type="text"
                  id="purchased-quantity"
                  name="purchased_quantity"
                  value={assetData.purchased_quantity}
                  onChange={(e) => handleAssetForm(e)}
                  error={Boolean(assetFormErrors.purchased_quantity)}
                  helperText={assetFormErrors.purchased_quantity}
                  placeholder="Purchased Quantity"
                />
              </Grid>
            </Grid>

            {/* Maintenance Freq */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  Maintenance Freq.
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  size="small"
                  sx={{ width: "100%" }}
                  id="asset-category"
                  name="maintenance_frequency"
                  value={assetData?.maintenance_frequency}
                  onChange={(e) => handleAssetForm(e)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {Object.keys(maintenance_frequency).map((key) => (
                    <MenuItem key={key} value={maintenance_frequency[key]}>
                      {maintenance_frequency[key]}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>

            {/* Vendor*/}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Vendor "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  defaultValue={""}
                  size="small"
                  sx={{ width: "100%" }}
                  name="vendor_id"
                  onChange={(e) => {
                    setVendorName({
                      ...vendorName,
                      value: e.target.value,
                    })
                    handleAssetForm(e)
                  }}

                  onFocus={handleVendorFocus}
                  value={Boolean(vendorName?.value) && vendorOptionsLoaded ? vendorName?.value : ``}
                  error={Boolean(assetFormErrors.vendor_id)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {vendorOptions &&
                    vendorOptions.map((vendor) => {
                      return (
                        <MenuItem key={vendor.value} value={vendor.value}>
                          {vendor.label}
                        </MenuItem>
                      );
                    })}
                </Select>

                <Typography variant="caption" color="error" p={2}>
                  {assetFormErrors.vendor_id}
                </Typography>
              </Grid>
            </Grid>

            {/* Warranty Start Date*/}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Warranty Start Date "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  type="date"
                  id="asset-warranty-start-date"
                  name="warranty_start_date"
                  value={assetData.warranty_start_date}
                  onChange={(e) => handleAssetForm(e)}
                  placeholder="Warranty Start Date"
                  error={Boolean(assetFormErrors.warranty_start_date)}
                  helperText={assetFormErrors.warranty_start_date}
                />
              </Grid>
            </Grid>

            {/* Warranty end Date*/}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Warranty End Date "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  size="small"
                  sx={{ width: "100%" }}
                  type="date"
                  id="asset-warranty-end-date"
                  name="warranty_end_date"
                  value={assetData.warranty_end_date}
                  onChange={(e) => handleAssetForm(e)}
                  placeholder="Warranty End Date"
                  error={Boolean(assetFormErrors.warranty_end_date)}
                  helperText={assetFormErrors.warranty_end_date}
                />
              </Grid>
            </Grid>

            {/* Service Provider */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Service Provider "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  defaultValue={""}
                  size="small"
                  sx={{ width: "100%" }}
                  name="service_provider_id"
                  onChange={(e) => handleAssetForm(e)}
                  onFocus={handleVendorFocus}
                  value={Boolean(assetData?.service_provider_id) && vendorOptionsLoaded ? assetData?.service_provider_id : ""}
                  error={Boolean(assetFormErrors.service_provider_id)}
                >
                  <MenuItem value="">Select</MenuItem>
                  {vendorOptions &&
                    vendorOptions.map((vendor) => {
                      return (
                        <MenuItem key={vendor.value} value={vendor.value}>
                          {vendor.label}
                        </MenuItem>
                      );
                    })}
                </Select>

                <Typography variant="caption" color="error" p={2}>
                  {assetFormErrors.service_provider_id}
                </Typography>
              </Grid>
            </Grid>

            {/* Description */}
            <Grid item container xs={12}>
              {/* Description */}
              <Grid item container xs={12}>
                <Grid item xs={12} lg={2.2}>
                  <InputLabel className={"fw-bold"}>
                    {"Description "}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <TextField
                    id="course-description"
                    name="description"
                    placeholder="Description"
                    value={assetData.description}
                    onChange={(e) => handleAssetForm(e)}
                    sx={{ mb: 2, width: "100%" }}
                    className={"fw-bold"}
                    size="large"
                    multiline
                  />
                </Grid>
                {!isSmallerScreen && <Grid item lg={2.8}></Grid>}
                <Typography variant="body2" my={2}>
                  <Asterisk />
                  {" Mandatory Fields"}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>


        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"end"}
          bottom={0}
        >
          <Divider className="horizontal-line" sx={{ my: 1 }} />

          <Box my={3} display={"flex"} columnGap={5}>

            <Button
              variant="contained"
              type="submit"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={(e) => {
                if (assetEditProp) {
                  handleAssetEdit(e);
                } else handleAssetSubmit(e);
              }}

              disabled={addAssetLoading || editAssetLoading}
              endIcon={
                (addAssetLoading || editAssetLoading) && (
                  <CircularProgress size={25} />
                )
              }
            >
              Save
            </Button>

            <Button
              variant="contained"
              type="button"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={closeAssetForm}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default AssetForm;
