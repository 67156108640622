import { contactActions } from "../../reducers/bd-reducers/contacts.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { contactApi } from "../../../constants/apis/BD/Contact";
import { toast } from "react-toastify";
import { timeFrameParam } from "../../../constants/queries.js";

const axiosInstance = createInstance();

/**
 * getDeals - Get key contacts data
 *
 * Returns Key contacts data using the provided parameters
 *
 * @param {object} timeFrame
 */
export const getKeyContacts = (timeFrame) => async (dispatch) => {
  try {
    dispatch(contactActions.getKeyContactsRequest());

    const { data } = await axiosInstance.get(
      `business-development/dashboard/dashboard-key-contacts`,
      {
        params: timeFrameParam(timeFrame)
      }
    );
    dispatch(contactActions.getKeyContactsSuccess(data.data));
  } catch (error) {

    errorHandler(error, "Failed to get key contacts");
    dispatch(contactActions.getKeyContactsFail());
  }
};

/**
 * getDeals - Get key contacts data
 *
 * Returns Contacts data using the provided parameters
 *
 * @param {object} params
 */
export const getContacts = (params) => async (dispatch) => {
  try {
    dispatch(contactActions.getContactsRequest());

    const { data } = await axiosInstance.get(contactApi, {
      params: params,
    });

    dispatch(contactActions.getContactsSuccess(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get contacts");
    dispatch(contactActions.getContactsFail());
  }
};

/**
 * getDeals - Get account specific contacts data
 *
 * Returns Deals data using the provided parameters
 *
 * @param {object} accountId
 * @param {object} params
 */
export const getAccountSpecificContacts = (accountId, params) => async (dispatch) => {
  try {
    dispatch(contactActions.getAccountSpecificContactsRequest());

    const { data } = await axiosInstance.get(contactApi.concat("/account/").concat(accountId) + params);

    dispatch(contactActions.getAccountSpecificContactsSuccess(data.data));
  } catch (error) {

    errorHandler(error.response.data.message, "Failed to get contacts");
    dispatch(contactActions.getAccountSpecificContactsFail());
  }
};

/**
 * editContactById - Edit a contact by ID.
 *
 * Edits a contact with the specified ID using the provided payload.
 *
 * @param {string} id - The ID of the contact to be edited.
 * @param {object} payload - The data to update the contact with.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const editContactById = (id, payload, accountSpecific) => async (dispatch) => {
  try {
    dispatch(contactActions.editContactRequest());

    const { data } = await axiosInstance.put(`${contactApi}/${id}`, payload);

    if (data.success) {
      dispatch(
        contactActions.editContactSuccess({ editedContact: { ...data.data, contact_id: id }, accountSpecific })
      );
      dispatch(
        contactActions.editKeyContactsSuccess({ ...data.data, contact_id: id })
      );
      toast.success("Contact updated successfully");
    }
  } catch (error) {

    errorHandler(error, "Failed to edit contact");
    dispatch(contactActions.editContactFail());
  }
};

/**
 * addContact - Add a new contact.
 *
 * Adds a new contact using the provided payload.
 *
 * @param {object} payload - The data for the new contact.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const addContact = (payload, accountSpecific) => async (dispatch) => {
  try {
    dispatch(contactActions.addContactRequest());

    const { data } = await axiosInstance.post(`${contactApi}`, payload);

    if (data.success) {
      dispatch(
        contactActions.addContactSuccess({
          addedContact: {
            ...data.data,
            id: data.data.contact_id,
          },
          accountSpecific
        })
      );
      toast.success("Contact added successfully");
    }
  } catch (error) {
    errorHandler(error, "Failed to add contact");
    dispatch(contactActions.addContactFail());
  }
};

/**
 * deleteContactById - deletes a contact by id
 * @param {string} id
 * @param {string} accountSpecific
 */
export const deleteContactById = (id, accountSpecific) => async (dispatch) => {
  try {
    dispatch(contactActions.deleteContactRequest());

    const deleteResponse = await axiosInstance.delete(contactApi.concat(id));
    if (deleteResponse.data.success) {
      dispatch(contactActions.deleteContactSuccess({ id, accountSpecific }));
      toast.success("Contact deleted successfully");

      dispatch(getContacts());
    }
  } catch (error) {
    dispatch(contactActions.deleteContactFail());
    errorHandler(error, "Failed to get contacts");
  }
};