import React, { useState, useEffect } from "react";
import { navAppNames } from "../../../constants/AppNames";
import elSidebarMenus from "../../../data/json/elSidebarMenus.json";
import { autoCloseToastError } from "../../../constants/Common";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { toast } from "react-toastify";
import courseAssignmentColumns from "../../../data/json/courseAssignmentColumns.json";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { courseApi } from "../../../constants/apis/EL/Course";
import { useNavigate, useParams } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";

const CourseAssignment = () => {
  const appSidebar = JSON.parse(JSON.stringify(elSidebarMenus));
  let { courseId } = useParams() || undefined;
  const navigate = useNavigate();

  const [rowsLoading, setRowsLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [courseName, setCourseName] = useState(null);

  const isSelected = (id) => selectedRows.includes(id);

  const handleSelect = (id) => {
    const currentSelectedRows = [...selectedRows];
    if (!currentSelectedRows.includes(id)) {
      currentSelectedRows.push(id);
      setSelectedRows(currentSelectedRows);
      return;
    }
    currentSelectedRows.splice(selectedRows.indexOf(id), 1);
    setSelectedRows(currentSelectedRows);
  };

  const handleSelectAllClick = (e) => {
    if (e.target.checked) {
      setSelectedRows((_) => rows.map((row) => row.id));
      return;
    }

    setSelectedRows((_) => []);
  };

  const requestData = async (courseId) => {
    setRowsLoading(true);
    const axiosInstance = createInstance(true);
    await axiosInstance
      .get(courseApi + `unassigned-course-accounts/${courseId}`)
      .then((res) => {
        const accountResult = res.data.data;
        let accountRows = [];

        if (accountResult.courseName && accountResult.accounts) {
          accountRows = accountResult.accounts;
          setCourseName((_) => accountResult.courseName);
        } else {
          toast.error("Course doesn't exists", {
            autoClose: autoCloseToastError,
          });
        }

        // adds required fields
        accountRows.forEach((currentRow) => {
          currentRow.id = currentRow.account_id;
        });
        setRows(accountRows);
      })
      .catch((err) => {
        setRows([]);
        errorHandler(err, "Failed to fetch accounts");
      });
    setRowsLoading(false);
  };

  const assignCoursesToAccounts = async () => {
    if (selectedRows.length === 0) {
      toast.error("Please select accounts", { autoClose: autoCloseToastError });
    } else {
      const axiosInstance = createInstance(true);
      await axiosInstance
        .put(courseApi + `assigncourses`, {
          course_id: courseId,
          accounts: selectedRows,
        })
        .then((res) => {
          if (!res.data.success) {
            toast.error("Failed to assign courses to accounts", {
              autoClose: autoCloseToastError,
            });
          } else {
            toast.success(
              "Course is successfully assigned to selected accounts!"
            );
            requestData(courseId);
          }
        })
        .catch((err) => {
          errorHandler(err, "Failed to assign courses to accounts");
        });
      setSelectedRows([]);
    }
  };

  const getTableHeadCell = (columnInformation) => {
    return (
      <TableCell key={columnInformation.id}>
        <TableSortLabel>{columnInformation.name}</TableSortLabel>
      </TableCell>
    );
  };

  useEffect(() => {
    if (parseInt(courseId)) {
      requestData(courseId);
    } else {
      navigate(-1);
    }
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Typography variant="h6">Course Assignment</Typography>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {rowsLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Grid container alignItems={"center"} mb={2}>
                <Grid item>
                  <Typography
                    variant="body1"
                    fontWeight={"bold"}
                    color={"textSecondary"}
                    sx={{ mr: 2 }}
                  >
                    Course -
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2">
                    {courseName ? courseName : ""}
                  </Typography>
                </Grid>
              </Grid>
              {rows.length === 0 ? (
                <Typography variant="body1">
                  Course is already assigned to all ENX customer accounts ...
                </Typography>
              ) : (
                <>
                  <TableContainer>
                    <Table size="medium">
                      <TableHead className="stat-card-bg">
                        <TableRow>
                          <TableCell className="common-head-table-cell table-cell-head-checkbox">
                            <Checkbox
                              className="common-table-checkbox"
                              checked={
                                rows.length > 0 &&
                                selectedRows.length === rows.length
                              }
                              onChange={handleSelectAllClick}
                            />
                          </TableCell>
                          {courseAssignmentColumns.map((assetCol) =>
                            getTableHeadCell(assetCol)
                          )}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {rows &&
                          rows.map((courseAssignmentRow) => {
                            const isItemSelected = isSelected(
                              courseAssignmentRow.id
                            );
                            return (
                              <TableRow key={courseAssignmentRow.id}>
                                <TableCell
                                  sx={{ width: "120px" }}
                                  color="primary"
                                >
                                  <Checkbox
                                    className="common-table-checkbox"
                                    checked={isItemSelected}
                                    onChange={() =>
                                      handleSelect(courseAssignmentRow.id)
                                    }
                                  />
                                </TableCell>

                                <TableCell
                                  sx={{ maxWidth: "100%" }}
                                  className="text-truncate"
                                >
                                  {courseAssignmentRow.account_name}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </>
              )}
              <Divider className="horizontal-line" sx={{ my: 1 }} />
              <Button
                variant="contained"
                className="primary-btn"
                disabled={selectedRows.length === 0 || courseName === null}
                onClick={() => assignCoursesToAccounts()}
              >
                Assign
              </Button>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default CourseAssignment;
