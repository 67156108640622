const searchOptionDebounceTime = 300;
//time in miliseconds
const toastAutocloseAfter = 2000;
//sets whether error should close after toastAutocloseAfter time
const autoCloseToastError = false; 
const minCharsToLoadOptions = 1;
const userLogout = 'USER_LOGOUT';

const asyncSelectCustomStyles = {
    control: (provided) => ({
        ...provided,
        minHeight: "30px",
        maxHeight: "35px",
        minWidth: "210px",
    }),
    input: (provided) => ({
        ...provided,
        minHeight: "30px",
        maxHeight: "35px",
        marginTop: "-5px",
        color: "#0B1F36",
        font: "normal normal normal 14px/18px Red Hat Display",
    }),
    singleValue: (provided) => ({
        ...provided,
        color: "#0B1F36",
        font: "normal normal normal 14px/18px Red Hat Display",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        font: "normal normal normal 14px/18px Red Hat Display",
        color: "black",
        opacity: 0.5
      }),
};

const asyncSelectNewCustomStyles = {
    control: (provided, state) => ({
        ...provided,
        width: "100%",
        borderColor: state.selectProps.className.includes("async-select-error") ? "#d32f2f" : "hsl(0, 0%, 80%)",        
        "&:hover": {
        borderColor: state.selectProps.className.includes("async-select-error") ? "" : "black",
        },
        "&:focus-within": {
        borderColor: state.selectProps.className.includes("async-select-error") ? "#d32f2f" : "#1976d2",
        boxShadow: state.selectProps.className.includes("async-select-error") ? "0 0 0 1px #d32f2f" : "0 0 0 1px #1976d2",
        opacity: 1
        },
    }),
    input: (provided) => ({
        ...provided,
    
        color: "#0B1F36",
        font: "normal normal normal 14px/18px Red Hat Display",
    }),

    singleValue: (provided) => ({
        ...provided,
        color: "#0B1F36",
        font: "normal normal normal 14px/18px Red Hat Display",
    }),
    placeholder: (provided, state) => ({
        ...provided,
        font: "normal normal normal 14px/18px Red Hat Display",
        color: "black",
        opacity: 0.5
      }),
};

export { searchOptionDebounceTime, minCharsToLoadOptions, toastAutocloseAfter, autoCloseToastError, asyncSelectCustomStyles, asyncSelectNewCustomStyles, userLogout };
