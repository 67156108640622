const type = Object.freeze({
    change: 'Change',
    problem: 'Problem'
});

const priority = Object.freeze({
    low: 'Low',
    medium: 'Medium',
    high: 'High'
});

const status = Object.freeze({
    open: 'Open',
    in_progres: 'In progress',
    closed: 'Closed',
    waiting_for_input: 'Waiting for input'
});

const assign_to = Object.freeze({
    user: 'User',
    vendor: 'Vendor',
})

const ticket_stages = Object.freeze({
    openTickets: "Open Tickets",
    overdueTickets: "Overdue Tickets",
    resolvedTickets: "Resolved Tickets",
    unassignedTickets: "Unassigned Tickets",
});

const months = Object.freeze({
    january: 'January',
    february: 'February',
    march: 'March',
    april: 'April',
    may: 'May',
    june: 'June',
    july: 'July',
    august: 'August',
    september: 'September',
    october: 'October',
    november: 'November',
    december: 'December',
});

module.exports = {type, priority, status, assign_to, ticket_stages, months};