import { List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { userRoles } from "../../enums/Auth";

const BDLinks = () => {
  const profileData = useSelector((state) => state.userProfile.profileData);
  return (
    <>
      <List sx={{ py: "0", px: 1 }}>
        {[userRoles.SuperAdmin, userRoles.Admin, userRoles.User].includes(profileData?.role_id) && <>
          {/* Dashboard */}
          <NavLink
          to={"/bd/dashboard"}
          className={({ isActive }) =>
            isActive || window.location.pathname === "/bd"
              ? "active-link"
              : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/dashboard-icon.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
          </NavLink>

          {/* Targets */}
          <NavLink
          to={"/bd/targets"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/sales-report.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Targets"} />
          </ListItem>
          </NavLink>

          {/* Accounts */}
          <NavLink
          to={"/bd/accounts"}
          className={({ isActive }) =>
            isActive || window.location.pathname.indexOf("/bd/account") !== -1
              ? "active-link"
              : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/accounts-icon.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Accounts"} />
          </ListItem>
          </NavLink>

          {/* Deals */}
          <NavLink
          to={"/bd/deals"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/deals-icon.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Deals"} />
          </ListItem>
          </NavLink>

          {/* Contacts */}
          <NavLink
          to={"/bd/contacts"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/contacts-icon.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Contacts"} />
          </ListItem>
          </NavLink>

          {/* Tasks */}
          <NavLink
          to={"/bd/tasks"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/task-add.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Tasks"} />
          </ListItem>
          </NavLink>

          {/* Reports */}
          <NavLink
          to={"/bd/reports"}
          className={({ isActive }) =>
            isActive ||
            window.location.pathname.indexOf("/bd/report-details") !== -1
              ? "active-link"
              : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img src="/assets/images/sales-report.svg" height={"20px"} />
            </ListItemIcon>
            <ListItemText primary={"Reports"} />
          </ListItem>
          </NavLink>

        </>}

          {/* Import Users */}
        {[userRoles.SuperAdmin, userRoles.Admin].includes(profileData?.role_id) &&
          <NavLink
          to={"/bd/import-users"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img
                src="/assets/images/import_file.png"
                style={{ filter: "invert(1)" }}
                height={"20px"}
              />
            </ListItemIcon>
            <ListItemText primary={"Import Users"} />
          </ListItem>
          </NavLink>
        }

        {/* Import Locations */}
        {[userRoles.SuperAdmin].includes(profileData?.role_id) &&
          <NavLink
          to={"/bd/import-locations"}
          className={({ isActive }) =>
            isActive ? "active-link" : "sideNavLink"
          }
        >
          <ListItem>
            <ListItemIcon>
              <img
                src="/assets/images/import_file.png"
                style={{ filter: "invert(1)" }}
                height={"20px"}
              />
            </ListItemIcon>
            <ListItemText primary={"Import Locations"} />
          </ListItem>
          </NavLink>
        }

      </List>
    </>
  );
};

export default BDLinks;
