import { locationActions } from "../../reducers/bd-reducers/location.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { accountLocationApi } from "../../../constants/apis/BD/AccountLocation";
import { toast } from "react-toastify";

const axiosInstance = createInstance();

export const getAllLocations = (params) => async (dispatch) => {
  try {
    dispatch(locationActions.getLocationsRequest());

    const { data } = await axiosInstance.get(`${accountLocationApi}${params}`);

    if(data.success) {
      dispatch(locationActions.getLocationsSuccess(data.data));
    }
      
  } catch (error) {
    errorHandler(error, "Failed to get tasks");
    dispatch(locationActions.getLocationsFail(error.response.data.message));
  }
};

export const editLocationById = (id, payload) => async (dispatch) => {
  try {
    dispatch(locationActions.editLocationRequest());

    const { data } = await axiosInstance.put(`${accountLocationApi}/${id}`, payload);

    if(data.success) {
      dispatch(locationActions.editLocationSuccess(data.data));
      toast.success("Location updated successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to edit location");
    dispatch(locationActions.editLocationFail(error.response.data.message));
  }
};

export const addLocation = (payload) => async (dispatch) => {
  try {
    dispatch(locationActions.addLocationRequest());

    const { data } = await axiosInstance.post(`${accountLocationApi}`, payload);

    if(data.success) {
      dispatch(locationActions.addLocationSuccess(data.data));
      toast.success("Location added successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to add location");
    dispatch(locationActions.editLocationFail(error.response.data.message));
  }
};
