import axios from "axios";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../constants/Common";

function createInstance() {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_BACKEND_BASEURL,
        withCredentials: true
    });
    return axiosInstance;
}

function navigateToLogin(errorMessage) {
    const items = ["jwt_token", "user_id", "first_name", "last_name", "role", "account_id", "account_name"];
    items.forEach((element) => {
        localStorage.removeItem(element);
    });
    toast.error(errorMessage, { autoClose: autoCloseToastError });
    setTimeout(() => {
        window.location.href = "/";
    }, 1000);  
}

function errorHandler(error, customMessage) {
    if (error.response !== null && error.response !== undefined) {
        if (error.response.status === 401) {
            navigateToLogin(error.response.data.message);
        } else {
            toast.error(error.response?.data);
        }
    } else {
        toast.error(customMessage, {autoClose: autoCloseToastError});
    } 
}

export { createInstance, navigateToLogin, errorHandler };
