const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    assetEdit: false,
    assetId: null,
    assetEditOpenToggle: false,
    assetAddOpenToggle: false,
    assetAdded: false,
    assetEdited: false,
}

export const formSlice = createSlice({
    name:"assetForm",
    initialState,
    reducers:{
        setAssetEdit : (state, action)=>{
            state.assetEdit = action.payload;
        },
        setAssetId : (state, action)=>{
            state.assetId=action.payload;
        },
        setAssetEditOpenToggle:(state,action)=>{
            state.assetEditOpenToggle=!state.assetEditOpenToggle;
        },
        setAssetAddOpenToggle:(state,action)=>{
            state.assetAddOpenToggle=!state.assetAddOpenToggle;
        },
        setAssetAdded:(state,action)=>{
            state.assetAdded=action.payload;
        },
        setAssetEdited:(state,action)=>{
            state.assetEdited=action.payload;
        }
}
});
export const {setAssetEdit, setAssetId, setAssetAddOpenToggle, setAssetEditOpenToggle, setAssetAdded, setAssetEdited}=formSlice.actions;
export default formSlice.reducer;