import { useSelector } from "react-redux";

import { userRoles } from "../../../enums/Auth";
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  Drawer,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import moment from "moment";

const AssetDetails = ({ assetDetailsOpen, handleDrawerClose, dashboard = true }) => {

  const profileData = useSelector((state) => state.userProfile.profileData);

  const role = parseInt(profileData?.role_id);

  const assetData = useSelector(state => state.assets.selectedAssetData);

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: { sm: "90vw", lg: "70vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      open={assetDetailsOpen}
      onClose={handleDrawerClose}
    >
      <Box
        my={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">Asset Details</Typography>
        <IconButton
          aria-label="close"
          onClick={handleDrawerClose}
          color="primary"
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" />

      <Box my={3}>

        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Asset Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1"> {assetData?.asset_name}</Typography>
            </Grid>
          </Grid>
          {dashboard && (
            <>
              <Grid item container xs={12} md={6}>
                <Grid item xs={12} md={4} pr={2}>
                  <Typography
                    variant="body1"
                    fontWeight={"bold"}
                    color={"textSecondary"}
                  >
                    Maintenance Frequency
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="body1">
                    {assetData?.maintenance_frequency}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Category
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1"> {assetData?.category}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Date of Purchase
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1">
                {" "}
                {moment(assetData?.date_of_purchase).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Date of Creation
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1">
                {" "}
                {moment(assetData?.created_at).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
          </Grid>
          {!dashboard && role !== userRoles.User && (
            <>
              <Grid item container xs={12} md={6}>
                <Grid item xs={12} md={4} pr={2}>
                  <Typography
                    variant="body1"
                    fontWeight={"bold"}
                    color={"textSecondary"}
                  >
                    Purchased Quantity
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="body1">
                    {assetData?.purchased_quantity}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Make
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1">{assetData?.make}</Typography>
            </Grid>
          </Grid>
          {!dashboard && role !== userRoles.User && (
            <>
              <Grid item container xs={12} md={6}>
                <Grid item xs={12} md={4} pr={2}>
                  <Typography
                    variant="body1"
                    fontWeight={"bold"}
                    color={"textSecondary"}
                  >
                    Available Quantity
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="body1">
                    {assetData?.available_quantity}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Model
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1">{assetData?.model}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Warranty Start Date
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1">
                {moment(assetData?.warranty_start_date).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Warranty End Date
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1">
                {moment(assetData?.warranty_end_date).format("DD/MM/YYYY")}
              </Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Vendor Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1">{assetData?.vendor_name}</Typography>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} md={4} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
              >
                Service Provider
              </Typography>
            </Grid>
            <Grid item xs={12} md={8}>
              <Typography variant="body1">
                {assetData?.provider_name}
              </Typography>
            </Grid>
          </Grid>
          {assetData?.description && (
            <>
              <Grid item container xs={12}>
                <Grid item xs={12} md={4} pr={2}>
                  <Typography
                    variant="body1"
                    fontWeight={"bold"}
                    color={"textSecondary"}
                  >
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={12} md={8}>
                  <Typography variant="body1">
                    {assetData?.description}
                  </Typography>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

      </Box>
      <Divider className="horizontal-line" sx={{ my: 1 }} />
    </Drawer>
  );
};

export default AssetDetails;
