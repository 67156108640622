import "./ConsumableDetails.scss";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  IconButton,
  Divider,
  Grid,
  Drawer,
} from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import moment from "moment";

const ConsumableDetails = ({ isConsumableDetailsOpen, handleDetailsClose, isSmallerScreen }) => {

  const consumableData = useSelector(state => state.consumables.selectedConsumableData);

  return (
    <Drawer
      anchor="right"
      PaperProps={{
        sx: {
          width: { sm: "90vw", lg: "70vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      open={isConsumableDetailsOpen}
      onClose={() => handleDetailsClose()}
    >
      <Box
        my={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">Consumable Details</Typography>
        <IconButton
          aria-label="close"
          onClick={() => handleDetailsClose()}
          color="primary"
        >
          <CloseRoundedIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" orientation="horizontal" />

      <Box my={3}>

        <Grid
          container
          columnSpacing={{
            sm: 10,
            md: 10,
            lg: 0,
          }}
        >
          <Grid item container xs={12} sm={5.8}>
            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Consumable Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableData.consumable_name}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Make
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableData.make}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Date of Purchase
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {moment(consumableData.date_of_purchase).format('DD/MM/YYYY')}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Purchased Quantity
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableData.purchased_quantity}
              </Typography>
            </Grid>
          </Grid>

          <Grid item container xs={12} sm={5.8}>
            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Category
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableData?.category}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Model
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableData.model}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                Vendor Name
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableData.vendor_name}
              </Typography>
            </Grid>

            <Grid item xs={12} md={3.5} lg={3} pr={2}>
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color={"textSecondary"}
                mb={!isSmallerScreen && 3}
              >
                In Stock
              </Typography>
            </Grid>
            <Grid item xs={12} md={8.5} lg={9}>
              <Typography variant="body1" mb={isSmallerScreen ? 2 : 3}>
                {consumableData.in_stock}
              </Typography>
            </Grid>
          </Grid>

          <Grid item xs={12} my={3}>
            <Divider className="horizontal-line" sx={{ mb: 3 }} />
            {consumableData.description && (
              <Box>
                <Typography
                  variant="body1"
                  fontWeight={"bold"}
                  color={"textSecondary"}
                  mb={!isSmallerScreen && 3}
                >
                  Description
                </Typography>
                <Typography variant="body2" sx={{ my: 1 }}>
                  {consumableData.description}
                </Typography>
              </Box>
            )}
          </Grid>
        </Grid>

      </Box>
      {consumableData.description && (
        <Divider className="horizontal-line" sx={{ mb: { xs: 3, md: 0 } }} />
      )}
    </Drawer>
  );
};

export default ConsumableDetails;
