const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    consumableAssignmentIdForDetails : null, 
    consumableAssignmentDetailsOpen : false
}

export const formSlice = createSlice({
    name: "consumableAssignmentDetails",
    initialState,
    reducers: {
        setConsumableAssignmentIdForDetails: (state, action) => {
            state.consumableAssignmentIdForDetails = action.payload;
        },

        setConsumableAssignmentDetailsOpen: (state, action) => {
            state.consumableAssignmentDetailsOpen = action.payload;
        }

    }
})

export const { setConsumableAssignmentIdForDetails , setConsumableAssignmentDetailsOpen } = formSlice.actions;

export default formSlice.reducer;