import React, { useEffect, useState } from "react";
import BdCounts from "../../../components/BdCounts/BdCounts";
import taskColumns from "../../../data/json/taskColumns.json";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import { useDispatch, useSelector } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import { types } from "../../../enums/Task";
import { taskApi } from "../../../constants/apis/BD/Task";
import { createSearchParams } from "react-router-dom";
import {
  setTaskEdit,
  setTaskId,
  setTaskEditOpenToggle,
  setTaskAddOpenToggle,
  setTaskAdded,
  setTaskEdited,
  setTaskAddAccountSpecific,
} from "../../../features/BD/taskFormSlice";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { navAppNames } from "../../../constants/AppNames";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TaskForm from "../../../components/BdForms/TaskForm/TaskForm";
import TableMenu from "../../../components/TableMenu/TableMenu";
import getTableCell from "../../../utils/Common/DataTable";
import { deleteTaskById, getAllTasks } from "../../../redux/actions/bd-actions/tasks.action";
import { taskActions } from "../../../redux/reducers/bd-reducers/tasks.reducer";

const Tasks = () => {
  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[5].currentPage = true;

  const axiosInstance = createInstance(true);
  const dispatch = useDispatch();
  const taskAdded = useSelector((state) => state.taskForm.taskAdded);
  const taskEdited = useSelector((state) => state.taskForm.taskEdited);
  const tasksState = useSelector((state) => state.tasks);

  const initialTaskCountCards = [
    {
      type: "main",
      iconImagePath: "/assets/images/tasks_big.svg",
      altText: "tasks-icon",
      count: 0,
      textLabel: "Total Tasks",
    },
    {
      type: "normal",
      count: 0,
      textLabel: types.Marketing,
    },
    {
      type: "normal",
      count: 0,
      textLabel: types.Support,
    },
    {
      type: "normal",
      count: 0,
      textLabel: types.Sales,
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [countCards, setCountCards] = useState(initialTaskCountCards);
  const [rowsLoading, setRowsLoading] = useState(true);

  const params = createSearchParams({
    search: searched,
    sort_order: order,
    sort_by: orderBy,
    page: currentPage + 1,
    rows_per_page: rowsPerPage,
  });

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const setAddTaskForm = () => {
    setTaskEditProp(false);
    setIsTaskFormOpen(true);
  };

  const fillDataInForm = (id) => {
    dispatch(taskActions.setSelectedTask(id));
    setTaskEditProp(true);
    setIsTaskFormOpen(true);
  };

  const fetchData = async () => {
    dispatch(getAllTasks(params));
  };

  const deleteRow = async (id) => {
    try {
      dispatch(deleteTaskById(id));
    } catch (err) {
      errorHandler(err, "Task deletion failed");
    }
  };

  useEffect(() => {
    if (tasksState) {
      const taskRows = tasksState?.allTasks?.rows;
      setRows(taskRows);
      //sets the cards
      const responseCardData = tasksState?.allTasks?.cards;
      const currentCountCards = [...initialTaskCountCards];
      currentCountCards[0].count = tasksState?.allTasks?.meta?.total;
      // Create a map of task types
      const taskTypeMap = {};
      for (const card of responseCardData || []) {
        taskTypeMap[card.task_type] = card.count;
      }

      // Update currentCountCards based on responseCardData
      for (let i = 1; i < currentCountCards.length; i++) {
        const count = taskTypeMap[currentCountCards[i].textLabel];
        if (count !== undefined) {
          currentCountCards[i].count = count;
        }
      }
      setCountCards(currentCountCards);
    }
  }, [tasksState])


  useEffect(() => {
    fetchData();
  }, [currentPage, rowsPerPage, searched, order, orderBy]);

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);
  const [taskEditProp, setTaskEditProp] = useState(false);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isTaskFormOpen && (
          <TaskForm
            isOpenProp={isTaskFormOpen}
            handleClose={() => setIsTaskFormOpen(false)}
            taskEditProp={taskEditProp}
          />
        )}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Tasks</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnSpacing={2}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Grid my={1} item xs={12} md={"auto"}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={"auto"}>
                <Button
                  variant="outlined"
                  onClick={() => setAddTaskForm()}
                  startIcon={<AddIcon />}
                >
                  New Task
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          <Box mb={3}>
            <BdCounts cards={countCards} />
          </Box>
          {tasksState?.allTasksLoading ? (
            <LoadingSpinner />
          ) : tasksState?.allTasks?.rows && tasksState?.allTasks?.rows?.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow>
                      {taskColumns.map((col) => getTableCell(col, orderBy, order, handleSortRequest))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tasksState?.allTasks?.rows &&
                      tasksState?.allTasks?.rows?.map((taskRow) => {
                        return (
                          <TableRow key={taskRow.id}>
                            <Tooltip title={taskRow.task_title}>
                              <TableCell
                                sx={{ maxWidth: "250px" }}
                                className="text-truncate primary-text link-hover"
                                onClick={() => fillDataInForm(taskRow.id)}
                              >
                                {taskRow.task_title}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {taskRow.task_type}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {taskRow.account_name}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {taskRow.status}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {taskRow.assigned_to_name}
                            </TableCell>

                            <TableCell align="right">
                              <TableMenu
                                fillDataInForm={fillDataInForm}
                                rowId={taskRow.id}
                                isDeleteOptionEnabled={true}
                                deleteModalData={['Task', taskRow.task_title]}
                                handleDeleteRow={() => deleteRow(taskRow.id)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={tasksState?.allTasks?.meta?.total || 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Tasks;
