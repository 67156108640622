import React, { useEffect, useRef, useState } from "react";
import "./ImportLocations.scss";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { navAppNames } from "../../../constants/AppNames";
import * as XLSX from "xlsx";
import AsyncSelect from "react-select/async";
import {
  searchOptionDebounceTime,
  asyncSelectNewCustomStyles,
  minCharsToLoadOptions,
  autoCloseToastError,
} from "../../../constants/Common";
import { accountApi } from "../../../constants/apis/BD/Account";
import { useDispatch, useSelector } from "react-redux";
import debounce from "lodash/debounce";
import {
  setAccountOptionsLoadedForImport,
  setAccountOptionsForImport,
} from "../../../features/optionSlice";
import { ImportLocationsSchema } from "../../../validations/BdForms/ImportLocations";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { accountTypes } from "../../../enums/Account";
import { accountOption } from "../../../components/AsyncSelectOption/CustomizedOptions";
import FormControl from "@mui/material/FormControl";
import { accountLocationsImportApi } from "../../../constants/apis/BD/AccountLocation";
import { messages } from "../../../constants/validationMessages/BdForms/ImportLocations";
import { createSearchParams } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const ImportLocations = () => {
  const dispatch = useDispatch();

  const profileData = useSelector((state) => state.userProfile.profileData);

  const axiosInstance = createInstance(true);
  const allowedExtensionForImport = [".xlsx", ".xls", ".ods"];

  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[8].currentPage = true;

  const role = Number(profileData?.role_id);

  const initialImportLocationsFormData = {
    account_id: null,
    file_extension: "",
  };

  const [importLocationsFormData, setImportLocationsFormData] = useState(
    initialImportLocationsFormData
  );
  const [importLocationsErrors, setImportLocationsErrors] = useState({});
  const [locationsData, setLocationsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [gettingImported, setGettingImported] = useState(false);
  const accountOptionsLoadedForImport = useSelector(
    (state) => state.optionsForAsyncSelect.accountOptionsLoadedForImport
  );
  const accountOptionsForImport = useSelector(
    (state) => state.optionsForAsyncSelect.accountOptionsForImport
  );
  const fileInputRef = useRef(null);

  const handleFileChange = async (e) => {
    setLoading(true);
    const file = e.target.files[0];
    if (file) {
      setImportLocationsErrors({
        ...importLocationsErrors,
        file_extension: "",
      });
      const fileNameParts = file.name.split(".");
      const fileExtension = `.${fileNameParts[fileNameParts.length - 1]}`;
      setImportLocationsFormData({
        ...importLocationsFormData,
        file_extension: fileExtension,
      });
      if (allowedExtensionForImport.includes(fileExtension)) {
        const data = await readFileAsync(file);
        const workbook = XLSX.read(data, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
        setLocationsData(parsedData);
      } else {
        setImportLocationsErrors({
          ...importLocationsErrors,
          file_extension: messages.fileExtensionImproper,
        });
      }
    } else {
      setImportLocationsFormData({
        ...importLocationsFormData,
        file_extension: "",
      });
      setLocationsData(null);
    }
    setLoading(false);
  };

  const readFileAsync = (file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => resolve(e.target.result);
      reader.readAsBinaryString(file);
    });
  };

  const handleCancel = () => {
    // Reset the file input value to clear the selection
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }

    // Clear the selectedFile state
    setImportLocationsFormData((pre) => ({ ...pre, file_extension: "" }));
    setLocationsData(null);
    if (role === 1) {
      setImportLocationsFormData((pre) => ({ ...pre, account_id: null }));

      setAccount(null);
    }
    setImportLocationsErrors({});
  };

  const fetchAccountOptions = async (inputvalue) => {
    try {
      const params = createSearchParams({
        search: inputvalue,
        vendorsRequired: true,
      });
      const response = await axiosInstance.get(
        accountApi + `/enxcustomeraccountoptions?${params}`
      );

      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = {};

          // Add additional data based on a condition
          if (item.account_type === accountTypes.Organization) {
            additionalData = {
              account_type: item.account_type,
              gstin: item.gstin,
            };
          } else if (item.account_type === accountTypes.Individual) {
            additionalData = {
              account_type: item.account_type,
              mobile: item.mobile,
              city: item.city,
            };
          }

          return {
            value: item.account_id,
            label: item.account_name,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching account options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching account options");
    }
    return [];
  };

  const loadAccountOptionsForImport = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchAccountOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const handleAccountFocus = async () => {
    if (!accountOptionsLoadedForImport) {
      const fetchedOptions = await fetchAccountOptions("");
      dispatch(setAccountOptionsLoadedForImport(true));
      dispatch(setAccountOptionsForImport(fetchedOptions));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validation = ImportLocationsSchema.validate(importLocationsFormData, {
      abortEarly: false,
    });
    if (validation.error) {
      setImportLocationsErrors({});
      const validationErrors = validation.error.details.reduce((acc, error) => {
        const fieldName = error.path[0];
        acc[fieldName] = error.message;
        return acc;
      }, {});

      setImportLocationsErrors(validationErrors);
    } else {
      setGettingImported(true);
      setImportLocationsErrors({});
      try {
        const payload = locationsData.map((obj) => ({
          ...obj,
          account_id: importLocationsFormData.account_id,
        }));
        const response = await axiosInstance.post(
          accountLocationsImportApi,
          payload
        );
        if (response.status === 200) {
          toast.success("Locations imported successfully");
        } else {
          toast.error("Import locations failed", {
            autoClose: autoCloseToastError,
          });
        }
      } catch (err) {
        errorHandler(err, "Import locations failed");
      }
      setGettingImported(false);
      handleCancel();
    }
  };

  useEffect(() => {
    const accountId = Number(profileData?.account_id);
    if (role === 2)
      setImportLocationsFormData({
        ...importLocationsFormData,
        account_id: accountId,
      });
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="h6">Import Locations</Typography>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {gettingImported ? (
            <LoadingSpinner />
          ) : (
            <>
              {loading ? <LoadingSpinner /> : ""}
              <div className="import-locations-base">
                <div className="import-locations-parent">
                  <div className="import-locations-content">
                    {role === 1 && (
                      <div className="import-locations-row account-name-row">
                        <div className="import-locations-form-label">
                          Account Name
                          <span className="required-field-asterisk"> *</span>
                        </div>
                        <div className="import-locations-form-value">
                          <FormControl className={`form-control-for-field`}>
                            <AsyncSelect
                              className={`async-select-field async-select-input ${
                                Boolean(importLocationsErrors?.account_id)
                                  ? " async-select-error"
                                  : ""
                              }`}
                              styles={asyncSelectNewCustomStyles}
                              onChange={(selectedOption) => {
                                setImportLocationsErrors({
                                  ...importLocationsErrors,
                                  account_id: "",
                                });
                                setAccount(selectedOption);
                                if (selectedOption) {
                                  setImportLocationsFormData({
                                    ...importLocationsFormData,
                                    account_id: selectedOption.value,
                                  });
                                } else {
                                  setImportLocationsFormData({
                                    ...importLocationsFormData,
                                    account_id: null,
                                  });
                                }
                              }}
                              value={account}
                              defaultOptions={accountOptionsForImport}
                              loadOptions={debounce(
                                loadAccountOptionsForImport,
                                searchOptionDebounceTime
                              )}
                              placeholder="Type to search..."
                              name="account_id"
                              noOptionsMessage={() =>
                                "No accounts found. Type again..."
                              }
                              isClearable
                              onFocus={handleAccountFocus}
                              components={{ Option: accountOption }}
                            />
                            <p className="form-error">
                              {importLocationsErrors?.account_id || ""}
                            </p>
                          </FormControl>
                        </div>
                      </div>
                    )}
                    <div className="import-locations-row select-file-row">
                      <div className="import-locations-form-label">
                        Select File
                        <span className="required-field-asterisk"> *</span>
                      </div>

                      <div className="import-locations-form-value">
                        <input
                          type="file"
                          ref={fileInputRef}
                          onChange={handleFileChange}
                          accept=".xlsx, .xls, .ods"
                        />
                        <p className="form-error">
                          {importLocationsErrors.file_extension}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <Divider className="horizontal-line" sx={{ my: 1 }} />
              <Button
                variant={"contained"}
                sx={{ mr: 2, mb: { xs: 1, md: 0 } }}
                className="primary-btn"
                onClick={handleSubmit}
              >
                Import
              </Button>
              <Button
                variant={"contained"}
                color="error"
                className="primary-btn"
                onClick={handleCancel}
              >
                Cancel
              </Button>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ImportLocations;
