import React from "react";
import MainCountCard from "../MainCountCard/MainCountCard";
import {
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

const BdCounts = (props) => {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container p={2} className="stat-card-bg" rowGap={2}>
      {props?.cards.map((card, index) => {
        switch (card.type) {
          case "main":
            return (
              <React.Fragment key={index}>
                <MainCountCard
                  iconImagePath={card.iconImagePath}
                  alt={card.altText}
                  count={card.count}
                  textLabel={card.textLabel}
                />
                {!isMobileScreen && (
                  <Divider
                    orientation="vertical"
                    className="horizontal-line"
                    sx={{ mx: 2, }}
                    flexItem
                  />
                )}
              </React.Fragment>
            );

          case "normal":
            return (
              <React.Fragment key={index}>
                <Grid item xs={12} sm={2} mr={{ sm: 2 }}>
                  <Typography variant="h3" textAlign={"center"} color={card?.color}>
                    {card.count}
                  </Typography>
                  <Typography
                    variant={"body1"}
                    color={card.color || "textSecondary"}
                    textAlign={"center"}
                  >
                    {card.textLabel}
                  </Typography>
                  {isMobileScreen && (
                    <Divider
                      className="horizontal-line"
                      orientation="horizontal"
                    />
                  )}
                </Grid>
              </React.Fragment>
            );

          default:
            break;
        }
      })}
    </Grid>
  );
};

export default BdCounts;
