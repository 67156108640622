export const getContactCardsBasedOnRows = (contactRows) => {
    let topLevelTypeContacts = 0;
    let seniorLevelTypeContacts = 0;
    let midLevelTypeContacts = 0;
    let juniorLevelTypeContacts = 0;
    contactRows?.forEach((contact) => {
        if (contact.role === 'Top Level') {
            topLevelTypeContacts++;
        } else if (contact.role === 'Senior Level') {
            seniorLevelTypeContacts++;
        } else if (contact.role === 'Mid Level') {
            midLevelTypeContacts++;
        } else {
            juniorLevelTypeContacts++;
        }
    });
    return [
        {
            role: 'Top Level',
            count: topLevelTypeContacts,

        },
        {
            role: 'Senior Level',
            count: seniorLevelTypeContacts,
        },
        {
            role: 'Mid Level',
            count: midLevelTypeContacts,
        },
        {
            role: 'Junior Level',
            count: juniorLevelTypeContacts,
        },
    ];
}