import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  targets: {},
  targetsLoading: false,
  message: null,
  error: null,
};

const targetsSlice = createSlice({
  name: "targets",
  initialState,
  reducers: {
    getTargetsRequest(state) {
      state.targetsLoading = true;
    },
    getTargetsSuccess(state, action) {
      state.targetsLoading = false;
      state.targets = action.payload;
      state.targets.rows.forEach((row) => {
        row.id = row.ticket_id;
      });
    },
    getTargetsFail(state) {
      state.targetsLoading = false;
    },
    clearError(state) {
      state.error = null;
    },
    clearMessage(state) {
      state.message = null;
    },
  },
});

export const targetReducer = targetsSlice.reducer;

export const targetActions = targetsSlice.actions;
