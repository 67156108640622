import { dealActions } from "../../reducers/bd-reducers/deals.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { timeFrameParam } from "../../../constants/queries";
import { toast } from "react-toastify";


const baseURL = process.env.REACT_APP_BACKEND_BASEURL;
const dealApi = baseURL + "/business-development/deal/";
const axiosInstance = createInstance();

/**
 * getDeals - Get deals data
 * @param {string} params
 */
export const getOpenDeals = (timeFrame) => async (dispatch) => {
  try {
    dispatch(dealActions.getDealsRequest());
    const { data } = await axiosInstance.get(
      "business-development/dashboard/dashboard-deals",
      { params: timeFrameParam(timeFrame) }
    );

    dispatch(dealActions.getOpenDealsSuccess(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get open deals");
    dispatch(dealActions.getOpenDealsFail());
  }
};


/**
 * getDeals - Get deals data
 *
 * Returns Deals data using the provided parameters
 *
 * @param {object} params
 */
export const getDeals = (params) => async (dispatch) => {
  try {
    dispatch(dealActions.getDealsRequest());

    const { data } = await axiosInstance.get(dealApi, {
      params: params,
    });

    dispatch(dealActions.getDealsSuccess(data.data));
  } catch (error) {

    errorHandler(error, "Failed to get deals");
    dispatch(dealActions.getDealsFail());
  }
};

/**
 * getDeals - Get account specific deals data
 *
 * Returns Deals data using the provided parameters
 *
 * @param {object} accountId
 * @param {object} params
 */
export const getAccountSpecificDeals = (accountId, params) => async (dispatch) => {
  try {
    dispatch(dealActions.getAccountSpecificDealsRequest());

    const { data } = await axiosInstance.get(dealApi.concat("/account/").concat(accountId) + params);

    dispatch(dealActions.getAccountSpecificDealsSuccess(data.data));
  } catch (error) {

    errorHandler(error.response.data.message, "Failed to get deals");
    dispatch(dealActions.getAccountSpecificDealsFail());
  }
};

/**
 * editDealById - Edit a deal by ID.
 *
 * Edits a deal with the specified ID using the provided payload.
 *
 * @param {string} id - The ID of the deal to be edited.
 * @param {object} payload - The data to update the deal with.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const editDealById = (id, payload, accountSpecific) => async (dispatch) => {
  try {
    dispatch(dealActions.editDealRequest());

    const { data } = await axiosInstance.put(dealApi.concat(id), payload);

    if (data.success) {
      dispatch(
        dealActions.editDealSuccess({ editedDeal: { ...data.data, deal_id: id }, accountSpecific })
      );
      dispatch(
        dealActions.editOpenDealsSuccess({ ...data.data, deal_id: id })
      );
      toast.success("Deal updated successfully");
    }
  } catch (error) {
    errorHandler(error, "Failed to edit deal");
    dispatch(dealActions.editDealFail());
  }
};

/**
 * addDeal - Add a new deal.
 *
 * Adds a new deal using the provided payload.
 *
 * @param {object} payload - The data for the new deal.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const addDeal = (payload, assignedToName, accountSpecific) => async (dispatch) => {
  try {
    dispatch(dealActions.addDealRequest());

    const { data } = await axiosInstance.post(`${dealApi}`, payload);

    if (data.success) {
      dispatch(
        dealActions.addDealSuccess({
          addedDeal: {
            ...data.data,
            id: data.data.deal_id,
            assigned_to_name: assignedToName,
          },
          accountSpecific
        })
      );
      toast.success("Deal added successfully");
    }
  } catch (error) {
    errorHandler(error, "Failed to add deal");
    dispatch(dealActions.addDealFail());
  }
};

/**
 * deleteDealById - deletes a deal by id
 * @param {string} id
 */
export const deleteDealById = (id, accountSpecific) => async (dispatch) => {
  try {
    dispatch(dealActions.deleteDealRequest());

    const deleteResponse = await axiosInstance.delete(dealApi.concat(id));
    if (deleteResponse.data.success) {
      dispatch(dealActions.deleteDealSuccess({ deletedDealId: id, accountSpecific }));
      toast.success("Deal deleted successfully");
    }
  } catch (error) {
    dispatch(dealActions.deleteDealFail());
    errorHandler(error, "Failed to get deals");
  }
};


export { dealApi };