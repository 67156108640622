import {
  Grid,
  Typography,
} from "@mui/material";

const MainCountCard = (props) => {
  return (
    <Grid item width={'auto'} xs={12} sm={'auto'} pr={2} container>
      <Grid item xs={12} sm={2} textAlign={{ xs: "center", md: "start" }}>
        <img src={props.iconImagePath} alt={props.altText} />
      </Grid>
      <Grid item xs={12} sm={10} textAlign={{ xs: "center", md: "end" }}>
        <Typography variant="h3">{props.count}</Typography>

      </Grid>
      <Grid item xs={12} textAlign={{ xs: "center", md: "end" }}>
        <Typography variant="body1" color={"textSecondary"}>
          {props.textLabel}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default MainCountCard;
