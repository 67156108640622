import React, { useState, useEffect } from "react";
import "./AmReportDetails.scss";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { useParams, createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userApi, userOptionsApi } from "../../../constants/apis/User";
import {
  searchOptionDebounceTime,
  asyncSelectCustomStyles,
  minCharsToLoadOptions,
  autoCloseToastError,
} from "../../../constants/Common";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";
import {
  setUserOptionsLoaded,
  setUserOptions,
} from "../../../features/optionSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { userOption } from "../../../components/AsyncSelectOption/CustomizedOptions";
import { assignmentsAPI } from "../../../constants/apis/AM/AssignAssets";
import { consumableAPI } from "../../../constants/apis/AM/Consumables";
import { assetAPI } from "../../../constants/apis/AM/Assets";
import { convertParamsToString } from "../../../utils/Request";
import assetReportColumns from "../../../data/json/assetReportColumns.json";
import assignmentReportColumns from "../../../data/json/assignmentReportColumns.json";
import consumableReportColumns from "../../../data/json/consumableReportColumns.json";
import assetRenewalColumns from "../../../data/json/assetRenewalColumns.json";
import assetSideBarMenus from "../../../data/json/assetSideBarMenus.json";
import { category, renewal } from "../../../enums/AM/Asset";
import { category as consumableCategory } from "../../../enums/AM/Consumable";

import moment from "moment";
import { DebounceInput } from "react-debounce-input";
import { accountLocationApi } from "../../../constants/apis/BD/AccountLocation";
import { accountApi } from "../../../constants/apis/BD/Account";
import { accountTypes } from "../../../enums/Account";
import {
  Container,
  Grid,
  Paper,
  Typography,
  Box,
  Divider,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import getTableCell from "../../../utils/Common/DataTable";

export default function AmReportDetails() {
  const axiosInstance = createInstance(true);
  const dispatch = useDispatch();
  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(assetSideBarMenus));
  appSidebar.menuList[4].currentPage = true;

  const profileData = useSelector((state) => state.userProfile.profileData);

  const { reportId } = useParams() || undefined;

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [rows, setRows] = useState([]);
  const [searched, setSearched] = useState("");
  const [rowsLoading, setRowsLoading] = useState(true);
  const [assignedTo, setAssignedTo] = useState("");
  const [user, setUser] = useState(null);
  const [vendors, setVendors] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectCategory, setCategory] = useState("");
  const [selectedVendor, setSelectedVendor] = useState("");
  const [selectedLocation, setSelectedLocation] = useState("");
  const [selectRenewal, setRenewal] = useState("");

  const userOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.userOptionsLoaded
  );
  const userOptions = useSelector(
    (state) => state.optionsForAsyncSelect.userOptions
  );

  const handleUserOptions = async () => {
    if (!userOptionsLoaded) {
      const fetchedOptions = await fetchUserOptions("");
      dispatch(setUserOptionsLoaded(true));
      dispatch(setUserOptions(fetchedOptions));
      setAssignedTo(fetchedOptions[0]);
      setUser(fetchedOptions[0].value);
    } else {
      setAssignedTo(userOptions[0]);
      setUser(userOptions[0].value);
    }
  };

  const fetchUserOptions = async (inputValue) => {
    try {
      const response = await axiosInstance.get(
        userOptionsApi + "?search=" + inputValue
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = { location: item.location, mobile: item.mobile };
          return {
            value: item.user_id,
            label: item.assign_to,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching assigned to options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching assigned to options");
    }
    return [];
  };

  const loadUserOptions = (inputValue, callback) => {
    if (inputValue?.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchUserOptions(inputValue)
      .then((options) => callback(options))
      .catch((err) => {
        errorHandler(err, "Error while fetching assigned to options");
        callback([]);
      });
  };

  const assetReportsParams = {
    category: selectCategory,
    vendor: selectedVendor,
    search: searched,
    sort_by: orderBy,
    sort_order: order,
    page: currentPage + 1,
    rows_per_page: rowsPerPage,
    renewal: selectRenewal,
  };

  const assignmentReportParams = {
    user: user,
    location: selectedLocation,
    type: "Asset",
    search: searched,
    sort_by: orderBy,
    sort_order: order,
    page: currentPage + 1,
    rows_per_page: rowsPerPage,
  };
  //params will be category, vendor for assets and consumables
  //params will be user and location for assignmentsAPI, assginConsumablesAPI.
  const idAndReportMappings = {
    101: [
      assetAPI + convertParamsToString(assetReportsParams),
      assetReportColumns,
      "Assets Report",
    ],
    102: [
      consumableAPI + convertParamsToString(assetReportsParams),
      consumableReportColumns,
      "Consumables Report",
    ],
    103: [
      assignmentsAPI + convertParamsToString(assignmentReportParams),
      assignmentReportColumns,
      "Assigned Assets Report",
    ],
    104: [
      assignmentsAPI +
        convertParamsToString({
          ...assignmentReportParams,
          type: "Consumable",
        }),
      assignmentReportColumns,
      "Assigned Consumables Report",
    ],
    105: [
      assetAPI + convertParamsToString(assetReportsParams),
      assetRenewalColumns,
      "Asset Warranty Renewal Details",
    ],
  };

  const fetchData = async () => {
    setRowsLoading(true);
    try {
      const response = await axiosInstance.get(
        idAndReportMappings[reportId][0]
      );
      if (response.status === 200) {
        let reportRows;
        let totalCount = 0;
        if (reportId === "103" || reportId === "104") {
          reportRows = response.data.data.rows;
          totalCount = response.data.data.meta.total;
        } else {
          reportRows = response.data.data.rows;
          reportRows.map((reportRow) => {
            for (let key in reportRow) {
              if (key === "date_of_purchase" || key === "warranty_ends_on") {
                reportRow[key] = reportRow[key]
                  ? moment(reportRow[key]).format("DD/MM/YYYY")
                  : "";
              }
            }
            return reportRow;
          });
          totalCount = response.data.data.meta.total;
        }
        setRows(reportRows);
        setTotalCount(totalCount);
      } else {
        toast.error("Failed to fetch report details", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Failed to fetch report details");
    }
    setRowsLoading(false);
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  const handleCategory = (e) => {
    setCategory(e.target.value);
  };

  const handleVendor = (e) => {
    setSelectedVendor(e.target.value);
  };

  const handleLocation = (e) => {
    setSelectedLocation(e.target.value);
  };

  const handleRenewal = (e) => {
    setRenewal(e.target.value);
  };

  const fetchVendors = async () => {
    try {
      const axiosInstance = createInstance(true);
      let type = "Asset";
      if (reportId === "101") {
        type = "Asset";
      }
      if (reportId === "102") {
        type = "Consumable";
      }
      const response = await axiosInstance.get(accountApi + "/vendor-options");

      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = {};

          // Add additional data based on a condition
          if (item.account_type === accountTypes.Organization) {
            additionalData = {
              account_type: item.account_type,
              gstin: item.gstin,
            };
          } else if (item.account_type === accountTypes.Individual) {
            additionalData = {
              account_type: item.account_type,
              mobile: item.mobile,
              city: item.city,
            };
          }

          return {
            value: item.account_id,
            label: item.account_name,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        setVendors(formattedOptions);
        return formattedOptions;
      } else {
        toast.error("Error while fetching vendor options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching vendor options");
    }
  };

  const fetchLocations = async () => {
    try {
      const axiosInstance = createInstance(true);
      let response;
      const accountId = Number(profileData?.account_id);
      const params = {
        accountId: accountId,
        search: "",
      };
      if (reportId === "103") {
        response = await axiosInstance.get(
          accountLocationApi +
            "/account-location-options" +
            convertParamsToString(params)
        );
      }
      if (reportId === "104") {
        response = await axiosInstance.get(
          accountLocationApi +
            "/account-location-options" +
            convertParamsToString(params)
        );
      }
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          return {
            value: item.location_name.concat(` (${item.location_code})`),
            label: item.location_name.concat(` (${item.location_code})`),
          };
        });
        setLocations(formattedOptions);
      } else {
        toast.error("Error while fetching location", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching location");
    }
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    rowsPerPage,
    searched,
    order,
    orderBy,
    user,
    selectCategory,
    selectedVendor,
    selectedLocation,
    selectRenewal,
  ]);

  useEffect(() => {
    if (reportId === "101" || reportId === "102") {
      fetchVendors();
    }
    if (reportId === "103" || reportId === "104") {
      handleUserOptions();
      fetchLocations();
    }
    setUser("");
  }, []);

  useEffect(() => {
    setSelectedVendor("");
  }, []);

  return (
    <>
      <Container maxWidth={"xxl"}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container className="top-container">
            <Grid item xs={12} sm={7}>
              <Typography variant="h6">
                {idAndReportMappings[reportId][2]}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5} textAlign={{ xs: "start", sm: "end" }}>
              <Box className="search-container results-search">
                <DebounceInput
                  type="text"
                  placeholder="Search"
                  className="search-input"
                  value={searched}
                  onChange={(e) => handleSearch(e)}
                  debounceTimeout={500}
                />
              </Box>
            </Grid>
          </Grid>
          <div className="amreport-details-base">
            <div className="amreport-details-parent">
              <div className="amreport-details-content">
                <div className="top-container">
                  <div className="amreport-details-actions">
                    {reportId === "101" && (
                      <div className="select-div second-action">
                        <div className="filter-name">Category</div>
                        <select
                          value={selectCategory}
                          name="category"
                          onChange={(e) => handleCategory(e)}
                        >
                          <option value="">Select</option>
                          {Object.keys(category).map((key) => {
                            return (
                              <option key={key} value={category[key]}>
                                {category[key]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {reportId === "102" && (
                      <div className="select-div second-action">
                        <div className="filter-name">Category</div>
                        <select
                          value={selectCategory}
                          name="category"
                          onChange={(e) => handleCategory(e)}
                        >
                          <option value="">Select</option>
                          {Object.keys(consumableCategory).map((key) => {
                            return (
                              <option key={key} value={consumableCategory[key]}>
                                {consumableCategory[key]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {(reportId === "101" || reportId === "102") && (
                      <div className="select-div second-action">
                        <div className="filter-name">Vendor</div>
                        <select
                          value={selectedVendor}
                          name="vendor"
                          onChange={(e) => handleVendor(e)}
                        >
                          <option value="">Select</option>
                          {vendors.map((vendor) => {
                            return (
                              <option key={vendor.value} value={vendor.value}>
                                {vendor.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {(reportId === "103" || reportId === "104") && (
                      <div className="select-div second-action">
                        <div className="filter-name">User</div>
                        <AsyncSelect
                          className="amreport-async-select-field async-select-input"
                          styles={asyncSelectCustomStyles}
                          onChange={(selectedOption) => {
                            setAssignedTo(selectedOption);
                            if (selectedOption) {
                              setUser(selectedOption.value);
                            } else {
                              setUser(null);
                            }
                          }}
                          value={assignedTo}
                          defaultOptions={userOptions || []}
                          loadOptions={debounce(
                            loadUserOptions,
                            searchOptionDebounceTime
                          )}
                          placeholder="Type to search..."
                          name="assigned_to"
                          noOptionsMessage={() =>
                            "No user found. Type again..."
                          }
                          isClearable
                          components={{ Option: userOption }}
                        />
                      </div>
                    )}
                    {(reportId === "103" || reportId === "104") && (
                      <div className="select-div second-action">
                        <div className="filter-name">Location</div>
                        <select
                          value={selectedLocation}
                          name="Location"
                          onChange={(e) => handleLocation(e)}
                        >
                          <option value="">Select</option>
                          {locations.map((location) => {
                            return (
                              <option
                                key={location.value}
                                value={location.value}
                              >
                                {location.label}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                    {reportId === "105" && (
                      <div className="select-div second-action">
                        <div className="filter-name">Renewal Slot</div>
                        <select
                          value={selectRenewal}
                          name="category"
                          onChange={(e) => handleRenewal(e)}
                        >
                          <option value="">Select</option>
                          {Object.keys(renewal).map((key) => {
                            return (
                              <option key={key} value={renewal[key]}>
                                {renewal[key]}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows?.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow key={'am_report_details_head_row'}> 
                      {idAndReportMappings[reportId][1].map((assetCol) =>
                        getTableCell(assetCol,orderBy,order,handleSortRequest)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.map((amReportDetailsRow, i) => {
                        return (
                          <TableRow key={i}>
                            {idAndReportMappings[reportId][1].map(
                              (assetCol) => {
                                return (
                                  <TableCell
                                    key={assetCol.id}
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {amReportDetailsRow[assetCol.id]}
                                  </TableCell>
                                );
                              }
                            )}
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
}
