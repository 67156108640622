import { useEffect, useState } from "react";
import elSidebarMenus from "../../../data/json/elSidebarMenus.json";
import { DebounceInput } from "react-debounce-input";
import BdCounts from "../../../components/BdCounts/BdCounts";
import elDashboardColumns from "../../../data/json/elDashboardColumns.json";
import { elDashboardApi } from "../../../constants/apis/EL/ElDashboard";
import { convertParamsToString } from "../../../utils/Request";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  setModuleAddOpenToggle,
  setModuleAdded,
  setModuleEdit,
  setModuleEditOpenToggle,
  setModuleEdited,
  setModuleId,
} from "../../../features/EL/moduleFormSlice";
import {
  setCourseAddModuleSpecific,
  setCourseAddOpenToggle,
  setCourseAdded,
  setCourseEdit,
} from "../../../features/EL/courseFormSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../../enums/Auth";
import {
  setModuleOptions,
  setModuleOptionsLoaded,
} from "../../../features/optionSlice";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import TableMenu from "../../../components/TableMenu/TableMenu";
import ModuleForm from "../../../components/ElForms/ModuleForm/ModuleForm";
import CourseForm from "../../../components/ElForms/CourseForm/CourseForm";
import { deleteModuleById, getModules } from "../../../redux/actions/el-actions/modules.action";
import { moduleActions } from "../../../redux/reducers/el-reducers/modules.reducer";

const ElDashboard = () => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const profileData = useSelector((state) => state.userProfile.profileData);

  const moduleState = useSelector((state) => state.modules);

  const appSidebar = JSON.parse(JSON.stringify(elSidebarMenus));
  appSidebar.menuList[0].currentPage = true;
  let elDashboardColumnsLocal;

  if (parseInt(profileData?.role_id) === userRoles.SuperAdmin) {
    elDashboardColumnsLocal = elDashboardColumns;
  } else {
    elDashboardColumnsLocal = elDashboardColumns.filter(
      (singleColumn) => singleColumn.id !== "action"
    );
    appSidebar.menuList[1].addEntityButton = false;
  }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialContactCountCards = [
    {
      type: "main",
      iconImagePath: "/assets/images/eldashboard.svg",
      altText: "eldashboard-icon",
      count: moduleState?.modules?.meta?.total,
      textLabel: "Total Modules",
    },
    {
      type: "normal",
      count: moduleState?.cardsData?.total_courses,
      textLabel: "Total Courses",
    },
    {
      type: "normal",
      count: moduleState?.cardsData?.active_courses,
      textLabel: "Active Courses",
    },
    {
      type: "normal",
      count: moduleState?.cardsData?.total_learners,
      textLabel: "Total Learners",
    },
    {
      type: "normal",
      count: moduleState?.cardsData?.active_learners,
      textLabel: "Active Learners",
    },
  ];

  const [addButtonAnchorEl, setAddButtonAnchorEl] = useState(null);
  const [searched, setSearched] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [countCards, setCountCards] = useState([]);
  const [rowsLoading, setRowsLoading] = useState(true);
  const [isSAdminSignedIn, setIsSAdminSignedIn] = useState(false);
  const [isLocalAdminSignedIn, setIsLocalAdminSignedIn] = useState(false);
  const moduleAdded = useSelector((state) => state.moduleForm.moduleAdded);
  const moduleEdited = useSelector((state) => state.moduleForm.moduleEdited);
  const courseAdded = useSelector((state) => state.courseForm.courseAdded);

  const requestData = async () => {
    setRowsLoading(true);
    const requestParams = {};

    //handle search
    if (searched !== "") {
      requestParams["search"] = searched;
    }

    //handle sorting here
    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }

    //apply pagination here
    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;

    const params = convertParamsToString(requestParams);
    dispatch(getModules(params));
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const getTableHeadCell = (columnInformation) => {
    return (
      <TableCell key={columnInformation.id}>
        <TableSortLabel
          active={orderBy === columnInformation.id}
          direction={orderBy === columnInformation.id ? order : "asc"}
          onClick={() => handleSortRequest(columnInformation.id)}
        >
          {columnInformation.name}
        </TableSortLabel>
      </TableCell>
    );
  };

  const deleteRow = async (id) => {
    if (!(isLocalAdminSignedIn || isSAdminSignedIn)) return;

    try {
      dispatch(deleteModuleById(id));
    } catch (err) {
      errorHandler(err, "Failed to delete module");
    }
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const [isModuleFormOpen, setIsModuleFormOpen] = useState(false);
  const [moduleEditProp, setModuleEditProp] = useState(false);
  const setAddModuleForm = () => {
    handleAddButtonClose();
    setIsModuleFormOpen(true);
    dispatch(setModuleId(null));
    dispatch(setModuleEdit(false));
    dispatch(setModuleAddOpenToggle());
  };

  const handleModuleClose = () => {
    setIsModuleFormOpen(false);
    setModuleEditProp(false);
  };

  const [isCourseFormOpen, setIsCourseFormOpen] = useState(false);
  const setAddCourseForm = () => {
    handleAddButtonClose();
    setIsCourseFormOpen(true);
    dispatch(setCourseAddModuleSpecific(false));
    dispatch(setCourseEdit(false));
    dispatch(setCourseAddOpenToggle());
  };

  const handleCourseClose = () => {
    setIsCourseFormOpen(false);
  };

  const openModuleDetailsPage = (id) => {
    dispatch(moduleActions.setSelectedModule(id));
    navigate(`/el/module/${id}`);
  };

  const fillDataInForm = (id) => {
    dispatch(moduleActions.setSelectedModule(id));
    setModuleEditProp(true);
    setIsModuleFormOpen(true);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);

    setCurrentPage(0);
  };

  const handleAddButtonClick = (event) => {
    setAddButtonAnchorEl(event.currentTarget);
  };

  const handleAddButtonClose = () => {
    setAddButtonAnchorEl(null);
  };

  useEffect(() => {
    if (isSAdminSignedIn || isLocalAdminSignedIn) {
      requestData();
    }
  }, [
    currentPage,
    rowsPerPage,
    searched,
    order,
    orderBy,
    isSAdminSignedIn,
    isLocalAdminSignedIn,
    courseAdded,
  ]);

  useEffect(() => {
    const sessionRole = profileData?.role_id;
    if (parseInt(sessionRole) === userRoles.SuperAdmin) {
      setIsSAdminSignedIn(true);
      setCountCards(initialContactCountCards);
    } else if (parseInt(sessionRole) === userRoles.Admin) {
      setIsLocalAdminSignedIn(true);
      setCountCards(initialContactCountCards);
    }
  }, []);

  useEffect(() => {
    setCountCards(initialContactCountCards);
  }, [moduleState])


  return (
    <>
      {isModuleFormOpen && (
        <ModuleForm
          isOpenProp={isModuleFormOpen}
          handleClose={handleModuleClose}
          moduleEditProp={moduleEditProp}
        />
      )}
      {isCourseFormOpen && (
        <CourseForm
          isOpenProp={isCourseFormOpen}
          handleClose={handleCourseClose}
        />
      )}

      <Container maxWidth={"xxl"}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">E-Learning</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnGap={!isSmallerScreen ? 2 : 0}
              rowGap={isSmallerScreen ? 1 : 0}
              justifyContent={!isSmallerScreen ? "end" : "space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={"auto"}>
                {isLocalAdminSignedIn || isSAdminSignedIn ? (
                  <Box className="search-container">
                    <DebounceInput
                      type="text"
                      placeholder="Search"
                      className="search-input"
                      value={searched}
                      onChange={(e) => handleSearch(e)}
                      debounceTimeout={500}
                    />
                  </Box>
                ) : null}
              </Grid>
              <Grid item xs={12} sm={"auto"}>
                {isSAdminSignedIn ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={handleAddButtonClick}
                      startIcon={<AddIcon />}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Add
                    </Button>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-add-button"
                      disableScrollLock
                      anchorEl={addButtonAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(addButtonAnchorEl)}
                      onClose={handleAddButtonClose}
                      slotProps={{
                        paper: {
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            borderRadius: "10px",
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ pr: 4, py: 1, textAlign: "start" }}
                        onClick={setAddModuleForm}
                      >
                        <Typography textAlign="center">Module</Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{ pr: 4, py: 1, textAlign: "start" }}
                        className="action-item"
                        onClick={setAddCourseForm}
                      >
                        <Typography textAlign="center">Course</Typography>
                      </MenuItem>
                    </Menu>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {isLocalAdminSignedIn || isSAdminSignedIn ? (
            <>
              <Box mb={3}>
                <BdCounts cards={countCards} />
              </Box>
              <Box className="eldashboard-table-container">
                {moduleState?.modulesLoading ? (
                  <LoadingSpinner />
                ) : moduleState?.modules?.rows?.length === 0 ? (
                  "No data found..."
                ) : (
                  <>
                    <TableContainer>
                      <Table size="medium">
                        <TableHead className="stat-card-bg">
                          <TableRow>
                            {elDashboardColumnsLocal.map((assetColumn) =>
                              getTableHeadCell(assetColumn)
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {moduleState?.modules?.rows &&
                            moduleState?.modules?.rows?.map((moduleRow) => {
                              return (
                                <TableRow key={moduleRow.id}>
                                  <Tooltip title={moduleRow.title}>
                                    <TableCell
                                      sx={{ maxWidth: "40px" }}
                                      className="text-truncate primary-text link-hover"
                                      onClick={() =>
                                        openModuleDetailsPage(moduleRow.id)
                                      }
                                    >
                                      {moduleRow.title}
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "100px" }}
                                  >
                                    {moduleRow.course_count}
                                  </TableCell>

                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "100px" }}
                                  >
                                    {moduleRow.active_course_count}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "100px" }}
                                  >
                                    {moduleRow.learners_count}
                                  </TableCell>
                                  {profileData?.role_id === userRoles.SuperAdmin && <TableCell align="right">
                                    <TableMenu
                                      rowId={moduleRow.id}
                                      isDeleteOptionEnabled={true}
                                      fillDataInForm={fillDataInForm}
                                      deleteModalData={[
                                        "Module",
                                        moduleRow.title,
                                      ]}
                                      handleDeleteRow={deleteRow}
                                    />
                                  </TableCell>}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      component="div"
                      count={moduleState?.modules?.meta?.total || 0}
                      rowsPerPage={rowsPerPage}
                      page={currentPage}
                      onPageChange={handlePage}
                      onRowsPerPageChange={handleRowsPerPage}
                    />
                  </>
                )}
              </Box>
            </>
          ) : (
            ""
          )}
        </Paper>
      </Container>
    </>
  );
};

export default ElDashboard;
