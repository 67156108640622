import { createSlice } from "@reduxjs/toolkit";
import { getTaskCardsBasedOnRows } from "../../../utils/BD/taskUtils";

const initialState = {
  dashboardTasks: {},
  dashboardTasksLoading: false,
  allTasks: {},
  allTasksLoading: false,
  accountSpecificTasks: {},
  accountSpecificTasksLoading: false,
  selectedTask: null,
  editTaskLoading: false,
  addTaskLoading: false,
  deleteTaskLoading: false,
  message: null,
  error: null,
};

const taskSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    getDashboardTasksRequest(state) {
      state.dashboardTasksLoading = true;
    },
    getDashboardTasksSuccess(state, action) {
      state.dashboardTasks = action.payload;
      state.dashboardTasks.rows.forEach((row) => {
        row.id = row.task_id;
      });
      state.dashboardTasksLoading = false;
    },
    getDashboardTasksFail(state) {
      state.dashboardTasksLoading = false;
    },

    getAllTasksRequest(state) {
      state.allTasksLoading = true;
    },
    getAllTasksSuccess(state, action) {
      state.allTasks = action.payload;
      state.allTasks.rows.forEach((row) => {
        row.id = row.task_id;
      });
      state.allTasksLoading = false;
    },
    getAllTasksFail(state) {
      state.allTasksLoading = false;
    },
    getAccountSpecificTasksRequest(state) {
      state.accountSpecificTasksLoading = true;
    },
    getAccountSpecificTasksSuccess(state, action) {
      state.accountSpecificTasks = action.payload;
      state.accountSpecificTasks.rows.forEach((row) => {
        row.id = row.task_id;
      });
      state.accountSpecificTasksLoading = false;
    },
    getAccountSpecificTasksFail(state) {
      state.accountSpecificTasksLoading = false;
    },
    setSelectedAccountSpecificTask(state, action) {
      const id = action.payload;
      state.selectedTask = state?.accountSpecificTasks?.rows?.find((row) => row.id === id) || {};
    },
    setSelectedDashboardTask(state, action) {
      const id = action.payload;
      state.selectedTask = state?.dashboardTasks?.rows?.find((row) => row.id === id) || {};
    },
    setSelectedTask(state, action) {
      const id = action.payload;
      state.selectedTask = state?.allTasks?.rows?.find((row) => row.id === id) || {};
    },
    editTaskRequest(state) {
      state.editTaskLoading = true;
    },
    editTaskSuccess(state, action) {
      const editedTask = action.payload.editedTask;
      editedTask.id = editedTask.task_id;
      const accountSpecific = action.payload.accountSpecific;
      const forDashboard = action.payload.forDashboard;

      if (accountSpecific) {
        state.accountSpecificTasks.rows = state.accountSpecificTasks.rows.map((row) => {
          if (row.id === editedTask.id) {
            return editedTask;
          }
          return row;
        })
      } else if (forDashboard) {
        state.dashboardTasks.rows = state.dashboardTasks.rows.map((row) => {
          if (row.id === editedTask.id) {
            return editedTask;
          }
          return row;
        })
      } else {
        state.allTasks.rows = state.allTasks.rows.map((row) => {
          if (row.id === editedTask.id) {
            return editedTask;
          }
          return row;
        })
        state.allTasks.cards = getTaskCardsBasedOnRows(state.allTasks?.rows);
      }

      state.editTaskLoading = false;
    },
    editTaskFail(state) {
      state.editTaskLoading = false;
    },
    addTaskRequest(state) {
      state.addTaskLoading = true;
    },
    addTaskSuccess(state, action) {
      const addedTask = action.payload.addedTask;
      addedTask.id = addedTask.task_id;
      const accountSpecific = action.payload.accountSpecific;

      if (accountSpecific) {
        if (state.accountSpecificTasks?.rows) {
          state.accountSpecificTasks.rows = [addedTask, ...state.accountSpecificTasks.rows];
        }
        if (state.accountSpecificTasks?.meta?.total) {
          state.accountSpecificTasks.meta.total++;
        }
      } else {
        state.allTasks.rows = [addedTask, ...state.allTasks?.rows]
        state.allTasks.meta.total++;
        state.allTasks.cards = getTaskCardsBasedOnRows(state.allTasks?.rows);
      }
      state.addTaskLoading = false;
    },
    addTaskFail(state) {
      state.addTaskLoading = false;
    },
    deleteTaskRequest(state) {
      state.deleteTaskLoading = true;
    },
    deleteTaskSuccess(state, action) {
      const deletedTaskId = action.payload.id;
      const accountSpecific = action.payload.accountSpecific;

      if (accountSpecific) {
        state.accountSpecificTasks.rows = state.accountSpecificTasks.rows.filter((row) => row.id !== deletedTaskId);
        state.accountSpecificTasks.meta.total--;
      } else {
        state.allTasks.rows = state.allTasks.rows.filter((row) => row.id !== deletedTaskId);
        state.allTasks.meta.total--;
        state.allTasks.cards = getTaskCardsBasedOnRows(state.allTasks?.rows);
      }

      state.deleteTaskLoading = false;
    },
    deleteTaskFail(state) {
      state.deleteTaskLoading = false;
    },
    resetTasks(state) {
      state.selectedTask = null;
      state.editTaskLoading = false;
      state.addTaskLoading = false;
      state.deleteTaskLoading = false;
    },
  },

});

export const taskReducer = taskSlice.reducer;

export const taskActions = taskSlice.actions;
