import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    lowQuantityConsumables: {},
    lowQuantityConsumablesLoading: false,
    consumables: {},
    consumablesLoading: false,
    addConsumableLoading: false,
    editConsumableLoading: false,
    deleteConsumableLoading: false,
    selectedConsumableData: null,
    message: null,
    error: null,
};

const consumableSlice = createSlice({
    name: "consumables",
    initialState,
    reducers: {

        getLowQuantityConsumablesRequest(state) {
            state.lowQuantityConsumablesLoading = true;
        },

        getLowQuantityConsumablesSuccess(state, action) {
            state.lowQuantityConsumablesLoading = false;
            state.lowQuantityConsumables = action.payload;
            state.lowQuantityConsumables.rows.forEach((consumable) => {
                consumable.id = consumable.consumable_id;
            });
        },

        getLowQuantityConsumablesFail(state) {
            state.lowQuantityConsumablesLoading = false;
        },

        getConsumablesRequest(state) {
            state.consumablesLoading = true;
        },

        getConsumablesSuccess(state, action) {
            state.consumablesLoading = false;
            state.consumables = action.payload;
            state.consumables.rows.forEach((consumable) => {
                consumable.id = consumable.consumable_id;
            });
        },

        getConsumablesFail(state) {
            state.consumablesLoading = false;
        },


        setSelectedConsumableData(state, action) {
            state.selectedConsumableData = action.payload;
        },

        addConsumableRequest(state) {
            state.addConsumableLoading = true;
        },

        addConsumableSuccess(state, action) {
            state.addConsumableLoading = false;
            state.consumables.rows = [action.payload, ...state.consumables.rows];
        },

        addConsumableFail(state) {
            state.addConsumableLoading = false;
        },

        editConsumableRequest(state) {
            state.editConsumableLoading = true;
        },

        editConsumableSuccess(state, action) {
            const editedConsumable = action.payload;
            editedConsumable.id = editedConsumable.consumable_id;
            state.consumables.rows = state.consumables.rows.map((consumable) => {
                if (editedConsumable.id === consumable.id) {
                    return { ...editedConsumable, };
                }
                return consumable;
            });
            state.editConsumableLoading = false;
        },

        editConsumableFail(state) {
            state.editConsumableLoading = false;
        },

        clearError(state) {
            state.error = null;
        },

        clearMessage(state) {
            state.message = null;
        },
    },
});

export const consumableReducer = consumableSlice.reducer;

export const consumableActions = consumableSlice.actions;
