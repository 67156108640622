const category = Object.freeze({
    c1: "IT Hardware",
    c2: "Software Licenses",
    c3: "Electronics",
});

const maintenance_frequency = Object.freeze({
    mf1: "Yearly",
    mf2: "Quarterly",
    mf3: "Monthly"
})

const renewal = Object.freeze({
    r1 : "Next month",
    r2: "Next 3 months",
    r3: "Next 6 months"
})

const criticality = Object.freeze({
     high:"High",
     medium:"Medium",
     low:"Low"
})

const sensitivity = Object.freeze({
    high:"High",
    medium:"Medium",
    low:"Low"
})

module.exports = { category, maintenance_frequency, renewal, criticality, sensitivity };
