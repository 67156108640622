const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    assignmentIdForDetails : null, 
    assignmentDetailsOpen : false
}

export const formSlice = createSlice({
    name: "assignmentDetails",
    initialState,
    reducers: {
        setAssignmentIdForDetails: (state, action) => {
            state.assignmentIdForDetails = action.payload;
        },

        setAssignmentDetailsOpen: (state, action) => {
            state.assignmentDetailsOpen = action.payload;
        }

    }
})

export const { setAssignmentIdForDetails, setAssignmentDetailsOpen } = formSlice.actions;

export default formSlice.reducer;