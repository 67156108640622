import { messages } from "../constants/validationMessages/PasswordChange";
const Joi = require("joi");

const passwordChangeSchema = Joi.object().keys({
    current_password: Joi.string().required().messages({
        "string.empty": messages.currentPasswordDefault,
    }),
    new_password: Joi.string().min(8).max(15).required().messages({
        "string.min": messages.newPasswordMin,
        "string.max": messages.newPasswordMax,
        "string.empty": messages.newPasswordDefault,
    }),
    confirm_password: Joi.string().required().messages({
        "string.empty": messages.confirmPasswordDefault,
    }),
});

export { passwordChangeSchema };
