import React, { useEffect, useRef, useState } from "react";
import { newAccount as content } from "../../../constants/helperText/NewAccount";
import {
  categories,
  occupations,
  statuses,
  industries,
  noOfEmployees,
} from "../../../enums/Account";
import {
  individualAccountSchema,
  organizationAccountSchema,
} from "../../../validations/BdForms/NewAccount";
import { useDispatch, useSelector } from "react-redux";
import { accountApi } from "../../../constants/apis/BD/Account";
import moment from "moment";
import { autoCloseToastError } from "../../../constants/Common";
import { toast } from "react-toastify";
import {
  getAllCountriesEnx,
  getStatesForCountry,
  getCitiesForState,
} from "../../../utils/Location";
import { titleCase } from "../../../utils/Common";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import debounce from "lodash/debounce";
import { createSearchParams } from "react-router-dom";
import { accountTypes } from "../../../enums/Account";
import Box from "@mui/material/Box";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Asterisk from "../../Misc/Asterisk";
import { addAccount, editAccountById } from "../../../redux/actions/bd-actions/accounts.action";

export default function NewAccount({ isOpenProp, handleClose, accountEditProp, forDashboard=false }) {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const profileData = useSelector((state) => state.userProfile.profileData);

  const axiosInstance = createInstance(true);
  const dispatch = useDispatch();
  const accountState = useSelector((state) => state.accounts);

  const [individualAccount, setIndividualAccount] = useState(false);
  const [individualAccountFormErrors, setIndividualAccountFormErrors] =
    useState({});
  const [organizationAccountFormErrors, setOrganizationAccountFormErrors] =
    useState({});
  const [fetchingAccountData, setFetchingAccountData] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([
    {
      name: "Select State",
      value: "",
    },
  ]);
  const [cities, setCities] = useState([
    {
      name: "Select City",
      value: "",
    },
  ]);

  const [edit, setEdit] = useState(accountEditProp || false);
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(true);
  const [searched, setSearched] = useState("");
  const [accessDenied, setAccessDenied] = useState(false);
  let suggestionBoxRef = useRef();

  const defaultSelectedCountry = { value: "IN", name: "India", phone: "91" };

  const initialIndividualAccountData = {
    account_name: "",
    account_category: categories.Prospect,
    status: "Active",
    mobile: "",
    email: "",
    occupation: "",
    dob: "",
    aadhar: "",
    pan: "",
    country: defaultSelectedCountry.value,
    state: "",
    city: "",
    pincode: "",
    street_address: "",
    website: "",
    description: "",
  };

  const initialOrganizationAccountData = {
    account_name: "",
    industry: "",
    status: "Active",
    account_category: categories.Prospect,
    mobile: "",
    email: "",
    gstin: "",
    pan: "",
    annual_revenue: "",
    no_of_employees: "",
    country: defaultSelectedCountry.value,
    state: "",
    city: "",
    pincode: "",
    street_address: "",
    website: "",
    description: "",
  };

  const [individualAccountData, setIndividualAccountData] = useState(
    initialIndividualAccountData
  );
  const [organizationAccountData, setOrganizationAccountData] = useState(
    initialOrganizationAccountData
  );

  const handleIndividualForm = (e, value) => {
    if (!["country", "state", "city"].includes(e)) {
      setIndividualAccountFormErrors({
        ...individualAccountFormErrors,
        [e.target.name]: "",
      });
      setIndividualAccountData({
        ...individualAccountData,
        [e.target.name]: e.target.value,
      });
    } else {
      setIndividualAccountFormErrors({
        ...individualAccountFormErrors,
        [e]: "",
      });
      setIndividualAccountData({ ...individualAccountData, [e]: value });
    }

    if (e === "country") {
      setStates(() => getStatesForCountry(value));
      setIndividualAccountData({
        ...individualAccountData,
        [e]: value,
        state: "",
        city: "",
      });
    } else if (e === "state") {
      setCities(() =>
        getCitiesForState(individualAccountData["country"], value)
      );
      setIndividualAccountData({
        ...individualAccountData,
        [e]: value,
        city: "",
      });
    }
  };
  const handleOrganizationForm = (e, value) => {
    if (!["country", "state", "city"].includes(e)) {
      setOrganizationAccountFormErrors({
        ...organizationAccountFormErrors,
        [e.target.name]: "",
      });
      setOrganizationAccountData({
        ...organizationAccountData,
        [e.target.name]: e.target.value,
      });
    } else {
      setOrganizationAccountFormErrors({
        ...organizationAccountFormErrors,
        [e]: "",
      });
      setOrganizationAccountData({ ...organizationAccountData, [e]: value });
    }

    if (e === "country") {
      setStates(() => getStatesForCountry(value));
      setOrganizationAccountData({
        ...organizationAccountData,
        [e]: value,
        state: "",
        city: "",
      });
    } else if (e === "state") {
      setCities(() =>
        getCitiesForState(organizationAccountData["country"], value)
      );
      setOrganizationAccountData({
        ...organizationAccountData,
        [e]: value,
        city: "",
      });
    }
  };

  const handleIndividualFormSubmit = async (e) => {
    e.preventDefault();
    const accountNameTitleCase = await titleCase(
      individualAccountData.account_name
    );
    const userId = profileData?.user_id;
    const payload = { ...individualAccountData };
    payload.account_name = accountNameTitleCase;
    const validation = individualAccountSchema.validate(payload, {
      abortEarly: false,
    });
    if (validation.error) {
      setIndividualAccountFormErrors({});
      let validationErrors = {};
      if (validation.error) {
        validationErrors = validation.error.details.reduce((acc, error) => {
          const fieldName = error.path[0];
          acc[fieldName] = error.message;
          return acc;
        }, {});
      }
      setIndividualAccountFormErrors(validationErrors);
    } else {
      setIndividualAccountFormErrors({});
      payload.account_type = individualAccount ? "Individual" : "Organization";
      payload.created_by = userId;
      try {
        dispatch(addAccount(payload));
      } catch (err) {
        if (err.response?.status === 409) {
          let validationErrors = {};
          validationErrors["account_name"] = "Account Name already taken";
          setIndividualAccountFormErrors(validationErrors);
          errorHandler(err, "Account Name already taken");
        } else errorHandler(err, "Account addition failed");
      }
    }
  };

  const handleOrganizationFormSubmit = async (e) => {
    e.preventDefault();
    const userId = profileData?.user_id;
    const accountNameTitleCase = await titleCase(
      organizationAccountData.account_name
    );
    const payload = { ...organizationAccountData };
    payload.account_name = accountNameTitleCase;
    const validation = organizationAccountSchema.validate(payload, {
      abortEarly: false,
    });
    if (validation.error) {
      setOrganizationAccountFormErrors({});
      let validationErrors = {};
      if (validation.error) {
        validationErrors = validation.error.details.reduce((acc, error) => {
          const fieldName = error.path[0];
          acc[fieldName] = error.message;
          return acc;
        }, {});
      }
      setOrganizationAccountFormErrors(validationErrors);
    } else {
      setOrganizationAccountFormErrors({});
      payload.account_type = individualAccount ? "Individual" : "Organization";
      payload.created_by = userId;
      try {
        dispatch(addAccount(payload));
      } catch (err) {
        if (err.response?.status === 409) {
          let validationErrors = {};
          validationErrors["account_name"] = "Account Name already taken";
          setOrganizationAccountFormErrors(validationErrors);
          errorHandler(err, "Account Name already taken");
        } else errorHandler(err, "Account addition failed");
      }
    }
  };

  const handleIndividualFormEdit = async (e) => {
    e.preventDefault();
    const userId = profileData?.user_id;
    const accountNameTitleCase = await titleCase(
      individualAccountData.account_name
    );
    const validation = individualAccountSchema.validate(individualAccountData, {
      abortEarly: false,
    });
    if (validation.error) {
      setIndividualAccountFormErrors({});
      let validationErrors = {};
      if (validation.error) {
        validationErrors = validation.error.details.reduce((acc, error) => {
          const fieldName = error.path[0];
          acc[fieldName] = error.message;
          return acc;
        }, {});
      }
      setIndividualAccountFormErrors(validationErrors);
    } else {
      setIndividualAccountFormErrors({});
      const payload = { ...individualAccountData };
      payload.account_type = individualAccount ? "Individual" : "Organization";
      payload.updated_by = userId;
      payload.account_name = accountNameTitleCase;
      try {
        dispatch(editAccountById(accountState?.selectedAccount?.id, payload, forDashboard));
      } catch (err) {
          errorHandler(err, "Failed to edit account details");
      }
    }
  };

  const handleOrganizationFormEdit = async (e) => {
    e.preventDefault();
    const userId = profileData?.user_id;
    const accountNameTitleCase = await titleCase(
      organizationAccountData.account_name
    );
    const validation = organizationAccountSchema.validate(
      organizationAccountData,
      { abortEarly: false }
    );
    if (validation.error) {
      setOrganizationAccountFormErrors({});
      let validationErrors = {};
      if (validation.error) {
        validationErrors = validation.error.details.reduce((acc, error) => {
          const fieldName = error.path[0];
          acc[fieldName] = error.message;
          return acc;
        }, {});
      }
      setOrganizationAccountFormErrors(validationErrors);
    } else {
      setOrganizationAccountFormErrors({});
      const payload = { ...organizationAccountData };
      payload.account_type = individualAccount ? "Individual" : "Organization";
      payload.updated_by = userId;
      payload.account_name = accountNameTitleCase;
      try {
        dispatch(editAccountById(accountState?.selectedAccount?.id, payload, forDashboard));
      } catch (err) {
        errorHandler(err, "Account addition failed");
      }
    }
  };

  const fetchData = async (accountsId, fromEdit = false) => {
    const params = createSearchParams({
      fromEditAccount: fromEdit,
    });
    setFetchingAccountData(true);
    try {
      const editAccountData = accountState?.selectedAccount;
      const accountType = editAccountData?.account_type;
      if (accountType === "Individual") {
        setIndividualAccount(true);
        const fetchedIndividualAccountData = editAccountData;
        const editIndividualAccountData = {};
        for (let key in initialIndividualAccountData) {
          if (key === "dob") {
            const formattedDate = fetchedIndividualAccountData.dob
              ? moment(fetchedIndividualAccountData.dob).format("YYYY-MM-DD")
              : "";
            editIndividualAccountData[key] = formattedDate;
          } else {
            editIndividualAccountData[key] = fetchedIndividualAccountData[key] || "";
          }
        }

        setIndividualAccountData(editIndividualAccountData);
      } else {
        setIndividualAccount(false);
        const fetchedOrganizationAccountData = editAccountData;
        const editOrganizationAccountData = {};
        for (let key in initialOrganizationAccountData) {
          editOrganizationAccountData[key] =
            fetchedOrganizationAccountData[key] || "";
        }
        setOrganizationAccountData(editOrganizationAccountData);
      }
      const countryCode = editAccountData?.country;
      const stateCode = editAccountData?.state;
      setCountries(() => getAllCountriesEnx());
      setStates(() => getStatesForCountry(countryCode));
      setCities(() => getCitiesForState(countryCode, stateCode));
    } catch (err) {
      if (err.response?.status === 403) {
        setFetchingAccountData(false);
        setAccessDenied(true);
        setIndividualAccountData(initialIndividualAccountData);
        setOrganizationAccountData(initialOrganizationAccountData);
        errorHandler(err, "Not allowed");
      } else errorHandler(err, "Failed to fetch account details");
    }
    setFetchingAccountData(false);
  };

  useEffect(() => {
    if (accessDenied) {
      closeAccountForm();
      setAccessDenied(false);
    }
  }, [accessDenied]);

  const closeAccountForm = () => {
    handleDrawerClose();
    setSearched("");
  };

  useEffect(() => {
    if (accountEditProp) {
      setIndividualAccountFormErrors({});
      setOrganizationAccountFormErrors({});
      setEdit(true);
      fetchData(accountState?.selectedAccountId, true);
    } else {
      setIndividualAccount(false);
      setIndividualAccountData(initialIndividualAccountData);
      setOrganizationAccountData(initialOrganizationAccountData);
      setIndividualAccountFormErrors({});
      setOrganizationAccountFormErrors({});
      setCountries(() => getAllCountriesEnx());
      setStates(() => getStatesForCountry("IN"));
    }
    setSearched("");
  }, []);

  useEffect(() => {
    if (!edit) {
      setIndividualAccountData(initialIndividualAccountData);
      setOrganizationAccountData(initialOrganizationAccountData);
      setIndividualAccountFormErrors({});
      setOrganizationAccountFormErrors({});
      setCountries(() => getAllCountriesEnx());
      setStates(() => getStatesForCountry("IN"));
      setSearched("");
    }
  }, [individualAccount]);

  useEffect(() => {
    //  remove suggestion box on clicking outside of the suggestion box
    const handleOutsideClick = (event) => {
      if (
        suggestionBoxRef.current &&
        !suggestionBoxRef.current.contains(event.target)
      ) {
        setShowSuggestions(false);
      }
    };
    const handleScroll = (event) => {
      setShowSuggestions(false);
    };

    document.addEventListener("click", handleOutsideClick);
    window.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const fetchAccountNames = async () => {
    const params = createSearchParams({
      search: searched,
      accountSuggestion: individualAccount
        ? accountTypes.Individual
        : accountTypes.Organization,
    });

    try {
      const response = await axiosInstance.get(
        accountApi + `/accountoptions?${params}`
      );
      if (response.status === 200) {
        setSuggestions(response.data.data);
        setShowSuggestions(true);
      } else
        toast.error("Failed to show account names used", {
          autoClose: autoCloseToastError,
        });
    } catch (err) {
      toast.error("Failed to show account names used", {
        autoClose: autoCloseToastError,
      });
    }
  };

  // Debounce the function to avoid multiple API calls while typing
  const debouncedFetchData = debounce(fetchAccountNames, 300);

  const handleAccountNameSearch = (event) => {
    setSearched(event.target.value);
  };

  const [isOpen, setIsOpen] = useState(isOpenProp);
  const handleDrawerClose = (_) => {
    handleClose();
    setIsOpen(false);
  };

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: { sm: "75vw", md: "90vw", lg: "80vw", xl: "60vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      anchor={"right"}
      open={isOpen}
      onClose={handleDrawerClose}
    >
      <Box
        my={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">{edit ? "Edit " : "New "} Account</Typography>
        <IconButton
          aria-label="close"
          onClick={handleDrawerClose}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" />
      {fetchingAccountData ? (
        <LoadingSpinner />
      ) : (
        <Box my={!isSmallerScreen ? 3 : 2}>
          {/* Content */}
          <Grid container>
            {/* Account Type */}
            <Grid item xs={12} md={2} pr={2}>
              <InputLabel className="fw-bold" sx={{ mt: !edit ? 1 : 0 }}>
                {"Account Type"}
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={10} mb={2}>
              {!edit ? (
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        size="small"
                        checked={individualAccount}
                        onChange={() =>
                          setIndividualAccount(!individualAccount)
                        }
                      />
                    }
                    label="Individual"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        size="small"
                        checked={!individualAccount}
                        onChange={() =>
                          setIndividualAccount(!individualAccount)
                        }
                      />
                    }
                    label="Organization"
                  />
                </RadioGroup>
              ) : individualAccount ? (
                <Typography variant="body1">Individual</Typography>
              ) : (
                <Typography variant="body1">Organization</Typography>
              )}
            </Grid>
            {!individualAccount && (
              <>
                {/* Account Name */}
                <Grid item xs={12} md={2} pr={2}>
                  <InputLabel className="fw-bold">
                    {"Account Name "}
                    <Asterisk />
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={10} mb={2}>
                  <TextField
                    name="account_name"
                    size="small"
                    placeholder={content.accountName}
                    value={organizationAccountData.account_name}
                    onChange={(e) => {
                      handleOrganizationForm(e);
                      handleAccountNameSearch(e);
                    }}
                    error={Boolean(organizationAccountFormErrors?.account_name)}
                    helperText={
                      organizationAccountFormErrors?.account_name || ""
                    }
                    fullWidth
                  />
                  {/* TODO: Add suggestions Box */}
                </Grid>

                {/* Double Column */}
                <Grid container item md={12}>
                  {/* Industry */}
                  <Grid item xs={12} md={2} pr={2}>
                    <InputLabel className="fw-bold">
                      {"Industry "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Select
                      name="industry"
                      value={organizationAccountData.industry}
                      onChange={handleOrganizationForm}
                      size="small"
                      sx={{ width: "100%" }}
                      error={Boolean(organizationAccountFormErrors?.industry)}
                      displayEmpty
                    >
                      <MenuItem key={"Select"} value="">
                        {"Select Industry"}
                      </MenuItem>
                      {Object.keys(industries).map((key) => (
                        <MenuItem key={key} value={industries[key]}>
                          {industries[key]}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="caption" color="error" pl={2} m={0}>
                      {organizationAccountFormErrors?.industry || ""}
                    </Typography>
                  </Grid>

                  {/* Account Category */}
                  <Grid item xs={12} md={3} pr={2}>
                    <InputLabel
                      className="fw-bold"
                      sx={{ textAlign: !isSmallerScreen ? "right" : "inherit" }}
                    >
                      {"Account Category "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Select
                      name="account_category"
                      value={organizationAccountData.account_category}
                      onChange={handleOrganizationForm}
                      size="small"
                      error={Boolean(
                        organizationAccountFormErrors?.account_category
                      )}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem className="select-menu-item" value="">
                        Select Category
                      </MenuItem>
                      {Object.keys(categories).map((key) => (
                        <MenuItem key={key} value={categories[key]}>
                          {categories[key]}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="caption" color="error" pl={2} m={0}>
                      {organizationAccountFormErrors?.account_category || ""}
                    </Typography>
                  </Grid>

                  {/* Edit Data */}
                  {edit && (
                    <>
                      {/* Status */}
                      <Grid item xs={12} md={2} pr={2}>
                        <InputLabel className="fw-bold">
                          {"Status "}
                          <Asterisk />
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <Select
                          name="status"
                          onChange={handleOrganizationForm}
                          value={organizationAccountData.status}
                          size="small"
                          sx={{ width: "100%" }}
                          error={Boolean(organizationAccountFormErrors?.status)}
                          displayEmpty
                        >
                          <MenuItem key={"Select"} value="">
                            {"Select Status"}
                          </MenuItem>
                          {Object.keys(statuses).map((key) => (
                            <MenuItem key={key} value={statuses[key]}>
                              {statuses[key]}
                            </MenuItem>
                          ))}
                        </Select>
                        {organizationAccountFormErrors?.status && (
                          <Typography
                            variant="caption"
                            color="error"
                            pl={2}
                            m={0}
                          >
                            {organizationAccountFormErrors?.status}
                          </Typography>
                        )}
                      </Grid>

                      {/* Website */}
                      <Grid item xs={12} md={3} pr={2}>
                        <InputLabel
                          className="fw-bold"
                          sx={{
                            textAlign: !isSmallerScreen ? "right" : "inherit",
                          }}
                        >
                          {"Website"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="website"
                          placeholder={content.website}
                          value={organizationAccountData.website}
                          onChange={handleOrganizationForm}
                          error={Boolean(
                            organizationAccountFormErrors?.website
                          )}
                          helperText={
                            organizationAccountFormErrors?.website || ""
                          }
                          size="small"
                          fullWidth
                        />
                      </Grid>

                      {/* Phone Number */}
                      <Grid item xs={12} md={2} pr={2}>
                        <InputLabel className="fw-bold">
                          {"Phone Number"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="mobile"
                          inputProps={{ maxLength: 15 }}
                          placeholder="Phone Number"
                          value={organizationAccountData.mobile}
                          onChange={handleOrganizationForm}
                          error={Boolean(organizationAccountFormErrors?.mobile)}
                          helperText={
                            organizationAccountFormErrors?.mobile || ""
                          }
                          size="small"
                          fullWidth
                        />
                      </Grid>

                      {/* Email */}
                      <Grid item xs={12} md={3} pr={2}>
                        <InputLabel
                          className="fw-bold"
                          sx={{
                            textAlign: !isSmallerScreen ? "right" : "inherit",
                          }}
                        >
                          {"Email"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="email"
                          placeholder={content.email}
                          value={organizationAccountData.email}
                          onChange={handleOrganizationForm}
                          error={Boolean(organizationAccountFormErrors?.email)}
                          helperText={
                            organizationAccountFormErrors?.email || ""
                          }
                          size="small"
                          fullWidth
                        />
                      </Grid>

                      {/* GSTIN */}
                      <Grid item xs={12} md={2} pr={2}>
                        <InputLabel className="fw-bold">{"GSTIN"}</InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="gstin"
                          inputProps={{ minLength: 15, maxLength: 15 }}
                          placeholder={content.gstin}
                          value={organizationAccountData.gstin}
                          onChange={handleOrganizationForm}
                          error={Boolean(organizationAccountFormErrors?.gstin)}
                          helperText={
                            organizationAccountFormErrors?.gstin || ""
                          }
                          size="small"
                          fullWidth
                        />
                      </Grid>

                      {/* PAN Number */}
                      <Grid item xs={12} md={3} pr={2}>
                        <InputLabel
                          className="fw-bold"
                          sx={{
                            textAlign: !isSmallerScreen ? "right" : "inherit",
                          }}
                        >
                          {"PAN Number"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="pan"
                          inputProps={{ minLength: 10, maxLength: 10 }}
                          placeholder={content.panNumber}
                          value={organizationAccountData.pan}
                          onChange={handleOrganizationForm}
                          error={Boolean(organizationAccountFormErrors?.pan)}
                          helperText={organizationAccountFormErrors?.pan || ""}
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}

                  {/* Annual Revenue */}
                  <Grid item xs={12} md={2} pr={2}>
                    <InputLabel className="fw-bold">
                      {"Annual Revenue"}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <TextField
                      name="annual_revenue"
                      type="number"
                      placeholder={content.annualRevenue}
                      value={organizationAccountData.annual_revenue}
                      onChange={handleOrganizationForm}
                      error={Boolean(
                        organizationAccountFormErrors?.annual_revenue
                      )}
                      helperText={
                        organizationAccountFormErrors?.annual_revenue || ""
                      }
                      InputProps={{ inputProps: { min: 0 } }}
                      size="small"
                      fullWidth
                    />
                  </Grid>

                  {/* Number of Employees */}
                  <Grid item xs={12} md={3} pr={2}>
                    <InputLabel
                      className="fw-bold"
                      sx={{ textAlign: !isSmallerScreen ? "right" : "inherit" }}
                    >
                      {"No. of Employees"}
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5}>
                    <Select
                      name="no_of_employees"
                      value={organizationAccountData.no_of_employees}
                      onChange={handleOrganizationForm}
                      error={Boolean(
                        organizationAccountFormErrors?.account_category
                      )}
                      size="small"
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem className="select-menu-item" value="">
                        Select number of employees
                      </MenuItem>
                      {Object.keys(noOfEmployees).map((key) => (
                        <MenuItem key={key} value={noOfEmployees[key]}>
                          {noOfEmployees[key]}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant="caption" color="error" pl={2} m={0}>
                      {organizationAccountFormErrors?.no_of_employees || ""}
                    </Typography>
                  </Grid>

                  {/* Country */}
                  <Grid item xs={12} md={2} pr={2}>
                    <InputLabel className="fw-bold">
                      {"Country "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Autocomplete
                      name="country"
                      onChange={(event, value) =>
                        handleOrganizationForm("country", value?.value || "")
                      }
                      value={
                        countries.find(
                          (country) =>
                            country.value === organizationAccountData.country
                        ) || null
                      }
                      options={countries}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(
                            organizationAccountFormErrors?.country
                          )}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    {organizationAccountFormErrors?.country && (
                      <Typography variant="caption" color="error" pl={2} m={0}>
                        {organizationAccountFormErrors?.country}
                      </Typography>
                    )}
                  </Grid>

                  {/* State */}
                  <Grid item xs={12} md={3} pr={2}>
                    <InputLabel
                      className="fw-bold"
                      sx={{ textAlign: !isSmallerScreen ? "right" : "inherit" }}
                    >
                      {"State "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Autocomplete
                      name="state"
                      onChange={(event, value) =>
                        handleOrganizationForm("state", value?.value || "")
                      }
                      value={
                        states.find(
                          (state) =>
                            state.value === organizationAccountData.state
                        ) || null
                      }
                      options={states}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(organizationAccountFormErrors?.state)}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    {organizationAccountFormErrors?.state && (
                      <Typography variant="caption" color="error" pl={2} m={0}>
                        {organizationAccountFormErrors?.state}
                      </Typography>
                    )}
                  </Grid>

                  {/* City */}
                  <Grid item xs={12} md={2} pr={2}>
                    <InputLabel className="fw-bold">
                      {"City "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Autocomplete
                      name="city"
                      onChange={(event, value) =>
                        handleOrganizationForm("city", value?.value || "")
                      }
                      value={
                        cities.find(
                          (city) => city.value === organizationAccountData.city
                        ) || null
                      }
                      options={cities}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(organizationAccountFormErrors?.city)}
                          inputProps={{
                            ...params.inputProps,
                            className: `${
                              organizationAccountData.city === ""
                                ? "not-selected"
                                : ""
                            }`,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    {organizationAccountFormErrors?.city && (
                      <Typography variant="caption" color="error" pl={2} m={0}>
                        {organizationAccountFormErrors?.city}
                      </Typography>
                    )}
                  </Grid>

                  {/* Pin Code} */}
                  <Grid item xs={12} md={3} pr={2}>
                    <InputLabel
                      className="fw-bold"
                      sx={{ textAlign: !isSmallerScreen ? "right" : "inherit" }}
                    >
                      {"Pin Code "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <TextField
                      name="pincode"
                      placeholder={content.pincode}
                      value={organizationAccountData.pincode}
                      onChange={handleOrganizationForm}
                      error={Boolean(organizationAccountFormErrors?.pincode)}
                      helperText={organizationAccountFormErrors?.pincode || ""}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {/* Street Address */}
                <Grid item xs={12} md={2} pr={2}>
                  <InputLabel className="fw-bold">
                    {"Street Address"}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={10} mb={2}>
                  <TextField
                    name="street_address"
                    placeholder={content.streetAddress}
                    value={organizationAccountData.street_address}
                    onChange={handleOrganizationForm}
                    error={Boolean(
                      organizationAccountFormErrors?.street_address
                    )}
                    helperText={
                      organizationAccountFormErrors?.street_address || ""
                    }
                    size="small"
                    fullWidth
                  />
                </Grid>

                {/* Description */}
                <Grid item xs={12} md={2} pr={2}>
                  <InputLabel className="fw-bold">{"Description"}</InputLabel>
                </Grid>
                <Grid item xs={12} md={10} mb={2}>
                  <TextField
                    name="description"
                    placeholder={content.description}
                    value={organizationAccountData.description}
                    onChange={handleOrganizationForm}
                    error={Boolean(organizationAccountFormErrors?.description)}
                    helperText={
                      organizationAccountFormErrors?.description || ""
                    }
                    size="small"
                    fullWidth
                    multiline
                    rows={2}
                  />
                </Grid>
              </>
            )}

            {individualAccount && (
              <>
                {/* Full Name */}
                <Grid item xs={12} md={2} pr={2}>
                  <InputLabel className="fw-bold">
                    {"Full Name "}
                    <Asterisk />
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={10} mb={2}>
                  <TextField
                    name="account_name"
                    placeholder="Full Name"
                    value={individualAccountData.account_name}
                    onChange={(e) => {
                      handleIndividualForm(e);
                      handleAccountNameSearch(e);
                    }}
                    error={Boolean(individualAccountFormErrors?.account_name)}
                    helperText={individualAccountFormErrors?.account_name || ""}
                    size="small"
                    fullWidth
                  />
                </Grid>

                {/* Double Column */}
                <Grid container item md={12}>
                  {edit ? (
                    <>
                      {/* Status */}
                      <Grid item xs={12} md={2} pr={2}>
                        <InputLabel className="fw-bold">
                          {"Status "}
                          <Asterisk />
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <Select
                          name="status"
                          value={individualAccountData.status}
                          onChange={handleIndividualForm}
                          size="small"
                          sx={{ width: "100%" }}
                          error={Boolean(individualAccountFormErrors?.status)}
                          displayEmpty
                        >
                          <MenuItem key={"Select"} value="">
                            {"Select Status"}
                          </MenuItem>
                          {Object.keys(statuses).map((key) => (
                            <MenuItem key={key} value={statuses[key]}>
                              {statuses[key]}
                            </MenuItem>
                          ))}
                        </Select>
                        {individualAccountFormErrors?.status && (
                          <Typography
                            variant="caption"
                            color="error"
                            pl={2}
                            m={0}
                          >
                            {individualAccountFormErrors?.status}
                          </Typography>
                        )}
                      </Grid>
                    </>
                  ) : (
                    <>
                      {/* Mobile Number */}
                      <Grid item xs={12} md={2} pr={2}>
                        <InputLabel className="fw-bold">
                          {"Mobile Number "}
                          <Asterisk />
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="mobile"
                          inputProps={{ minLength: 10, maxLength: 10 }}
                          placeholder={content.mobileNumber}
                          value={individualAccountData.mobile}
                          onChange={handleIndividualForm}
                          error={Boolean(individualAccountFormErrors?.mobile)}
                          helperText={individualAccountFormErrors?.mobile || ""}
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}

                  {/* Account Category */}
                  <Grid item xs={12} md={3} pr={2}>
                    <InputLabel
                      className="fw-bold"
                      sx={{ textAlign: !isSmallerScreen ? "right" : "inherit" }}
                    >
                      {"Account Category "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Select
                      name="account_category"
                      value={individualAccountData.account_category}
                      onChange={handleIndividualForm}
                      size="small"
                      error={Boolean(
                        individualAccountFormErrors?.account_category
                      )}
                      fullWidth
                      displayEmpty
                    >
                      <MenuItem className="select-menu-item" value="">
                        Select Category
                      </MenuItem>
                      {Object.keys(categories).map((key) => (
                        <MenuItem key={key} value={categories[key]}>
                          {categories[key]}
                        </MenuItem>
                      ))}
                    </Select>
                    {individualAccountFormErrors?.account_category && (
                      <Typography variant="caption" color="error" pl={2} m={0}>
                        {individualAccountFormErrors?.account_category}
                      </Typography>
                    )}
                  </Grid>

                  {edit && (
                    <>
                      {/* Mobile Number */}
                      <Grid item xs={12} md={2} pr={2}>
                        <InputLabel className="fw-bold">
                          {"Mobile Number "}
                          <Asterisk />
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="mobile"
                          inputProps={{ minLength: 10, maxLength: 10 }}
                          placeholder={content.mobileNumber}
                          value={individualAccountData.mobile}
                          onChange={handleIndividualForm}
                          error={Boolean(individualAccountFormErrors?.mobile)}
                          helperText={individualAccountFormErrors?.mobile || ""}
                          size="small"
                          fullWidth
                        />
                      </Grid>

                      {/* Email} */}
                      <Grid item xs={12} md={3} pr={2}>
                        <InputLabel
                          className="fw-bold"
                          sx={{
                            textAlign: !isSmallerScreen ? "right" : "inherit",
                          }}
                        >
                          {"Email"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          type="email"
                          name="email"
                          placeholder={content.email}
                          value={individualAccountData.email}
                          onChange={handleIndividualForm}
                          error={Boolean(individualAccountFormErrors?.email)}
                          helperText={individualAccountFormErrors?.email || ""}
                          size="small"
                          fullWidth
                        />
                      </Grid>

                      {/* Occupation */}
                      <Grid item xs={12} md={2} pr={2}>
                        <InputLabel className="fw-bold">
                          {"Occupation"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <Select
                          name="occupation"
                          value={individualAccountData.occupation}
                          onChange={handleIndividualForm}
                          size="small"
                          error={Boolean(
                            individualAccountFormErrors?.occupation
                          )}
                          fullWidth
                          displayEmpty
                        >
                          <MenuItem className="select-menu-item" value="">
                            Select Occupation
                          </MenuItem>
                          {Object.keys(occupations).map((key) => (
                            <MenuItem key={key} value={occupations[key]}>
                              {occupations[key]}
                            </MenuItem>
                          ))}
                        </Select>
                        {individualAccountFormErrors?.account_category && (
                          <Typography
                            variant="caption"
                            color="error"
                            pl={2}
                            m={0}
                          >
                            {individualAccountFormErrors?.occupation}
                          </Typography>
                        )}
                      </Grid>

                      {/* DOB */}
                      <Grid item xs={12} md={3} pr={2}>
                        <InputLabel
                          className="fw-bold"
                          sx={{
                            textAlign: !isSmallerScreen ? "right" : "inherit",
                          }}
                        >
                          {"DOB"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          type="date"
                          placeholder={content.dob}
                          name="dob"
                          value={individualAccountData.dob}
                          onChange={handleIndividualForm}
                          error={Boolean(individualAccountFormErrors?.dob)}
                          helperText={individualAccountFormErrors?.dob || ""}
                          size="small"
                          fullWidth
                        />
                      </Grid>

                      {/* Aadhar Number} */}
                      <Grid item xs={12} md={2} pr={2}>
                        <InputLabel className="fw-bold">
                          {"Aadhar Number"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="aadhar"
                          inputProps={{ minLength: 12, maxLength: 12 }}
                          placeholder={content.aadharNumber}
                          value={individualAccountData.aadhar}
                          onChange={handleIndividualForm}
                          error={Boolean(individualAccountFormErrors?.aadhar)}
                          helperText={individualAccountFormErrors?.aadhar || ""}
                          size="small"
                          fullWidth
                        />
                      </Grid>

                      {/* PAN Number */}
                      <Grid
                        item
                        xs={12}
                        md={3}
                        pr={2}
                        sx={{
                          textAlign: !isSmallerScreen ? "right" : "inherit",
                        }}
                      >
                        <InputLabel className="fw-bold">
                          {"PAN Number"}
                        </InputLabel>
                      </Grid>
                      <Grid item xs={12} md={3.5} mb={2}>
                        <TextField
                          name="pan"
                          inputProps={{ minLength: 10, maxLength: 10 }}
                          placeholder={content.panNumber}
                          value={individualAccountData.pan}
                          onChange={handleIndividualForm}
                          error={Boolean(individualAccountFormErrors?.pan)}
                          helperText={individualAccountFormErrors?.pan || ""}
                          size="small"
                          fullWidth
                        />
                      </Grid>
                    </>
                  )}

                  {/* Country */}
                  <Grid item xs={12} md={2} pr={2}>
                    <InputLabel className="fw-bold">
                      {"Country "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Autocomplete
                      name="country"
                      onChange={(event, value) =>
                        handleIndividualForm("country", value?.value || "")
                      }
                      value={
                        countries.find(
                          (country) =>
                            country.value === individualAccountData.country
                        ) || null
                      }
                      options={countries}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(individualAccountFormErrors?.country)}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    {individualAccountFormErrors?.country && (
                      <Typography variant="caption" color="error" pl={2} m={0}>
                        {individualAccountFormErrors?.country}
                      </Typography>
                    )}
                  </Grid>

                  {/* State */}
                  <Grid item xs={12} md={3} pr={2}>
                    <InputLabel
                      className="fw-bold"
                      sx={{ textAlign: !isSmallerScreen ? "right" : "inherit" }}
                    >
                      {"State "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Autocomplete
                      name="state"
                      onChange={(event, value) =>
                        handleIndividualForm("state", value?.value || "")
                      }
                      value={
                        states.find(
                          (state) => state.value === individualAccountData.state
                        ) || null
                      }
                      options={states}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(individualAccountFormErrors?.state)}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    {individualAccountFormErrors?.state && (
                      <Typography variant="caption" color="error" pl={2} m={0}>
                        {individualAccountFormErrors?.state}
                      </Typography>
                    )}
                  </Grid>

                  {/* City */}
                  <Grid item xs={12} md={2} pr={2}>
                    <InputLabel className="fw-bold">
                      {"City "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <Autocomplete
                      name="city"
                      onChange={(event, value) =>
                        handleIndividualForm("city", value?.value || "")
                      }
                      value={
                        cities.find(
                          (city) => city.value === individualAccountData.city
                        ) || null
                      }
                      options={cities}
                      autoHighlight
                      getOptionLabel={(option) => option.name}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.name}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          size="small"
                          error={Boolean(individualAccountFormErrors?.city)}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: "new-password",
                          }}
                        />
                      )}
                    />
                    {individualAccountFormErrors?.city && (
                      <Typography variant="caption" color="error" pl={2} m={0}>
                        {individualAccountFormErrors?.city}
                      </Typography>
                    )}
                  </Grid>

                  {/* Pin Code} */}
                  <Grid item xs={12} md={3} pr={2}>
                    <InputLabel
                      className="fw-bold"
                      sx={{ textAlign: !isSmallerScreen ? "right" : "inherit" }}
                    >
                      {"Pin Code "}
                      <Asterisk />
                    </InputLabel>
                  </Grid>
                  <Grid item xs={12} md={3.5} mb={2}>
                    <TextField
                      name="pincode"
                      placeholder={content.pincode}
                      value={individualAccountData.pincode}
                      onChange={handleIndividualForm}
                      error={Boolean(individualAccountFormErrors?.pincode)}
                      helperText={individualAccountFormErrors?.pincode || ""}
                      size="small"
                      fullWidth
                    />
                  </Grid>
                </Grid>

                {/* Street Address */}
                <Grid item xs={12} md={2} pr={2}>
                  <InputLabel className="fw-bold">
                    {"Street Address"}
                  </InputLabel>
                </Grid>
                <Grid item xs={12} md={10} mb={2}>
                  <TextField
                    name="street_address"
                    placeholder={content.streetAddress}
                    value={individualAccountData.street_address}
                    onChange={handleIndividualForm}
                    error={Boolean(individualAccountFormErrors?.street_address)}
                    helperText={
                      individualAccountFormErrors?.street_address || ""
                    }
                    size="small"
                    fullWidth
                  />
                </Grid>

                {edit && (
                  <>
                    {/* Website */}
                    <Grid item xs={12} md={2} pr={2}>
                      <InputLabel className="fw-bold">{"Website"}</InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} mb={2}>
                      <TextField
                        name="website"
                        placeholder={content.website}
                        value={individualAccountData.website}
                        onChange={handleIndividualForm}
                        error={Boolean(individualAccountFormErrors?.website)}
                        helperText={individualAccountFormErrors?.website || ""}
                        size="small"
                        fullWidth
                      />
                    </Grid>

                    {/* Description */}
                    <Grid item xs={12} md={2} pr={2}>
                      <InputLabel className="fw-bold">
                        {"Description"}
                      </InputLabel>
                    </Grid>
                    <Grid item xs={12} md={10} mb={2}>
                      <TextField
                        name="description"
                        placeholder={content.description}
                        value={individualAccountData.description}
                        onChange={handleIndividualForm}
                        error={Boolean(
                          individualAccountFormErrors?.description
                        )}
                        helperText={
                          individualAccountFormErrors?.description || ""
                        }
                        size="small"
                        fullWidth
                        multiline
                        rows={2}
                      />
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"end"}
        height="100%"
      >
        <Divider className="horizontal-line" sx={{ my: 1 }} />

        <Box
          my={3}
          display={"flex"}
          justifyContent={{ xs: "space-between", sm: "flex-start" }}
          columnGap={5}
        >
          <Button
            variant="contained"
            type="button"
            className={`primary-btn ${
              isSmallerScreen ? "primary-small-btn" : ""
            }`}
            onClick={handleDrawerClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            type="submit"
            className={`primary-btn ${
              isSmallerScreen ? "primary-small-btn" : ""
            }`}
            onClick={(e) => {
              if (edit) {
                individualAccount
                  ? handleIndividualFormEdit(e)
                  : handleOrganizationFormEdit(e);
              } else {
                individualAccount
                  ? handleIndividualFormSubmit(e)
                  : handleOrganizationFormSubmit(e);
              }
            }}
            disabled={accountState?.editAccountLoading || accountState?.addAccountLoading}
            endIcon={(accountState?.editAccountLoading || accountState?.addAccountLoading) && <CircularProgress size={25}/>}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}
