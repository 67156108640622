import React from "react";
import ReactDOM from "react-dom/client";
import { pdfjs } from 'react-pdf';
import "./index.scss";
import App from "./App";
import { Provider } from "react-redux";
import { store } from "./app/store";
import { createTheme, ThemeProvider } from "@mui/material";
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;


const theme = createTheme({
  palette: {
    primary: {
      main: "#4DAA98",
      contrastText: "#fff",
    },
  },
  components: {
    MuiTooltip: {
      defaultProps: {
        placement: "bottom-start",
        slotProps: {
          tooltip: {
            className: "sidenav-bg",
          },
          popper: {
            modifiers: [
              {
                name: "offset",
                options: {
                  offset: [0, -14],
                },
              },
            ],
          },
        },
        arrow: true,
      },
    },
    MuiMenu: {
      defaultProps: {
        anchorOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        keepMounted: true,
        transformOrigin: {
          vertical: "top",
          horizontal: "right",
        },
        disableScrollLock: true,
        slotProps: {
          paper: {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              borderRadius: "10px",
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          },
        },
      },
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <App />
    </Provider>
  </ThemeProvider>
);
