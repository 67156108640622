import { accountTypes } from "../../enums/Account";
import "./CustomizedOptions.scss";
const accountOption = ({ innerProps, label, data, isSelected }) => (
    <div {...innerProps} className={`custom-async ${isSelected && "selected-option"}`}>
        {label}
        <div className="option-details">
            <div>
                {data.additionalData.account_type === accountTypes.Organization ? (
                    <div>GSTIN: {data.additionalData.gstin} </div>
                ) : (
                    <div>
                        {data.additionalData.city && `${data.additionalData.city}`}
                        {data.additionalData.mobile && `, ${data.additionalData.mobile}`}
                    </div>
                )}
            </div>
        </div>
    </div>
);

const userOption = ({ innerProps, label, data, isSelected }) => (
    <div {...innerProps} className={`custom-async ${isSelected && "selected-option"}`}>
        {label}
        <div className="option-details">
            <div>
                <div>
                    {data.additionalData.location ? data.additionalData.location : ""}
                    {data.additionalData.mobile ? (data.additionalData.location ? `, ${data.additionalData.mobile}` : `${data.additionalData.mobile}`) : ""}
                </div>
            </div>
        </div>
    </div>
);

export { accountOption, userOption };
