import { accountActions } from "../../reducers/bd-reducers/accounts.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { timeFrameParam } from "../../../constants/queries";
import { accountApi } from "../../../constants/apis/BD/Account";
import { toast } from "react-toastify";
import { bdDashboardApi } from "../../../constants/apis/BD/BdDashboard";

const axiosInstance = createInstance();

/**
 * getDeals - Get key accounts data
 * @param {string} timeFrame
 */
export const getDashboardAccounts = (timeFrame) => async (dispatch) => {
  try {
    dispatch(accountActions.getDashboardAccountsRequest());

    const { data } = await axiosInstance.get(bdDashboardApi + "dashboard-key-accounts".concat(`?timeframe=${timeFrame}`));

    dispatch(accountActions.getDashboardAccountsSuccess(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get key accounts");
    dispatch(accountActions.getDashboardAccountsFail(error.response.data.message));
  }
};

export const getIndividualAccount = (accountId) => async (dispatch) => {
  try {
    dispatch(accountActions.getAllAccountsRequest());

    const { data } = await axiosInstance.get(accountApi + `${accountId}`);

    dispatch(accountActions.setSelectedDashboardAccountWithAccountData(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get account");
    dispatch(accountActions.getAllAccountsFail(error.response.data.message));
  }
};

export const getAllAccounts = (params) => async (dispatch) => {
  try {
    dispatch(accountActions.getAllAccountsRequest());

    const { data } = await axiosInstance.get(accountApi + `?${params}`);

    dispatch(accountActions.getAllAccountsSuccess(data.data));
  } catch (error) {
    errorHandler(error, "Failed to get accounts");
    dispatch(accountActions.getAllAccountsFail(error.response.data.message));
  }
};

export const editAccountById = (id, payload, forDashboard) => async (dispatch) => {
  try {
    dispatch(accountActions.editAccountRequest());
    const { data } = await axiosInstance.put(`${accountApi}${id}`, payload);

    if (data.success) {
      dispatch(accountActions.editAccountSuccess({ editedAccount: data.data, forDashboard }));
      toast.success("Account updated successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to update account");
    dispatch(accountActions.editAccountFail(error.response.data.message));
  }
};

export const deleteAccountById = (id) => async (dispatch) => {
  try {
    dispatch(accountActions.deleteAccountRequest());

    const { data } = await axiosInstance.delete(accountApi.concat(id));

    if (data.success) {
      dispatch(accountActions.deleteAccountSuccess(id));
      toast.success("Account deleted successfully");
    }
  } catch (error) {
    errorHandler(error, "Account deletion failed");
    dispatch(accountActions.deleteAccountFail());
  }
};

export const getSelectedAccountMetaData = (accountId) => async (dispatch) => {
  try {
    dispatch(accountActions.getMetaDataRequest());

    const { data } = await axiosInstance.get(accountApi + "/counts/" + accountId);

    if (data.success) {
      dispatch(accountActions.getMetaDataSuccess(data.data));
    }
  } catch (error) {
    errorHandler(error, "Failed to get account meta data");
    dispatch(accountActions.getMetaDataFail(error.response.data.message));
  }
};

export const addAccount = (payload) => async (dispatch) => {
  try {
    dispatch(accountActions.addAccountRequest());

    const { data } = await axiosInstance.post(accountApi, payload);

    if (data.success) {
      dispatch(accountActions.addAccountSuccess(data.data));
      toast.success("Account added successfully");
    }

  } catch (error) {
    errorHandler(error, "Failed to add account");
    dispatch(accountActions.addAccountFail(error.response.data.message));
  }
};
