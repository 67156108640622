import { React, useEffect, useState } from "react";
import tsSidebarMenus from "../../../data/json/tsSidebarMenus.json";
import { DebounceInput } from "react-debounce-input";
import { ticketsContent as ticket } from "../../../constants/helperText/Tickets";
import ticketColumns from "../../../data/json/ticketColumns.json";
import { errorHandler } from "../../../utils/Request/ReqUtils";
import { createSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { userRoles } from "../../../enums/Auth";
import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import AddIcon from "@mui/icons-material/Add";
import TicketForm from "../../../components/TsForms/TicketForm";
import TicketDetails from "../../../components/EntityDetails/TicketDetails/TicketDetails";
import TableMenu from "../../../components/TableMenu/TableMenu";
import getTableCell from "../../../utils/Common/DataTable";
import { deleteTicketById, getAllTickets } from "../../../redux/actions/ts-actions/ticket.action";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { ticketActions } from "../../../redux/reducers/ts-reducers/ticket.reducer";

const Tickets = () => {
  const profileData = useSelector((state) => state.userProfile.profileData);
  const ticketsState = useSelector((state) => state.tickets1);
  const canCreateTicket = () => {
    return [userRoles.Admin, userRoles.User].includes(profileData?.role_id);
  };
  const isUser = () => {
    return [userRoles.User].includes(profileData?.role_id);
  };

  const appSidebar = JSON.parse(JSON.stringify(tsSidebarMenus));
  appSidebar.menuList[1].currentPage = true;
  if (!canCreateTicket()) {
    appSidebar.menuList[1].addEntityButton = false;
  }

  if (isUser()) {
    appSidebar.menuList.splice(0, 1);
    appSidebar.menuList.splice(1, 1);
  }

  const [filterButtonAnchorEl, setFilterButtonAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [order, setOrder] = useState("");
  const [orderBy, setOrderBy] = useState("");
  const [searched, setSearched] = useState("");
  const [status, setStatus] = useState("");
  const dispatch = useDispatch();
  const [ticketsAssignFilter, setTicketsAssignFilter] = useState("assigned");
  const [filterStatusType, setFilterStatusType] = useState({
    open: false,
    closed: false,
  });
  const params = createSearchParams({
    search: searched,
    sort_order: order,
    sort_by: orderBy,
    page: currentPage + 1,
    rows_per_page: rowsPerPage,
    status: status,
    vendorFilter: ticketsAssignFilter,
  });

  const fetchData = async () => {
    try {
      dispatch(getAllTickets(params));
    } catch (err) {
      errorHandler(err, "Failed to fetch tickets");
    }
  };

  const handleSearch = (e) => {
    setCurrentPage(0);
    setSearched(e.target.value);
  };

  const setAddTicketForm = () => {
    setTicketEditProp(false);
    setIsTicketFormOpen(true);
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const deleteRow = async (id) => {
    try {
      dispatch(deleteTicketById(id));
    } catch (err) {
      errorHandler(err, "Ticket addition failed");
    }
  };

  const fillDataInForm = (id) => {
    dispatch(ticketActions.setSelectedTicketId(id));
    setTicketEditProp(true);
    setIsTicketFormOpen(true);
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const openDetailsForm = (id) => {
    dispatch(ticketActions.setSelectedTicketId(id));
    setIsTicketDetailsOpen(true);
  };

  useEffect(() => {
    fetchData();
  }, [
    currentPage,
    rowsPerPage,
    searched,
    order,
    orderBy,
    status,
    ticketsAssignFilter,
  ]);

  const handleStatusFilter = (e) => {
    setFilterStatusType({
      ...{
        open: false,
        closed: false,
      },
      [e.target.name]: e.target.checked,
    });
    setStatus(e.target.name);
  };

  const handleAssignFilter = (e) => {
    if (e.target.checked) {
      setTicketsAssignFilter("all");
    } else {
      setTicketsAssignFilter("assigned");
    }
  };

  const clearStatusFilter = (e) => {
    setStatus("");
    setFilterStatusType({
      open: false,
      closed: false,
    });
    setTicketsAssignFilter("assigned");
  };

  const handleFilterButtonClick = (event) => {
    setFilterButtonAnchorEl(event.currentTarget);
  };

  const handleFilterButtonClose = () => {
    setFilterButtonAnchorEl(null);
  };

  const [isTicketFormOpen, setIsTicketFormOpen] = useState(false);
  const [ticketEditProp, setTicketEditProp] = useState(false);
  const [isTicketDetailsOpen, setIsTicketDetailsOpen] = useState(false);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isTicketFormOpen && (
          <TicketForm
            isOpenProp={isTicketFormOpen}
            handleClose={() => setIsTicketFormOpen(false)}
            ticketEditProp={ticketEditProp}
          />
        )}
        {isTicketDetailsOpen && (
          <TicketDetails
            isOpenProp={isTicketDetailsOpen}
            handleClose={() => setIsTicketDetailsOpen(false)}
          />
        )}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Tickets</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnSpacing={2}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Grid item xs={12} md={"auto"} my={1}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              {canCreateTicket() && (
                <Grid item xs={8} md={"auto"}>
                  <Button
                    variant="outlined"
                    onClick={() => setAddTicketForm()}
                    startIcon={<AddIcon />}
                  >
                    {ticket.newTicket}
                  </Button>
                </Grid>
              )}
              <Grid item xs={4} md={"auto"} textAlign={"end"}>
                <IconButton onClick={handleFilterButtonClick}>
                  <FilterAltIcon />
                </IconButton>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-add-button"
                  disableScrollLock
                  anchorEl={filterButtonAnchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(filterButtonAnchorEl)}
                  onClose={handleFilterButtonClose}
                  slotProps={{
                    paper: {
                      elevation: 0,
                      sx: {
                        overflow: "visible",
                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                        mt: 1.5,
                        borderRadius: "10px",
                        "& .MuiAvatar-root": {
                          width: 32,
                          height: 32,
                          ml: -0.5,
                          mr: 1,
                        },
                        "&::before": {
                          content: '""',
                          display: "block",
                          position: "absolute",
                          top: 0,
                          right: 14,
                          width: 10,
                          height: 10,
                          bgcolor: "background.paper",
                          transform: "translateY(-50%) rotate(45deg)",
                          zIndex: 0,
                        },
                      },
                    },
                  }}
                >
                  <Box sx={{ p: 1, textAlign: "start" }}>
                    <Typography color={"textSecondary"}>Show Only</Typography>
                  </Box>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Radio
                      name="open"
                      id="account-individual"
                      onChange={(e) => handleStatusFilter(e)}
                      checked={filterStatusType.open}
                    />
                    <Typography textAlign="center">Opened</Typography>
                  </MenuItem>
                  <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                    <Radio
                      name="closed"
                      id="account-organization"
                      onChange={(e) => handleStatusFilter(e)}
                      checked={filterStatusType.closed}
                    />
                    <Typography textAlign="center">Closed</Typography>
                  </MenuItem>
                  {[userRoles.Vendor, userRoles.ServiceProvider].includes(
                    profileData?.role_id
                  ) && (
                    <Box>
                      <Divider className="horizontal-line" sx={{ my: 1 }} />
                      <MenuItem sx={{ pr: 4, py: 1, textAlign: "start" }}>
                        <Checkbox
                          name="all-tickets"
                          onChange={(e) => handleAssignFilter(e)}
                          checked={ticketsAssignFilter === "all"}
                        />
                        <Typography textAlign="center">All Tickets</Typography>
                      </MenuItem>
                    </Box>
                  )}
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  <MenuItem
                    onClick={() => clearStatusFilter()}
                    sx={{ pr: 4, py: 1, textAlign: "start" }}
                  >
                    <Typography className="primary-text" fontWeight={"bold"}>
                      Clear Filter
                    </Typography>
                  </MenuItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {ticketsState.ticketsLoading ? (
            <LoadingSpinner />
          ) : ticketsState.tickets.rows && ticketsState.tickets.rows.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                  <Table size="medium">
                    <TableHead className="stat-card-bg">
                      <TableRow>
                        { ticketColumns.map((col) => getTableCell(col, orderBy, order, handleSortRequest)) }
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {ticketsState.tickets.rows &&
                        ticketsState.tickets.rows.map((ticketRow) => {
                          return (
                            <TableRow key={ticketRow.ticket_id}>
                              <Tooltip title={ticketRow.title}>
                                <TableCell
                                  sx={{ maxWidth: "250px" }}
                                  className="text-truncate primary-text link-hover"
                                  onClick={() => openDetailsForm(ticketRow.ticket_id)}
                                >
                                  {ticketRow.title}
                                </TableCell>
                              </Tooltip>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {ticketRow.asset_name}
                              </TableCell>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {ticketRow.vendor_name}
                              </TableCell>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {ticketRow.support_status}
                              </TableCell>

                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {ticketRow.priority}
                              </TableCell>

                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {ticketRow.type}
                              </TableCell>
                              <TableCell align="right">
                                <TableMenu
                                  fillDataInForm={fillDataInForm}
                                  rowId={ticketRow.id}
                                  isDeleteOptionEnabled={true}
                                  deleteModalData={['Ticket', ticketRow.title]}
                                  handleDeleteRow={() => deleteRow(ticketRow.ticket_id)}
                                />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={ticketsState.tickets?.meta?.total || 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Tickets;
