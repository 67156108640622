import { messages } from "../../constants/validationMessages/BdForms/AddLocation";
const Joi = require("joi");

const locationSchema = Joi.object().keys({
    location_name: Joi.string().min(3).max(255).required().messages({
        "string.max": messages.locationNameMax,
        "string.min": messages.locationNameMin,
        "string.empty": messages.locationNameDefault,
    }),

    location_code: Joi.string().allow("").min(3).max(30).messages({
        "string.min": messages.locationCodeMin,
        "string.max": messages.locationCodeMax,
    }),

    address: Joi.string().allow("").max(1024).messages({
        "string.max": messages.addressMax,
    }),
});

export { locationSchema };
