import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { ticketApi } from "../../../constants/apis/TS/Tickets";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import moment from "moment";
import { userApi } from "../../../constants/apis/User";
import { Avatar, Box, Divider, Drawer, Grid, IconButton, Typography, useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

const TicketDetails = ({ isOpenProp, handleClose }) => {
    const theme = useTheme();
    const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

    const profileData = useSelector((state) => state.userProfile.profileData);
    const ticketsState = useSelector((state) => state.tickets1);

    const [ticketData, setTicketData] = useState({});
    const [userData, setUserData] = useState({});
    const [detailsLoading, setDetailsLoading] = useState(true);
    const [comments, setComments] = useState([]);

    const [isOpen, setIsOpen] = useState(isOpenProp);
    const handleDrawerClose = _ => {
        handleClose();
        setIsOpen(false);
    }

    const getNameInitial = (name) => {
        return name.substring(0, 1).toUpperCase();
    }

    const avatarColors = new Map();
    const getAvatarColor = (created_by, theme) => {
        if (!avatarColors.has(created_by)) {
            const color = avatarColors.size === 0 ? theme.palette.primary.main : theme.palette.warning.main;
            avatarColors.set(created_by, color);
        }
    
        return avatarColors.get(created_by);
    }

    const fetchComments = async (ticketId) => {
        try {
            const axiosInstance = createInstance(true);
            const result = await axiosInstance.get(ticketApi + `comments?ticketId=${ticketId}`);
            if (result.data.success) {
                const fetchedComments = result.data.data;
                setComments(fetchedComments);
            } else {
                toast.error("Failed to fetch comments", { autoClose: autoCloseToastError });
            }
        } catch (err) {
            errorHandler(err, "Failed to fetch comments");
        }
    };

    useEffect(() => {
        const fetchTicketData = (ticketId) => {
            setTicketData((ticketsState.tickets.rows.filter(ticket => ticket.ticket_id === ticketId))?.[0]);
        };

        const fetchUserData = (userId) => {
            try {
                const axiosInstace = createInstance(true);
                axiosInstace
                    .get(userApi.concat(`/${userId}`))
                    .then((res) => {
                        if (res.data.success) {
                            const fetchedUserData = res.data.data[0];
                            setUserData({ ...fetchedUserData });
                        } else {
                            throw new Error("Failed to fetch user details");
                        }
                    })
                    .catch((err) => {
                        errorHandler(err, "Failed to fetch user details.");
                    });
            } catch (err) {
                toast.error("Failed to fetch user details.", { autoClose: autoCloseToastError });
            }
        };

        if (isOpenProp) {
            setDetailsLoading(true);
            fetchTicketData(ticketsState.selectedTicketId);
            fetchComments(ticketsState.selectedTicketId);
            setDetailsLoading(false);
        }
    }, []);

    return (
        <Drawer
            PaperProps={{
                sx: {
                width: { sm: "70vw", md: "85vw", lg: "65vw", xl: "55vw" },
                px: { xs: 2, md: 4 },
                },
            }}
            anchor={"right"}
            open={isOpen}
            onClose={handleDrawerClose}
            >
            <Box
                my={2}
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
            >
                <Typography variant="h5">Ticket Details</Typography>
                <IconButton aria-label="close" onClick={handleDrawerClose} color="primary">
                    <CloseIcon />
                </IconButton>
            </Box>

            <Divider className="horizontal-line" />
            {
                detailsLoading ?
                <LoadingSpinner /> : <>
                <Box my={!isSmallerScreen ? 3 : 2}>
                    {/* Content */}
                    <Grid container>
                        {/* Title */}
                        <Grid item xs={12} md={2} pr={2}>
                            <Typography
                                variant="body1"
                                className="fw-bold"
                            >
                                {"Title"}
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>
                            <Typography
                                variant="body1"
                                mb={2}
                                >
                                    {ticketData.title}
                            </Typography>
                        </Grid>

                        {/* Double Column */}
                        <Grid container item md={12}>
                            
                            {/* Asset Name */}
                            <Grid item xs={12} md={2} pr={2}>
                                <Typography
                                    variant="body1"
                                    className="fw-bold"
                                >
                                    {"Asset"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} mb={2}>
                                <Typography
                                    variant="body1"
                                    >
                                        {ticketData.asset_name}
                                </Typography>
                            </Grid>

                            {/* Asset Tag */}
                            <Grid item xs={12} md={2} pr={2}>
                                <Typography
                                    variant="body1"
                                    className="fw-bold"
                                >
                                    {"Asset Tag"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} mb={2}>
                                <Typography
                                    variant="body1"
                                    >
                                        {ticketData.asset_tag}
                                </Typography>
                            </Grid>

                            {/* Status */}
                            <Grid item xs={12} md={2} pr={2}>
                                <Typography
                                    variant="body1"
                                    className="fw-bold"
                                >
                                    {"Status"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} mb={2}>
                                <Typography
                                    variant="body1"
                                    >
                                        {ticketData.support_status}
                                </Typography>
                            </Grid>

                            {/* Priority */}
                            <Grid item xs={12} md={2} pr={2}>
                                <Typography
                                    variant="body1"
                                    className="fw-bold"
                                >
                                    {"Priority"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} mb={2}>
                                <Typography
                                    variant="body1"
                                    >
                                        {ticketData.priority}
                                </Typography>
                            </Grid>

                            {/* Type */}
                            <Grid item xs={12} md={2} pr={2}>
                                <Typography
                                    variant="body1"
                                    className="fw-bold"
                                >
                                    {"Type"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} mb={2}>
                                <Typography
                                    variant="body1"
                                    >
                                        {ticketData.type}
                                </Typography>
                            </Grid>

                            {/* Vendor */}
                            <Grid item xs={12} md={2} pr={2}>
                                <Typography
                                    variant="body1"
                                    className="fw-bold"
                                >
                                    {"Vendor"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={4} mb={2}>
                                <Typography
                                    variant="body1"
                                    >
                                        {ticketData.vendor_name}
                                </Typography>
                            </Grid>
                        </Grid>
                        
                        {/* Description */}
                        {ticketData.description && <>
                            <Grid item xs={12} md={2} pr={2}>
                                <Typography
                                    variant="body1"
                                    className="fw-bold"
                                >
                                    {"Description"}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={10}>
                                <Typography
                                    variant="body1"
                                    >
                                        {ticketData.description}
                                </Typography>
                            </Grid>
                        </>
                        }
                    </Grid>

                    <Divider className="horizontal-line" sx={{ my: 1 }} />
                    
                </Box>
                    <Box mb={2}>
                        <Typography variant="h6">Comments</Typography>
                    </Box>
                    <Grid container mb={2}>
                        <Divider className="horizontal-line" sx={{ my: 1 }} />

                        {/* Comments */}
                        {
                            comments.map((_comment, i) => {
                                return (
                                    <>
                                        <Grid item xs={12} mb={1} container>
                                            {i!==0 && <Divider className="horizontal-line" sx={{ my: 1, width: '100%' }}/>}
                                            <Grid item xs={2} md={1} container alignItems={"center"}>
                                                <Avatar variant="circular" sx={{background: theme => getAvatarColor(_comment.created_by, theme)}} >
                                                    {getNameInitial(_comment.created_by)}
                                                </Avatar>
                                            </Grid>
                                            <Grid item xs={10} md={11}>
                                                <Grid item xs={12} container justifyContent={"space-between"}>
                                                    <Typography variant="h6">
                                                        {_comment.created_by}
                                                    </Typography>
                                                    <Typography variant="body2" color="textSecondary" sx={{ display: 'flex', alignItems: 'center'}} align='right'>
                                                        { (moment(_comment.created_at).format("DD-MM-YYYY")) + ` at ${moment(_comment.created_at).format("HH:mm")}`}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Typography variant="body2">
                                                        {_comment.comment}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </>
                                )
                            })
                        }
                    </Grid>
                </>
            }
            </Drawer>
    );
}

export default TicketDetails;