const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    consumableEdit: false,
    consumableId: null,
    consumableEditOpenToggle: false,
    consumableAddOpenToggle: false,
    consumableAdded: false,
    consumableEdited: false,
}

export const formSlice = createSlice({
    name:"consumableForm",
    initialState,
    reducers:{
        setConsumableEdit : (state, action)=>{
            state.consumableEdit = action.payload;
        },
        setConsumableId : (state, action)=>{
            state.consumableId=action.payload;
        },
        setConsumableEditOpenToggle:(state,action)=>{
            state.consumableEditOpenToggle=!state.consumableEditOpenToggle;
        },
        setConsumableAddOpenToggle:(state,action)=>{
            state.consumableAddOpenToggle=!state.consumableAddOpenToggle;
        },
        setConsumableAdded:(state,action)=>{
            state.consumableAdded=action.payload;
        },
        setConsumableEdited:(state,action)=>{
            state.consumableEdited=action.payload;
        }
}
});
export const {setConsumableEdit, setConsumableId, setConsumableAddOpenToggle, setConsumableEditOpenToggle, setConsumableAdded, setConsumableEdited}=formSlice.actions;
export default formSlice.reducer;