const messages = {
    consumableNameDefault : "Consumable name cannot be empty",
    consumableNameMax: "Maximum 255 characters are allowed",

    make : "Please select make",
    makeValid : "Please select make",

    dateOfPurchase : "Please select date of purchase",

    model : "Please select model",
    modelValid : "Please select model",

    assignedOn : "Please select valid date to assign consumable",

    assignTotal : "Please enter quantity to assign",

    assignedTo : "Please select user to assign consumable",

}

export {messages};