const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    profileDataFetched: false,
    profileFirstTimeOpened: false,
    profileToggle: false,
    firstTimeLoggedIn: false,
    first_name: "",
    last_name: "",
    mobile: "",
    email: "",
    dob: "",
    retirement_date: "",
    userLocation: null,
    location_id: "",
    profileUpdated: false,
    profileData: undefined
};

export const formSlice = createSlice({
    name: "userProfile",
    initialState,
    reducers: {
        setProfileDataFetched: (state, action) => {
            state.profileDataFetched = action.payload;
        },
        setProfileFirstTimeOpened: (state, action) => {
            state.profileFirstTimeOpened = action.payload;
        },
        setProfileToggle: (state, action) => {
            state.profileToggle = !state.profileToggle;
        },
        setFirstTimeLoggedIn: (state, action) => {
            state.firstTimeLoggedIn = action.payload;
        },
        setProfileFormField: (state, action) => {
            const { fieldName, value } = action.payload;
            state[fieldName] = value;
        },
        setProfileUpdated: (state, action) => {
        state.profileUpdated = action.payload;
        },

        setProfileData: (state, action) => {
            state.profileData = action.payload;
        },
    },
});

export const { setProfileDataFetched, setProfileFirstTimeOpened, setProfileToggle, setFirstTimeLoggedIn, setProfileFormField, setProfileUpdated, setProfileData } = formSlice.actions;

export default formSlice.reducer;
