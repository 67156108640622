import { configureStore, combineReducers } from "@reduxjs/toolkit";
import dealFormReducer from "../features/BD/dealFormSlice";
import accountReducer from "../features/BD/accountSlice";
import contactFormReducer from "../features/BD/contactForm";
import taskFormReducer from "../features/BD/taskFormSlice";
import optionsForAsyncSelectReducer from "../features/optionSlice";
import moduleFormReducer from "../features/EL/moduleFormSlice";
import courseFormReducer from "../features/EL/courseFormSlice";
import courseDetailsFormReducer from "../features/EL/courseDetailsForm";
import assetFormReducer from "../features/AM/assetForm"
import courseDetailsReducer from "../features/EL/courseDetailsSlice";
import courseQuestionsFormReducer from "../features/EL/courseQuestionsFormSlice";
import targetFormReducer from "../features/BD/targetFormSlice";
import assignmentFormReducer from "../features/AM/assignmentForm";
import myCoursesReducer from "../features/EL/myCoursesSlice";
import ticketReducer from "../features/TS/ticketSlice";
import assetDetailsReducer from "../features/AM/assetDetailsSlice"
import ticketDetailsReducer from "../features/TS/ticketDetailsSlice";
import assignmentDetailsReducer from "../features/AM/assignmentDetailsSlice";
import consumableFormReducer from "../features/AM/consumableForm"
import consumableAssignmentFormReducer from "../features/AM/consumableAssignmentForm";
import consumableAssignmentDetailsReducer from "../features/AM/consumableAssignmentDetailsSlice";
import consumableDetailsReducer from "../features/AM/cosnumableDetailsSlice";
import locationFormReducer from "../features/BD/locationFormSlice";
import userProfileReducer from "../features/userSlice";
import passwordChangeFormReducer from "../features/passwordChangeFormSlice";
import vendorFormReducer from "../features/AM/vendorForm"
import vendorDetailsReducer from "../features/AM/vendorDetailsSlice";
import { userLogout } from "../constants/Common";
import { dashboardReducer } from "../redux/reducers/bd-reducers/dashboard.reducer";
import { dealReducer } from "../redux/reducers/bd-reducers/deals.reducer";
import { taskReducer } from "../redux/reducers/bd-reducers/tasks.reducer";
import { contactReducer } from "../redux/reducers/bd-reducers/contacts.reducer";
import { accountsReducer } from "../redux/reducers/bd-reducers/accounts.reducer";
import { ticketReducer1 } from "../redux/reducers/ts-reducers/ticket.reducer";
import { consumableReducer } from "../redux/reducers/am-reducers/consumables.reducer";
import { assignmentReducer } from "../redux/reducers/am-reducers/assignments.reducer";
import { assetReducer } from "../redux/reducers/am-reducers/assets.reducer";
import { targetReducer } from "../redux/reducers/bd-reducers/targets.reducer";
import { moduleReducer } from "../redux/reducers/el-reducers/modules.reducer";
import { locationReducer } from "../redux/reducers/bd-reducers/location.reducer";

const rootReducer = combineReducers({
  account: accountReducer,
  dealForm: dealFormReducer,
  contactForm: contactFormReducer,
  taskForm: taskFormReducer,
  optionsForAsyncSelect: optionsForAsyncSelectReducer,
  moduleForm: moduleFormReducer,
  courseForm: courseFormReducer,
  courseDetailsForm: courseDetailsFormReducer,
  assetForm: assetFormReducer,
  courseDetails: courseDetailsReducer,
  courseQuestionsForm: courseQuestionsFormReducer,
  targetForm: targetFormReducer,
  assignmentForm: assignmentFormReducer,
  myCourses: myCoursesReducer,
  ticket: ticketReducer,
  ticketDetails: ticketDetailsReducer,
  assetDetails: assetDetailsReducer,
  assignmentDetails: assignmentDetailsReducer,
  consumableForm: consumableFormReducer,
  consumableAssignmentForm: consumableAssignmentFormReducer,
  consumableAssignmentDetails: consumableAssignmentDetailsReducer,
  consumableDetails: consumableDetailsReducer,
  locationForm: locationFormReducer,
  userProfile: userProfileReducer,
  passwordChangeForm: passwordChangeFormReducer,
  vendorForm: vendorFormReducer,
  vendorDetails: vendorDetailsReducer,
  dashboard: dashboardReducer,
  deals: dealReducer,
  tasks: taskReducer,
  contacts: contactReducer,
  accounts: accountsReducer,
  tickets1: ticketReducer1,
  consumables: consumableReducer,
  assignments: assignmentReducer,
  assets: assetReducer,
    targets: targetReducer,
    modules: moduleReducer,
    locations: locationReducer
});


const rootReducerWithLogout = (state, action) => {
  // If the action type is USER_LOGOUT, reset the states
  if (action.type === userLogout) {
    state = undefined;
  }

  return rootReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducerWithLogout
});