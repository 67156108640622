const stages = Object.freeze({
    qualification: "Qualification",
    needsAnalysis: "Needs Analysis",
    identifyDecisionMakers: "Identify Decision Makers",
    proposal: "Proposal",
    negotiation: "Negotiation",
    closedWon: "Closed Won",
    closedLost: "Closed Lost",
});

const probabilities = Object.freeze({
    "30": 0.3,
    "60": 0.6,
    "90": 0.9,
});

module.exports = {stages, probabilities};