const messages = {
    titleDefault: "Title cannot be empty",
    titleMax: "Maximum 255 characters allowed",

    descriptionDefault: "Description cannot be empty",
    descriptionMin: "Description cannot be empty",

    moduleId: "Please select module",

    language: "Please select language",

    duration: "Please select duration",

    passingPercentageDefault: "Invalid value",
    passingPercentageMin: "Passing Percentage cannot be less than 1",
    passingPercentageMax: "Passing Percentage cannot be greater than 100",
    
    courseFile: "File not selected",

    questionText: "Question cannot be empty",

    marksDefault: "Invalid value",
    marksMin: "Marks cannot be less than 0",

    optionText: "Option cannot be empty",
    correctOptionMissing: "Please select correct option",

    is_correct: "Invalid value",

    courseImageChanged: "Course image changed field is required",

    courseFileChanged: "Course file changed field is required"
};

export { messages };
