import { assetActions } from "../../reducers/am-reducers/assets.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { timeFrameParam } from "../../../constants/queries";
import { assetAPI } from "../../../constants/apis/AM/Assets";
import { toast } from "react-toastify";
import { dashboardAPI } from "../../../constants/apis/AM/Dashboard";

const axiosInstance = createInstance();


/**
 * getAssets - Get assets under maintenance
 *
 * Returns Assets data under maintenance using the provided parameters
 *
 * @param {object} params
 */
export const getAssetsOnMaintenance = (params) => async (dispatch) => {
    try {
        dispatch(assetActions.getAssetsOnMaintenanceRequest());

        const { data } = await axiosInstance.get(dashboardAPI + "maintenance-data", {
            params: params,
        });


        dispatch(assetActions.getAssetsOnMaintenanceSuccess(data.data));
    } catch (error) {
        errorHandler(error, "Failed to get assets");
        dispatch(assetActions.getAssetsOnMaintenanceFail());
    }
};

/**
 * getAssets - Get assets data
 *
 * Returns Assets data using the provided parameters
 *
 * @param {object} params
 */
export const getAssets = (params) => async (dispatch) => {
    try {
        dispatch(assetActions.getAssetsRequest());

        const { data } = await axiosInstance.get(assetAPI, {
            params: params,
        });

        dispatch(assetActions.getAssetsSuccess(data.data));
    } catch (error) {

        errorHandler(error, "Failed to get assets");
        dispatch(assetActions.getAssetsFail());
    }
};

/**
 * editAssetById - Edit a asset by ID.
 *
 * Edits a asset with the specified ID using the provided payload.
 *
 * @param {string} id - The ID of the asset to be edited.
 * @param {object} payload - The data to update the asset with.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const editAssetById = (id, payload) => async (dispatch) => {
    try {
        dispatch(assetActions.editAssetRequest());

        const { data } = await axiosInstance.put(assetAPI.concat(id), payload);

        if (data.success) {
            dispatch(
                assetActions.editAssetSuccess({ ...data.data, asset_id: id })
            );
            toast.success("Asset updated successfully");
        }
    } catch (error) {
        errorHandler(error, "Failed to edit asset");
        dispatch(assetActions.editAssetFail());
    }
};

/**
 * addAsset - Add a new asset.
 *
 * Adds a new asset using the provided payload.
 *
 * @param {object} payload - The data for the new asset.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const addAsset = (payload) => async (dispatch) => {
    try {
        dispatch(assetActions.addAssetRequest());

        const { data } = await axiosInstance.post(`${assetAPI}`, payload);

        if (data.success) {
            dispatch(
                assetActions.addAssetSuccess({
                    ...data.data,
                    id: data.data.asset_id,
                })
            );
            toast.success("Asset added successfully");
        }
    } catch (error) {
        errorHandler(error, "Failed to add asset");
        dispatch(assetActions.addAssetFail());
    }
};




