import React from 'react';
import "./PageNotFound.scss";
import { useNavigate } from 'react-router-dom';

const PageNotFound = () => {
  const navigate = useNavigate();

  const navigateToAllapps = () => {
    navigate("/allapps");
  }

  return (
    <div className="pagenotfound-base">
      <div className="pagenotfound-parent">
        <div className="pagenotfound-content">
          <div className="pagenotfound-text-404">
            404
          </div>
          <div className="pagenotfound-main-text">
            OOPS! NOTHING WAS FOUND
          </div>
          <div className="pagenotfound-sub-text">
            The page you are looking for might have been removed had its name changed or is temporarily unavailable
          </div>
          <div className="pagenotfound-return-to-homepage">
            <u><span onClick={() => navigateToAllapps()} href="/allapps" className='return-to-homepage-text'>Return To Homepage</span></u>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound;