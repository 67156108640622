const messages = {
    firstNameDefault: "First name cannot be empty",
    firstNameMax: "Maximum 255 characters allowed",

    lastNameDefault: "Last name cannot be empty",
    lastNameMax: "Maximum 255 characters allowed",

    mobileEmpty: "Mobile number is required",
    mobileDefault: "Mobile number must be of 10 digits",
    mobileRegex: "Only digits are allowed",

    emailMin: "Minimum 3 characters are required",
    emailMax: "Maximum 255 characters are allowed",
    emailEmpty: "Email cannot be empty",
    emailInvalid: "Invalid email entered",

    dobDefault: "Invalid DOB",
    retirementDefault: "Invalid retirement date",
    locationId: "You must select location",
};

export { messages };
