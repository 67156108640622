import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import { probabilities, stages } from "../../../enums/Deal";
import { dealSchema } from "../../../validations/BdForms/Deal";
import { useDispatch, useSelector } from "react-redux";
import { addDeal, editDealById } from "../../../redux/actions/bd-actions/deals.action";
import { toast } from "react-toastify";
import { accountApi } from "../../../constants/apis/BD/Account";
import { userOptionsApi } from "../../../constants/apis/User";
import { searchOptionDebounceTime } from "../../../constants/DealForm";
import {
  asyncSelectNewCustomStyles,
  autoCloseToastError,
  minCharsToLoadOptions,
} from "../../../constants/Common";
import {
  setUserOptionsLoaded,
  setUserOptions,
  setAccountOptionsLoaded,
  setAccountOptions,
} from "../../../features/optionSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { userRoles } from "../../../enums/Auth";

import { accountTypes } from "../../../enums/Account";
import moment from "moment";
import {
  accountOption,
  userOption,
} from "../../AsyncSelectOption/CustomizedOptions";
import {
  FormControl,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Asterisk from "../../Misc/Asterisk";


const DealForm = ({ isDealFormOpen, editDealProp, dealAddAccountSpecific = false, handleDrawerClose, isSmallerScreen, accountSpecific=false, accountData }) => {
  const dispatch = useDispatch();

  const accountOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.accountOptionsLoaded
  );
  const userOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.userOptionsLoaded
  );
  const accountOptions = useSelector(
    (state) => state.optionsForAsyncSelect.accountOptions
  );
  const userOptions = useSelector(
    (state) => state.optionsForAsyncSelect.userOptions
  );

  const profileData = useSelector((state) => state.userProfile.profileData);

  const dealsState = useSelector((state) => state.deals);

  const addDealLoading = dealsState.addDealLoading;
  const editDealLoading = dealsState.editDealPropDealLoading;

  const selectedDealData = dealsState?.selectedDealData;
  const dealAccountName = accountData?.account_name;
  const dealAccountId = accountData?.account_id;


  const initialDealData = {
    account_id: Boolean(selectedDealData?.account_id) ? selectedDealData?.account_id : null,
    assigned_to: Boolean(selectedDealData?.assign_to) ? selectedDealData?.assign_to : null,
    deal_name: Boolean(selectedDealData?.deal_name) ? selectedDealData?.deal_name : "",
    amount: Boolean(selectedDealData?.amount) ? selectedDealData?.amount : "",
    deal_stage: Boolean(selectedDealData?.deal_stage) ? selectedDealData?.deal_stage : stages.qualification,
    deal_next_step: Boolean(selectedDealData?.deal_next_step) ? selectedDealData?.deal_next_step : "",
    probability: Boolean(selectedDealData?.probability) ? probabilities[`${selectedDealData?.probability}`] : probabilities[30],
    description: Boolean(selectedDealData?.description) ? selectedDealData?.description : "",
    due_date: Boolean(selectedDealData?.due_date) ? selectedDealData?.due_date : "",
  };

  const [dealData, setDealData] = useState(initialDealData);
  const [dealFormErrors, setDealFormErrors] = useState({});
  const [dealAccount, setDealAccount] = useState(null);
  const [dealAssignedTo, setDealAssignedTo] = useState(null);
  const [dealAssignedToAccess, setDealAssignedToAccess] = useState(false);

  const fetchUserOptions = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      const response = await axiosInstance.get(
        userOptionsApi + "?search=" + inputValue
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = { location: item.location, mobile: item.mobile };

          return {
            value: item.user_id,
            label: item.assign_to,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching assigned to options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching assigned to options");
    }
    return [];
  };

  const fetchAccountOptions = async (inputvalue) => {
    try {
      const axiosInstance = createInstance(true);
      const response = await axiosInstance.get(
        accountApi + "/accountoptions?search=" + inputvalue
      );

      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = {};

          // Add additional data based on a condition
          if (item.account_type === accountTypes.Organization) {
            additionalData = {
              account_type: item.account_type,
              gstin: item.gstin,
            };
          } else if (item.account_type === accountTypes.Individual) {
            additionalData = {
              account_type: item.account_type,
              mobile: item.mobile,
              city: item.city,
            };
          }

          return {
            value: item?.account_id,
            label: item?.account_name,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching account options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching account options");
    }
    return [];
  };

  const loadAssignedToOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchUserOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const loadAccountOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchAccountOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const closeDealForm = () => {
    handleDrawerClose();
  };
  const handleDealForm = (e) => {
    setDealFormErrors({ ...dealFormErrors, [e.target.name]: "" });
    setDealData({ ...dealData, [e.target.name]: e.target.value });
  };
  const handleDealSubmit = async (e) => {
    e.preventDefault();
    const userId = profileData?.user_id;
    const payload = { ...dealData, created_by: userId };
    const validation = dealSchema.validate(dealData, { abortEarly: false });
    if (validation.error) {
      setDealFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setDealFormErrors(currentErrors);
    } else {
      let payloadDealData = JSON.parse(JSON.stringify(payload));
      dispatch(addDeal(payloadDealData, dealAssignedTo.label, accountSpecific));
      setDealFormErrors({});
    }
  };
  const handleDealEdit = async (e) => {
    e.preventDefault();
    const userId = profileData?.user_id;
    const payload = { ...dealData, updated_by: userId };
    const validation = dealSchema.validate(dealData, { abortEarly: false });
    if (validation.error) {
      setDealFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setDealFormErrors(currentErrors);
    } else {
      let payloadDealData = JSON.parse(JSON.stringify(payload));
      dispatch(editDealById(selectedDealData?.id, payloadDealData, accountSpecific));
      setDealFormErrors({});
    }
  };

  const handleAccountFocus = async () => {
    if (!accountOptionsLoaded) {
      const fetchedOptions = await fetchAccountOptions("");
      dispatch(setAccountOptionsLoaded(true));
      dispatch(setAccountOptions(fetchedOptions));
    }
  };

  const handleUserFocus = async () => {
    if (!userOptionsLoaded) {
      const fetchedOptions = await fetchUserOptions("");
      dispatch(setUserOptionsLoaded(true));
      dispatch(setUserOptions(fetchedOptions));
    }
  };

  useEffect(() => {
    setDealFormErrors({});
    if (editDealProp) {
      const editDealData = {};
      for (let key in dealData) {
        editDealData[key] = selectedDealData[key] || "";
        if (key === "probability") {
          const parsedValue = parseFloat(editDealData[key]);
          editDealData[key] = isNaN(parsedValue) ? 0 : probabilities[parsedValue];
        } else if (key === "due_date") {
          const fetchedDueDate = selectedDealData[key];
          editDealData["due_date"] = fetchedDueDate
            ? moment(fetchedDueDate).format("YYYY-MM-DD")
            : "";
        }
      }

      setDealAccount({
        value: selectedDealData?.account_id,
        label: selectedDealData?.account_name,
      });

      if (selectedDealData.assigned_to) {
        setDealAssignedTo({
          value: selectedDealData?.assigned_to,
          label: selectedDealData?.assigned_to_name,
        });
      } else {
        setDealAssignedTo(null);
      }
      setDealData(editDealData);
    }

    const assignedTo = Number(profileData?.user_id);
    const assignedToName = profileData?.first_name
      .concat(" ")
      .concat(profileData?.last_name);

    if (!editDealProp) {
      setDealAccount(null);
      setDealAssignedTo({
        value: assignedTo,
        label: assignedToName,
      });
      setDealData({ ...initialDealData, assigned_to: assignedTo });
    }

    if (dealAddAccountSpecific) {
      setDealAccount({ value: dealAccountId, label: dealAccountName });
      setDealAssignedTo({
        value: assignedTo,
        label: assignedToName,
      });
      setDealData({
        ...initialDealData,
        account_id: dealAccountId,
        assigned_to: assignedTo,
      });
    }

    const personRole = parseInt(profileData?.role_id);
    const hasInlineEditAccess = [
      userRoles.Admin,
      userRoles.SuperAdmin,
    ].includes(personRole);
    setDealAssignedToAccess(hasInlineEditAccess);
  }, []);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: { sm: "80vw", md: "70vw", lg: "50vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      anchor={"right"}
      open={isDealFormOpen}
      onClose={closeDealForm}
    >
      <Box my={2} display={"flex"} justifyContent={"space-between"}>
        <Typography variant="h5">
          {editDealProp ? "Edit " : "New "}
          Deal
        </Typography>
        <IconButton
          aria-label="close"
          onClick={closeDealForm}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" />


      <Box my={3}>
        <Grid
          container
          rowSpacing={{ xs: 1, md: 4 }}
          columnSpacing={{ xs: 0, md: 10, lg: 15 }}
        >
          {/* Account Name */}
          <Grid item container xs={12}>
            <Grid item xs={12} lg={2.2}>
              <InputLabel>
                {"Account Name "}
                {editDealProp === false ? <Asterisk /> : ""}
              </InputLabel>
            </Grid>
            <Grid item xs={10} lg={9.8}>
              {editDealProp === true || dealAddAccountSpecific ? (
                <>
                  {dealAccount?.label}
                  {Boolean(dealFormErrors?.account_id) && (
                    <Typography variant="caption" color="error" p={2}>
                      {dealFormErrors?.account_id}
                    </Typography>
                  )}
                </>
              ) : (
                ""
              )}
              {editDealProp === false && dealAddAccountSpecific === false ? (
                <FormControl sx={{ width: "100%" }}>
                  <AsyncSelect
                    className={`${Boolean(dealFormErrors?.account_id)
                      ? " error-border"
                      : ""
                      }`}
                    styles={asyncSelectNewCustomStyles}
                    onChange={(selectedOption) => {
                      setDealFormErrors({
                        ...dealFormErrors,
                        ["account_id"]: "",
                      });
                      setDealAccount(selectedOption);
                      if (selectedOption) {
                        setDealData({
                          ...dealData,
                          account_id: selectedOption?.value,
                        });
                      } else {
                        setDealData({ ...dealData, account_id: null });
                      }
                    }}
                    value={dealAccount}
                    defaultOptions={accountOptions}
                    loadOptions={debounce(
                      loadAccountOptions,
                      searchOptionDebounceTime
                    )}
                    placeholder="Type to search..."
                    name="account_id"
                    noOptionsMessage={() =>
                      "No accounts found. Type again..."
                    }
                    isClearable
                    onFocus={handleAccountFocus}
                    components={{ Option: accountOption }}
                  />

                  {Boolean(dealFormErrors?.account_id) && (
                    <Typography variant="caption" color="error" p={2}>
                      {dealFormErrors?.account_id}
                    </Typography>
                  )}
                </FormControl>
              ) : (
                ""
              )}
            </Grid>
          </Grid>

          {/* Deal Title */}
          <Grid item container xs={12}>
            <Grid item xs={12} lg={2.2}>
              <InputLabel>
                {"Deal Title "}
                <Asterisk />
              </InputLabel>
            </Grid>
            <Grid item xs={10} lg={9.8}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  type="text"
                  id="deal-title"
                  name="deal_name"
                  value={dealData.deal_name}
                  onChange={(e) => handleDealForm(e)}
                  placeholder="Deal Title"
                  error={Boolean(dealFormErrors?.deal_name)}
                  helperText={
                    Boolean(dealFormErrors?.deal_name) &&
                    dealFormErrors?.deal_name
                  }
                  size="small"
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Amount */}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} xl={5}>
              <InputLabel>
                Amount&nbsp;&#40;&#8377;&#41;&nbsp;
                <Asterisk />
              </InputLabel>
            </Grid>
            <Grid item xs={10} xl={7}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  type="number"
                  id="deal-amount"
                  name="amount"
                  value={dealData.amount}
                  onChange={(e) => handleDealForm(e)}
                  placeholder="Amount"
                  error={Boolean(dealFormErrors?.amount)}
                  helperText={
                    Boolean(dealFormErrors?.amount) && dealFormErrors?.amount
                  }
                  size="small"
                  InputProps={{ inputProps: { min: 0 } }}
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Assigned To */}
          <Grid item container xs={12} md={6}>
            {dealAssignedToAccess ? (
              <>
                <Grid item xs={10} xl={5}>
                  <InputLabel>
                    {"Assigned To "}
                    <Asterisk />
                  </InputLabel>
                </Grid>

                {editDealProp === true ? (
                  <Grid item xs={10} xl={7}>
                    <FormControl sx={{ width: "100%" }}>
                      <AsyncSelect
                        className={`${Boolean(dealFormErrors?.assigned_to)
                          ? "error-border"
                          : ""
                          }`}
                        name="assigned_to"
                        onChange={(selectedOption) => {
                          setDealFormErrors({
                            ...dealFormErrors,
                            ["assigned_to"]: "",
                          });
                          setDealAssignedTo(selectedOption);
                          if (selectedOption) {
                            setDealData({
                              ...dealData,
                              assigned_to: selectedOption.value,
                            });
                          } else {
                            setDealData({ ...dealData, assigned_to: null });
                          }
                        }}
                        defaultOptions={userOptions}
                        value={dealAssignedTo}
                        placeholder="Type to search..."
                        loadOptions={debounce(
                          loadAssignedToOptions,
                          searchOptionDebounceTime
                        )}
                        noOptionsMessage={() =>
                          "No user found. Type again..."
                        }
                        isClearable
                        onFocus={handleUserFocus}
                      />

                      {Boolean(dealFormErrors?.assign_to) && (
                        <Typography variant="caption" color="error" p={2}>
                          {dealFormErrors?.assign_to}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
                {editDealProp === false ? (
                  <Grid item xs={10} xl={7}>
                    <FormControl sx={{ width: "100%" }}>
                      <AsyncSelect
                        className={`${Boolean(dealFormErrors?.assigned_to)
                          ? "error-border"
                          : ""
                          }`}
                        onChange={(selectedOption) => {
                          setDealFormErrors({
                            ...dealFormErrors,
                            ["assigned_to"]: "",
                          });
                          setDealAssignedTo(selectedOption);
                          if (selectedOption) {
                            setDealData({
                              ...dealData,
                              assigned_to: selectedOption.value,
                            });
                          } else {
                            setDealData({ ...dealData, assigned_to: null });
                          }
                        }}
                        value={dealAssignedTo}
                        placeholder="Type to search..."
                        defaultOptions={userOptions}
                        loadOptions={debounce(
                          loadAssignedToOptions,
                          searchOptionDebounceTime
                        )}
                        noOptionsMessage={() =>
                          "No user found. Type again..."
                        }
                        isClearable
                        onFocus={handleUserFocus}
                        components={{ Option: userOption }}
                      />
                      {Boolean(dealFormErrors?.assign_to) && (
                        <Typography variant="caption" color="error" p={2}>
                          {dealFormErrors?.assign_to}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </Grid>

          {/* Stage */}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} xl={5}>
              <InputLabel>
                {"Stage "}
                <Asterisk />
              </InputLabel>
            </Grid>
            <Grid item xs={10} xl={7}>
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(dealFormErrors?.deal_stage)}
              >
                <Select
                  id="deal-stage"
                  name="deal_stage"
                  value={dealData.deal_stage}
                  onChange={(e) => handleDealForm(e)}
                  size="small"
                  displayEmpty
                  error={Boolean(dealFormErrors?.deal_stage)}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem value="">Select Stage</MenuItem>
                  {Object.keys(stages).map((key) => (
                    <MenuItem key={key} value={stages[key]}>
                      {stages[key]}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(dealFormErrors?.deal_stage) && (
                  <Typography variant="caption" color="error" p={2}>
                    {dealFormErrors?.deal_stage}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          {/* Profitability */}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} xl={5}>
              <InputLabel>
                Probablity&nbsp;&#40;&#37;&#41;&nbsp;
                <Asterisk />
              </InputLabel>
            </Grid>
            <Grid item xs={10} xl={7}>
              <FormControl
                sx={{ width: "100%" }}
                error={Boolean(dealFormErrors?.probability)}
              >
                <Select
                  id="deal-probability"
                  name="probability"
                  value={dealData?.probability}
                  onChange={(e) => handleDealForm(e)}
                  size="small"
                  displayEmpty
                  error={Boolean(dealFormErrors?.probability)}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  {Object.keys(probabilities).map((key) => (
                    <MenuItem key={key} value={probabilities[key]}>
                      {key}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(dealFormErrors?.probability) && (
                  <Typography variant="caption" color="error" p={2}>
                    {dealFormErrors?.probability}
                  </Typography>
                )}
              </FormControl>
            </Grid>
          </Grid>

          {/* Next Step */}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} xl={5}>
              <InputLabel>Next Step</InputLabel>
            </Grid>
            <Grid item xs={10} xl={7}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  type="text"
                  id="deal-next-step"
                  name="deal_next_step"
                  value={dealData.deal_next_step}
                  onChange={(e) => handleDealForm(e)}
                  placeholder="Next Step"
                  error={Boolean(dealFormErrors?.deal_next_step)}
                  helperText={
                    Boolean(dealFormErrors?.deal_next_step) &&
                    dealFormErrors?.deal_next_step
                  }
                  size="small"
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Due Date */}
          <Grid item container xs={12} md={6}>
            <Grid item xs={12} xl={5}>
              <InputLabel>
                {"Due Date "}
                <Asterisk />
              </InputLabel>
            </Grid>
            <Grid item xs={10} xl={7}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  type="date"
                  id="due_date"
                  name="due_date"
                  value={dealData.due_date}
                  onChange={(e) => handleDealForm(e)}
                  error={Boolean(dealFormErrors?.due_date)}
                  helperText={
                    Boolean(dealFormErrors?.due_date) &&
                    dealFormErrors?.due_date
                  }
                  size="small"
                />
              </FormControl>
            </Grid>
          </Grid>

          {/* Description */}
          {/* */}
          <Grid item container xs={12}>
            <Grid item xs={12} lg={2.2}>
              <InputLabel>Description</InputLabel>
            </Grid>
            <Grid item xs={10} lg={9.8}>
              <FormControl sx={{ width: "100%" }}>
                <TextField
                  id="deal-description"
                  name="description"
                  onChange={(e) => handleDealForm(e)}
                  placeholder="Description"
                  value={dealData.description}
                  error={Boolean(dealFormErrors?.description)}
                  helperText={
                    Boolean(dealFormErrors?.description) &&
                    dealFormErrors?.description
                  }
                  size="small"
                  multiline
                  rows={3}
                >
                  {dealData.description}
                </TextField>
              </FormControl>
            </Grid>
            <Typography variant="body2" my={2}>
              <Asterisk />
              {" Mandatory Fields"}
            </Typography>
          </Grid>
        </Grid>
      </Box>

      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"end"}
        bottom={0}
      >
        <Divider className="horizontal-line" sx={{ my: 1 }} />
        <Box my={3} display={"flex"} columnGap={5}>

          <Button
            variant="contained"
            type="submit"
            className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
              }`}
            onClick={(e) => {
              if (editDealProp) handleDealEdit(e);
              else handleDealSubmit(e);
            }}

            disabled={addDealLoading || editDealLoading}
            endIcon={
              (addDealLoading || editDealLoading) && (
                <CircularProgress size={25} />
              )}
          >
            Save
          </Button>

          <Button
            variant="contained"
            type="button"
            className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
              }`}
            onClick={closeDealForm}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default DealForm;
