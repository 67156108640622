import React, { useState } from "react";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import elSidebarMenus from "../../../data/json/elSidebarMenus.json";
import { courseAssignApi } from "../../../constants/apis/EL/Course";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@mui/material";

const AssignCourses = () => {
  const axiosInstance = createInstance(true);

  const appSidebar = JSON.parse(JSON.stringify(elSidebarMenus));
  appSidebar.menuList[5].currentPage = true;

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);
    try {
      const response = await axiosInstance.put(courseAssignApi);
      if (response.status === 200) {
        toast.success("Courses assigned successfully");
      } else {
        toast.error("Assigning courses failed", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Assigning courses failed");
    }
    setLoading(false);
  };

  return (
    <>
      <Container maxWidth={"xxl"}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Assign Courses</Typography>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          <Box mt={2}>
            {loading ? <LoadingSpinner /> : ""}
            <Typography variant="subtitle2">
              Click on assign button to assign courses to newly added account
              users.
            </Typography>
            <Button
              sx={{ mt: 2 }}
              variant="contained"
              onClick={handleSubmit}
              className="primary-btn"
            >
              Assign
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default AssignCourses;
