import React, { useState, useEffect } from "react";
import debounce from "lodash/debounce";
import AsyncSelect from "react-select/async";
import { contactSchema } from "../../../validations/BdForms/Contact";
import { useDispatch, useSelector } from "react-redux";
import { accountApi } from "../../../constants/apis/BD/Account";
import {
  searchOptionDebounceTime,
  asyncSelectNewCustomStyles,
  minCharsToLoadOptions,
  autoCloseToastError,
} from "../../../constants/Common";
import { roles } from "../../../enums/Contact";
import moment from "moment";
import { toast } from "react-toastify";
import {
  setAccountOptionsLoaded,
  setAccountOptions,
} from "../../../features/optionSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";

import { accountTypes } from "../../../enums/Account";
import { accountOption } from "../../AsyncSelectOption/CustomizedOptions";
import {
  FormControl,
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Asterisk from "../../Misc/Asterisk";
import { editContactById } from "../../../redux/actions/bd-actions/contacts.action";
import { addContact } from "../../../redux/actions/bd-actions/contacts.action";
import { contactActions } from "../../../redux/reducers/bd-reducers/contacts.reducer";

const ContactForm = ({
  isOpen,
  contactEditProp,
  handleDrawerClose,
  isSmallerScreen,
  contactAddAccountSpecific,
  accountSpecific = false,
  accountData
}) => {
  const dispatch = useDispatch();

  const accountOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.accountOptionsLoaded
  );
  const accountOptions = useSelector(
    (state) => state.optionsForAsyncSelect.accountOptions
  );

  const profileData = useSelector((state) => state.userProfile.profileData);

  const contactsState = useSelector((state) => state.contacts);

  const addContactLoading = contactsState.contacts?.addContactLoading;

  const editContactLoading = contactsState.contacts?.editContactLoading;

  const selectedContactData = contactsState.selectedContactData;
  const contactId = selectedContactData?.id;

  const accountName = accountSpecific ? accountData?.account_name : (contactEditProp ? selectedContactData?.account_name : "");
  const accountId = accountSpecific ? accountData?.account_id : (contactEditProp ? selectedContactData?.account_id : null);

  const curatedContactData = {
    account_id: accountId,
    first_name: selectedContactData?.first_name,
    last_name: selectedContactData?.last_name,
    dob: selectedContactData?.dob
      ? moment(selectedContactData?.dob).format("YYYY-MM-DD")
      : "",
    mobile: selectedContactData?.mobile,
    email: selectedContactData?.email,
    role: selectedContactData?.role || roles.role4,
    description: selectedContactData?.description,
  };

  const [contactData, setContactData] = useState(curatedContactData);
  const [contactFormErrors, setContactFormErrors] = useState({});

  const [contactAccount, setContactAccount] = useState(null);

  const fetchAccountOptions = async (inputvalue) => {
    try {
      const axiosInstance = createInstance(true);
      const response = await axiosInstance.get(
        accountApi + "/accountoptions?search=" + inputvalue
      );

      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = {};

          // Add additional data based on a condition
          if (item.account_type === accountTypes.Organization) {
            additionalData = {
              account_type: item.account_type,
              gstin: item.gstin,
            };
          } else if (item.account_type === accountTypes.Individual) {
            additionalData = {
              account_type: item.account_type,
              mobile: item.mobile,
              city: item.city,
            };
          }

          return {
            value: item.account_id,
            label: item.account_name,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching account options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching account options");
    }
    return [];
  };
  const loadAccountOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchAccountOptions(inputValue)
      .then((options) => callback(options))
      .catch((error) => {
        console.error(error);
        callback([]);
      });
  };

  const closeContactForm = () => {
    handleDrawerClose();
    setContactData(null);
    setContactFormErrors({});
  };
  const handleContactForm = (e) => {
    setContactFormErrors({ ...contactFormErrors, [e.target.name]: "" });
    setContactData({ ...contactData, [e.target.name]: e.target.value });
  };

  const handleContactSubmit = async (e) => {
    e.preventDefault();

    const userId = profileData?.user_id;
    const payload = { ...contactData, created_by: userId };
    const validation = contactSchema.validate(contactData, {
      abortEarly: false,
    });
    if (validation.error) {
      setContactFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setContactFormErrors(currentErrors);
    } else {
      try {
        let payloadContactData = JSON.parse(JSON.stringify(payload));
        dispatch(addContact(payloadContactData, accountSpecific));
        setContactFormErrors({});
      } catch (err) {
        errorHandler(err, "Failed to create ticket");
      }
    }
  };
  const handleContactEdit = async (e) => {
    e.preventDefault();
    const userId = profileData?.user_id;

    const payload = { ...contactData, updated_by: userId };

    const validation = contactSchema.validate(contactData, {
      abortEarly: false,
    });
    if (validation.error) {
      setContactFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setContactFormErrors(currentErrors);
    } else {
      let payloadContactData = JSON.parse(JSON.stringify(payload));
      dispatch(editContactById(contactId, payloadContactData, accountSpecific));
      setContactFormErrors({});
    }
  };

  const handleAccountFocus = async () => {
    if (!accountOptionsLoaded) {
      const fetchedOptions = await fetchAccountOptions("");
      dispatch(setAccountOptionsLoaded(true));
      dispatch(setAccountOptions(fetchedOptions));
    }
  };

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: { sm: "80vw", md: "70vw", lg: "50vw" },
            px: { xs: 2, md: 4 },
          },
        }}
        anchor={"right"}
        open={isOpen}
        onClose={closeContactForm}
      >
        <Box my={2} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h5">
            {contactEditProp ? "Edit " : "New "}
            Contact
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeContactForm}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider className="horizontal-line" />

        <Box my={3}>
          <Grid
            container
            rowSpacing={{ xs: 1, md: 4 }}
            columnSpacing={{ xs: 0, md: 10, lg: 15 }}
          >
            {/* Account Name  */}
            <Grid item container xs={12}>
              <Grid item container xs={12} lg={2.2}>
                <InputLabel>
                  {"Account Name "}
                  {contactEditProp === false ? <Asterisk /> : ""}
                </InputLabel>
              </Grid>
              <Grid item container xs={10} lg={9.8}>
                {contactEditProp === true || contactAddAccountSpecific ? (
                  <>
                    {accountName}
                    {Boolean(contactFormErrors.account_id) && (
                      <Typography variant="caption" color="error" p={2}>
                        {contactFormErrors.account_id}
                      </Typography>
                    )}
                  </>
                ) : (
                  ""
                )}
                {contactEditProp === false &&
                  contactAddAccountSpecific === false ? (
                  <FormControl sx={{ width: "100%" }}>
                    <AsyncSelect
                      styles={asyncSelectNewCustomStyles}
                      className={`${Boolean(contactFormErrors?.account_id)
                        ? "form-error"
                        : ""
                        }`}
                      onChange={(selectedOption) => {
                        setContactFormErrors({
                          ...contactFormErrors,
                          ["account_id"]: "",
                        });
                        setContactAccount(selectedOption);
                        if (selectedOption) {
                          setContactData({
                            ...contactData,
                            account_id: selectedOption.value,
                          });
                        } else {
                          setContactData({
                            ...contactData,
                            account_id: null,
                          });
                        }
                      }}
                      value={contactAccount}
                      defaultOptions={accountOptions}
                      loadOptions={debounce(
                        loadAccountOptions,
                        searchOptionDebounceTime
                      )}
                      placeholder="Type to search..."
                      name="account_id"
                      noOptionsMessage={() =>
                        "No accounts found. Type again..."
                      }
                      isClearable
                      onFocus={handleAccountFocus}
                      components={{ Option: accountOption }}
                    />
                    {Boolean(contactFormErrors.account_id) && (
                      <Typography variant="caption" color="error" p={2}>
                        {contactFormErrors.account_id}
                      </Typography>
                    )}
                  </FormControl>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>

            {/* First Name */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel>
                  {"First Name "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={10} xl={7}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    id="contact-fname"
                    name="first_name"
                    value={Boolean(contactData?.first_name) ? contactData?.first_name : ""}
                    onChange={(e) => handleContactForm(e)}
                    placeholder="First Name"
                    size="small"
                    error={Boolean(contactFormErrors?.first_name)}
                    helperText={
                      Boolean(contactFormErrors?.first_name) &&
                      contactFormErrors?.first_name
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* Last Name */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel>
                  {"Last Name "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={10} xl={7}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    id="contact-lname"
                    name="last_name"
                    value={Boolean(contactData?.last_name) ? contactData?.last_name : ""}
                    onChange={(e) => handleContactForm(e)}
                    placeholder="Last Name"
                    size="small"
                    error={Boolean(contactFormErrors?.last_name)}
                    helperText={
                      Boolean(contactFormErrors?.last_name) &&
                      contactFormErrors?.last_name
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* Mobile Number */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel>
                  {"Mobile Number "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={10} xl={7}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    id="contact-mobile-number"
                    name="mobile"
                    inputProps={{ minLength: 10, maxLength: 10 }}
                    value={(contactData?.mobile) ? contactData?.mobile : ''}
                    onChange={(e) => handleContactForm(e)}
                    placeholder="Mobile Number"
                    size="small"
                    error={Boolean(contactFormErrors?.mobile)}
                    helperText={
                      Boolean(contactFormErrors?.mobile) &&
                      contactFormErrors?.mobile
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* Email ID */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel>Email ID</InputLabel>
              </Grid>
              <Grid item xs={10} xl={7}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    type="text"
                    id="contact-email"
                    name="email"
                    value={(contactData?.email) ? contactData?.email : ""}
                    onChange={(e) => handleContactForm(e)}
                    placeholder="Email ID"
                    size="small"
                    error={Boolean(contactFormErrors?.email)}
                    helperText={
                      Boolean(contactFormErrors?.email) &&
                      contactFormErrors?.email
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* Role */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel>
                  {"Role "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={10} xl={7}>
                <FormControl
                  sx={{ width: "100%" }}
                  error={Boolean(contactFormErrors?.role)}
                >
                  <Select
                    id="contact-role"
                    name="role"
                    value={(contactData?.role) ? contactData?.role : ""}
                    onChange={(e) => handleContactForm(e)}
                    size="small"
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    error={Boolean(contactFormErrors?.role)}
                    placeholder="Select Role"
                  >
                    {Object.keys(roles).map((key) => (
                      <MenuItem key={key} value={roles[key]}>
                        {roles[key]}
                      </MenuItem>
                    ))}
                  </Select>
                  {Boolean(contactFormErrors.role) && (
                    <Typography variant="caption" color="error" p={2}>
                      {contactFormErrors?.role}
                    </Typography>
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* DOB */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel>DOB</InputLabel>
              </Grid>
              <Grid item xs={10} xl={7}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    type="date"
                    id="contact-birthday"
                    name="dob"
                    value={Boolean(contactData?.dob) ? contactData?.dob : ""}
                    onChange={(e) => handleContactForm(e)}
                    error={Boolean(contactFormErrors?.dob)}
                    helperText={
                      Boolean(contactFormErrors?.dob) && contactFormErrors?.dob
                    }
                    size="small"
                  />
                </FormControl>
              </Grid>
            </Grid>

            {/* Description */}
            <Grid item container xs={12}>
              <Grid item container xs={12} lg={2.2}>
                <InputLabel>Description</InputLabel>
              </Grid>
              <Grid item container xs={10} lg={9.8}>
                <FormControl sx={{ width: "100%" }}>
                  <TextField
                    id="contact-description"
                    name="description"
                    onChange={(e) => handleContactForm(e)}
                    placeholder="Description"
                    value={Boolean(contactData?.description) ? contactData?.description : ""}
                    error={Boolean(contactFormErrors?.description)}
                    helperText={
                      Boolean(contactFormErrors?.description) &&
                      contactFormErrors?.description
                    }
                    size="small"
                    multiline
                    rows={3}
                  >
                    {contactData?.description}
                  </TextField>
                </FormControl>
              </Grid>

              <Typography variant="body2" my={2}>
                <Asterisk />
                {" Mandatory Fields"}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"end"}
          bottom={0}
        >
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          <Box my={3} display={"flex"} columnGap={5}>
            <Button
              variant="contained"
              type="submit"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={(e) => {
                if (contactEditProp) handleContactEdit(e);
                else handleContactSubmit(e);
              }}
              disabled={addContactLoading || editContactLoading}
              endIcon={
                (addContactLoading || editContactLoading) && (
                  <CircularProgress size={25} />
                )
              }
            >
              Save
            </Button>

            <Button
              variant="contained"
              type="button"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={closeContactForm}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default ContactForm;
