import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import StarsRoundedIcon from "@mui/icons-material/StarsRounded";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { courseApi } from "../../../constants/apis/EL/Course";
import { getHrsMinsSecs } from "../../../utils/Time";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { setCourseDetailsOpen } from "../../../features/EL/courseDetailsSlice";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";

const CourseDetails = ({ isOpenProp, handleClose }) => {
  const dispatch = useDispatch();
  const [courseData, setCourseData] = useState({});
  const [detailsLoading, setDetailsLoading] = useState(true);
  const courseIdForDetails = useSelector(
    (state) => state.courseDetails.courseIdForDetails
  );
  const courseDetailsOpen = useSelector(
    (state) => state.courseDetails.courseDetailsOpen
  );

  const [isOpen, setIsOpen] = useState(isOpenProp);

  const handleDrawerClose = (_) => {
    handleClose();
    setIsOpen(false);
  };

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    const fetchCourseData = (courseId) => {
      setDetailsLoading(true);
      try {
        const axiosInstace = createInstance(true);
        axiosInstace
          .get(courseApi.concat(courseId))
          .then((res) => {
            if (res.data.success) {
              const fetchedCourseData = res.data.data;

              //calculating hrs and minutes
              const hrsMinsSeconds = getHrsMinsSecs(fetchedCourseData.duration);
              fetchedCourseData.durationHours = hrsMinsSeconds[0];
              fetchedCourseData.durationMinutes = hrsMinsSeconds[1];

              //calculating total marks
              let currentTotalMarks = 0;
              fetchedCourseData.questions.forEach((question) => {
                currentTotalMarks += question.marks;
              });

              fetchedCourseData.totalMarks = currentTotalMarks;
              setCourseData({ ...fetchedCourseData });
            } else {
              throw new Error("Failed to fetch course details");
            }
          })
          .catch((err) => {
            errorHandler(err, "Failed to fetch course details.");
          });
      } catch (err) {
        toast.error("Failed to fetch course details.", {
          autoClose: autoCloseToastError,
        });
      }
      setDetailsLoading(false);
    };

    if (courseDetailsOpen) {
      setCourseData({});
      fetchCourseData(courseIdForDetails);
      dispatch(setCourseDetailsOpen(false));
    }
  }, [courseDetailsOpen]);

  return (
    <Drawer
      PaperProps={{
        sx: {
          width: { md: "90vw" },
          px: { xs: 2, md: 4 },
        },
      }}
      anchor={"right"}
      open={isOpen}
      onClose={handleDrawerClose}
    >
      <Box
        my={2}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">Course Details</Typography>
        <IconButton
          aria-label="close"
          onClick={handleDrawerClose}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" />

      {detailsLoading ? (
        <LoadingSpinner />
      ) : (
        <Box my={3}>
          <Grid container>
            <Grid item container xs={12} md={5.8}>
              <Grid
                item
                xs={12}
                md={12}
                mb={{ xs: 1, md: 3 }}
                mt={isSmallerScreen && 1}
              >
                <Typography variant={"h6"}>Course</Typography>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Course Title
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Typography
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {courseData.course_title}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Module
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Typography
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {courseData?.module_title}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Language
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Typography
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {courseData.language}
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Duration
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Typography
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {courseData.durationHours} Hrs {courseData.durationMinutes}{" "}
                  Minutes
                </Typography>
              </Grid>

              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Uploaded Course Image
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                <Link
                  href={courseData.course_image_url}
                  target="_blank"
                  variant="body1"
                  mb={isSmallerScreen ? 2 : 3}
                >
                  {courseData.course_image_name}
                </Link>
              </Grid>
              <Grid item xs={12} md={3.5} lg={3} pr={2}>
                <Typography
                  variant="body1"
                  className="fw-bold"
                  mb={!isSmallerScreen && 3}
                >
                  Uploaded Course Files
                </Typography>
              </Grid>
              <Grid item xs={12} md={8.5} lg={9}>
                { courseData?.course_file_names &&
                  courseData.course_file_urls.map((url, index) => {
                    return <Grid item xs={12} md={8.5} lg={9}>
                      <Link
                        href={url}
                        target="_blank"
                        variant="body1"
                        mb={isSmallerScreen ? 2 : 3}
                      >
                        {courseData.course_file_names[index]}
                      </Link>
                      </Grid>
                  })
                }
              </Grid>
            </Grid>
            {!isSmallerScreen && (
              <Grid item md={0.4} display={"flex"} justifyContent={"center"}>
                <Divider className="horizontal-line" orientation="vertical" />
              </Grid>
            )}
            <Grid item xs={12} md={5.8}>
              <Grid
                item
                xs={12}
                md={12}
                mt={isSmallerScreen && 1}
                mb={!isSmallerScreen && 3}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography variant="h6">Questions</Typography>
                  <Box display={"flex"} alignItems={"center"}>
                    <StarsRoundedIcon fontSize="small" />
                    <Typography variant="body2" fontWeight={"bold"}>
                      {`Total Marks: ${courseData.totalMarks}`}
                    </Typography>
                  </Box>
                </Box>
                <Divider className={"horizontal-line"} sx={{ my: 1 }} />
              </Grid>
              {courseData?.questions?.length === 0 ? (
                <Typography variant={"body1"}>No questions added...</Typography>
              ) : (
                courseData.questions?.map((question, index) => {
                  return (
                    <Grid item md={12} mt={2} key={index}>
                      <Box
                        display={"flex"}
                        justifyContent={"space-between"}
                        alignItems={"center"}
                      >
                        <Typography variant="body1" fontWeight={"bold"}>{`Q${
                          index + 1
                        }. ${question.question_text}`}</Typography>{" "}
                        <Typography
                          variant="body2"
                          fontWeight={"bold"}
                          align="right"
                          color="textSecondary"
                        >{`${question.marks} Marks`}</Typography>
                      </Box>
                      {question.options.map((option, index) => (
                        <Box key={index}>
                          <Typography
                            name="option_4"
                            variant="body2"
                            size="small"
                            color={option.is_correct ? "green" : "inherit"}
                          >
                            {`${String.fromCharCode(index + 65)}. ${
                              option.option_text
                            }`}
                          </Typography>
                        </Box>
                      ))}
                    </Grid>
                  );
                })
              )}
            </Grid>
            <Grid item sm={12} md={8}></Grid>
          </Grid>
        </Box>
      )}
      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"end"}
        bottom={0}
      >
        <Divider className="horizontal-line" sx={{ my: 1 }} />
      </Box>
    </Drawer>
  );
};

export default CourseDetails;
