import React, { useEffect, useState } from "react";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import { timeframes } from "../../../enums/Dashboard";
import { bdDashboardApi } from "../../../constants/apis/BD/BdDashboard";
import dealDashboardColumns from "../../../data/json/dealDashboardColumns.json";
import taskDashboardColumns from "../../../data/json/taskDashboardColumns.json";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { useDispatch, useSelector } from "react-redux";
import {
  setTaskEdited,
} from "../../../features/BD/taskFormSlice";
import keyAccountsDashboardColumns from "../../../data/json/keyAccountsDashboardColumns.json";
import keyContactsDashboardColumns from "../../../data/json/keyContactsDashboardColumns.json";
import {
  setAccountEdited,
} from "../../../features/BD/accountSlice";
import { getKeyContacts } from "../../../redux/actions/bd-actions/contacts.action";
import { contactActions } from "../../../redux/reducers/bd-reducers/contacts.reducer";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import DealForm from "../../../components/BdForms/DealForm/DealForm";
import ContactForm from "../../../components/BdForms/ContactForm/ContactForm";
import TaskForm from "../../../components/BdForms/TaskForm/TaskForm";
import NewAccount from "../../../components/BdForms/NewAccount/NewAccount";
import { getDashboardTasks } from "../../../redux/actions/bd-actions/tasks.action";
import { taskActions } from "../../../redux/reducers/bd-reducers/tasks.reducer";
import { getDashboardAccounts } from "../../../redux/actions/bd-actions/accounts.action";
import { accountActions } from "../../../redux/reducers/bd-reducers/accounts.reducer";
import { getOpenDeals } from "../../../redux/actions/bd-actions/deals.action";
import { dealActions } from "../../../redux/reducers/bd-reducers/deals.reducer";

const BdDashboard = () => {
  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[0].currentPage = true;

  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("lg"));

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const accountState = useSelector((state) => state.accounts);
  const taskState = useSelector((state) => state.tasks);

  const initialCountCards = [
    {
      iconImagePath: "/assets/images/deals_big.svg",
      altText: "deals-big-icon",
      count: 0,
      textLabel: "Active Deals",
      cardName: "active_deals",
    },
    {
      iconImagePath: "/assets/images/tasks_big.svg",
      altText: "tasks-big-icon",
      count: taskState?.dashboardTasks?.total,
      textLabel: "Active Tasks",
      cardName: "active_tasks",
    },
    {
      iconImagePath: "/assets/images/contacts_big.svg",
      altText: "contacts-big-icon",
      count: accountState?.dashboardAccounts?.total,
      textLabel: "Key Accounts",
      cardName: "key_accounts",
    },
    {
      iconImagePath: "/assets/images/contacts_big.svg",
      altText: "contacts-big-icon",
      count: 0,
      textLabel: "Key Contacts",
      cardName: "key_contacts",
    },
  ];

  const [countCards, setCountCards] = useState(initialCountCards);

  const [timeFrame, setTimeFrame] = useState("tfy");
  const [dashboardLoading, setDashboardLoading] = useState(true);
  const [isTaskFormOpen, setIsTaskFormOpen] = useState(false);
  const [taskEditProp, setTaskEditProp] = useState(false);
  const [isAccountFormOpen, setIsAccountFormOpen] = useState(false);

  const contactsState = useSelector((state) => state.contacts);
  const keyContactsRows = contactsState.keyContacts?.rows;
  const [accountEditProp, setAccountEditProp] = useState(false);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [contactEditProp, setContactEditProp] = useState(false);
  const [isDealFormOpen, setIsDealFormOpen] = useState(false);
  const [dealEditProp, setDealEditProp] = useState(false);

  const dealsState = useSelector((state) => state.deals);
  const dealRows = dealsState.openDeals?.rows;

  const taskEdited = useSelector((state) => state.taskForm.taskEdited);
  const accountEdited = useSelector((state) => state.account.accountEdited);

  const axiosInstance = createInstance();

  const fetchDashboardDeals = () => {
    dispatch(getOpenDeals(timeFrame));
  };

  const fetchDashboardTasks = async () => {
    try {
      dispatch(getDashboardTasks(timeFrame));
    } catch (err) {
      errorHandler(err, "Failed to get open tasks");
    }
  };

  const fetchDashboardKeyAccounts = async () => {
    try {
      dispatch(getDashboardAccounts(timeFrame));
    } catch (err) {
      errorHandler(err, "Failed to get key accounts");
    }
  };

  const fetchDashboardKeyContacts = async () => {
    dispatch(getKeyContacts(timeFrame));
  };

  const requestData = async () => {
    setDashboardLoading(true);
    try {
      await Promise.all([
        fetchDashboardDeals(),
        fetchDashboardTasks(),
        fetchDashboardKeyAccounts(),
        fetchDashboardKeyContacts(),
      ]);
    } catch (error) {
      errorHandler(error, "Failed to fetch dashboard data");
    }
    setDashboardLoading(false);
  };

  const handleTimeFrame = (e) => {
    e.preventDefault();
    setTimeFrame(e.target.value);
  };

  const fillTaskDataInForm = (id) => {
    dispatch(taskActions.setSelectedDashboardTask(id));
    setTaskEditProp(true);
    setIsTaskFormOpen(true);
  };

  const fillDealDataInForm = (id) => {
    const selectedDealData = dealRows?.filter(
      (deal) => deal.deal_id === id
    )?.[0];
    dispatch(dealActions.setSelectedDealData(selectedDealData));
    setIsDealFormOpen(true);
    setDealEditProp(true);
  };

  const handleDealDrawerClose = () => {
    setIsDealFormOpen(false);
    setDealEditProp(false);
    dispatch(dealActions.setSelectedDealData(null));
  }

  const fillAccountDataInForm = (id) => {
    setAccountEditProp(true);
    dispatch(accountActions.setSelectedDashboardAccount(id));
    setIsAccountFormOpen(true);
  };

  const fillContactDataInForm = (id) => {
    const selectedContactData = keyContactsRows?.filter(
      (contact) => contact.contact_id === id
    )?.[0];
    dispatch(contactActions.setSelectedContactData(selectedContactData));
    setIsContactFormOpen(true);
    setContactEditProp(true);
  };

  const handleContactDrawerClose = () => {
    setIsContactFormOpen(false);
    setContactEditProp(false);
    dispatch(contactActions.setSelectedContactData(null));
  };

  useEffect(() => {
    // setting card value
    const newCountCards = [...initialCountCards];
    newCountCards[0].count = Boolean(dealRows) ? dealsState.openDeals?.total : newCountCards[0].count;
    newCountCards[1].count = Boolean(taskState?.dashboardTasks?.rows) ? taskState?.dashboardTasks?.total : newCountCards[1].count;
    newCountCards[2].count = Boolean(accountState?.dashboardAccounts?.rows) ? accountState?.dashboardAccounts?.total : newCountCards[2].count;
    newCountCards[3].count = Boolean(contactsState.keyContacts?.total)
      ? contactsState.keyContacts?.total
      : newCountCards[3].count;
    setCountCards(newCountCards);
  }, [keyContactsRows, dealRows, taskState?.dashboardTasks?.rows, accountState?.dashboardAccounts?.rows]);

  useEffect(() => {
    requestData();
  }, [timeFrame]);

  useEffect(() => {
    if (taskEdited) {
      requestData();
      dispatch(setTaskEdited(false));
    }
  }, [taskEdited]);

  useEffect(() => {
    if (accountEdited) {
      requestData();
      dispatch(setAccountEdited(false));
    }
  }, [accountEdited]);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isDealFormOpen && <DealForm
          isDealFormOpen={isDealFormOpen}
          editDealProp={dealEditProp}
          handleDrawerClose={handleDealDrawerClose}
          isSmallerScreen={isSmallerScreen}
        />}

        {isContactFormOpen && (
          <ContactForm
            isOpen={isContactFormOpen}
            contactEditProp={contactEditProp}
            handleDrawerClose={handleContactDrawerClose}
            isSmallerScreen={isSmallerScreen}
            contactAddAccountSpecific={false}
          />
        )}

        {isTaskFormOpen && (
          <TaskForm
            isOpenProp={isTaskFormOpen}
            taskEditProp={taskEditProp}
            handleClose={() => setIsTaskFormOpen(false)}
            forDashboard={true}
          />
        )}

        {isAccountFormOpen && (
          <NewAccount
            isOpenProp={isAccountFormOpen}
            handleClose={() => setIsAccountFormOpen(false)}
            accountEditProp={accountEditProp}
            forDashboard={true}
          />
        )}

        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Dashboard</Typography>
            </Grid>
            <Grid item xs={12} md={5} textAlign={"end"}>
              <Select
                size="small"
                MenuProps={{ disableScrollLock: true }}
                name="timeframe"
                id="timeframe"
                value={timeFrame}
                onChange={(e) => handleTimeFrame(e)}
              >
                {Object.keys(timeframes).map((key) => (
                  <MenuItem key={key} value={key}>
                    {timeframes[key]}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {dashboardLoading ? (
            <LoadingSpinner />
          ) : (
            <>
              <Grid container justifyContent={"space-between"}>
                {countCards.map((countCard, i) => (
                  <Grid
                    key={i}
                    item
                    xs={12}
                    sm={5.9}
                    md={2.9}
                    container
                    className="stat-card-bg"
                    sx={{ borderRadius: "15px", p: 2, my: 1 }}
                  >
                    <Grid item xs={8}>
                      <img
                        src={countCard.iconImagePath}
                        alt={countCard.altText}
                      />
                    </Grid>
                    <Grid item xs={4} textAlign={"end"}>
                      <Typography variant="h3">{countCard.count}</Typography>
                    </Grid>
                    <Grid item xs={12} textAlign={"end"}>
                      <Typography variant="body1" color={"textSecondary"}>
                        {countCard.textLabel}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
              <Grid container columnSpacing={4} rowSpacing={4}>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Open Deals
                  </Typography>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  {dealRows?.length === 0 ? (
                    "No data found..."
                  ) : (
                    <>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead className="stat-card-bg">
                            <TableRow>
                              {dealDashboardColumns &&
                                dealDashboardColumns.map((dealCol) => (
                                  <TableCell key={dealCol.id}>
                                    <TableSortLabel hideSortIcon>
                                      {dealCol.name}
                                    </TableSortLabel>
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {dealRows &&
                              dealRows?.map((dealRow) => (
                                <TableRow key={dealRow.id}>
                                  <Tooltip title={dealRow.deal_name}>
                                    <TableCell
                                      sx={{ maxWidth: "250px" }}
                                      className="text-truncate primary-text link-hover"
                                      onClick={() =>
                                        fillDealDataInForm(dealRow.id)
                                      }
                                    >
                                      {dealRow.deal_name}
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {dealRow.deal_stage}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {dealRow.probability}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {dealRow.amount}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {countCards[0].count > 10 ? (
                    <Button
                      variant="text"
                      onClick={() => navigate("/bd/deals")}
                    >
                      view more...
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Open Tasks
                  </Typography>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  {taskState?.dashboardTasks?.rows?.length === 0 ? (
                    "No data found..."
                  ) : (
                    <>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead className="stat-card-bg">
                            <TableRow>
                              {taskDashboardColumns &&
                                taskDashboardColumns.map((taskCol) => (
                                  <TableCell key={taskCol.id}>
                                    <TableSortLabel hideSortIcon>
                                      {taskCol.name}
                                    </TableSortLabel>
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {taskState?.dashboardTasks?.rows &&
                              taskState?.dashboardTasks?.rows?.map((taskRow) => (
                                <TableRow key={taskRow.id}>
                                  <Tooltip title={taskRow.task_title}>
                                    <TableCell
                                      sx={{ maxWidth: "250px" }}
                                      className="text-truncate primary-text link-hover"
                                      onClick={() =>
                                        fillTaskDataInForm(taskRow.id)
                                      }
                                    >
                                      {taskRow.task_title}
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {taskRow.task_type}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {taskRow.status}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {taskRow.assigned_to_name}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {countCards[1].count > 10 ? (
                    <Button
                      variant="text"
                      onClick={() => navigate("/bd/tasks")}
                    >
                      view more...
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Key Accounts
                  </Typography>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  {accountState?.dashboardAccounts?.rows?.length === 0 ? (
                    "No data found..."
                  ) : (
                    <>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead className="stat-card-bg">
                            <TableRow>
                              {keyAccountsDashboardColumns &&
                                keyAccountsDashboardColumns.map((keyAccCol) => (
                                  <TableCell key={keyAccCol.id}>
                                    <TableSortLabel hideSortIcon>
                                      {keyAccCol.name}
                                    </TableSortLabel>
                                  </TableCell>
                                ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {accountState?.dashboardAccounts?.rows &&
                              accountState?.dashboardAccounts?.rows?.map((keyAccRow) => (
                                <TableRow key={keyAccRow.id}>
                                  <Tooltip title={keyAccRow.account_name}>
                                    <TableCell
                                      sx={{ maxWidth: "250px" }}
                                      className="text-truncate primary-text link-hover"
                                      onClick={() =>
                                        fillAccountDataInForm(keyAccRow.id)
                                      }
                                    >
                                      {keyAccRow.account_name}
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {keyAccRow.account_type}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {keyAccRow.city}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {countCards[2].count > 10 ? (
                    <Button
                      variant="text"
                      onClick={() => navigate("/bd/accounts")}
                    >
                      view more...
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Typography variant="body1" fontWeight={"bold"}>
                    Key Contacts
                  </Typography>
                  <Divider className="horizontal-line" sx={{ my: 1 }} />
                  {keyContactsRows?.length === 0 ? (
                    "No data found..."
                  ) : (
                    <>
                      <TableContainer>
                        <Table size="medium">
                          <TableHead className="stat-card-bg">
                            <TableRow>
                              {keyContactsDashboardColumns &&
                                keyContactsDashboardColumns.map(
                                  (keyContactsCol) => (
                                    <TableCell key={keyContactsCol.id}>
                                      <TableSortLabel hideSortIcon>
                                        {keyContactsCol.name}
                                      </TableSortLabel>
                                    </TableCell>
                                  )
                                )}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {keyContactsRows &&
                              keyContactsRows?.map((keyContactsRow) => (
                                <TableRow key={keyContactsRow.id}>
                                  <Tooltip title={keyContactsRow.contact_name}>
                                    <TableCell
                                      sx={{ maxWidth: "250px" }}
                                      className="text-truncate primary-text link-hover"
                                      onClick={() =>
                                        fillContactDataInForm(keyContactsRow.id)
                                      }
                                    >
                                      {keyContactsRow.first_name}{" "}
                                      {keyContactsRow.last_name}
                                    </TableCell>
                                  </Tooltip>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {keyContactsRow.mobile}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {keyContactsRow.role}
                                  </TableCell>
                                  <TableCell
                                    className="text-truncate"
                                    sx={{ maxWidth: "150px" }}
                                  >
                                    {keyContactsRow.account_name}
                                  </TableCell>
                                </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </>
                  )}
                  {countCards[3].count > 10 ? (
                    <Button
                      variant="text"
                      onClick={() => navigate("/bd/contacts")}
                    >
                      view more...
                    </Button>
                  ) : (
                    ""
                  )}
                </Grid>
              </Grid>
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default BdDashboard;
