import React, { useState, useEffect } from "react";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import { pptApi } from "../../constants/apis/EL/Ppt";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../constants/Common";
import { Backdrop, Modal } from "@mui/material";

import {
  Box,
  IconButton,
  Paper,
  Grid,
  Typography,
} from "@mui/material";

import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import CropFreeRoundedIcon from "@mui/icons-material/CropFreeRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import PdfViewer from "./PdfViewer";
import TextViewer from "./TextViewer";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import delay from "lodash/delay";


const PAGE_LINES = 30;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "100%",
  width: "100%",
  overflowY: "scroll",
  boxShadow: 24,
  px: { xs: 0, md: 1 },
};

const DocViewer = ({
  fileType,
  fileUrl,
  isPptModalOpen,
  setIsPptModalOpen,
  lastRecordedProgress,
  updateFileLastProgress,
}) => {

  const [text, setText] = useState('');
  const [pages, setPages] = useState([]);
  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(parseInt(Boolean(lastRecordedProgress) ? lastRecordedProgress : 1));
  const [fileDataLoading, setFileDataLoading] = useState(true)
  const [fileDataError, setFileDataError] = useState(null)

  const checkWhetherLastProgressIsValid = (total_pages) => {
    if (lastRecordedProgress >= total_pages) {
      setCurrentPage(1)
    }
  }

  const handleDocumentLoadSuccess = (pdfInformation) => {
    // set pages for pdf files
    let pdfLength = pdfInformation._pdfInfo.numPages;
    setTotalPages(pdfLength);
    delay(() => checkWhetherLastProgressIsValid(pdfLength), 300);
    setFileDataLoading(false);
  };

  const handleDocumentLoadError = (error, message) => {
    if (!(message?.includes('Failed to load page!') ?? false)) {
      errorHandler(error, message);
      setFileDataLoading(false)
      setFileDataError(error);
    }
  };

  const handleNextSlide = () => {
    if (currentPage < totalPages) {
      updateFileLastProgress(currentPage + 1);
      setCurrentPage((_) => _ + 1);

    }
  };

  const handlePreviousSlide = () => {
    if (currentPage !== 1) {
      updateFileLastProgress(currentPage - 1);
      setCurrentPage((_) => _ - 1);
    }
  };

  function togglePptModalView() {
    setIsPptModalOpen(!isPptModalOpen);
  }

  useEffect(() => {
    const fetchData = async () => {
      setFileDataLoading(true);
      fetch(fileUrl).then(response => response.text())
        .then(text => setText(text)).
        catch(error => setFileDataError(error, "Failed to fetch text content."));

    }
    if (fileType === 'txt') {
      fetchData();
    }

    setCurrentPage(lastRecordedProgress);

    return () => {
      if (fileType === 'txt') {
        setText('')
        setPages(0);
        setTotalPages(0)
        setCurrentPage(1);
      }

    }
  }, [fileType,lastRecordedProgress,fileUrl]);


  useEffect(() => {
    function splitByCharacters(text) {
      if (text !== '') {
        const text_file_pages = [];
        const lines = text.split("\n"); // Split text into lines

        let currentPage = "";
        let lineCount = 0;

        for (let i = 0; i < lines.length; i++) {
          const line = lines[i];

          // Add line to current page with newline character
          currentPage += line + "\n";
          lineCount++;

          // Check if page is full or at the end of the text
          if (lineCount >= PAGE_LINES || i === lines.length - 1) {
            text_file_pages.push(currentPage.trimEnd()); // Add trimmed page and reset

            currentPage = "";
            lineCount = 0;
          }
        }
        setPages(text_file_pages)
        setTotalPages(text_file_pages.length)
        delay(() => checkWhetherLastProgressIsValid(text_file_pages.length), 300);
        setFileDataLoading(false)
      }
    }

    splitByCharacters(text);
  }, [text])



  return (
    <>
      <Paper
        variant="outlined"
        sx={{
          position: "relative",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            borderRadius: 2,
            overflowY: "scroll",
            height: { xs: "350px", md: "75vh" },
          }}
        >
          <Grid container justifyContent={"center"} className="dark-bg">
            <Grid item container justifyContent={"center"} xs={12} md={10} xl={9}>

              {fileType === "pdf" ? <PdfViewer url={fileUrl}
                onDocumentLoadSuccess={handleDocumentLoadSuccess}
                onLoadError={handleDocumentLoadError}
                currentPage={currentPage}
                lastRecordedProgress={lastRecordedProgress} />
                : <TextViewer page={pages[currentPage - 1]} />}
            </Grid>
          </Grid>
        </Box>

        <IconButton
          onClick={togglePptModalView}
          className={`primary-bg`}
          sx={{
            position: "absolute",
            bottom: { xs: 10, md: 10 },
            right: { xs: 10, md: 10 },
          }}
        >
          <CropFreeRoundedIcon fontSize="medium" />
        </IconButton>

        <Modal
          disableEnforceFocus
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={isPptModalOpen}
          onClose={togglePptModalView}
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <>
            {" "}
            <IconButton
              sx={{
                backgroundColor: "transparent",
                color: "inherit",
                position: "absolute",
                top: 20,
                right: 20,
                zIndex: 2,
              }}
              onClick={togglePptModalView}
            >
              <CloseRoundedIcon fontSize="large" className={"primary-text"} />
            </IconButton>
            <Box
              sx={style}
              className={"overlay-bg"}
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
            >
              <IconButton
                className={`${currentPage !== 1 && "primary-text"}`}
                onClick={() => handlePreviousSlide()}
                disabled={currentPage === 1}
              >
                <ArrowCircleLeftIcon fontSize="large" />
              </IconButton>
              <Box
                display={"flex"}
                justifyContent={"center"}
                sx={{
                  maxHeight: "90vh",
                  width: {
                    xs: "80%",
                    sm: "80%",
                    lg: "50%",
                  },
                  overflowY: "scroll",
                  mx: "auto",
                }}
              >
                {fileDataLoading ? (
                  <LoadingSpinner />
                ) : ((fileType === "pdf") ?
                  <PdfViewer url={fileUrl} onDocumentLoadSuccess={handleDocumentLoadSuccess} onLoadError={handleDocumentLoadError} currentPage={currentPage} lastRecordedProgress={lastRecordedProgress} />
                  : <TextViewer page={pages[currentPage - 1]} />)}
              </Box>

              <IconButton
                size="large"
                className={`${currentPage !== totalPages && "primary-text"}`}
                onClick={() => handleNextSlide()}
                disabled={currentPage === totalPages}
              >
                <ArrowCircleRightIcon fontSize="large" />
              </IconButton>
            </Box>
          </>
        </Modal>
      </Paper>

      <Box display={"flex"} justifyContent={"center"}>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <IconButton
            className={`${currentPage !== 1 && "primary-text"}`}
            onClick={() => handlePreviousSlide()}
            disabled={currentPage === 1}
          >
            <ArrowCircleLeftIcon fontSize="large" />
          </IconButton>

          <Typography sx={{ mx: 0.5 }} variant="body1">
            {Boolean(totalPages) ? currentPage : 0} of {totalPages}
          </Typography>

          <IconButton
            size="large"
            className={`${currentPage !== totalPages && "primary-text"}`}
            onClick={() => handleNextSlide()}
            disabled={currentPage === totalPages}
          >
            <ArrowCircleRightIcon fontSize="large" />
          </IconButton>
        </Box>
      </Box>
    </>
  );
};

export default DocViewer;
