import React, { useEffect, useState } from "react";
import BdCounts from "../../../components/BdCounts/BdCounts";
import contactColumns from "../../../data/json/contactColumns.json";
import sidebarMenus from "../../../data/json/sidebarMenus.json";
import { useDispatch, useSelector } from "react-redux";
import { roles } from "../../../enums/Contact";
import { DebounceInput } from "react-debounce-input";
import {
  deleteContactById,
  getContacts,
} from "../../../redux/actions/bd-actions/contacts.action";

import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import ContactForm from "../../../components/BdForms/ContactForm/ContactForm";
import TableMenu from "../../../components/TableMenu/TableMenu";
import getTableCell from "../../../utils/Common/DataTable";
import { contactActions } from "../../../redux/reducers/bd-reducers/contacts.reducer";

const Contacts = () => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(sidebarMenus));
  appSidebar.menuList[4].currentPage = true;

  const dispatch = useDispatch();

  const initialContactCountCards = [
    {
      type: "main",
      iconImagePath: "/assets/images/contacts_big.svg",
      altText: "contacts-icon",
      count: 0,
      textLabel: "Total Contacts",
    },
    {
      type: "normal",
      count: 0,
      textLabel: roles.role1,
    },
    {
      type: "normal",
      count: 0,
      textLabel: roles.role2,
    },
    {
      type: "normal",
      count: 0,
      textLabel: roles.role3,
    },
    {
      type: "normal",
      count: 0,
      textLabel: roles.role4,
    },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [searched, setSearched] = useState("");
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [countCards, setCountCards] = useState(initialContactCountCards);
  const [isContactFormOpen, setIsContactFormOpen] = useState(false);
  const [contactEditProp, setContactEditProp] = useState(false);

  const contactsState = useSelector((state) => state.contacts);
  const rowsLoading = contactsState.contactsLoading;

  const rows = contactsState.contacts?.rows;

  const totalCount = contactsState.contacts?.meta?.total;

  const getRequestParams = () => {
    const requestParams = {};

    //handle search
    if (searched !== "") {
      requestParams["search"] = searched;
    }

    //handle sorting here
    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }

    //apply pagination here
    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;

    return requestParams;
  }

  const requestData = async () => {
    dispatch(getContacts(getRequestParams()));
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const deleteRow = async (id) => {
    dispatch(deleteContactById(id));
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const setContactFrom = () => {
    setIsContactFormOpen(true);
    setContactEditProp(false);
  };

  const fillDataInForm = (id) => {
    const selectedContactData = contactsState.contacts.rows?.filter(
      (contact) => contact.contact_id === id
    )?.[0];
    dispatch(contactActions.setSelectedContactData(selectedContactData));
    setIsContactFormOpen(true);
    setContactEditProp(true);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  const handleContactDrawerClose = () => {
    setIsContactFormOpen(false);
    setContactEditProp(false);
    dispatch(contactActions.setSelectedContactData(null));
  };

  useEffect(() => {
    //sets the cards
    const currentCountCards = [...initialContactCountCards];
    currentCountCards[0].count = totalCount;
    const resCountCards = contactsState?.contacts?.cards;
    for (let i = 1; i < currentCountCards.length; i++) {
      for (let j = 0; j < resCountCards?.length; j++) {
        if (
          currentCountCards[i].textLabel ===
          contactsState?.contacts?.cards[j]?.role
        ) {
          currentCountCards[i].count = contactsState?.contacts?.cards[j]?.count;
          break;
        }
      }
    }
    setCountCards(currentCountCards);
  }, [contactsState]);

  useEffect(() => {
    requestData();
  }, [currentPage, rowsPerPage, searched, order, orderBy]);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isContactFormOpen && (
          <ContactForm
            isOpen={isContactFormOpen}
            contactEditProp={contactEditProp}
            handleDrawerClose={handleContactDrawerClose}
            isSmallerScreen={isSmallerScreen}
            contactAddAccountSpecific={false}
          />
        )}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Contacts</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnSpacing={2}
              justifyContent={"end"}
              alignItems={"center"}
            >
              <Grid my={1} item xs={12} md={"auto"}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={"auto"}>
                <Button
                  variant="outlined"
                  data-bs-toggle="offcanvas"
                  data-bs-target="#offcanvasTicketForm"
                  onClick={() => setContactFrom()}
                  startIcon={<AddIcon />}
                >
                  New Contact
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          <Box mb={3}>
            <BdCounts cards={countCards} />
          </Box>
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows?.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow>
                      {contactColumns.map((col) =>
                        getTableCell(col, orderBy, order, handleSortRequest)
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows?.map((contactRow) => {
                        return (
                          <TableRow key={contactRow.id}>
                            <Tooltip title={contactRow.contact_name}>
                              <TableCell
                                sx={{ maxWidth: "250px" }}
                                className="text-truncate primary-text link-hover"
                                onClick={() => fillDataInForm(contactRow.id)}
                              >
                                {contactRow.first_name} {contactRow.last_name}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {contactRow.mobile}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {contactRow.account_name}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {contactRow.role}
                            </TableCell>

                            <TableCell align="right">
                              <TableMenu
                                fillDataInForm={() =>
                                  fillDataInForm(contactRow.id)
                                }
                                rowId={contactRow.id}
                                isDeleteOptionEnabled={true}
                                deleteModalData={[
                                  "Contact",
                                  `${contactRow.first_name} ${contactRow.last_name}`,
                                ]}
                                handleDeleteRow={() => deleteRow(contactRow.id)}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={Boolean(totalCount) ? totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Contacts;