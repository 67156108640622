const messages = {
    accountId: "Please select account",
    assignedTo: "Please select assigned to",

    titleDefault: "Title cannot be empty",
    titleMax: "Maximum 255 characters allowed",

    amountDefault: "Enter proper value",
    amountMin: "Value cannot be less than 0",

    stageDefault: "Select proper stage",

    nextStepDefault: "Enter proper value",

    probabilityDefault: "Select proper probability",

    descriptionDefault: "Enter description",

    dueDateDefault: "Due Date cannot be empty",
};

export { messages };
