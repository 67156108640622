import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import React from "react";
import "./ConfirmationModal.scss";
import CloseIcon from "@mui/icons-material/Close";

const ConfirmationModal = ({
  modalHeader,
  hideModal,
  confirmModal,
  message,
}) => {
  return (
    <>
      <DialogTitle
        sx={{ m: 0, p: 2, fontWeight: "bold" }}
        id="customized-dialog-title"
      >
        {modalHeader}
      </DialogTitle>

      <IconButton
        aria-label="close"
        onClick={hideModal}
        sx={{
          position: "absolute",
          right: 8,
          top: 8,
        }}
        color="primary"
      >
        <CloseIcon />
      </IconButton>

      <DialogContent dividers>
        <Typography variant="body2" color={"textSecondary"} gutterBottom>
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          className="primary-btn "
          onClick={() => confirmModal()}
        >
          Submit
        </Button>
      </DialogActions>
    </>
  );
};

export default ConfirmationModal;
