import React, { useEffect, useState } from "react";
import elSidebarMenus from "../../../data/json/elSidebarMenus.json";
import { DebounceInput } from "react-debounce-input";

import courseColumns from "../../../data/json/courseColumns.json";
import { courseApi } from "../../../constants/apis/EL/Course";
import { convertParamsToString } from "../../../utils/Request";
import { getHrsMinsSecs } from "../../../utils/Time";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import { useDispatch, useSelector } from "react-redux";
import {
  setCourseAddModuleSpecific,
  setCourseAddOpenToggle,
  setCourseAdded,
  setCourseEdit,
} from "../../../features/EL/courseFormSlice";
import {
  setModuleAddOpenToggle,
  setModuleEdit,
  setModuleId,
} from "../../../features/EL/moduleFormSlice";
import {
  setCourseDetailsOpen,
  setCourseIdForDetails,
} from "../../../features/EL/courseDetailsSlice";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { userRoles } from "../../../enums/Auth";
import {
  setCourseDetailsEdit,
  setCourseDetailsEditId,
  setCourseDetailsEditOpenToggle,
  setCourseDetailsEdited,
} from "../../../features/EL/courseDetailsForm";
import CourseQuestionsForm from "../../../components/ElForms/CourseQuestionsForm/CourseQuestionsForm";
import CourseDetailsForm from "../../../components/ElForms/CourseDetailsForm/CourseDetailsForm";
import {
  setCourseQuestionsEdit,
  setCourseQuestionsEditId,
  setCourseQuestionsEditOpenToggle,
} from "../../../features/EL/courseQuestionsFormSlice";
import AdminCourseActions from "../../../components/ElCustomActions/AdminCourseActions/AdminCourseActions";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import ModuleForm from "../../../components/ElForms/ModuleForm/ModuleForm";
import CourseForm from "../../../components/ElForms/CourseForm/CourseForm";
import CourseDetails from "../../../components/EntityDetails/CourseDetails/CourseDetails";

const Course = () => {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));

  const profileData = useSelector((state) => state.userProfile.profileData);

  const navigate = useNavigate();
  const appSidebar = JSON.parse(JSON.stringify(elSidebarMenus));
  appSidebar.menuList[1].currentPage = true;
  let courseColumnsLocal;

  if (parseInt(profileData?.role_id) === userRoles.SuperAdmin) {
    courseColumnsLocal = courseColumns;
  } else {
    courseColumnsLocal = courseColumns.filter(
      (singleColumn) => singleColumn.id !== "action"
    );
    appSidebar.menuList[1].addEntityButton = false;
  }

  const dispatch = useDispatch();

  const [addButtonAnchorEl, setAddButtonAnchorEl] = useState(null);
  const [searched, setSearched] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalCount, setTotalCount] = useState(0);
  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [rowsLoading, setRowsLoading] = useState(true);
  const [isSAdminSignedIn, setIsSAdminSignedIn] = useState(false);
  const [isCourseDetailsOpen, setIsCourseDetailsOpen] = useState(false);
  const [isCourseDetailsFormOpen, setIsCourseDetailsFormOpen] = useState(false);
  const [isCourseQuestionsFormOpen, setIsCourseQuestionsFormOpen] =
    useState(false);

  const courseAdded = useSelector((state) => state.courseForm.courseAdded);
  const courseDetailsEdited = useSelector(
    (state) => state.courseDetailsForm.courseDetailsEdited
  );

  const requestData = async () => {
    setRowsLoading(true);
    const requestParams = {};

    //handle search
    if (searched !== "") {
      requestParams["search"] = searched;
    }

    //handle sorting here
    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }

    //apply pagination here
    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;

    const axiosInstance = createInstance(true);
    await axiosInstance
      .get(courseApi + convertParamsToString(requestParams))
      .then((res) => {
        const courseRows = res.data.data.rows;

        //adds required fields
        courseRows.forEach((currentRow) => {
          currentRow.id = currentRow.course_id;
          let durationArray = getHrsMinsSecs(currentRow.duration);
          let durationString = "";
          for (let i = 0; i < durationArray.length; i++) {
            let currentNumber = durationArray[i];
            if (currentNumber === 0) durationString += "00";
            else if (currentNumber < 10) durationString += "0" + currentNumber;
            else durationString += currentNumber;
            if (i !== durationArray.length - 1) durationString += ":";
          }
          currentRow.duration = durationString;
        });
        setRows(courseRows);
        setTotalCount(res.data.data.meta.total);
      })
      .catch((err) => {
        setRows([]);
        setTotalCount(0);
        toast.error("Failed to fetch courses", {
          autoClose: autoCloseToastError,
        });
      });
    setRowsLoading(false);
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const getTableHeadCell = (columnInformation) => {
    return (
      <TableCell key={columnInformation.id}>
        <TableSortLabel
          active={orderBy === columnInformation.id}
          direction={orderBy === columnInformation.id ? order : "asc"}
          onClick={() => handleSortRequest(columnInformation.id)}
        >
          {columnInformation.name}
        </TableSortLabel>
      </TableCell>
    );
  };

  const deleteRow = async (id) => {
    try {
      const axiosInstance = createInstance(true);
      const deleteResponse = await axiosInstance.delete(courseApi.concat(id));
      if (deleteResponse.data.success) {
        toast.success("Course deleted successfully");
        if (currentPage === 0 || rows.length > 1) {
          requestData();
        } else {
          setCurrentPage(currentPage - 1);
        }
      } else {
        toast.error("Failed to delete course", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Failed to delete course");
    }
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const [isModuleFormOpen, setIsModuleFormOpen] = useState(false);
  const setAddModuleForm = () => {
    handleAddButtonClose();
    dispatch(setModuleId(null));
    dispatch(setModuleEdit(false));
    dispatch(setModuleAddOpenToggle());
    setIsModuleFormOpen(true);
  };

  const handleModuleClose = () => {
    setIsModuleFormOpen(false);
  };

  const [isCourseFormOpen, setIsCourseFormOpen] = useState(false);
  const setAddCourseForm = () => {
    handleAddButtonClose();
    setIsCourseFormOpen(true);
    dispatch(setCourseAddModuleSpecific(false));
    dispatch(setCourseEdit(false));
    dispatch(setCourseAddOpenToggle());
  };

  const handleCourseClose = () => {
    setIsCourseFormOpen(false);
  };

  const handleCourseDetailsClose = () => {
    setIsCourseDetailsOpen(false);
  };

  const openDetailsForm = (id) => {
    dispatch(setCourseIdForDetails(id));
    dispatch(setCourseDetailsOpen(true));
    setIsCourseDetailsOpen(true);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  const navigateToCourseAssginemt = (id) => {
    navigate(`/el/course-assignment/${id}`);
  };

  const fillDataInCourseDetails = (id) => {
    dispatch(setCourseDetailsEdit(true));
    dispatch(setCourseDetailsEditId(id));
    dispatch(setCourseDetailsEditOpenToggle());
    setIsCourseDetailsFormOpen(true);
  };

  const fillDataInCourseQuestions = (id) => {
    dispatch(setCourseQuestionsEdit(true));
    dispatch(setCourseQuestionsEditId(id));
    dispatch(setCourseQuestionsEditOpenToggle());
    setIsCourseQuestionsFormOpen(true);
  };

  const customActions = {
    navigateToCourseAssginemt,
    fillDataInCourseDetails,
    fillDataInCourseQuestions,
  };

  const handleAddButtonClick = (event) => {
    setAddButtonAnchorEl(event.currentTarget);
  };

  const handleAddButtonClose = () => {
    setAddButtonAnchorEl(null);
  };

  useEffect(() => {
    if (courseAdded) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        requestData();
      }
      dispatch(setCourseAdded(false));
    }
  }, [courseAdded]);

  useEffect(() => {
    if (courseDetailsEdited) {
      if (currentPage !== 0) {
        setCurrentPage(0);
      } else {
        requestData();
      }
      dispatch(setCourseDetailsEdited(false));
    }
  }, [courseDetailsEdited]);

  useEffect(() => {
    requestData();
  }, [currentPage, rowsPerPage, searched, order, orderBy]);

  useEffect(() => {
    const sessionRole = profileData?.role_id;

    if (parseInt(sessionRole) === userRoles.SuperAdmin) {
      setIsSAdminSignedIn(true);
    }
  }, []);

  return (
    <>
      {isCourseDetailsFormOpen && (
        <CourseDetailsForm
          isOpenProp={isCourseDetailsFormOpen}
          handleClose={() => setIsCourseDetailsFormOpen(false)}
        />
      )}
      {isCourseQuestionsFormOpen && (
        <CourseQuestionsForm
          isOpenProp={isCourseQuestionsFormOpen}
          handleClose={() => setIsCourseQuestionsFormOpen(false)}
        />
      )}
      {isModuleFormOpen && (
        <ModuleForm
          isOpenProp={isModuleFormOpen}
          handleClose={handleModuleClose}
        />
      )}
      {isCourseFormOpen && (
        <CourseForm
          isOpenProp={isCourseFormOpen}
          handleClose={handleCourseClose}
        />
      )}
      {isCourseDetailsOpen && (
        <CourseDetails
          isOpenProp={isCourseDetailsOpen}
          handleClose={handleCourseDetailsClose}
        />
      )}
      <Container maxWidth={"xxl"}>
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">Courses</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnGap={!isSmallerScreen ? 2 : 0}
              justifyContent={!isSmallerScreen ? "end" : "space-between"}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={"auto"}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={"auto"} mt={isSmallerScreen && 1}>
                {isSAdminSignedIn ? (
                  <>
                    <Button
                      variant="outlined"
                      onClick={handleAddButtonClick}
                      startIcon={<AddIcon />}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      New
                    </Button>
                    <Menu
                      sx={{ mt: "45px" }}
                      id="menu-add-button"
                      disableScrollLock
                      anchorEl={addButtonAnchorEl}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      keepMounted
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={Boolean(addButtonAnchorEl)}
                      onClose={handleAddButtonClose}
                      slotProps={{
                        paper: {
                          elevation: 0,
                          sx: {
                            overflow: "visible",
                            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                            mt: 1.5,
                            borderRadius: "10px",
                            "& .MuiAvatar-root": {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            "&::before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: "background.paper",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ pr: 4, py: 1, textAlign: "start" }}
                        onClick={() => setAddModuleForm()}
                      >
                        <Typography textAlign="center">Module</Typography>
                      </MenuItem>
                      <MenuItem
                        sx={{ pr: 4, py: 1, textAlign: "start" }}
                        className="action-item"
                        onClick={() => setAddCourseForm()}
                      >
                        <Typography textAlign="center">Course</Typography>
                      </MenuItem>
                    </Menu>
                  </>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />{" "}
          <Box>
            {rowsLoading ? (
              <LoadingSpinner />
            ) : rows.length === 0 ? (
              "No data found..."
            ) : (
              <>
                <TableContainer>
                  <Table size="medium">
                    <TableHead className="stat-card-bg">
                      <TableRow>
                        {courseColumnsLocal.map((assetColumn) =>
                          getTableHeadCell(assetColumn)
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rows &&
                        rows.map((courseRow) => {
                          return (
                            <TableRow key={courseRow.id}>
                              <Tooltip title={courseRow.title}>
                                <TableCell
                                  sx={{ maxWidth: "300px" }}
                                  className="text-truncate primary-text link-hover"
                                  onClick={() => openDetailsForm(courseRow.id)}
                                >
                                  {courseRow.title}
                                </TableCell>
                              </Tooltip>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "250px" }}
                              >
                                {courseRow.module_title}
                              </TableCell>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "100px" }}
                              >
                                {courseRow.duration}
                              </TableCell>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "100px" }}
                              >
                                {courseRow.language}
                              </TableCell>

                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "100px" }}
                              >
                                {courseRow.status}
                              </TableCell>

                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {courseRow.learners_count}
                              </TableCell>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {courseRow.active_learners}
                              </TableCell>
                              {profileData?.role_id === userRoles.SuperAdmin && <TableCell align="right">
                                <AdminCourseActions
                                  customActions={customActions}
                                  id={courseRow.id}
                                  deleteModalData={["Course", courseRow.title]}
                                  handleDeleteRow={deleteRow}
                                />
                              </TableCell>}
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </TableContainer>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={totalCount}
                  rowsPerPage={rowsPerPage}
                  page={currentPage}
                  onPageChange={handlePage}
                  onRowsPerPageChange={handleRowsPerPage}
                />
              </>
            )}
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default Course;
