const messages = {
    accountId: "You must select an account",

    locationNameDefault: "Location name cannot be empty",
    locationNameMax: "Maximum 255 characters are allowed",
    locationNameMin: "Minimum 3 characters are required",

    locationCodeMin: "Minimum 3 characters are required",
    locationCodeMax: "Maximum 30 characters allowed",

    addressMax: "Maximum 1024 characters are allowed",
};

export { messages };
