import { messages } from "../../constants/validationMessages/BdForms/AddDeal";
import { probabilities, stages } from "../../enums/Deal";
const Joi = require("joi");

const dealSchema = Joi.object().keys({
    account_id: Joi.number()
        .integer()
        .min(1)
        .required()
        .messages({
            "number.min": messages.accountId,
            "number.base": messages.accountId,
            "any.only": messages.accountId
        }),
    
    assigned_to: Joi.number()
    .integer()
    .min(1)
    .required()
    .messages({
        "number.min": messages.assignedTo,
        "number.base": messages.assignedTo,
        "any.only": messages.assignedTo
    }),

    deal_name: Joi.string()
        .min(3)
        .max(255)
        .required()
        .messages({
            "string.max": messages.titleMax,
            "string.empty": messages.titleDefault,
        }),
    
    amount: Joi.number()
        .min(1)
        .required()
        .messages({
            "number.min": messages.amountMin,
            "number.base": messages.amountDefault
        }),
    
    deal_stage: Joi.string()
        .valid(...Object.values(stages))
        .required()
        .messages({
            "string.empty": messages.stageDefault,
            "any.only": messages.stageDefault
        }),

    deal_next_step: Joi.string()
        .allow("")
        .messages({
            "any.only": messages.nextStepDefault
        }),

    probability: Joi.number()
        .valid(...Object.values(probabilities))
        .required()
        .messages({
            "number.base": messages.probabilityDefault,
            "any.only": messages.probabilityDefault
        }),

    description: Joi.string()
        .allow("")
        .required()
        .messages({
            "any.required": messages.descriptionDefault
        }),

    due_date: Joi.alternatives([
        Joi.date().required()
        ]).required()
        .messages({
            "date.empty": messages.dueDateDefault,
        }),
});

export { dealSchema };