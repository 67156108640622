const languages = Object.freeze({
    english: "English",
    marathi: "Marathi"
});

const testResults = Object.freeze({
    pass: "Pass",
    fail: "Fail"
});

const userCourseStatuses = Object.freeze({
    started: "Started",
    notStarted: "Not Started",
    completed: "Completed"
});

module.exports = { languages, testResults, userCourseStatuses };