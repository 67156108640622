import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
    assignments: {},
    assignmentsLoading: false,
    selectedAssignmentDetails: null,
    message: null,
    error: null,
};

const assignmentSlice = createSlice({
    name: "assignment",
    initialState,
    reducers: {

        getAssignmentsRequest(state) {
            state.assignmentsLoading = true;
        },

        getAssignmentsSuccess(state, action) {
            state.assignmentsLoading = false;
            state.assignments = action.payload;
            state.assignments.rows.forEach((assign_asset) => {
                assign_asset.id = assign_asset.assign_asset_id;
                if ("assigned_on" in assign_asset) {
                    const formattedDate = assign_asset["assigned_on"];
                    assign_asset["assigned_on"] = formattedDate
                        ? moment(formattedDate).format("DD/MM/YYYY")
                        : "";
                }
                if ('warranty_end_date' in assign_asset) {
                    const formattedDate = assign_asset["warranty_end_date"];
                    assign_asset["warranty_end_date"] =
                        formattedDate
                            ? moment(assign_asset["warranty_end_date"]).format(
                                "DD/MM/YYYY"
                            ) : "";
                }

                return assign_asset;
            });
        },

        getAssignmentsFail(state) {
            state.assignmentsLoading = false;
        },

        setSelectedAssignmentDetails(state, action) {
            state.selectedAssignmentDetails = action.payload;
        },

        addAssignment(state, action) {
            let addedAssignment = action.payload;
            addedAssignment.id = addedAssignment.assign_asset_id || addedAssignment.assign_consumable_id;
            addedAssignment.assign_asset_id = addedAssignment.assign_asset_id || addedAssignment.assign_consumable_id;
            addedAssignment.asset_name = addedAssignment.asset_name || addedAssignment.consumable_name;
            if ("assigned_on" in addedAssignment) {
                const formattedDate = addedAssignment["assigned_on"];
                addedAssignment["assigned_on"] = formattedDate
                    ? moment(formattedDate).format("DD/MM/YYYY")
                    : "";
            }
            if ('warranty_end_date' in addedAssignment) {
                const formattedDate = addedAssignment["warranty_end_date"];
                addedAssignment["warranty_end_date"] =
                    formattedDate
                        ? moment(addedAssignment["warranty_end_date"]).format(
                            "DD/MM/YYYY"
                        ) : "";
            }
            state.assignments.rows = [addedAssignment, ...state.assignments.rows]
        },

        clearError(state) {
            state.error = null;
        },

        clearMessage(state) {
            state.message = null;
        },
    },
});

export const assignmentReducer = assignmentSlice.reducer;

export const assignmentActions = assignmentSlice.actions;
