const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    locationEdit: false,
    locationAddAccountSpecific: false,
    locationAddedForAccountId: null,
    locationId: null,
    locationAccountId: null,
    locationEditOpenToggle: false,
    locationAddOpenToggle: false,
    locationAccountName: "",
    locationAdded: false,
    locationEdited: false,
};

export const formSlice = createSlice({
    name: "locationForm",
    initialState,
    reducers: {
        setLocationEdit: (state, action) => {
            state.locationEdit = action.payload;
        },

        setLocationAddAccountSpecific: (state, action) => {
            state.locationAddAccountSpecific = action.payload;
        },

        setLocationAddedForAccountId: (state, action) => {
            state.locationAddedForAccountId = action.payload;
        },

        setLocationAccountId: (state, action) => {
            state.locationAccountId = action.payload;
        },

        setLocationAccountName: (state, action) => {
            state.locationAccountName = action.payload;
        },

        setLocationAdded: (state, action) => {
            state.locationAdded = action.payload;
        },

        setLocationEdited: (state, action) => {
            state.locationEdited = action.payload;
        },

        setLocationId: (state, action) => {
            state.locationId = action.payload;
        },

        setLocationEditOpenToggle: (state, action) => {
            state.locationEditOpenToggle = !state.locationEditOpenToggle;
        },

        setLocationAddOpenToggle: (state, action) => {
            state.locationAddOpenToggle = !state.locationAddOpenToggle;
        },
    },
});

export const {
    setLocationEdit,
    setLocationAddAccountSpecific,
    setLocationAddedForAccountId,
    setLocationAccountId,
    setLocationAccountName,
    setLocationAdded,
    setLocationEdited,
    setLocationId,
    setLocationEditOpenToggle,
    setLocationAddOpenToggle,
} = formSlice.actions;

export default formSlice.reducer;
