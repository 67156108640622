import React, { useEffect, useState } from "react";
import { createInstance, errorHandler } from "../../utils/Request/ReqUtils";
import assetColumns from "../../data/json/assetColumns.json";
import assetColumnsForUser from "../../data/json/assetColumnsForUser.json";
import assetSideBarMenus from "../../data/json/assetSideBarMenus.json";
import { useDispatch, useSelector } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../constants/Common";
import { assetAPI } from "../../constants/apis/AM/Assets";
import AssetDetails from "../../components/EntityDetails/AssetDetails/AssetDetails";
import { setAssetIdForDetails } from "../../features/AM/assetDetailsSlice";
import { setAssetDetailsOpen } from "../../features/AM/assetDetailsSlice";
import { userRoles } from "../../enums/Auth";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import AssetForm from "../../components/AmForms/AssetForm/AssetForm";
import TableMenu from "../../components/TableMenu/TableMenu";
import { getAssets } from "../../redux/actions/am-actions/assets.actions";
import { assetActions } from "../../redux/reducers/am-reducers/assets.reducer";
import moment from "moment";
import getTableCell from "../../utils/Common/DataTable";

const Assets = () => {
  //sidebar menus details is provided to load the sidebar for business development
  const appSidebar = JSON.parse(JSON.stringify(assetSideBarMenus));
  appSidebar.menuList[1].currentPage = true;

  const profileData = useSelector((state) => state.userProfile.profileData);

  const role = parseInt(profileData?.role_id);

  if (role === userRoles.User) {
    appSidebar.menuList[5].currentPage = true;
  }

  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searched, setSearched] = useState("");
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [assetDetailsOpen, setAssetDetailsOpen] = useState(false)
  const [assetFormOpen, setAssetFormOpen] = useState(false)
  const [assetEditProp, setAssetEditProp] = useState(false)

  const assetsState = useSelector(state => state.assets);
  const rowsLoading = assetsState?.assetsLoading;
  const rows = assetsState?.assets?.rows;
  const totalCount = assetsState?.assets?.meta?.total;


  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));


  const getRequestParams = () => {
    const requestParams = {};

    //handle search
    if (searched !== "") {
      requestParams["search"] = searched;
    }

    //handle sorting here
    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }

    //apply pagination here
    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;

    return requestParams;
  }

  const requestData = async () => {
    const requestParams = getRequestParams();
    dispatch(getAssets(requestParams));
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const deleteRow = async (id) => {
    try {
      const axiosInstance = createInstance(true);
      const deleteResponse = await axiosInstance.delete(assetAPI.concat(id));
      if (deleteResponse.data.success) {
        toast.success("Asset deleted successfully");
        if (currentPage === 0 || rows?.length > 1) {
          requestData();
        } else {
          setCurrentPage(currentPage - 1);
        }
      } else {
        toast.error("Failed to delete Asset", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Failed to delete Asset");
    }
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };



  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const handleDrawerClose = () => {
    if (assetEditProp) {
      setAssetEditProp(false);
    }
    setAssetFormOpen(false);
    dispatch(assetActions.setSelectedAssetData(null));
  };

  const setAssetForm = () => {
    setAssetFormOpen(true);
    setAssetEditProp(false);
  };

  const fillDataInForm = (id) => {
    const selectedAssetData = assetsState.assets.rows?.filter(
      (asset) => asset.asset_id === id
    )?.[0];
    dispatch(assetActions.setSelectedAssetData(selectedAssetData));
    setAssetEditProp(true);
    setAssetFormOpen(true);
  };

  const openDetailsForm = (id) => {
    const selectedAssetData = assetsState.assets.rows?.filter(
      (asset) => asset.asset_id === id
    )?.[0];
    dispatch(assetActions.setSelectedAssetData(selectedAssetData));
    setAssetDetailsOpen(true);
  };

  const handleCloseDetailsForm = () => {
    dispatch(assetActions.setSelectedAssetData(null));
    setAssetDetailsOpen(false);
  }

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  useEffect(() => {
    requestData();
  }, [currentPage, rowsPerPage, searched, order, orderBy]);

  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {assetDetailsOpen && <AssetDetails assetDetailsOpen={assetDetailsOpen} handleDrawerClose={handleCloseDetailsForm} dashboard={false} />}

        {assetFormOpen && <AssetForm
          assetFormOpen={assetFormOpen}
          assetEditProp={assetEditProp}
          handleDrawerClose={handleDrawerClose}
          isSmallerScreen={isSmallerScreen}
        />}

        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">
                {role !== userRoles.User ? "Assets" : "My Assets"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnGap={{ md: 2 }}
              justifyContent={{ xs: "space-between", md: "end" }}
              alignItems={"center"}
            >
              <Grid item xs={12} md={"auto"} my={1}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} md={"auto"}>
                {(role === userRoles.SuperAdmin ||
                  role === userRoles.Admin) && (
                    <Button
                      variant="outlined"
                      onClick={() => setAssetForm()}
                      startIcon={<AddIcon />}
                    >
                      Add
                    </Button>
                  )}
              </Grid>
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows?.length === 0 ? (
            role !== userRoles.User ? (
              "No data found..."
            ) : (
              "No assets assigned..."
            )
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow key={`asset_head_row`}>
                      {role === userRoles.User
                        ? assetColumnsForUser.map((assetColumn) =>
                          getTableCell(assetColumn, orderBy, order, handleSortRequest)
                        )
                        : assetColumns.map((assetCol) =>
                          getTableCell(assetCol, orderBy, order, handleSortRequest)
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.map((assetRow, i) => {
                        return (
                          <TableRow key={`asset_${i}`}>
                            <Tooltip title={assetRow.asset_name}>
                              <TableCell
                                sx={{ maxWidth: "250px" }}
                                className="text-truncate primary-text link-hover"
                                onClick={() => openDetailsForm(assetRow.id)}
                              >
                                {assetRow.asset_name}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {moment(assetRow.date_of_purchase).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assetRow.purchased_quantity}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assetRow.available_quantity}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assetRow.category}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assetRow.make}
                            </TableCell>
                            <TableCell align="right">
                              <TableMenu
                                fillDataInForm={fillDataInForm}
                                rowId={assetRow.id}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={Boolean(totalCount) ? totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Assets;
