import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import AsyncSelect from "react-select/async";
import { asyncSelectNewCustomStyles } from "../../../constants/Common";
import {
  searchOptionDebounceTime,
  minCharsToLoadOptions,
} from "../../../constants/Common";
import debounce from "lodash/debounce";
import { consumableAPI } from "../../../constants/apis/AM/Consumables";
import { userOptionsApi } from "../../../constants/apis/User";
import moment from "moment";
import {
  setConsumableNameOptions,
  setConsumableNameOptionsLoaded,
  setUserOptions,
  setUserOptionsLoaded,
} from "../../../features/optionSlice";
import { assignConsumablesAPI } from "../../../constants/apis/AM/AssignConsumables";
import { consumableAssignmentSchema } from "../../../validations/AmForms/ConsumableAssignment";
import { userOption } from "../../AsyncSelectOption/CustomizedOptions";
import { createSearchParams } from "react-router-dom";
import RequestInProgressModal from "../../RequestInProgressModal/RequestInProgressModal";
import Asterisk from "../../Misc/Asterisk";
import { assignmentActions } from "../../../redux/reducers/am-reducers/assignments.reducer";

const ConsumableAssignmentForm = ({ isConsumableAssignmentsFormOpen, handleDrawerClose, isSmallerScreen }) => {

  const profileData = useSelector((state) => state.userProfile.profileData);

  const initialConsumableAssignmentData = {
    consumable_name: "",
    make: "",
    model: "",
    user_id: null,
    assigned_on: "",
    date_of_purchase: "",
    assigned_quantity: "",
  };

  const dispatch = useDispatch();

  const [consumableAssignmentFormErrors, setConsumableAssignmentFormErrors] =
    useState({});
  const [consumableNameSelected, setConsumableNameSelected] = useState(null);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [dop, setDop] = useState([]);
  const [consumableAssignmentData, setConsumableAssignmentData] = useState(
    initialConsumableAssignmentData
  );
  const [userName, setUserName] = useState(null);
  const [consumableId, setConsumableId] = useState(null);
  const [description, setDescription] = useState(null);
  const [inStock, setInStock] = useState(null);
  const [location, setLocation] = useState(null);
  const [assignmentInProgress, setAssignmentInProgress] = useState(false);

  const consumableNameOptions = useSelector(
    (state) => state.optionsForAsyncSelect.consumableNameOptions
  );
  const consumableAssignmentAddOpenToggle = useSelector(
    (state) => state.consumableAssignmentForm.consumableAssignmentAddOpenToggle
  );
  const consumableNameOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.consumableNameOptionsLoaded
  );

  const userOptions = useSelector(
    (state) => state.optionsForAsyncSelect.userOptions
  );
  const userOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.userOptionsLoaded
  );

  const handleConsumableAssignmentForm = (e) => {
    setConsumableAssignmentFormErrors({
      ...consumableAssignmentFormErrors,
      [e.target.name]: "",
    });
    setConsumableAssignmentData({
      ...consumableAssignmentData,
      [e.target.name]: e.target.value,
    });
    if (e.target.name === "model" && e.target.value === "") {
      setInStock(null);
    }
  };

  const closeConsumableAssignmentForm = () => {
    setConsumableAssignmentData(initialConsumableAssignmentData);
    handleDrawerClose();
  };

  const handleConsumableAssignmentSubmit = async (e) => {
    e.preventDefault();
    setAssignmentInProgress(true);
    const userId = profileData?.user_id;
    const validation = consumableAssignmentSchema.validate(
      consumableAssignmentData,
      { abortEarly: false }
    );
    if (validation.error) {
      setConsumableAssignmentFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setConsumableAssignmentFormErrors(currentErrors);
      setAssignmentInProgress(false);
    } else {
      try {
        const serverData = {
          consumable_id: consumableId,
          user_id: consumableAssignmentData.user_id,
          assigned_on: consumableAssignmentData.assigned_on,
          created_by: null,
          assigned_quantity: consumableAssignmentData.assigned_quantity,
          location: location,
        };

        if (inStock < consumableAssignmentData.assigned_quantity) {
          toast.error(
            "Number of consumables to assign should not be more than available quantity"
          );
        } else {
          const payload = { ...serverData, created_by: userId };
          let payloadConsumableAssignmentData = JSON.parse(
            JSON.stringify(payload)
          );
          const axiosInstance = createInstance(true);
          let consumableAssignmentAddResponse = await axiosInstance.post(
            assignConsumablesAPI,
            payloadConsumableAssignmentData
          );

          dispatch(assignmentActions.addAssignment(consumableAssignmentAddResponse.data.data));
          if (
            consumableAssignmentAddResponse.data.success &&
            consumableAssignmentAddResponse.data.data.length !== 0
          ) {
            toast.success("Consumable Assigned Successfully");
          } else {
            toast.error("Failed to assign consumable", {
              autoClose: autoCloseToastError,
            });
          }
        }
      } catch (err) {
        errorHandler(err, "Failed to assign consumable");
      } finally {
        setAssignmentInProgress(false);
      }
    }
  };

  const handleConsumableNameFocus = async () => {
    if (!consumableNameOptionsLoaded) {
      const fetchedOptions = await fetchConsumableNames("");
      dispatch(setConsumableNameOptionsLoaded(true));
      dispatch(setConsumableNameOptions(fetchedOptions));
    }
  };

  const handleUserFocus = async () => {
    if (!userOptionsLoaded) {
      const fetchedOptions = await fetchUserOptions("");
      dispatch(setUserOptionsLoaded(true));
      dispatch(setUserOptions(fetchedOptions));
    }
  };

  const fetchConsumableNames = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      const response = await axiosInstance.get(
        consumableAPI + "/getconsumable-names?search=" + inputValue
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => ({
          value: item.consumable_name,
          label: item.consumable_name,
        }));
        return formattedOptions;
      } else {
        toast.error("Error while fetching consumable name options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching consumable name otpions");
    }
    return [];
  };

  const loadConsumableNames = (inputValue, callback) => {
    fetchConsumableNames(inputValue)
      .then((options) => callback(options))
      .catch((err) => {
        errorHandler(err, "error while fetching consumable name options");
        callback([]);
      });
  };

  const fetchUserOptions = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      const response = await axiosInstance.get(
        userOptionsApi + "?search=" + inputValue
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = { location: item.location, mobile: item.mobile };

          return {
            value: item.user_id,
            label: item.assign_to,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching assigned to options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching assigned to options");
    }
    return [];
  };

  const loadUserOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchUserOptions(inputValue)
      .then((options) => callback(options))
      .catch((err) => {
        errorHandler(err, "error while fetching user options");
        callback([]);
      });
  };

  useEffect(() => {
    const fetchDateOfPurchase = async () => {
      if (consumableNameSelected) {
        try {
          const axiosInstance = createInstance(true);
          const response = await axiosInstance.get(
            consumableAPI +
            "/fetch-date-of-purchases/" +
            consumableNameSelected.value
          );
          const responseArr = response.data.data;
          const formattedArr = responseArr.map((currentDate) => {
            let formattedDate = {
              date_of_purchase: "",
            };
            formattedDate.date_of_purchase = currentDate.date_of_purchase
              ? moment(currentDate.date_of_purchase).format("DD/MM/YYYY")
              : "";
            return formattedDate;
          });
          setDop(formattedArr);
        } catch (err) {
          errorHandler(err, "error while fetching date of purchases");
        }
      }
    };

    fetchDateOfPurchase();
  }, [consumableNameSelected]);

  useEffect(() => {
    const fetchMake = async () => {
      if (consumableAssignmentData.date_of_purchase && consumableNameSelected) {
        try {
          const axiosInstance = createInstance(true);
          const params = createSearchParams({
            consumableName: consumableNameSelected.value,
            dateOfPurchase: consumableAssignmentData.date_of_purchase,
          });
          const response = await axiosInstance.get(
            consumableAPI + `/fetch-makes?${params}`
          );
          setMakes(response.data.data);
        } catch (err) {
          errorHandler(err, "error while fetching makes");
        }
      }
    };

    fetchMake();
  }, [consumableAssignmentData.date_of_purchase, consumableNameSelected]);

  useEffect(() => {
    const fetchModel = async () => {
      if (
        consumableAssignmentData.date_of_purchase &&
        consumableAssignmentData.make &&
        consumableNameSelected
      ) {
        try {
          const axiosInstance = createInstance(true);
          const params = createSearchParams({
            consumableName: consumableNameSelected.value,
            dateOfPurchase: consumableAssignmentData.date_of_purchase,
            make: consumableAssignmentData.make,
          });
          const response = await axiosInstance.get(
            consumableAPI + `/fetch-models?${params}`
          );
          const responseArr = response.data.data;
          setModels(responseArr);
        } catch (err) {
          errorHandler(err, "error while fetching model options");
        }
      }
    };

    fetchModel();
  }, [consumableAssignmentData.make]);

  useEffect(() => {
    const fetchAllFields = async () => {
      if (
        consumableNameSelected &&
        consumableAssignmentData.date_of_purchase &&
        consumableAssignmentData.make &&
        consumableAssignmentData.model
      ) {
        try {
          const axiosInstance = createInstance(true);
          const response = await axiosInstance.get(
            consumableAPI + "/getconsumableby-fields",
            {
              params: {
                consumableName: consumableNameSelected.value,
                make: consumableAssignmentData.make,
                dateOfPurchase: consumableAssignmentData.date_of_purchase,
                model: consumableAssignmentData.model,
              },
            }
          );
          if (response.data && response.data.success) {
            const data = response.data.data;
            setConsumableId(data[0].consumable_id);
            setDescription(data[0].description);
            setInStock(data[0].in_stock);
          } else {
            setDescription("");
            setLocation("");
            setInStock("");
            toast.error("No consumable is available for selected values", {
              autoClose: autoCloseToastError,
            });
          }
        } catch (err) {
          setDescription("");
          setLocation("");
          setInStock("");
          errorHandler(err, "Error while fetching data for fields");
        }
      }
    };
    fetchAllFields();
  }, [consumableAssignmentData.model]);

  useEffect(() => {
    setConsumableAssignmentFormErrors({});
    setConsumableAssignmentData(() => initialConsumableAssignmentData);
    setDescription("");
    setLocation("");
    setUserName("");
    setConsumableNameSelected(null);
    setInStock(null);
  }, [consumableAssignmentAddOpenToggle]);

  useEffect(() => {
    setMakes([]);
    setModels([]);
    setDop([]);
    setConsumableAssignmentData((prev) => ({
      ...prev,
      model: "",
      make: "",
      date_of_purchase: "",
    }));
  }, [consumableNameSelected]);

  useEffect(() => {
    setMakes([]);
    setModels([]);
    setInStock(null);
    setConsumableAssignmentData((prev) => ({ ...prev, model: "", make: "" }));
  }, [consumableAssignmentData.date_of_purchase]);

  useEffect(() => {
    setModels([]);
    setInStock(null);
    setConsumableAssignmentData((prev) => ({ ...prev, model: "" }));
  }, [consumableAssignmentData.make]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: { sm: "80vw", md: "70vw", lg: "60vw" },
            px: { xs: 2, md: 4 },
          },
        }}
        anchor={"right"}
        open={isConsumableAssignmentsFormOpen}
        onClose={closeConsumableAssignmentForm}
      >
        <Box my={2} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h5">Assign Consumable</Typography>
          <IconButton
            aria-label="close"
            onClick={closeConsumableAssignmentForm}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider className="horizontal-line" />

        <Box my={3}>
          <Grid
            container
            rowSpacing={{ xs: 1, md: 4 }}
            columnSpacing={{ xs: 0, md: 10, lg: 15 }}
          >
            {/* Consumable Name */}
            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Consumable Name "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <AsyncSelect
                  className={`course-input-select ${Boolean(consumableAssignmentFormErrors.consumable_name)
                    ? "error-border"
                    : ""
                    }`}
                  value={consumableNameSelected}
                  defaultOptions={consumableNameOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setConsumableAssignmentFormErrors({
                        ...consumableAssignmentFormErrors,
                        ["consumable_name"]: "",
                      });
                      setConsumableAssignmentData({
                        ...consumableAssignmentData,
                        consumable_name: selectedOption.value,
                      });
                    } else {
                      setConsumableAssignmentData({
                        ...consumableAssignmentData,
                        consumable_name: "",
                      });
                    }
                    setConsumableNameSelected(selectedOption);
                    setDescription("");
                    setInStock("");
                  }}
                  loadOptions={debounce(
                    loadConsumableNames,
                    searchOptionDebounceTime
                  )}
                  placeholder="Type to search..."
                  name="consumable_name"
                  noOptionsMessage={() =>
                    "No Consumable Names Found. Type again..."
                  }
                  isClearable
                  onFocus={handleConsumableNameFocus}
                />

                {Boolean(consumableAssignmentFormErrors.consumable_name) && (
                  <Typography variant="caption" color="error" p={2}>
                    {consumableAssignmentFormErrors.consumable_name}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6} alignItems={"start"}>
              {/* Date of Purchase */}
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Date of Purchase "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  value={consumableAssignmentData.date_of_purchase}
                  defaultValue="Select"
                  name="date_of_purchase"
                  onChange={(e) => handleConsumableAssignmentForm(e)}
                  error={Boolean(
                    consumableAssignmentFormErrors.date_of_purchase
                  )}
                  size="small"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {dop.map((obj, i) => (
                    <MenuItem key={`dop_${i}`} value={obj.date_of_purchase}>
                      {obj.date_of_purchase}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(consumableAssignmentFormErrors.date_of_purchase) && (
                  <Typography variant="caption" color="error" p={2}>
                    {consumableAssignmentFormErrors.date_of_purchase}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6} alignItems={"start"}>
              {" "}
              {/* Make */}
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Make "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  value={consumableAssignmentData.make}
                  defaultValue="Select"
                  name="make"
                  onChange={(e) => handleConsumableAssignmentForm(e)}

                  error={Boolean(consumableAssignmentFormErrors.make)}
                  size="small"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {makes.map((obj, i) => (
                    <MenuItem key={`make_${i}`} value={obj.make}>
                      {obj.make}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(consumableAssignmentFormErrors.make) && (
                  <Typography variant="caption" color="error" p={2}>
                    {consumableAssignmentFormErrors.make}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6} alignItems={"start"}>
              {/* Model */}
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Model "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  value={consumableAssignmentData.model}
                  name="model"
                  onChange={(e) => handleConsumableAssignmentForm(e)}

                  error={Boolean(consumableAssignmentFormErrors.model)}
                  size="small"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {models.map((obj, i) => (
                    <MenuItem key={`model_${i}`} value={obj.model}>
                      {obj.model}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(consumableAssignmentFormErrors.model) && (
                  <Typography variant="caption" color="error" p={2}>
                    {consumableAssignmentFormErrors.model}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6} alignItems={"start"}>
              {/* Assigned Quantity */}
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Assigned Quantity "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  id="assigned-quantity"
                  name="assigned_quantity"
                  size="small"
                  value={consumableAssignmentData.assigned_quantity}
                  placeholder="Enter Count"
                  type="number"
                  onChange={(e) => handleConsumableAssignmentForm(e)}
                  helperText={consumableAssignmentFormErrors.assigned_quantity}
                  error={Boolean(
                    consumableAssignmentFormErrors.assigned_quantity
                  )}
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: inStock } }}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                {/* Assigned On */}
                <InputLabel className={"fw-bold"}>
                  {"Assigned On "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  id="assigned-quantity"
                  size="small"

                  fullWidth
                  type="date"
                  placeholder="Assigned on"
                  name="assigned_on"
                  value={consumableAssignmentData.assigned_on}
                  onChange={(e) => handleConsumableAssignmentForm(e)}
                  error={Boolean(consumableAssignmentFormErrors.assigned_on)}
                  helperText={consumableAssignmentFormErrors.assigned_on}
                />
              </Grid>
            </Grid>

            <Grid item container xs={12} md={6} alignItems={"start"}>
              <Grid item xs={12} xl={5}>
                {/* Assign To */}
                <InputLabel className={"fw-bold"}>
                  {"Assign To "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <AsyncSelect
                  className={`course-input-select ${Boolean(consumableAssignmentFormErrors.user_id)
                    ? "error-border"
                    : ""
                    }`}
                  onChange={(selectedOption) => {
                    setUserName(selectedOption);
                    if (selectedOption) {
                      setLocation(selectedOption.additionalData.location);
                      setConsumableAssignmentFormErrors({
                        ...consumableAssignmentFormErrors,
                        user_id: "",
                      });
                      setConsumableAssignmentData({
                        ...consumableAssignmentData,
                        user_id: selectedOption.value,
                      });
                    } else {
                      setConsumableAssignmentData({
                        ...consumableAssignmentData,
                        user_id: null,
                      });
                      setLocation("");
                    }
                  }}
                  value={userName}
                  defaultOptions={userOptions}
                  loadOptions={debounce(
                    loadUserOptions,
                    searchOptionDebounceTime
                  )}
                  placeholder="Type to search..."
                  name="user_id"
                  noOptionsMessage={() => "No Users found. Type again..."}
                  isClearable
                  onFocus={handleUserFocus}
                  components={{ Option: userOption }}
                />
                {Boolean(consumableAssignmentFormErrors.user_id) && (
                  <Typography variant="caption" color="error" p={2}>
                    {consumableAssignmentFormErrors.user_id}
                  </Typography>
                )}
              </Grid>{" "}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            mt={3}
            alignItems={"center"}
            className={"stat-card-bg"}
            px={2}
            py={1}
          >
            <Grid container my={2}>
              <Grid item xs={12} md={2}>
                <Typography fontWeight={"bold"} color={"textSecondary"}>
                  In Stock
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>{inStock} </Typography>
              </Grid>
            </Grid>

            {description && (
              <Grid container my={1}>
                <Grid item xs={12} md={2}>
                  <Typography fontWeight={"bold"} color={"textSecondary"}>
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography>{description}</Typography>
                </Grid>
              </Grid>
            )}

            <Grid container my={1}>
              <Grid item xs={12} md={2}>
                <Typography fontWeight={"bold"} color={"textSecondary"}>
                  Location
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>{location}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="body2" my={2}>
          <Asterisk />
          {" Mandatory Fields"}
        </Typography>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"end"}
          bottom={0}
        >
          <Divider className="horizontal-line" sx={{ my: 1 }} />

          <Box my={3} display={"flex"} columnGap={5}>
            <RequestInProgressModal
              show={assignmentInProgress}
              headerText={""}
              contentText={"Consumable update in progress.."}
            />

            <Button
              variant="contained"
              type="submit"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={(e) => {
                handleConsumableAssignmentSubmit(e);
              }}
            >
              Save
            </Button>

            <Button
              variant="contained"
              type="button"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={closeConsumableAssignmentForm}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default ConsumableAssignmentForm;
