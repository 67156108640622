const timeframes = Object.freeze({
    tfy: "This Financial Year",
    lfy: "Last Financial Year",
    l12m: "Last 12 Months",
    l6m: "Last 6 Months",
    tm: "This Month",
    lm: "Last Month",
    tw: "This Week",
    lw: "Last Week"
});

module.exports = { timeframes };