import * as yup from "yup";
import { languages } from "../../../enums/EL/Course";
import { maxFilesPerCourse } from "../../../constants/CourseForm";

const courseDetailsSchema = yup.object({
    title: yup
        .string()
        .trim()
        .required("Title cannot be empty")
        .min(3, "Title should be greater than 2 characters")
        .max(255, "Title should not be greater than 255 characters"),

    description: yup
        .string()
        .required("Description cannot be empty")
        .min(3, "Description should be greater than 2 characters")
        .max(255, "Description should not be greater than 1024 characters"),

    course_image_name: yup
        .string()
        .required("Upload course image"),

    deleted_course_file_names: yup
        .array()
        .of(yup.string())
        .max(maxFilesPerCourse, `You can delete at most ${maxFilesPerCourse} items`),

    new_course_file_names: yup
        .array()
        .of(yup.string())
        .max(maxFilesPerCourse, `You can add at most ${maxFilesPerCourse} items`),

    module_id: yup
        .number()
        .integer()
        .min(1)
        .required("Please select module"),

    language: yup
        .string()
        .oneOf(Object.values(languages), "Please select a valid language")
        .required("Please select language"),

    hours: yup
        .number()
        .integer(),
    minutes: yup
        .number()
        .integer(),

    passing_percentage: yup
        .number()
        .min(1, "Percentage should be greater than 0")
        .max(100, "Percentage should be less than 100")
        .required("Please add passing percentage")
});

const questionSchema = yup.object({
    question_text: yup
        .string()
        .trim()
        .min(3, "Question should be greater than 2 characters")
        .max(255, "Question should not be greater than 255 characters")
        .required("Question cannot be empty"),

    marks: yup
        .number()
        .integer()
        .min(1, "Marks cannot be less than 1")
        .required("Marks cannot be empty"),

    option_1: yup
        .string()
        .trim()
        .required("Option cannot be empty")
        .min(1, "Option should be at least 1 character"),
    option_2: yup
        .string()
        .trim()
        .required("Option cannot be empty")
        .min(1, "Option should be at least 1 character"),

    option_3: yup
        .string()
        .trim()
        .required("Option cannot be empty")
        .min(1, "Option should be at least 1 character"),

    option_4: yup
        .string()
        .trim()
        .required("Option cannot be empty")
        .min(1, "Option should be at least 1 character"),
});

export { courseDetailsSchema, questionSchema };