import { targetActions } from "../../reducers/bd-reducers/targets.reducer";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { frequencies, months, quarters } from "../../../enums/Target";
import { targetApi } from "../../../constants/apis/BD/Target";

const axiosInstance = createInstance();

/**
 * getAllTargets - Fetch all targets.
 * 
 * Retrieves all targets based on the specified parameters.
 * 
 * @param {object} params - Parameters to filter targets.
 * @returns {function} - An asynchronous function that dispatches actions based on the API response.
 */
export const getAllTargets = (params) => async (dispatch) => {
  try {
    dispatch(targetActions.getTargetsRequest());

    const { data } = await axiosInstance.get(`${targetApi}?${params}`);

    if(data.success) {
        data?.data?.rows?.forEach((currentRow) => {
            currentRow.id = currentRow.target_details_id;
            if (currentRow.frequency === frequencies.Quarterly) {
                currentRow.time_period = quarters[currentRow.time_period];
            } else if (currentRow.frequency === frequencies.Yearly) {
                currentRow.time_period = `Apr ${currentRow.financial_year} - Mar ${
                currentRow.financial_year + 1
                }`;
            } else if (currentRow.frequency === frequencies.Monthly) {
                currentRow.time_period = months[currentRow.time_period];
            }
            currentRow.financial_year = `${currentRow.financial_year}-${currentRow.financial_year + 1}`;
        });
      dispatch(targetActions.getTargetsSuccess(data.data));
    }
      

  } catch (error) {
    errorHandler(error, "Failed to fetch targets");
    dispatch(targetActions.getTargetsFail(error.response.data.message));
  }
};