export const getDealsCardsBasedOnRows = (dealRows) => {
    let qualificationStageDeals = 0;
    let needsAnalysisStageDeals = 0;
    let identifyDecisionMakersStageDeals = 0;
    let proposalStageDeals = 0;
    let negotiationStageDeals = 0;
    let overDueStageDeals = 0;
    dealRows?.forEach((deal) => {
        if (deal.deal_stage === 'Qualification') {
            qualificationStageDeals++;
        } else if (deal.deal_stage === 'Needs Analysis') {
            needsAnalysisStageDeals++;
        } else if (deal.deal_stage === 'Identify Decision Makers') {
            identifyDecisionMakersStageDeals++;
        } else if (deal.deal_stage === 'Proposal') {
            proposalStageDeals++;
        } else if (deal.deal_stage === 'Negotiation') {
            negotiationStageDeals++;
        } else {
            overDueStageDeals++;
        }
    });
    return [
        {
            deal_stage: 'Qualification',
            count: qualificationStageDeals,

        },
        {
            deal_stage: 'Needs Analysis',
            count: needsAnalysisStageDeals,
        },
        {
            deal_stage: 'Identify Decision Makers',
            count: identifyDecisionMakersStageDeals,
        },
        {
            deal_stage: 'Proposal',
            count: proposalStageDeals,
        },
        {
            deal_stage: 'Negotiation',
            count: negotiationStageDeals,
        },
        {
            deal_stage: 'Over Due',
            count: overDueStageDeals,
        },
    ];
}