import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { setProfileData } from '../../features/userSlice';
import { userRoles } from '../../enums/Auth';

const Protected = ({ allowedRoles, path, children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const profileData = useSelector((state) => state.userProfile.profileData);
  const [userStatus, setUserStatus] = useState(undefined);
  const [user, setUser] = useState(undefined);

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        const { data } = await axios.get(
          `${process.env.REACT_APP_BACKEND_BASEURL}/auth/status`,
          {
            withCredentials: true,
          },
        );
        const { success, user: userData } = data;
        setUserStatus(success);
        setUser(userData);
        dispatch(setProfileData(userData));

        if (success) {
          handleUserRoles(userData);
        }
      } catch (error) {
        handleAuthenticationError();
      }
    };

    if (!profileData) {
      fetchUserStatus();
    } else {
      setUserStatus(true);
      setUser(profileData);
      handleUserRoles(profileData);
    }
  }, []);

  const handleUserRoles = (userData) => {
    if ([userRoles.Vendor, userRoles.ServiceProvider].includes(userData.role_id)) {
      return navigate('/ts');
    }

    if (allowedRoles && !allowedRoles.includes(userData.role_id)) {
      return navigate('/forbidden');
    }

    if ([userRoles.SuperAdmin, userRoles.Admin, userRoles.User].includes(userData.role_id)) {
      return;
    }

    navigate('/');
  };

  const handleAuthenticationError = () => {
    setUserStatus(false);
    dispatch(setProfileData(undefined));
    navigate('/login');
  };

  return userStatus && user && (
    <>
      {children}
    </>
  );
};

export default Protected;
