import React from "react";
import "./UserResponses.scss";
import { testResults } from "../../enums/EL/Course";

import {
  Box,
  Paper,
  Button,
  ImageList,
  ImageListItem,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Divider,
} from "@mui/material";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const flexDirectionBreakpoints = {
  xs: "column",
  sm: "row",
  md: "row",
  lg: "row",
  xl: "row",
};

const UserResponses = ({
  testResult,
  obtainedTotal,
  testTotal,
  responses,
  nextAction,
  nextActionText,
  nextActionFunction,
  isSmallerScreen,
}) => {
  return (
    <Paper
      mb={2}
      variant={"outlined"}
      display={isSmallerScreen ? "flex" : "inherit"}
      flexDirection={"column"}
      sx={{ borderRadius: 2, px: 2, py: 2 }}
    >
      <Box
        display={"flex"}
        alignItems={isSmallerScreen ? "start" : "center"}
        sx={{
          flexDirection: flexDirectionBreakpoints,
        }}
        justifyContent={"space-between"}
      >
        <Typography variant="h6" fontWeight={"bold"}>
          Test Submission
        </Typography>

        <Box
          display={"flex"}
          sx={{
            flexDirection: flexDirectionBreakpoints,
          }}
          alignItems={isSmallerScreen ? "start" : "center"}
        >
          <Typography
            variant="h6"
            className={
              testResult === testResults.pass ? "success-text" : "error-text"
            }
            fontWeight={"bold"}
            sx={{
              mx: {
                xs: 0,
                sm: 1,
              },
            }}
          >
            {testResult === testResults.pass
              ? testResults.pass
              : testResults.fail}
          </Typography>

          <Typography
            variant="h6"
            fontWeight={"bold"}
            mx={isSmallerScreen ? 0 : 1}
          >
            {obtainedTotal} / {testTotal}
          </Typography>
        </Box>
      </Box>
      <Divider className="horizontal-line" sx={{ my: 1 }} />
      <Box>
        {responses.map((singleResponse, index) => (
          <>
            <Box key={singleResponse.question_id} borderRadius={2}>
              {" "}
              <Grid container sx={{ px: 0 }}>
                <Grid item xs={12} md={9}>
                  <Typography variant="subtitle2" fontWeight={"bold"}>
                    Q.{index + 1}. {singleResponse?.question?.question_text}
                  </Typography>
                </Grid>
                <Grid item xs={12} md={3}>
                  <Box
                    display={"flex"}
                    alignItems={isSmallerScreen ? "start" : "center"}
                    justifyContent={isSmallerScreen ? "center" : "end"}
                    flexDirection={isSmallerScreen ? "column" : "row"}
                  >
                    <Typography
                      variant="body1"
                      fontWeight={"bold"}
                      mx={isSmallerScreen ? 0 : 1}
                    >
                      Marks Scored: {singleResponse?.marks_obtained}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
              <Box my={1}>
                {singleResponse?.question?.options.map(
                  (singleOption, optionIndex) => (
                    <Box
                      my={2}
                      className="user-responses-options-row"
                      key={singleOption.option_id}
                    >
                      <Box display={"flex"} alignItems={"end"}>
                        {singleOption.option_id ===
                        singleResponse.correct_option ? (
                          <CheckRoundedIcon
                            fontSize="medium"
                            className="success-text"
                            mr={2}
                          />
                        ) : singleOption.option_id ===
                          singleResponse.option_id ? (
                          <CloseRoundedIcon
                            fontSize="medium"
                            className="error-text"
                            mr={2}
                          />
                        ) : (
                          <></>
                        )}
                        <Typography
                          variant="subtitle2"
                          fontSize={"bold"}
                          className={` ${
                            singleOption.option_id ===
                            singleResponse.correct_option
                              ? "success-text"
                              : singleOption.option_id ===
                                singleResponse.option_id
                              ? "error-text"
                              : ""
                          }`}
                        >
                          {optionIndex + 1}. {singleOption.option_text}
                        </Typography>
                      </Box>
                    </Box>
                  )
                )}
              </Box>
            </Box>
            <Divider className="horizontal-line" sx={{ my: 1 }} />
          </>
        ))}
      </Box>

      {nextAction ? (
        <Box sx={{ flex: isSmallerScreen ? 0.4 : 1 }}>
          <Button
            my={3}
            variant="contained"
            className={`${
              isSmallerScreen ? `primary-small-btn` : `primary-btn`
            } `}
            onClick={() => {
              if (nextActionFunction) nextActionFunction();
            }}
          >
            {nextActionText}
          </Button>
        </Box>
      ) : (
        ""
      )}
    </Paper>
  );
};

export default UserResponses;
