const baseURL = process.env.REACT_APP_BACKEND_BASEURL;
const assetAPI = baseURL + "/asset-management/asset/";
const assetsPagePath = "/am/assets";
const assignedAssetsApi = baseURL + "/asset-management/asset/assigned_assets";
const assetTagApi = baseURL + "/asset-management/asset/asset_tag";
const inventoryAPI = baseURL + "/asset-management/inventory/";

export {
  assetAPI,
  assetsPagePath,
  assignedAssetsApi,
  inventoryAPI,
  assetTagApi,
};
