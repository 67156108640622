import React, { useState } from "react";
import { Typography, Menu, MenuItem, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EditNoteIcon from "@mui/icons-material/EditNote";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import DeleteConfirmation from "../../DeleteConfirmation/DeleteConfirmation";

const AdminCourseActions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = props.id;
  const deleteModalData = props.deleteModalData;
  const handleDeleteRow = props.handleDeleteRow;

  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);

  const handleCloseMenu = () => setAnchorEl(null);

  const msg = Boolean(deleteModalData)
    ? "Delete "
        .concat(deleteModalData[0])
        .concat(' "')
        .concat(deleteModalData[1])
        .concat('" ?')
    : "";

  const submitDelete = () => {
    handleDeleteRow(id);
    setDisplayConfirmationModal(false);
  };

  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };

  <DeleteConfirmation
    showModal={displayConfirmationModal}
    confirmModal={submitDelete}
    hideModal={() => setDisplayConfirmationModal(false)}
    message={msg}
  />;

  return (
    <>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={() => setDisplayConfirmationModal(false)}
        message={msg}
      />

      <IconButton color="primary" onClick={(e) => setAnchorEl(e.currentTarget)}>
        <MoreVertIcon />
      </IconButton>

      <Menu
        sx={{ mt: "45px" }}
        id="menu-appbar"
        anchorEl={anchorEl}
        open={open}
        onClose={handleCloseMenu}
      >
        <MenuItem
          sx={{
            pr: 4,
            py: 1,
            textAlign: "start",
          }}
          onClick={() => {
            handleCloseMenu();
            props.customActions.navigateToCourseAssginemt(id);
          }}
        >
          <AssignmentIcon sx={{ mr: 1 }} />
          <Typography textAlign="center">Assign Course</Typography>
        </MenuItem>

        <MenuItem
          sx={{
            pr: 4,
            py: 1,
            textAlign: "start",
          }}
          onClick={() => {
            handleCloseMenu();
            props.customActions.fillDataInCourseDetails(id);
          }}
        >
          <EditNoteIcon sx={{ mr: 1 }} />
          <Typography textAlign="center">Edit Details</Typography>
        </MenuItem>

        <MenuItem
          sx={{
            pr: 4,
            py: 1,
            textAlign: "start",
          }}
          onClick={() => {
            handleCloseMenu();
            props.customActions.fillDataInCourseQuestions(id);
          }}
        >
          <EditIcon sx={{ mr: 1 }} />
          <Typography textAlign="center">Edit Questions</Typography>
        </MenuItem>

        <MenuItem
          onClick={() => {
            handleCloseMenu();
            showDeleteModal();
          }}
        >
          <DeleteIcon sx={{ mr: 1 }} />
          <Typography textAlign="center">Delete</Typography>
        </MenuItem>
      </Menu>
    </>
  );
};

export default AdminCourseActions;
