import React from "react";
import { useNavigate } from "react-router-dom";
import { userRoles } from "../../enums/Auth";
import { Box, Paper, Typography, useTheme, useMediaQuery } from "@mui/material";
import EastIcon from "@mui/icons-material/East";
import { useSelector } from "react-redux";

//props contains details of app such as
//url: to which user will be navigated if clicked on it
//app icon, app url
const AppCard = (props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const profileData = useSelector((state) => state.userProfile.profileData);

  const handleModuleNavigate = () => {
    if (!props.appDetails.disabled) {
      const role = profileData?.role_id;
      const { appName, appUrl } = props.appDetails;
  
      if (role === userRoles.User) {
        if (appName === "Asset Management") {
          navigate(appUrl + "my-assets");
        } else if (appName === "Ticketing System") {
          navigate(appUrl + "tickets");
        } else {
          navigate(appUrl);
        }
      } else {
        navigate(appUrl);
      }
    }
  };

  return (
    <Paper
      variant={"outlined"}
      className={`primary-hover apply-pointer secondary-content-body-card inactive ${
        props.appDetails.disabled ? "inactive-card" : ""
      }`}
      sx={{
        p: 3,
      }}
      onClick={handleModuleNavigate}
    >
      {!isMobileScreen ? (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box>
              <img
                src={props.appDetails.imagePath}
                alt={props.appDetails.altText}
              />
            </Box>
            <Box px={3}>
              <TextComponent appDetails={props.appDetails} />
            </Box>
          </Box>
          <Box>
            <EastIcon className={"primary-text"} />
          </Box>
        </Box>
      ) : (
        <>
          <Box display={"flex"} justifyContent={"space-between"} mb={2}>
            <Box>
              <img
                src={props.appDetails.imagePath}
                alt={props.appDetails.altText}
              />
            </Box>
            <EastIcon className={"primary-text"} />
          </Box>
          <TextComponent appDetails={props.appDetails} />
        </>
      )}
    </Paper>
  );
};

function TextComponent({ appDetails }) {
  return (
    <>
      <Typography variant="h6" mb={1}>
        {appDetails.appName}
      </Typography>
      <Typography variant="body1">{appDetails.text}</Typography>
    </>
  );
}

export default AppCard;
