import React, { useEffect, useState } from "react";
import { elDashboardApi } from "../../../constants/apis/EL/ElDashboard";
import { autoCloseToastError } from "../../../constants/Common";
import LoadingSpinner from "../../LoadingSpinner/LoadingSpinner";
import { toast } from "react-toastify";
import {
  setModuleAdded,
  setModuleEdit,
  setModuleEdited,
  setModuleId,
} from "../../../features/EL/moduleFormSlice";
import { useDispatch, useSelector } from "react-redux";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { convertParamsToString } from "../../../utils/Request";
import { useLocation } from "react-router-dom";
import { elPagesPaths } from "../../../constants/elPagesPaths";
import {
  setModuleOptions,
  setModuleOptionsLoaded,
} from "../../../features/optionSlice";
import {
  Box,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  TextField,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import Asterisk from "../../Misc/Asterisk";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import moduleValidationSchema from "../../../validations/schemas/e-learning/module.schema.validator";
import { addModule, editModuleById } from "../../../redux/actions/el-actions/modules.action";

const ModuleForm = ({ isOpenProp, handleClose, moduleEditProp=false }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const currentPagePath = location.pathname;

  const moduleState = useSelector((state) => state.modules);

  const moduleEdit = useSelector((state) => state.moduleForm.moduleEdit);
  const moduleId = useSelector((state) => state.moduleForm.moduleId);

  const moduleAddOpenToggle = useSelector(
    (state) => state.moduleForm.moduleAddOpenToggle
  );
  const moduleEditOpenToggle = useSelector(
    (state) => state.moduleForm.moduleEditOpenToggle
  );

  const initialModuleData = {
    title: "",
    description: "",
  };

  const formik = useFormik({
    initialValues: {
      title: "",
      description: "",
    },
    validationSchema: moduleValidationSchema,
    onSubmit: ({ title, description }) => {
      if (edit) handleModuleEdit({ title, description });
      else handleModuleSubmit({ title, description });
    },
  });

  const [moduleData, setModuleData] = useState(initialModuleData);
  const [fetchingModuleData, setFetchingModuleData] = useState(false);
  const [edit, setEdit] = useState(moduleEditProp);
  const [courseInModuleAssigned, setCourseInModuleAssigned] = useState(false);

  const [isOpen, setIsOpen] = useState(isOpenProp);

  const handleModuleEdit = async ({ title, description }) => {
    const payload = { title, description };
    try {
      let payloadModuleData = JSON.parse(JSON.stringify(payload));
      dispatch(editModuleById(moduleState?.selectedModule?.id, payloadModuleData));
    } catch (err) {
      errorHandler(err, "Failed to edit module");
    }
    dispatch(setModuleId(null));
  };

  const handleModuleSubmit = async ({ title, description }) => {
    const payload = { title, description };
    try {
      let payloadModuleData = JSON.parse(JSON.stringify(payload));
      dispatch(addModule(payloadModuleData));
    } catch (err) {
      toast.error("Failed to add module", { autoClose: autoCloseToastError });
    }
  };

  useEffect(() => {
    if (!moduleId) {
      setEdit(false);
    }
  }, [moduleId]);

  useEffect(() => {
    //fetch single module data
    const fetchModuleData = async (moduleId) => {
      setFetchingModuleData(true);
      try {
        const fetchedModuleData = moduleState?.selectedModule;
        const editModuleData = {};
        for (let key in moduleData) {
          editModuleData[key] = fetchedModuleData[key] || "";
        }

        if (fetchedModuleData.courseInModuleAssigned) {
          setCourseInModuleAssigned((_) => true);
          toast.warn(
            "Module details cannot be edited as module is already to assigned to some account",
            { autoClose: false }
          );
        }

        formik.setValues({
          ...formik.values,
          ...editModuleData,
        });
      } catch (err) {
        errorHandler(err, "Failed to fetch module data for edit");
      }
      setFetchingModuleData(false);
    };

    setCourseInModuleAssigned((_) => false);
    if (moduleEditProp) {
      //fetch module data
      setEdit((_) => true);
      fetchModuleData(moduleId);
    } else {
      setModuleData({ ...initialModuleData });
    }
  }, []);

  const handleDrawerClose = (_) => {
    handleClose();
    setIsOpen(false);
  };

  return (
    <Drawer
      sx={{ zIndex: 2001 }}
      PaperProps={{ sx: { minWidth: "50vw", px: 4 } }}
      anchor={"right"}
      open={isOpen}
      onClose={handleDrawerClose}
    >
      <Box
        my={3}
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <Typography variant="h5">{edit ? "Edit " : "New "}Module</Typography>
        <IconButton
          aria-label="close"
          onClick={handleDrawerClose}
          color="primary"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Divider className="horizontal-line" sx={{ my: 1 }} />

      {fetchingModuleData ? (
        <LoadingSpinner />
      ) : (
        <Box my={3}>
          <Grid container>
            <Grid item xs={12} md={2} pr={2}>
              <InputLabel className="fw-bold" htmlFor="module_name">
                Module Name
                <Asterisk />
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="module-title"
                name="title"
                size="small"
                placeholder="Module Name"
                value={formik.values.title}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.title && Boolean(formik.errors.title)}
                helperText={formik.touched.title && formik.errors.title}
                sx={{ mb: 2, width: "100%" }}
              />
            </Grid>
            <Grid item md={2}></Grid>

            <Grid item xs={12} md={2} pr={2}>
              <InputLabel className="fw-bold" htmlFor="description">
                Description
              </InputLabel>
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                id="module-description"
                name="description"
                placeholder="Description"
                value={formik.values.description}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.description &&
                  Boolean(formik.errors.description)
                }
                helperText={
                  formik.touched.description && formik.errors.description
                }
                sx={{ mb: 2, width: "100%" }}
                multiline
                rows={3}
                size="small"
              />
            </Grid>
            <Grid item sm={12} md={2} pr={2}></Grid>
            <Grid item sm={12} md={2} pr={2}></Grid>
            <Grid item xs={12} md={9}>
              <Typography variant="subtitle2">
                <Asterisk />
                Mandatory field
              </Typography>
            </Grid>
          </Grid>
        </Box>
      )}

      <Box
        display={"flex"}
        flexDirection={"column"}
        justifyContent={"end"}
        bottom={0}
        height={"100%"}
      >
        <Divider className="horizontal-line" sx={{ my: 1 }} />

        <Box my={3} display={"flex"} columnGap={{ xs: 5 }}>
          <Button
            variant="contained"
            type="submit"
            className={`primary-btn ${
              courseInModuleAssigned ? "disabled-btn" : ""
            }`}
            onClick={formik.handleSubmit}
            disabled={courseInModuleAssigned || moduleState?.addModuleLoading || moduleState?.editModuleLoading }
            endIcon={(moduleState?.addModuleLoading || moduleState?.editModuleLoading) && <CircularProgress size={25}/>}
          >
            Save
          </Button>
          <Button
            variant="contained"
            type="button"
            className={`primary-btn`}
            onClick={handleDrawerClose}
          >
            Cancel
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ModuleForm;
