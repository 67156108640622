import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { loginApi } from "../../constants/apis/Login";
import { loginContent as content } from "../../constants/helperText/Login";
import { useDispatch, useSelector } from "react-redux";
import { setFirstTimeLoggedIn, setProfileData } from "../../features/userSlice";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import loginValidationSchema from "../../validations/schemas/login.schema.validator";
import { createInstance } from "../../utils/Request/ReqUtils";

export default function Login({ setIsSidenavOpen }) {
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMobileScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.userProfile.profileData);

  const [isSignedIn, setIsSignedIn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const formik = useFormik({
    initialValues: {
      mobile_number: "",
      password: "",
    },
    validationSchema: loginValidationSchema,
    onSubmit: async ({ mobile_number, password }) => {
      try {
        const axiosInstance = createInstance(true);
        const loginResponse = await axiosInstance.post(loginApi, {
          mobile: mobile_number,
          password: password,
        });

        if (loginResponse.status === 200) {
          const jwtToken = loginResponse.data.data.token;
          const userId = loginResponse.data.data.user_id;
          const firstName = loginResponse.data.data.first_name;
          const lastName = loginResponse.data.data.last_name;
          const role = loginResponse.data.data.role_id;
          const accountId = loginResponse.data.data.account_id;
          const accountName = loginResponse.data.data.account_name;
          const firstTimeLoggedIn =
            loginResponse.data.data.first_time_logged_in;
          localStorage.setItem("jwt_token", jwtToken);
          localStorage.setItem("user_id", userId);
          localStorage.setItem("first_name", firstName);
          localStorage.setItem("last_name", lastName);
          localStorage.setItem("role", role);
          localStorage.setItem("account_id", accountId);
          localStorage.setItem("account_name", accountName);

          dispatch(setProfileData(loginResponse.data.data));

          if (firstTimeLoggedIn) {
            dispatch(setFirstTimeLoggedIn(true));
          }

          navigate("/allapps");
          toast.success("Login Successful", { autoClose: 500 });
        } else {
          toast.error("Login Failed");
        }
      } catch (error) {
        toast.error("Invalid Credentials");
      }
    },
  });

  useEffect(() => {
    setIsSidenavOpen(false);
    if (!userData) {
      setIsSignedIn(false);
    } else {
      setIsSignedIn(true);
    }
  }, []);

  useEffect(() => {
    if (isSignedIn) {
      navigate("/allapps");
    }
  }, [isSignedIn]);

  return (
    !isSignedIn && (
      <Grid container p={0} sx={{ overflowY: "hidden" }} height={"100vh"}>
        <Grid
          display={isSmallerScreen && "none"}
          item
          xs={12}
          md={7}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Box overflow={"hidden"} height={"100%"}>
            <img
              src="/assets/images/login-screen-bg.png"
              alt={content.groupImageAlt}
              style={{
                objectFit: "cover", // Ensure the image covers the entire container
                height: "100%",
                width: "100%",
              }}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={5}>
          <Container
            maxWidth={"xs"}
            sx={{
              mt: { xs: 5, sm: 10, md: 15, lg: 20 },
            }}
          >
            <Box
              display={"flex"}
              justifyContent={isSmallerScreen ? "center" : "flex-start"}
            >
              {isSmallerScreen ? (
                <img
                  className="enx-logo"
                  src="/assets/images/VG_Logo_With_Data.png"
                  alt={content.logoImageAlt}
                  height={"100px"}
                />
              ) : (
                <img
                  className="enx-logo"
                  src="/assets/images/VG_Logo.png"
                  alt={content.logoImageAlt}
                  height={"50px"}
                />
              )}
            </Box>
            <Box my={4}>
              <Typography
                variant="h4"
                component="h4"
                mb={1}
                textAlign={{ xs: "center", md: "start" }}
              >
                Login
              </Typography>
              <Typography
                variant="body1"
                mb={2}
                textAlign={{ xs: "center", md: "start" }}
              >
                Use your existing account login details.
              </Typography>
            </Box>

            <Box>
              <InputLabel htmlFor="mobile_number">Mobile Number</InputLabel>
              <TextField
                fullWidth
                id="mobile_number"
                name="mobile_number"
                value={formik.values.mobile_number}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.mobile_number &&
                  Boolean(formik.errors.mobile_number)
                }
                helperText={
                  formik.touched.mobile_number && formik.errors.mobile_number
                }
                sx={{ mb: 2 }}
              />
              <InputLabel htmlFor="password">Password</InputLabel>
              <TextField
                fullWidth
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={handleTogglePasswordVisibility}
                        edge="end"
                      >
                        {showPassword ? (
                          <VisibilityIcon className="primary-text" />
                        ) : (
                          <VisibilityOffIcon className="primary-text" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{ mb: 2 }}
              />
              <Link
                className={"primary-text"}
                sx={{
                  textDecoration: "none",
                  textAlign: "end",
                  display: "block",
                }}
              >

                {/* TODO: Add forgot password modal */}
                <Typography
                  className="link-hover"
                  variant={"body1"}
                  component={"span"}
                >
                  {" "}
                  Forgot Password
                </Typography>
              </Link>
              <Button
                variant="contained"
                type="submit"
                className={`primary-btn  ${!formik.dirty && "disabled-btn"}`}
                onClick={formik.handleSubmit}
                fullWidth={isMobileScreen}
                sx={{ my: 2 }}
              >
                Login
              </Button>
            </Box>
          </Container>
        </Grid>
      </Grid>
    )
  );
}
