const navAppNames = {
    global: "DIGITAL",
    businessDevelopment: "BUSINESS",
    eLearning: "E LEARNING",
    assetManagement: "ASSET MANAGEMENT",
    tSystem: "TICKETS"
};

const appNames = {
    global: "DIGITAL",
    businessDevelopment: "Business Development",
    eLearning: "E Learning",
    assetManagement: "Asset Management",
    tSystem: "Ticketing System"
};

module.exports = { navAppNames, appNames };