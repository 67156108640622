import React, { Fragment } from 'react'
import { Box, Typography } from '@mui/material';

function TextViewer({ page }) {
    return (
        <Box
            sx={{
                overflowX: 'auto',
                whiteSpace: 'normal',
                minHeight: { xs: '350px', md: '75vh' },
                width: '100%',
                px: '5%',
                py: '5%',
                display: 'flex', // Wrap lines if content overflows width
                flexDirection: 'column',

            }}
            className="paper-bg"
        >
            {page?.split('\n').map((line, i) => {
                return <Fragment key={`page_line_${i}`}>
                    <Typography
                        component="pre"
                        variant="body1"
                        sx={{
                            color: 'black', fontFamily: 'monospace',
                            whiteSpace: 'break-spaces',
                        }}
                    >
                        {line.trim() ? line : <br />}
                    </Typography>
                </Fragment>
            }
            )}
        </Box>
    )
}

export default TextViewer;