const messages = {
    accountId: "You must select an account",
    assignedTo: "Please select proper user to assign task",

    titleDefault: "Task title cannot be empty",
    titleMax: "Maximum 255 characters are allowed",
    titleMin: "Minimum 3 characters are required",

    typeDefault: "Please select proper type",

    priorityDefault: "Please select proper priority",

    statusDefault: "Please select proper status",

    descriptionDefault: "Enter description",
};

export { messages };
