const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
    consumableAssignmentId: null,
    consumableAssignmentAddOpenToggle: false, 
    consumableAssignmentAdded: false,
}

export const formSlice = createSlice({
    name: "consumableAssignment",
    initialState,
    reducers:{
        setConsumableAssignmentId : (state,action) => {
            state.consumableAssignmentId= action.payload;
        },
        setConsumableAssignmentAddOpenToggle:(state,action)=>{
            state.consumableAssignmentAddOpenToggle = !state.consumableAssignmentAddOpenToggle;
        },
        setConsumableAssignmentAdded:(state,action)=>{
            state.consumableAssignmentAdded= action.payload;
        }
    }
});

export const {setConsumableAssignmentId, setConsumableAssignmentAddOpenToggle, setConsumableAssignmentAdded} = formSlice.actions;
export default formSlice.reducer;