import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createInstance, errorHandler } from "../../../utils/Request/ReqUtils";
import { assignmentsAPI } from "../../../constants/apis/AM/AssignAssets";
import { toast } from "react-toastify";
import { autoCloseToastError } from "../../../constants/Common";
import AsyncSelect from "react-select/async";
import {
  searchOptionDebounceTime,
  minCharsToLoadOptions,
} from "../../../constants/Common";
import debounce from "lodash/debounce";
import { assetAPI } from "../../../constants/apis/AM/Assets";
import { userOptionsApi } from "../../../constants/apis/User";
import moment from "moment";
import { assignmentSchema } from "../../../validations/AmForms/Assignment";
import {
  setAssetNameOptions,
  setAssetNameOptionsLoaded,
  setUserOptions,
  setUserOptionsLoaded,
} from "../../../features/optionSlice";
import { userOption } from "../../AsyncSelectOption/CustomizedOptions";
import { createSearchParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Drawer,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import Asterisk from "../../Misc/Asterisk";
import { assignmentActions } from "../../../redux/reducers/am-reducers/assignments.reducer";


const AssignmentForm = ({ isAssignmentFormOpen, handleDrawerClose, isSmallerScreen }) => {

  const profileData = useSelector((state) => state.userProfile.profileData);

  const initialAssignmentData = {
    asset_name: "",
    make: "",
    model: "",
    user_id: null,
    assigned_on: "",
    date_of_purchase: "",
    asset_tag: "",
  };


  const dispatch = useDispatch();

  const [assignmentFormErrors, setAssignmentFormErrors] = useState({});

  const [assetNameSelected, setAssetNameSelected] = useState(null);
  const [makes, setMakes] = useState([]);
  const [models, setModels] = useState([]);
  const [dop, setDop] = useState([]);
  const [assignmentData, setAssignmentData] = useState(initialAssignmentData);
  const [userName, setUserName] = useState(null);
  const [description, setDescription] = useState(null);
  const [assetId, setAssetId] = useState(null);
  const [inStock, setInStock] = useState(null);
  const [assignmentInProgress, setAssignmentInProgress] = useState(false);

  const [location, setLocation] = useState(null);

  const assetNameOptions = useSelector(
    (state) => state.optionsForAsyncSelect.assetNameOptions
  );
  const assetNameOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.assetNameOptionsLoaded
  );

  const userOptions = useSelector(
    (state) => state.optionsForAsyncSelect.userOptions
  );
  const userOptionsLoaded = useSelector(
    (state) => state.optionsForAsyncSelect.userOptionsLoaded
  );

  const handleAssignmentForm = (e) => {
    setAssignmentFormErrors({ ...assignmentFormErrors, [e.target.name]: "" });
    setAssignmentData({ ...assignmentData, [e.target.name]: e.target.value });
    if (e.target.name === "model" && e.target.value === "") {
      setInStock(null);
    }
  };

  const closeAssignmentForm = () => {
    setAssignmentData(initialAssignmentData);
    handleDrawerClose();
  };

  const handleAssignmentSubmit = async (e) => {
    e.preventDefault();
    setAssignmentInProgress(true);
    const userId = profileData?.user_id;
    const validation = assignmentSchema.validate(assignmentData, {
      abortEarly: false,
    });
    if (validation.error) {
      setAssignmentFormErrors({});
      const errorDetails = validation.error.details;
      let currentErrors = {};
      errorDetails.forEach((error) => {
        currentErrors[error.path[0]] = error.message;
      });
      setAssignmentFormErrors(currentErrors);
      setAssignmentInProgress(false);
    } else {
      try {
        const serverData = {
          asset_id: assetId,
          user_id: assignmentData.user_id,
          assigned_on: assignmentData.assigned_on,
          assigned_till: assignmentData.assigned_till,
          asset_tag_alphanumeric: assignmentData.asset_tag,
          created_by: null,
          location: location,
        };

        const payload = { ...serverData, created_by: userId };
        let payloadAssignmentData = JSON.parse(JSON.stringify(payload));
        const axiosInstance = createInstance(true);
        let assignmentAddResponse = await axiosInstance.post(
          assignmentsAPI,
          payloadAssignmentData
        );
        if (
          assignmentAddResponse.data.success &&
          assignmentAddResponse.data.data.length !== 0
        ) {
          toast.success("Asset Assigned  Successfully");
          dispatch(assignmentActions.addAssignment(assignmentAddResponse.data.data))
          setAssignmentFormErrors({});
        } else {
          toast.error("Failed to assign asset", {
            autoClose: autoCloseToastError,
          });
        }
      } catch (err) {
        errorHandler(err, "Failed to assign asset");
      } finally {
        setAssignmentInProgress(false);
      }
    }
  };

  const handleAssetNameFocus = async () => {
    if (!assetNameOptionsLoaded) {
      const fetchedOptions = await fetchAssetNames("");
      dispatch(setAssetNameOptionsLoaded(true));
      dispatch(setAssetNameOptions(fetchedOptions));
    }
  };

  const handleUserFocus = async () => {
    if (!userOptionsLoaded) {
      const fetchedOptions = await fetchUserOptions("");
      dispatch(setUserOptionsLoaded(true));
      dispatch(setUserOptions(fetchedOptions));
    }
  };

  const fetchAssetNames = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      const response = await axiosInstance.get(
        assetAPI + "/getasset-names?search=" + inputValue
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => ({
          value: item.asset_name,
          label: item.asset_name,
        }));
        return formattedOptions;
      } else {
        toast.error("Error while fetching asset name options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching asset name options");
    }
    return [];
  };

  const loadAssetNames = (inputValue, callback) => {
    fetchAssetNames(inputValue)
      .then((options) => callback(options))
      .catch((err) => {
        errorHandler(err, "error while fetching Asset name options");
        callback([]);
      });
  };

  const fetchUserOptions = async (inputValue) => {
    try {
      const axiosInstance = createInstance(true);
      const response = await axiosInstance.get(
        userOptionsApi + "?search=" + inputValue
      );
      if (response.data && response.data.success) {
        const formattedOptions = response.data.data.map((item) => {
          let additionalData = { location: item.location, mobile: item.mobile };

          return {
            value: item.user_id,
            label: item.assign_to,
            additionalData: additionalData, // Include additional data in the result
          };
        });

        return formattedOptions;
      } else {
        toast.error("Error while fetching assigned to options", {
          autoClose: autoCloseToastError,
        });
      }
    } catch (err) {
      errorHandler(err, "Error while fetching assigned to options");
    }
    return [];
  };

  const loadUserOptions = (inputValue, callback) => {
    if (inputValue.length < minCharsToLoadOptions) {
      return callback([]);
    }
    fetchUserOptions(inputValue)
      .then((options) => callback(options))
      .catch((err) => {
        errorHandler(err, "error while fetching assigned to  options");
        callback([]);
      });
  };

  useEffect(() => {
    const fetchDateOfPurchase = async () => {
      if (assetNameSelected) {
        try {
          const axiosInstance = createInstance(true);
          const response = await axiosInstance.get(
            assetAPI + "/fetch-date-of-purchases/" + assetNameSelected.value
          );
          const responseArr = response.data.data;
          const formattedArr = responseArr.map((currentDate) => {
            let formattedDate = {
              date_of_purchase: "",
            };
            formattedDate.date_of_purchase = currentDate.date_of_purchase
              ? moment(currentDate.date_of_purchase).format("DD/MM/YYYY")
              : "";
            return formattedDate;
          });
          setDop(formattedArr);
        } catch (err) {
          errorHandler(err, "error while fetching date of purchases");
        }
      }
    };

    fetchDateOfPurchase();
  }, [assetNameSelected]);

  useEffect(() => {
    const fetchMake = async () => {
      if (assignmentData.date_of_purchase && assetNameSelected) {
        try {
          const axiosInstance = createInstance(true);
          const params = createSearchParams({
            assetName: assetNameSelected.value,
            dateOfPurchase: assignmentData.date_of_purchase,
          });
          const response = await axiosInstance.get(
            assetAPI + `/fetch-makes?${params}`
          );
          setMakes(response.data.data);
        } catch (err) {
          errorHandler(err, "error while fetching makes");
        }
      }
    };

    fetchMake();
  }, [assignmentData.date_of_purchase, assetNameSelected]);

  useEffect(() => {
    const fetchModel = async () => {
      if (
        assignmentData.date_of_purchase &&
        assignmentData.make &&
        assetNameSelected
      ) {
        try {
          const axiosInstance = createInstance(true);
          const params = createSearchParams({
            assetName: assetNameSelected.value,
            dateOfPurchase: assignmentData.date_of_purchase,
            make: assignmentData.make,
          });
          const response = await axiosInstance.get(
            assetAPI + `/fetch-models?${params}`
          );
          const responseArr = response.data.data;
          setModels(responseArr);
        } catch (err) {
          errorHandler(err, "error while fetching model options");
        }
      }
    };

    fetchModel();
  }, [assignmentData.make]);

  useEffect(() => {
    const fetchAssetByAllFields = async () => {
      if (
        assetNameSelected &&
        assignmentData.date_of_purchase &&
        assignmentData.make &&
        assignmentData.model
      ) {
        try {
          const axiosInstance = createInstance(true);
          const response = await axiosInstance.get(
            assetAPI + "/get-asset-by-fields",
            {
              params: {
                assetName: assetNameSelected.value,
                dateOfPurchase: assignmentData.date_of_purchase,
                make: assignmentData.make,
                model: assignmentData.model,
              },
            }
          );
          if (response.data && response.data.success) {
            const data = response.data.data;
            setAssetId(data[0].asset_id);
            setDescription(data[0].description);
            setInStock(data[0].available_quantity);
          } else {
            if (
              assetNameSelected &&
              assignmentData.date_of_purchase &&
              assignmentData.make &&
              assignmentData.model
            ) {
              toast.error("No Asset is available for selected fields", {
                autoClose: autoCloseToastError,
              });
            }
            setDescription("");
            setLocation("");
          }
        } catch (err) {
          setDescription("");
          setLocation("");
          if (
            assetNameSelected &&
            assignmentData.date_of_purchase &&
            assignmentData.make &&
            assignmentData.model
          ) {
            errorHandler(err, "No Asset is available for selected fields");
          }
        }
      }
    };
    fetchAssetByAllFields();
  }, [assignmentData.model]);

  useEffect(() => {
    setAssignmentFormErrors({});
    setAssignmentData(initialAssignmentData);
    setAssetNameSelected(null);
    setDescription("");
    setUserName("");
    setLocation("");
    setInStock(null);
  }, [isAssignmentFormOpen]);

  useEffect(() => {
    setMakes([]);
    setModels([]);
    setDop([]);
    setAssignmentData((prev) => ({
      ...prev,
      model: "",
      make: "",
      date_of_purchase: "",
    }));
  }, [assetNameSelected]);

  useEffect(() => {
    setMakes([]);
    setModels([]);
    setInStock(null);
    setAssignmentData((prev) => ({ ...prev, model: "", make: "" }));
  }, [assignmentData.date_of_purchase]);

  useEffect(() => {
    setModels([]);
    setInStock(null);
    setAssignmentData((prev) => ({ ...prev, model: "" }));
  }, [assignmentData.make]);

  return (
    <>
      <Drawer
        PaperProps={{
          sx: {
            width: { sm: "80vw", md: "70vw", lg: "60vw" },
            px: { xs: 2, md: 4 },
          },
        }}
        anchor={"right"}
        open={isAssignmentFormOpen}
        onClose={closeAssignmentForm}
      >
        <Box my={2} display={"flex"} justifyContent={"space-between"}>
          <Typography variant="h5">Assign Asset</Typography>
          <IconButton
            aria-label="close"
            onClick={closeAssignmentForm}
            color="primary"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider className="horizontal-line" />

        <Box my={3}>
          <Grid
            container
            rowSpacing={{ xs: 1, md: 4 }}
            columnSpacing={{ xs: 0, md: 10, lg: 15 }}
          >
            {/* Asset Name */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Asset Name "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <AsyncSelect
                  className={`course-input-select ${Boolean(assignmentFormErrors.asset_name)
                    ? "error-border"
                    : ""
                    }`}
                  value={assetNameSelected}
                  defaultOptions={assetNameOptions}
                  onChange={(selectedOption) => {
                    if (selectedOption) {
                      setAssignmentFormErrors({
                        ...assignmentFormErrors,
                        asset_name: "",
                      });
                      setAssignmentData({
                        ...assignmentData,
                        asset_name: selectedOption.value,
                      });
                    } else {
                      setAssignmentData({
                        ...assignmentData,
                        asset_name: "",
                      });
                    }
                    setAssetNameSelected(selectedOption);
                    setDescription("");
                  }}
                  loadOptions={debounce(
                    loadAssetNames,
                    searchOptionDebounceTime
                  )}
                  placeholder="Type to search..."
                  name="asset_name"
                  noOptionsMessage={() => "No Asset Names Found. Type again..."}
                  isClearable
                  onFocus={handleAssetNameFocus}
                />
                {Boolean(assignmentFormErrors.asset_name) && (
                  <Typography variant="caption" color="error" p={2}>
                    {assignmentFormErrors.asset_name}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* Date of Purchase */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Date of Purchase "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select 
                  displayEmpty
                  id="assignment-category"
                  name="date_of_purchase"
                  value={assignmentData.date_of_purchase}
                  onChange={(e) => handleAssignmentForm(e)}
                  error={Boolean(assignmentFormErrors.date_of_purchase)}
                  size="small"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {dop.map((obj, index) => (
                    <MenuItem key={`dop_${index}`} value={obj.date_of_purchase}>
                      {obj.date_of_purchase}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(assignmentFormErrors.date_of_purchase) && (
                  <Typography variant="caption" color="error" p={2}>
                    {assignmentFormErrors.date_of_purchase}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* make */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Make "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select
                  displayEmpty
                  id="assignment-category"
                  name="make"
                  value={assignmentData.make}
                  onChange={(e) => handleAssignmentForm(e)}
                  error={Boolean(assignmentFormErrors.make)}
                  size="small"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {makes.map((obj, index) => (
                    <MenuItem key={`make_${index}`} value={obj.make}>
                      {obj.make}
                    </MenuItem>
                  ))}
                </Select>
                {Boolean(assignmentFormErrors.make) && (
                  <Typography variant="caption" color="error" p={2}>
                    {assignmentFormErrors.make}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* model */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Model "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <Select 
                  displayEmpty
                  id="assignment-category"
                  name="model"
                  value={assignmentData.model}
                  onChange={(e) => handleAssignmentForm(e)}
                  error={Boolean(assignmentFormErrors.model)}
                  size="small"
                  sx={{ width: "100%" }}
                >
                  <MenuItem value="">Select</MenuItem>
                  {models.map((obj, index) => (
                    <MenuItem key={`model_${index}`} value={obj.model}>
                      {obj.model}
                    </MenuItem>
                  ))}
                </Select>

                {Boolean(assignmentFormErrors.model) && (
                  <Typography variant="caption" color="error" p={2}>
                    {assignmentFormErrors.model}
                  </Typography>
                )}
              </Grid>
            </Grid>

            {/* Asset Tag */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Asset Tag "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  type="text"
                  id="asset-tag"
                  name="asset_tag"
                  value={assignmentData.asset_tag}
                  onChange={(e) => handleAssignmentForm(e)}
                  error={Boolean(assignmentFormErrors.asset_tag)}
                  helperText={assignmentFormErrors.asset_tag}
                  placeholder="Asset Tag"
                  sx={{ width: "100%" }}
                  size="small"
                />
              </Grid>
            </Grid>

            {/* Assign On */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Assign On "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <TextField
                  type="date"
                  id="assignment-assigned-on"
                  name="assigned_on"
                  value={assignmentData.assigned_on}
                  error={Boolean(assignmentFormErrors.assigned_on)}
                  helperText={assignmentFormErrors.assigned_on}
                  onChange={(e) => handleAssignmentForm(e)}
                  placeholder="Assigned on"
                  sx={{ width: "100%" }}
                  size="small"
                />
              </Grid>
            </Grid>

            {/* Assign To */}
            <Grid item container xs={12} md={6}>
              <Grid item xs={12} xl={5}>
                <InputLabel className={"fw-bold"}>
                  {"Assign To "}
                  <Asterisk />
                </InputLabel>
              </Grid>
              <Grid item xs={12} xl={7}>
                <AsyncSelect
                  className={`course-input-select ${Boolean(assignmentFormErrors.user_id) ? "error-border" : ""
                    }`}
                  onChange={(selectedOption) => {
                    setUserName(selectedOption);
                    if (selectedOption) {
                      setLocation(selectedOption.additionalData.location);
                      setAssignmentFormErrors({
                        ...assignmentFormErrors,
                        ["user_id"]: "",
                      });
                      setAssignmentData({
                        ...assignmentData,
                        user_id: selectedOption.value,
                      });
                    } else {
                      setAssignmentData({ ...assignmentData, user_id: null });
                      setLocation("");
                    }
                  }}
                  value={userName}
                  defaultOptions={userOptions}
                  loadOptions={debounce(
                    loadUserOptions,
                    searchOptionDebounceTime
                  )}
                  placeholder="Type to search..."
                  name="user_id"
                  noOptionsMessage={() => "No Users found. Type again..."}
                  isClearable
                  onFocus={handleUserFocus}
                  components={{ Option: userOption }}
                />
                {Boolean(assignmentFormErrors.user_id) && (
                  <Typography variant="caption" color="error" p={2}>
                    {assignmentFormErrors.user_id}
                  </Typography>
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            mt={3}
            alignItems={"center"}
            className={"stat-card-bg"}
            px={2}
            py={1}
          >
            <Grid container my={2}>
              <Grid item xs={12} md={2}>
                <Typography fontWeight={"bold"} color={"textSecondary"}>
                  In Stock
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>{inStock} </Typography>
              </Grid>
            </Grid>

            {description && (
              <Grid container my={1}>
                <Grid item xs={12} md={2}>
                  <Typography fontWeight={"bold"} color={"textSecondary"}>
                    Description
                  </Typography>
                </Grid>
                <Grid item xs={12} md={10}>
                  <Typography>{description}</Typography>
                </Grid>
              </Grid>
            )}

            <Grid container my={1}>
              <Grid item xs={12} md={2}>
                <Typography fontWeight={"bold"} color={"textSecondary"}>
                  Location
                </Typography>
              </Grid>
              <Grid item xs={12} md={10}>
                <Typography>{location}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Typography variant="body2" my={2}>
          <Asterisk />
          {" Mandatory Fields"}
        </Typography>

        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"end"}
          bottom={0}
        >
          <Divider className="horizontal-line" sx={{ my: 1 }} />

          <Box my={3} display={"flex"} columnGap={5}>

            <Button
              variant="contained"
              type="submit"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={(e) => {
                handleAssignmentSubmit(e);
              }}

              disabled={assignmentInProgress}
              endIcon={
                (assignmentInProgress) && (
                  <CircularProgress size={25} />
                )
              }
            >
              Save
            </Button>

            <Button
              variant="contained"
              type="button"
              className={`primary-btn  ${isSmallerScreen ? "primary-small-btn" : ""
                }`}
              onClick={closeAssignmentForm}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};
export default AssignmentForm;
