import React from "react";
import "./RequestInProgressModal.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const RequestInProgressModal = ({ show, headerText, contentText }) => {
  return (
    <React.Fragment>
      <Dialog open={show} >
        <DialogTitle
          sx={{ m: 0, p: 2, fontWeight: "bold" }}
          id="customized-dialog-title"
        >
          {headerText}
        </DialogTitle>

        <DialogContent dividers>
          <Typography variant="body2" color={"textSecondary"} gutterBottom>
            {contentText}
          </Typography>

          <LoadingSpinner />
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
};

export default RequestInProgressModal;
