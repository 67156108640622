import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import assetSideBarMenus from "../../../data/json/assetSideBarMenus.json";
import { DebounceInput } from "react-debounce-input";
import LoadingSpinner from "../../../components/LoadingSpinner/LoadingSpinner";
import assignmentColumns from "../../../data/json/assignmentColumns.json";
import AssignmentDetails from "../../../components/EntityDetails/AssignmentDetails/AssignmentDetails";
import ConsumableAssignmentDetails from "../../../components/EntityDetails/ConsumableAssignmentDetails/ConsumableAssignmentDetails";
import ConsumableAssignmentForm from "../../../components/AmForms/AssignConsumableForm/AssignConsumableForm";
import AssignmentForm from "../../../components/AmForms/AssignmentForm/AssignmentForm";
import { userRoles } from "../../../enums/Auth";
import assetColumnsForUser from "../../../data/json/assetColumnsForUser.json";
import {
  Box,
  Button,
  Container,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Paper,
  Typography,
  useTheme,
  useMediaQuery,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  TablePagination,
  TableSortLabel,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getAssignments } from "../../../redux/actions/am-actions/assignments.action";
import { setAssignmentDetailsOpen } from "../../../features/AM/assignmentDetailsSlice";
import { assignmentActions } from "../../../redux/reducers/am-reducers/assignments.reducer";
import getTableCell from "../../../utils/Common/DataTable";
import TableMenu from "../../../components/TableMenu/TableMenu";

const Assignments = () => {
  const appSidebar = JSON.parse(JSON.stringify(assetSideBarMenus));
  appSidebar.menuList[3].currentPage = true;

  const profileData = useSelector((state) => state.userProfile.profileData);

  const role = parseInt(profileData?.role_id);
  if (role === userRoles.User) {
    appSidebar.menuList[5].currentPage = true;
  }
  const theme = useTheme();
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch();
  const [assignButtonAnchorEl, setAssignButtonAnchorEl] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [searched, setSearched] = useState("");
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState();
  const [isAssetAssignmentDetailsOpen, setIsAssetAssignmentDetailsOpen] = useState(false)
  const [isConsumableAssignmentsDetailsOpen, setIsConsumableAssignmentsDetailsOpen] = useState(false)
  const [isConsumableAssignmentsFormOpen, setIsConsumableAssignmentsFormOpen] = useState(false)
  const [isAssetAssignmentsFormOpen, setIsAssetAssignmentsFormOpen] = useState(false)



  const assignmentsState = useSelector(state => state.assignments);

  const rows = assignmentsState?.assignments?.rows;
  const totalCount = assignmentsState?.assignments?.meta?.total;
  const rowsLoading = assignmentsState?.assignments?.rowsLoading;


  const getRequestParams = () => {
    const requestParams = {};

    if (searched !== "") {
      requestParams["search"] = searched;
    }

    if (orderBy && order) {
      requestParams["sort_by"] = orderBy;
      requestParams["sort_order"] = order;
    }
    requestParams["page"] = currentPage + 1;
    requestParams["rows_per_page"] = rowsPerPage;

    return requestParams;
  }

  const requestData = async () => {
    dispatch(getAssignments(getRequestParams()));
  };

  const handleSortRequest = (cellId) => {
    const isAscending = orderBy === cellId && order === "asc";
    setOrder(isAscending ? "desc" : "asc");
    setOrderBy(cellId);
    setCurrentPage(0);
  };

  const handlePage = (e, newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setCurrentPage(0);
  };

  const setAssignmentForm = (value) => {
    setIsAssetAssignmentsFormOpen(value);
  };

  const setConsumableAssignmentForm = (value) => {
    setIsConsumableAssignmentsFormOpen(value);
  };

  const handleSearch = (e) => {
    setSearched(e.target.value);
    setCurrentPage(0);
  };

  const openDetailsForm = (id, assignmentType) => {
    const selectedAssignmentData = rows?.filter(
      (assignment) => assignment.assign_asset_id === id
    )?.[0];
    dispatch(assignmentActions.setSelectedAssignmentDetails(selectedAssignmentData));
    if (assignmentType === "Consumable") {
      setIsConsumableAssignmentsDetailsOpen(true);
    } else {
      setIsAssetAssignmentDetailsOpen(true);
    }
  };

  const closeAssignmentDetails = () => {
    setIsAssetAssignmentDetailsOpen(false);
    dispatch(assignmentActions.setSelectedAssignmentDetails(null));
  }

  const closeConsumableAssignmentsDetails = () => {
    setIsConsumableAssignmentsDetailsOpen(false);
    dispatch(assignmentActions.setSelectedAssignmentDetails(null));
  }

  const handleAssignButtonClick = (event) => {
    setAssignButtonAnchorEl(event.currentTarget);
  };

  const handleAssignButtonClose = () => {
    setAssignButtonAnchorEl(null);
  };

  useEffect(() => {
    requestData();
  }, [currentPage, rowsPerPage, searched, order, orderBy]);


  return (
    <>
      <Container maxWidth={"xxl"} sx={{ pb: 2 }}>
        {isAssetAssignmentDetailsOpen && <AssignmentDetails isAssignmentDetailsOpen={isAssetAssignmentDetailsOpen} handleDrawerClose={closeAssignmentDetails} isSmallerScreen={isSmallerScreen} />}
        {isConsumableAssignmentsDetailsOpen && <ConsumableAssignmentDetails isConsumableAssignmentsDetailsOpen={isConsumableAssignmentsDetailsOpen} handleDrawerClose={closeConsumableAssignmentsDetails} isSmallerScreen={isSmallerScreen} />}
        {isConsumableAssignmentsFormOpen && <ConsumableAssignmentForm
          isConsumableAssignmentsFormOpen={isConsumableAssignmentsFormOpen}
          handleDrawerClose={() => setConsumableAssignmentForm(false)}
          isSmallerScreen={isSmallerScreen}
        />}
        {isAssetAssignmentsFormOpen && <AssignmentForm
          isAssignmentFormOpen={isAssetAssignmentsFormOpen}
          handleDrawerClose={() => setAssignmentForm(false)}
          isSmallerScreen={isSmallerScreen}
        />}
        <Paper variant="outlined" sx={{ p: 2 }} className="body-card">
          <Grid container>
            <Grid item xs={12} md={7}>
              <Typography variant="h6">
                {role === userRoles.User ? "My Assets" : "Assignments"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={5}
              container
              columnGap={{ md: 2 }}
              justifyContent={{ xs: "space-between", md: "end" }}
              alignItems={"center"}
            >
              <Grid item xs={6} md={"auto"}>
                <Box className="search-container">
                  <DebounceInput
                    type="text"
                    placeholder="Search"
                    className="search-input"
                    value={searched}
                    onChange={(e) => handleSearch(e)}
                    debounceTimeout={500}
                  />
                </Box>
              </Grid>
              {role !== userRoles.User && (
                <Grid item xs={5} md={"auto"} textAlign={{ md: "end" }}>
                  <Button
                    variant="outlined"
                    onClick={handleAssignButtonClick}
                    endIcon={<KeyboardArrowDownIcon />}
                  >
                    Assign
                  </Button>
                  <Menu
                    sx={{ mt: "45px" }}
                    id="menu-add-button"
                    disableScrollLock
                    anchorEl={assignButtonAnchorEl}
                    anchorOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "right",
                    }}
                    open={Boolean(assignButtonAnchorEl)}
                    onClose={handleAssignButtonClose}
                    slotProps={{
                      paper: {
                        elevation: 0,
                        sx: {
                          overflow: "visible",
                          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                          mt: 1.5,
                          borderRadius: "10px",
                          "& .MuiAvatar-root": {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                          },
                          "&::before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: "background.paper",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        },
                      },
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        setAssignmentForm(true);
                        handleAssignButtonClose();
                      }}
                      sx={{ pr: 4, py: 1, textAlign: "start" }}
                    >
                      <Typography textAlign="center">Asset</Typography>
                    </MenuItem>
                    <MenuItem
                      onClick={() => {
                        setConsumableAssignmentForm(true);
                        handleAssignButtonClose();
                      }}
                      sx={{ pr: 4, py: 1, textAlign: "start" }}
                    >
                      <Typography textAlign="center">Consumable</Typography>
                    </MenuItem>
                  </Menu>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Divider className="horizontal-line" sx={{ my: 1 }} />
          {rowsLoading ? (
            <LoadingSpinner />
          ) : rows?.length === 0 ? (
            "No data found..."
          ) : (
            <>
              <TableContainer>
                <Table size="medium">
                  <TableHead className="stat-card-bg">
                    <TableRow key={`assignment_head_row`}>
                      {role === userRoles.User
                        ? assetColumnsForUser.map((assetCol) =>
                          getTableCell(assetCol, orderBy, order, handleSortRequest)
                        )
                        : assignmentColumns.map((assetCol) =>
                          getTableCell(assetCol, orderBy, order, handleSortRequest)
                        )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows &&
                      rows.map((assignmentRow, i) => {
                        if(role === userRoles.User){
                          return (
                            <TableRow key={`assignment_${i}`}>
                              <Tooltip title={assignmentRow.asset_name}>
                                <TableCell
                                  sx={{ maxWidth: "250px" }}
                                  className="text-truncate primary-text link-hover"
                                  onClick={() =>
                                    openDetailsForm(assignmentRow.id, assignmentRow.type)
                                  }
                                >
                                  {assignmentRow.asset_name || assignmentRow.consumable_name}
                                </TableCell>
                              </Tooltip>
                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {assignmentRow.category}
                              </TableCell>

                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {assignmentRow.make}
                              </TableCell>

                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {assignmentRow.model}
                              </TableCell>

                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {assignmentRow.location}
                              </TableCell>

                              <TableCell
                                className="text-truncate"
                                sx={{ maxWidth: "150px" }}
                              >
                                {assignmentRow.assigned_on}
                              </TableCell>
                            </TableRow>
                          );
                        } else {
                          return (
                            <TableRow key={`assignment_${i}`}>
                            <Tooltip title={assignmentRow.asset_name}>
                              <TableCell
                                sx={{ maxWidth: "250px" }}
                                className="text-truncate primary-text link-hover"
                                onClick={() =>
                                  openDetailsForm(assignmentRow.id, assignmentRow.type)
                                }
                              >
                                {assignmentRow.asset_name || assignmentRow.consumable_name}
                              </TableCell>
                            </Tooltip>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assignmentRow.type}
                            </TableCell>
                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assignmentRow.category}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assignmentRow.make}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assignmentRow.assigned_to_name}
                            </TableCell>

                            <TableCell
                              className="text-truncate"
                              sx={{ maxWidth: "150px" }}
                            >
                              {assignmentRow.location}
                            </TableCell>
                            </TableRow>
                          );
                        }
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={Boolean(totalCount) ? totalCount : 0}
                rowsPerPage={rowsPerPage}
                page={currentPage}
                onPageChange={handlePage}
                onRowsPerPageChange={handleRowsPerPage}
              />
            </>
          )}
        </Paper>
      </Container>
    </>
  );
};

export default Assignments;
