export const getTaskCardsBasedOnRows = (taskRows) => {
    let salesTypeTasks = 0;
    let supportTypeTasks = 0;
    let MarketingTypeTasks = 0;
    taskRows?.forEach((task) => {
        if (task.task_type === 'Sales') {
            salesTypeTasks++;
        } else if (task.task_type === 'Support') {
            supportTypeTasks++;
        } else {
            MarketingTypeTasks++;
        }
    });
    return [
        {
            task_type: 'Sales',
            count: salesTypeTasks,

        },
        {
            task_type: 'Support',
            count: supportTypeTasks,
        },
        {
            task_type: 'Marketing',
            count: MarketingTypeTasks,
        },
    ];
}