import { messages } from "../../constants/validationMessages/BdForms/AddTarget";
import { frequencies } from "../../enums/Target";
const Joi = require("joi");

const precisionRule = (value, helpers) => {
    if (value !== undefined) {
        const decimalPart = (value.toString().split(".")[1] || "").length;
        if (decimalPart > 2) {
            return helpers.error("number.precision");
        }
    }
    return value;
};

const targetSchema = Joi.object().keys({
    user_id: Joi.number().integer().min(1).required().messages({
        "number.min": messages.userIdDefault,
        "number.base": messages.userIdDefault,
        "any.only": messages.userIdDefault,
    }),

    frequency: Joi.string()
        .valid(...Object.values(frequencies))
        .required()
        .messages({
            "any.only": messages.frequencyDefault,
            "string.empty": messages.frequencyDefault,
        }),

    description: Joi.string().allow("").messages({
        "any.required": messages.descriptionDefault,
    }),
});

const targetDetailsSchema = Joi.array()
    .items(
        Joi.object({
            deals_count: Joi.number().integer().min(0).required().messages({
                "number.min": messages.dealsCountMin,
                'number.integer':messages.dealsCountInteger,
                'any.required': messages.dealsCountRequired,
                "number.base": messages.dealsCountBase,
            }),
            deals_amount: Joi.number().min(0).required().custom(precisionRule, "custom precision").messages({
                "number.min": messages.dealsAmountMin,
                "number.base": messages.dealsAmountBase,
                "number.precision": messages.dealsAmountPrecision,
                'any.required': messages.dealsAmountRequired,

            }),
        })
    )
    .required();

export { targetSchema, targetDetailsSchema };
